.heart-and-vascular {
	.btn {
		@include text(kappa);
		@include font-weight(medium);
		background-color: $color-gray-dark;
		border-color: $color-gray-dark;
		border-radius: 2px;
		color: $color-white;
		max-width: 100%;
		padding: 0.75rem 2.5rem;
		white-space: pre-wrap;
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: none;
		}
	}

	.btn-primary {
		background-color: $color-gray-dark;
		border-color: $color-gray-dark;
		color: $color-white;
	}

	.btn-secondary {
		background-color: $color-white;
		border-color: $color-gray-neutral;
		color: $color-black;

		&:hover,
		&:focus {
			background-color: $color-gray-dark;
			border-color: $color-gray-dark;
			color: $color-white;
		}
	}

	.btn-sm {
		@include text(lambda);
		padding: 0.5rem 1.5rem;
	}
}
