/*
 * pulse-highlight($color)
 * adds pulse highlight to circular element with equal height and width
 * $color must be passed
 */
@mixin pulse-highlight($color) {
	$delay: 5;
	$animationPercent: (calc(2 / (2 + $delay))) * 100;

	&:before {
		border-radius: 50%;
		content: '';
		height: calc(100% + 12px);
		width: calc(100% + 12px);
		border: 4px solid $color;
		margin-left: -6px;
		margin-top: -6px;
		position: absolute;
		top: 0;
		left: 0;
		pointer-events: none;
		opacity: 0;
		animation: pulse infinite ease-in-out #{2 + $delay}s;
	}

	&:after {
		border-radius: 50%;
		content: '';
		height: calc(100% + 12px);
		width: calc(100% + 12px);
		border: 2px solid $color;
		margin-left: -6px;
		margin-top: -6px;
		position: absolute;
		top: 0;
		left: 0;
		pointer-events: none;
		opacity: 0;
		animation: pulse-outward infinite ease-in-out #{2 + $delay}s;
	}

	@keyframes pulse {
		0% {
			opacity: 0;
		}
		#{$animationPercent * 0.3}% {
			//Originally 30% when looping 2s animation
			opacity: 1;
		}
		#{$animationPercent}% {
			//Originally 100% when looping 2s animation
			opacity: 0;
		}
	}

	@keyframes pulse-outward {
		0% {
			transform: scale(1, 1);
			opacity: 0;
		}
		#{$animationPercent * 0.15}% {
			//Originally 15% when looping 2s animation
			opacity: 0;
		}
		#{$animationPercent * 0.25}% {
			//Originally 25% when looping 2s animation
			opacity: 0.5;
		}
		#{$animationPercent}% {
			//Represents 100% when looping 2s animation, adjusted for delay
			transform: scale(1.5, 1.5);
			opacity: 0;
		}
	}
}
