button,
.btn {
	cursor: pointer;
}

.btn-link {
	@include text(lambda);
	@include font-weight(normal);
	color: $color-blue;
	margin-bottom: 1rem;
	text-decoration: none;
	cursor: pointer;

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

.btn-link-inline {
	color: $color-blue;
	text-decoration: none;
	cursor: pointer;

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

.btn {
	@include text(kappa);
	@include font-weight(bold);
	background-image: none;
	border: 1px solid transparent;
	display: inline-block;
	margin-bottom: 0;
	outline-style: none;
	padding: 1.125rem 1.375rem;
	text-align: center;
	text-decoration: none;
	touch-action: manipulation;
	transition-duration: 0.15s;
	transition-property: background-color, color, border-color;
	transition-timing-function: ease-in-out;
	user-select: none;
	vertical-align: middle;
	white-space: nowrap;
	&:hover {
		text-decoration: none;
	}
	&:active {
		background-image: none;
		outline: 0;
	}
	&:focus {
		outline: $color-blue-bright solid 2px;
		.using-mouse & {
			outline: none;
		}
	}
}

/* primary
---------------------------- */
.btn-primary {
	background-color: $color-white;
	border-color: $color-white;
	color: $color-black;
	margin-bottom: 1.5rem;
	min-width: 190px;

	&[disabled] {
		color: $color-gray-neutral;
		cursor: default;
	}

	&.submitting {
		@include loader($color-gray-neutral, 26px, 4px);
		display: flex;
		height: 60px;
		justify-content: center;

		span {
			display: none;
		}
	}

	@include breakpoint(md) {
		&:not([disabled]) {
			&:focus {
				background-color: $color-black;
				border-color: $color-black;
				color: $color-white;
			}
			&:hover {
				background-color: $color-black;
				border-color: $color-black;
				color: $color-white;
			}
			&:active {
				background-color: $color-black;
				border-color: $color-black;
				color: $color-white;
			}
		}
	}
}

/* secondary
---------------------------- */
.btn-secondary {
	background-color: $color-gray-neutral;
	border-color: $color-gray-neutral;
	color: $color-black;
	margin-bottom: 1.5rem;
	min-width: 190px;
	&:focus {
		background-color: $color-black;
		border-color: $color-black;
		color: $color-white;
	}
	&:hover {
		background-color: $color-black;
		border-color: $color-black;
		color: $color-white;
	}
	&:active {
		background-color: $color-black;
		border-color: $color-black;
		color: $color-white;
	}
}

/* success
---------------------------- */
.btn-success {
	background-color: $color-success;
	border-color: $color-gray-light;
	color: $color-black;
	&:focus {
		background-color: $color-success-hover;
	}
	&:hover {
		background-color: $color-success-hover;
	}
	&:active {
		background-color: $color-success-hover;
	}
}

/* info
---------------------------- */
.btn-info {
	background-color: $color-info;
	border-color: $color-gray-light;
	color: $color-black;

	&:focus {
		background-color: $color-info-hover;
	}
	&:hover {
		background-color: $color-info-hover;
	}
	&:active {
		background-color: $color-info-hover;
	}
}

/* danger
---------------------------- */
.btn-danger {
	background-color: $color-danger;
	border-color: $color-gray-light;
	color: $color-black;

	&:focus {
		background-color: $color-danger-hover;
	}
	&:hover {
		background-color: $color-danger-hover;
	}
	&:active {
		background-color: $color-danger-hover;
	}
}

/* warning
---------------------------- */
.btn-warning {
	background-color: $color-warning;
	border-color: $color-gray-light;
	color: $color-black;

	&:focus {
		background-color: $color-warning-hover;
	}
	&:hover {
		background-color: $color-warning-hover;
	}
	&:active {
		background-color: $color-warning-hover;
	}
}

.btn-no-background {
	@include text(gamma);
	@include font-weight(bold);
	background-color: transparent;
	border-color: transparent;
	color: $color-white;

	&:focus {
		background-color: $color-blue;
	}
	&:hover {
		background-color: $color-blue;
	}
	&:active {
		background-color: $color-blue;
	}
}

/* btn-group
---------------------------- */
.btn-group {
	display: flex;
	flex-direction: column;
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;

	.btn {
		width: 100%;
		margin-bottom: 0;
	}

	@include breakpoint(md) {
		display: flex;
		flex-direction: row;
		justify-content: center;

		.btn {
			min-width: 200px;
			width: auto;
		}
	}

	@include breakpoint(lg) {
		justify-content: flex-start;
	}
}

/* button with outline
---------------------------- */
.btn-outline {
	@include text(lambda);
	@include font-weight(medium);
	background: $color-white;
	border: 1px solid $color-gray-neutral;
	border-radius: 2px;
	text-align: center;
	text-decoration: none;
	display: block;
	padding: 10px 15px;
	white-space: nowrap;
	@include breakpoint(md) {
		&:not([disabled]) {
			&:focus,
			&:hover,
			&:active {
				background-color: $color-gray-dark;
				border-color: $color-gray-dark;
				color: $color-white;
				text-decoration: none;
			}
		}
	}
}
