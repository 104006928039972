.featured-images {
	overflow: hidden;

	.title-primary {
		margin-bottom: 0.5rem;
		text-align: center;
		width: 100%;
	}

	.title-secondary {
		margin: auto;
		margin-bottom: 0.25rem;
	}

	.description {
		margin: auto;
		margin-bottom: 0.25rem;
	}

	.cta-buttons {
		display: flex;
		justify-content: space-between;
	}

	.cta-left,
	.cta-right {
		max-width: 200px;
		width: calc(50% - (0.75rem / 2));
	}

	.images-container {
		align-items: center;
		display: flex;
		flex: 1 1 0;
		justify-content: center;
		margin-bottom: 2rem;
		margin-left: -16%;
		position: relative;
		width: 128%;
	}

	.mobile-image {
		z-index: 1;

		img {
			margin-left: 35.5%;
			max-height: 445px;

			.is-android & {
				margin-top: 5%;
				max-height: 400px;
			}
		}
	}

	.desktop-image {
		img {
			margin-top: 4.5%;
			margin-left: -11.5%;
			max-height: 352px;
		}
	}

	@include breakpoint(md) {
		.featured-images-wrapper {
			@include make-row();
			@include make-col-ready();
			@include make-col(12);
			margin: auto;
		}

		.centered-container {
			align-items: center;
			display: flex;
			justify-content: center;
			justify-content: space-between;
			position: relative;
			width: 100%;
		}

		.images-container {
			margin: 0 auto;
			width: 100%;
		}

		.desktop-image {
			margin-right: -2%;
		}

		.cta-left,
		.cta-right {
			align-items: center;
			display: flex;
			left: 0;
			// padding-bottom: 2rem;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 140px;
			z-index: 1;

			&.description-cta {
				width: 240px;
			}
		}

		.cta-right {
			left: auto;
			right: 0;
		}
	}

	@include breakpoint(lg) {
		.featured-images-wrapper {
			@include make-col(10);
		}

		.images-container {
			max-width: 100%;
		}
	}
}
