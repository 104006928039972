.ngde-care-team {
    @include make-container();
    margin-bottom: 4rem;
    margin-left: auto;
    margin-right: auto;
    max-width: $lg-xl-breakpoint;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 140px;
    width: 100%;

    a {
        text-decoration: none;
    }

    .content-col {
        padding-top: 1.5rem;
    }

    .care-team-list {
        position: relative;

        &.loading {
            min-height: 400px;

            .loading-overlay {
                opacity: 1;
            }
        }

        &.primary {
            .col-12 {
                display: flex;
            }

            .care-team-card {
                flex-grow: 1;
            }
        }
    }

    .care-team-card {
        background-color: $color-gray-providers;
        display: block;
        margin-bottom: 2rem;
        padding: 1rem 2rem 2rem;

        .modal-open-btn {
            @include text(mu);
            @include font-weight(medium);
            display: block;
            margin: 2rem 0 0;
        }
    }

    .card-header {
        @include text(lambda);
        @include font-weight(medium);
        border-bottom: 1px solid $color-black;
        margin-bottom: 5px;
        width: fit-content;

        &:last-of-type {
            margin-bottom: 3rem;
        }
    }

    .card-description {
        @include text(mu);
        display: block;
        margin-bottom: 0.3125rem;
        width: fit-content;

        a {
            display: inline;
        }
    }

    .other-care-header {
        @include font-weight(medium);
        margin-bottom: 1rem;
    }

    .section-description {
        @include text(lambda);

        .icon {
            height: 10px;
            margin-left: 10px;
            stroke: $color-blue;
            width: 10px;
        }
    }

    .phone-type {
        display: inline-block;
        text-transform: capitalize;
    }

    p.change-pcp-btn {
        button.btn-link.modal-open-btn {
            color: $color-white;
            padding: 0.7em 1.5em;
            background-color: #363534;
            text-decoration: none;
        }
    }

    @include breakpoint(md) {
        padding-top: 168px;
    }

    @include breakpoint(lg) {
        padding-top: 255px;
    }
}
