.read-more {
	margin-bottom: 2rem;
	position: relative;

	&.is-expanded {
		> .read-more-body {
			max-height: none;
		}

		> .read-more-condensed {
			display: none;
		}

		.read-more-expanded {
			display: block;
		}
	}

	.read-more-body {
		margin-bottom: 2rem;
		max-height: 280px;
	}

	p {
		@include font-weight(medium);
		@include text(lambda);

		&:last-of-type {
			margin-bottom: 0.5rem;
		}
	}

	.read-more-expanded {
		display: none;
	}

	.read-more-link {
		@include font-weight(medium);
		@include text(lambda);
		display: inline-block;
	}

	.read-more-link-alt {
		background-color: $color-blue-highlight;
		border-bottom: 1px solid $color-blue;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 38px;
		width: 100%;

		span {
			color: $color-blue;
			padding-right: 0.5rem;
		}

		.icon {
			stroke: $color-blue;
		}
	}

	.button-container {
		display: flex;
		justify-content: center;
	}

	.btn {
		&.toggle-expand {
			.icon {
				height: 14px;
				fill: $color-white;
				width: 28px;
			}
		}
	}

	.read-more-link,
	.button-container .btn {
		&.is-expanded {
			> .expandToggleLabel {
				display: none;
			}

			> .collapseToggleLabel {
				display: inline;
			}

			> .icon {
				transform: rotate(180deg);
			}
		}

		> .collapseToggleLabel {
			display: none;
		}
	}

	@include breakpoint(md) {
		margin-bottom: 4rem;

		.sc-edit-mode & {
			.read-more-condensed {
				&:after {
					content: '(Hidden when expanded)';
					display: block;
					font-style: italic;
					color: $color-gray-neutral;
					margin-bottom: 1rem;
				}
			}
			.read-more-expanded {
				display: block;

				&:after {
					content: '(Hidden until expanded)';
					display: block;
					font-style: italic;
					color: $color-gray-neutral;
					margin-bottom: 1rem;
				}
			}
			.read-more-link {
				display: none;
			}
		}
	}
}
