.article-list-paginated {
	.article-list {
		padding-left: 0;
		padding-right: 0;
		margin-bottom: 2rem;

		ul {
			padding-left: 0;
		}
	}

	.article-listing {
		display: none;

		&.active {
			display: block;
		}

		&:last-of-type {
			margin-bottom: 2.5rem;
		}

		a {
			color: $color-black;
		}
	}

	.pagination-controls {
		display: flex;
		flex-direction: column;
		align-items: center;

		.pagination-counts {
			justify-content: center;
		}

		.page-link {
			.left {
				transform: rotate(180deg);
			}
		}
	}

	.results-count {
		@include text(iota);
		@include font-weight(medium);
		margin-bottom: 2rem;
	}

	@include breakpoint(md) {
		margin-bottom: 2rem;
		padding-right: 30px;

		.pagination-controls {
			align-items: flex-start;
		}

		.results-count {
			@include text(lambda);
		}
	}

	@include breakpoint(lg) {
		padding-right: 70px;
	}
}
