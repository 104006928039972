.card {
	display: flex;
	flex-direction: column;
	margin-bottom: 2rem;
	align-items: center;

	.card-header,
	.card-body,
	.card-footer {
		padding: 1rem;
	}

	.no-results-message {
		.card-link {
			color: $color-blue;
		}
	}
}
