.ngde-benefits-and-coverage {
	@include make-container();
	padding-top: 300px;

	.claim-summary-card {
		@include make-row();
	}
	.claim-id a {
		@include text(gamma);
		color: $color-red;
		text-decoration: none;
	}

	.grid-row {
		@include make-row();
	}

	h2 {
		@include text(delta);
	}

	h3 {
		@include text(epsilon);
	}
}
