.social-share {
	.btn {
		@include text(kappa);
		@include font-weight(medium);
		background-color: $color-white;
		border-color: $color-gray-neutral;
		border-radius: 2px;
		color: $color-black;
		margin-bottom: 10px;
		max-width: 100%;
		padding: 0.75rem 2.5rem;
		text-decoration: none;
		white-space: pre-wrap;
		width: 100%;

		&:hover,
		&:focus {
			background-color: $color-gray-dark;
			border-color: $color-gray-dark;
			color: $color-white;
			text-decoration: none;
		}
	}

	.btn-facebook {
		background-color: $color-facebook;
		border-color: $color-facebook;
		color: $color-white;

		&:hover,
		&:focus {
			background-color: $color-facebook-hover;
			border-color: $color-facebook-hover;
		}
	}

	.btn-twitter {
		background-color: $color-twitter;
		border-color: $color-twitter;
		color: $color-white;

		&:hover,
		&:focus {
			background-color: $color-twitter-hover;
			border-color: $color-twitter-hover;
		}
	}

	@include breakpoint(xs) {
		.social-share-row {
			display: flex;
			justify-content: space-between;

			.btn {
				flex: 0 1 calc(50% - 5px);
				margin-bottom: 0;
			}
		}
	}
}
