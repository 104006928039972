$grid-columns: 12;
$gutter: 10px;
$max-container-width: 1440px;
// $sm-grid-outer-padding: 30px;
// $md-grid-outer-padding: 40px;
// $lg-grid-outer-padding: 60px;
// $xl-grid-outer-padding: 100px;
$sm-grid-outer-padding: 30px;
$md-grid-outer-padding: 30px;
$lg-grid-outer-padding: 70px;
$xl-grid-outer-padding: 70px;
