.image-summary {
	display: flex;
	flex-direction: column;

	&.image-top {
		.image-block {
			margin-bottom: 1.5rem;

			.image-container {
				&:not(:last-child) {
					margin-bottom: 1.5rem;
				}
			}
		}
	}

	.image-block {
		align-items: center;
		display: flex;
		flex-direction: column;
		padding: 0 3rem;
		position: relative;

		&.full-width {
			padding: 0;
		}

		.image-container {
			height: 100%;
			position: relative;
			text-align: center;
			width: 100%;

			// TODO: Remove this / simplify once images are cropped with consistent transparency padding
			.hide-for-android {
				margin-top: -1rem;
				margin-bottom: -1rem;
			}
		}

		img {
			margin: auto;
			max-height: 500px;
		}

		.image-caption {
			@include text(kappa);
			@include font-weight(medium);
			margin: -1rem 0 2rem -3rem;
			text-align: center;
			width: calc(100% + 6rem);

			.is-android & {
				margin-top: 0;
			}
		}

		.mobile-links {
			width: calc(100% + 6rem);
		}
	}

	.content-block {
		> :last-child {
			margin-bottom: 0;
		}

		.mobile-trim {
			margin-bottom: 0;
		}

		.cta-link {
			margin-bottom: 0.25rem;
		}
	}

	.desktop-links {
		display: none;
	}

	.title-secondary {
		+ .subtitle {
			margin-top: -0.75rem;
		}
	}

	.subtitle {
		margin-bottom: 0.25rem;
	}

	.cta-link-wrapper {
		@include text(kappa);
		@include font-weight(bold);
		color: $color-black;
		margin-bottom: 0.25rem;

		.cta-link {
			display: inline;
			margin: 0;
		}
	}

	.btn-group {
		padding-top: 2rem;
	}

	.btn {
		width: 100%;
	}

	@include breakpoint(md) {
		align-items: center;
		flex-direction: row-reverse;
		justify-content: center;
		margin-top: 0;
		padding-bottom: 5.75rem;
		padding-top: 2rem;

		&.image-top {
			.image-block {
				margin-bottom: 0;
			}

			&.image-left {
				flex-direction: row;
				justify-content: flex-start;
			}
		}

		&.half {
			justify-content: center;
			padding-top: 4.5rem;

			.image-block {
				flex-basis: 510px;
				max-width: 510px;
				padding: 0 1.25rem 0 1.75rem;
			}

			.content-block {
				flex-basis: 510px;
				max-width: 510px;
				padding: 0 0 0 3rem;
			}

			&.image-top {
				.image-block {
					align-items: center;
				}

				.content-block {
					flex-basis: 510px;
					max-width: 510px;
					padding: 0 3rem 0 0;
				}
			}
		}

		.image-block {
			align-items: flex-end;
			margin-top: 0;
			max-width: 650px;
			padding: 0 5rem 0 4rem;

			.image-caption {
				margin-top: -1.5rem;
				position: absolute;
				top: 100%;

				.is-android & {
					margin-top: 0;
				}
			}

			.image-container {
				.hide-for-android {
					margin-top: 0;
				}
			}
		}

		.content-block {
			flex: 1 1 0;
			flex-basis: 460px;
			max-width: 460px;

			.mobile-trim {
				margin-bottom: 1.5rem;
			}
		}

		.mobile-links {
			display: none;
		}

		.desktop-links {
			display: block;
		}

		.title-secondary {
			+ .subtitle {
				margin-top: 0;
			}
		}

		.subtitle {
			margin-bottom: 1rem;
		}

		.btn {
			width: auto;
		}

		.form-label {
			text-align: left;
		}
	}

	@include breakpoint(lg) {
		justify-content: flex-end;

		.image-block {
			min-width: 530px;
		}
	}
}
