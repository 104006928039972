.claims-date-filter {
	position: relative;

	.claim-button-input.filter-input {
		align-items: center;
		color: $color-gray-medium-light;
		display: flex;
		padding-right: 30px;

		.date-input {
			@include text(lambda);
			border-radius: 2px;
			background-color: $color-blue-highlight;
			color: $color-blue-providers;
			height: auto;
			text-align: center;
			padding: 5px;
			pointer-events: none;
			margin: 0 5px 0 0;
			min-width: 0;
			border: none;
		}

		.icon {
			position: absolute;
			height: 18px;
			width: 18px;
			right: 10px;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.date-filter-container {
		align-items: center;
		box-shadow: 0px 3px 6px rgba($color-black, 0.29);
		position: absolute;
		margin: 1.25rem -2rem 0 0;
		background: $color-white;
		border: 1px solid $color-gray-table-border;
		border-radius: 4px;
		display: flex;
		flex-direction: column;
		padding: 1rem 0;
		left: 50%;
		text-align: center;
		top: 100%;
		transform: translateX(-50%);
		width: 100%;
		z-index: 9;

		&:before {
			display: block;
			content: ' ';
			width: 0;
			height: 0;
			border-left: 18px solid transparent;
			border-right: 18px solid transparent;
			border-bottom: 18px solid $color-gray-table-border;
			position: absolute;
			margin-right: 10px;
			top: 0;
			left: 50%;
			transform: translate(-50%, -100%);
		}

		&:after {
			display: block;
			content: ' ';
			width: 0;
			height: 0;
			border-left: 16px solid transparent;
			border-right: 16px solid transparent;
			border-bottom: 16px solid $color-white;
			position: absolute;
			margin-right: 10px;
			top: 0;
			left: 50%;
			transform: translate(-50%, -100%);
		}
	}

	.filter-tabs {
		background-color: $color-gray-light;
		border: 1px solid $color-gray-neutral;
		border-radius: 15px;
		display: flex;
		justify-content: space-between;
		padding: 0.125rem;
	}

	.filter-tab {
		@include text(mu);
		border-radius: 15px;
		padding: 0.125rem 1rem;

		&.active {
			@include font-weight(normal);
			color: $color-white;
			background-color: $color-blue-providers;
		}
	}

	.tab-content {
		position: relative;
		padding: 1rem;
	}

	.year-container {
		display: flex;
		padding: 0 2rem;
	}

	.year-title {
		@include text(lambda);
		@include font-weight(bold);
		color: $color-gray-dark;
		margin-bottom: 1.25rem;
	}

	.DayPickerNavigation {
		display: flex;
		justify-content: space-between;
		padding: 0 24px;
		position: absolute;
		top: 0;
		width: 100%;
	}

	.year-nav-btns {
		display: flex;
		justify-content: space-between;
		padding: 0 1rem;
		position: absolute;
		left: 0;
		top: 1rem;
		width: 100%;
		z-index: 2;
		pointer-events: none;

		.year-nav-btn {
			pointer-events: auto;
		}
	}

	.calendar-btn {
		display: flex;
		background-color: $color-white;
		border: 1px solid $color-gray-neutral;
		width: 24px;
		height: 24px;
		justify-content: center;
		align-items: center;
		transform: translateY(17px);

		.icon {
			height: 0.5rem;
			width: 0.5rem;
		}

		&.double {
			.icon {
				width: 0.25rem;
			}
		}

		&.left {
			.icon {
				transform: rotate(180deg);
			}
		}
	}

	.CalendarDay__selected {
		background: $color-gray-table-border;
		border: 1px solid #e4e7e7;
		color: $color-black;

		&:hover {
			background: $color-gray-medium-light;
			border: 1px solid #e4e7e7;
			color: $color-black;
		}

		&_span {
			background: $color-gray-light; //background
			border: 1px solid #e4e7e7;
			color: $color-black;
		}
	}

	.CalendarDay__hovered_span:hover,
	.CalendarDay__hovered_span {
		background: $color-gray-light;
		border: 1px solid #e4e7e7;
		color: $color-black;
	}

	.month-text {
		@include text(lambda);
		@include font-weight(bold);
	}

	.radio-wrapper {
		input[type='radio'] {
			+ label {
				&:before {
					border: 1px solid #bfbfbf;
				}
			}
		}
	}

	@include breakpoint(md) {
		.date-filter-container {
			width: 650px;
		}
	}
}
