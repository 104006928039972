.title-bar {
	align-items: flex-start;
	display: flex;
	flex-flow: column-reverse;
	margin: 60px 30px 0.75rem;
	padding-top: 1.5rem;

	.language {
		margin-left: auto;
	}

	&.has-search {
		.title-and-links {
			display: none;
		}

		.search-loaded & {
			margin-bottom: 0.75rem;
			margin-top: 134px;

			.title-and-links {
				display: grid;
				gap: 10px;
			}
		}
	}

	.title {
		margin-bottom: 0.25rem;
	}

	.btn-link {
		margin-bottom: 0;
		margin-top: 0.25rem;
		text-align: left;
	}

	@include breakpoint(md) {
		align-items: center;
		display: flex;
		flex-flow: initial;
		justify-content: space-between;
		margin-bottom: 1.5rem;
		padding-top: 2rem;

		&.has-search {
			display: flex;
			justify-content: flex-end;
			margin-bottom: 0;

			.title-and-links {
				display: grid;
				visibility: hidden;
			}

			.search-loaded & {
				justify-content: space-between;
				margin-bottom: 1.5rem;

				.title-and-links {
					visibility: visible;
				}
			}

			.title {
				margin-bottom: 0;
			}
		}

		.title {
			margin-bottom: 0.375rem;
		}
	}

	@include breakpoint(lg) {
		@include make-container();
		margin-top: 0;
		padding-top: 0;

		&.has-search {
			margin-top: 0;

			.search-loaded & {
				margin-top: 0;
			}
		}
	}
}
