.portal {
	.portal-table {
		border-collapse: collapse;
		margin-bottom: 2rem;
		width: 100%;

		thead {
			tr {
				border: none;
			}
		}

		th {
			@include text(lambda);
			background-color: $color-gray-light;
			cursor: pointer;
			padding: 1rem;
			position: relative;
			text-align: left;
			vertical-align: middle;
			white-space: nowrap;
			width: 1%;

			.table-header-title {
				color: $color-gray-dark;
				display: inline;
				font-weight: normal;
				line-height: 1;
				padding: 10px 0;

				&.active-sort {
					font-weight: bold;
				}
			}

			.arrow-icon-wrapper {
				bottom: -6px;
				display: inline-block;
				height: 24px;
				position: relative;
				width: 24px;

				&.active-sort {
					.icon {
						fill: $color-gray-dark;
					}
				}
			}

			.icon {
				fill: $color-gray-neutral;
				height: 24px;
				margin-left: 10px;
				stroke: $color-gray-light;
				width: 24px;
			}
		}

		tr {
			border-top: 1px solid $color-gray-table-border;

			&.expandable {
				&.expanded {
					background-color: $color-off-white;

					+ .additional-info {
						display: table-row;
					}

					.expander {
						.icon {
							transform: rotate(90deg);
						}
					}
				}
			}

			&.additional-info {
				background-color: $color-off-white;
				border: none;
				display: none;
			}
		}

		td {
			@include text(lambda);
			padding: 1.5rem 1rem;
			width: 1%;

			&.expander {
				cursor: pointer;
			}
		}

		.status-icon-wrapper {
			display: flex;

			.icon {
				margin-right: 0.5rem;
			}
		}

		.icon-arrow-right {
			height: 10px;
			width: 6px;
		}

		.additional-info {
			td {
				padding: 0 4rem 2rem;
			}
		}

		tfoot {
			tr {
				border: none;
			}

			td {
				@include text(lambda);
				background-color: $color-gray-light;
				padding: 1.5rem 1rem;
				position: relative;
				text-align: left;
				vertical-align: middle;
				white-space: nowrap;
				width: 1%;
			}

			.totals-label {
				text-transform: uppercase;
			}
		}
	}
}
