.verify-communication-type-form {
	.heading-1 {
		color: $color-black;
	}

	.btn-primary {
		background-color: $color-white;
		border: 1px solid #bfbfbf;
		color: $color-black;
		height: fit-content;

		&:disabled {
			background-color: #eeeeee;
			border: 1px solid #bfbfbf;
			color: #707070;
		}

		&:hover:not([disabled]) {
			font-weight: bold;
			background-color: $color-black;
			color: $color-white;
		}
	}

	.form-control {
		button {
			text-align: center;
		}
	}

	.terms-instruction {
		color: $color-red;
	}

	.btn-instruction-text {
		color: $color-red;
	}

	.btn-with-instruction {
		margin-bottom: 1rem;
		.btn {
			width: 100%;
			margin-bottom: 0.3rem;
		}
	}

	.verify-communication-type-field {
		flex-direction: column;
		.input-field {
			width: 100%;
		}

		.btn-with-instruction {
			width: 100%;
			/*.btn {
				
			}*/
		}
	}

	@include breakpoint(lg) {
		.verify-communication-type-field {
			flex-direction: row;

			.input-field {
				width: 65%;
			}

			.btn-with-instruction {
				width: 35%;
				/*.btn {
					
				}*/
			}
		}
	}
}

.verify-communication-type-field {
	display: flex;
	flex-direction: row;

	.input-field {
		width: 75rem;
		margin-right: 1rem;
	}

	.floating-check {
		@include text(lambda);
		right: 1rem;
		opacity: 1;
		pointer-events: none;
		position: absolute;
		top: 1.5rem;
	}

	.btn {
		width: 25rem;
	}
}
.success-icon-container {
	position: absolute;
	right: 1rem;
	top: calc(50% - 1.5rem);
}
.verifying-icon-container {
	position: absolute;
	right: 1rem;
	top: calc(50% - 0.75rem);
}

.verification-error {
	color: red;
}

.verification-code-placeholder {
	text-align: left;
	color: #707070;
	position: absolute;
	top: 1.2rem;
	padding-left: 1rem;
}

.resend-code {
	text-align: center;
}

.verify-consent-checkbox-wrapper {
	@include checkbox(
		$borderColor: $color-gray-medium-light,
		$borderColorHover: $color-gray-dark,
		$checkboxColor: $color-black
	);

	color: $color-gray-medium-light;

	label {
		@include text(kappa);
		color: $color-gray-medium-light !important;
		display: flex !important;
		font-size: 1rem !important;
		padding-left: 1rem;
	}

	&.checkbox-span {
		display: flex;
		width: 100%;

		.pull-right {
			margin-left: auto;
		}
	}
}

.input-field-with-validation {
	display: flex;
	flex-direction: column;

	&.validation-error {
		input {
			border-color: $color-red;
		}
	}
}

.validation-error-message {
	padding-left: 0.25rem;
	padding-bottom: 1rem;
	color: $color-red;
}
