.banner-alert {
	background-color: $color-gray-dark;

	&.closed {
		display: none;
	}

	.banner-container {
		@include make-container();
		padding-bottom: 2rem;
		padding-top: 2rem;
	}

	.icons-wrapper {
		display: flex;
		justify-content: space-between;
		margin-bottom: 0.75rem;

		.icon {
			height: 25px;
			width: 25px;
		}

		.icon-modal-close {
			height: 17px;
			stroke: $color-white;
			stroke-width: 4px;
			width: 17px;
		}
	}

	.icon-container {
		display: flex;
	}

	.banner-close {
		align-items: center;
		display: flex;

		&:hover,
		&:focus {
			.icon {
				stroke: $color-gray-arrow;
			}
		}
	}

	.banner-heading {
		@include text(kappa);
		color: $color-white;
		font-family: $font-avenir-heavy;
		margin-bottom: 0;
	}

	a {
		color: $color-white;
	}

	p {
		@include text(kappa);
		color: $color-white;
		font-family: $font-avenir-book;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	@include breakpoint(md) {
		.banner-text {
			margin-right: 4rem;
			max-width: 1025px;
		}
	}

	@include breakpoint(lg) {
		.banner-container {
			overflow: hidden;
			position: relative;
		}

		.icons-wrapper {
			align-items: center;
			left: 50%;
			margin-bottom: 0;
			padding: 0 12px;
			pointer-events: none;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 100%;

			.icon {
				height: 35px;
				width: 35px;
			}

			.icon-modal-close {
				height: 18px;
				pointer-events: auto;
				width: 18px;
			}
		}
	}
}

.portal {
	.banner-alert {
		background-color: rgba($color-red-light, 0.95);
		margin-top: 0;
		margin-bottom: 0;

		.banner-container {
			padding-bottom: 0.5rem;
			padding-top: 0.5rem;
		}

		.banner-text {
			margin-right: 0;
			max-width: 98%;
		}

		.banner-heading {
			color: $color-black;
		}

		p {
			color: $color-black;
		}

		.icon-modal-close {
			stroke: $color-black;
		}
	}

	.banner-warning {
		background-color: rgba($color-neutral-yellow, 0.95);
		margin-top: 0;

		.banner-text {
			color: $color-black;
			margin-right: 0;
			max-width: 98%;
		}
	}

	.banner-info {
		background-color: rgba($color-blue-highlight, 0.95);
		margin-top: 0;

		.banner-text {
			margin-right: 0;
			max-width: 98%;
		}
	}

	a {
		color: $color-blue;
	}

	.system-error {
		height: 65px;
		left: 0px;
		position: fixed;
		top: 0px;
		width: 100%;
		z-index: 9999;
	}
}

.pls {
	.banner-wrapper {
		margin-top: 0px;
	}
	.banner-wrapper:first-of-type {
		margin-top: 135px;
	}
	.banner-alert {
		margin-bottom: none !important;
		background-color: rgba($color-red-light, 0.95);
		width: 100%;

		.icon {
			height: 40px;
			width: 40px;
		}

		.banner-container {
			display: flex;
			align-items: center;
			flex-direction: row;
			justify-content: stretch;
			padding-left: 25px;
			gap: 15px;
			padding-right: 25px;
			padding-bottom: 0.5rem;
			padding-top: 0.5rem;
			margin-left: 0px;
			margin-right: 0px;
			max-width: 100%;
		}

		.icon-container {
			flex-grow: 0;
			flex-shrink: 0;
		}

		.banner-close {
			flex-grow: 0;
			flex-shrink: 0;
		}

		.banner-text {
			margin-right: 0;
			flex: 1 1 200px;
			width: 100%;
			text-align: left;
			max-width: 100%;
		}

		.banner-heading {
			color: $color-black;
		}

		p {
			color: $color-black;
		}

		.icon-modal-close {
			stroke: $color-black;
			width: 20px;
		}
	}

	&.has-banner {
		.title-bar {
			/*margin-top: 30px;*/
		}
	}
	&.detail-view-loaded {
		.title-bar {
			margin-top: 60px;
		}
	}

	@include breakpoint(lg) {
		.banner-wrapper:first-of-type {
			margin-top: -45px;
		}
	}

	@include breakpoint(xl) {
		.banner-wrapper:first-of-type {
			margin-top: -45px;
		}
		.banner-alert {
			width: 100%;
		}
	}

	.banner-warning {
		background-color: rgba($color-neutral-yellow, 0.95);

		.banner-text {
			color: $color-black;
			margin-right: 0;
			max-width: 98%;
		}
	}

	.banner-info {
		background-color: rgba($color-blue-highlight, 0.95);
		margin-top: 0;

		.banner-text {
			margin-right: 0;
			max-width: 98%;
		}
	}

	a {
		color: $color-blue;
	}

	.system-error {
		height: 65px;
		left: 0px;
		position: fixed;
		top: 0px;
		width: 100%;
		z-index: 9999;
	}
}
