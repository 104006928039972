.custom-error {
	.title-primary {
		text-align: left;
	}

	.custom-error-content {
		p,
		span,
		li {
			@include text(iota);
			color: $color-gray-dark;
			text-align: left;
		}

		.list-title {
			margin-bottom: 0.5rem;
		}

		ul {
			margin-bottom: 1rem;
		}

		li {
			padding-bottom: 0.375rem;
		}

		.error-code {
			@include font-weight('medium');
			color: $color-red;
		}
	}

	@include breakpoint(md) {
		.custom-error-content {
			p,
			span,
			li {
				@include text(zeta);
			}
		}
	}
}
