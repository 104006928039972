.page-title {
	margin-bottom: 2rem;

	h1 {
		@include text(gamma);
		margin: 0;
	}

	.title-inner {
		@include make-container-bg();
		align-items: center;
		background-color: $color-blue;
		color: $color-white;
		display: flex;
		height: 155px;
	}

	@include breakpoint(md) {
		h1 {
			@include text(alpha);
		}

		.title-inner {
			height: 200px;
		}
	}
}
