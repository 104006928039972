.t3 {
	.title-primary {
		@include text(epsilon, 26);
		@include font-weight(semi-bold);
		color: $color-red;
		line-height: 1;
		margin-bottom: 1.25rem;
		text-align: center;
	}

	.title-secondary {
		@include text(zeta, 26);
		@include font-weight(bold);
		color: $color-black;
		margin-bottom: 1rem;
		text-align: center;
	}

	.section-title {
		@include text(kappa, 18);
		@include font-weight(bold);
		color: $color-black;
		margin-bottom: 1rem;
		text-align: center;
	}

	.subtitle {
		@include text(kappa, 18);
		@include font-weight(medium);
		color: $color-black;
		margin-bottom: 1rem;
		text-align: center;
	}

	.font-bold {
		@include font-weight(bold);
	}

	p {
		@include text(kappa, 18);
		color: $color-gray-dark;
		text-align: center;
	}

	.description {
		@include text(iota);
		color: $color-gray-dark;
		text-align: center;
	}

	.cta-link {
		@include text(kappa);
		@include font-weight(bold);
		color: $color-black;
		display: block;
		margin: 0 auto 1rem;
		text-align: center;
		text-decoration: underline;

		&:focus,
		&:hover {
			cursor: pointer;
			text-decoration: none;
		}

		&.center {
			margin-left: auto;
			margin-right: auto;
			text-align: center;
		}
	}

	.cta-box {
		background-color: $color-white;
		display: block;
		height: 0;
		padding: 100% 0 0;
		position: relative;
		text-align: left;
		transition: background-color 0.15s ease-in-out;
		width: 100%;

		&:focus,
		&:hover {
			background-color: $color-gray-dark;
			border-color: $color-gray-dark;
			color: $color-white;

			> span {
				color: $color-white;
			}

			svg {
				stroke: $color-white;
			}
		}

		> span {
			@include text(kappa, 16);
			@include font-weight(bold);
			color: $color-black;
			display: flex;
			flex-direction: column;
			height: 100%;
			justify-content: space-between;
			left: 0;
			padding: 1.25rem;
			position: absolute;
			top: 0;
			transition: color 0.15s ease-in-out;
			width: 100%;
		}

		.cta-description {
			@include font-weight(normal);
			display: none;
		}

		.cta-title {
			@include font-weight(bold);
		}

		svg {
			bottom: 1.25rem;
			height: 14px;
			margin-bottom: 2px;
			position: absolute;
			right: 1.25rem;
			stroke: $color-black;
			transition: stroke 0.15s ease-in-out;
			width: 8px;
		}
	}

	a[href^='tel:'] {
		white-space: nowrap;
	}

	.no-wrap {
		white-space: nowrap;
	}

	.dark-mode {
		background-color: $color-black;
		color: $color-gray-light;
		position: relative;

		p {
			color: $color-gray-light;
		}
	}

	@include breakpoint(md) {
		.title-primary {
			font-size: 50px;
			text-align: left;
		}

		.title-secondary {
			text-align: left;
		}

		.section-title {
			text-align: left;
		}

		.subtitle {
			text-align: left;
		}

		p {
			text-align: left;
		}

		.description {
			text-align: left;
		}

		.cta-link {
			margin: 0 0 1rem;
			text-align: left;
		}

		.cta-box {
			.cta-description {
				display: block;
			}

			&.description-cta {
				> span {
					padding: 1.875rem;
				}

				svg {
					bottom: 1.875rem;
					right: 1.875rem;
				}
			}
		}
	}
}
