.pls-profile-section {
	position: relative;

	&:not(:first-child) {
		margin-top: 2px;
	}

	// account for height of slim-header when anchoring to this section
	&:before {
		content: '';
		display: block;
		height: 60px;
		margin-top: -60px;
		pointer-events: none;
		visibility: hidden;
	}

	.accordion {
		position: relative;

		.accordion-item {
			border-top: 0;
			border-bottom: 0;

			&:last-of-type {
				border-bottom: 0;
			}
		}

		.accordion-item-toggle {
			display: none;
			background-color: $color-gray-light;
			padding: 24px 0 23px;
			position: relative;
			z-index: 1;

			&[aria-expanded='true'] {
				.icon-arrow-down {
					transform: rotate(180deg);
				}
			}

			&.show-on-mobile {
				display: block;
			}
		}
		.toggle-container {
			@include text(iota);
			@include font-weight(semi-bold);
		}
		.accordion-item-body {
			margin: 0;
		}
	}

	.modal-open-btn {
		@include text(iota);
		@include font-weight(semi-bold);
		align-items: center;
		background-color: $color-gray-light;
		display: flex;
		justify-content: space-between;
		padding: 26px 30px;
		position: relative;
		z-index: 1;

		.icon-arrow-down {
			transform: rotate(-90deg);
		}
	}

	.modal-header {
		background-color: $color-gray-light;
		height: 70px;

		.heading-3 {
			@include font-weight(semi-bold);
		}
	}

	.modal-body {
		background-color: $color-white;
	}

	.profile-title {
		@include font-weight(bold);
		@include text(iota);
		margin-bottom: 0.25rem;
	}

	.provider-profile-list {
		margin-bottom: 1.5rem;

		li {
			@include text(lambda);
			color: $color-gray-dark;
			margin-bottom: 0.25rem;

			a {
				@include text(lambda);
				color: $color-blue;
			}
		}
	}

	.input-icon-container {
		position: relative;
		margin-bottom: 2rem;

		input {
			@include text(kappa);
			background-color: $color-white;
			border: 2px solid $color-gray-neutral;
			height: 60px;
			margin-bottom: 0;
			padding: 1.125rem 1.25rem;
			text-align: left;
			width: 100%;

			&::placeholder {
				color: #999;
				text-align: left;
			}
		}

		.icon {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 1rem;
		}
	}

	.modal.visible,
	.modal.open {
		.accordion-item-body {
			display: block;
			padding: 0;
		}
	}

	@include breakpoint(md) {
		.accordion {
			.accordion-item {
				&:last-of-type {
					border-bottom: 2px solid $color-gray-neutral;
				}
			}

			.accordion-item-toggle {
				display: block;
				background-color: transparent;
				padding: 30px 0;
			}

			.toggle-container {
				@include text(theta, iota);
				@include font-weight(medium);
				padding: 0 20px 0 0;

				.icon {
					fill: $color-gray-dark;
					height: 1.5rem;
					width: 1.5rem;
				}
			}

			.accordion-item-body {
				padding: 0 0 30px;
			}
		}

		.input-icon-container {
			input {
				@include text(lambda);
				padding: 1.25rem;
			}
		}

		.modal {
			background-color: transparent;
			display: block;
			height: auto;
			opacity: 1;
			overflow: hidden;
			position: relative;
			z-index: 0;
		}

		.modal-container {
			max-height: none;
			padding: 0;
		}

		.modal-header {
			display: none;
		}

		.modal-inner {
			padding: 0;
		}

		.modal-body {
			background-color: transparent;
			overflow: hidden;
		}

		.modal-open-btn {
			display: none;
		}
	}

	@include breakpoint(lg) {
		&:before {
			height: 40px;
			margin-top: -40px;
		}
	}
}
