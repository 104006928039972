.title-banner {
	@include make-container;
	padding-bottom: 2rem;

	&.ready {
		.appointment-confirm {
			.appointment-soon {
				display: none;
			}

			.appointment-complete {
				display: inline;
			}
		}
	}

	.icon-success {
		height: 30px;
		width: 30px;
	}

	.back-link {
		@include font-weight(semi-bold);
		@include text(kappa, lambda);
		align-items: center;
		color: $color-gray-medium;
		display: inline-flex;
		text-decoration: none;
		margin-left: -2rem;
		margin-top: -1.5rem;

		svg {
			stroke: $color-gray-medium;
			margin-top: -3px;
			transition: all 0.2s ease-in-out;
		}

		&:hover,
		&:focus {
			svg {
				transform: translateX(-5px);
			}
		}
	}

	.title-container {
		margin-bottom: 1.8rem;
		margin-top: 2rem;
	}

	.title {
		@include font-weight(semi-bold);
		@include text(gamma);
		color: $color-red;
		margin-bottom: 2rem;
	}

	.title-info {
		@include font-weight(bold);
		@include text(iota);
		color: $color-black;
	}

	.confirm-container {
		flex-wrap: wrap;
		margin-bottom: 2.25rem;
	}

	.appointment-confirm {
		background-color: $color-white;
		flex-wrap: wrap;

		.row-primary {
			padding: 1.8rem 1.2rem;
		}

		.appointment-complete {
			display: none;
		}

		.appointment-time {
			@include font-weight(bold);
			@include text(iota);
			color: $color-gray-dark;
			margin-bottom: 0;
		}

		p {
			@include text(iota);
			color: $color-gray-dark;

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.banner-btn {
		@include font-weight(bold);
		@include text(iota, kappa);
		align-items: center;
		background-color: $color-blue-bright;
		color: $color-black;
		display: flex;
		margin-bottom: 1.25rem;
		padding: 30px 20px;
		width: 100%;
		text-decoration: none;

		&:hover,
		&:focus {
			background-color: $color-black;
			color: $color-white;
		}
	}

	.sections {
		background-color: $color-white;
		padding: 1.8rem 1.2rem;

		&.on-time {
			border-bottom: 4px solid $color-green-t3;

			.appointment-eta {
				background-color: $color-green-t3;
				color: $color-black;
			}

			.scheduled-time {
				color: $color-green-at;
			}
		}

		&.delayed {
			border-bottom: 4px solid $color-orange-delay;

			.appointment-eta {
				background-color: $color-orange-delay;
				color: $color-black;
			}

			.estimated-time,
			.delay {
				color: $color-orange-delay-alt;
			}

			.scheduled-time {
				@include text(mu);
				@include font-weight(normal);
				text-decoration: line-through;
			}
		}
	}

	.appointment-eta {
		@include text(kappa);
		@include font-weight(bold);
		align-items: center;
		display: flex;
		height: 30px;
		justify-content: center;
		margin: -1.8rem -1.2rem 1.8rem -1.2rem;
		width: calc(100% + 2.4rem);
	}

	.section-block {
		color: $color-black;
		margin-bottom: 2rem;

		a {
			@include font-weight(semi-bold);
			@include text(kappa, lambda);
			color: $color-blue;
			text-decoration: none;

			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	.section-block-inner {
		display: flex;
	}

	.section-block-img {
		height: 105px;
		min-width: 105px;
		overflow: hidden;
		position: relative;
		flex-basis: 105px;
		width: 105px;
		margin-right: 1rem;

		img {
			height: 100%;
			object-fit: cover;
			object-position: center 25%;
			font-family: 'object-fit: cover; object-position: center 25%;';
			width: 100%;
		}
	}

	.section-block-content {
		flex: 1;
	}

	.section-type {
		@include font-weight(normal);
		@include text(kappa, lambda);
		margin-bottom: 0.25rem;
	}

	.section-title,
	.appointment-type {
		@include font-weight(bold);
		@include text(kappa, lambda);
		margin-bottom: 0;

		.delay {
			@include text(lambda);
		}
	}

	.appointment-type {
		@include text(mu);
		color: $color-gray-medium-light;
		margin-bottom: 1.5rem;
		text-transform: capitalize;
	}

	.section-subtitle {
		@include font-weight(semi-bold);
		@include text(kappa, lambda);
		margin-bottom: 0;
	}

	.section-help {
		@include font-weight(semi-bold);
		@include text(kappa, lambda);
		margin-bottom: 0;
		margin-top: 1.5rem;
	}

	@include breakpoint(md) {
		margin-top: -2rem;

		.back-link {
			@include font-weight(bold);
			@include text(iota, kappa);

			svg {
				height: 70px;
				width: 70px;
			}

			&:hover,
			&:focus {
				svg {
					transform: translateX(-8px);
				}
			}

			+ .title-container {
				margin-top: 1rem;
			}
		}

		.title-container {
			display: flex;
			justify-content: space-between;
		}

		.title {
			margin-bottom: 0;
		}

		.title-info {
			text-align: right;
		}

		.confirm-container {
			.appointment-confirm {
				display: flex;
				flex-wrap: nowrap;

				.appointment-time,
				p {
					@include text(zeta);
				}

				.row-primary-button {
					margin: 0 0 0 auto;
					padding: 1rem 1.25rem;
					width: 285px;
				}
			}
		}

		.sections {
			display: flex;
			padding-top: 2.75rem;
			position: relative;

			&.on-time {
				border-top: 4px solid $color-green-t3;
			}

			&.delayed {
				border-top: 4px solid $color-orange-delay;
			}

			.section-block {
				flex: 0 0 50%;
				margin-bottom: 0;
				max-width: 50%;
			}
		}

		.appointment-eta {
			margin: -4px 0 0 -1.2rem;
			position: absolute;
			top: 0;
			width: auto;
			width: 95px;
		}

		.section-title {
			@include text(theta, iota);
		}

		.office-half {
			display: flex;

			> div {
				flex: 0 0 50%;
				max-width: 50%;
			}
		}
	}

	@include breakpoint(lg) {
		.back-link {
			margin-left: -70px;
		}
	}
}
