@mixin dot-pulse($color: $color-white) {
	position: relative;
	display: flex;
	width: 22px;
	align-items: center;
	justify-content: space-between;

	.dot,
	&::before,
	&::after {
		content: '';
		display: block;
		position: relative;
		top: 0;
		width: 6px;
		height: 6px;
		border-radius: 5px;
		background-color: $color;
		color: $color;
		animation: dotPulse 1.5s infinite linear;
		animation-delay: 0.25s;
	}

	&::before {
		animation-delay: 0s;
	}

	&::after {
		animation-delay: 0.5s;
	}

	@keyframes dotPulse {
		0% {
			transform: scale(0.5);
		}
		30% {
			transform: scale(1);
		}
		60%,
		100% {
			transform: scale(0.5);
		}
	}
}
