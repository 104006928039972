.security-requirements {
	padding-bottom: 5rem;

	.security-requirements-content {
		hr {
			background-color: $color-gray-neutral;
			border: none;
			color: $color-gray-neutral;
			height: 2px;
			margin-bottom: 1.5rem;
		}

		.btn-primary {
			display: block;
			margin: 1.5rem auto;
		}
	}
	@include breakpoint(md) {
		.security-requirements-content {
			hr {
				margin: 1.2rem 0px;
			}

			.btn-secondary {
				margin: 5rem 0 0;
				width: 100%;
			}

			.grid-row {
				@include make-row();
			}

			.grid-col {
				@include make-col-ready();
				@include make-col(4);
				height: 100%;
			}
		}
	}
}
