.typeahead-suggestion {
	@include text(lambda);
	@include font-weight(medium);
	display: flex;
	justify-content: space-between;
	padding: 1rem;

	&:hover {
		cursor: pointer;
	}

	&.active {
		background-color: $color-gray-providers;
	}

	&.disabled {
		font-style: italic;
	}

	mark {
		background-color: $color-yellow-highlight;
	}
}

.placeholder-container {
	position: relative;
	animation: fadein .5s;
	-moz-animation: fadein .5s; /* Firefox */
	-webkit-animation: fadein .5s; /* Safari and Chrome */
	-o-animation: fadein .5s; /* Opera */

	.filtertypeahead-placeholder {
		position: absolute;
		top: 5px;
		left: 7px;
		z-index: 2000;
		font-size: .7rem;
		color: gray;
	}
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@-moz-keyframes fadein {
	/* Firefox */
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@-webkit-keyframes fadein {
	/* Safari and Chrome */
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@-o-keyframes fadein {
	/* Opera */
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

#typeahead .active .typeahead-option {
	background-color: #eee !important;
}
