.hv-isc-listing-module {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-top: 2rem;
	border-bottom: 2px solid #e7e7e7;
	width: 100%;
	height: auto;

	h4 {
		@include text(iota);
		@include font-weight(bold);
		margin-bottom: 1.375rem;
	}

	.name-nct {
		@include text(lambda);
		margin: 0;
	}

	.updated {
		@include text(lambda);
		@include font-weight(bold);
		margin-bottom: 0.7rem;
	}

	.isc-location {
		margin-bottom: 0.7rem;
	}

	.pcc-name {
		@include text(kappa);
		@include font-weight(bold);
		margin: 0;
	}

	.albuquerque {
		@include text(kappa);
	}

	.description-row {
		@include text(kappa);
		display: none;
	}

	.description-col {
		@include text(kappa);
	}

	.left-side-listing {
		padding-left: 1.5rem;
		width: 100%;
		text-align: left;
	}

	.right-side-listing {
		text-align: left;
		padding-left: 1.5rem;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		padding-bottom: 2rem;
	}

	.c-trials-button {
		border-color: $color-blue-bright;
		color: black;
		border: 2px solid $color-blue-bright;
		background-color: white;
		padding-top: 1rem;
		padding-bottom: 1rem;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		margin-top: 0.5rem;
	}

	@include breakpoint(md) {
		flex-direction: row;

		.updated {
			margin-bottom: 1.5rem;
		}

		.isc-location {
			margin-bottom: 1.5rem;
		}

		.c-trials-button {
			margin-top: 1.5rem;
		}

		.description-row {
			display: block;
		}

		.description-col {
			display: none;
		}

		.left-side-listing {
			padding-left: 0;
			padding-right: 0.5rem;
			width: 65%;
			text-align: left;
		}

		.right-side-listing {
			padding-right: 0;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
		}
	}
}
