.pls-footer {
	background-color: $color-gray-dark;
	position: relative;

	&.fixed {
		.pls-footer-navigation {
			bottom: 0;
			position: fixed;
			top: auto;
			margin-bottom: 0;
			transition: margin-bottom 0.3s ease-in-out;
		}
	}

	.mobile-nav-open & {
		&.fixed {
			.pls-footer-navigation {
				margin-bottom: -100vh;
			}
		}
	}

	.pls-footer-navigation {
		@include font-weight(medium);
		background-color: $color-black;
		display: flex;
		width: 100%;

		.feedback-btn {
			color: $color-gray-neutral;
			display: block;
			padding: 1rem 0;
			text-align: left;
			width: 100%;

			span {
				@include make-container();
				align-items: center;
				display: flex;
			}

			&:hover,
			&:focus {
				background-color: $color-gray-neutral;
				color: $color-black;
			}
		}
	}

	@include breakpoint(md) {
		min-height: 160px;
	}
}
