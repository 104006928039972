.inaccuracy-form {
	.success {
		.success-notification-wrap {
			@include text(iota);
			background-color: $color-green-pale-alert;
			display: flex;
			margin: 1rem 0 2rem 0;
			padding: 1.25rem;

			.text-wrapper {
				margin-left: 1.25rem;
			}
		}
	}
	.errorName,
	.errorCheckbox,
	.errorEmail,
	.errorFail {
		.error-notification-wrap {
			@include text(iota);
			background-color: $color-red-light;
			display: flex;
			margin: 1rem 0 2rem 0;
			padding: 1.25rem;
		}
	}

	.icon {
		stroke-width: 0px;
	}

	.icon-phs-logo {
		fill: $color-red;
	}

	.icon-success,
	.error {
		//without this being min it was making it smaller on small screens.
		min-height: 22px;
		min-width: 22px;
		margin-right: 17px;
	}

	.icon-question {
		min-height: 19px;
		min-width: 19px;
	}

	.icon-arrow-down {
		min-height: 15px;
		min-width: 7.5px;
		stroke-width: 1px;
	}

	.break {
		flex-basis: 100%;
		height: 0;
	}

	.floating-label {
		@include text(lambda);
		color: $color-gray-medium-light;
		left: 1.05rem;
		opacity: 1;
		pointer-events: none;
		position: absolute;
		top: 4px;
	}

	.error-message {
		padding: 0 0 0.625rem 0;
	}

	.errorName {
		.text-wrapper-checkbox {
			display: none;
		}
		.text-wrapper-email {
			display: none;
		}
		.text-wrapper-name {
			margin-left: 1.25rem;
			display: flex;
		}
		.text-wrapper-fail {
			display: none;
		}
		.name-input {
			border-bottom: 2px solid $color-red !important;
		}
	}
	.errorCheckbox {
		.text-wrapper-checkbox {
			display: flex;
		}
		.text-wrapper-email {
			display: none;
		}
		.text-wrapper-name {
			margin-left: 1.25rem;
			display: none;
		}
		.text-wrapper-fail {
			display: none;
		}
	}

	.in-progress {
		.in-progress-notification-wrap {
			@include text(iota);
			background-color: $color-neutral-yellow;
			display: flex;
			margin: 1rem 0 2rem 0;
			padding: 1.25rem;

			.text-wrapper {
				margin-left: 1.25rem;
			}
		}
	}

	.errorEmail {
		.text-wrapper-checkbox {
			margin-left: 1.25rem;
			display: none;
		}
		.text-wrapper-email {
			margin-left: 1.25rem;
			display: flex;
		}

		.text-wrapper-fail {
			display: none;
		}
		.text-wrapper-name {
			display: none;
		}
		.email-input {
			border-bottom: 2px solid $color-red !important;
		}
	}

	.errorName.errorEmail {
		.text-wrapper-checkbox {
			margin-left: 1.25rem;
			display: none;
		}
		.text-wrapper-email {
			margin-left: 1.25rem;
			display: flex;
		}
		.text-wrapper-name {
			display: flex;
		}

		.text-wrapper-fail {
			display: none;
		}
		.email-input {
			border-bottom: 2px solid $color-red !important;
		}
		.name-input {
			border-bottom: 2px solid $color-red !important;
		}
	}

	.errorFail {
		.text-wrapper-name {
			display: none;
		}
		.text-wrapper-email {
			display: none;
		}

		.text-wrapper-fail {
			margin-left: 1.25rem;
			display: flex;
		}
	}

	.success-notification-wrap,
	.error-notification-wrap,
	.in-progress-notification-wrap {
		display: none;
	}

	.clear-btn {
		color: $color-blue;
		text-decoration: none;
		margin: auto calc(50% - 24px);
	}

	.content-col {
		margin-left: -15px;
	}

	.checkbox-wrapper {
		@include text(lambda, mu);
		width: 49%;
		input[type='checkbox'] {
			height: 1.5rem;
			margin-top: 2px;
			opacity: 0;
			width: 1.5rem;
			border: 1px solid $color-gray-neutral;
			+ {
				label {
					&:before {
						background-color: $color-white;
						border: 1px solid $color-gray-medium-light;
						border-radius: 2px;
						content: '';
						display: block;
						height: 1.5rem;
						left: 0;
						position: absolute;
						top: 0;
						width: 1.5rem;
					}
				}
			}
		}
	}

	.inaccuracy-list-btn {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		column: 2;
		width: 100%;
	}

	.hide-disable {
		display: none;
	}

	.input-field {
		max-width: 100%;
		margin-bottom: 20px;
		justify-content: center;
		position: relative;
		max-width: 100%;
		textarea.text-box._ar_hide_ {
			@include text(iota, lambda);
			background-color: $color-gray-light;
			border: 1px solid $color-gray-light;
			height: 200px;
			padding: 1.5rem 1.05rem;
			resize: none;
			width: 20rem;
		}
		& :focus {
			border-bottom: 1px solid #999999 !important;
		}
		& :focus-visible {
			border-bottom: 1px solid #999999 !important;
		}
		input.name-input._ar_hide_,
		input.email-input._ar_hide_ {
			background-color: $color-gray-light;
			border: 1px solid $color-gray-light;
			color: $color-black;
			padding-top: 1.8rem;
			text-align: left;
		}
		& :focus {
			border-bottom: 1px solid #999999 !important;
		}
		span.floating-label {
			@include text(lambda, lambda);
			color: $color-gray-medium-light;
			left: 1.05rem;
			opacity: 1;
			pointer-events: none;
			position: absolute;
			top: 4px;
		}
		.text-box {
			@include text(iota);
			background-color: $color-gray-light;
			border: 1px solid $color-gray-light;
			height: 200px;
			padding: 1.5rem 1.05rem;
			resize: none;
			width: 100%;

			&:focus-visible {
				outline: none;
			}
		}

		.text-counter-wrapper {
			@include text(lambda);
			color: $color-gray-medium-light;
			padding: 0 0 1.625rem 1.25rem;
		}
	}

	.inaccuracyform-cta {
		display: block;
		margin-top: 10px;
		.submit-button.send-button {
			background-color: $color-gray-dark;
			border: 1px solid $color-gray-neutral;
			color: $color-white;
			margin-bottom: 1.5rem;
			min-width: 192px;
			margin-right: 57px;
		}
		button.btn.btn-primary.submit-button.send-button[disabled] {
			background-color: #bfbfbf;
		}

		.cancel-button {
			background-color: $color-white;
			border: 1px solid $color-gray-neutral;
			color: $color-gray-medium-light;
		}
	}
	input.email-input._ar_hide_:focus {
		border-bottom: 1px solid #999999 !important;
	}

	@include breakpoint(sm) {
		.input-field {
			textarea.text-box._ar_hide_ {
				width: 44rem;
			}
		}
		.inaccuracy-form .inaccuracyform-cta {
			display: flex;
		}
	}
}
