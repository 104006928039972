html,
body {
	@include font-weight(normal);
	color: $color-black;
	-webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
	-webkit-font-smoothing: antialiased; /* Makes the font look lighter in weight, and makes edges appear less fuzzy. This makes text better match the XD design files. */
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
}

h1,
.heading-1 {
	@include text(gamma);
	@include font-weight(normal);
	margin-bottom: 2.25rem;
}

h2,
.heading-2 {
	@include text(gamma);
	@include font-weight(normal);
	margin-bottom: 2.25rem;
}

h3,
.heading-3 {
	@include text(delta);
	@include font-weight(medium);
	margin-bottom: 2.25rem;
}

h4,
.heading-4 {
	@include text(epsilon);
	@include font-weight(normal);
	margin-bottom: 1.875rem;
}

h5,
.heading-5 {
	@include text(zeta);
	@include font-weight(normal);
	margin-bottom: 1.875rem;
}

h6,
.heading-6 {
	@include text(iota, 23);
	@include font-weight(normal);
	margin-bottom: 0.875rem;
}

p,
.body-copy {
	@include text(eta);
	@include font-weight(normal);
	margin-bottom: 1.5rem;
}

a {
	color: $color-black;
	text-decoration: underline;

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

address {
	@include text(theta);
	@include font-weight(normal);
	font-style: normal;
}

time {
	@include text(theta);
	@include font-weight(normal);
}

strong {
	@include font-weight(bold);
}

@include breakpoint(md) {
	h1,
	.heading-1 {
		@include text(alpha);
		margin-bottom: 2.75rem;
	}

	h2,
	.heading-2 {
		@include text(gamma);
		margin-bottom: 2.25rem;
	}

	h3,
	.heading-3 {
		@include text(delta, gamma);
		margin-bottom: 2.25rem;
	}

	h4,
	.heading-4 {
		@include text(epsilon);
		margin-bottom: 1.875rem;
	}

	h5,
	.heading-5 {
		@include text(zeta);
		margin-bottom: 1.875rem;
	}

	h6,
	.heading-6 {
		@include text(eta);
		margin-bottom: 0.875rem;
	}
}

@include breakpoint(xxl) {
	h1,
	.heading-1 {
		@include text(alpha);
		margin-bottom: 3rem;
	}

	h2,
	.heading-2 {
		@include text(beta);
		margin-bottom: 2.75rem;
	}

	h3,
	.heading-3 {
		@include text(gamma);
		margin-bottom: 2.25rem;
	}

	h4,
	.heading-4 {
		@include text(delta);
		margin-bottom: 1.875rem;
	}

	h5,
	.heading-5 {
		@include text(epsilon);
		margin-bottom: 1.875rem;
	}

	h6,
	.heading-6 {
		@include text(zeta);
		margin-bottom: 0.875rem;
	}

	p,
	.body-copy {
		@include text(epsilon);
		@include font-weight(normal);
		margin-bottom: 1.5rem;
	}
}
