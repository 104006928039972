.secure-message-verify-modal {
	.ngde-form {
		justify-content: center;
	}

	.heading-1 {
		color: $color-black;
	}

	.modal-inner {
		display: flex;
		justify-content: center;
	}
}

.redirecting-icon-container {
	.redirecting-loader {
		position: relative;
		height: 3.5rem;
		width: 3.5rem;
		display: inline-block;
		animation: around 5.4s infinite;
	}

	.redirecting-loader::after,
	.redirecting-loader::before {
		content: '';
		background: white;
		position: absolute;
		display: inline-block;
		width: 100%;
		height: 100%;
		border-width: 2px;
		border-color: #333 #333 transparent transparent;
		border-style: solid;
		border-radius: 40px;
		box-sizing: border-box;
		top: 0;
		left: 0;
		animation: around 0.7s ease-in-out infinite;
	}

	.redirecting-loader::after {
		animation: around 0.7s ease-in-out 0.1s infinite;
		background: transparent;
	}
}

@keyframes around {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
