.styleguide {
	main {
		section {
			margin: 4rem auto;
		}
	}

	hr {
		margin: 1.5rem 0;
	}

	.table-of-contents {
		a {
			@include text(eta);
		}
	}

	.color-box-list {
		display: flex;
		justify-content: space-evenly;
		flex-wrap: wrap;
		flex-direction: column;
	}

	.color-box {
		color: $color-white;
		height: 250px;
		padding-top: 1rem;
		text-align: center;
	}

	@include breakpoint(sm) {
		.color-box-list {
			flex-direction: row;
		}

		.color-box {
			width: calc(100% / 2);
		}
	}

	@include breakpoint(md) {
		.color-box {
			height: 324px;
			width: calc(100% / 3);
		}
	}

	@include breakpoint(lg) {
		.color-box {
			height: 400px;
			width: calc(100% / 6);
		}
	}
}
