.heart-and-vascular {
	a {
		color: $color-blue;
		text-decoration: underline;
	}

	&.navbar-fixed {
		padding-top: 60px;
		transition: margin-top 0.3s ease-in-out;

		&.mobile-nav-open {
			margin-top: 100vh;
		}
	}

	&.navbar-scroll-up {
		padding-top: 60px;
	}

	section {
		padding-bottom: 0.75rem;
	}

	@include breakpoint(md) {
		a {
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	@media (min-width: $md-lg-breakpoint) {
		&.navbar-fixed,
		&.navbar-scroll-up {
			padding-top: 0;
			transition: none;
		}
	}
}
