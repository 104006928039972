.ngde-chat-box {
	display: none;
	left: 0;
	opacity: 0;
	outline: 0;
	position: fixed;
	top: 0;
	transition: opacity 0.3s ease-in-out;
	width: 100%;
	z-index: 10;

	&.visible {
		display: block;
		background-color: $color-white;
	}

	&.open {
		opacity: 1;
	}

	.modal-header {
		background-color: $color-gray-light;
		display: flex;
		min-height: 60px;
		align-items: center;

		.container {
			display: flex;
			justify-content: space-between;
		}

		.heading-3 {
			@include text(iota, 18);
			@include font-weight(bold);
			margin-bottom: 0;
		}
	}

	.modal-inner,
	.iframe-container {
		height: calc(100vh - 60px);
	}

	.modal-row {
		height: calc(100vh - 60px);
		width: 100%;

		.modal-inner {
			height: calc(100vh - 60px);
			width: 100%;
		}
	}

	@include breakpoint(lg) {
		&.visible {
			display: block;
			background-color: transparent;
		}

		.modal-container {
			bottom: 0;
			background-color: $color-white;
			height: 545px;
			position: fixed;
			pointer-events: none;
			overflow: hidden;
			right: 0;
			//width: 1000px;
		}

		.modal-inner-container {
			//	border: 1px solid #999;
			display: inline-block;
			vertical-align: bottom;
			height: 300px;
			//	position: relative;
			pointer-events: auto;
			width: 500px;
			overflow: none;
		}
	}
}
