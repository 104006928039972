.hero {
	color: $color-gray-dark;
	height: 100vh;
	min-height: 700px;
	text-align: center;

	p {
		margin-bottom: 0px;
	}

	.hero-background {
		background: url('../images/light-bg-image.png');
		background-position: center bottom;
		background-repeat: no-repeat;
		background-size: cover;
		height: 100%;
		mix-blend-mode: darken;
		width: 100%;
	}

	.hero-content {
		//Consider using make-container here
		padding: 60px 30px 0px 30px;
	}

	.message {
		@include text(kappa);
		margin-bottom: 1.5rem;
	}

	.main-title {
		@include text(beta);
		@include font-weight(light);
		color: $color-red;
		margin-bottom: 1.875rem;
	}

	.scroll-btn {
		.icon {
			height: 19px;
			margin-bottom: 1.5rem;
			stroke: $color-red;
			stroke-width: 3px;
			width: 40px;
		}

		.label {
			@include text(lambda);
			color: $color-red;
			display: block;
			text-transform: uppercase;
		}
	}

	.sub-title {
		@include text(kappa);
		@include font-weight(extra-bold);
		margin-bottom: 2.625rem;
		text-transform: uppercase;
	}

	@include breakpoint(md) {
		.hero-background {
			background-image: url('../images/light-bg-image-2x.png');
		}

		.message {
			@include text(theta);
		}
		.main-title {
			@include text(
				alpha
			); //this needs to be 70 but need some more designs to figure out typography sizes
		}
		.sub-title {
			@include text(delta);
		}
	}
}
