.nav-link {
	text-decoration: none;
	display: inline-block;
	position: relative;
	color: $color-gray-dark;
	padding: 5px 18px;

	&::after {
		content: '';
		opacity: 0;
		position: absolute;
		display: block;
		height: 1px;
		width: 20px;
		bottom: 0;
		left: 40px;
		background-color: $color-red;
		transition: left 0.25s ease-in-out, opacity 0.25s ease-out;
	}

	&:hover,
	&:active,
	&:focus,
	&.active {
		color: $color-black;

		&::after {
			opacity: 1;
			left: 18px;
		}
	}

	&.alt {
		color: $color-orange-dark;

		&:hover,
		&:active,
		&:focus,
		&.active {
			color: $color-orange;
		}

		&::after {
			background-color: $color-black;
		}
	}

	&.active {
		&::after {
			display: none;
		}
	}
}
