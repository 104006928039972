.tag-list {
	margin-bottom: 0.75rem;

	.tag-list-title {
		@include text(mu);
		color: $color-gray-medium;
		display: block;
		margin-bottom: 0.5rem;
	}

	.tag {
		@include text(mu);
		@include font-weight(medium);
		background-color: $color-white;
		color: #6f6f6f;
		display: inline-block;
		padding: 0.5rem 0.625rem;
		margin: 0 0.5rem 0.5rem 0;

		span {
			display: inline-flex;
		}

		.tag-remove-btn {
			@include text(zeta);
			@include font-weight(bold);
			background: none;
			border: none;
			margin-left: 0.5rem;
			padding: 0;

			.icon {
				align-self: center;
				display: flex;
				stroke: $color-gray-medium;
				height: 0.625rem;
				transform: translateY(-1px);
				width: 0.625rem;
			}

			&:hover,
			&:focus {
				outline: 0;

				.icon {
					stroke: $color-gray-darkest;
					stroke-width: 4px;
				}
			}
		}

		&.star-tag {
			padding: 0.5rem 0.625rem 6px;
			transform: translateY(2px);
		}

		.star-tag-span {
			height: 18px;
		}

		.rating-stars {
			width: 80px;

			.star {
				height: 14px;
				width: 14px;

				svg {
					height: 14px;
					width: 14px;
				}

				.star-fill {
					height: 14px;
				}
			}
		}
	}
}
