.mobile-nav {
	background-color: $color-white;
	display: none;
	height: 100vh;
	margin-top: -100vh;
	overflow: auto;
	transition: margin-top 0.3s ease-in-out;
	width: 100vw;

	&.visible {
		display: block;
	}

	.mobile-nav-open & {
		margin-top: 0;
		position: fixed;
		z-index: 11;
	}

	.mobile-nav-container {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
		padding-top: $sm-grid-outer-padding;

		.at-footer-container,
		.pls-footer-container {
			padding-bottom: 10rem; // account for mobile browser bottom UI chrome
		}
	}

	.mobile-nav-logo {
		@include make-container();
		align-items: flex-start;
		display: flex;
		justify-content: space-between;
		margin-bottom: 1.25rem;
		width: 100%;

		.logo-container {
			height: auto;
			padding-top: 0.625rem;
		}

		.icon-phs-logo {
			fill: $color-red;
			height: calc(40.717px * 0.793);
			max-width: calc(353px * 0.793);
			width: 100%;
		}

		.logo-link {
			&:focus {
				outline: 0;
			}
		}

		.logo-subtitle {
			@include text(iota);
			@include font-weight(light);
			display: block;
			margin-bottom: 0;
			padding-left: 2.125rem;
		}
	}

	.logo-container {
		height: auto;
		padding-right: 1rem;
	}

	.mobile-nav-close {
		display: flex;
	}

	.primary-nav {
		@include make-container();
		flex-grow: 1;
	}

	.nav-links {
		list-style: none;
		margin-bottom: 0.5rem;
		padding: 0;

		li {
			display: block;
			margin: 0;
			padding-bottom: 0.5rem;

			&:before {
				display: none;
			}

			&.active {
				position: relative;

				a,
				button {
					color: $color-blue;
					position: relative;
					text-decoration: none;

					&::after {
						background-color: $color-red;
						bottom: 0;
						content: '';
						display: none;
						height: 1px;
						left: 0;
						opacity: 1;
						position: absolute;
						width: 20px;
					}
				}
			}

			a,
			button {
				@include text(kappa);
				color: $color-black;
				display: block;
				outline: 0;
				padding: 1.25rem 0 0.75rem;
				text-decoration: none;

				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}

			.message-badge {
				position: relative;
				top: -0.35rem;
				right: 0.2rem;
				padding: 0rem 0.3rem;
				border-radius: 100%;
				background: red;
				color: $color-red-light;
			}
		}
	}

	.t3-mobile-nav-footer {
		@include make-container();
		background-color: $color-gray-dark;
		margin-top: auto;
		padding-bottom: 8rem;
		padding-top: 2rem;

		.help-title {
			color: $color-blue-bright;
		}

		.link-list {
			margin-bottom: 2rem;
		}

		p {
			@include text(kappa);
			color: $color-white;

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		a {
			color: $color-white;
		}
	}

	@include breakpoint(lg) {
		display: none;

		&.visible {
			display: none;
		}
	}
}
