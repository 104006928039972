.action-block {
	@include text(lambda);
	@include font-weight(normal);
	align-items: center;
	background-color: $color-gray-light;
	color: $color-black;
	display: block;
	margin: 0.25rem 0;
	padding: 10px 20px;
	text-align: left;
	width: 100%;

	.secondary-title {
		@include text(mu);
		@include font-weight(normal);
		align-items: center;
		color: $color-blue;
		display: flex;
		pointer-events: none;

		.icon {
			height: 0.75rem;
			margin-left: 0.5rem;
			stroke: $color-blue;
			width: 0.75rem;
		}

		button {
			pointer-events: auto;
		}
	}

	&:hover,
	&:focus {
		.secondary-title {
			text-decoration: underline;
		}
	}

	&.transparent-bg {
		background-color: transparent;
	}

	&.links-block {
		a {
			@include text(lambda);
			@include font-weight(normal);
			color: $color-blue;
			margin-bottom: 1rem;

			&:hover,
			&:focus {
				color: $color-blue;
				text-decoration: underline;
			}
		}

		&:hover,
		&:focus {
			.secondary-title {
				text-decoration: none;
			}
		}
	}

	@include breakpoint(md) {
		display: flex;
		justify-content: space-between;

		&.links-block {
			a {
				margin-bottom: 0;
			}
		}
	}
}
