.hv-providers {
	.provider-result {
		text-decoration: none;
	}

	@include breakpoint(md) {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.hv-rail & {
			display: block;

			.provider-result {
				display: flex;
				background-color: transparent;
				margin-bottom: 0;
				padding-bottom: 1rem;

				.profile-image-outer,
				.profile-image-container {
					width: 65px;
				}

				.provider-details {
					padding: 0 0 0 10px;
					width: auto;
					justify-content: flex-start;
				}

				.provider-name {
					@include text(lambda);
				}

				.detail-top {
					.group-and-specialty {
						@include text(mu);
						flex-basis: 100%;
					}
				}

				.ratings {
					margin-top: 0.375rem;

					.rating-stars {
						width: 70px;
					}

					.star {
						width: 11px;
						height: 11px;
						// margin-right: 0.125rem;

						svg {
							height: 11px;
							width: 11px;
						}

						.star-fill {
							height: 11px;
						}
					}

					.average-rating,
					.rating-count {
						display: none;
					}
				}
			}
		}

		.provider-result {
			flex: 0 1 calc(100% / 2 - 0.5rem);

			&:only-child {
				flex: 1;
			}

			&:hover,
			&:focus {
				.provider-name {
					text-decoration: underline;
				}
			}
		}
	}

	@media (min-width: $md-lg-breakpoint) {
		.hv-rail & {
			.provider-result {
				.profile-image-outer,
				.profile-image-container {
					width: 75px;
				}

				.provider-details {
					padding: 0 20px;
				}
			}
		}
	}
}
