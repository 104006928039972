body {
	background-color: $color-white;
	overflow-x: hidden;

	main {
		background-color: $color-white;
		min-height: 300px;
	}

	@include breakpoint(md) {
		main {
			min-height: calc(100vh - 250px);
		}
	}
}
