.tooltip {
	position: relative;

	&:focus,
	&:hover {
		&:before,
		&:after {
			display: block;
			/* Older Edge/IE will only set value none to pseudo-element attributes if it was declared previously */
			text-decoration: underline;
		}

		&:before,
		&:after {
			text-decoration: none;
		}
	}

	&:before,
	&:after {
		display: none;
		line-height: 1;
		opacity: 0;
		pointer-events: none;
		position: absolute;
		text-transform: none;
		user-select: none;
	}
	&:before {
		border: 5px solid transparent;
		content: '';
		z-index: 99;
	}
	&:after {
		@include text(mu);
		@include font-weight(normal);
		background: $color-gray-dark;
		border-radius: 0.3ch;
		box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.35);
		color: $color-white;
		content: attr(data-tooltip);

		/*
			Let the content set the size of the tooltips
			but this will also keep them from being obnoxious
			*/
		max-width: 21em;
		min-width: 16em;
		overflow: hidden;
		padding: 1ch 1.5ch;
		text-align: center;
		white-space: normal;
		z-index: 100;
	}

	[tooltip='']:before,
	[tooltip='']:after {
		display: none !important;
	}

	/* FLOW: UP */
	&:not([flow]):before,
	&[flow^='up']:before {
		border-bottom-width: 0;
		border-top-color: $color-gray-dark;
		bottom: 100%;
	}
	&:not([flow]):after,
	&[flow^='up']:after {
		bottom: calc(100% + 5px);
	}
	&:not([flow]):before,
	&:not([flow]):after,
	&[flow^='up']:before,
	&[flow^='up']:after {
		left: 50%;
		transform: translate(-50%, -0.5em);
	}

	/* FLOW: DOWN */
	&[flow^='down']:before {
		border-bottom-color: $color-gray-dark;
		border-top-width: 0;
		top: 100%;
	}
	&[flow^='down']:after {
		top: calc(100% + 5px);
	}
	&[flow^='down']:before,
	&[flow^='down']:after {
		left: 50%;
		transform: translate(-50%, 0.5em);
	}

	/* FLOW: LEFT */
	&[flow^='left']:before {
		border-left-color: $color-gray-dark;
		border-right-width: 0;
		left: calc(0em - 5px);
		top: 50%;
		transform: translate(-0.5em, -50%);
	}
	&[flow^='left']:after {
		right: calc(100% + 5px);
		top: 50%;
		transform: translate(-0.5em, -50%);
	}

	/* FLOW: RIGHT */
	&[flow^='right']:before {
		border-left-width: 0;
		border-right-color: $color-gray-dark;
		right: calc(0em - 5px);
		top: 50%;
		transform: translate(0.5em, -50%);
	}
	&[flow^='right']:after {
		left: calc(100% + 5px);
		top: 50%;
		transform: translate(0.5em, -50%);
	}

	/* KEYFRAMES */
	@keyframes tooltips-vert {
		to {
			opacity: 0.95;
			transform: translate(-50%, 0);
		}
	}

	@keyframes tooltips-horz {
		to {
			opacity: 0.95;
			transform: translate(0, -50%);
		}
	}

	/* FX All The Things */
	&:not([flow]):focus:before,
	&:not([flow]):focus:after,
	&[flow^='up']:focus:before,
	&[flow^='up']:focus:after,
	&[flow^='down']:focus:before,
	&[flow^='down']:focus:after,
	&:not([flow]):hover:before,
	&:not([flow]):hover:after,
	&[flow^='up']:hover:before,
	&[flow^='up']:hover:after,
	&[flow^='down']:hover:before,
	&[flow^='down']:hover:after {
		animation: tooltips-vert 300ms ease-out forwards;
	}
	&[flow^='left']:focus:before,
	&[flow^='left']:focus:after,
	&[flow^='right']:focus:before,
	&[flow^='right']:focus:after,
	&[flow^='left']:hover:before,
	&[flow^='left']:hover:after,
	&[flow^='right']:hover:before,
	&[flow^='right']:hover:after {
		animation: tooltips-horz 300ms ease-out forwards;
	}
}

.rating-icon-info-alert.tooltip {
	&:after {
		max-width: 34em;
		min-width: 12em;
		text-align: left;
	}
}

@include breakpoint(md) {
	.rating-icon-info-alert.tooltip {
		&:after {
			max-width: 34em;
			min-width: 33em;
			text-align: left;
		}
	}
}
