.centennial-card {
	padding: 20px;

	h2 {
		margin-bottom: 0.25rem;
	}

	h3 {
		margin-bottom: 0.5rem;
	}

	.pcp-info {
		display: flex;
		width: 100%;
		justify-content: space-between;
	}
}
