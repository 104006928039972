.way-finder {
    margin-top: 4rem;

    .grid-row {
        @include make-row();
        justify-content: center;
    }

    .header-block {
        padding-left: 1.2rem;

        .language {
            display: flex;
            justify-content: right;
        }
    }

    .inner-block {
        background-color: $color-gray-light;
        padding: 1.4rem 2.2rem;

        .description {
            @include text(theta);
            @include font-weight(bold);
            color: $color-gray-dark;
        }

        padding-bottom: 0px;
    }

    .helpertext {
        margin-left: auto;
        margin-right: auto;
        padding-top: 4rem;
        width: 100%;
        text-align: center;
        font-size: 0.875rem;
        font-family: 'AvenirMedium';

        i:first-of-type {
            font-weight: bolder;
        }

        display: flex;
        gap: 10px;
        flex-direction: column;
    }

    .link-card {
        @include text(lambda);
        background-color: $color-white;
        margin: 1.2rem 0;
        padding: 1rem 1.8rem;

        .btn-primary {
            @include text(lambda);
            @include font-weight(normal);
            background-color: $color-gray-dark;
            color: $color-white;
            padding: 1.125rem;
            white-space: normal;
            width: 100%;

            &:hover {
                text-decoration: none;
                background-color: $color-black;
            }
        }

        p {
            @include text(lambda);
        }

        .icon {
            border: $color-green-t3;
            display: block;
            fill: $color-red;
            height: 6rem;
            margin: 1rem auto;
            strokes: transparent;
            stroke-width: 0px;
            width: 6rem;
        }
    }

    @include breakpoint(md) {
        @include make-container();
        margin-top: 5rem;
        margin-bottom: 5rem;

        .header-block {
            padding-left: 0;
        }

        .inner-block {
            background-color: $color-gray-light;
            padding: 3.8rem 1.2rem 8.2rem;
            padding-bottom: 0px;
        }

        .link-card {
            background-color: $color-white;
            margin: 2rem 1.2rem;
            padding: 1rem;
        }
    }

    @include breakpoint(lg) {
        margin-top: 3rem;
    }
}
