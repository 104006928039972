.feedback-banner {
	background-color: $color-blue-highlight;
	margin-bottom: 1rem;

	&.closed {
		display: none;
	}

	&.error {
		background-color: $color-red-light;
	}

	&.success {
		background-color: $color-success;
	}

	.hv-hero + & {
		margin-top: -1rem;
	}

	.banner-container {
		@include make-container();
		align-items: flex-start;
		display: flex;
		padding-bottom: 0.75rem;
		padding-top: 0.75rem;
	}

	.icon {
		height: 20px;
		width: 20px;
	}

	.icon-container {
		display: flex;
	}

	.icon-information {
		stroke-width: 0;
	}

	.banner-text {
		padding: 0 0.75rem;
	}

	.banner-heading {
		@include text(kappa);
		@include font-weight(bold);
		color: $color-black;
		margin-bottom: 0.25rem;
	}

	p {
		@include text(kappa);
		@include font-weight(semi-bold);
		color: $color-black;

		&:last-of-type {
			margin-bottom: 0;
		}

		a {
			color: $color-blue;
		}
	}

	a {
		@include text(kappa);
		@include font-weight(semi-bold);
		color: $color-blue;
	}

	.banner-close {
		align-items: center;
		display: flex;

		&:hover,
		&:focus {
			.icon {
				stroke: $color-gray-medium;
			}
		}
	}

	.icon-modal-close {
		height: 12px;
		stroke: $color-black;
		stroke-width: 3px;
		width: 12px;
	}

	@include breakpoint(md) {
		.banner-container {
			position: relative;
		}

		.banner-close {
			position: absolute;
			right: 9px;
		}
	}

	@include breakpoint(lg) {
		.hv-hero + & {
			margin-top: -3rem;
		}

		.banner-container {
			padding-bottom: 0.5rem;
			padding-top: 0.5rem;
		}

		.banner-text {
			display: flex;
			margin-right: 4rem;
		}

		.banner-heading {
			flex-shrink: 0;
			margin-bottom: 0;
			margin-right: 0.5rem;
		}

		.banner-close {
			margin-top: 0.25rem;
			right: 29px;
		}
	}
}
