.t3-main-navigation {
	display: flex;

	.modal-open & {
		display: none;
	}

	.icon-menu-open {
		fill: $color-gray-dark;
	}

	.icon-menu-close {
		position: relative;
		stroke: $color-gray-dark;
		stroke-width: 3px;
	}

	.full-nav {
		display: none;
	}

	.nav-open,
	.nav-close {
		background-color: transparent;
		border: 0;
		line-height: 0;
		padding: 0;
	}

	@media (min-width: $mobile-nav-breakpoint) {
		align-self: flex-end;

		.full-nav {
			align-self: flex-start;
			display: flex;
			overflow: hidden;
			padding-top: 16px;
		}

		.nav-open {
			display: none;
		}

		.nav-link {
			&::after {
				background-color: $color-blue-bright;
			}

			&.active {
				color: $color-blue;
			}

			&:last-of-type {
				padding-right: 0;
			}
		}
	}
}
