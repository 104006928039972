/*
 *
 * BREAKPOINTS MAP
 *
 */

$breakpoints: (
	xss: 340px,
	xs: 360px,
	sm: 414px,
	sb: 514px,
	md: 768px,
	lg: 1280px,
	xl: 1440px,
	xxl: 1680px,
);

$print-breakpoints: (
	xs: 3px,
	sm: 5px,
	md: 6px,
	lg: 7px,
	xl: 1440px,
	xxl: 1680px,
);
$lg-xl-breakpoint: 1440px;
$md-lg-breakpoint: 1024px;
$mobile-nav-breakpoint: 890px;
