.pagination-nav {
	display: flex;
	justify-content: center;
	margin: 2.6rem 0 15px;

	.pagination {
		align-items: center;
		display: flex;
	}

	.page-item {
		align-items: center;
		display: flex;

		&.active {
			.page-link {
				color: $color-red;
			}
		}

		&.disabled {
			pointer-events: none;
			color: $color-gray-light;

			svg {
				stroke: $color-gray-light;
			}
		}
	}

	.page-link {
		@include text(kappa);
		@include font-weight(bold);
		padding: 0.625rem;
		line-height: 1;

		&:hover {
			color: $color-gray-neutral;

			svg {
				stroke: $color-gray-neutral;
			}
		}

		.left {
			transform: rotate(180deg);
		}
	}
}
