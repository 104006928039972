.portal {
	.notification {
		background-color: $color-info;
		display: flex;
		margin: 1rem -1.875rem 2rem -1.875rem;
		padding: 1.25rem 1.875rem;

		.notification-icon {
			display: flex;
			margin-right: 0.625rem;
		}

		.icon-info-alert {
			height: 19px;
			width: 19px;
		}

		.notification-content {
			align-items: flex-start;
			display: flex;
			flex-direction: column;
		}

		.btn-link {
			display: inline-block;
			font-size: inherit;
			line-height: inherit;
			margin-bottom: 0;
			margin-top: 0.5rem;
			text-decoration: underline;
		}

		@include breakpoint(md) {
			align-items: center;
			margin: 1rem 0 2rem;

			.notification-content {
				flex-direction: row;
				justify-content: center;
			}

			.btn-link {
				margin-left: 0.625rem;
				margin-top: 0;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}
