.my-care-resources {
	margin-top: 0rem;

	.description {
		margin-bottom: 1rem;
	}

	.sub-header {
		margin-top: 1rem;
		margin-bottom: 0rem;
	}
	.helpertext {
		padding-top: 0rem;
		padding-bottom: 1rem;
		width: 100%;
		text-align: left;
		font-size: 0.875rem;
		font-weight: normal;
		font-style: italic;
		font-family: 'AvenirMedium';
	}
	.footer {
		margin-top: 2rem;
	}

	.action-section {
		margin-bottom: 0.5rem;

		.btn-link {
			background-color: $color-gray-dark;
			border-color: $color-gray-dark;
			color: $color-white;
			font-weight: normal;
			margin-top: 1rem;
			margin-bottom: 0.5rem;
			width: 100%;
			&:hover {
				text-decoration: none;
				background-color: $color-gray-dark;
				color: $color-white;
			}
		}
	}

	.link-box {
		background-color: $color-white;
		border: 1px solid grey;
		margin: 0.5rem 0.5rem 0rem 0.5rem;
		padding: 1rem;

		p {
			margin-bottom: 0rem;
			font-size: 1rem;
			line-height: 1.375;
			font-family: AvenirMedium, Helvetica, sans-serif;
			font-weight: normal;
			color: #363534;
		}
	}

	@include breakpoint(md) {
		//@include make-container();
		margin-top: 0rem;
		margin-bottom: 0rem;

		.link-box {
			background-color: $color-white;
			margin: 0.5rem 0.5rem 0rem 0.5rem;
			padding: 1rem;
		}
	}

	@include breakpoint(lg) {
		margin-top: 0rem;
	}
}
