.ngde-my-profile {
	@include make-container();
	padding-bottom: 100px;
	padding-top: 200px;

	@include breakpoint(lg) {
		padding-top: 300px;

		.card-action-wrap {
			text-align: left;
			.card-action {
				text-decoration: none;
			}
		}
	}

	.paperless-preference-member-email {
		font-weight: bold;
	}
}

.preferences-modal {
	.btn-primary {
		background-color: $color-black;
		color: $color-white;
	}
	.modal-container {
		.modal-inner-container {
			border-top: 0;
		}
	}
}
