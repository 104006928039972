.tabs {
	.tabs-header {
		background-color: $color-off-white;
		margin-top: 24px;
	}

	.tab-list {
		display: flex;
	}

	.tab-btn {
		@include font-weight(bold);
		@include text(kappa, 16);
		background: $color-off-white;
		border: none;
		color: $color-gray-medium;
		display: flex;
		flex: 1 1 0;
		padding: 1.5rem 1.25rem 2.25rem;

		&:hover,
		&:focus {
			background: $color-gray-medium;
			color: $color-white;
		}

		&.active {
			background: $color-white;
			border-top: 4px solid $color-black;
			color: $color-black;
			margin-top: -24px;
			pointer-events: none;

			&.complete {
				border-top-color: $color-green-t3;
			}

			&.has-cta {
				border-top-color: $color-blue-bright;
			}

			&:hover,
			&:focus {
				background: $color-white;
				color: $color-black;
			}
		}

		.desktop-title {
			display: none;
		}

		span {
			pointer-events: none;
		}
	}

	.tab-panel {
		display: none;

		&.active {
			background-color: $color-white;
			display: block;
		}
	}

	.tab-content {
		@include make-container();
		padding-bottom: 40px;
		padding-top: 30px;

		+ .tab-content {
			padding-top: 0;
		}

		> :last-child {
			margin-bottom: 0;
		}

		&.has-image {
			.image-container {
				margin-top: 2rem;
			}
		}

		p {
			@include font-weight(semi-bold);
			@include text(iota, kappa);
			color: $color-gray-dark;
		}
	}

	.large-icon {
		margin-bottom: 0.75rem;

		.icon {
			height: 40px;
			width: 40px;
		}
	}

	.tab-title {
		@include font-weight(bold);
		@include text(iota, kappa);
		color: $color-gray-dark;
		margin-bottom: 0;
	}

	.due-date {
		@include font-weight(bold);
		@include text(kappa);
		color: $color-gray-dark;
	}

	.banner-btn {
		background-color: $color-blue-bright;
		display: block;
		padding: 30px 0;
		width: 100%;
		text-decoration: none;

		span {
			@include font-weight(bold);
			@include text(iota, kappa);
			@include make-container();
			color: $color-black;
			display: flex;
			align-items: center;
			justify-content: space-between;

			svg {
				stroke: $color-black;
			}
		}

		&:hover,
		&:focus {
			background-color: $color-black;

			span {
				color: $color-white;

				svg {
					stroke: $color-white;
				}
			}
		}

		&.gray {
			background-color: $color-gray-neutral;

			&:hover,
			&:focus {
				background-color: $color-black;
			}
		}
	}

	.banner-tested {
		border-top: 2px solid $color-gray-neutral;
		display: block;
		padding: 30px 0;
		width: 100%;

		div {
			@include text(iota, kappa);
			@include make-container();
			background-color: $color-white;
			color: $color-black;
			display: flex;
			height: 21px;
			align-items: center;
			justify-content: space-between;

			strong {
				display: block;
			}

			.icon-success {
				fill: $color-white;
				stroke: $color-green;
				height: 3rem;
				width: 3rem;
				transform: translateX(0.5rem);
			}
		}
	}

	.check-in-item {
		@include font-weight(semi-bold);
		@include text(kappa);
		display: flex;
		margin-bottom: 1.25rem;

		&:last-of-type {
			margin-bottom: 0;
		}

		&.update-needed {
			&:before {
				border: 2px solid $color-gray-neutral;
				border-radius: 50%;
				display: block;
				content: '';
				height: 18px;
				width: 18px;
				max-width: 18px;
				flex: 0 0 18px;
				margin-right: 1.25rem;
			}
		}

		.check-in-label {
			@include font-weight(bold);
			display: block;
		}

		.icon-success {
			height: 18px;
			width: 18px;
			max-width: 18px;
			flex: 0 0 18px;
			margin-right: 1.25rem;
		}
	}

	.location-block {
		margin-top: 2rem;

		.location-image {
			margin: 0 -30px 1.5rem;
			text-decoration: none;

			img {
				width: 100%;
			}
		}

		.location-name {
			@include font-weight(bold);
			@include text(kappa);
			color: $color-black;
			margin-bottom: 1rem;
		}

		.location-info {
			p,
			a {
				@include text(kappa);
				color: $color-black;
				margin-bottom: 0;
			}

			.location-bold {
				@include font-weight(bold);
				margin-top: 1.5rem;
			}
		}

		.location-data {
			margin-bottom: 1.5rem;
		}
	}

	hr {
		border-top: 5px solid $color-gray-light;
		margin: 0;
	}

	@include breakpoint(md) {
		.tab-list {
			@include make-container();
		}

		.tab-btn {
			.mobile-title {
				display: none;
			}

			.desktop-title {
				display: block;
			}
		}

		.tab-content {
			padding-bottom: 60px;
			padding-top: 50px;

			&.has-image {
				display: flex;

				.image-container {
					flex: 0 0 40%;
					margin-top: 0;
					max-width: 40%;
				}
			}

			&.has-border {
				border-top: 5px solid $color-off-white;
			}

			.content-container {
				max-width: 80%;
			}

			p {
				@include text(zeta, 28);
			}
		}

		.tab-title {
			@include text(zeta, 28);
		}

		.banner-tested {
			padding: 36px 0;

			div {
				strong {
					display: inline;
				}
			}
		}

		.banner-btn {
			padding: 36px 0;
		}

		.check-in-item {
			.check-in-label {
				display: inline;
			}
		}

		.location-block {
			display: flex;

			.location-image {
				flex: 0 0 25%;
				margin: 0;
				max-width: 25%;
			}

			.location-info-container {
				flex: 1;
				margin-left: 1.25rem;
			}

			.location-info {
				display: flex;
				justify-content: space-between;
			}

			.location-data {
				flex: 1 1 0;
				margin-right: 1rem;

				&:last-of-type {
					margin-right: 0;
				}
			}
		}
	}

	@include breakpoint(lg) {
		.tab-content {
			&.has-image {
				display: flex;

				.image-container {
					max-width: calc(100% / 3);
					flex: 0 0 calc(100% / 3);
				}
			}
		}
	}
}
