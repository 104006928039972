.provider-profile-detail {
	.profile-inner {
		@include text(lambda);
		@include font-weight(medium);
		background-color: $color-off-white;
		color: $color-gray-dark;
		padding-bottom: 2rem;
	}

	p {
		@include text(lambda);
		@include font-weight(medium);
		color: $color-gray-dark;
	}

	a {
		color: $color-blue;
		cursor: pointer;
	}

	.icon-transport {
		fill: black;
		width: 2rem;
		height: 2rem;
	}

	.transport-info-wrapper {
		display: flex;
		gap: 3px;
		margin-top: 10px;
	}

	.transport-item-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		p {
			font-size: 12px;
		}
	}

	.btn {
		@include text(lambda);
		@include font-weight(medium);
		border: 1px solid $color-gray-neutral;
		border-radius: 2px;
		margin: 0;
		padding: 0.625rem 1rem 0.5rem;
	}

	.profile-image-container {
		display: block;
		height: 0;
		overflow: hidden;
		padding-bottom: 66.666%;
		position: relative;
		width: 100%;
	}

	.profile-image {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		img {
			font-family: 'object-fit: cover; object-position: center 25%;';
			height: 100%;
			object-fit: cover;
			object-position: center 25%;
			width: 100%;
		}
	}

	.provider-details {
		padding: 1.5rem 30px;

		.provider-list-btn {
			display: none;
		}
	}

	.provider-list-btn {
		@include text(lambda);
		@include font-weight(bold);
		align-items: center;
		border-bottom: 1px solid $color-gray-neutral;
		display: flex;
		height: 40px;
		justify-content: center;
		text-decoration: none;
		width: 100%;

		svg {
			margin-right: 0.5rem;
		}

		.checkbox-wrapper {
			@include checkbox(
				$color-gray-neutral,
				$color-gray-medium-light,
				$color-green-providers
			);
			margin-bottom: 0;
			margin-top: 4px;

			label {
				@include font-weight(bold);
			}
		}
	}

	.provider-name {
		@include text(theta);
		@include font-weight(bold);
		color: $color-black;
		display: block;
		margin-bottom: 0.25rem;
	}

	.medical-group {
		@include text(lambda);
		@include font-weight(bold);
		margin-bottom: 0.625rem;

		&.presbyterian {
			@include font-weight(medium);
			color: $color-red;
		}
	}

	.new-patient {
		margin: 1rem 0;
	}

	.telehealth-indicator {
		//margin: 1rem 0;
		margin-top: -11px;
	}

	.provider-website {
		.icon-external {
			height: 0.5rem;
			margin-left: 0.25rem;
			stroke: $color-blue;
			width: 0.5rem;
		}
	}

	.ratings {
		margin: 0.75rem 0 1.25rem;
	}

	.new-patient-icon-container,
	.open-weekend-icon-container {
		align-items: center;
		display: flex;
		margin-bottom: 1.5rem;

		.icon {
			margin-bottom: 1px;
			margin-right: 0.5rem;
		}
	}

	.open-weekend-icon-container {
		align-items: center;
		display: flex;
		margin-bottom: 1.5rem;

		.icon {
			margin-bottom: 1px;
			margin-right: 0.5rem;
		}
	}

	.telehealth-indicator-icon-container {
		align-items: center;
		display: flex;
		margin-bottom: 1.5rem;

		.icon {
			margin-bottom: 1px;
			margin-right: 0.5rem;
		}

		svg {
			height: 21px;
			width: 16px;
			margin-right: 7px;
		}
	}

	.virtual-care {
		margin-bottom: 0.25rem;
	}

	.provider-profile-navigation {
		display: none;
	}

	.accessibility-label {
		@include font-weight(bold);
		margin-bottom: 5px;
	}
	@include breakpoint(md) {
		margin-bottom: 1.5rem;

		&.simplified {
			.provider-details,
			.location-info-container {
				flex: 0 1 calc(100% / 3);
				max-width: calc(100% / 3);
			}

			.detail-top,
			.detail-bottom {
				flex: 0 1 100%;
				max-width: 100%;
				padding: 0 1rem;
			}

			.location-info-container {
				border-left: 2px solid $color-white;
			}

			.detail-top {
				display: flex;
				flex-direction: column;
			}
		}

		.profile-inner {
			background-color: $color-gray-providers;
			display: flex;
			position: relative;
			padding: 0;
			margin-bottom: 2px;
		}

		.profile-image-outer {
			flex: 0 1 calc(100% / 3);
			max-width: calc(100% / 3);

			.provider-list-btn {
				display: none;
			}
		}

		.profile-image-container {
			padding-bottom: 100%;
		}

		.provider-details {
			flex: 0 1 calc(100% / 3 * 2);
			max-width: calc(100% / 3 * 2);
			padding: 1.5rem 0;

			.provider-list-btn {
				display: block;
			}
		}

		.provider-list-btn {
			border-bottom: none;
			display: none;
			justify-content: flex-start;
			width: auto;
			margin-bottom: 2.5rem;
		}

		.provider-details-inner {
			display: flex;
			height: 100%;
		}

		.provider-name {
			@include text(zeta);
			padding: 0 1rem;
		}

		.profile-biography {
			margin-bottom: 0;
		}

		.detail-top,
		.detail-bottom {
			flex: 0 1 50%;
			max-width: 50%;
			padding: 0 1rem;
		}

		.detail-bottom {
			display: flex;
			justify-content: center;
		}

		.detail-bottom-inner {
			display: flex;
			flex-direction: column;
		}

		.new-patient-icon-container,
		.open-weekend-icon-container {
			display: flex;
			margin-bottom: 1rem;
			align-items: flex-start;
			line-height: 18px;
		}

		.open-weekend-icon-container {
			margin-bottom: 1rem;
			align-items: flex-start;
			line-height: 18px;
		}

		.telehealth-indicator-icon-container {
			margin-bottom: 1rem;
			margin-top: 1rem;
			align-items: flex-start;
			line-height: 18px;
		}

		.virtual-care,
		.message-link {
			margin-bottom: 1rem;
			margin-left: 1.5rem;
		}

		.provider-profile-navigation {
			align-items: stretch;
			display: flex;
			justify-content: space-evenly;

			a {
				@include text(kappa);
				@include font-weight(semi-bold);
				align-items: center;
				background-color: $color-gray-light;
				border-right: 2px solid $color-white;
				color: $color-gray-dark;
				display: flex;
				height: 50px;
				flex: 1;
				justify-content: center;
				position: relative;
				text-decoration: none;
				transition: background-color 0.4s ease, color 0.4s ease;
				z-index: 1;

				&:last-child {
					border-right: none;
				}

				&:focus,
				&:hover {
					color: $color-black;
					background-color: $color-gray-neutral;
				}
			}
		}
	}

	@include breakpoint(lg) {
		margin-bottom: 1.5rem;

		&.simplified {
			.provider-details,
			.new-patient-icon-container,
			.open-weekend-icon-container {
				margin-bottom: 1rem;
			}
		}
	}
}
