//This blocks off the entire page with loading image.
.loading-overlay {
	@include loader($color-gray-neutral, 100px, 5px);
	align-items: flex-start;
	background-color: $color-white;
	display: flex;
	height: 100%;
	justify-content: center;
	left: 0;
	opacity: 0;
	padding-top: 150px;
	pointer-events: none;
	position: absolute;
	top: 0;
	transition: opacity 0.4s ease-in-out;
	width: 100%;
	z-index: 2;
}
