.ngde-header-background {
	height: 450px;
	background-size: cover;
	background-attachment: fixed;
	overflow: hidden;
	img {
		width: 100%;
		height: 450px;
		object-fit: cover;
	}
}
