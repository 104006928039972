.ngde-mychart-card {
	@include make-container();

	p {
		@include text(kappa);
	}

	.grid-row {
		position: relative;
		@include make-row();
		margin-bottom: 5rem;
	}

	.btn {
		width: 100%;
		@include text(iota);
		@include font-weight(normal);
		background-color: $color-gray-dark;
		color: $color-white;
	}

	.btn:hover {
		background-color: $color-gray-darkest;
	}

	.large-portal-picture-card-img {
		height: 300px;
		object-fit: cover;
		width: 100%;
		margin-bottom: 1rem;
	}

	.phs-logo-wrap .icon-phs-logo {
		fill: $color-red;
		margin-bottom: 0.5rem;
	}

	.icon-external {
		height: 0.5rem;
		margin-left: 0.25rem;
		stroke: $color-white;
		width: 0.5rem;
	}
}
.mychart-card {
	background-color: $color-gray-light;
	padding: 2rem 2rem 3rem 2rem;
	margin: -1rem 0rem 0rem 0rem;
	.icon-external {
		height: 0.5rem;
		margin-left: 0.25rem;
		stroke: $color-blue;
		width: 0.5rem;
	}
}
.mychart-card-activate {
	background-color: $color-gray-light;
	padding: 1rem 1.5rem 3rem 1.5rem;
	margin: 0rem 0rem 0rem 0rem;
	border-radius: 8px;
	.btn {
		background-color: $color-white;
		color: $color-gray-darkest;
		text-align: center;
	}
	.btn:hover {
		background-color: $color-gray-light;
	}
	input[type='text'] {
		@include text(iota);
		line-height: 1.3333333333;
		font-family: $font-avenir-book;
		font-weight: $font-avenir-book;
		background-color: $color-off-white;
		color: $color-gray-dark;
		border-bottom: 2px solid $color-gray-neutral;
		padding-top: 22px;
		text-align: left;
		::placeholder {
			text-align: left;
		}
	}

	.input-down {
		padding-top: 36px;
	}

	.form .form-control {
		max-width: 100%;
	}
	.floating-label {
		font-size: 0.875rem;
		line-height: 1.4285714286;
		color: $color-gray-medium-light;
		left: 1.05rem;
		opacity: 0;
		pointer-events: none;
		position: relative;
		top: 4px;
		top: -67px;
	}
	p.section-description {
		@include text(lambda);
		color: $color-gray-dark;
	}

	h2.second-header {
		@include text(iota);
	}

	@include breakpoint(lg) {
		input[type='text'] {
			padding-right: 21rem;
		}
	}
	@include breakpoint(xl) {
		input[type='text'] {
			padding-right: 11rem;
		}
	}
}
.mychart-card-logo {
	background-color: $color-gray-light;
	padding: 2rem 0rem 3rem 0rem;
}

.mychart-notification-wrap {
	background-color: $color-red-light;
	display: -webkit-box;
	margin: 1rem 0 4rem 0;
	padding: 1.2rem;
	.icon {
		height: 2rem;
		stroke: unset;
		stroke-linecap: round;
		stroke-width: 2px;
		width: 4rem;
		margin-right: 5%;
	}
}

.MyChartActivateLink {
	background-color: $color-gray-light;
	border-radius: 8px;
	.portal-picture-card-img {
		height: 200px;
		object-fit: cover;
		width: 100%;
		margin-bottom: -2rem;
	}
}

span.stauts-icon-wrapper {
	display: flex;
	width: 94%;
	@include text(lambda);
	color: $color-gray-dark;
}

img.portal-picture-card-img.mychart-card-logo {
	height: 200px;
	-o-object-fit: cover;
	font-family: 'object-fit:cover', 'object-fit:cover';
	object-fit: fill;
	width: 100%;
	padding: 50px 50px 50px 21px;
	border-radius: 8px;
}

button.btn.btn-secondary.actsubmit-button.disabled {
	color: $color-gray-neutral;
}

.card-show {
	display: block;
}

.card-hide {
	display: none;
}
