.heart-and-vascular,
.pls {
	h1,
	.heading-1 {
		@include text(iota);
		@include font-weight(medium);
		color: $color-red;
		margin-bottom: 1.25rem;
	}

	h2,
	.heading-2 {
		@include text(iota);
		@include font-weight(bold);
		color: $color-black;
		margin-bottom: 0.75rem;

		a {
			color: $color-black;
		}
	}

	h3,
	.heading-3 {
		@include text(iota);
		@include font-weight(bold);
		color: $color-gray-dark;
		margin-bottom: 0.5rem;
	}

	h4,
	.heading-4 {
		@include text(kappa);
		@include font-weight(bold);
		color: $color-gray-dark;
		margin-bottom: 0.5rem;
	}

	.hero-copy {
		@include text(iota);
		@include font-weight(light);
		margin-bottom: 0.75rem;
	}

	p,
	.body-copy {
		@include text(kappa, 24);
		@include font-weight(semi-bold);
		color: $color-gray-dark;
		margin-bottom: 1.5rem;
	}

	a:not[class] {
		color: $color-blue;
		text-decoration: underline;

		&:hover,
		&:focus {
			text-decoration: none;
		}
	}

	@include breakpoint(md) {
		h1,
		.heading-1 {
			@include text(delta, 36);
			@include font-weight(light);
		}

		h2,
		.heading-2 {
			@include text(theta, 24);
			@include font-weight(medium);
		}

		.hero-copy {
			@include text(zeta);
		}
	}
}
