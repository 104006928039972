.pls {
	@include breakpoint(md) {
		a {
			text-decoration: none;

			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
	}
}
