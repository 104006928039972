.ngde-coverage-history {
	padding-top: 160px;
	margin-right: auto;
	margin-left: auto;
	max-width: $lg-xl-breakpoint;
	padding-right: 30px;
	padding-left: 30px;
	width: 100%;

	@include make-container();
	a {
		text-decoration: none;
	}

	.grid-row {
		@include make-row();
		section {
			overflow: hidden;
			width: 100%;
		}
	}

	.padbot {
		padding-bottom: 15px;
	}

	.summary-of-coverage-wrap-container {
		&.loading {
			.loading-overlay {
				opacity: 1;
			}
		}
		position: sticky;
		overflow-x: scroll;
		overflow-y: hidden;
		.summary-of-coverage-wrap {
			margin-bottom: 3rem;
			margin-left: 0.5rem;
			padding-left: -1rem;
			width: 862px;
			.summary-of-coverage-table {
				min-width: 800px;
				max-width: 100%;
				/* overflow-y: hidden;
				overflow-x: scroll; */
			}

			svg.icon.arrow-down-triangle {
				/* margin-top: -2rem; */
				position: relative;
				top: 3px;
				stroke-width: 0px;
				fill: $color-gray-arrow;
				margin-left: 20px;
			}
			svg.icon.arrow-down {
				/* margin-top: -2rem; */
				position: relative;
				top: 3px;
				stroke-width: 0px;
				fill: $color-gray-arrow;
				margin-left: 20px;
			}
			svg.icon.arrow-up-triangle {
				/* margin-top: -2rem; */
				position: relative;
				top: 3px;
				stroke-width: 0px;
				fill: $color-gray-medium-light;
				margin-left: 20px;
			}
			svg.icon.arrow-up {
				/* margin-top: -2rem; */
				position: relative;
				top: 3px;
				stroke-width: 0px;
			}
			.summary-of-coverage-title-row {
				background-color: $color-gray-light;
				padding: 1rem 1rem;
				border-bottom: solid $color-gray-neutral 1px;
				cursor: pointer;
			}

			.summary-of-coverage-data-row {
				padding: 2rem 1rem;
				border: solid $color-gray-neutral 1px;
				border-top: none;
			}
			.summary-of-coverage-group,
			.summary-of-coverage-member {
				font-size: 0.9rem;
			}
			.coverage-label {
				font-style: oblique;
			}
		}
		.content-col {
			h1 {
				@include text(gamma);
			}
		}
	}

	@include breakpoint(md) {
		padding-top: 200px;
		.grid-row {
			@include make-row();
			section {
				overflow: unset;
				width: unset;
			}
		}
		.summary-of-coverage-wrap-container {
			width: 100%;
			position: sticky;
			overflow-x: unset;
			overflow-y: unset;
			.summary-of-coverage-wrap {
				width: 100%;
				.summary-of-coverage-table {
					width: 185%;
					overflow-y: unset;
					overflow-x: unset;
				}
			}
		}
	}

	@include breakpoint(lg) {
		padding-top: 300px;
	}
}
