.hv-two-col {
	@include make-container();
	.providers-live-help {
		ul {
			margin-bottom: 1rem;
		}

		li {
			margin-bottom: 0.1rem;
		}

		.title {
			@include font-weight(semi-bold);
			color: $color-red;
		}
	}

	@include breakpoint(md) {
		.hv-row {
			@include make-row();
			flex-direction: row-reverse;
		}

		.hv-rail {
			@include make-col-ready();
			@include make-col(3);
			display: flex;
			flex-direction: column;

			h2 {
				margin-bottom: 1.25rem;
			}
		}

		.hv-content {
			@include make-col-ready();
			@include make-col(9);
		}
	}
}
