.ngde-portal-cards-grid {
	@include make-container();

	.campaign-portlet {
		background-color: $color-gray-light;
		padding-bottom: 2.2rem;

		.heading-2 {
			@include text(iota);
			padding: 0.5rem 2rem;
		}

		.section-description {
			padding: 0rem 2rem;
			margin-bottom: 0.4rem;
		}

		.text-center {
			text-align: left;
			padding: 0rem 2rem;
		}
	}

	.icon-external-secondary {
		height: 0.5rem;
		margin-left: 0.25rem;
		stroke: $color-blue;
		width: 0.5rem;
	}

	.grid-row & {
		padding: 0;
	}

	.portal-id-card-col {
		&.loading {
			height: 300px;
			background-color: $color-white;
		}
	}

	&.article-list {
		.portal-card-col {
			margin-top: 0;
			margin-bottom: 0;
		}

		.grid-row {
			margin-bottom: 2rem;
		}

		h2,
		.heading-2 {
			color: $color-gray-dark;
			margin-bottom: 0.25rem;
		}

		.btn {
			@include font-weight(semi-bold);

			&:hover {
				text-decoration: none;
			}
		}
	}

	h2,
	h2.heading-2 {
		@include text(kappa);
		@include font-weight(bold);
		margin-bottom: 1rem;
	}

	h3,
	.heading-3 {
		@include text(kappa);
		@include font-weight(medium);
		margin-bottom: 0.5rem;
	}

	p,
	p.section-description {
		@include text(lambda);
	}

	.grid-row {
		position: relative;
		@include make-row();
		margin-bottom: 5rem;
	}

	.btn {
		width: 100%;
		@include text(iota);
		@include font-weight(normal);
		background-color: $color-gray-dark;
		color: white;
		padding: 1.125rem;
		white-space: normal;

		&.auto-width {
			width: auto;
		}

		&:hover {
			text-decoration: none;
		}
	}

	.deductible-chart-details {
		@include make-row();
		.deductible-chart-details-text {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: -20px;
            padding-left: 22px;
			padding-right: 0;
		}
	}

	.btn:hover {
		background-color: $color-gray-darkest;
	}

	.portal-picture-card {
		&.loading {
			.loading-overlay-section {
				opacity: 1;
			}
		}
	}

	.portal-picture-card-img {
		height: 200px;
		object-fit: cover;
		width: 100%;
		margin-bottom: 1rem;
	}

	.portal-logo-card-img {
		height: 200px;
		object-fit: contain;
		width: 100%;
		margin-bottom: 1rem;
	}

	.large-portal-picture-card-img {
		height: 300px;
		object-fit: cover;
		width: 100%;
		margin-bottom: 1rem;
	}

	.no-stretch-image-portal-picture-card-img {
		height: 200px;
		margin-bottom: 1rem;
		padding: 0 20px;
	}

	.phs-logo-wrap .icon-phs-logo {
		fill: $color-red;
		margin-bottom: 0.5rem;
	}

	.icon-external {
		height: 0.5rem;
		margin-left: 0.25rem;
		stroke: white;
		width: 0.5rem;
	}
}

.svg-content-wrap {
	display: flex;
	justify-content: center;
	align-items: center;

	.svg-container {
		padding-right: 1rem;
		margin-bottom: 1rem;
	}
}

.member-card-details {
	padding: 0rem 5px;
	margin-bottom: 1rem;
	h2 {
		margin-bottom: 0.5rem;
	}
}

.portal-card-col {
	margin-top: -80px;
	margin-bottom: 7rem;

	&.loading {
		.loading-overlay-section {
			opacity: 1;
		}
	}
}

.portal-id-card {
	padding: 5px;
	border-radius: 7px;
	box-shadow: $box-shadow;
	background-color: white;
	margin-bottom: 2.5rem;

	.patient-details {
		@include make-row();
		margin-bottom: 1rem;
	}
}

.next-visit-card {
	padding: 2rem;
	border-radius: 7px;
	background: $color-gray-light;
	margin-bottom: 2rem;
}

.card-link {
	color: $color-blue;
	@include text(lambda);
}
