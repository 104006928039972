.main-navigation {
	display: flex;

	.icon-menu-open {
		fill: $color-gray-dark;
	}

	.icon-menu-close {
		position: relative;
		stroke: $color-gray-dark;
		stroke-width: 3px;
	}

	.full-nav {
		display: none;
	}

	.nav-open,
	.nav-close {
		background-color: transparent;
		border: 0;
		line-height: 0;
		padding: 0;
	}

	@media (min-width: $md-lg-breakpoint) {
		.hv-header & {
			.full-nav {
				display: flex;
				overflow: hidden;
				padding-left: 2rem;

				.search-btn {
					padding: 7px 18px 5px;
				}
			}

			.nav-open {
				display: none;
			}
		}
	}

	@include breakpoint(lg) {
		.full-nav {
			display: flex;
			overflow: hidden;
			padding-left: 2rem;

			.search-btn {
				padding: 7px 18px 5px;
			}
		}

		.nav-open {
			display: none;
		}
	}

	.message-badge {
		position: relative;
		top: -0.35rem;
		right: 0.2rem;
		padding: 0rem 0.3rem;
		border-radius: 100%;
		background: red;
		color: $color-red-light;
	}
}
