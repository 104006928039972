.portal {
	.data-summary {
		display: flex;
		flex-direction: column;
		margin-bottom: 2rem;
		margin-left: -30px;
		width: 100vw;

		.data-section {
			background-color: $color-neutral-light;
			border-right: 1px solid $color-gray-neutral;
			display: flex;
			flex-direction: column;
			padding: 35px 30px 22px;

			.data-item-container {
				display: flex;
				flex-flow: row;
				width: 100%;
			}

			.you-pay {
				background-color: $color-blue-highlight;
				margin: 0 -30px 0 -30px;
				padding: 12px 30px;
				width: 100vw;

				dt,
				dd {
					margin: 0;
				}
			}

			dl {
				@include text(lambda);
				align-content: flex-start;
				display: flex;
				flex-grow: 1;
				flex-wrap: wrap;
				justify-content: space-between;

				&:last-child {
					padding-right: 0;
				}
			}
			dt {
				@include font-weight(semi-bold);
				margin-bottom: 0.75rem;
				padding-right: 0.25rem;
				width: 40%;
			}
			dd {
				color: #616161;
				display: flex;
				margin-bottom: 0.75rem;
				margin-left: auto;
				padding-right: 0.5rem;
				width: 60%;
			}
		}

		.link-section {
			padding: 35px 30px 22px;
		}

		.link-list-header,
		.link-list-item {
			@include text(lambda);

			p {
				@include text(lambda);
				margin-bottom: 0;
			}
		}

		.link-list-header {
			margin-bottom: 0.25rem;

			.icon {
				margin-left: 1rem;
			}
		}

		.link-list {
			margin-bottom: 1rem;
			margin-left: 0;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.link-list-item {
			margin-bottom: 0.5rem;

			.btn-link {
				margin-bottom: 0;
				text-align: left;
			}
		}

		@include breakpoint(lg) {
			border: 1px solid $color-gray-neutral;
			flex-direction: row;
			margin-left: 0;
			width: 100%;

			.data-section {
				flex-basis: 68%;
				flex-direction: row;
				max-width: 68%;

				dl {
					padding-right: 1rem;
				}

				.you-pay {
					margin-top: 1rem;
					padding: 1rem 30px;
					width: calc(100% + 60px);
				}
			}

			.link-section {
				flex-basis: 32%;
				max-width: 32%;
			}
		}
	}
}
