.grid-title {
	margin-bottom: 2rem;

	.grid-title-inner {
		@include make-container-bg();
		align-items: center;
		background-color: $color-gray-light;
		color: $color-black;
		display: flex;
	}

	h2 {
		@include text(gamma);
		margin-bottom: 1rem;

		&:after {
			@include text(eta);
			content: 'XXS: <#{map-get($breakpoints, xs)}';
			display: block;
			margin-top: 2px;
		}
	}

	@include breakpoint(xs) {
		h2 {
			&:after {
				content: 'XS Breakpoint: ≥#{map-get($breakpoints, xs)}';
			}
		}
	}

	@include breakpoint(sm) {
		h2 {
			&:after {
				content: 'SM Breakpoint: ≥#{map-get($breakpoints, sm)}';
			}
		}
	}

	@include breakpoint(md) {
		h2 {
			&:after {
				content: 'MD Breakpoint: ≥#{map-get($breakpoints, md)}';
			}
		}
	}

	@include breakpoint(lg) {
		h2 {
			&:after {
				content: 'LG Breakpoint: ≥#{map-get($breakpoints, lg)}';
			}
		}
	}

	@include breakpoint(xl) {
		h2 {
			&:after {
				content: 'XL Breakpoint: ≥#{map-get($breakpoints, xl)}';
			}
		}
	}
}
