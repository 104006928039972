.portal {
	.ngde-modal {
		.modal-inner-container {
			border-top: 0.75rem solid $color-red;
		}

		.modal-inner {
			margin-bottom: 3rem;
			margin-left: 30px;
			margin-right: 30px;
			max-width: calc(100vw - 60px);
			padding: 0;
		}

		.modal-description {
			@include text(kappa);
		}

		.ngde-form {
			margin: 0;
		}

		.select-wrapper {
			min-height: 56px;
			position: relative;
			width: 100%;

			.select {
				background-color: $color-gray-light;
				min-height: 56px;
				width: 100%;
			}

			.icon {
				fill: $color-gray-darkest;
				position: absolute;
				right: 1rem;
				top: calc(50% - 0.5rem);
			}
		}

		.form-control {
			margin-bottom: 1rem;
			max-width: none;
		}

		input[type='text'],
		input[type='password'] {
			max-width: none;
			width: 100%;

			&:focus-visible {
				outline: -webkit-focus-ring-color auto 1px;
			}
		}

		.btn {
			max-width: none;
			width: 100%;
		}

		.submit-button {
			&:focus-visible,
			&:focus {
				outline: $color-blue-bright auto 3px;
			}
		}

		input {
			@include text(kappa);
			background-color: #e8e8e8;
			height: auto;
			min-height: 56px;
			padding-bottom: 0;
			padding-top: 0;
			text-align: left;
			width: 100%;

			&::placeholder {
				text-align: left;
			}
		}

		.radio-group {
			display: flex;

			.radio-wrapper {
				padding-right: 2rem;

				label {
					@include text(iota);
				}

				input[type='radio'] + label:before {
					border: 1px solid $color-gray-neutral;
				}
			}
		}

		@include breakpoint(md) {
			.modal-inner {
				margin: 0 95px;
				max-width: 550px;
			}
		}
	}
}
