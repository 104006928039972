@mixin breakpoint($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		@media (min-width: map-get($breakpoints, $breakpoint)) {
			@content;
		}

		//Need this for Firefox and Safari print. They do not use the @page size element in the print.sccs
		@media print and (min-width: map-get($print-breakpoints, $breakpoint)) {
			@content;
		}
	} @else {
		@warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. ' + 'Available breakpoints are: #{map-keys($breakpoints)}.';
	}
}
