.authorized-rep-member-view {
	.data-summary {
		.data-section {
			max-width: 100%;
			flex-basis: 100%;
		}
	}

	.current-coverage-wrap {
		.heading-1 {
			color: $color-black;
		}
	}

	.ngde-member-id-cards {
		padding-top: 20px;
	}

	.ngde-care-team {
		padding-top: 20px;
	}
}

.ngde-portal-cards-grid {
	.authorized-rep-card {
		padding-bottom: 150px;
		.authorized-rep-member-select {
			padding: 20px;
			.select-wrapper-plan {
				padding-bottom: 20px;
				width: 100%;
				select {
					background-color: #eee;
				}
			}
		}
	}
}
