.at-header {
	background-color: $color-gray-dark;
	margin-bottom: 2rem;

	.main-header {
		height: 60px;
		position: relative;

		a {
			.icon-phs-logo,
			.icon-phs-icon {
				fill: $color-white;
			}
		}

		.logo-container {
			align-self: flex-start;
			height: auto;
			padding-bottom: 19px;
			padding-top: 15px;

			.icon-phs-logo {
				display: block;
				height: 26px;
				width: 226px;
			}

			a {
				display: block;
				line-height: 1;
			}
		}

		.mobile-logo-container {
			align-items: center;
			display: flex;
			text-decoration: none;

			a {
				display: flex;
			}

			.icon-phs-icon {
				height: 25px;
				margin-bottom: 8px;
				margin-right: 0.25rem;
				width: 22px;
			}

			.logo-subtitle {
				@include text(iota);
				@include font-weight(medium);
				color: $color-white;
				display: block;
				margin: 0;
				padding-left: 0.25rem;
			}
		}

		.phs-link {
			display: none;
		}
	}

	.icon-menu-open {
		fill: $color-white;
	}

	@include breakpoint(md) {
		margin-bottom: 60px;
	}

	@media (min-width: $mobile-nav-breakpoint) {
		margin-bottom: 60px;

		.main-header {
			.logo-subtitle {
				@include text(iota);
				@include font-weight(light);
				color: $color-white;
				margin-bottom: 1px;
				padding-left: 0.75rem;
			}

			.nav-link {
				color: $color-white;
			}
		}
	}

	@include breakpoint(lg) {
		.main-header {
			.phs-link {
				right: $lg-grid-outer-padding;
			}
		}
	}

	@include breakpoint(xl) {
		.main-header {
			.phs-link {
				right: $xl-grid-outer-padding;
			}
		}
	}
}
