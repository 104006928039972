.hv-header {
	position: relative;

	.main-header {
		background-color: $color-red;

		.icon-phs-logo {
			fill: $color-white;
		}

		.logo-subtitle {
			color: $color-white;
		}
	}

	.main-navigation {
		.icon-menu-open {
			fill: $color-white;
		}
	}

	.nav-link {
		&:hover,
		&:focus {
			text-decoration: none;
		}
	}

	@media (min-width: $md-lg-breakpoint) {
		z-index: 1;

		.navbar-fixed &,
		.navbar-scroll-up &,
		.navbar-scroll-down & {
			position: relative;

			.main-header {
				animation: none;
				margin-top: 0;
				position: relative;
			}
		}

		.search-open & {
			.main-header-outer {
				display: none;
			}
		}

		.utility-header {
			background: linear-gradient($color-gray-dark, $color-black);
			display: block;
			transition: margin-top 0.3s ease-in-out;

			.utility-navigation {
				@include make-container();
				align-items: center;
				display: flex;
				height: 35px;
			}

			a {
				@include text(lambda);
				color: $color-gray-neutral;
				display: inline-block;
				padding: 0.5rem 2.5rem 0.5rem 0;
				text-decoration: none;

				&:hover,
				&:focus {
					color: $color-gray-light;
				}
			}

			.about-link {
				margin-left: auto;
				padding-right: 0;
			}
		}

		.main-header-outer {
			background-color: rgba($color-red, 0.5);
			mix-blend-mode: multiply;
			position: relative;

			&:before {
				background-color: rgba($color-red, 0.5);
				content: '';
				display: block;
				height: 100%;
				left: 0;
				position: absolute;
				mix-blend-mode: multiply;
				top: 0;
				width: 100%;
			}

			&:after {
				background-color: rgba($color-red, 0.5);
				content: '';
				display: block;
				height: 100%;
				left: 0;
				position: absolute;
				mix-blend-mode: multiply;
				top: 0;
				width: 100%;
			}
		}

		.main-header {
			background-color: transparent;
			height: 100px;
			padding-left: 15px;
			padding-right: 15px;

			.logo-subtitle {
				font-size: 22px;
			}
		}

		.logo-container,
		.full-nav {
			z-index: 1;
		}

		.nav-link {
			@include text(lambda);
			@include font-weight(semi-bold);
			color: $color-white;

			&:after {
				background-color: $color-white;
			}
		}

		.full-nav {
			.search-btn {
				.icon-search {
					stroke: $color-white;
				}
			}
		}
	}

	@include breakpoint(lg) {
		.nav-link {
			@include text(kappa);
		}

		.main-header {
			padding-left: 30px;
			padding-right: 30px;
		}

		.utility-header {
			.utility-navigation {
				height: 40px;
			}

			a {
				@include text(kappa);
			}
		}
	}
}
