.form-page {
	@include make-container();

	&.form-submitted {
		.form-request {
			display: none;
		}
		.form-response {
			display: block;
		}
	}

	&.form-resubmitted {
		.form-response {
			button {
				display: none;
			}
			.alert {
				display: block;
				text-align: center;
			}
		}
	}

	.form-request {
		justify-content: center;
	}

	.form-response {
		display: none;

		.alert {
			display: none;
		}
	}
}
