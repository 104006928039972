.filter-list {
	background-color: $color-gray-light;
	height: 100%;
	left: 0;
	opacity: 0;
	overflow: auto;
	padding-bottom: 80px;
	pointer-events: none;
	position: fixed;
	top: 20vh;
	transition: visibility 0s ease-in-out 400ms, opacity 0.2s ease-in-out,
		top 0.2s ease-in-out;
	visibility: hidden;
	width: 100%;
	z-index: 9;

	&.filters-open {
		display: block;
		opacity: 1;
		pointer-events: auto;
		top: 0;
		transition-delay: 0s;
		visibility: visible;

		[class^='translated-'] & {
			height: calc(100% - 39px);
			top: 39px;
		}
	}

	&.view-all-open {
		> .accordion-container {
			display: none;
		}
	}

	.disabled-filter {
		color: #999;
	}

	.accordion-container {
		padding: 10px;
	}
	.pharmacy-filter {
		padding: 15px 10px 0;
	}

	.filter-title {
		@include text(iota);
		@include font-weight(medium);
		color: $color-gray-dark;
		margin-bottom: 1rem;
	}

	.mobile-filter-header {
		@include make-container();
		align-items: center;
		background-color: $color-gray-medium-light;
		color: $color-gray-dark;
		display: flex;
		height: 48px;
		justify-content: space-between;
		position: relative;

		.mobile-close-btn {
			@include text(delta);
			@include font-weight(bold);
			background: none;
			border: none;
			color: $color-gray-medium;
			line-height: 0;
			margin-left: -1rem;
			padding: 1rem;
			transform: translateX(1rem);

			.icon {
				height: 0.75rem;
				stroke: $color-white;
				width: 0.75rem;
			}

			&:hover {
				outline: 0;
				.icon {
					fill: $color-blue;
				}
			}
			&:focus-visible {
				outline: $color-white solid 2px;
			}
		}

		.icon-arrow-right {
			transform: rotate(180deg);
		}

		.filter-heading {
			@include text(iota);
			@include font-weight(medium);
			color: $color-white;
			margin: 0;
		}

		.filter-link {
			color: $color-blue;
		}
	}

	.input-icon-container {
		margin-top: 1rem;
		padding: 0 10px;
		position: relative;

		input {
			@include text(kappa);
			background-color: $color-white;
			height: 60px;
			margin-bottom: 0;
			padding: 1.125rem 2.75rem 1.125rem 20px;
			text-align: left;
			width: 100%;

			&::placeholder {
				color: #999;
				text-align: left;
				font-size: 0.8rem;
			}
		}

		button,
		> .icon {
			position: absolute;
			right: 30px;
			top: 50%;
			transform: translateY(-50%);
		}

		> .icon {
			pointer-events: none;
		}
	}

	.nav-accordion {
		background-color: $color-gray-light;

		.accordion-item {
			border-color: $color-gray-neutral;

			&:last-of-type {
				border-bottom: none;
			}

			&[aria-expanded='true'] {
				.accordion-item-title {
					.accordion-item-toggle {
						color: $color-gray-dark;

						.label-secondary.fade-out {
							opacity: 0;
						}
					}
				}

				&.closing {
					> .accordion-item-title {
						.accordion-item-toggle {
							color: $color-gray-dark;

							&:hover,
							&:focus {
								color: $color-gray-medium;
							}
						}
					}
				}
			}

			.accordion-item-title {
				.accordion-item-toggle {
					color: $color-black;

					&:hover {
						color: $color-blue;
						.icon:not(.icon-star) {
							fill: $color-blue;
						}
					}

					&:focus-visible {
						color: $color-blue;
						outline: -webkit-focus-ring-color auto 1px;
						.icon:not(.icon-star) {
							fill: $color-blue;
						}
					}

					& > h2 {
						margin: 0;
						color: inherit;
					}

					.label-secondary {
						@include text(lambda);
						@include font-weight(semi-bold);
						color: $color-gray-medium;
						display: flex;
						margin-left: auto;
						margin-right: 0.5rem;
						opacity: 1;
						transition: opacity 0.1s ease-in-out;

						.rating-stars {
							margin-right: 0.375rem;
						}
					}

					.icon:not(.icon-star) {
						fill: $color-white;
					}
				}

				.accordion-item-tags {
					background-color: $color-gray-dark;
				}
			}

			.accordion-item-body {
				color: $color-gray-darkest;

				.input-icon-container {
					margin-bottom: 1rem;
					margin-left: -20px;
					padding: 0;
					width: calc(100% + 40px);
				}
			}
		}
	}

	.checkbox-filter {
		align-items: center;
		display: flex;
		padding: 1rem;

		.checkbox-wrapper {
			margin: 0 1rem 0 0;

			input[type='checkbox'] {
				+ label {
					color: $color-white;
				}
			}
		}

		img {
			display: none;
		}

		&.isMHMG-container {
			display: none;
		}
	}

	.distance-radio {
		display: flex;
		margin-left: -30px;
		width: calc(100% + 60px);

		.distance-radio-wrapper {
			flex: 1;
			height: 40px;
			position: relative;

			input[type='radio'] {
				height: 0;
				width: 0;

				&:checked {
					+ label {
						background-color: $color-gray-dark;
						color: $color-white;
					}
				}

				&:focus,
				&:hover {
					+ label {
						box-shadow: inset 0 0 1px 1px $color-gray-light;
					}
				}
			}

			label {
				@include text(mu);
				@include font-weight(bold);
				align-items: center;
				background-color: $color-gray-neutral;
				color: $color-gray-dark;
				display: flex;
				height: 40px;
				justify-content: center;
				position: absolute;
				top: 0;
				width: 100%;

				&:hover {
					cursor: pointer;
				}
			}
		}
	}

	.mobile-filter-show-btn {
		align-items: center;
		background-color: $color-gray-dark;
		bottom: 0;
		display: flex;
		height: 80px;
		padding: 0 15px;
		position: fixed;
		width: 100%;

		.filter-show-btn {
			background-color: $color-blue;
			border-color: $color-blue;
			width: 100%;

			&:hover {
				background-color: $color-blue;
				border-color: $color-blue;
			}

			&:focus {
				background-color: $color-blue;
				border-color: $color-blue;
				box-shadow: 0 0 0 0.2rem rgba($color-orange-dark, 0.5);
			}
		}

		&.loading {
			.filter-show-btn {
				@include loader($color-white);
				color: transparent;
				position: relative;

				&:after {
					left: 50%;
					margin-left: -13px;
					margin-top: -13px;
					position: absolute;
					top: 50%;
				}
			}
		}
	}

	.filter-header {
		display: none;
	}

	.filter-description {
		@include font-weight(normal);
		@include text(lambda);
	}

	.filter-link,
	.show-more-trigger {
		@include text(lambda, 14);
		@include font-weight(semi-bold);
		background: none;
		border: 0;
		color: $color-black;
		margin: 0;
		outline: 0;
		padding: 0;

		&:hover,
		&:focus {
			text-decoration: underline;
		}

		&:disabled {
			color: $color-gray-medium-light;
			pointer-events: none;
		}
	}

	.geolocation-link {
		color: $color-blue;
		display: inline-flex;
		margin-bottom: 2rem;
		margin-top: -0.5rem;

		.icon-geolocate {
			margin-right: 0.25rem;
			stroke: $color-blue;
		}

		&.geolocating {
			text-decoration: none;

			&:after {
				-webkit-animation: ellipsis steps(4, end) 900ms infinite;
				animation: ellipsis steps(4, end) 900ms infinite;
				content: '\2026'; /* ascii code for the ellipsis character */
				display: inline-block;
				overflow: hidden;
				vertical-align: bottom;
				width: 0px;
			}

			@keyframes ellipsis {
				to {
					width: 20px;
				}
			}

			@-webkit-keyframes ellipsis {
				to {
					width: 20px;
				}
			}
		}
	}

	.range-container {
		margin: 0 1rem;
		padding-top: 1.5rem;
	}

	.view-all-trigger {
		@include text(lambda, 14);
		align-items: center;
		color: $color-blue;
		display: flex;
		height: 19px;
		margin-top: 2rem;
		overflow: hidden;

		.icon {
			height: 0.75rem;
			margin-left: 5px;
			stroke: $color-blue;
			transition: margin 0.1s ease-in-out;
			width: 1.25rem;
		}

		&:hover,
		&:focus {
			.icon {
				margin-left: 10px;
			}
		}
	}

	.view-all-panel {
		background-color: $color-gray-light;
		display: block;
		height: calc(100vh - 80px);
		left: 0;
		overflow-y: auto;
		position: fixed;
		top: 0;
		transform: translateX(100%);
		transition: all 0.2s ease-in-out;
		width: 100%;
		visibility: hidden;

		&.open {
			transform: translateX(0);
			visibility: visible;
		}

		+ .filter-title {
			margin-top: 2rem;
		}

		.view-all-back-btn {
			@include text(lambda);
			@include font-weight(semi-bold);
			align-items: center;
			color: $color-blue;
			display: flex;
			margin-left: 1.25rem;
			margin-top: 2rem;

			.icon {
				stroke: $color-blue;
			}
		}

		.view-all-close-btn {
			display: none;
		}

		.view-all-body {
			margin-bottom: 80px;
			padding: 1rem 10px;
		}

		.view-all-title-container,
		.tag-list {
			padding: 0 18px;
		}

		.range-container {
			margin: 0 2rem;
			padding: 1.5rem 0 0;
		}

		.accordion-label {
			@include text(lambda);
			@include font-weight(medium);
			color: $color-black;
			margin: 2rem 1rem 0;
		}

		.nav-accordion {
			border-top: 1px solid $color-gray-medium;
			margin-top: 1rem;

			.object-section {
				display: flex;
				padding: 0.5rem 1rem 0.5rem 0;

				.object-section-label {
					@include text(zeta);
					margin-left: 1.25rem;
					margin-right: 2rem;
					min-width: 16px;
				}

				.object-section-content {
					border-bottom: 1px solid $color-gray-light;
					padding-bottom: 0.5rem;
					width: 100%;
				}

				&:last-of-type {
					.object-section-content {
						border-bottom: 0;
					}
				}
			}
		}

		.view-all-no-accordion {
			border-top: none;
			padding: 0 18px;
		}
	}

	.filter-availability {
		padding: 0.25rem 0;

		hr {
			border-color: $color-gray-medium;
			margin: 1.5rem 0;
		}

		.secondary-filter-title {
			@include text(epsilon);
			@include font-weight(semi-bold);
			color: $color-white;
		}

		p {
			@include text(zeta);
		}

		.radio-btns {
			padding-top: 0.25rem;

			input[type='radio'] {
				&:checked {
					+ label {
						background-color: $color-blue;
						border-color: $color-blue;
					}
				}

				&:focus {
					+ label {
						background-color: $color-blue;
						border-color: $color-blue;
						box-shadow: 0 0 0 0.2rem rgba($color-orange-dark, 0.5);
					}
				}

				+ label {
					background-color: $color-blue;
					border-color: $color-blue;
				}

				&:disabled {
					+ label {
						background-color: $color-gray-light;
						border-color: $color-gray-light;
						pointer-events: none;
					}

					&:checked {
						+ label {
							background-color: $color-gray-light;
							border-color: $color-gray-light;
						}
					}
				}
			}
		}
	}

	.filter-gender {
		padding: 0.25rem 0;

		.radio-wrapper:last-of-type {
			margin-bottom: 0;
		}
	}

	.clear-btn-container {
		align-items: center;
		display: flex;
		justify-content: flex-end;
		padding: 0.75rem 20px 0;
	}

	.react-autosuggest__container {
		position: relative;

		&--open {
			.react-autosuggest__suggestions-container {
				border-bottom: 1px solid $color-gray-neutral;
			}
		}
	}

	.react-autosuggest__suggestions-container {
		background-color: $color-white;
		position: absolute;
		top: 100%;
		width: 100%;
		z-index: 2;
	}

	.react-autosuggest__suggestion--highlighted {
		background-color: $color-gray-providers;
	}

	.mobile-filter-actions {
		align-items: center;
		background-color: $color-white;
		bottom: 0;
		display: flex;
		height: 80px;
		position: fixed;
		width: 100%;
		z-index: 1;

		.buttons-container {
			@include make-container();
			display: flex;
			justify-content: space-between;
		}
	}

	.mobile-clear-btn {
		@include text(lambda);
		@include font-weight(medium);
		align-items: center;
		border: 1px solid $color-gray-neutral;
		border-radius: 2px;
		color: $color-black;
		display: flex;
		height: 37px;
		justify-content: center;
		width: calc(50% - 0.5rem);
	}

	.mobile-apply-btn {
		@include text(lambda);
		@include font-weight(medium);
		align-items: center;
		background-color: $color-gray-dark;
		border-radius: 2px;
		color: $color-white;
		display: flex;
		height: 37px;
		justify-content: center;
		width: calc(50% - 0.5rem);
	}

	@include breakpoint(md) {
		.input-icon-container {
			input {
				@include text(lambda);
				padding: 1.25rem 2.75rem 1.25rem 1.25rem;
			}
		}
	}

	@include breakpoint(lg) {
		align-self: flex-start;
		background-color: $color-gray-light;
		display: block;
		height: auto;
		left: auto;
		opacity: 1;
		overflow: visible;
		pointer-events: auto;
		position: relative;
		top: auto;
		visibility: visible;
		width: auto;
		z-index: 7;

		&.view-all-open {
			overflow: visible;
		}

		.input-icon-container {
			margin: 0;
			padding: 0;

			input {
				padding: 1.25rem 2.75rem 1.25rem 1rem;
			}

			button,
			> .icon {
				right: 1rem;
			}
		}

		.accordion-container {
			margin-bottom: 0;
			padding: 0;
		}

		.nav-accordion {
			background-color: $color-gray-light;

			.accordion-item {
				border-color: $color-gray-neutral;

				&[aria-expanded='true'] {
					.accordion-item-title {
						.accordion-item-toggle {
							color: $color-gray-dark;
						}
					}

					&.closing {
						> .accordion-item-title {
							.accordion-item-toggle {
								color: $color-gray-dark;

								&:hover,
								&:focus {
									color: $color-gray-medium;
								}
							}
						}
					}
				}

				.accordion-item-title {
					.accordion-item-toggle {
						color: $color-gray-dark;
						cursor: pointer;

						&:hover,
						&:focus {
							color: $color-blue;

							.icon:not(.icon-star) {
								fill: $color-blue;
							}
						}

						.icon:not(.icon-star) {
							fill: $color-gray-medium;
						}
					}

					.accordion-item-tags {
						background-color: $color-gray-light;
					}
				}

				.accordion-item-body {
					color: $color-gray-darkest;

					.tag-list {
						display: none;
					}

					.input-icon-container {
						margin-left: -1rem;
						width: calc(100% + 2rem);
					}
				}
			}
		}

		.checkbox-filter {
			.checkbox-wrapper {
				input[type='checkbox'] {
					+ label {
						color: $color-gray-dark;
					}
				}
			}

			img {
				display: block;
				height: auto;
				width: 80px;
			}
		}

		.distance-radio {
			margin-left: -1rem;
			width: calc(100% + 2rem);
		}

		.filter-title {
			&.mobile-only {
				display: none;
			}
		}

		.mobile-filter-show-btn {
			display: none;
		}

		.mobile-filter-header {
			display: none;
		}

		.filter-header {
			align-items: center;
			display: flex;
			justify-content: space-between;
			margin-bottom: 0.5rem;
			margin-top: 1rem;

			.filter-heading {
				@include text('kappa');
				@include font-weight(medium);
				color: $color-gray-dark;
				margin: 0;
				padding-left: 1rem;
			}

			.clear-btn-container {
				margin-right: 2px;
			}
		}

		.clear-btn-container {
			align-items: center;
			display: flex;
			justify-content: flex-end;
			padding: 0.25rem 0 1rem;
		}

		.filter-link,
		.show-more-trigger {
			color: $color-blue;
		}

		.range-container {
			margin: 0;
		}

		.view-all-panel {
			color: $color-gray-darkest;
			display: none;
			height: auto;
			min-height: 0;
			transform: none;

			&.open {
				display: block;
				overflow: visible;
				position: relative;
			}

			.mobile-filter-header {
				display: none;
			}

			.view-all-title-container {
				align-items: center;
				display: flex;
				justify-content: space-between;
				margin-bottom: 1rem;
				padding: 0;

				.filter-title {
					margin-bottom: 0;
				}

				.view-all-close-btn {
					display: block;

					.icon-menu-close {
						height: 1rem;
						stroke: $color-gray-dark;
						width: 1rem;
					}

					&:hover,
					&:focus {
						.icon-menu-close {
							stroke: $color-black;
						}
					}
				}
			}

			.view-all-body {
				margin-bottom: 0;
				padding: 0;
			}

			.rbt {
				margin: 0;
			}

			.range-container {
				margin: 0;
				padding: 1.5rem 0 0;
			}

			.tag-list {
				padding: 0 0.1rem;
			}

			.accordion-label {
				margin: 2rem 0 0;
			}

			.nav-accordion {
				border-top: 1px solid $color-gray-light;

				.object-section {
					padding-right: 1.5rem;
				}
			}

			.view-all-no-accordion {
				border-color: $color-gray-neutral;
				padding: 0;
			}

			.view-all-back-btn {
				margin-left: 0;

				&:hover,
				&:focus {
					text-decoration: underline;

					.icon {
						margin-left: -0.3125rem;
						margin-right: 10px;
						transition: margin 0.1s ease-in-out;
					}
				}
			}
		}

		.filter-availability {
			hr {
				border-color: rgba($color-black, 0.1);
			}

			.secondary-filter-title {
				color: $color-gray-dark;
			}

			.radio-btns {
				input[type='radio'] {
					&:checked {
						+ label {
							background-color: $color-gray-dark;
							border-color: $color-gray-dark;
						}
					}

					&:focus {
						+ label {
							background-color: $color-gray-dark;
							border-color: $color-gray-dark;
							box-shadow: 0 0 0 0.2rem
								rgba($color-gray-dark, 0.35);
						}
					}

					+ label {
						background-color: $color-gray-medium;
						border-color: $color-gray-medium;
					}
				}
			}
		}

		.filter-gender {
			padding: 0.25rem 0;

			.radio-wrapper:last-of-type {
				margin-bottom: 0;
			}
		}

		.mobile-filter-actions {
			display: none;
		}
	}
}
