.donut-chart-wrapper {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 90%;

	.circular-chart {
		display: block;
		max-width: 100%;
		width: 200px;
		max-height: 250px;
		margin-bottom: 0rem;
		margin-top: -1.7rem;
	}

	.circle-bg {
		fill: none;
		stroke: $color-gray-neutral;
		stroke-width: 4.5;
	}

	.circle {
		stroke: rgb(19, 126, 19);
		fill: none;
		stroke-width: 4.2;
		stroke-linecap: square;
		animation: progress 1s ease-out forwards;
		stroke-dasharray: 50 100;
	}

	.percentage {
		text-anchor: middle;
		font-size: 7px;
		font-weight: 900;
		letter-spacing: 0.2px;
		font-kerning: normal;
	}

	h2.text-center {
		font-size: 1rem;
	}

	canvas {
		display: block;
		box-sizing: border-box;
		height: 13rem;
		width: 300px;
	}

	@keyframes progress {
		0% {
			stroke-dasharray: 0 100;
		}
	}
}

@include breakpoint(sm) {
	.donut-chart-wrapper {
		padding: 2rem 0;
		margin: -30px 11px 2px 20px;
	}
}
