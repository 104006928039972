.modal {
	display: none;
	height: 100%;
	left: 0;
	opacity: 0;
	outline: 0;
	position: fixed;
	top: 0;
	transition: opacity 0.3s ease-in-out;
	width: 100%;
	z-index: 10;

	&.visible {
		display: block;
		background-color: $color-white;
	}

	&.open {
		opacity: 1;
		pointer-events: auto;
	}

	&.iframe-modal {
		.modal-inner,
		.iframe-container {
			height: calc(100vh - 60px);
		}

		.modal-inner {
			padding: 0;
			text-align: center;
		}

		iframe {
			height: calc(100vh - 60px);
		}

		.schedule-header,
		.modal-title {
			display: none;
		}
	}

	&.full-screen {
		.modal-inner-container {
			display: flex;
			flex-direction: column;
		}

		.modal-row {
			flex-grow: 1;
		}
	}

	.at & {
		height: 100%;
		width: 100%;

		.modal-container {
			background-color: $color-gray-light;
		}

		.modal-header {
			background-color: $color-white;
		}

		p {
			@include text(epsilon, delta);
		}

		a {
			@include text(epsilon, theta);
			color: $color-blue-bright;
			display: inline-block;
			margin-top: 0.25rem;
			text-decoration: none;
			word-wrap: break-word;

			&:hover,
			&:focus {
				color: $color-black;
				text-decoration: none;
			}
		}

		.modal-description {
			@include font-weight(normal);
			@include text(zeta);
		}

		.modal-subdescription {
			@include font-weight(normal);
			@include text(kappa);
			a {
				@include text(kappa);
				color: $color-blue;
			}
		}

		.banner-alert {
			margin-top: 0rem;
			width: 100%;

			p {
				@include text(kappa);
			}
		}
	}
	.desktop-only {
		display: none;
	}

	.btn-alert-close {
		background-color: $color-gray-dark !important;
		color: white !important;
		height: auto !important;
		padding: 0.5rem !important;
		float: right !important;
		margin-top: 2rem !important;
	}

	.modal-close-btn {
		background-color: transparent;
		border: none;
		height: 1rem;
		outline: 0;
		padding: 0;
		width: 1rem;

		&:hover,
		&:focus {
			cursor: pointer;
			.icon {
				stroke: $color-gray-darkest;
				stroke-width: 5px;
			}
		}

		&:focus-visible {
			border: 2px solid $color-black;
		}

		&.text-close {
			color: $color-blue;
			height: auto;
			transform: none;
			width: auto;

			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}

		.icon {
			height: 1rem;
			stroke: $color-black;
			stroke-width: 4px;
			width: 1rem;
		}
	}

	.modal-container {
		background-color: $color-white;
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 100%;
	}

	.modal-header {
		background-color: $color-gray-light;
		display: flex;
		min-height: 60px;
		align-items: center;

		.container {
			display: flex;
			justify-content: space-between;
		}

		.heading-3 {
			@include text(iota, 18);
			@include font-weight(bold);
			margin-bottom: 0;
		}
	}

	.modal-inner-container {
		overflow: auto;
	}

	.modal-body {
		flex-grow: 1;
		overflow-y: auto;
	}

	.modal-inner {
		@include make-container();
		overflow: auto;
		padding: 1.5rem 30px 4rem;

		.container {
			padding: 0;
		}
	}

	.heading-3 {
		@include text(gamma, beta);
		@include font-weight(normal);
	}

	p,
	li {
		@include text(lambda);
		.btn-link {
			text-align: left;
		}
	}

	a {
		@include text(lambda);
		color: $color-blue;
		display: inline-block;
		text-decoration: none;
		word-wrap: break-word;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	.button-group {
		display: flex;
		flex-direction: column;

		.btn-primary {
			margin-bottom: 1rem;
			width: 100%;
		}

		:last-child {
			margin-bottom: 0;
		}
	}

	.submit-btn {
		@include highlight($color-blue-bright);
		@include text(zeta);
		background-color: transparent;
		border: none;
		color: $color-white;
		float: right;
		opacity: 0;
		padding: 0;
		transition: opacity 0.2s ease-in-out, color 0.1s linear,
			background-position 0.1s linear;

		&:hover,
		&:focus {
			color: $color-black;
			cursor: pointer;
		}
	}

	.modal-title {
		@include font-weight(bold);
		@include text(zeta, 30);
		margin-bottom: 1rem;
	}

	.modal-description {
		@include font-weight(normal);
		@include text(lambda);
	}

	.banner-btn {
		background-color: $color-blue-bright;
		display: block;
		margin-bottom: 0;
		padding: 30px 0;
		width: 100%;
		text-decoration: none;

		&.disabled {
			background-color: $color-gray-neutral;
			color: $color-black;

			&:hover,
			&:focus {
				background-color: $color-gray-neutral;

				span {
					color: $color-black;

					svg {
						stroke: $color-black;
					}

					.icon-arrow-right {
						stroke: $color-black;
					}
				}
			}
		}

		span {
			@include font-weight(bold);
			@include text(iota, kappa);
			@include make-container();
			align-items: center;
			color: $color-black;
			display: flex;
			justify-content: space-between;

			svg {
				stroke: $color-black;
			}

			.icon-arrow-right {
				stroke: $color-black;
			}
		}

		&:hover,
		&:focus {
			background-color: $color-black;

			span {
				color: $color-white;

				svg {
					stroke: $color-white;
				}

				.icon-arrow-right {
					stroke: $color-white;
				}
			}
		}
	}

	.no-span {
		@include font-weight(bold);
		@include text(iota, kappa);
		@include make-container();
		align-items: center;
		color: $color-black;
		display: flex;
		justify-content: space-between;

		svg {
			stroke: $color-black;
		}

		.icon-arrow-right {
			stroke: $color-black;
		}

		&:hover,
		&:focus {
			color: $color-white;

			svg {
				stroke: $color-white;
			}

			.icon-arrow-right {
				stroke: $color-white;
			}
		}
	}

	.content-slides {
		.sign-in-btn {
			display: none;
		}
	}

	//Text box on a modal needs different formatting
	.form {
		&.ready {
			&.invalid {
				input {
					padding-left: 1rem;
				}
			}

			.error-message {
				padding-left: 1rem;
			}
		}

		.error-message {
			align-items: left;
			justify-content: left;
		}
	}

	input[type='text'] {
		background-color: white;
		padding-left: 1rem;
		text-align: left;
		padding-top: 2rem;
	}

	@include breakpoint(lg) {
		background-color: rgba($color-gray-dark, 0.75);
		overflow-y: scroll;

		&.visible {
			align-items: center;
			background-color: rgba($color-gray-dark, 0.75);
			display: flex;
		}

		&.iframe-modal {
			.modal-inner {
				height: 500px;
				width: 698.666px;
			}

			.iframe-container {
				height: calc(454px - (2.1875rem + 4px));
			}

			iframe {
				height: 500px;
				width: 698.666px;
			}

			.schedule-header,
			.modal-title {
				display: block;

				&:first-child {
					display: none;
				}
			}
		}

		.at & {
			.modal-container {
				flex-direction: column;
				height: 100%;
				max-width: none;
				padding: 0;
				width: 100%;
			}

			.modal-header {
				width: 100%;

				.heading-3 {
					display: block;
				}

				.modal-close-btn {
					transform: none;

					.icon {
						height: 1rem;
						stroke-width: 4px;
						width: 1rem;
					}
				}
			}

			.modal-body {
				width: 100%;
			}

			.modal-inner {
				padding: 1.5rem 70px 4rem;
			}
		}

		&.full-screen {
			.modal-container,
			.modal-inner-container {
				flex-direction: column;
				height: 100%;
				margin: 0;
				max-width: none;
				padding: 0;
				width: 100%;
			}

			.modal-inner-container {
				flex: 1;
			}

			.modal-header {
				background-color: $color-gray-light;
				display: flex;
				min-height: 60px;
				align-items: center;
				width: 100%;

				.heading-3 {
					display: block;
				}

				.modal-close-btn {
					transform: none;

					.icon {
						height: 1rem;
						stroke-width: 4px;
						width: 1rem;
					}
				}
			}

			.modal-row {
				align-items: center;
				display: flex;
			}

			.modal-body {
				width: 100%;
			}

			.modal-inner {
				padding: 1.5rem 70px 4rem;
			}
		}

		.modal-container {
			@include make-container();
			align-items: flex-start;
			flex-direction: row;
			height: auto;
			background-color: transparent;
			justify-content: center;
			max-height: 100vh;
			pointer-events: auto;
		}

		.modal-inner-container {
			@include make-col(10);
			background-color: $color-white;
			margin: 3rem 0;
			padding: 0;
			pointer-events: auto;
		}

		.modal-header {
			background-color: transparent;

			.heading-3 {
				display: none;
			}

			.modal-close-btn {
				height: auto;
				margin-left: auto;
				transform: translate(100%, 20px);
				width: auto;
				pointer-events: auto;

				&:hover,
				&:focus {
					.icon {
						stroke: $color-gray-darkest;
						stroke-width: 4px;
					}
				}
				.icon {
					height: 1.25rem;
					stroke-width: 2px;
					width: 1.25rem;
				}
			}
		}

		.modal-body {
			display: flex;
			flex-grow: 1;
			justify-content: center;
			align-items: center;
		}

		.modal-inner {
			padding: 0 95px 95px;
		}

		.button-group-container {
			display: flex;
			justify-content: center;
		}

		.button-group {
			display: inline-flex;
			margin: auto;
		}
	}
}
