.hv-no-results {
	.no-results-wrapper {
		background-color: #ffefef;
		padding-left: 2rem;
		padding-right: 2rem;
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	h4 {
		@include text(theta);
		@include font-weight(bold);
	}

	ul {
		list-style-position: inside;
		padding-left: 0;
	}

	a {
		color: $color-blue;
		padding-left: 0.8rem;
	}

	.reset-filters {
		display: flex;
		flex-direction: row;
		padding-top: 1.5rem;

		p {
			margin-bottom: 0;
		}
	}

	.text {
		@include text(kappa);
	}

	@include breakpoint(lg) {
		.no-results-wrapper {
			padding-left: 6rem;
			padding-right: 0;
			padding-top: 3rem;
			padding-bottom: 3rem;
		}
	}
}
