.location-profile-detail {
	padding: 0;
	margin-bottom: 1.2rem;

	address {
		font: inherit;
		p {
			font: inherit;
			margin-bottom: 0px;
		}
	}

	.icon-transport {
		fill: black;
		width: 2rem;
		height: 2rem;
	}

	.transport-info-wrapper {
		display: flex;
		gap: 3px;
		margin-top: 10px;
	}

	.transport-item-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		p {
			font-size: 12px;
		}
	}

	.location-profile-header {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		padding: 0;
	}

	.location-profile-heading {
		@include text(iota);
		color: $color-red;
		line-height: 18px;
		margin-bottom: 0;
		padding: 25px 30px;
		@include font-weight(medium);
	}

	.location-image-heading {
		margin-left: auto;
		img {
			height: 75px;
			width: auto;
		}
	}

	.open-weekend-icon-container {
		display: flex;
		font-size: 0.875rem;
		p {
			font: inherit;
		}
	}

	.location-detail {
		@include font-weight(medium);
		@include text(lambda);
		color: $color-gray-dark;
		flex-direction: row;
		justify-content: space-between;
		margin: 0;
		> div {
			background: $color-white;
			min-height: auto;
			width: 100%;
		}
	}

	.location-detail-info {
		order: 2;
		padding: 0px;
		.info-inner {
			padding: 16px 30px 0;
		}
		.location-type {
			margin-bottom: 10px;
		}
		.location-name {
			color: $color-black;
			font-family: $font-avenir-heavy;
			font-size: 20px;
			line-height: 24px;
			margin-bottom: 10px;
		}
		.location-specs {
			margin-bottom: 1rem;
			min-height: 40px;
		}
		.scheduling-btn {
			margin-bottom: 1.25rem;
		}
	}

	.location-address {
		@include font-weight(medium);
		@include text(lambda, mu);
		margin-bottom: 25px;
		.location-miles {
			color: $color-blue-providers;
			text-decoration: none;
			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
		address {
			font: inherit;
		}
	}

	.location-office-hours {
		.office-title {
			font-family: $font-avenir-heavy;
			margin-bottom: 5px;
		}
		.office-hours-list {
			li {
				line-height: 18px;
				span {
					display: inline-block;
					margin-right: 3px;
					width: 37px;
				}
			}
		}
	}

	.location-detail-contact {
		order: 3;
		padding: 0px;
		img {
			display: none;
			width: 100%;
		}
	}
	.location-contact {
		padding: 0 30px;
		margin: 20px 0 10px;
		//this makes content links clickable on moble.
		//fix for anchoring that uses margin-top: -60px had these behind
		position: relative;
		z-index: 2;

		.contact-title {
			@include font-weight(bold);
		}
		.contact-phone a {
			color: $color-blue-providers;
			text-decoration: none;
			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
		.contact-fax {
			margin-bottom: 20px;
			a {
				text-decoration: none;
				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}
		}

		.contact-email {
			a {
				color: $color-blue-providers;
				text-decoration: none;
				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}
		}

		.contact-website {
			padding-top: 0.8rem;
			padding-bottom: 2rem;
			a {
				color: $color-blue-providers;
				text-decoration: none;
				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}
			p {
				font-family: AvenirMedium, Helvetica, sans-serif;
				font-weight: normal;
				font-size: 0.875rem;
				line-height: 1.4285714286;
				margin-bottom: 0;
			}
		}

		.contact-link a {
			color: $color-blue-providers;
			text-decoration: none;
			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
	}

	.location-hours-contact-info {
		.location-contact {
			padding: 0;
		}
	}

	.location-info-map {
		min-height: 240px;
		order: 1;
		padding: 0px;
		aspect-ratio: 3 / 2;
		.location-map-image {
			height: 100%;
			position: relative;
			img {
				display: block;
				height: 100%;
				object-fit: cover;
				width: 100%;
			}
		}
	}

	@include breakpoint(md) {
		.location-profile-heading {
			@include text(delta);
			padding: 40px 0px;
		}

		.location-detail {
			flex-wrap: nowrap;
			margin: 0 -10px;
		}

		.location-detail-info {
			order: 1;
			padding: 0px 10px;
			.info-inner {
				background: $color-gray-light;
				height: 100%;
			}
			&.col-md-4 {
				.info-inner {
					padding: 16px 20px 20px;
				}
			}
			.scheduling-btn {
				margin-bottom: 1rem;
			}
		}

		.location-detail-contact {
			order: 2;
			padding: 0px 10px;
			img {
				display: block;
				height: 100%;
			}
			.contact-inner {
				background: $color-gray-light;
				height: 100%;
			}
			.location-contact {
				padding: 0 20px;
			}
		}

		.location-hours-contact-info {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			gap: 1rem;
			justify-content: space-between;
			.location-contact {
				margin: 0;
				.contact-title {
					margin-bottom: 5px;
				}
			}
		}

		.location-info-map {
			order: 3;
			padding: 0 10px;
			aspect-ratio: unset;
		}

		.location-image-heading {
			display: none;
		}
	}
}
