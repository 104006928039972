.hv-search-and-filter {
	margin-bottom: 2rem;

	.toggle-container {
		height: 2.375rem;
		padding-left: 1.875rem;
		padding-right: 1.875rem;
	}

	.toggle-label {
		@include text(kappa);
		@include font-weight(normal);
	}

	.search-form {
		padding: 1rem 2rem 1.5rem;
	}

	.filter-label {
		@include text(lambda, gamma);
		@include font-weight(bold);
	}

	.input-with-icon {
		position: relative;
		height: 3rem;
		margin-bottom: 0.25rem;

		.icon {
			position: absolute;
			top: 50%;
			right: 1.25rem;
			transform: translateY(-50%);
			stroke: $color-gray-medium-light;
		}
	}

	.filter-input {
		@include text(kappa);
		background-color: $color-white;
		border-color: $color-white;
		color: $color-black;
		height: 3rem;
		text-align: left;
		padding-left: 1rem;

		&:focus {
			background-color: $color-white;
		}

		&::placeholder {
			color: $color-gray-medium-light;
			text-align: left;
		}
	}

	.select-with-icon {
		height: 3rem;
		position: relative;
		margin-bottom: 0.5rem;

		.icon {
			position: absolute;
			top: 50%;
			right: 1.25rem;
			transform: translateY(-50%);
		}
	}

	.filter-select {
		@include text(kappa);
		padding: 10px 16px;
		appearance: none;
		background-color: $color-white;
		border-color: $color-white;
		color: $color-black;
		height: 3rem;
		width: 100%;
		margin-bottom: 0.75rem;
	}

	@include breakpoint(lg) {
		// @include make-container();

		.toggle-container {
			padding: 0 1.25rem;
		}

		.search-form {
			display: flex;
			justify-content: space-between;
			width: 100%;
		}

		.filter-control {
			flex-grow: 4;
			padding-right: 1.25rem;

			&:last-child {
				flex-grow: 3;
				padding-right: 0;
			}
		}
	}
}
