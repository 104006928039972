/*
 *
 * COLOR VARIABLES
 *
 */

/* Monochromes */
$color-black: #000;
$color-white: #fff;
$color-off-white: #fafafa;
$color-gray-arrow: #aaa;
$color-gray-footer: #333;
$color-gray-darkest: #222;
$color-gray-dark-light: #4e4e4e;
$color-gray-dark: #363534;
$color-gray-medium: #595756;
$color-gray-medium-light: #707070;
$color-gray-neutral: #bfbfbf;
$color-gray-light: #eee;
$color-gray-providers: #f9f9f9;
$color-gray-providers-banner: #f2f2f2;
$color-gray-table-border: #d5d6d2;

/* Blues */
$color-blue: #00759a;
$color-blue-bright: #00b0e6;
$color-teal: #48b9c7;
$color-blue-providers: #006dac;
$color-blue-highlight: #e5f1f5;

/* Reds */
$color-red: #a71930;
$color-red-light: #ffefef;

/* Oranges */
$color-orange: #df7a00;
$color-orange-dark: #913d00;
$color-orange-delay: #f09135;
$color-orange-delay-alt: #b5600e;

/* Yellows */
$color-yellow: #ff8c00;
$color-yellow-alt: #e46e18;
$color-yellow-highlight: #ffff99;
$color-neutral-yellow: #fff8da;
$color-neutral-yellow-light: #fffbef;
$color-neutral-light: #efefef67;

/* Purples */
$color-purple: #5f3171;

/* Browns */
$color-khaki: #d7d3c7;

/* Greens */
$color-green: #86bf6b;
$color-green-t3: #74c15f;
$color-green-at: #498d37;
$color-green-providers: #137e13;
$color-green-pale-alert: #e2f5e4;

/* Alert Colors */
$color-success: #dff0d8;
$color-info: #d9edf7;
$color-danger: #fcf8e3;
$color-warning: #f2dede;

$color-success-hover: #afc9a5;
$color-info-hover: #b5d0dd;
$color-danger-hover: #dbd6bc;
$color-warning-hover: #cfaaaa;

/* Social Media Colors */
$color-facebook: #117cef;
$color-facebook-hover: #0f74e2;
$color-twitter: #1ea4f0;
$color-twitter-hover: #1b94d8;
