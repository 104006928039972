/*
 *
 * TYPOGRAPHY VARIABLES AND MAPS
 *
 */

//for.ttf says no supported format found
//tried all the .woff files and added the thinest

@font-face {
	font-family: 'AvenirLight';
	src: url('../fonts/AvenirLight.otf') format('opentype');
}

@font-face {
	font-family: 'AvenirBook';
	src: url('../fonts/AvenirBook.otf') format('opentype');
}

@font-face {
	font-family: 'AvenirRoman';
	src: url('../fonts/AvenirRoman.otf') format('opentype');
}

@font-face {
	font-family: 'AvenirMedium';
	src: url('../fonts/AvenirMedium.otf') format('opentype');
}

@font-face {
	font-family: 'AvenirHeavy';
	src: url('../fonts/AvenirHeavy.otf') format('opentype');
}
@font-face {
	font-family: 'AvenirBlack';
	src: url('../fonts/AvenirBlack.otf') format('opentype');
}

//create four new fonts for the different wacky fonts
//which matches book, and then try to determine others
//figure out how to set up sass function include font weight and output font family and weight
$font-avenir-light: 'AvenirLight', Helvetica, sans-serif;
$font-avenir-book: 'AvenirBook', Helvetica, sans-serif;
$font-avenir-roman: 'AvenirRoman', Helvetica, sans-serif;
$font-avenir-medium: 'AvenirMedium', Helvetica, sans-serif;
$font-avenir-heavy: 'AvenirHeavy', Helvetica, sans-serif;
$font-avenir-black: 'AvenirBlack', Helvetica, sans-serif;
/*
  * Font Weight map
  * Matching keys gets used together by default
  * Used in font-weight() mixin to return font family and for mock fonts the weight.
  */
$font-weight-scale: (
	light: $font-avenir-light,
	normal: $font-avenir-book,
	semi-bold: $font-avenir-roman,
	medium: $font-avenir-medium,
	bold: $font-avenir-heavy,
	extra-bold: $font-avenir-black,
);

/*
  * Font Size and Line Height Maps
  * Matching keys gets used together by default
  * Used in text() mixin and calulateLineHeight() function
  */
$font-size-scale: (
	alpha: 60,
	beta: 48,
	gamma: 40,
	delta: 30,
	epsilon: 26,
	zeta: 24,
	eta: 22,
	theta: 20,
	iota: 18,
	kappa: 16,
	lambda: 14,
	mu: 12,
);

$line-height-scale: (
	alpha: 60,
	beta: 48,
	gamma: 40,
	delta: 30,
	epsilon: 32,
	zeta: 32,
	eta: 32,
	theta: 30,
	iota: 24,
	kappa: 22,
	lambda: 20,
	mu: 18,
);
