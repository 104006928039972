.echeckin-modal-header {
	@extend .modal-header;
	background-color: $color-gray-dark !important;

	.heading-3 {
		color: $color-white;
		padding-bottom: 20px;
		padding-top: 25px;
	}

	a {
		.icon-phs-logo,
		.icon-phs-icon {
			fill: $color-white;
		}
	}

	.modal-logo-container {
		display: none;
		align-self: flex-start;
		height: auto;
		padding-bottom: 19px;
		padding-top: 15px;

		.icon-phs-logo {
			display: block;
			height: 26px;
			width: 226px;
		}

		a {
			display: block;
			line-height: 1;
		}
	}

	.modal-close-btn {
		background-color: transparent;
		border: none;
		height: 1rem;
		outline: 0;
		padding: 0;
		width: 1rem;
		padding-bottom: 20px;
		padding-top: 25px;
		svg {
			height: 1rem;
			stroke: $color-white;
			stroke-width: 4px;
			width: 1rem;
		}

		&:hover,
		&:focus {
			cursor: pointer;

			svg {
				stroke: rgba($color-white, 0.5);
			}
		}
	}

	@media (min-width: $mobile-nav-breakpoint) {
		.modal-logo-container {
			display: block;
		}

		.heading-3 {
			padding-right: 250px;
		}
	}
}
