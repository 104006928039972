.portal {
	.select-wrapper-plan,
	.select-wrapper {
		@include text(lambda);
		color: $color-gray-dark;
		position: relative;
		.arrow-icon-wrapper {
			pointer-events: none;
			position: absolute;
			right: 1.25rem;
			top: 30%;
			.arrow-down {
				stroke: $color-black;
			}
		}

		.select-title {
			@include text(mu);
			color: $color-gray-dark;
		}

		&[aria-expanded='true'] {
			.arrow-down {
				transform: rotate(180deg);
			}
		}
	}

	.section-description {
		@include text(kappa);
	}

	a,
	.nav-btn {
		color: $color-blue;
		text-decoration: none;
		li {
			margin-bottom: 0.5rem;
		}

		&:hover {
			text-decoration: underline;
		}
	}

	.nav-btn {
		display: block;
		margin-bottom: 0.8rem;
	}

	.title-section {
		margin-bottom: 2rem;

		.second-header {
			@include text(zeta);
			@include font-weight(medium);
			margin-bottom: 0.25rem;
		}

		.section-description {
			&:last-of-type {
				margin-bottom: 0.75rem;
			}
		}

		.btn-link {
			background-color: $color-gray-dark;
			border-color: $color-gray-dark;
			color: $color-white;
			margin-bottom: 2rem;
			width: 100%;
		}
	}

	.external-link {
		@include font-weight(semi-bold);
		span {
			padding-right: 0.25rem;
		}

		&:focus,
		&:hover {
			text-decoration: none;

			span {
				text-decoration: underline;
			}
		}

		.icon-external {
			height: 10px;
			stroke: $color-blue;
			width: 10px;
		}
	}

	.print-btn {
		&:hover,
		&:focus {
			.icon-print {
				stroke: $color-gray-dark;
			}
		}

		.icon-print {
			height: 20px;
			stroke: $color-gray-medium;
			width: 20px;
		}
	}

	.portal-list {
		@include font-weight(semi-bold);

		li {
			border-bottom: 1px solid $color-gray-neutral;
			padding: 0.5rem;
		}
	}

	.active {
		@include font-weight(bold);
		color: $color-black;
	}

	.grid-row {
		@include make-row();
	}

	.first-header {
		@include font-weight(medium);
		@include text(iota);
		color: $color-red !important;
		margin-bottom: 1rem;
	}

	.second-header-big {
		@include text(iota, 23);
		@include font-weight(bold);
		color: $color-gray-dark;
		margin-bottom: 1rem;
		padding: 1rem 2rem;
	}

	.second-header {
		@include font-weight(medium);
		@include text(theta);
		margin-bottom: 1rem;
	}

	.sub-header {
		@include font-weight(bold);
		@include text(iota);
		color: $color-black;
		margin-bottom: 1.5rem;
	}

	.informational {
		@include font-weight(semi-bold);
		@include text(lambda);
		margin: 0.5rem 0;
	}

	.section-break {
		background-color: $color-gray-neutral;
		border: 1px solid $color-gray-neutral;
		margin-top: 2rem;
	}

	.no-wrap {
		white-space: nowrap;
	}

	.icon-arrow-up {
		height: 7.42px;
		width: 7.5px;
	}

	.icon-download {
		height: 11.89px;
		width: 10px;
	}

	@include breakpoint(md) {
		.first-header {
			@include font-weight(light);
			@include text(delta);
		}
	}

	@include breakpoint(lg) {
		.select-wrapper-plan,
		.select-wrapper {
			@include text(kappa);
		}
		.second-header {
			@include text(theta);
			@include font-weight(bold);
			color: $color-gray-dark;
			margin-bottom: 1rem;
		}

		.rail-header {
			@include text(iota, 23);
			@include font-weight(bold);
			color: $color-gray-dark;
			margin-bottom: 1rem;
		}

		.text-content {
			li {
				margin-bottom: 1rem;
			}
		}

		main {
			h1 {
				@include text(delta);
				@include font-weight(light);
				color: $color-red;
				margin-bottom: 1rem;
			}

			.overview-text {
				@include text(zeta);
				@include font-weight(light);
				color: $color-gray-dark;
				margin-bottom: 3rem;
			}
		}

		.title-section {
			.btn-link {
				width: 80%;
			}
		}
	}
}

:lang(en) {
	.show-for-english {
		display: block;
	}
	.show-for-spanish {
		display: none;
	}
}

:lang(es) {
	.show-for-english {
		display: none;
	}
	.show-for-spanish {
		display: block;
	}
}
