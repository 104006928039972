.float {
	div {
		height: 150px;
		margin: 5px;
		width: 50px;
	}
	.left {
		background: $color-khaki;
		float: left;
	}

	.right {
		background: $color-blue;
		float: right;
	}

	&:after {
		content: '';
		clear: both;
		display: block;
	}
}
