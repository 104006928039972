.ngde-contact-us {
    @include make-container();
    padding-top: 192px;
    margin-right: auto;
    margin-left: auto;
    max-width: $lg-xl-breakpoint;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;

    .contact-section {
        &:before {
            content: '';
            display: block;
            height: 150px;
            margin-top: -150px;
            pointer-events: none;
            visibility: hidden;
        }
    }

    svg.icon.arrow-down-anchor {
        display: none;
    }

    h1 {
        color: $color-red;
        @include text(eta);
        margin-bottom: 1rem;
    }

    .above-header {
        margin-bottom: 2rem;
    }

    a {
        text-decoration: none;
    }

    .grid-row {
        @include make-row();

        section {
            overflow: hidden;
            width: 100%;
        }
    }

    svg {
        &.icon {
            &.my-chart-logo {
                width: 13rem;
                height: 3.2rem;
                stroke: none;
            }
        }

        &.icon-info-alert {
            stroke: $color-blue;
            stroke-linecap: round;
            stroke-width: 0px;
            width: 60px;
            margin: 0px 11px;
        }
    }

    .icon.icon-menu-close {
        color: $color-black;
        height: 11px;
        width: 11px;
    }

    p {
        &.section-description {
            padding-right: 10px;

            &.blue-alert {
                background-color: $color-info;
                padding: 25px 87px 25px 22px;
                display: flex;
                margin: 11px -34px;
                width: 131%;
                min-width: 300px;
            }

            &.appeal {
                margin-bottom: 120px;
                @include text(kappa);
            }

            a {
                @include text(kappa);
            }
        }

        &.mychart-message {
            font-size: 1rem;
            width: 100%;
            margin-left: 10px;
            margin-top: 10px;
        }
    }

    span {
        &.blue-alert-message {
            padding: -2px;
            display: inline-block;
        }
    }

    .chat-with-us-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .chat-with-us-content {
        padding-top: 30px;
    }

    .chat-boxes {
        //margin-right: 59px;
        text-align: center;
        transform: scale(0.85);
        margin-bottom: 46px;

        img {
            width: 100%;
        }
    }

    .talk-bubble {
        margin: 17px 12px;
        display: inline-block;
        position: relative;
        width: 275px;
        height: 53px;
    }

    .border {
        background-color: $color-gray-light;
        border: 0px solid $color-gray-neutral;

        &:first-of-type {
            background-color: $color-blue;
            border: 0px solid $color-gray-neutral;
            color: $color-white;
            margin-left: 113px;
            margin-bottom: 1px;
        }
    }

    .round {
        border-radius: 12px;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
    }

    .tri-right {
        &.border {
            &.btm-left-in {
                &:before {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    left: 30px;
                    right: auto;
                    top: auto;
                    bottom: -40px;
                    border: 0px solid;
                    border-color: $color-gray-medium-light transparent transparent $color-gray-medium-light;
                }

                &:after {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    left: 38px;
                    right: auto;
                    top: auto;
                    bottom: -20px;
                    border: 12px solid;
                    border-color: $color-gray-light transparent transparent $color-gray-light;
                }
            }

            &.btm-right-in {
                &:before {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    left: auto;
                    right: 30px;
                    bottom: -40px;
                    border: 0px solid;
                    border-color: $color-blue $color-blue transparent transparent;
                }

                &:after {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    left: auto;
                    right: 30px;
                    top: auto;
                    bottom: -20px;
                    border: 12px solid;
                    border-color: $color-blue $color-blue transparent transparent;
                }
            }
        }
    }

    .talktext {
        padding: 1em;
        text-align: left;
        line-height: 1.5em;

        p {
            -webkit-margin-before: 0em;
            @include text(kappa);
            vertical-align: middle;
            text-align: center;
        }
    }

    .navigation-col {
        display: block;
    }

    .mobile-nav-accordion {
        h2.second-header {
            background-color: $color-gray-light;
            padding: 25px 19px 24px 60px;
            margin: -15px 2px 20px -31px;
            width: 130%;
        }
    }

    .down-arrow-container {
        margin-left: 50%;
    }

    .mobile-nav-col {
        .navigation-col {
            display: block;

            .not-selected h2 {
                font-size: 0.875rem;
                line-height: 1.4285714286;
                font-family: AvenirRoman, Helvetica, sans-serif;
                font-weight: normal;
                color: $color-black;
                margin-bottom: 0rem;
                padding: 1rem 2rem;
            }
        }
    }

    .accordion {
        border-radius: 8px;
        border-width: 1px;
        flex-direction: column;
        height: auto;
        background-color: $color-gray-light;
        width: 125%;
        margin-left: -34px;
        padding-left: 40px;

        .accordion-item {
            &.active {
                .accordion-item-body {
                    display: block;
                    width: 128%;
                }
            }
        }

        &.action-block-accordion {
            .action-block {
                display: flex;
                justify-content: space-between;
                width: 88%;
                margin: 6px 0px;
            }

            .accordion-item-body {
                background-color: $color-white;
                margin: 0 0 0 -44px;
            }

            .accordion-item {
                &.active {
                    .icon-arrow-down {
                        display: none;
                    }

                    .icon {
                        &.icon-menu-close {
                            display: block;
                        }
                    }
                }

                .icon {
                    &.icon-menu-close {
                        display: none;
                    }
                }
            }
        }
    }

    .mobile-nav-trigger {
        @include text(iota);
        @include font-weight(medium);
        align-items: center;
        background-color: $color-gray-light;
        display: flex;
        height: 70px;
        justify-content: space-between;
        margin-bottom: 2rem;
        margin-left: -30px;
        padding: 0 30px;
        width: 100vw;
    }

    .nav-list-container {
        background-color: $color-white;
        pointer-events: none;
        overflow: auto;
        visibility: hidden;
        opacity: 0;
        top: 20vh;
        padding-bottom: 80px;
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        transition: visibility 0s ease-in-out 400ms, opacity 0.2s ease-in-out, top 0.2s ease-in-out;
        z-index: 9;

        hr {
            width: 90%;
            margin: 18px 25px 18px 25px;
        }
    }

    .mobile-nav-header {
        align-items: center;
        background-color: $color-gray-light;
        display: flex;
        height: 70px;
        justify-content: space-between;
        margin-bottom: 1rem;
        padding: 0 30px;

        .mobile-close-container {
            .mobile-close-btn {
                &:focus {
                    outline: none !important;
                }
            }
        }

        .nav-heading {
            @include text(iota);
            @include font-weight(semi-bold);
            margin-bottom: 0;
        }
    }

    .heading-1 {
        padding: 0 30px;
    }

    .nav-list {
        display: flex;
        flex-direction: column;
        padding: 0 0px;
    }

    .nav-item {
        @include text(kappa);
        @include font-weight(semi-bold);
        align-items: center;
        display: flex;
        min-height: 40px;
        padding: 0.5rem 20px;

        a {
            @include text(lambda);
            color: $color-gray-dark;
            text-decoration: none;
            margin-left: 10px;
            margin-top: 4px;
        }

        &.active {
            @include font-weight(bold);
            border-left-color: $color-gray-dark;
        }
    }

    .section-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
    }

    option:disabled {
        display: none;
    }

    .content-wrapper {
        @include text(lambda);
        border-bottom: 1px solid $color-gray-neutral;
        border-radius: 0px;
        color: $color-black;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 20px 0px 8px 0px;
        width: 100%;

        .content-label {
            max-width: 75%;
            flex-wrap: wrap;
        }

        .content-number {
            color: $color-blue;
            text-align: right;
        }
    }

    span#complaint,
    span#chat,
    span#phonenumbers,
    span#contactus {
        h2.second-header {
            @include text(iota);
        }
    }

    &.nav-open {
        .nav-list-container {
            display: block;
            opacity: 1;
            pointer-events: auto;
            visibility: visible;
            top: 0;
            transition-delay: 0s;
        }
    }

    &.trigger-fixed {
        margin-bottom: 2rem;

        .mobile-nav-trigger {
            top: 0;
            z-index: 8;
            transition: top 0.2s;
        }

        .navbar-scroll-up & {
            .mobile-nav-trigger {
                top: 60px;
            }
        }

        .navbar-scroll-down & {
            .mobile-nav-trigger {
                top: 0;
            }
        }

        .body-fixed & {
            .mobile-nav-trigger {
                transition: top 0.3s ease-in-out;
            }
        }

        .mobile-nav-open & {
            .mobile-nav-trigger {
                top: 100vh;
            }
        }
    }

    @include breakpoint(md) {
        padding-top: 200px;

        h1 {
            @include text(gamma);
        }

        .chat-with-us-wrapper {
            flex-direction: unset;
            justify-content: unset;
        }

        .chat-boxes {
            margin-right: -24px;
            text-align: unset;
            transform: scale(0.75);
            margin-left: -44px;
        }

        svg {
            &.icon {
                &.icon-info-alert {
                    width: 19px;
                }
            }
        }

        p {
            &.section-description {
                padding-right: 10px;

                &.blue-alert {
                    background-color: $color-info;
                    padding: 20px;
                    display: flex;
                    margin: unset;
                    width: unset;
                    height: unset;
                }
            }

            &.mychart-message {
                @include text(lambda);
                width: 70%;
                margin-left: 28px;
                margin-top: 10px;
            }
        }

        .mobile-nav-col {
            display: none;
        }

        .mobile-nav-accordion {
            h2.second-header {
                display: none;
            }
        }

        &.column-fixed {
            .nav-list {
                padding: 0;
                top: 50px;
            }
        }

        &.column-end {
            margin-top: auto;

            .nav-list {
                position: relative;
                flex: 0 0 100%;
                max-width: 100%;
            }
        }

        .nav-list {
            padding: 0;
        }

        .mobile-nav-trigger,
        .mobile-nav-header,
        .heading-1 {
            display: none;
        }

        .nav-list-container {
            background-color: transparent;
            display: block;
            height: auto;
            opacity: 1;
            overflow: visible;
            pointer-events: auto;
            visibility: visible;
            position: relative;
            top: auto;
            left: auto;
            width: auto;
            z-index: 0;
        }

    }

    @include breakpoint(lg) {
        padding-top: 300px;
        margin-bottom: 0rem;

        h1 {
            @include text(gamma);
        }

        svg {
            &.icon {
                &.icon-info-alert {
                    width: 22px;
                    margin-right: 13px;
                }
            }
        }

        .navigation-col {
            display: block;
        }

        .content-wrapper {
            padding: 20px 0px 8px 0px;
            width: 49%;
        }

        .modal-inner-container {
            max-width: 50% !important;
        }
    }

    hr.contact-section {
        /* color: #8d2a2a2e; */
        border-color: #f3eded;
        background-color: #cdc5c5;
        border: 0.5px #e9e4e4 solid;
        margin-bottom: 26px;
    }
}
