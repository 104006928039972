.alert {
	border: 1px solid transparent;
	border-radius: 4px;
	color: $color-black;
	margin-bottom: 20px;
	padding: 15px;

	.alert-link {
		@include font-weight(bold);
	}
}

.alert-success {
	background-color: $color-success;
}

.alert-info {
	background-color: $color-info;
}

.alert-warning {
	background-color: $color-warning;
}

.alert-danger {
	background-color: $color-danger;
}
