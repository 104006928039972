.provider-locations {
	padding: 0;
	padding-left: 0px;
	padding-right: 0px;

	.modal .modal-inner {
		padding: 0;
	}

	@include breakpoint(md) {
		.provider-locations {
			padding-left: 0px !important;
			padding-right: 0px !important;
		}

		.location-block {
			display: flex;
			flex-wrap: nowrap;

			.location-info-container,
			.location-list-nav {
				flex: 0 1 calc(100% / 3);
				max-width: calc(100% / 3);
			}

			.location-info {
				margin: 1rem 0;
			}

			.location-info-list {
				display: flex;
				flex-wrap: wrap;
				justify-items: flex-start;
				width: 100%;

				.location-item {
					display: flex;
					flex-basis: calc(100% / 2);
					max-width: calc(100% / 2);
				}

				.location-info-container {
					flex: none;
					padding-right: 2px;
					max-width: none;
					width: 100%;
				}
			}
		}
	}

	@include breakpoint(lg) {
		.location-block {
			.location-info-list {
				.location-item {
					display: flex;
					flex-basis: calc(100% / 3);
					max-width: calc(100% / 3);
				}
			}
		}
	}
}
