.row-primary-button {
	align-items: center;
	background-color: $color-white;
	display: flex;
	flex-wrap: wrap;
	padding: 1.08rem 2rem 0.83rem 1.25rem;
	text-decoration: none;
	width: 100%;

	&:hover {
		background-color: $color-gray-dark;
		color: $color-white;

		.appointment-type {
			color: $color-gray-light;
		}

		.icon-office-visit,
		.icon-phone-visit,
		.icon-video-visit,
		.icon-arrow-right {
			fill: $color-white;
			stroke: $color-white;
		}

		.icon-action-needed {
			border: 2px solid $color-white;
		}

		&.on-time,
		&.delayed {
			.estimated-time,
			.scheduled-time,
			.delay {
				color: $color-white;
			}
		}
	}

	&.on-time {
		.appointment-eta {
			background-color: $color-green-t3;
			color: $color-black;
		}

		.scheduled-time {
			color: $color-green-at;
		}
	}

	&.delayed {
		.appointment-eta {
			background-color: $color-orange-delay;
			color: $color-black;
		}

		.estimated-time,
		.delay {
			color: $color-orange-delay-alt;
		}

		.appointment-date {
			.scheduled-time {
				@include text(mu);
				@include font-weight(normal);
				text-decoration: line-through;
			}
		}
	}

	.appointment-eta {
		@include text(kappa);
		@include font-weight(bold);
		align-items: center;
		display: flex;
		height: 30px;
		justify-content: center;
		margin: -1.08rem -2rem 1.75rem -1.25rem;
		width: calc(100% + 3.25rem);
	}

	.row-text {
		text-align: left;
		width: 100%;
	}

	.row-icon-right {
		display: flex;
		margin: auto 0 6px auto;
	}

	&.row-secondary-button {
		@include font-weight(bold);
		background-color: $color-blue-bright;
		color: $color-black;
		min-height: 80px;

		&:hover {
			background-color: $color-black;
			color: $color-white;

			.icon-arrow-right {
				stroke: $color-white;
			}
		}

		&.action-complete {
			align-items: center;
			background-color: $color-white;
			border-top: 4px solid $color-gray-medium;
			color: $color-gray-medium;
			pointer-events: none;

			.row-icon-success {
				display: inline;
				height: 30px;
				padding-right: 25px;
			}

			.row-icon-right {
				display: none;
			}

			.icon-success {
				fill: $color-gray-medium;
				stroke: $color-white;
			}
		}

		.row-icon-success {
			display: none;
		}

		.row-text {
			flex: 1;
			margin-bottom: 0;
			min-width: 0;
		}

		.row-icon-right {
			display: flex;
			margin: auto 0 auto auto;
		}
	}

	@include breakpoint(md) {
		align-items: center;
		flex-wrap: nowrap;
		min-height: 120px;
		padding: 1.875rem 2rem 1.875rem 0;
		position: relative;

		&.row-secondary-button {
			padding: 1.25rem 2.8125rem 1.25rem 1.25rem;
			width: 360px;
		}

		&.on-time {
			border-top: 0.25rem solid $color-green-t3;
			padding-top: 1.625rem;
		}

		&.delayed {
			border-top: 0.25rem solid $color-orange-delay;
			padding-top: 1.625rem;
		}

		.appointment-eta {
			margin: -4px 0 0 0;
			position: absolute;
			top: 0;
			width: auto;
			width: 95px;
		}

		.row-text {
			order: 2;
		}

		.row-icon {
			display: flex;
			flex: 0 0 113px;
			justify-content: center;
			max-width: 113px;
			order: 1;
		}

		.row-icon-right {
			margin: auto 0 auto auto;
			order: 3;
		}
	}
}
