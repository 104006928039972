.deductible-card {
	background-color: $color-gray-light;
	border-radius: 8px;
	margin-bottom: 1rem;
	padding: 1.25rem 2.5rem;

	&.loading {
		.loading-overlay-section {
			opacity: 1;
		}
	}

	&.hidden {
		opacity: 0;
	}

	.deductible-chart-details {
		&.hidden {
			opacity: 0;
		}
	}

	h2.text-center.heading-2 {
		&.hidden {
			opacity: 0;
		}
	}

	.loading-overlay-section {
		top: 3rem;
	}

	.deductible-card-title {
		@include text(iota);
		@include font-weight(semi-bold);
		color: $color-gray-dark;
		margin-bottom: 8px;
		text-align: center;
	}

	.rdeductible-list {
		min-height: 120px;
	}

	.deductible-data-list {
		padding: 10px;

		&.no-deductible-found {
			padding-top: 20px;
		}

		> div {
			display: flex;
		}

		dt,
		dd {
			@include text(lambda);
			margin-bottom: 0;
		}

		dt {
			margin-right: 0.5rem;
		}
	}

	.deductible-details-link {
		color: $color-black;

		&:hover {
			text-decoration: none;

			.deductible-status {
				text-decoration: underline;
			}
		}
	}

	.deductible-status {
		@include font-weight(semi-bold);
		color: $color-blue;
		position: relative;

		dt {
			display: none;
		}

		.status-icon-wrapper {
			left: -1.75rem;
			position: absolute;
			top: -0.125rem;

			.icon {
				height: 16px;
				margin-top: 2px;
				width: 16px;
			}
		}
	}

	.view-more-link {
		margin-top: 2rem;
		padding: 10px;
		text-align: center;
		width: 100%;

		a {
			@include text(lambda);
			@include font-weight(semi-bold);
		}
	}
}
