.provider-background {
	@include breakpoint(md) {
		.accordion-item-body {
			columns: 3;
			column-gap: 0;

			> div {
				break-inside: avoid-column;
			}
		}
	}
}
