.parking {
	@include make-container();
	margin-bottom: 4rem;
	.title {
		@include text(zeta, epsilon);
		margin-bottom: 10px;
	}
	img {
		display: none;
	}
	.parking-info {
		align-items: flex-start;
		display: flex;
	}
	@include breakpoint(md) {
		img {
			display: block;
		}
		.parking-info {
			.parking-description {
				padding-left: 20px;
			}
		}
	}
}
