.change-primary-care-provider {
	.ngde-form {
		flex-direction: column;
		margin: 0;

		.form-control {
			margin-bottom: 1.5rem;
		}

		input {
			margin-bottom: 0;
		}
	}

	.form-control {
		margin-bottom: 3rem;

		label {
			@include text(iota);
			display: block;
			margin-bottom: 0.75rem;
		}
	}

	.error-message,
	.success-message {
		display: none;

		&.active {
			display: block;
		}
	}

	.success-message {
		color: $color-green-at;
	}

	.error-message {
		color: $color-red;
	}

	.disclaimer {
		@include text(mu);
	}
}
