.pls-footer-container {
	@include make-container();
	background-color: $color-gray-dark;
	color: $color-off-white;
	padding-bottom: 52px;
	padding-top: 35px;
	position: relative;

    .link-list > li > a {
        color: $color-off-white;
    }
    
	a {
		color: $color-off-white;
	}

	.back-to-top-container {
		margin-bottom: 24px;
		display: flex;
		position: absolute;
		top: 35px;
		right: 30px;

		.mobile-nav & {
			display: none;
		}
	}

	.back-to-top-btn {
		align-items: center;
		display: flex;
		margin-left: auto;
		padding: 0;

		.icon-arrow-down {
			stroke: $color-off-white;
			stroke-width: 4px;
			transform: rotate(180deg);
		}
	}

	.copyright {
		text-align: left;
		margin-bottom: 32px;
		padding-right: 5rem;

		p {
			@include text(iota);
			margin-bottom: 0;
			color: inherit;
		}
	}

	.link-list {
		@include text(lambda);
		columns: 2;

		li {
			margin-bottom: 5px;
		}
	}

	.legal-description {
		@include text(mu);
		margin-bottom: 0;
		margin-top: 2rem;
	}

	@include breakpoint(md) {
		display: flex;

		div {
			flex: 1 1 auto;
		}

		.legal-content {
			flex: 0 1 40%;
		}

		.back-to-top-container {
			display: none;
		}

		.back-to-top-btn {
			display: none;
		}

		.copyright {
			display: flex;
			flex-direction: column;
			margin-bottom: 0;
			padding-right: 50px;

			.tech-support {
				margin-bottom: 0;
			}
		}

		.tech-support {
			margin-top: auto;

			span {
				display: block;
			}
		}

		p {
			margin-bottom: 0.2rem;
		}
	}

	@include breakpoint(lg) {
		.copyright {
			padding-right: 100px;

			p {
				@include text(iota);
			}
		}
	}
}
