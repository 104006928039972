.ngde-contact-information {
	.multiple-errors {
		display: none;
	}

	&.errors {
		.multiple-errors {
			@include text(iota);
			background-color: $color-red-light;
			display: flex;
			flex-wrap: wrap;
			margin: 1rem 0 2rem 0;
			padding: 1.25rem;
			width: 100%;

			.error {
				margin-right: 1.25rem;
			}
		}

		.email-error {
			display: none;
		}

		.mobile-error {
			display: none;
		}

		.other-error {
			display: none;
		}

		.alt-phone-type-error {
			display: none;
		}
	}

	&.first {
		.email {
			border-bottom: 2px solid $color-red;
		}

		.email-error {
			display: block;
		}
	}

	&.second {
		.mobile-number {
			border-bottom: 2px solid $color-red;
		}

		.mobile-error {
			display: block;
		}
	}

	&.third {
		.other-number {
			border-bottom: 2px solid $color-red;
		}

		.other-error {
			display: block;
		}
	}

	&.fourth {
		.type-select {
			border-bottom: 2px solid $color-red;
		}

		.alt-phone-type-error {
			display: block;
		}
	}

	.select-wrapper {
		position: relative;
		margin-bottom: 1rem;
		width: 100%;
		.type-select {
			background-color: $color-gray-light;
			display: flex;
			height: 60px;
		}

		.icon-arrow-down {
			position: absolute;
			right: 20px;
			top: 30%;
		}

		:focus {
			border: 2px solid black;
		}
	}

	@include breakpoint(lg) {
		.select-input-wrapper {
			display: flex;
			justify-content: space-between;
			width: 100%;

			.input-field {
				width: calc((100% - 15px) / 1.5 - 1px);
			}

			.select-wrapper {
				position: relative;
				width: calc((100% - 15px) / 3 - 1px);
			}
		}
	}

	.heading-1 {
		color: $color-black;
	}

	.verify-communication-type-form {
		.heading-1 {
			color: $color-black;
		}

		.consent-text {
			flex-direction: column;
		}
		/*&.send-code-form {
			.input-field {
				width: 105rem;
			}
		}

		&.submit-code-form {
			.input-field {
				width: 100%;
			}
		}*/

		.btn-with-instruction {
			margin-bottom: 1rem;
			.btn {
				width: 100%;
				margin-bottom: 0.3rem;
			}
		}

		.btn-instruction-text {
			color: $color-red;
		}

		.verify-communication-type-field {
			flex-direction: column;
			.input-field {
				width: 100%;
			}

			.btn-with-instruction {
				width: 100%;
				/*.btn {
					
				}*/
			}
		}

		@include breakpoint(lg) {
			.verify-communication-type-field {
				flex-direction: row;

				.input-field {
					width: 65%;
				}

				.btn-with-instruction {
					width: 35%;
					/*.btn {
						
					}*/
				}
			}
		}
	}

	.terms-instruction {
		color: $color-red;
	}
}
