.provider-comparison {
	margin-top: -4rem; // hug title bar up next to google translate dropdown
	max-width: 1440px;
	padding-left: 72px;
	padding-right: 72px;
	margin-left: auto;
	margin-right: auto;

	.provider-comparison-list {
		display: none;
	}

	.provider-result {
		&.removed {
			opacity: 0.3;
		}
	}

	&.comparison-list-view {
		&.show-additional-info {
			.additional-info {
				display: block;
			}

			.additional-info-toggle {
				svg {
					transform: rotate(-90deg);
				}
			}
		}

		.provider-list {
			display: none;
		}

		.provider-comparison-list {
			display: block;
		}

		.comparison-top {
			margin-bottom: 50px;
		}

		.provider-comparison-list {
			display: flex;
			overflow-x: auto;
			padding: 0 30px;
			width: 100%;
		}

		.provider-result {
			@include text(mu);
			background-color: $color-gray-providers;
			flex-basis: calc(50% - 3px);
			margin-right: 4px;
			min-width: calc(50% - 3px);
			padding: 0;

			&:last-of-type {
				margin-right: 0;
			}

			&:nth-of-type(even) {
				background-color: $color-white;

				.comparison-row {
					&:nth-child(n + 3):nth-child(odd) {
						background-color: $color-neutral-yellow-light;
					}
				}
			}
		}

		.comparison-row {
			border-bottom: 2px solid $color-white;
			border-top: 2px solid $color-white;
			margin: 0;
			padding: 0.75rem 1rem 0.75rem 0.625rem;

			&:last-child {
				background-color: transparent;
			}

			&:nth-child(n + 3):nth-child(odd) {
				background-color: $color-neutral-yellow;

				.star-fill {
					svg {
						fill: $color-yellow-alt;
					}
				}
			}

			&.view-all-expanded {
				.view-all-list {
					display: block;
				}
			}
		}

		.view-all-list {
			display: none;
		}

		.group-and-specialty {
			min-height: 86px;
		}

		.comparison-specialty,
		.comparison-title {
			display: block;
		}

		.comparison-text {
			display: block;

			&.accepting {
				color: $color-green-providers;
			}
		}

		.comparison-specialty {
			display: block;
		}

		.new-patient-text {
			display: none;
		}

		.comparison-title {
			@include font-weight(bold);
			color: $color-black;
			font-size: 13px;
		}

		.provider-name {
			@include text(kappa);
			padding: 0.5rem 1rem 0 0.625rem;
		}

		.group-and-specialty {
			padding: 0 1rem 0.75rem 0.625rem;

			.specialty {
				display: none;
			}
		}

		.medical-group {
			@include text(mu);
		}

		.provider-details {
			padding: 0;
		}

		.detail-top {
			.ratings {
				display: none;
			}
		}

		.ratings {
			margin: 0 0 0.25rem;

			.rating-stars {
				width: 80px;

				.star {
					height: 14px;
					width: 14px;

					svg {
						height: 14px;
						width: 14px;
					}

					.star-fill {
						height: 14px;
					}
				}
			}

			.average-rating {
				@include text(mu);
				margin-left: 0.5rem;
				margin-top: 0;
			}

			.rating-count {
				@include text(mu);
				margin-top: 0;
			}
		}

		.comparison-new-patient {
			border-top: 0;
		}

		.phone-number {
			display: none;
		}

		.location-address {
			display: none;
		}

		.detail-bottom {
			display: none;
		}

		.comparison-schedule-btn {
			display: block;
			padding: 0.75rem 1rem 0.75rem 0.625rem;

			.btn {
				min-width: 0;
				padding: 0.625rem 0.5rem 0.5rem;
				width: 100%;
			}

			&.desktop {
				display: none;
			}
		}

		ul {
			list-style: none;
			padding: 0;

			li {
				margin-bottom: 0.5rem;
			}
		}

		.view-all-btn {
			@include font-weight(medium);
			border: none;
			color: $color-blue-providers;
			margin: 0;
			padding: 0;
			text-decoration: underline;

			&:hover,
			&:focus {
				text-decoration: none;
			}
		}

		.section-link {
			@include text(mu);
			@include font-weight(medium);
			display: inline-block;
		}

		.additional-info-toggle {
			@include text(kappa);
			@include font-weight(medium);
			align-items: center;
			color: $color-gray-dark;
			display: flex;
			height: 50px;
			left: 30px;
			margin-top: -50px;
			position: absolute;
			width: calc(100% - 60px);

			svg {
				margin-bottom: 1px;
				margin-left: 0.5rem;
				stroke: $color-gray-dark;
				transform: rotate(90deg);
			}
		}

		.additional-info {
			display: none;
		}
	}

	.controls-container {
		@include text(mu);
		@include font-weight(medium);
		display: flex;
		justify-content: flex-end;
		margin-bottom: 2rem;
		padding-right: 1.875rem;
	}

	.view-controls {
		align-items: center;
		background-color: $color-gray-light;
		border-radius: 2px;
		display: flex;
		justify-content: space-between;
		padding: 0.5rem;
		width: 70px;

		.list-view-btn,
		.comparison-view-btn {
			align-items: center;
			display: flex;
			height: 1.125rem;
			justify-content: center;
			width: 1.125rem;

			&.selected {
				svg {
					fill: $color-gray-dark;
				}
			}

			svg {
				fill: $color-gray-neutral;
				pointer-events: none;
			}
		}

		.comparison-view-btn {
			svg {
				height: 18px;
				transform: rotate(90deg);
				width: 18px;
			}
		}
	}
	@include breakpoint(xss) {
		margin-top: 0;
		padding: 0;
		&.comparison-list-view {
			padding: 0;

			.provider-comparison-list {
				padding: 0 16px;
			}
		}
	}
	@include breakpoint(xs) {
		margin-top: 0;
		padding: 0;
		&.comparison-list-view {
			padding: 0;

			.provider-comparison-list {
				padding: 0 16px;
			}
		}
	}
	@include breakpoint(sm) {
		margin-top: 0;
		padding: 0;
		&.comparison-list-view {
			padding: 0;

			.provider-comparison-list {
				padding: 0 16px;
			}
		}
	}
	@include breakpoint(md) {
		margin-top: 0;

		&.comparison-list-view {
			padding: 0;

			.provider-result {
				@include text(lambda);
				display: block;
				flex-basis: calc(25% - 15px);
				margin-right: 20px;
				min-width: calc(25% - 15px);

				.profile-image-container {
					padding-bottom: 66.666%;
				}

				.profile-image-outer {
					padding: 0;
					width: 100%;
				}
			}

			.comparison-title {
				font-size: 15px;
				margin-bottom: 0.375rem;
			}

			.provider-name {
				@include text(theta);
				margin-bottom: 0;
				padding: 1.125rem 1.25rem 0 1.125rem;
			}

			.group-and-specialty {
				margin-bottom: 0;
				padding: 0 1.25rem 0.5rem 1.125rem;

				.specialty {
					display: block;
				}
			}

			.medical-group {
				@include text(lambda);
			}

			.provider-details {
				display: flex;
				flex-direction: column;
				width: 100%;
			}

			.detail-top {
				display: block;
			}

			.comparison-specialty,
			.provider-ratings,
			.provider-languages,
			.distance-and-location,
			.comparison-new-patient,
			.provider-insurance {
				padding: 1rem 1.25rem 1rem 1.125rem;
			}

			.comparison-schedule-btn {
				&.mobile {
					display: none;
				}

				&.desktop {
					display: block;
				}
			}

			.section-link {
				@include text(lambda);
				margin-top: 1rem;
				text-decoration: none;

				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}

			.additional-info-toggle {
				display: none;
			}

			.additional-info {
				display: block;
			}

			.action-column {
				.column-icons {
					right: 70px;
				}
			}
		}

		.comparison-container {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			padding-top: 110px;
		}
		.provider-list {
			order: 1;
		}

		.controls-container {
			order: 2;
			margin-bottom: 1rem;
			padding-right: 0;
		}

		.action-column {
			.column-icons {
				top: 0;
				right: 0;
			}
		}
	}

	@include breakpoint(lg) {
		&.comparison-list-view {
			.ratings {
				.rating-stars {
					width: 100px;

					.star {
						height: 18px;
						width: 18px;

						svg {
							height: 18px;
							width: 18px;
						}

						.star-fill {
							height: 18px;
						}
					}
				}

				.average-rating {
					@include text(lambda);
					margin-left: 0.625rem;
					margin-top: 2px;
				}

				.rating-count {
					@include text(lambda);
					margin-top: 2px;
				}
			}
		}

		.action-column {
			.column-icon {
				right: 0;
			}
		}
	}
}
