.primary-description {
	.lead {
		@include text(iota);
		@include font-weight(light);
		color: $color-gray-dark;

		a {
			color: $color-blue;
			text-decoration: none;

			&:hover,
			&.focus {
				text-decoration: underline;
			}
		}
	}

	@include breakpoint(lg) {
		.lead {
			@include text(zeta);
			margin-bottom: 2.375rem;
		}
	}
}
