.hv-related-content {
	background: $color-gray-light;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-bottom: 2rem;
	padding-bottom: 25px;
	padding-left: 20px;
	padding-top: 20px;
	width: 100%;

	&.one-block {
		justify-content: space-around;
	}

	.related-content-block {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding-bottom: 2rem;
		padding-right: 30px;

		&:last-child {
			padding-bottom: 0px;
		}
	}

	.related-content-top {
		display: flex;
		flex-direction: column;
		padding-bottom: 2rem;
	}

	.related-content-list {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	h4 {
		@include text(theta);
		@include font-weight(semi-bold);
	}

	li {
		padding-bottom: 0.25rem;
	}

	a {
		@include text(lambda, mu);
		@include font-weight(semi-bold);
		color: $color-blue;
		display: inline-block;
		text-decoration: none;

		&:focus,
		&:hover {
			text-decoration: underline;
		}
	}

	.related-content-cta {
		span {
			padding-right: 0.25rem;
		}

		&:focus,
		&:hover {
			text-decoration: none;

			span {
				text-decoration: underline;
			}
		}
	}

	.icon-external {
		height: 8px;
		stroke: $color-blue;
		width: 8px;
	}

	@include breakpoint(xs) {
		&.one-block {
			.related-content-list {
				column-gap: 1rem;
				columns: 2;
			}
		}
	}

	@include breakpoint(sm) {
		&.three-blocks {
			flex-direction: row;
			flex-wrap: wrap;
		}
	}

	@include breakpoint(md) {
		flex-direction: row;

		&.one-block {
			.related-content-list {
				columns: 2;
			}
		}

		.related-content-block {
			padding-bottom: 0px;
		}
	}

	@include breakpoint(lg) {
		&:not(.three-blocks) {
			.related-content-list {
				columns: 2;
			}
		}
	}
}
