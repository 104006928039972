.t3-footer {
	background-color: $color-gray-dark;
	padding-top: 56px;
	position: relative;

	&.fixed {
		.t3-footer-navigation {
			bottom: 0;
			position: fixed;
			top: auto;
			transition: margin-bottom 0.3s ease-in-out;
		}
	}

	.mobile-nav-open & {
		&.fixed {
			.t3-footer-navigation {
				margin-bottom: -100vh;
			}
		}
	}

	.t3-footer-navigation {
		@include font-weight(medium);
		background-color: $color-black;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1;

		.t3-footer-navigation-container {
			@include make-container();
			display: flex;
		}

		.btn {
			@include font-weight(medium);
			padding: 1rem 0;
			flex: 0 0 50%;
			max-width: 50%;
		}

		.feedback-btn {
			color: $color-gray-neutral;
			text-align: left;
		}

		.scroll-btn {
			color: $color-blue-bright;
			text-align: right;
		}
	}

	.t3-footer-cta {
		padding: 80px 0;

		.t3-footer-cta-container {
			@include make-container();
		}

		a {
			color: $color-off-white;
		}

		p {
			@include font-weight(light);
			color: $color-off-white;
			margin-bottom: 2rem;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.back-to-top-btn {
			display: block;
			margin: -50px auto 0;
			padding: 2rem;

			.icon-arrow-down {
				stroke: $color-off-white;
				stroke-width: 4px;
				transform: rotate(180deg);
				width: 1rem;
			}
		}

		.help-title {
			display: none;
		}
	}

	@include breakpoint(md) {
		min-height: 160px;
		padding: 35px 0 34px;

		.t3-footer-navigation {
			display: none;
		}

		.t3-footer-cta {
			padding: 0;

			.back-to-top-btn {
				display: none;
			}

			.help-title {
				color: $color-blue-bright;
				display: block;
			}

			p {
				display: block;
				margin-bottom: 0.2rem;
			}
		}
	}
}
