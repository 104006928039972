.schedule-info-container {
	padding: 1rem;
	position: relative;
	text-align: center;

	&.show-appointment-types,
	&.show-direct-scheduling,
	&.show-open-scheduling {
		.reset-btn {
			display: block;
		}
	}

	&.show-direct-scheduling,
	&.show-open-scheduling {
		.schedule-default {
			display: none;
		}
	}

	&.show-appointment-types {
		.appointment-types {
			display: block;
		}

		.schedule-header {
			.reset-btn {
				display: none;
			}
		}
	}

	&.show-direct-scheduling {
		.direct-scheduling {
			display: block;
		}
	}

	&.show-open-scheduling {
		.open-scheduling {
			display: block;
		}
	}

	&.urgent-care-scheduling {
		&.show-terms-of-use {
			.terms-of-use {
				display: block;
			}
		}

		.appointment-types {
			.reset-btn {
				display: none;
			}
		}
	}

	&.loading {
		.loader {
			display: block;
		}
	}

	.schedule-title {
		@include font-weight(bold);
		@include text(zeta);
		margin-bottom: 1rem;
	}

	.reset-btn {
		display: none;
		position: absolute;
		right: 1.25rem;
		top: 0;

		.icon {
			height: 1.5rem;
			width: 1.5rem;
		}
	}

	.appointment-types,
	.direct-scheduling,
	.open-scheduling {
		display: none;
	}

	.appointment-types {
		background-color: rgba($color-white, 0.95);
		height: 100%;
		padding-top: 4.5rem;
		position: absolute;
		top: 0;
		width: 100%;

		.schedule-btns {
			max-width: none;
		}
	}

	.appointment-type-title {
		@include font-weight(bold);
		@include text(iota);
		margin-bottom: 1rem;
	}

	.appointment-type {
		@include text(zeta);
		@include font-weight(bold);
		margin-bottom: 1.5rem;
	}

	.schedule-content {
		.description {
			@include text(lambda);
			margin-bottom: 0.25rem;

			&-primary {
				margin-bottom: 3rem;
			}
		}
	}

	.schedule-btns {
		margin-left: auto;
		margin-right: auto;
	}

	.appointment-type-description {
		@include text(lambda);
	}

	.terms-of-use {
		display: none;
		margin-left: auto;
		margin-right: auto;
		max-width: 547px;
		text-align: left;

		.reset-btn {
			display: block;
		}

		p {
			@include text(lambda);
		}

		.checkbox-wrapper {
			@include checkbox(
				$borderColor: $color-gray-medium-light,
				$borderColorHover: $color-gray-dark,
				$checkboxColor: $color-black
			);
		}
	}

	.loader {
		@include loader($color-gray-neutral, 25px, 2px);
		display: none;
		float: right;
	}

	@include breakpoint(md) {
		background-color: $color-white;
		flex: 0 1 calc(100% / 3 * 2);
		margin-bottom: 4rem;
		max-width: calc(100% / 3 * 2);
		overflow: hidden;
		padding: 0;

		.schedule-content {
			.description-primary {
				@include text(iota);
				margin-bottom: 3.4375rem;
			}

			.description-secondary {
				@include text(lambda);
				margin-bottom: 0;
			}
		}

		.schedule-title {
			@include text(zeta);
			color: $color-gray-dark;
			margin-bottom: 1.5rem;
		}

		.schedule-container {
			height: 90%;
		}

		.direct-scheduling,
		.open-scheduling {
			iframe {
				height: 375px;
				width: 698.666px;
			}
		}

		.appointment-types {
			.schedule-btns {
				max-width: 500px;
			}
		}
	}
}
