/*
 * font-weight($font-weight)
 * takes the weight value like light, medium bold and returns a font-family
 */
@mixin font-weight($font-weight) {
	@if map-has-key($font-weight-scale, $font-weight) {
		font-family: #{map-get($font-weight-scale, $font-weight)};
		font-weight: normal;
	} @else {
		@warn 'Unfortunately, no value could be retrieved from the font weight. ';
	}
}
