.selected-providers {
	background-color: $color-white;
	position: fixed;
	bottom: 0;
	padding: 0.75rem 30px 0 30px;
	width: 100%;
	z-index: 8;

	.selected-providers-list {
		display: flex;
		overflow: auto;
		width: calc(100% - 40px);
		padding-bottom: 0;
	}

	.selected-provider-item {
		display: block;
		width: 50px;
		margin-right: 1rem;
		flex-basis: 52px;
		min-width: 50px;
		padding: 1px;

		.compare-provider-checkbox {
			visibility: hidden;
		}

		&.list-link {
			@include font-weight(bold);
			align-items: center;
			background-color: $color-gray-light;
			display: flex;
			flex-direction: column;
			font-size: 10px;
			height: 50px;
			justify-content: center;
			text-decoration: none;
			margin-right: 0;
			position: absolute;
			right: 30px;

			&.disabled {
				cursor: default;

				color: $color-gray-neutral;

				.checkbox-wrapper {
					input[type='checkbox'] + label {
						&:after {
							border-color: $color-gray-neutral;
						}
					}
				}
			}
		}

		& > a {
			&:hover,
			&:focus {
				.profile-image-outer {
					outline: 1px solid $color-black;
				}
			}
		}
	}

	.remove-btn {
		color: $color-gray-medium-light;
		font-size: 11px;
		margin-top: 0.5rem;
		text-align: center;
		width: 100%;
		&:hover,
		&:focus {
			color: $color-black;
			text-decoration: underline;
		}
	}

	.desktop-remove-btn {
		display: none;
	}

	.profile-image-container {
		display: block;
		height: 0;
		overflow: hidden;
		padding-bottom: 100%;
		position: relative;
		width: 100%;
	}

	.profile-image {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		img {
			font-family: 'object-fit: cover; object-position: center 25%;';
			height: 100%;
			object-fit: cover;
			object-position: center 25%;
			width: 100%;
		}
	}

	.checkbox-wrapper {
		@include checkbox(transparent, black, $color-green-providers);
		margin-top: -8px;
		margin-left: 13px;
		margin-bottom: -8px;
		pointer-events: none;
	}

	ul {
		display: inherit;
		list-style: none;
		padding: inherit;

		li {
			display: inherit;
			padding: inherit;
		}
	}

	@include breakpoint(lg) {
		@include make-container();
		display: block;
		position: relative;
		background-color: transparent;
		padding: 0;

		.selected-providers-list {
			display: flex;
			flex-direction: column;
			padding-right: 1rem;
			position: absolute;
			left: 0;
			overflow: visible;
			transform: translateX(-100%);
			width: auto;
		}

		.selected-provider-item {
			margin-right: 0;
			margin-bottom: 10px;
			width: 50px;
			flex-basis: 50px;
			min-width: 50px;
			position: relative;

			&.list-link {
				@include font-weight(bold);
				align-items: center;
				background-color: $color-gray-light;
				display: flex;
				flex-direction: column;
				font-size: 10px;
				height: 50px;
				justify-content: center;
				text-decoration: none;
				position: relative;
				right: 0;
			}
		}

		.remove-btn {
			margin-top: 0;
			padding-top: 0.25rem;
		}

		ul {
			display: block;
			padding: 0;
		}
	}
}

.sp-outer {
	position: fixed;
	bottom: 0;
	z-index: 1000;
	background-color: $color-gray-light;
	width: 100%;
	left: 0;
	box-shadow: 0 6px 6px -3px #0003, 0 10px 14px 1px #00000024,
		0 4px 18px 3px #0000001f;

	.remove-btn {
		display: block;
	}

	.sp-header {
		padding-left: 5%;
		padding-bottom: 5px;
		padding-right: 5%;
		margin: 10px;

		display: flex;
		align-items: baseline;
		justify-content: space-between;

		.col2 {
			display: flex;
			align-items: baseline;
			a {
				padding-right: 10px;
				text-wrap: nowrap;
			}
		}

		.red-button {
			background-color: $color-red;
			color: white;
			border: none;
			padding: 10px 20px;
			text-align: center;
			text-decoration: none;
			display: inline-block;
			font-size: 16px;
			margin: 4px 2px;
			cursor: pointer;
			border-radius: 4px;
			transition: transform 0.2s; /* Animation */

			&:hover {
				transform: scale(1.1);
			}
		}
	}

	.sp-container {
		display: flex;
		flex-wrap: no-wrap;
		justify-content: space-between;
		padding-left: 5%;
		padding-bottom: 2%;
		padding-right: 5%;

		.sp-card {
			display: grid;
			align-items: flex-start;
			justify-content: space-between;
			width: 24%;
			background-color: white;
			box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
			border-radius: 8px;
			transition: transform 0.2s ease;
			height: 8rem;
			&:hover {
				transform: scale(1.05);
			}
			& > * {
				flex: 1 1 auto;
				min-width: 0; // Prevent overflow issues
			}
			.row1 {
				display: flex;
				justify-content: flex-end;
				text-align: right;
				margin-top: -10px;

				.close-icon {
					position: relative;
					width: 30px;
					height: 30px;
					background-color: #fff;
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
					transition: background-color 0.3s ease, transform 0.2s ease;
					&::before {
						content: '';
						position: absolute;
						width: 20px;
						height: 2px;
						background-color: #333;
						transition: transform 0.2s ease;
						transform: rotate(45deg);
					}
					&::after {
						content: '';
						position: absolute;
						width: 20px;
						height: 2px;
						background-color: #333;
						transition: transform 0.2s ease;
						transform: rotate(-45deg);
					}
					&:hover {
						background-color: $color-red;
						transform: scale(1.1);
						&::before {
							background-color: #fff;
						}
						&::after {
							background-color: #fff;
						}
					}
				}
			}
			.row2 {
				a {
					display: flex;
					text-decoration: none !important;
					align-items: flex-start;
				}
				img {
					width: 30%;
					height: auto;
					margin-right: 15px;
					object-fit: cover;
				}
				.sp-description {
					flex-grow: 1;
					width: 50%;

					h3 {
						font-size: 1rem;
						margin: 10px 0;
					}

					p {
						font-size: 0.8rem;
					}
				}
			}
		}
	}
}

@media (max-width: 600px) {
	.sp-outer {
		.sp-header {
			padding-left: 0;
			padding-right: 0;
			line-height: 10px;
			.col1 {
				h2 {
					font-size: 0.9rem;
				}
			}
			.col2 {
				font-size: 0.85rem;
				.red-button {
					font-size: 0.9rem;
				}
			}
		}

		.sp-container {
			.sp-card {
				min-width: 20%;
				flex-direction: column;
				max-height: 6rem;
				max-width: 18%;

				.row1 {
					margin-top: -5px !important;
					.close-icon {
						width: 20px !important;
						height: 20px !important;

						&::after,
						&::before {
							width: 14px;
							height: 2px;
						}
					}
				}

				.row2 {
					a {
						display: grid !important;

						img {
							width: 100%;
							min-width: 4rem;
							margin-right: 0;
						}
						.sp-description {
							width: 100%;
							p {
								display: none;
							}
							h3 {
								font-size: 0.6rem !important;
								text-align: center;
							}
						}
					}
				}
			}
		}
	}
}
