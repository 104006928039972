.content-slides {
	[data-slide] {
		display: none;

		&.active {
			display: block;

			&.image-summary {
				display: flex;
			}

			&.invisible {
				opacity: 0;
			}
		}

		&.animate {
			.content-block {
				.animate-in {
					animation: fade-in 0.4s;
					opacity: 1;
					animation-fill-mode: both;

					+ .animate-in {
						animation-delay: 0.15s;

						+ .animate-in {
							animation-delay: 0.3s;

							+ .animate-in {
								animation-delay: 0.45s;
							}
						}
					}

					@keyframes fade-in {
						from {
							opacity: 0;
							transform: translateX(40px);
						}
						to {
							opacity: 1;
							transform: translateX(0);
						}
					}
				}
			}
		}
	}

	.prev,
	.next {
		align-items: center;
		border: 2px solid $color-white;
		border-radius: 50%;
		display: flex;
		height: 30px;
		justify-content: center;
		padding-left: 2px;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translate(calc(100%), -50%);
		transition: border-color 0.15s ease-in-out;
		width: 30px;

		&:hover,
		&:focus {
			border-color: $color-blue-bright;
			cursor: pointer;
		}

		&.prev {
			left: 0;
			padding-left: 0;
			padding-right: 2px;
			right: auto;
			transform: translate(calc(-100%), -50%);

			svg {
				transform: rotate(180deg);
			}
		}
	}

	.slide-indicators {
		display: inline-flex;

		.indicator {
			background-color: transparent;
			display: flex;
			height: 2px;
			width: 12px;
			margin-right: 0.5rem;
			padding: 0.5rem 0;
			position: relative;

			&:after {
				content: ' ';
				display: block;
				background-color: $color-black;
				position: absolute;
				width: 100%;
				height: 2px;
				top: 50%;
				transform: translateY(-50%);
			}

			&.active {
				width: 30px;

				&:after {
					background-color: $color-blue-bright;
				}
			}

			&:last-of-type {
				margin-right: 0;
			}

			&:focus {
				outline: $color-blue-bright solid 2px;
				.using-mouse & {
					outline: none;
				}
			}

			&:hover,
			&:focus {
				cursor: pointer;
			}
		}
	}
}
