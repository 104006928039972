.ngde-header {
	z-index: 10;

	&.no-plan-switch {
		.plan-switch {
			display: none;
		}
	}

	.skip-link {
		position: absolute;
		top: -134px;
		background: $color-white;
		padding: 5px;
		&:focus {
			top: 0;
			z-index: 10;
		}
	}

	.select-wrapper-members {
		display: none;
	}

	.collapsed {
		height: 0px !important;
	}

	.fix {
		position: fixed;
		right: 0;
		width: 100%;
	}

	.hide {
		display: none;
	}

	.collapse {
		overflow: hidden;
		flex: 0;
	}

	.ngde-portal-header {
		z-index: 2;
	}

	.ngde-select-column {
		display: none;
	}

	.links-container,
	.user-span,
	.utility-separator {
		display: none;
	}

	.logo-container {
		.icon-phs-logo {
			fill: $color-white;
			height: 16px;
			width: 141px;
		}
	}

	.ngde-utility-header {
		background-color: $color-gray-dark;
		display: flex;
		height: 65px;
		overflow: hidden;
		align-items: center;
		display: flex;
		justify-content: space-between;

		.utility-navigation {
			@include make-container();
			display: flex;
		}

		.utility-right {
			margin-left: auto;
		}

		.logo-container {
			align-items: center;
			display: flex;
			padding-right: 2rem;
		}

		.language {
			display: flex;
			align-items: center;
			/* padding-right: 1rem; */
			select {
				background-color: $color-gray-dark;
				color: $color-white;
				width: 200px;
				/* padding: 0.4em 1em; */
			}

			.arrow-icon-wrapper {
				.arrow-down {
					stroke: $color-white;
				}
			}
		}
		.language-span {
			color: $color-white;
			display: inline-block;
			padding: 1rem 0rem 1rem 0;
			text-decoration: none;
		}

		.globe-icon-wrapper {
			display: flex;
			align-items: center;
			padding: 0;
			text-decoration: none;
			.icon-geolocate {
				stroke: $color-white;
			}
		}

		.select-wrapper-language {
			@include text(lambda);
			color: $color-gray-dark;
			position: relative;
			.arrow-icon-wrapper {
				pointer-events: none;
				position: absolute;
				right: 1.25rem;
				top: 30%;
				.arrow-down {
					stroke: $color-white;
				}
			}

			&:focus-within {
				.arrow-down {
					transform: rotate(180deg);
					transition: 0.2s ease;
				}
			}

			.select-title {
				/* @include text(mu); */
				font-size: 1rem;
				background-color: $color-gray-dark;
				color: $color-white;
			}

			select {
				cursor: pointer;
				padding-left: 5px;
				padding-right: 0px;

				&:active {
					outline: none;
				}
			}
			option:hover {
				background-color: $color-white;
				color: $color-black;
			}
		}
	}

	.user-profile-link-wrapper {
		display: flex;
		align-items: center;
		.user-icon-notification {
			stroke: none;
		}
		.arrow-icon-wrapper {
			margin-left: 0.5em;
			.arrow-down {
				stroke: white;
			}
		}
	}

	.profile-menu-button {
		display: block;
		.icon {
			stroke-width: 0px;
		}
	}

	.top-mobile-menu-banner {
		position: fixed;
		width: 100%;
		background: $color-gray-dark;
		padding: 1rem 0;
		text-align: center;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-left: 30px;
		padding-right: 30px;
		z-index: 2;

		.menu-title {
			color: white;
		}

		.icon {
			stroke: white;
		}
	}

	.top-mobile-menu-select-container {
		padding: 1.5rem 30px;
		background: $color-gray-light;
		margin-top: 60px;
		select {
			margin: 0.5rem 0;
		}
		.select-wrapper-plan {
			.arrow-icon-wrapper {
				top: 38%;
			}
		}
	}

	.main-header-outer {
		.title-container {
			z-index: 1;
			a {
				@include font-weight(medium);
				@include text(delta);
				color: $color-white;
			}

			.nav-link {
				padding-left: 0;

				&:hover,
				&:active,
				&:focus,
				&.active {
					&::after {
						opacity: 0;
						left: 18px;
					}
				}
			}

			.icon.icon-mypres-member-logo {
				fill: white;
				width: 186px;
				top: -15px;
			}
		}
	}

	.main-header-mobile {
		display: flex;

		.icon.icon-menu-open {
			fill: white;
		}
		@include breakpoint(lg) {
			display: none;
		}
	}

	.main-header-desktop {
		display: none;

		@include breakpoint(lg) {
			@include make-container();
			display: flex;
		}
	}

	.mobile-profile-menu {
		.mobile-nav-container {
			padding-top: 50px;
		}
	}

	.mobile-nav-link-divider {
		width: 100%;
		height: 1px;
		background: $color-gray-neutral;
	}

	.mobile-nav .nav-links li {
		padding: 1rem 0;
	}

	.mobile-nav-bottom-links {
		margin: 2rem 0;
		a {
			text-decoration-color: $color-blue;
		}

		.mobile-nav-bottom-links-list-item {
			padding: 0.75rem 0;
			color: $color-blue;
		}
	}

	.ngde-personalization-background {
		height: 45px;
		overflow: hidden;
		position: relative;
		transition: height 0.3s;

		.ngde-personalization-bar {
			@include make-container();
			height: 100%;
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;

			.row {
				height: 100%;
			}

			.plan-switch {
				select {
					padding: 0.6125rem 1rem;
				}
			}
		}
	}

	.ngde-portal-header {
		background-color: rgba(0, 0, 0, 0.4);
		display: block;
		.welcome-text-column {
			display: flex;
			align-items: center;

			.welcome-message-text {
				@include font-weight(light);
				@include text(theta);
				color: $color-white;
				margin-bottom: 0;
			}
		}
	}

	.user-profile-navigation-links {
		background: $color-gray-dark;
		position: absolute;
		margin-left: -50px;
		z-index: 3;
		border-radius: 3px;
		list-style: none;

		li {
			button {
				color: white;
			}
			margin: 1em;
		}
	}

	.messages-list-item {
		display: flex;
	}

	.notification-number {
		background: $color-red;
		border-radius: 90px;
		height: 15px;
		width: 15px;
		padding: 0.6rem;
		margin-left: 0.5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 12px;
	}

	@media (min-width: $md-lg-breakpoint) {
		.profile-menu-button,
		.nav-open {
			z-index: 2;
		}
	}

	@include breakpoint(lg) {
		.profile-menu-button,
		.nav-open {
			display: none;
		}

		.ngde-portal-header {
			display: block;

			.welcome-text-column {
				.welcome-message-text {
					@include text(zeta);
				}
			}
		}
		.ngde-select-column {
			display: block;
		}
		.col-2 {
			&.ngde-select-column {
				.select-wrapper {
					display: none;
				}
			}
		}
		.ngde-utility-header {
			transition: height 0.3s;

			.utility-navigation {
				@include make-container();
				align-items: center;
				display: flex;
			}

			.utility-right {
				display: flex;
				justify-content: flex-end;
				width: 40%;
			}

			.links-container {
				display: flex;
				width: 100%;
			}

			.user-span {
				color: $color-white;
				display: inline-block;
				padding: 1rem 1.2rem 1rem 0;
				text-decoration: none;

				&:hover,
				&:focus {
					color: $color-gray-light;
				}

				a {
					color: white;
					text-decoration: none;
					cursor: pointer;
				}
			}

			.utility-separator {
				color: $color-white;
				display: flex;
				padding: 0rem 1rem 0rem 1rem;
				text-decoration: none;
				align-items: center;
			}

			a {
				color: white;
			}
		}

		.ngde-select-column {
			display: block;
		}

		.ngde-personalization-background {
			height: 70px;

			.ngde-personalization-bar {
				padding-top: 0.9rem;
				padding-bottom: 0.9rem;
			}
		}

		.main-header-outer {
			.title-container {
				z-index: 1;

				a {
					@include font-weight(medium);
					@include text(delta);
					color: $color-white;
				}

				.nav-link {
					padding-left: 0;

					&::after {
						background-color: #fff0;
					}
				}

				.icon.icon-mypres-member-logo {
					fill: white;
					width: 260px;
					top: -25px;
				}
			}
		}
	}
}
