.image-summary {
	.at & {
		align-items: flex-start;
		justify-content: flex-start;

		h1 {
			@include text(theta);
			@include font-weight(bold);
			margin-bottom: 20px;
		}

		p {
			@include text(kappa);
		}

		.image-block {
			margin: 0 auto;
			padding: 0 2.5rem 1rem;

			img {
				max-height: 55vh;
			}
		}

		.slide-indicators {
			display: none;
		}

		@include breakpoint(lg) {
			flex-direction: row-reverse;

			h1 {
				@include text(zeta);
				@include font-weight(bold);
				margin-bottom: 20px;
			}

			p {
				@include text(iota);
			}

			.image-block {
				max-width: 700px;
				padding: 0 2rem;
			}
		}
	}
}
