.navigation-col {
	display: none;
	padding-bottom: 2rem;

	.text-content {
		li {
			margin-bottom: 1rem;
		}
	}

	.rail-title {
		.nav-link-title {
			margin-bottom: 1rem;
			padding: 0 2rem;
		}
	}

	.nav-btn {
		@include text(lambda);
	}

	hr {
		border-style: none;
		background-color: $color-gray-neutral;
		height: 1px;
		margin: 0 auto;
		width: 85%;
	}

	.selected-with-links,
	.selected-no-links {
		&.loading {
			.loading-overlay-section {
				opacity: 1;
			}
		}

		.nav-link-title {
			@include font-weight(bold);
		}
	}

	.selected-with-links {
		background-color: $color-gray-light;

		.link-list-header {
			margin-left: 2.5rem;
			margin-top: 1.5rem;
			margin-bottom: 1rem;
		}

		.second-section-header {
			margin-top: 0rem;
		}

		.link-list {
			padding-left: 2.5rem;
			padding-bottom: 1.5rem;
		}
	}

	.selected-no-links {
		display: block;
	}

	.nav-link-title {
		@include text(lambda);
		@include font-weight(semi-bold);
		color: $color-black;
		display: block;
		margin-bottom: 0;
		padding: 1.5625rem 2rem;
	}

	.link-list-header {
		@include text(lambda);
		@include font-weight(medium);
		color: $color-gray-dark;
		margin-bottom: 0.5rem;
		margin-left: 2rem;
		padding-bottom: 0;
		padding-left: 0;

		.icon-arrow-up {
			height: 7.42px;
			margin-left: 0.75rem;
			width: 7.5px;
		}

		.icon-download-sm {
			height: 11.89px;
			margin-left: 0.75rem;
			width: 10px;
		}
	}

	.link-list {
		color: $color-gray-dark;
		padding: 0 2rem;

		ul {
			padding-top: 1rem;
		}

		.link-list {
			color: $color-gray-dark;
			padding: 0 0 1rem 2.5rem;
		}

		.link-list-item {
			@include text(lambda);
			padding-bottom: 0.5rem;
		}

		.btn-link {
			margin-bottom: 0;
			text-align: left;
		}
	}

	.has-sub-nav {
		&.expanded {
			.nav-link-title {
				padding-bottom: 0.5rem;
			}
		}
	}

	.sub-nav-list {
		padding: 0 0 0.5rem 2.5rem;

		.sub-nav-item {
			@include text(lambda);
			padding: 0.5rem 0;

			&.selected {
				@include font-weight(bold);
				color: $color-black;

				a {
					color: $color-black;
				}
			}
		}
	}

	@include breakpoint(lg) {
		display: block;
	}
}
