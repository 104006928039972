.footer {
	.link-list {
		list-style: none;
		margin: 0;
		padding-bottom: 2rem;

		li {
			line-height: 0;
			margin: 0;
			padding: 0 0 0.5rem;

			&:before {
				display: none;
			}
		}

		a {
			@include text(kappa);
			color: $color-orange;
			display: inline-block;
			padding: 0.125rem 0;
			text-decoration: none;

			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
	}

	.footer-cta {
		background-color: $color-gray-dark;
		padding-top: 30px;

		.footer-cta-container {
			@include make-container();
		}

		.footer-cta-heading {
			@include text(kappa);
			color: $color-white;
			margin-bottom: 0.25rem;
		}

		.footer-cta-description {
			@include text(kappa);
			color: $color-gray-light;
			margin-bottom: 3rem;
		}
	}

	.footer-navigation {
		background-color: $color-black;
		color: $color-white;
		overflow: hidden;

		.footer-navigation-container {
			@include make-container();
			display: flex;
			padding-bottom: 32px;
			padding-top: 41px;
			position: relative;
		}

		.back-to-top-btn {
			bottom: 31px;
			left: 31px;
			position: absolute;

			.icon-arrow-down {
				height: 9px;
				stroke: $color-white;
				transform: rotate(180deg);
				width: 19px;
			}
		}

		.links-feedback,
		.links-legal {
			flex-basis: 50%;
		}

		a {
			@include text(lambda);
			color: $color-gray-neutral;
		}

		.copyright {
			bottom: 19px;
			color: $color-gray-neutral;
			font-size: 9px;
			left: calc(100% - 19px);
			position: absolute;
			transform: rotate(-90deg);
			transform-origin: top left 0;
			width: 100%;
		}
	}

	@include breakpoint(lg) {
		.footer-cta {
			.grid-row {
				@include make-row();
			}

			.grid-col {
				@include make-col-ready();

				&:nth-child(1) {
					@include make-col(3);
				}
				&:nth-child(2) {
					@include make-col(6);
				}
				&:nth-child(3) {
					@include make-col(3);
				}
			}

			.footer-cta-heading {
				@include text(iota);
			}

			.footer-cta-description {
				margin-bottom: 3rem;
				padding-right: 2rem;
			}

			.link-list {
				li {
					padding: 0 0 0.25rem;
				}

				a {
					padding: 0;
				}
			}
		}

		.footer-navigation {
			padding-bottom: 0;
			padding-top: 0;

			.footer-navigation-container {
				align-items: center;
				flex-direction: row;
				height: 60px;

				.back-to-top-btn {
					bottom: auto;
					left: auto;
					padding-left: 2rem;
					position: relative;

					.icon-arrow-down {
						height: 17px;
						stroke-width: 1px;
						width: 34px;
					}
				}

				.link-list {
					padding-bottom: 0;

					li {
						display: inline-block;
						padding: 0;
					}
				}

				a {
					padding: 0.5rem 1rem;
				}

				.copyright {
					bottom: auto;
					left: auto;
					margin-left: auto;
					position: relative;
					transform: none;
					width: auto;
				}

				.links-feedback,
				.links-legal {
					flex-basis: unset;
				}

				.links-feedback {
					padding-right: 1rem;
					position: relative;

					&:after {
						border-right: 1px solid $color-gray-neutral;
						content: '';
						display: block;
						height: 20px;
						position: absolute;
						right: 0;
						top: 7px;
						width: 1px;
					}
				}

				.links-legal {
					padding-left: 1rem;
				}
			}
		}
	}
}
