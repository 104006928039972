.hv-hero {
	margin-bottom: 1rem;

	.hero-img-container {
		height: 0;
		padding-bottom: 41.666%;
		position: relative;
	}

	.hero-img {
		display: block;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		img,
		source {
			height: 100%;
			object-fit: cover;
			object-position: center;
			width: 100%;
		}
	}

	.hero-content {
		@include make-container();
		background-color: $color-off-white;
		padding-top: 1rem;
		padding-bottom: 2rem;
	}

	.hero-heading {
		@include text(theta, 28);
		@include font-weight(semi-bold);
		margin-bottom: 0.5rem;
	}

	.hero-text-and-read-more {
		margin-bottom: 1.5rem;
	}

	.hero-text {
		@include text(lambda, kappa);
		@include font-weight(semi-bold);
		display: inline;
		color: $color-gray-dark;
	}

	.cta {
		margin-bottom: 0;
		width: 100%;
		text-decoration: none;

		&:hover,
		&:focus {
			background-color: $color-gray-dark;
			border-color: $color-gray-dark;
			color: $color-white;
			text-decoration: none;
		}
	}

	.read-more-btn {
		@include text(lambda, kappa);
		@include font-weight(semi-bold);
		color: $color-blue;
	}

	@include breakpoint(md) {
		position: relative;
		margin-bottom: 3rem;

		.hero-img-container {
			height: 350px;
			padding-bottom: 0;
		}

		.hero-content {
			background-color: rgba($color-white, 0.85);
			position: absolute;
			top: 0;
			right: 30px;
			width: 40%;
		}
	}

	@media (min-width: $md-lg-breakpoint) {
		.hero-img-container {
			height: 500px;
			padding-bottom: 0;
		}

		&:first-child {
			margin-top: -100px;
		}

		.hero-content {
			background-color: transparent;
			left: 50%;
			right: auto;
			transform: translateX(-50%);
			padding-top: 0;
			padding-bottom: 0;
			width: 100%;
		}

		.hero-content-inner {
			background-color: rgba($color-white, 0.85);
			padding: 130px 30px 2rem;
			width: 345px;
			position: absolute;
			right: 130px;
		}
	}
}
