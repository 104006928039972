.hv-breadcrumb {
	display: none;

	@include breakpoint(md) {
		@include make-container();
		display: block;
		margin-bottom: 2rem;
		margin-top: 1rem;

		.breadcrumb-list {
			display: flex;
			list-style-type: none;
		}

		.breadcrumb-item {
			color: $color-gray-medium;
			display: inline-block;
			margin-left: 1.5rem;
			position: relative;

			&:first-child {
				margin-left: 0;

				&:before {
					display: none;
				}
			}

			&:before {
				content: '/';
				left: -1rem;
				position: absolute;
				top: 1px;
			}
		}

		.breadcrumb-link {
			@include text(lambda);
			@include font-weight(semi-bold);
			align-items: center;
			color: $color-gray-medium;
			display: inline-flex;
			height: 20px;
			text-decoration: none;

			&:hover,
			&:focus {
				text-decoration: underline;
			}

			.icon {
				height: 0.5rem;
				width: 0.8125rem;
			}
		}
	}
}
