.language-dropdown {
	min-height: 66px;
	padding: 0 0 0.75rem;

	.language-list {
		img {
			display: inline;
		}
	}

	@include breakpoint(md) {
		min-height: 42px;
		padding: 0;
		text-align: right;
	}
}

.portal {
	.language-dropdown {
		padding-top: 10px;
	}

	.goog-te-gadget {
		.goog-te-combo {
			margin: 2px 0;
		}
	}

	.goog-logo-link,
	.goog-te-gadget {
		color: $color-white;

		a,
		a:hover {
			@include text(mu);
			@include font-weight(light);
			color: $color-white;
			text-decoration: none;
		}
	}

	@include breakpoint(md) {
		.language-dropdown {
			padding-top: 0px;
		}
	}
}
