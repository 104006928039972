.breadcrumb {
	padding: 0.75rem 0;
	margin-bottom: 0.5rem;

	.breadcrumb-link {
		@include text(mu);
		@include font-weight(medium);
		align-items: center;
		color: $color-black;
		display: inline-flex;
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: underline;
		}

		.icon-arrow-right {
			height: 0.625rem;
			margin-right: 0.25rem;
			transform: rotate(180deg) translateY(1px);
			width: 0.625rem;
		}
	}

	@include breakpoint(md) {
		align-self: flex-end;
		padding: 0;

		.breadcrumb-link {
			@include text(lambda);
			@include font-weight(semi-bold);
		}
	}
}
