.at-footer {
	background-color: $color-gray-dark;
	padding-top: 56px;
	position: relative;

	&.fixed {
		.at-footer-navigation {
			bottom: 0;
			position: fixed;
			top: auto;
			margin-bottom: 0;
			transition: margin-bottom 0.3s ease-in-out;
		}
	}

	.mobile-nav-open & {
		&.fixed {
			.at-footer-navigation {
				margin-bottom: -100vh;
			}
		}
	}

	.at-footer-navigation {
		@include font-weight(medium);
		background-color: $color-black;
		display: flex;
		position: absolute;
		top: 0;
		width: 100%;

		.btn {
			@include font-weight(medium);
			padding: 1rem 0;

			span {
				@include make-container();
				display: block;
				max-width: calc(#{$max-container-width} / 2);
			}
		}

		.feedback-btn {
			color: $color-gray-neutral;
			text-align: left;
			flex: 0 0 50%;
			max-width: 50%;

			span {
				margin-right: 0;
			}

			&:hover,
			&:focus {
				background-color: $color-white;
				color: $color-black;
			}
		}

		.scroll-btn {
			color: $color-blue-bright;
			text-align: right;
			flex: 0 0 50%;
			max-width: 50%;

			span {
				margin-left: 0;
			}

			&:hover,
			&:focus {
				background-color: $color-blue-bright;
				color: $color-white;
			}
		}
	}

	@include breakpoint(md) {
		min-height: 160px;
	}
}
