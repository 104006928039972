.search-landing {
	display: flex;
	flex-direction: column;
	min-height: 300px;

	.search-landing-header {
		.heading-1 {
			margin: 0;
			padding: 0.75rem 0 0.75rem;
		}
	}

	.search-landing-title {
		@include text(iota);
		@include font-weight(bold);
		color: $color-gray-dark;
	}

	.search-landing-body {
		background-color: $color-gray-light;
		flex: 1;
	}

	.search-landing-content {
		padding: 1rem 30px;

		.contracted-link-container {
			align-items: center;
			display: flex;
			justify-content: center;

			svg {
				fill: $color-gray-medium-light;
				height: 1.25rem;
				margin-right: 0.5rem;
				min-width: 1.25rem;
				transform: translateY(-2px);
				width: 1.25rem;
			}
		}

		.contracted-link {
			display: block;
		}
	}

	.select-wrapper {
		margin-bottom: 50px;
		margin-left: -1.25rem;
		position: relative;
		width: calc(100% + 2.5rem);

		select {
			@include text(lambda);
			@include font-weight(normal);

			/* for Firefox */
			-moz-appearance: none;
			/* for Chrome */
			-webkit-appearance: none;
			background-color: $color-white;
			border: 0;
			color: $color-black;
			height: 45px;
			padding: 0 3rem 0 1.25rem;
			width: 100%;

			/* For IE10 */
			&::-ms-expand {
				display: none;
			}

			&:hover {
				cursor: pointer;
			}
		}

		svg {
			pointer-events: none;
			position: absolute;
			right: 1.25rem;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.input-icon-container {
		margin-bottom: 1.75rem;
		margin-left: -1.25rem;
		position: relative;
		width: calc(100% + 2.5rem);

		input {
			@include text(kappa);
			background-color: $color-white;
			height: 60px;
			margin-bottom: 0;
			padding: 1.125rem 2.75rem 1.125rem 1.25rem;
			text-align: left;
			width: 100%;

			&::placeholder {
				color: #999;
				text-align: left;
			}
		}

		button,
		> .icon {
			position: absolute;
			right: 1.25rem;
			top: 50%;
			transform: translateY(-50%);
		}

		> .icon {
			pointer-events: none;
		}
	}

	.react-autosuggest__container {
		position: relative;

		&--open {
			.react-autosuggest__suggestions-container {
				border-bottom: 1px solid $color-gray-neutral;
			}
		}
	}

	.react-autosuggest__suggestions-container {
		background-color: $color-white;
		position: absolute;
		top: 100%;
		width: 100%;
		z-index: 2;
	}

	.react-autosuggest__suggestion--highlighted {
		background-color: $color-gray-providers;
	}

	.checkbox-wrapper {
		margin-bottom: 1.5rem;
	}

	.search-btn {
		@include text(kappa);
		@include font-weight(medium);
		background-color: $color-gray-dark;
		border-color: $color-gray-dark;
		border-radius: 2px;
		color: $color-white;
		max-width: 100%;
		padding: 0.625rem 1.5rem;
		white-space: pre-wrap;
		width: 100%;

		&[disabled] {
			background-color: $color-gray-neutral;
			border-color: $color-gray-neutral;
			cursor: default;
			transition: none;
		}

		&.geolocating {
			text-decoration: none;

			&:after {
				-webkit-animation: ellipsis steps(4, end) 900ms infinite;
				animation: ellipsis steps(4, end) 900ms infinite;
				content: '\2026'; /* ascii code for the ellipsis character */
				display: inline-block;
				overflow: hidden;
				vertical-align: bottom;
				width: 0px;
			}

			@keyframes ellipsis {
				to {
					width: 20px;
				}
			}

			@-webkit-keyframes ellipsis {
				to {
					width: 20px;
				}
			}
		}
	}

	.btn-container {
		text-align: center;
	}

	.btn-link {
		@include text(lambda);
		@include font-weight(normal);
		margin-bottom: 0.625rem;
		padding: 0;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	@include breakpoint(md) {
		min-height: calc(100vh - 250px);

		.search-landing-content {
			padding-top: 2rem;
		}

		.search-landing-title-container {
			align-items: center;
			display: flex;
			justify-content: space-between;
			margin-bottom: 0.625rem;

			.btn-link {
				margin-bottom: 0;
			}
		}

		.search-landing-title {
			@include text(iota);
			@include font-weight(medium);
			margin-bottom: 0;
		}

		.input-icon-container {
			margin-bottom: 1.5rem;
			margin-left: 0;
			width: 100%;

			input {
				@include text(lambda);
				padding: 1.25rem 2.75rem 1.25rem 1.25rem;
			}
		}

		.checkbox-select-row {
			align-items: center;
			display: flex;
			margin-bottom: 3rem;

			.checkbox-outer-container {
				align-items: center;
				display: flex;
				flex: 0 1 50%;
				justify-content: center;
				margin-bottom: 0;
				max-width: 50%;

				.checkbox-wrapper {
					display: inline-flex;
					margin-bottom: 0;
				}
			}

			.select-wrapper {
				flex: 0 1 50%;
				margin-bottom: 0;
				margin-left: 0;
				max-width: 50%;
			}
		}

		.search-btn {
			min-width: 300px;
			width: auto;
		}
	}

	@include breakpoint(lg) {
		margin-top: 2.5rem;
		min-height: 0;

		.search-landing-header {
			.heading-1 {
				margin-bottom: 0.5rem;
				padding: 0;
			}
		}

		.search-landing-body {
			@include make-container();
			background-color: transparent;
		}

		.search-landing-content {
			background-color: $color-gray-light;
			padding: 2.75rem 4rem;
		}
	}
}
