.logout-modal {
	text-align: center;

	.modal-header,
	.modal-close-btn {
		display: none;
	}

	.modal-container {
		justify-content: center;
	}

	.logout-title {
		@include text(delta, 36);
		color: $color-red;
		margin-bottom: 1rem;

		.time-remaining {
			@include font-weight(bold);
			display: block;
			margin-top: 1rem;
		}
	}

	p {
		@include text(kappa);
	}

	.btn-remain {
		background-color: $color-gray-dark;
		border-color: $color-gray-dark;
		color: $color-white;
		width: 100%;

		&:hover,
		&:focus {
			background-color: $color-black;
			border-color: $color-black;
		}
	}

	@include breakpoint(md) {
		.logout-title {
			@include text(gamma, 48);
		}

		.btn-remain {
			min-width: 240px;
			width: auto;
		}
	}

	@include breakpoint(lg) {
		.modal-header {
			display: block;
		}
	}
}
