.ngde-bills-and-claims {
	margin-left: auto;
	margin-right: auto;
	max-width: $lg-xl-breakpoint;
	padding-left: 30px;
	padding-right: 30px;
	padding-top: 168px;
	width: 100%;
	@include make-container();

	&.no-padding {
		padding-top: 0 !important;
	}

	.back-link {
		@include text(lambda);
		color: $color-black;
		display: inline-block;
		margin-bottom: 1rem;

		.icon-arrow-right {
			height: 8.9px;
			margin-right: 0.5rem;
			transform: rotate(180deg);
			width: 4.45px;
		}
	}

	.collapsed {
		display: none !important;
	}

	.plan-inactive {
		display: none;
	}

	&.set-active {
		.plan-inactive {
			display: none;
		}

		.plan-active,
		.plan-list-active {
			display: block;
		}
	}

	&.set-inactive {
		.plan-inactive {
			display: block;
		}

		.plan-active,
		.plan-list-active {
			display: none;
		}
	}

	a {
		color: $color-blue;
		text-decoration: none;
		li {
			margin-bottom: 0.5rem;
		}
	}

	.grid-row {
		@include make-row();
		section {
			overflow: hidden;
			width: 100%;
		}
	}

	.claims-wrap {
		margin-bottom: 4rem;
		position: relative;

		&.loading {
			min-height: 400px;
			pointer-events: none;

			.hide-while-loading,
			.total-and-summary-container {
				display: none;
			}

			.loading-overlay {
				opacity: 1;
			}
		}

		.loading-overlay {
			background-color: transparent;
			padding-top: 150px;
		}
	}

	.mobile-card-list {
		.mobile-card-list-item {
			padding-bottom: 1.25rem;
		}
	}

	.portal-table {
		display: none;
	}

	.in-network-icon {
		width: 19px;
		height: 18px;
		margin-right: 0.5rem;
	}

	.mobile-claim-card {
		border: solid 1px $color-gray-table-border;
		padding: 1.625rem 1.375rem;

		> div {
			display: flex;
			flex-flow: row;
			margin-bottom: 0.3125rem;
		}

		a,
		dt,
		dd {
			@include text(lambda);
		}

		dt {
			margin-right: 0.25rem;
		}

		dd {
			overflow: hidden;
			text-overflow: ellipsis;
		}

		a {
			text-decoration: underline;
		}

		.view-claim-detail {
			border-bottom: 1px solid $color-gray-table-border;
			display: block;
			margin-bottom: 1rem;
			padding-bottom: 1rem;
		}
	}

	.claims-mobile-container {
		display: flex;
		justify-content: space-between;
	}

	.claims-mobile-filters {
		.mobile-filter-btn {
			margin-left: 0.75rem;

			.icon-download-sm {
				height: 15.89px;
				width: 14px;
			}
		}
	}

	.claim-summary-title {
		@include text(theta);
		margin-bottom: 1rem;
	}

	.claim-details-title {
		@include text(iota);
	}

	.claim-details-heading {
		align-items: center;
		display: flex;
		justify-content: space-between;
	}

	.claim-details {
		padding: 0 10px;
		width: 100%;
	}

	.claims-data {
		.data-summary {
			margin-left: -20px;

			.data-section {
				padding-bottom: 0;
			}
		}
	}

	.downloads-col {
		background: white;
	}

	.download-tab {
		background: $color-white;
		border: 1px solid $color-gray-table-border;
		border-radius: 4px;
		box-shadow: 0px 3px 6px rgba($color-black, 0.29);
		margin: 1.25rem -2rem 0 0;
		padding: 1rem 40px;
		position: absolute;
		right: 0;
		text-align: center;
		top: 100%;
		width: 275px;
		z-index: 9;

		&:before {
			border-bottom: 18px solid $color-gray-table-border;
			border-left: 18px solid transparent;
			border-right: 18px solid transparent;
			content: ' ';
			display: block;
			height: 0;
			margin-right: 10px;
			position: absolute;
			right: 2rem;
			top: 0;
			transform: translate(50%, -100%);
			width: 0;
		}

		&:after {
			border-bottom: 16px solid $color-white;
			border-left: 16px solid transparent;
			border-right: 16px solid transparent;
			content: ' ';
			display: block;
			height: 0;
			margin-right: 10px;
			position: absolute;
			right: 2rem;
			top: 0;
			transform: translate(50%, -100%);
			width: 0;
		}
	}

	.download-content {
		h4,
		.heading-4 {
			@include text(lambda);
			@include font-weight(medium);
			color: $color-gray-dark;
			margin-bottom: 1rem;
		}

		.btn-container {
			font-size: 1rem;
			margin-bottom: 0.625rem;
			text-align: center;
		}

		.download-header {
			@include text(mu);
			@include font-weight(normal);
			color: $color-gray-dark;
			margin-bottom: 0.25rem;
			margin-top: 1.5rem;
		}

		.download-header-secondary {
			font-size: 10px;
		}

		input {
			margin: auto;
			width: 90%;
		}
	}

	.drop-down__list-item {
		align-items: flex-start;
		display: flex;
		margin-bottom: 10px;

		&.disabled {
			> input {
				display: none;
			}

			> label {
				margin-left: 0;
			}
		}

		&:first-child {
			@include font-weight(bold);
		}
	}
	.drop-down__list-item.title-divider {
		@include font-weight(bold);
		border-top: 1px solid $color-gray-neutral;
		box-sizing: content-box;
		margin-top: 1rem;
		padding-top: 0.75rem;
	}
	.drop-down__list-item > input {
		height: 20px;
		margin: 0;
		min-width: 20px;
		width: 20px;

		+ label {
			margin-left: 0.5rem;
		}
	}
	.drop-down__list-item > label {
		@include text(lambda);
		color: $color-gray-medium-light;
		margin-left: 0;
	}
	.new-drop-down__button {
		@include text(kappa);
		border: 1px solid $color-gray-neutral;
		color: $color-gray-medium-light;
		cursor: pointer;
		font-family: inherit;
		margin-bottom: 1rem;
		padding: 0.5rem;
		position: relative;
		text-align: left;
		width: 100%;
	}

	.label-filter {
		@include text(lambda);
		@include font-weight(bold);
		color: $color-gray-dark;
		line-height: 2.5;
		padding: 1rem;
	}
	.drop-down-icon {
		align-items: center;
		display: flex;
		height: 18px;
		justify-content: center;
		position: absolute;
		right: 0.75rem;
		stroke: $color-gray-medium-light;
		top: 50%;
		transform: translateY(-50%);
		width: 18px;
	}
	.drop-down-icon--is-rotate {
		transform: rotate(180deg) translateY(50%);
	}

	.new-drop-down {
		@include text(lambda);
		display: none;
		position: relative;
		width: 100%;
		z-index: 1;
	}

	button.new-drop-down__button.new-drop-down--is-open {
		color: $color-gray-dark;
	}

	button.new-drop-down__button.new-drop-down--is-open span {
		color: $color-gray-dark;
	}

	.new-drop-down__list-wrapper {
		background: white;
		border: 1px solid $color-gray-neutral;
		border: 1px solid #eee;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
		max-width: 100%;
		padding: 1rem;
		position: absolute;
		top: 45px;
		width: 100%;
	}

	.mobile-member-list {
		margin: 10px 0 50px;

		.drop-down__list-item {
			display: flex;
			flex-direction: row-reverse;
			justify-content: space-between;

			&.disabled,
			&.title-divider {
				flex-direction: row;
			}

			> input + label {
				margin-left: 0;
			}
		}
	}

	.mobile-filter-label {
		@include text(lambda);
		@include font-weight(semi-bold);
		color: $color-gray-dark;
		display: block;
		margin-bottom: 0.5rem;
	}

	.member-list {
		background-color: $color-gray-light;
		margin: 0 -30px;
		padding: 1rem 30px;
	}

	.filter-input {
		@include text(kappa);
		background-color: $color-white;
		border: 1px solid $color-gray-neutral;
		color: $color-gray-medium-light;
		cursor: pointer;
		font-family: inherit;
		height: 2.5rem;
		margin-bottom: 1rem;
		padding: 0.5rem;
		position: relative;
		text-align: left;
		width: 100%;

		&:focus {
			background-color: $color-white;
		}

		&::placeholder {
			color: $color-gray-medium-light;
			text-align: left;
		}
	}

	.input-icon-container {
		position: relative;
	}

	svg.icon.icon-search {
		position: absolute;
		right: 10px;
		stroke-width: 2px;
		top: 50%;
		transform: translateY(-50%);
		width: 15px;
	}

	.claims-wrap .search-icon-wrapper {
		margin-top: -2.85rem;
		position: absolute;
		right: 16.5rem;
	}

	.claim-button-input {
		color: $color-gray-dark;
		text-align: center;
	}

	.no-display {
		display: none;
	}

	.filter-wrapper {
		display: flex;
		justify-content: space-between;
		.dropdown-title {
			@include font-weight(bold);
			text-align: center;
		}
		.dropdown-subtitle {
			margin-bottom: 0;
			text-align: center;
			@include text(lambda);
		}
		.dropdown-text {
			font-size: 10px;
			margin-bottom: 0;
			text-align: center;
		}
		.top-margin {
			margin-top: 1.5rem;
		}
	}

	@include breakpoint(xs) {
		padding-top: 168px;
	}

	@include breakpoint(md) {
		padding-top: 200px;
		.mobile-member-list {
			display: none;
		}

		.new-drop-down {
			display: block;
		}
	}

	@include breakpoint(lg) {
		padding-top: 300px;
		.grid-row {
			@include make-row();
			section {
				overflow: unset;
				width: unset;
			}
		}

		.claims-wrap {
			.loading-overlay {
				padding-top: 100px;
			}
		}

		.claims-data {
			.data-summary {
				margin-left: 0;

				.data-section {
					padding-bottom: 22px;
				}
			}
		}

		.claim-details {
			padding: 0px;
		}

		.portal-table {
			display: table;
		}

		.mobile-card-list {
			display: none;
		}

		.label-filter {
			@include font-weight(semi-bold);
			padding: 0;
		}
	}
}
