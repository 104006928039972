$default-icon-color: $color-black;

.icon {
	fill: none;
	height: 1rem;
	stroke: $default-icon-color;
	stroke-linecap: round;
	stroke-width: 2px;
	width: 19px;

	use {
		path {
			all: inherit;
		}
	}

	// Fill (vs stroke) icons
	&.icon-calendar,
	&.icon-call,
	&.icon-caution,
	&.icon-danger,
	&.icon-denied,
	&.icon-error,
	&.icon-eye,
	&.icon-help,
	&.icon-info-alert,
	&.icon-more-time,
	&.icon-filter,
	&.icon-grid-view,
	&.icon-list-view,
	&.icon-map-marker,
	&.icon-menu-open,
	&.icon-phs-logo,
	&.icon-phs-icon,
	&.icon-quote,
	&.icon-share-this,
	&.icon-sort,
	&.icon-star,
	&.icon-success,
	&.icon-universal-no,
	&.icon-doctor-typeahead,
	&.icon-hospital,
	&.icon-question {
		fill: $default-icon-color;
		stroke-width: 0;
	}

	// Fill and Stroke icons
	&.icon-arrow-down,
	&.icon-arrow-left-long,
	&.icon-arrow-right,
	&.icon-arrow-right-long,
	&.icon-arrow-up,
	&.icon-chat,
	&.icon-download,
	&.icon-modal-close,
	&.icon-print,
	&.icon-share,
	&.icon-mail {
		stroke: $default-icon-color;
	}

	&.icon-arrow-left-long,
	&.icon-arrow-right-long {
		stroke-width: 1px;
		height: 0.75rem;
		width: 1.25rem;
	}

	&.icon-arrow-left-long {
		margin-right: 0.3125rem;
	}

	&.icon-arrow-right-long {
		margin-left: 0.3125rem;
	}

	&.icon-arrow-left-thin {
		stroke: $default-icon-color;
		fill: $color-gray-light;
		width: 2rem;
		height: 2rem;
	}

	&.icon-check {
		stroke: $color-green-t3; //this is the color for t3. It was strilled from the .svg
	}

	&.icon-download-sm {
		stroke-width: 1px;
	}

	&.icon-external {
		stroke: $default-icon-color;
		stroke-width: 1px;
	}

	&.icon-geolocate {
		stroke: $default-icon-color;
		stroke-width: 1px;
	}

	&.icon-globe {
		stroke: $color-white;
		stroke-width: 2px;
		width: 15px;
		height: 15px;
	}

	&.icon-heart {
		fill: none;
		stroke: $color-red;
		stroke-width: 1px;
	}

	&.icon-print {
		stroke-width: 1px;
	}

	&.icon-office-visit {
		fill: #1a1818;
		height: 29.02px;
		stroke-width: 0;
		width: 50.71px;
	}

	&.icon-phone-visit {
		fill: #1a1818;
		height: 44.51px;
		stroke-width: 0;
		width: 26.12px;
	}

	&.icon-phs-logo {
		height: calc(40.717px * 0.625);
		width: calc(353px * 0.625);
	}

	&.icon-phs-icon {
		height: 43.19px;
		width: 38.33px;
	}

	&.icon-menu-open {
		height: 30px;
		width: 23px;
	}

	&.icon-menu-close {
		height: 19px;
		width: 19px;
	}

	&.icon-search {
		height: 20.707px;
		width: 20.708px;
		stroke-width: 1px;
	}

	&.icon-success {
		fill: $color-green-t3;
		stroke: $color-white;
	}

	&.icon-video-visit {
		fill: #1a1818;
		height: 44.17px;
		stroke-width: 0;
		width: 49.75px;
	}

	&.icon-telehealth {
		fill: $color-gray-darkest;
		height: 28.17px;
		stroke-width: 0;
		width: 16.75px;
	}
}

.icon-phone-test,
.icon-phone-number,
.icon-phone-sms {
	height: 100px;
	width: 50px;
}
