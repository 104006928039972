.styleguide-section-heading {
	@include make-container();

	h3 {
		@include font-weight(bold);
		font-size: 14px;
		text-transform: uppercase;
		margin-bottom: 0.25rem;
	}

	hr {
		border-top: 0;
		border-bottom: 1px solid $color-gray-medium;
		border-style: solid;
		outline: 0;
	}
}
