/*
 *
 * COLOR BACKGROUND CLASSES
 *
 */

/* Monochrome Backgrounds */
.bg-black {
	background-color: $color-black;
}

.bg-white {
	background-color: $color-white;
}

.bg-gray-darkest {
	background-color: $color-gray-darkest;
}

.bg-gray-dark {
	background-color: $color-gray-dark;
}

.bg-gray-light {
	background-color: $color-gray-light;
}

.bg-gray-lightest {
	background-color: $color-gray-light;
}

/* Blues */
.bg-blue {
	background-color: $color-blue;
}
.bg-teal {
	background-color: $color-teal;
}

/* Reds */
.bg-red {
	background-color: $color-red;
}

/* Oranges */
.bg-orange {
	background-color: $color-orange;
}

/* Purples */
.bg-purple {
	background-color: $color-purple;
}

/* Browns */
.bg-khaki {
	background-color: $color-khaki;
}
