.form {
	&.ready {
		&.invalid {
			border-color: $color-red;

			.form-control {
				label,
				.error-message {
					display: flex;
				}

				.error {
					display: block;
				}
			}

			input {
				padding-left: 0;

				.modal {
					padding-left: 20px;
				}
			}
		}

		button {
			color: inherit;
			cursor: pointer;
		}
	}

	.form-intro {
		@include text(iota);
		@include font-weight(bold);
		color: $color-gray-dark;
		display: block;
		margin-bottom: 1.25rem;
		text-align: center;
	}

	.form-control {
		label,
		.error-message,
		.error {
			align-items: center;
			color: $color-red;
			display: none;
			justify-content: center;
		}

		label,
		.error-message {
			@include text(lambda);
		}

		label {
			margin-bottom: -18px;
		}

		.error-message,
		.error {
			padding: 0.625rem 0;
		}
	}

	.input-field {
		justify-content: center;
		position: relative;

		input {
			position: relative;
		}

		.error {
			display: none;
			height: 100%;
			position: absolute;
			padding-right: 0.5rem;
			right: 0;
			top: 10px;

			.icon-error {
				height: 20px;
				position: relative;
				width: 20px;
				z-index: 2;
			}
		}
	}

	// appear disabled until ready
	button {
		color: $color-gray-neutral;
		cursor: default;
	}

	input:focus ~ .floating-label,
	input:not(:focus):valid ~ .floating-label {
		@include text(lambda);
		color: $color-gray-medium-light;
		left: 1rem;
		opacity: 1;
		position: absolute;
		pointer-events: none;
		top: 4px;
	}

	@include breakpoint(md) {
		.form-intro {
			text-align: left;
		}

		.form-control {
			max-width: 265px;

			label,
			.error-message,
			.error {
				justify-content: flex-start;
			}
			input {
				padding-left: 1rem;
				text-align: left;
			}

			button {
				padding-left: 1rem;
				text-align: left;
				width: 100%;
			}
		}

		.input-button-combo {
			display: flex;

			.input-field {
				flex-grow: 1;
			}
		}
	}
}
