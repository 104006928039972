/* $hue-range is in degrees (-360 to 360) */
$hue-range: -90;
$main-color: $color-teal;
$opacity: 0.2;

.grid-overlay {
	display: none;

	&.show-grid {
		display: block;
	}

	&.active {
		.grid-example {
			display: flex;
		}

		.toggle-grid-btn {
			.on {
				display: block;
			}

			.off {
				display: none;
			}
		}
	}

	.toggle-grid-btn {
		@include text(lambda);
		left: 0;
		min-width: 0;
		opacity: 0.5;
		padding: 0.25rem;
		position: fixed;
		top: 0;
		z-index: 9;

		.on {
			display: none;
		}

		.off {
			display: block;
		}
	}

	.grid-example {
		display: none;
		// height: 100%;
		// position: relative;
		// width: 100%;
		bottom: 0;
		left: 0;
		opacity: 0.8;
		position: fixed;
		right: 0;
		top: 0;
		z-index: 8;
	}

	.grid-container {
		@include make-container();
	}

	.grid-row {
		flex-grow: 1;

		.grid-col {
			background-color: transparent;
			height: 100vh;

			@for $i from 1 through $grid-columns {
				&:nth-child(#{$i}) {
					.grid-content {
						background-color: rgba(
							adjust-hue(
								$main-color,
								$i * calc($hue-range / $grid-columns)
							),
							$opacity
						);
					}
				}
			}
		}
	}
}
