.horizontal-card {
	display: flex;
	flex-direction: column;

	&.four-by-three {
		.horizontal-card-image-container {
			padding-bottom: 75%;
		}
	}

	.horizontal-card-image-outer {
		flex: 1;
	}

	.horizontal-card-image-container {
		display: block;
		height: 0;
		overflow: hidden;
		padding-bottom: 56.25%;
		position: relative;
		width: 100%;
		min-width: 170px;

		img {
			width: 100%;
			margin: 0;
			display: block;
		}
	}

	.content {
		flex: 3;
		margin-left: 0;
		margin-top: 2rem;
	}

	@include breakpoint(sm) {
		flex-direction: row;

		&.four-by-three {
			.horizontal-card-image-container {
				padding-bottom: 75%;
			}
		}

		.content {
			margin-top: 0;
			margin-left: 1rem;
		}
	}
}
