.link-block {
	@include make-container();
	text-align: center;
	margin-bottom: 0rem;
	margin-top: 2.5rem;

	&.has-search {
		display: none;
		.search-loaded & {
			display: block;
			margin-top: 15px;
		}
		.detail-view-loaded & {
			margin-left: auto;
			margin-right: auto;
			div {
				max-width: 100% !important;
			}
		}
	}

	a {
		@include text(lambda);
		@include font-weight(medium);
		color: $color-blue;
	}

	& > div {
		a {
			@include text(lambda, theta);
		}
	}

	@include breakpoint(md) {
		margin-bottom: 0rem;

		a {
			text-decoration: none;

			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
	}

	@include breakpoint(lg) {
		margin-bottom: 0rem;
		.search-filters-open & {
			> div {
				max-width: calc(66.6667% - 10px);
				margin: 0 0 0 auto;
			}
		}
	}
}
