.slim-header {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 9;

	[class^='translated-'] & {
		top: 39px;
	}

	.skip-link {
		position: absolute;
		top: -50px;
		background: $color-white;
		padding: 5px;
		&:focus {
			top:0;
			z-index: 10;
		}
	}

	.main-header-outer {
		background-color: $color-red;
	}

	.main-header {
		@include make-container();
		align-items: center;
		display: flex;
		height: 60px;
		justify-content: space-between;
		transition: margin-top 0.3s ease-in-out;

		.icon-phs-logo {
			fill: $color-white;
		}

		.logo-container {
			height: 32px;
		}

		.logo-subtitle {
			display: none;
		}
	}

	.main-navigation {
		.icon-menu-open {
			fill: $color-white;
		}
	}

	@include breakpoint(lg) {
		height: 40px;
		margin-bottom: 2.75rem;
		position: relative;

		.main-header-outer {
			position: fixed;
			top: 0;
			width: 100%;
			z-index: 9;

			[class^='translated-'] & {
				top: 39px;
			}
		}

		.main-header {
			height: 40px;
			transition: none;

			.logo-container {
				height: 32px;
				margin-top: -4px;
			}
		}

		.icon-phs-logo {
			height: calc(40.717px * 0.793);
			width: calc(353px * 0.793);
		}

		.icon-phs-logo {
			height: calc(40.717px * 0.754);
			width: calc(353px * 0.754);
		}

		.nav-link {
			align-items: center;
			color: $color-white;
			display: flex;
			font-size: 13px;

			&::after {
				display: none;
			}
		}

		.search-btn {
			svg {
				stroke: $color-white;
			}
		}
	}
}
