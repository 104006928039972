.feature-label-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 2rem;
	width: 100%;

	.feature-label {
		@include text(eta);
		@include font-weight(normal);

		align-items: center;
		background-color: $color-red-light;
		display: flex;
		font-style: oblique;
		justify-content: center;
		margin-bottom: 1rem;
		min-height: 125px;
		padding: 2rem 1rem;
		text-align: center;
		width: calc(50% - 0.5rem);
	}

	@include breakpoint(md) {
		.feature-label {
			width: calc((100% / 3) - 0.5rem);
		}
	}
}
