@media print {
	.header,
	.main-header-outer,
	.hv-hero,
	.hv-content-nav {
		display: none;

		+ .feedback-banner {
			margin-top: 0 !important;
		}
	}

	.pls-footer {
		display: none;
	}

	.results-controls,
	.skip-link,
	.selected-providers,
	.filters-container,
	.language-dropdown {
		display: none;
	}

	.action-column,
	.search-banner,
	.search-page-controls,
	.results-controls {
		display: none !important;

		&.desktop-only {
			display: none !important;
		}

		&.desktop {
			display: none !important;
		}
	}

	.title-bar {
		margin-top: 1rem !important;
	}

	.results-list {
		&.view-mode-grid {
			display: block !important;
		}
	}

	.location-result,
	.provider-result,
	.results-list-item {
		display: block !important;
		margin-bottom: 40px;
		width: 100% !important;

		img {
			width: 350px !important;
		}

		.schedule-title {
			width: 40%;
		}
	}

	.location-details,
	.provider-details {
		display: block !important;
		margin: 0 !important;
		padding: 0 !important;
	}

	.location-overview,
	.location-result,
	.location-profile-detail,
	.pls-profile-section,
	.parking,
	.provider-profile-detail,
	.provider-result,
	.related-specialties,
	.related-providers {
		display: inline-block !important; //without this the top gets cut off
		font-size: 1.25rem !important;
		page-break-inside: avoid !important;
		margin-bottom: 30px;
		.location-name,
		.provider-name {
			font-size: 1.5rem !important;
		}

		a,
		address,
		.content-container,
		.info-inner,
		li,
		.location-about,
		.location-contact,
		.location-overview,
		.medical-group,
		p,
		.parking-info,
		.provider-details,
		.related-providers-info,
		.related-provider-inner,
		.related-specialties-info,
		.schedule-title,
		ul {
			font-size: 1.25rem !important;
		}

		.location-details,
		.provider-details {
			width: 800px !important; //withough this it is all on left side
		}
	}

	a,
	blockquote,
	table,
	pre {
		page-break-inside: avoid;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	img {
		page-break-after: avoid;
		page-break-inside: avoid;
	}

	ul,
	ol,
	dl {
		page-break-before: avoid;
	}

	@page {
		overflow: visible;
		size: 330mm 427mm;
	}
}
