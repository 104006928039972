.generating-pdf-message {
	margin-top: 150px;
	text-align: center;
}

.pdf-loading {
	margin-top: 10px;
	position: relative;
	&.loading {
		pointer-events: none;

		.loading-overlay {
			opacity: 1;
		}
	}
}

.pls-pdf-container {
	margin-top: 60px;
	iframe {
		width: 100%;
		height: 800px;
	}
}

.download-link {
	margin-top: 150px;
	text-align: center;
}
