.navigation-col-mobile {
	.navigation-col {
		display: none;
	}

	.mobile-nav-accordion {
		h2.second-header {
			background-color: $color-gray-light;
			padding: 25px 19px 24px 60px;
			margin: -15px 2px 20px -31px;
			width: 130%;
		}
	}
	.down-arrow-container {
		margin-left: 50%;
	}

	.mobile-nav-col {
		.navigation-col {
			display: block;
		}
	}

	.mobile-nav-trigger {
		@include text(iota);
		@include font-weight(medium);
		align-items: center;
		background-color: $color-gray-light;
		display: flex;
		height: 70px;
		justify-content: space-between;
		margin-top: 20px;
		margin-bottom: 2rem;
		margin-left: -40px;
		padding: 0 30px;
		width: 100vw;
	}
	.nav-list-container {
		background-color: $color-white;
		display: none;
		height: 100%;
		left: 0;
		overflow: auto;
		opacity: 0;
		padding-bottom: 80px;
		pointer-events: none;
		position: fixed;
		top: 20vh;
		transition: visibility 0s ease-in-out 400ms, opacity 0.2s ease-in-out,
			top 0.2s ease-in-out;
		width: 100%;
		z-index: 11;
		hr {
			width: 90%;
			margin: 18px 25px 18px 25px;
		}
	}
	.mobile-nav-header {
		align-items: center;
		background-color: $color-gray-light;
		display: flex;
		height: 70px;
		justify-content: space-between;
		margin-bottom: 1rem;
		padding: 0 30px;
		.mobile-close-container {
			.mobile-close-btn {
				&:focus-visible {
					outline: $color-black solid 2px;
				}
			}
		}
		.nav-heading {
			@include text(iota);
			@include font-weight(semi-bold);
			margin-bottom: 0;
		}
	}

	.nav-list {
		display: flex;
		flex-direction: column;
		padding: 0 0px;
	}
	.nav-item {
		@include text(kappa);
		@include font-weight(semi-bold);
		align-items: center;
		display: flex;
		min-height: 40px;
		padding: 0.5rem 20px;
		a {
			color: $color-gray-dark;
			text-decoration: none;
			font-size: 0.875rem;
			margin-left: 10px;
			margin-top: 4px;
		}
		&.active {
			@include font-weight(bold);
			border-left-color: $color-gray-dark;
		}
	}

	.icon.icon-menu-close {
		stroke-width: 2px;
	}

	&.nav-open {
		.nav-list-container {
			display: block;
			opacity: 1;
			pointer-events: auto;
			top: 0;
			transition-delay: 0s;
		}
	}

	@include breakpoint(lg) {
		.navigation-col {
			display: block;
		}

		.mobile-nav-trigger,
		.mobile-nav-header,
		.heading-1,
		.nav-list-container {
			display: none;
		}

		&.nav-open {
			.nav-list-container {
				display: none;
			}
		}
	}
}
