.hv-content-nav {
	&.nav-open {
		.nav-list-container {
			display: block;
			opacity: 1;
			pointer-events: auto;
			visibility: visible;
			top: 0;
			transition-delay: 0s;
		}
	}

	&.trigger-fixed {
		height: 70px;
		margin-bottom: 2rem;

		.mobile-nav-trigger {
			position: fixed;
			top: 0;
			width: 100%;
			z-index: 8;
			transition: top 0.2s;
		}

		.navbar-scroll-up & {
			.mobile-nav-trigger {
				top: 60px;
			}
		}

		.navbar-scroll-down & {
			.mobile-nav-trigger {
				top: 0;
			}
		}

		.body-fixed & {
			.mobile-nav-trigger {
				transition: top 0.3s ease-in-out;
			}
		}

		.mobile-nav-open & {
			.mobile-nav-trigger {
				top: 100vh;
			}
		}
	}

	.mobile-nav-trigger {
		@include text(iota);
		@include font-weight(medium);
		align-items: center;
		background-color: $color-gray-light;
		display: flex;
		height: 70px;
		justify-content: space-between;
		margin-bottom: 2rem;
		margin-left: -30px;
		padding: 0 30px;
		width: 100vw;
	}

	.nav-list-container {
		background-color: $color-white;
		pointer-events: none;
		overflow: auto;
		visibility: hidden;
		opacity: 0;
		top: 20vh;
		padding-bottom: 80px;
		position: fixed;
		width: 100%;
		height: 100%;
		left: 0;
		transition: visibility 0s ease-in-out 400ms, opacity 0.2s ease-in-out,
			top 0.2s ease-in-out;
		z-index: 9;
	}

	.mobile-nav-header {
		align-items: center;
		background-color: $color-gray-light;
		display: flex;
		height: 70px;
		justify-content: space-between;
		margin-bottom: 1rem;
		padding: 0 30px;

		.mobile-close-container {
			.mobile-close-btn {
				&:focus {
					outline: none !important;
				}
			}
		}

		.nav-heading {
			@include text(iota);
			@include font-weight(semi-bold);
			margin-bottom: 0;
		}
	}

	.heading-1 {
		padding: 0 30px;
	}

	.nav-list {
		display: flex;
		flex-direction: column;
		padding: 0 30px;
	}

	.nav-item {
		@include text(kappa);
		@include font-weight(semi-bold);
		align-items: center;
		border-left: 2px solid $color-gray-neutral;
		display: flex;
		min-height: 60px;
		padding: 0.5rem 20px;

		a {
			color: $color-gray-dark;
			text-decoration: none;
		}

		&.active {
			@include font-weight(bold);
			border-left-color: $color-gray-dark;
		}
	}

	@include breakpoint(md) {
		&.column-fixed {
			.nav-list {
				@include make-col(3);
				padding: 0;
				position: fixed;
				top: 50px;
			}
		}

		&.column-end {
			margin-top: auto;

			.nav-list {
				position: relative;
				flex: 0 0 100%;
				max-width: 100%;
			}
		}

		.nav-list {
			padding: 0;
		}

		.mobile-nav-trigger,
		.mobile-nav-header,
		.heading-1 {
			display: none;
		}

		.nav-list-container {
			background-color: transparent;
			display: block;
			height: auto;
			opacity: 1;
			overflow: visible;
			pointer-events: auto;
			visibility: visible;
			position: relative;
			top: auto;
			left: auto;
			width: auto;
			z-index: 0;
		}
	}
}
