.portal {
	.heading-1 {
		@include text(iota);
		@include font-weight(light);
		color: $color-red;
		margin-bottom: 1rem;
	}

	.heading-2 {
		@include text(iota);
		@include font-weight(medium);
		color: $color-gray-dark;
		margin-bottom: 1rem;
	}

	.heading-3 {
		@include text(lambda);
		@include font-weight(bold);
		color: $color-gray-dark;
		margin-bottom: 1rem;
	}

	.description {
		@include text(kappa);
		@include font-weight(medium);
		color: $color-gray-dark;
		margin-bottom: 2rem;

		&:last-child {
			margin-bottom: 0;
		}

		p {
			@include text(kappa);
			@include font-weight(medium);
			color: $color-gray-dark;
			margin-bottom: 1.25rem;
		}

		.btn-link {
			@include text(kappa);
			@include font-weight(medium);
			display: inline;
		}
	}

	.overview-text {
		@include text(iota);
		@include font-weight(light);
		color: $color-gray-dark;
		margin-bottom: 3rem;

		a {
			cursor: pointer;
		}
	}

	.data-label {
		@include text(lambda);
		@include font-weight(medium);
		color: $color-gray-dark;
		margin-bottom: 1.25rem;
	}

	.rich-text {
		margin-bottom: 3rem;

		h1,
		h2,
		h3,
		h4,
		h5 {
			color: $color-gray-dark;
			margin-bottom: 1rem;
		}

		h1,
		h2 {
			@include text(epsilon);
			@include font-weight(light);
		}

		h3 {
			@include text(eta);
			@include font-weight(semi-bold);
		}

		h4 {
			@include text(theta);
			@include font-weight(semi-bold);
		}

		h5 {
			@include text(iota);
			@include font-weight(bold);
		}

		p {
			@include text(iota);
			color: $color-gray-dark;
		}

		ul,
		ol {
			margin-left: 1.5rem;
			margin-bottom: 1.5rem;
			padding-left: 3rem;
		}

		li {
			@include text(iota);
			color: $color-gray-dark;
			padding-bottom: 0.5rem;
		}

		.mobile-card-list {
			padding-left: 0;
			margin-left: 0;
		}

		.mobile-card-data-list {
			padding: 1rem;
			background: #eee;
		}
	}

	fieldset {
		border: 0 none;
		margin: 0;
		padding: 0;

		legend {
			padding: 0;
		}
	}

	@include breakpoint(md) {
		.heading-1 {
			@include text(delta);
		}

		.overview-text {
			@include text(zeta);
		}
	}
}
