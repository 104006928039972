.radio-wrapper {
	align-items: flex-start;
	display: flex;
	justify-items: flex-start;
	margin-bottom: 0.75rem;
	position: relative;

	&.disabled {
		pointer-events: none;

		input[type='radio'] {
			&:checked {
				+ label {
					&:after {
						background-color: $color-gray-light;
					}
				}
			}

			+ label {
				color: $color-gray-neutral;
			}
		}
	}

	input[type='radio'] {
		margin-top: 2px;
		opacity: 0;

		&:checked {
			+ label {
				&:after {
					background-color: $color-black;
					border-radius: 50%;
					content: '';
					display: block;
					height: 0.5rem;
					left: 0;
					margin: 0.375rem;
					position: absolute;
					width: 0.5rem;
				}
			}
		}

		&:hover {
			cursor: pointer;

			+ label {
				&:before {
					border: 1px solid $color-gray-neutral;
				}
			}
		}

		&:focus {
			+ label {
				&:before {
					border: 1px solid $color-gray-neutral;
				}
			}
		}

		+ label {
			&:before {
				background-color: $color-white;
				border-radius: 50%;
				content: '';
				display: block;
				height: 1.25rem;
				left: 0;
				position: absolute;
				width: 1.25rem;
			}

			&:hover {
				cursor: pointer;

				&:before {
					border: 1px solid $color-gray-neutral;
				}
			}
		}
	}

	label {
		@include text(lambda);
		@include font-weight(medium);
		display: flex;
		justify-content: space-between;
		margin: 0;
		padding: 0 0 0 0.5rem;
		width: 100%;

		.label-display-name {
			+ .label-name {
				text-align: right;
			}
		}

		.label-name {
			flex-grow: 1;
		}

		.label-number {
			padding-left: 1rem;
		}
	}
}

.radio-btns {
	display: flex;

	.radio-wrapper {
		flex: 1;
		margin-right: 0.5rem;
		margin-bottom: 0;

		&:last-child {
			margin-right: 0;
		}
	}

	input[type='radio'] {
		height: 0;
		width: 0;

		&:checked {
			+ label {
				background-color: $color-gray-medium;
				border-color: $color-orange;

				&:before,
				&:after {
					display: none;
				}
			}
		}

		&:focus {
			+ label {
				background-color: $color-orange;
				border-color: $color-orange;
				box-shadow: 0 0 0 0.2rem rgba($color-orange, 0.35);
			}
		}

		+ label {
			align-items: center;
			background-color: $color-gray-medium;
			border: 1px solid $color-gray-medium;
			border-radius: 0.625rem;
			color: $color-white;
			display: flex;
			font-size: 1rem;
			height: 100%;
			justify-content: center;
			line-height: 1.375;
			padding: 0.75rem 0.25rem;
			width: 100%;

			&:before,
			&:after {
				display: none;
			}

			&:hover {
				background-color: $color-orange;
				border-color: $color-orange;
			}

			span {
				text-align: center;
			}
		}
	}

	@include breakpoint(xl) {
		.radio-wrapper {
			margin-right: 1rem;
		}
	}
}
