.display-settings {
	opacity: 0;

	.initialized & {
		opacity: 1;
	}

	.desktop-only,
	.android-only,
	.firefox-only,
	.ios-only,
	.ios-unsupported-only,
	.ipad-only,
	.mac-only,
	.mac-only-inline,
	.telehealth-enabled-only,
	.upgrade-required-only {
		display: none;
	}
}

.telehealth-enabled {
	.telehealth-enabled-only {
		display: block;
	}

	.telehealth-disabled-only {
		display: none;
	}
}

.is-desktop {
	.desktop-only {
		display: block;
	}

	.mobile-only {
		display: none;
	}
}

.is-android {
	.android-only {
		display: block;
	}

	.hide-for-android {
		display: none;
	}
}

.is-firefox {
	.firefox-only {
		display: block;
	}

	.chrome-only {
		display: none;
	}
}

.is-ios {
	.ios-only {
		display: block;
	}

	.hide-for-ios {
		display: none;
	}
}

.is-mac {
	.mac-only {
		display: block;
	}

	.mac-only-inline {
		display: inline;
	}
}

.ios-unsupported {
	.ios-unsupported-only {
		display: block;
	}

	.ios-supported-only {
		display: none;
	}
}

.is-ipad {
	.ipad-only {
		display: block;
	}

	.hide-for-ipad {
		display: none;
	}
}

.upgrade-required {
	.upgrade-required-only {
		display: block;
	}

	.upgrade-not-required {
		display: none;
	}
}
