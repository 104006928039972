.global-search-locations {
	margin-bottom: 1.5rem;
	ul {
		list-style-type: none;
		padding-left: 1.5rem;

		.title {
			list-style-image: url('/assets/images/icons/map_view.svg');

			h4 {
				@include text(lambda);
			}
		}
	}

	p {
		@include text(mu);
		margin: 0;
	}
}
