.location-facility-info {
	.facility-data-value {
		font-size: 14px;
		font-weight: 400;
	}
	.facility-data {
		&-wrapper {
			columns: 4 313px;
			margin: 30px 0 0;
		}
		&-item {
			break-inside: avoid;
			margin-bottom: 25px;
			ul {
				list-style-type: disc;
			}
			.facility-data-list {
				list-style: none;
			}
		}
		&-value {
			@include text(lambda);
			@include font-weight(medium);
		}
		&-link {
			@include text(lambda);
			@include font-weight(medium);
			margin-bottom: 0.3rem;
			a {
				color: $color-blue;
			}
		}
	}

	@include breakpoint(md) {
		.facility-data-wrapper {
			margin: 5px 0 0;
		}
	}
}
