.badge {
	@include text(kappa);
	@include font-weight(normal);
	background-color: $color-gray-light;
	border-radius: 9px;
	color: $color-black;
	margin-bottom: 1.875rem;
	padding: 1px 9px 2px;
	white-space: nowrap;

	&:hover {
		color: $color-black;
		cursor: pointer;
		text-decoration: none;
	}
}

.badge-danger {
	background-color: $color-danger;

	&:hover {
		background-color: $color-danger-hover;
	}
}

.badge-warning {
	background-color: $color-warning;
	&:hover {
		background-color: $color-warning-hover;
	}
}

.badge-success {
	background-color: $color-success;
	&:hover {
		background-color: $color-success-hover;
	}
}

.badge-info {
	background-color: $color-info;
	&:hover {
		background-color: $color-info-hover;
	}
}
