.pls-profile-insurance {
	margin-bottom: 3rem;

	.insurance-container {
		color: $color-gray-dark;
	}

	.insurance-title {
		@include text(zeta, epsilon);
		@include font-weight(normal);
		margin-bottom: 10px;
	}

	.location-search-input {
		margin-top: 18px;
	}

	.insurance-inner.error {
		.error-message {
			background-color: $color-red-light;
			display: block;
			margin-bottom: 1.5rem;
			padding: 1rem;

			:last-child {
				margin-bottom: 0;
			}
		}

		.insurance-message {
			display: none;
		}
	}

	.search-message {
		display: block;
	}

	.error-message {
		display: none;
	}

	.insurance-message,
	.error-message {
		p {
			@include text(lambda);
			@include font-weight(medium);

			a {
				color: $color-blue;
				display: inline;
			}
		}
	}

	.highlight {
		background-color: $color-yellow-highlight;
	}

	ul {
		padding-left: 16px;
	}

	.insurance-list {
		li {
			@include text(lambda);
			@include font-weight(medium);
			margin-bottom: 0.25rem;
		}
	}

	.insurance-plans {
		.profile-title {
			margin: 30px 0 0.25rem;

			&.new-patients {
				margin-top: 6px;
			}
		}
	}

	@include breakpoint(md) {
		.insurance-inner {
			display: flex;
		}

		.insurance-search-input {
			flex: 0 1 calc(100% / 3);
			margin-top: 0;
			max-width: calc(100% / 3);
			padding-right: 2rem;
		}

		.insurance-list {
			flex: 0 1 calc(100% / 3 * 2);
			max-width: calc(100% / 3 * 2);

			ul {
				columns: 2;
			}
		}
	}
}
