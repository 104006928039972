.t3 {
	$header-height: 60px;
	$header-margin: 2rem;
	$footer-height: 50px;
	$safari-ios-buffer: 122px;
	background-color: $color-gray-light;

	&.showing-modal {
		.main-header {
			overflow: hidden;
		}
	}

	main {
		background-color: $color-gray-light;
	}

	section {
		align-items: center;
		display: flex;
		margin-top: -$header-margin;
		min-height: calc(100vh - #{$header-height} - #{$footer-height});
		padding-bottom: 2.5rem;
		padding-top: 2rem;

		@supports (-webkit-touch-callout: none) {
			min-height: calc(
				100vh - #{$header-height} - #{$footer-height} - #{$safari-ios-buffer}
			);
		}
	}

	@include breakpoint(md) {
		$header-height: 60px;
		$header-margin: 60px;
		$footer-height: 160px;
		section {
			margin-top: -$header-margin;
			min-height: calc(100vh - #{$header-height} - #{$footer-height});
			padding-top: 6rem;
			padding-bottom: 5rem;
		}
	}
}
