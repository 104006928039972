.ready-snack-bar {
	background-color: $color-gray-medium;
	color: $color-white;
	display: none;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 1rem 2rem 1rem 1.25rem;
	width: 100%;

	.getting-ready & {
		display: flex;
	}

	.ready-message {
		align-items: flex-start;
		display: flex;
		margin-bottom: 2rem;
		width: 100%;
	}

	.icon-information {
		fill: $color-white;
		height: 25px;
		margin-right: 0.5rem;
		width: 50px;
	}

	p {
		@include font-weight(normal);
		@include text(lambda);
		margin-bottom: 0;
	}

	.countdown {
		@include font-weight(bold);
	}

	.ready-action {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;
		width: 100%;

		.btn {
			margin: 0 0.2rem 1.5rem;
			min-width: 126px;
			padding: 0.625rem 0.425rem;
		}

		.btn-secondary {
			background-color: transparent;
			border-color: $color-white;
			border-width: 2px;
			color: $color-white;

			&:hover {
				background-color: $color-white;
				color: $color-gray-dark;
			}
		}
	}

	@include breakpoint(md) {
		flex-wrap: nowrap;
		justify-content: space-between;
		width: 100%;

		.ready-message {
			align-items: center;
			flex-grow: 1;
			margin-bottom: 0;
			padding-right: 0.5rem;
		}

		.ready-action {
			display: flex;
			flex-wrap: nowrap;
			justify-content: flex-end;
			width: 324px;

			.btn {
				margin: 0;
			}

			.btn-primary {
				margin-right: 0.4rem;
			}
		}
	}
}
