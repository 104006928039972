.hv-footer {
	p {
		color: $color-white;

		a {
			color: $color-white;
		}
	}

	li {
		a {
			color: $color-white;
		}
	}

	.copyright {
		p {
			margin-bottom: 1.5rem;
		}
	}

	@include breakpoint(md) {
		li, p {
			a {
				text-decoration: underline;

				&:hover,
				&:focus {
					text-decoration: none;
				}
			}
		}
	}
}
