.text-content {
	ul {
		list-style: none;
		margin-bottom: 2rem;
		padding-left: 1.5rem;

		> li {
			@include text(epsilon);
			margin-left: 1rem;

			&:before {
				color: $color-blue;
				content: '\2022';
				display: inline-block;
				margin-left: -1rem;
				width: 1rem;
			}

			&:not(:last-of-type) {
				margin-bottom: 0.25rem;
			}
		}

		ol,
		ul {
			margin-top: 0.25rem;
		}

		@include breakpoint(md) {
			margin-bottom: 3rem;
		}
	}

	ol {
		margin-bottom: 2rem;
		padding-left: 3rem;

		> li {
			@include text(epsilon);

			&:before {
				@include font-weight(normal);
			}

			&:not(:last-of-type) {
				margin-bottom: 0.25rem;
			}
		}

		ol,
		ul {
			margin-top: 0.25rem;
		}

		@include breakpoint(md) {
			margin-bottom: 3rem;
		}
	}
}
