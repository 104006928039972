/*
 * highlight($color (optional, default $color-bright-blue)))
 * adds background highlight and ltr animation - defaults to $color-bright-blue, but can accept other colors
 */
@mixin highlight($color) {
	background: linear-gradient(to right, transparent 50%, $color 50%);
	background-size: calc(200% + 2px) 100%;
	background-position: left 100%;
	position: relative;
	transition: color 0.1s linear, background-position 0.1s linear;
	z-index: 0;

	// Psuedo element hides if -100% highlight background-position overshoots by a pixel or two (can't use calc in background-position as this will cause issues in IE/Edge)
	&:before {
		background-color: transparent;
		content: '';
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 3px;
		z-index: -1;
		transition: background-color 0.05s linear;
	}

	&:hover,
	&:focus {
		background-position: -100% 100%;
		text-decoration: none;

		&:before {
			background-color: $color;
		}
	}
}
