.global-search {
	height: 100px;
	width: 100%;
	background-color: $color-off-white;
	display: none;
	justify-content: center;
	align-items: center;

	&.homepage-search-results,
	&.search-results-page {
		background-color: transparent;
		display: flex;
		height: 60px;
		padding-top: 0;
		margin: 0;

		.global-search-row {
			padding: 0;

			.global-search-button {
				display: none;
			}

			.search-btn {
				display: block;
				position: absolute;
				right: 2rem;
				top: 1.2rem;

				.icon-search {
					stroke: $color-gray-medium-light;
				}
			}

			.global-search-input {
				@include text(kappa);
				@include font-weight(light);
				background-color: $color-gray-light;
				border: 0px;
				width: 100%;
			}
		}

		.global-search-button {
			background-color: $color-gray-light;
		}

		.search-close {
			display: none;
		}
	}

	&.search-results-page {
		margin-bottom: 2rem;
	}

	.search-open & {
		display: none;
	}

	.global-search-row {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-left: 30px;
		padding-right: 30px;

		.global-form-search {
			display: flex;
			position: relative;
			flex-direction: row;
			justify-content: center;
			width: 100%;
		}

		.global-search-input {
			@include text(zeta);
			@include font-weight(light);
			background-color: white;
			height: 60px;
			width: 75%;
			border: 1px solid $color-gray-neutral;
			margin-bottom: 0;
			text-align: left;
			padding-left: 2rem;

			&::placeholder {
				color: $color-gray-medium-light;
				text-align: left;
			}
		}

		.global-search-button {
			@include text(kappa);
			@include font-weight(medium);
			float: left;
			width: 150px;
			height: 60px;
			background: white;
			font-size: 17px;
			border: 1px solid $color-gray-neutral;
			border-left: none;
			cursor: pointer;
			transition: all 0.1s ease-in-out;

			&:hover,
			&:focus {
				background-color: $color-gray-dark;
				border-color: $color-gray-dark;
				color: $color-white;
			}
		}

		.search-btn {
			display: none;
		}

		.search-close {
			position: relative;
			left: 3%;
		}
	}

	@include breakpoint(md) {
		&.search-results-page {
			height: auto;

			.global-search-row {
				padding: 60px 30px;

				.global-search-input {
					@include text(zeta);
					@include font-weight(light);
					background-color: white;
					height: 65px;
					width: 100%;
					border: 1px solid $color-gray-neutral;
					margin-bottom: 0;
					text-align: left;
					padding-left: 2rem;

					&::placeholder {
						color: $color-gray-medium-light;
						text-align: left;
					}
				}

				.global-search-button {
					display: block;
					height: 65px;
				}

				.search-btn {
					display: none;
				}

				.search-close {
					position: relative;
					left: 3%;
				}
			}

			.global-search-row {
				@include make-container();
			}

			.global-form-search {
				padding: 0;
			}
		}
	}

	@media (min-width: $md-lg-breakpoint) {
		.search-open & {
			display: flex;
		}

		&.search-results-page {
			.global-search-row {
				justify-content: flex-start;

				.global-form-search {
					@include make-col(9);
					margin-left: 0;
					padding: 0;
				}
			}
		}

		.global-search-input {
			height: 65px;
		}

		.global-search-button {
			height: 65px;
		}
	}

	@include breakpoint(lg) {
		height: 140px;

		.global-form-search {
			@include make-container();
		}
	}
}
