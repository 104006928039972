@charset "UTF-8";
/* Font Families */
/* Global (reset, functions, variables, mixins, helpers) */
/* Reset */ /* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
}
body.body-fixed {
  overflow-y: hidden;
  position: fixed;
  top: 0;
  width: 100%;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  display: block;
  max-width: 100%;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Strip border, outline, and padding from unclassed buttons */
button {
  background: transparent;
  border: 0;
  padding: 0;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
    transition-duration: 0.01ms !important;
  }
}
/* Variables */
/*
 *
 * BREAKPOINTS MAP
 *
 */
/*
 *
 * COLOR VARIABLES
 *
 */
/* Monochromes */
/* Blues */
/* Reds */
/* Oranges */
/* Yellows */
/* Purples */
/* Browns */
/* Greens */
/* Alert Colors */
/* Social Media Colors */
/* Box Shadow */ /*
 *
 * TYPOGRAPHY VARIABLES AND MAPS
 *
 */
@font-face {
  font-family: "AvenirLight";
  src: url("../fonts/AvenirLight.otf") format("opentype");
}
@font-face {
  font-family: "AvenirBook";
  src: url("../fonts/AvenirBook.otf") format("opentype");
}
@font-face {
  font-family: "AvenirRoman";
  src: url("../fonts/AvenirRoman.otf") format("opentype");
}
@font-face {
  font-family: "AvenirMedium";
  src: url("../fonts/AvenirMedium.otf") format("opentype");
}
@font-face {
  font-family: "AvenirHeavy";
  src: url("../fonts/AvenirHeavy.otf") format("opentype");
}
@font-face {
  font-family: "AvenirBlack";
  src: url("../fonts/AvenirBlack.otf") format("opentype");
}
/*
  * Font Weight map
  * Matching keys gets used together by default
  * Used in font-weight() mixin to return font family and for mock fonts the weight.
  */
/*
  * Font Size and Line Height Maps
  * Matching keys gets used together by default
  * Used in text() mixin and calulateLineHeight() function
  */
/* Functions */
/*
 *
 * TYPOGRAPHY FUNCTIONS
 *
 */
/*
 * calculateRem($font-size, $base)
 * convert absolute (px) into relative em (rem) values
 * assumes a 16px baseline; override if in a different context
 * -----
 * calculateRem(32)      ==> 0.5rem
 * calculateRem(48, 12)  ==> 4rem
 */
/*
 * calulateLineHeight($font-size, $line-height)
 * convert absolute (px) from corresponding line-heights map into unitless line-height values
 * $font-size argument must correlate to $font-size-scale in _variables.scss to avoid errant font-sizes
 * $line-height argument can be correlated to $line-height-scale (ie alpha, beta) in _variables.scss or a specific number value (ie 27, 32)
 */
/* Mixins */
/*
 * font-weight($font-weight)
 * takes the weight value like light, medium bold and returns a font-family
 */
/*
 * highlight($color (optional, default $color-bright-blue)))
 * adds background highlight and ltr animation - defaults to $color-bright-blue, but can accept other colors
 */
/*
 * loader($color, $height(optional, default 26px), $stroke(optional, default 2px))
 * adds generic loader as :after psuedo element
 * $color must be passed. $height and $stroke have defaults, but can be passed in as needed.
 */
/*
 * pulse-highlight($color)
 * adds pulse highlight to circular element with equal height and width
 * $color must be passed
 */
/*
 * text($font-size, $line-height(optional))
 * calculates font-size and line-height corresponding to $font-size-scale and $line-height-scale maps in _variables.scss
 * $line-height argument is optional, and can be a different value in $line-height-scale or a number value
 */
/* Helpers */
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

/*
 *
 * COLOR BACKGROUND CLASSES
 *
 */
/* Monochrome Backgrounds */
.bg-black {
  background-color: #000;
}

.bg-white {
  background-color: #fff;
}

.bg-gray-darkest {
  background-color: #222;
}

.bg-gray-dark {
  background-color: #363534;
}

.bg-gray-light {
  background-color: #eee;
}

.bg-gray-lightest {
  background-color: #eee;
}

/* Blues */
.bg-blue {
  background-color: #00759a;
}

.bg-teal {
  background-color: #48b9c7;
}

/* Reds */
.bg-red {
  background-color: #a71930;
}

/* Oranges */
.bg-orange {
  background-color: #df7a00;
}

/* Purples */
.bg-purple {
  background-color: #5f3171;
}

/* Browns */
.bg-khaki {
  background-color: #d7d3c7;
}

.container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
}
@media (min-width: 768px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .container {
    padding-right: 70px;
    padding-left: 70px;
  }
}

.container-bg {
  margin-right: auto;
  margin-left: auto;
  max-width: calc(1440px - 30px * 2);
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
}
@media (min-width: 768px) {
  .container-bg {
    max-width: calc(
			1440px - 30px * 2
		);
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .container-bg {
    max-width: calc(
			1440px - 30px * 2
		);
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .container-bg {
    max-width: calc(
			1440px - 70px * 2
		);
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .container-bg {
    max-width: calc(
			1440px - 70px * 2
		);
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .container-bg {
    max-width: calc(
			1440px - 70px * 2
		);
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .container-bg {
    max-width: calc(
			1440px - 70px * 2
		);
    padding-right: 70px;
    padding-left: 70px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.col {
  width: 100%;
}

.col,
[class*=col-] {
  flex: 1 1 0;
  margin-bottom: 0;
  padding: 0 10px;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
  margin-bottom: 0;
  padding: 0 10px;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
  margin-bottom: 0;
  padding: 0 10px;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
  margin-bottom: 0;
  padding: 0 10px;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
  margin-bottom: 0;
  padding: 0 10px;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
  margin-bottom: 0;
  padding: 0 10px;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: 0;
  padding: 0 10px;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
  margin-bottom: 0;
  padding: 0 10px;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
  margin-bottom: 0;
  padding: 0 10px;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
  margin-bottom: 0;
  padding: 0 10px;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
  margin-bottom: 0;
  padding: 0 10px;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
  margin-bottom: 0;
  padding: 0 10px;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 0;
  padding: 0 10px;
}

@media (min-width: 768px) {
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0;
    padding: 0 10px;
  }
}
@media print and (min-width: 6px) {
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0;
    padding: 0 10px;
  }
}
@media (min-width: 1280px) {
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0;
    padding: 0 10px;
  }
}
@media print and (min-width: 7px) {
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0;
    padding: 0 10px;
  }
}
@media (min-width: 1440px) {
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0;
    padding: 0 10px;
  }
}
@media print and (min-width: 1440px) {
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0;
    padding: 0 10px;
  }
}
.top-buffer {
  margin-top: 10px;
}

@media (min-width: 768px) {
  ol.two-columns, ol.three-columns, ol.four-columns,
ul.two-columns,
ul.three-columns,
ul.four-columns {
    columns: 2;
    column-gap: 1rem;
  }
}
@media print and (min-width: 6px) {
  ol.two-columns, ol.three-columns, ol.four-columns,
ul.two-columns,
ul.three-columns,
ul.four-columns {
    columns: 2;
    column-gap: 1rem;
  }
}
@media (min-width: 1280px) {
  ol.three-columns, ol.four-columns,
ul.three-columns,
ul.four-columns {
    columns: 3;
    column-gap: 1rem;
  }
}
@media print and (min-width: 7px) {
  ol.three-columns, ol.four-columns,
ul.three-columns,
ul.four-columns {
    columns: 3;
    column-gap: 1rem;
  }
}
@media (min-width: 1440px) {
  ol.four-columns,
ul.four-columns {
    columns: 4;
    column-gap: 1rem;
  }
}
@media print and (min-width: 1440px) {
  ol.four-columns,
ul.four-columns {
    columns: 4;
    column-gap: 1rem;
  }
}

@media print {
  .header,
.main-header-outer,
.hv-hero,
.hv-content-nav {
    display: none;
  }
  .header + .feedback-banner,
.main-header-outer + .feedback-banner,
.hv-hero + .feedback-banner,
.hv-content-nav + .feedback-banner {
    margin-top: 0 !important;
  }
  .pls-footer {
    display: none;
  }
  .results-controls,
.skip-link,
.selected-providers,
.filters-container,
.language-dropdown {
    display: none;
  }
  .action-column,
.search-banner,
.search-page-controls,
.results-controls {
    display: none !important;
  }
  .action-column.desktop-only,
.search-banner.desktop-only,
.search-page-controls.desktop-only,
.results-controls.desktop-only {
    display: none !important;
  }
  .action-column.desktop,
.search-banner.desktop,
.search-page-controls.desktop,
.results-controls.desktop {
    display: none !important;
  }
  .title-bar {
    margin-top: 1rem !important;
  }
  .results-list.view-mode-grid {
    display: block !important;
  }
  .location-result,
.provider-result,
.results-list-item {
    display: block !important;
    margin-bottom: 40px;
    width: 100% !important;
  }
  .location-result img,
.provider-result img,
.results-list-item img {
    width: 350px !important;
  }
  .location-result .schedule-title,
.provider-result .schedule-title,
.results-list-item .schedule-title {
    width: 40%;
  }
  .location-details,
.provider-details {
    display: block !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .location-overview,
.location-result,
.location-profile-detail,
.pls-profile-section,
.parking,
.provider-profile-detail,
.provider-result,
.related-specialties,
.related-providers {
    display: inline-block !important;
    font-size: 1.25rem !important;
    page-break-inside: avoid !important;
    margin-bottom: 30px;
  }
  .location-overview .location-name,
.location-overview .provider-name,
.location-result .location-name,
.location-result .provider-name,
.location-profile-detail .location-name,
.location-profile-detail .provider-name,
.pls-profile-section .location-name,
.pls-profile-section .provider-name,
.parking .location-name,
.parking .provider-name,
.provider-profile-detail .location-name,
.provider-profile-detail .provider-name,
.provider-result .location-name,
.provider-result .provider-name,
.related-specialties .location-name,
.related-specialties .provider-name,
.related-providers .location-name,
.related-providers .provider-name {
    font-size: 1.5rem !important;
  }
  .location-overview a,
.location-overview address,
.location-overview .content-container,
.location-overview .info-inner,
.location-overview li,
.location-overview .location-about,
.location-overview .location-contact,
.location-overview .location-overview,
.location-overview .medical-group,
.location-overview p,
.location-overview .parking-info,
.location-overview .provider-details,
.location-overview .related-providers-info,
.location-overview .related-provider-inner,
.location-overview .related-specialties-info,
.location-overview .schedule-title,
.location-overview ul,
.location-result a,
.location-result address,
.location-result .content-container,
.location-result .info-inner,
.location-result li,
.location-result .location-about,
.location-result .location-contact,
.location-result .location-overview,
.location-result .medical-group,
.location-result p,
.location-result .parking-info,
.location-result .provider-details,
.location-result .related-providers-info,
.location-result .related-provider-inner,
.location-result .related-specialties-info,
.location-result .schedule-title,
.location-result ul,
.location-profile-detail a,
.location-profile-detail address,
.location-profile-detail .content-container,
.location-profile-detail .info-inner,
.location-profile-detail li,
.location-profile-detail .location-about,
.location-profile-detail .location-contact,
.location-profile-detail .location-overview,
.location-profile-detail .medical-group,
.location-profile-detail p,
.location-profile-detail .parking-info,
.location-profile-detail .provider-details,
.location-profile-detail .related-providers-info,
.location-profile-detail .related-provider-inner,
.location-profile-detail .related-specialties-info,
.location-profile-detail .schedule-title,
.location-profile-detail ul,
.pls-profile-section a,
.pls-profile-section address,
.pls-profile-section .content-container,
.pls-profile-section .info-inner,
.pls-profile-section li,
.pls-profile-section .location-about,
.pls-profile-section .location-contact,
.pls-profile-section .location-overview,
.pls-profile-section .medical-group,
.pls-profile-section p,
.pls-profile-section .parking-info,
.pls-profile-section .provider-details,
.pls-profile-section .related-providers-info,
.pls-profile-section .related-provider-inner,
.pls-profile-section .related-specialties-info,
.pls-profile-section .schedule-title,
.pls-profile-section ul,
.parking a,
.parking address,
.parking .content-container,
.parking .info-inner,
.parking li,
.parking .location-about,
.parking .location-contact,
.parking .location-overview,
.parking .medical-group,
.parking p,
.parking .parking-info,
.parking .provider-details,
.parking .related-providers-info,
.parking .related-provider-inner,
.parking .related-specialties-info,
.parking .schedule-title,
.parking ul,
.provider-profile-detail a,
.provider-profile-detail address,
.provider-profile-detail .content-container,
.provider-profile-detail .info-inner,
.provider-profile-detail li,
.provider-profile-detail .location-about,
.provider-profile-detail .location-contact,
.provider-profile-detail .location-overview,
.provider-profile-detail .medical-group,
.provider-profile-detail p,
.provider-profile-detail .parking-info,
.provider-profile-detail .provider-details,
.provider-profile-detail .related-providers-info,
.provider-profile-detail .related-provider-inner,
.provider-profile-detail .related-specialties-info,
.provider-profile-detail .schedule-title,
.provider-profile-detail ul,
.provider-result a,
.provider-result address,
.provider-result .content-container,
.provider-result .info-inner,
.provider-result li,
.provider-result .location-about,
.provider-result .location-contact,
.provider-result .location-overview,
.provider-result .medical-group,
.provider-result p,
.provider-result .parking-info,
.provider-result .provider-details,
.provider-result .related-providers-info,
.provider-result .related-provider-inner,
.provider-result .related-specialties-info,
.provider-result .schedule-title,
.provider-result ul,
.related-specialties a,
.related-specialties address,
.related-specialties .content-container,
.related-specialties .info-inner,
.related-specialties li,
.related-specialties .location-about,
.related-specialties .location-contact,
.related-specialties .location-overview,
.related-specialties .medical-group,
.related-specialties p,
.related-specialties .parking-info,
.related-specialties .provider-details,
.related-specialties .related-providers-info,
.related-specialties .related-provider-inner,
.related-specialties .related-specialties-info,
.related-specialties .schedule-title,
.related-specialties ul,
.related-providers a,
.related-providers address,
.related-providers .content-container,
.related-providers .info-inner,
.related-providers li,
.related-providers .location-about,
.related-providers .location-contact,
.related-providers .location-overview,
.related-providers .medical-group,
.related-providers p,
.related-providers .parking-info,
.related-providers .provider-details,
.related-providers .related-providers-info,
.related-providers .related-provider-inner,
.related-providers .related-specialties-info,
.related-providers .schedule-title,
.related-providers ul {
    font-size: 1.25rem !important;
  }
  .location-overview .location-details,
.location-overview .provider-details,
.location-result .location-details,
.location-result .provider-details,
.location-profile-detail .location-details,
.location-profile-detail .provider-details,
.pls-profile-section .location-details,
.pls-profile-section .provider-details,
.parking .location-details,
.parking .provider-details,
.provider-profile-detail .location-details,
.provider-profile-detail .provider-details,
.provider-result .location-details,
.provider-result .provider-details,
.related-specialties .location-details,
.related-specialties .provider-details,
.related-providers .location-details,
.related-providers .provider-details {
    width: 800px !important;
  }
  a,
blockquote,
table,
pre {
    page-break-inside: avoid;
  }
  h1,
h2,
h3,
h4,
h5,
h6,
img {
    page-break-after: avoid;
    page-break-inside: avoid;
  }
  ul,
ol,
dl {
    page-break-before: avoid;
  }
  @page {
    overflow: visible;
    size: 330mm 427mm;
  }
}
.sc-edit-mode .modal {
  display: block;
  opacity: 1;
  position: relative;
} /* Atoms (basic HTML elements e.g. inputs, labels, buttons) */
.alert {
  border: 1px solid transparent;
  border-radius: 4px;
  color: #000;
  margin-bottom: 20px;
  padding: 15px;
}
.alert .alert-link {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
}

.alert-success {
  background-color: #dff0d8;
}

.alert-info {
  background-color: #d9edf7;
}

.alert-warning {
  background-color: #f2dede;
}

.alert-danger {
  background-color: #fcf8e3;
}

.background-image-container {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.background-image {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.badge {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  background-color: #eee;
  border-radius: 9px;
  color: #000;
  margin-bottom: 1.875rem;
  padding: 1px 9px 2px;
  white-space: nowrap;
}
.badge:hover {
  color: #000;
  cursor: pointer;
  text-decoration: none;
}

.badge-danger {
  background-color: #fcf8e3;
}
.badge-danger:hover {
  background-color: #dbd6bc;
}

.badge-warning {
  background-color: #f2dede;
}
.badge-warning:hover {
  background-color: #cfaaaa;
}

.badge-success {
  background-color: #dff0d8;
}
.badge-success:hover {
  background-color: #afc9a5;
}

.badge-info {
  background-color: #d9edf7;
}
.badge-info:hover {
  background-color: #b5d0dd;
}

button,
.btn {
  cursor: pointer;
}

.btn-link {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  color: #00759a;
  margin-bottom: 1rem;
  text-decoration: none;
  cursor: pointer;
}
.btn-link:hover, .btn-link:focus {
  text-decoration: underline;
}

.btn-link-inline {
  color: #00759a;
  text-decoration: none;
  cursor: pointer;
}
.btn-link-inline:hover, .btn-link-inline:focus {
  text-decoration: underline;
}

.btn {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  background-image: none;
  border: 1px solid transparent;
  display: inline-block;
  margin-bottom: 0;
  outline-style: none;
  padding: 1.125rem 1.375rem;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition-duration: 0.15s;
  transition-property: background-color, color, border-color;
  transition-timing-function: ease-in-out;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}
.btn:hover {
  text-decoration: none;
}
.btn:active {
  background-image: none;
  outline: 0;
}
.btn:focus {
  outline: #00b0e6 solid 2px;
}
.using-mouse .btn:focus {
  outline: none;
}

/* primary
---------------------------- */
.btn-primary {
  background-color: #fff;
  border-color: #fff;
  color: #000;
  margin-bottom: 1.5rem;
  min-width: 190px;
}
.btn-primary[disabled] {
  color: #bfbfbf;
  cursor: default;
}
.btn-primary.submitting {
  display: flex;
  height: 60px;
  justify-content: center;
}
.btn-primary.submitting:after {
  border: 4px solid #bfbfbf;
  border-bottom: 4px solid transparent;
  border-left: 4px solid transparent;
  content: " ";
  display: block;
  pointer-events: none;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  animation: spin 1.4s infinite linear;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.btn-primary.submitting span {
  display: none;
}
@media (min-width: 768px) {
  .btn-primary:not([disabled]):focus {
    background-color: #000;
    border-color: #000;
    color: #fff;
  }
  .btn-primary:not([disabled]):hover {
    background-color: #000;
    border-color: #000;
    color: #fff;
  }
  .btn-primary:not([disabled]):active {
    background-color: #000;
    border-color: #000;
    color: #fff;
  }
}
@media print and (min-width: 6px) {
  .btn-primary:not([disabled]):focus {
    background-color: #000;
    border-color: #000;
    color: #fff;
  }
  .btn-primary:not([disabled]):hover {
    background-color: #000;
    border-color: #000;
    color: #fff;
  }
  .btn-primary:not([disabled]):active {
    background-color: #000;
    border-color: #000;
    color: #fff;
  }
}

/* secondary
---------------------------- */
.btn-secondary {
  background-color: #bfbfbf;
  border-color: #bfbfbf;
  color: #000;
  margin-bottom: 1.5rem;
  min-width: 190px;
}
.btn-secondary:focus {
  background-color: #000;
  border-color: #000;
  color: #fff;
}
.btn-secondary:hover {
  background-color: #000;
  border-color: #000;
  color: #fff;
}
.btn-secondary:active {
  background-color: #000;
  border-color: #000;
  color: #fff;
}

/* success
---------------------------- */
.btn-success {
  background-color: #dff0d8;
  border-color: #eee;
  color: #000;
}
.btn-success:focus {
  background-color: #afc9a5;
}
.btn-success:hover {
  background-color: #afc9a5;
}
.btn-success:active {
  background-color: #afc9a5;
}

/* info
---------------------------- */
.btn-info {
  background-color: #d9edf7;
  border-color: #eee;
  color: #000;
}
.btn-info:focus {
  background-color: #b5d0dd;
}
.btn-info:hover {
  background-color: #b5d0dd;
}
.btn-info:active {
  background-color: #b5d0dd;
}

/* danger
---------------------------- */
.btn-danger {
  background-color: #fcf8e3;
  border-color: #eee;
  color: #000;
}
.btn-danger:focus {
  background-color: #dbd6bc;
}
.btn-danger:hover {
  background-color: #dbd6bc;
}
.btn-danger:active {
  background-color: #dbd6bc;
}

/* warning
---------------------------- */
.btn-warning {
  background-color: #f2dede;
  border-color: #eee;
  color: #000;
}
.btn-warning:focus {
  background-color: #cfaaaa;
}
.btn-warning:hover {
  background-color: #cfaaaa;
}
.btn-warning:active {
  background-color: #cfaaaa;
}

.btn-no-background {
  font-size: 2.5rem;
  line-height: 1;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  background-color: transparent;
  border-color: transparent;
  color: #fff;
}
.btn-no-background:focus {
  background-color: #00759a;
}
.btn-no-background:hover {
  background-color: #00759a;
}
.btn-no-background:active {
  background-color: #00759a;
}

/* btn-group
---------------------------- */
.btn-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}
.btn-group .btn {
  width: 100%;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .btn-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .btn-group .btn {
    min-width: 200px;
    width: auto;
  }
}
@media print and (min-width: 6px) {
  .btn-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .btn-group .btn {
    min-width: 200px;
    width: auto;
  }
}
@media (min-width: 1280px) {
  .btn-group {
    justify-content: flex-start;
  }
}
@media print and (min-width: 7px) {
  .btn-group {
    justify-content: flex-start;
  }
}

/* button with outline
---------------------------- */
.btn-outline {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  background: #fff;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  text-align: center;
  text-decoration: none;
  display: block;
  padding: 10px 15px;
  white-space: nowrap;
}
@media (min-width: 768px) {
  .btn-outline:not([disabled]):focus, .btn-outline:not([disabled]):hover, .btn-outline:not([disabled]):active {
    background-color: #363534;
    border-color: #363534;
    color: #fff;
    text-decoration: none;
  }
}
@media print and (min-width: 6px) {
  .btn-outline:not([disabled]):focus, .btn-outline:not([disabled]):hover, .btn-outline:not([disabled]):active {
    background-color: #363534;
    border-color: #363534;
    color: #fff;
    text-decoration: none;
  }
}

.card {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  align-items: center;
}
.card .card-header,
.card .card-body,
.card .card-footer {
  padding: 1rem;
}
.card .no-results-message .card-link {
  color: #00759a;
}

.checkbox-wrapper {
  align-items: flex-start;
  display: flex;
  min-height: 1.5rem;
  justify-items: flex-start;
  position: relative;
  margin-bottom: 0.75rem;
}
.checkbox-wrapper input[type=checkbox] {
  height: 1.5rem;
  margin-top: 2px;
  opacity: 0;
  width: 1.5rem;
}
.checkbox-wrapper input[type=checkbox]:checked + label:after {
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  content: "";
  display: block;
  height: 0.9rem;
  left: 0;
  margin: 0.25rem 0.5rem;
  position: absolute;
  top: 0;
  transform: rotate(45deg) translate(-1px, -1px);
  width: 0.45rem;
  border-bottom-width: 1px;
  border-right-width: 1px;
}
.checkbox-wrapper input[type=checkbox]:not([disabled]):hover {
  cursor: pointer;
}
.checkbox-wrapper input[type=checkbox]:not([disabled]):hover + label:before {
  border-color: #bfbfbf;
}
.checkbox-wrapper input[type=checkbox]:not([disabled]):focus + label:before {
  border-color: #bfbfbf;
  border-width: 2px;
}
.checkbox-wrapper input[type=checkbox]:not([disabled]) + label:hover {
  cursor: pointer;
}
.checkbox-wrapper input[type=checkbox]:not([disabled]) + label:hover:before {
  border-color: #bfbfbf;
}
.checkbox-wrapper input[type=checkbox] + label:before {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 2px;
  content: "";
  display: block;
  height: 1.5rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 1.5rem;
}
.checkbox-wrapper.centered input[type=checkbox] {
  align-items: center;
}
.checkbox-wrapper.centered input[type=checkbox]:checked + label:after {
  top: 50%;
  margin-top: -8px;
}
.checkbox-wrapper.centered input[type=checkbox] + label:before {
  top: 50%;
  transform: translateY(-50%);
}
.checkbox-wrapper label {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  display: flex;
  justify-content: space-between;
  margin: 3px 0 0;
  padding: 0 0 0 3px;
  width: 100%;
}
.checkbox-wrapper label .label-display-name + .label-name {
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  color: #595756;
  text-align: right;
}
.checkbox-wrapper label .label-name {
  flex-grow: 1;
}
.checkbox-wrapper label .label-number {
  padding-left: 0.5rem;
}

.float div {
  height: 150px;
  margin: 5px;
  width: 50px;
}
.float .left {
  background: #d7d3c7;
  float: left;
}
.float .right {
  background: #00759a;
  float: right;
}
.float:after {
  content: "";
  clear: both;
  display: block;
}

.form.ready.invalid {
  border-color: #a71930;
}
.form.ready.invalid .form-control label,
.form.ready.invalid .form-control .error-message {
  display: flex;
}
.form.ready.invalid .form-control .error {
  display: block;
}
.form.ready.invalid input {
  padding-left: 0;
}
.form.ready.invalid input .modal {
  padding-left: 20px;
}
.form.ready button {
  color: inherit;
  cursor: pointer;
}
.form .form-intro {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
  display: block;
  margin-bottom: 1.25rem;
  text-align: center;
}
.form .form-control label,
.form .form-control .error-message,
.form .form-control .error {
  align-items: center;
  color: #a71930;
  display: none;
  justify-content: center;
}
.form .form-control label,
.form .form-control .error-message {
  font-size: 0.875rem;
  line-height: 1.4285714286;
}
.form .form-control label {
  margin-bottom: -18px;
}
.form .form-control .error-message,
.form .form-control .error {
  padding: 0.625rem 0;
}
.form .input-field {
  justify-content: center;
  position: relative;
}
.form .input-field input {
  position: relative;
}
.form .input-field .error {
  display: none;
  height: 100%;
  position: absolute;
  padding-right: 0.5rem;
  right: 0;
  top: 10px;
}
.form .input-field .error .icon-error {
  height: 20px;
  position: relative;
  width: 20px;
  z-index: 2;
}
.form button {
  color: #bfbfbf;
  cursor: default;
}
.form input:focus ~ .floating-label,
.form input:not(:focus):valid ~ .floating-label {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #707070;
  left: 1rem;
  opacity: 1;
  position: absolute;
  pointer-events: none;
  top: 4px;
}
@media (min-width: 768px) {
  .form .form-intro {
    text-align: left;
  }
  .form .form-control {
    max-width: 265px;
  }
  .form .form-control label,
.form .form-control .error-message,
.form .form-control .error {
    justify-content: flex-start;
  }
  .form .form-control input {
    padding-left: 1rem;
    text-align: left;
  }
  .form .form-control button {
    padding-left: 1rem;
    text-align: left;
    width: 100%;
  }
  .form .input-button-combo {
    display: flex;
  }
  .form .input-button-combo .input-field {
    flex-grow: 1;
  }
}
@media print and (min-width: 6px) {
  .form .form-intro {
    text-align: left;
  }
  .form .form-control {
    max-width: 265px;
  }
  .form .form-control label,
.form .form-control .error-message,
.form .form-control .error {
    justify-content: flex-start;
  }
  .form .form-control input {
    padding-left: 1rem;
    text-align: left;
  }
  .form .form-control button {
    padding-left: 1rem;
    text-align: left;
    width: 100%;
  }
  .form .input-button-combo {
    display: flex;
  }
  .form .input-button-combo .input-field {
    flex-grow: 1;
  }
}

.icon {
  fill: none;
  height: 1rem;
  stroke: #000;
  stroke-linecap: round;
  stroke-width: 2px;
  width: 19px;
}
.icon use path {
  all: inherit;
}
.icon.icon-calendar, .icon.icon-call, .icon.icon-caution, .icon.icon-danger, .icon.icon-denied, .icon.icon-error, .icon.icon-eye, .icon.icon-help, .icon.icon-info-alert, .icon.icon-more-time, .icon.icon-filter, .icon.icon-grid-view, .icon.icon-list-view, .icon.icon-map-marker, .icon.icon-menu-open, .icon.icon-phs-logo, .icon.icon-phs-icon, .icon.icon-quote, .icon.icon-share-this, .icon.icon-sort, .icon.icon-star, .icon.icon-success, .icon.icon-universal-no, .icon.icon-doctor-typeahead, .icon.icon-hospital, .icon.icon-question {
  fill: #000;
  stroke-width: 0;
}
.icon.icon-arrow-down, .icon.icon-arrow-left-long, .icon.icon-arrow-right, .icon.icon-arrow-right-long, .icon.icon-arrow-up, .icon.icon-chat, .icon.icon-download, .icon.icon-modal-close, .icon.icon-print, .icon.icon-share, .icon.icon-mail {
  stroke: #000;
}
.icon.icon-arrow-left-long, .icon.icon-arrow-right-long {
  stroke-width: 1px;
  height: 0.75rem;
  width: 1.25rem;
}
.icon.icon-arrow-left-long {
  margin-right: 0.3125rem;
}
.icon.icon-arrow-right-long {
  margin-left: 0.3125rem;
}
.icon.icon-arrow-left-thin {
  stroke: #000;
  fill: #eee;
  width: 2rem;
  height: 2rem;
}
.icon.icon-check {
  stroke: #74c15f;
}
.icon.icon-download-sm {
  stroke-width: 1px;
}
.icon.icon-external {
  stroke: #000;
  stroke-width: 1px;
}
.icon.icon-geolocate {
  stroke: #000;
  stroke-width: 1px;
}
.icon.icon-globe {
  stroke: #fff;
  stroke-width: 2px;
  width: 15px;
  height: 15px;
}
.icon.icon-heart {
  fill: none;
  stroke: #a71930;
  stroke-width: 1px;
}
.icon.icon-print {
  stroke-width: 1px;
}
.icon.icon-office-visit {
  fill: #1a1818;
  height: 29.02px;
  stroke-width: 0;
  width: 50.71px;
}
.icon.icon-phone-visit {
  fill: #1a1818;
  height: 44.51px;
  stroke-width: 0;
  width: 26.12px;
}
.icon.icon-phs-logo {
  height: 25.448125px;
  width: 220.625px;
}
.icon.icon-phs-icon {
  height: 43.19px;
  width: 38.33px;
}
.icon.icon-menu-open {
  height: 30px;
  width: 23px;
}
.icon.icon-menu-close {
  height: 19px;
  width: 19px;
}
.icon.icon-search {
  height: 20.707px;
  width: 20.708px;
  stroke-width: 1px;
}
.icon.icon-success {
  fill: #74c15f;
  stroke: #fff;
}
.icon.icon-video-visit {
  fill: #1a1818;
  height: 44.17px;
  stroke-width: 0;
  width: 49.75px;
}
.icon.icon-telehealth {
  fill: #222;
  height: 28.17px;
  stroke-width: 0;
  width: 16.75px;
}

.icon-phone-test,
.icon-phone-number,
.icon-phone-sms {
  height: 100px;
  width: 50px;
}

input[type=text],
input[type=password] {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  background: #bfbfbf;
  border: 0;
  border-bottom: 2px solid transparent;
  caret-color: #00b0e6;
  color: #000;
  display: flex;
  height: 60px;
  margin-bottom: 0.75rem;
  outline: 0;
  padding: 1.25rem;
  position: relative;
  text-align: center;
  width: 100%;
}
input[type=text]::placeholder,
input[type=password]::placeholder {
  color: #000;
}
input[type=text]:focus,
input[type=password]:focus {
  background-color: transparent;
  border-color: #bfbfbf;
}
input[type=text]:focus::placeholder,
input[type=password]:focus::placeholder {
  color: #bfbfbf;
}
.invalid input[type=text],
.invalid input[type=password] {
  background-color: transparent;
  border-color: #a71930;
  margin-bottom: 0;
}
@media (min-width: 1280px) {
  input[type=text],
input[type=password] {
    padding-left: 0;
    text-align: left;
  }
  input[type=text]::placeholder,
input[type=password]::placeholder {
    text-align: center;
  }
}
@media print and (min-width: 7px) {
  input[type=text],
input[type=password] {
    padding-left: 0;
    text-align: left;
  }
  input[type=text]::placeholder,
input[type=password]::placeholder {
    text-align: center;
  }
}

.text-content ul {
  list-style: none;
  margin-bottom: 2rem;
  padding-left: 1.5rem;
}
.text-content ul > li {
  font-size: 1.625rem;
  line-height: 1.2307692308;
  margin-left: 1rem;
}
.text-content ul > li:before {
  color: #00759a;
  content: "•";
  display: inline-block;
  margin-left: -1rem;
  width: 1rem;
}
.text-content ul > li:not(:last-of-type) {
  margin-bottom: 0.25rem;
}
.text-content ul ol,
.text-content ul ul {
  margin-top: 0.25rem;
}
@media (min-width: 768px) {
  .text-content ul {
    margin-bottom: 3rem;
  }
}
@media print and (min-width: 6px) {
  .text-content ul {
    margin-bottom: 3rem;
  }
}
.text-content ol {
  margin-bottom: 2rem;
  padding-left: 3rem;
}
.text-content ol > li {
  font-size: 1.625rem;
  line-height: 1.2307692308;
}
.text-content ol > li:before {
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
}
.text-content ol > li:not(:last-of-type) {
  margin-bottom: 0.25rem;
}
.text-content ol ol,
.text-content ol ul {
  margin-top: 0.25rem;
}
@media (min-width: 768px) {
  .text-content ol {
    margin-bottom: 3rem;
  }
}
@media print and (min-width: 6px) {
  .text-content ol {
    margin-bottom: 3rem;
  }
}

.loading-overlay {
  align-items: flex-start;
  background-color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  padding-top: 150px;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 0.4s ease-in-out;
  width: 100%;
  z-index: 2;
}
.loading-overlay:after {
  border: 5px solid #bfbfbf;
  border-bottom: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  display: block;
  pointer-events: none;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  animation: spin 1.4s infinite linear;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-overlay-container {
  position: relative;
  height: 100%;
  width: 100%;
}
.loading-overlay-container .loading-overlay-section {
  align-items: flex-start;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 0.4s ease-in-out;
  width: 100%;
  z-index: 2;
}
.loading-overlay-container .loading-overlay-section:after {
  border: 5px solid #bfbfbf;
  border-bottom: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  display: block;
  pointer-events: none;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  animation: spin 1.4s infinite linear;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.radio-wrapper {
  align-items: flex-start;
  display: flex;
  justify-items: flex-start;
  margin-bottom: 0.75rem;
  position: relative;
}
.radio-wrapper.disabled {
  pointer-events: none;
}
.radio-wrapper.disabled input[type=radio]:checked + label:after {
  background-color: #eee;
}
.radio-wrapper.disabled input[type=radio] + label {
  color: #bfbfbf;
}
.radio-wrapper input[type=radio] {
  margin-top: 2px;
  opacity: 0;
}
.radio-wrapper input[type=radio]:checked + label:after {
  background-color: #000;
  border-radius: 50%;
  content: "";
  display: block;
  height: 0.5rem;
  left: 0;
  margin: 0.375rem;
  position: absolute;
  width: 0.5rem;
}
.radio-wrapper input[type=radio]:hover {
  cursor: pointer;
}
.radio-wrapper input[type=radio]:hover + label:before {
  border: 1px solid #bfbfbf;
}
.radio-wrapper input[type=radio]:focus + label:before {
  border: 1px solid #bfbfbf;
}
.radio-wrapper input[type=radio] + label:before {
  background-color: #fff;
  border-radius: 50%;
  content: "";
  display: block;
  height: 1.25rem;
  left: 0;
  position: absolute;
  width: 1.25rem;
}
.radio-wrapper input[type=radio] + label:hover {
  cursor: pointer;
}
.radio-wrapper input[type=radio] + label:hover:before {
  border: 1px solid #bfbfbf;
}
.radio-wrapper label {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0 0 0 0.5rem;
  width: 100%;
}
.radio-wrapper label .label-display-name + .label-name {
  text-align: right;
}
.radio-wrapper label .label-name {
  flex-grow: 1;
}
.radio-wrapper label .label-number {
  padding-left: 1rem;
}

.radio-btns {
  display: flex;
}
.radio-btns .radio-wrapper {
  flex: 1;
  margin-right: 0.5rem;
  margin-bottom: 0;
}
.radio-btns .radio-wrapper:last-child {
  margin-right: 0;
}
.radio-btns input[type=radio] {
  height: 0;
  width: 0;
}
.radio-btns input[type=radio]:checked + label {
  background-color: #595756;
  border-color: #df7a00;
}
.radio-btns input[type=radio]:checked + label:before, .radio-btns input[type=radio]:checked + label:after {
  display: none;
}
.radio-btns input[type=radio]:focus + label {
  background-color: #df7a00;
  border-color: #df7a00;
  box-shadow: 0 0 0 0.2rem rgba(223, 122, 0, 0.35);
}
.radio-btns input[type=radio] + label {
  align-items: center;
  background-color: #595756;
  border: 1px solid #595756;
  border-radius: 0.625rem;
  color: #fff;
  display: flex;
  font-size: 1rem;
  height: 100%;
  justify-content: center;
  line-height: 1.375;
  padding: 0.75rem 0.25rem;
  width: 100%;
}
.radio-btns input[type=radio] + label:before, .radio-btns input[type=radio] + label:after {
  display: none;
}
.radio-btns input[type=radio] + label:hover {
  background-color: #df7a00;
  border-color: #df7a00;
}
.radio-btns input[type=radio] + label span {
  text-align: center;
}
@media (min-width: 1440px) {
  .radio-btns .radio-wrapper {
    margin-right: 1rem;
  }
}
@media print and (min-width: 1440px) {
  .radio-btns .radio-wrapper {
    margin-right: 1rem;
  }
}

.tooltip {
  position: relative;
  /* FLOW: UP */
  /* FLOW: DOWN */
  /* FLOW: LEFT */
  /* FLOW: RIGHT */
  /* KEYFRAMES */
  /* FX All The Things */
}
.tooltip:focus:before, .tooltip:focus:after, .tooltip:hover:before, .tooltip:hover:after {
  display: block;
  /* Older Edge/IE will only set value none to pseudo-element attributes if it was declared previously */
  text-decoration: underline;
}
.tooltip:focus:before, .tooltip:focus:after, .tooltip:hover:before, .tooltip:hover:after {
  text-decoration: none;
}
.tooltip:before, .tooltip:after {
  display: none;
  line-height: 1;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  text-transform: none;
  user-select: none;
}
.tooltip:before {
  border: 5px solid transparent;
  content: "";
  z-index: 99;
}
.tooltip:after {
  font-size: 0.75rem;
  line-height: 1.5;
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  background: #363534;
  border-radius: 0.3ch;
  box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.35);
  color: #fff;
  content: attr(data-tooltip);
  /*
  	Let the content set the size of the tooltips
  	but this will also keep them from being obnoxious
  	*/
  max-width: 21em;
  min-width: 16em;
  overflow: hidden;
  padding: 1ch 1.5ch;
  text-align: center;
  white-space: normal;
  z-index: 100;
}
.tooltip [tooltip=""]:before,
.tooltip [tooltip=""]:after {
  display: none !important;
}
.tooltip:not([flow]):before, .tooltip[flow^=up]:before {
  border-bottom-width: 0;
  border-top-color: #363534;
  bottom: 100%;
}
.tooltip:not([flow]):after, .tooltip[flow^=up]:after {
  bottom: calc(100% + 5px);
}
.tooltip:not([flow]):before, .tooltip:not([flow]):after, .tooltip[flow^=up]:before, .tooltip[flow^=up]:after {
  left: 50%;
  transform: translate(-50%, -0.5em);
}
.tooltip[flow^=down]:before {
  border-bottom-color: #363534;
  border-top-width: 0;
  top: 100%;
}
.tooltip[flow^=down]:after {
  top: calc(100% + 5px);
}
.tooltip[flow^=down]:before, .tooltip[flow^=down]:after {
  left: 50%;
  transform: translate(-50%, 0.5em);
}
.tooltip[flow^=left]:before {
  border-left-color: #363534;
  border-right-width: 0;
  left: calc(0em - 5px);
  top: 50%;
  transform: translate(-0.5em, -50%);
}
.tooltip[flow^=left]:after {
  right: calc(100% + 5px);
  top: 50%;
  transform: translate(-0.5em, -50%);
}
.tooltip[flow^=right]:before {
  border-left-width: 0;
  border-right-color: #363534;
  right: calc(0em - 5px);
  top: 50%;
  transform: translate(0.5em, -50%);
}
.tooltip[flow^=right]:after {
  left: calc(100% + 5px);
  top: 50%;
  transform: translate(0.5em, -50%);
}
@keyframes tooltips-vert {
  to {
    opacity: 0.95;
    transform: translate(-50%, 0);
  }
}
@keyframes tooltips-horz {
  to {
    opacity: 0.95;
    transform: translate(0, -50%);
  }
}
.tooltip:not([flow]):focus:before, .tooltip:not([flow]):focus:after, .tooltip[flow^=up]:focus:before, .tooltip[flow^=up]:focus:after, .tooltip[flow^=down]:focus:before, .tooltip[flow^=down]:focus:after, .tooltip:not([flow]):hover:before, .tooltip:not([flow]):hover:after, .tooltip[flow^=up]:hover:before, .tooltip[flow^=up]:hover:after, .tooltip[flow^=down]:hover:before, .tooltip[flow^=down]:hover:after {
  animation: tooltips-vert 300ms ease-out forwards;
}
.tooltip[flow^=left]:focus:before, .tooltip[flow^=left]:focus:after, .tooltip[flow^=right]:focus:before, .tooltip[flow^=right]:focus:after, .tooltip[flow^=left]:hover:before, .tooltip[flow^=left]:hover:after, .tooltip[flow^=right]:hover:before, .tooltip[flow^=right]:hover:after {
  animation: tooltips-horz 300ms ease-out forwards;
}

.rating-icon-info-alert.tooltip:after {
  max-width: 34em;
  min-width: 12em;
  text-align: left;
}

@media (min-width: 768px) {
  .rating-icon-info-alert.tooltip:after {
    max-width: 34em;
    min-width: 33em;
    text-align: left;
  }
}
@media print and (min-width: 6px) {
  .rating-icon-info-alert.tooltip:after {
    max-width: 34em;
    min-width: 33em;
    text-align: left;
  }
}
.slider {
  display: inline-block;
  height: 24px;
  margin-right: 0.75rem;
  position: relative;
  width: 42px;
  transition: 0.4s;
}
.slider .switch {
  background-color: #bfbfbf;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.slider .switch:before {
  background-color: #fff;
  bottom: 2px;
  content: "";
  height: 20px;
  left: 2px;
  position: absolute;
  transition: 0.4s;
  width: 20px;
}
.slider input:checked + .switch {
  background-color: #363534;
}
.slider input:checked + .switch:before {
  transform: translateX(18px);
}
.slider input:focus + .switch {
  outline: 2px solid #000;
}
.slider .switch.round {
  border-radius: 24px;
}
.slider .switch.round:before {
  border-radius: 50%;
}
.slider + .label-name {
  align-self: center;
  cursor: pointer;
}

html,
body {
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  color: #000;
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
  -webkit-font-smoothing: antialiased; /* Makes the font look lighter in weight, and makes edges appear less fuzzy. This makes text better match the XD design files. */
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

h1,
.heading-1 {
  font-size: 2.5rem;
  line-height: 1;
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 2.25rem;
}

h2,
.heading-2 {
  font-size: 2.5rem;
  line-height: 1;
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 2.25rem;
}

h3,
.heading-3 {
  font-size: 1.875rem;
  line-height: 1;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 2.25rem;
}

h4,
.heading-4 {
  font-size: 1.625rem;
  line-height: 1.2307692308;
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 1.875rem;
}

h5,
.heading-5 {
  font-size: 1.5rem;
  line-height: 1.3333333333;
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 1.875rem;
}

h6,
.heading-6 {
  font-size: 1.125rem;
  line-height: 1.2777777778;
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 0.875rem;
}

p,
.body-copy {
  font-size: 1.375rem;
  line-height: 1.4545454545;
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 1.5rem;
}

a {
  color: #000;
  text-decoration: underline;
}
a:hover, a:focus {
  text-decoration: none;
}

address {
  font-size: 1.25rem;
  line-height: 1.5;
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
}

time {
  font-size: 1.25rem;
  line-height: 1.5;
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
}

strong {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
}

@media (min-width: 768px) {
  h1,
.heading-1 {
    font-size: 3.75rem;
    line-height: 1;
    margin-bottom: 2.75rem;
  }
  h2,
.heading-2 {
    font-size: 2.5rem;
    line-height: 1;
    margin-bottom: 2.25rem;
  }
  h3,
.heading-3 {
    font-size: 1.875rem;
    line-height: 1.3333333333;
    margin-bottom: 2.25rem;
  }
  h4,
.heading-4 {
    font-size: 1.625rem;
    line-height: 1.2307692308;
    margin-bottom: 1.875rem;
  }
  h5,
.heading-5 {
    font-size: 1.5rem;
    line-height: 1.3333333333;
    margin-bottom: 1.875rem;
  }
  h6,
.heading-6 {
    font-size: 1.375rem;
    line-height: 1.4545454545;
    margin-bottom: 0.875rem;
  }
}
@media print and (min-width: 6px) {
  h1,
.heading-1 {
    font-size: 3.75rem;
    line-height: 1;
    margin-bottom: 2.75rem;
  }
  h2,
.heading-2 {
    font-size: 2.5rem;
    line-height: 1;
    margin-bottom: 2.25rem;
  }
  h3,
.heading-3 {
    font-size: 1.875rem;
    line-height: 1.3333333333;
    margin-bottom: 2.25rem;
  }
  h4,
.heading-4 {
    font-size: 1.625rem;
    line-height: 1.2307692308;
    margin-bottom: 1.875rem;
  }
  h5,
.heading-5 {
    font-size: 1.5rem;
    line-height: 1.3333333333;
    margin-bottom: 1.875rem;
  }
  h6,
.heading-6 {
    font-size: 1.375rem;
    line-height: 1.4545454545;
    margin-bottom: 0.875rem;
  }
}
@media (min-width: 1680px) {
  h1,
.heading-1 {
    font-size: 3.75rem;
    line-height: 1;
    margin-bottom: 3rem;
  }
  h2,
.heading-2 {
    font-size: 3rem;
    line-height: 1;
    margin-bottom: 2.75rem;
  }
  h3,
.heading-3 {
    font-size: 2.5rem;
    line-height: 1;
    margin-bottom: 2.25rem;
  }
  h4,
.heading-4 {
    font-size: 1.875rem;
    line-height: 1;
    margin-bottom: 1.875rem;
  }
  h5,
.heading-5 {
    font-size: 1.625rem;
    line-height: 1.2307692308;
    margin-bottom: 1.875rem;
  }
  h6,
.heading-6 {
    font-size: 1.5rem;
    line-height: 1.3333333333;
    margin-bottom: 0.875rem;
  }
  p,
.body-copy {
    font-size: 1.625rem;
    line-height: 1.2307692308;
    font-family: AvenirBook, Helvetica, sans-serif;
    font-weight: normal;
    margin-bottom: 1.5rem;
  }
}
@media print and (min-width: 1680px) {
  h1,
.heading-1 {
    font-size: 3.75rem;
    line-height: 1;
    margin-bottom: 3rem;
  }
  h2,
.heading-2 {
    font-size: 3rem;
    line-height: 1;
    margin-bottom: 2.75rem;
  }
  h3,
.heading-3 {
    font-size: 2.5rem;
    line-height: 1;
    margin-bottom: 2.25rem;
  }
  h4,
.heading-4 {
    font-size: 1.875rem;
    line-height: 1;
    margin-bottom: 1.875rem;
  }
  h5,
.heading-5 {
    font-size: 1.625rem;
    line-height: 1.2307692308;
    margin-bottom: 1.875rem;
  }
  h6,
.heading-6 {
    font-size: 1.5rem;
    line-height: 1.3333333333;
    margin-bottom: 0.875rem;
  }
  p,
.body-copy {
    font-size: 1.625rem;
    line-height: 1.2307692308;
    font-family: AvenirBook, Helvetica, sans-serif;
    font-weight: normal;
    margin-bottom: 1.5rem;
  }
}
/* Directory */
@media (min-width: 768px) {
  .pls a {
    text-decoration: none;
  }
  .pls a:hover, .pls a:focus {
    text-decoration: underline;
  }
}
@media print and (min-width: 6px) {
  .pls a {
    text-decoration: none;
  }
  .pls a:hover, .pls a:focus {
    text-decoration: underline;
  }
}

/* Technology Test */
.t3 .cta-text-list li {
  padding-bottom: 0.25rem;
  text-align: center;
}
.t3 .cta-text-list .cta-link {
  display: inline;
}
@media (min-width: 768px) {
  .t3 .cta-text-list li {
    text-align: left;
  }
}
@media print and (min-width: 6px) {
  .t3 .cta-text-list li {
    text-align: left;
  }
}
.t3 .instruction-list {
  margin-bottom: 1.5rem;
  text-align: center;
}
.t3 .instruction-list li {
  font-size: 1rem;
  line-height: 1.125;
  color: #363534;
  counter-increment: step-counter;
  margin-bottom: 1rem;
}
.t3 .instruction-list li:before {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  background-color: lightgray;
  border-radius: 50%;
  color: #4b4948;
  content: counter(step-counter);
  display: block;
  height: 24px;
  margin: auto;
  margin-bottom: 1rem;
  padding-left: 1px;
  padding-top: 3px;
  text-align: center;
  width: 24px;
}
@media (min-width: 768px) {
  .t3 .instruction-list {
    padding-top: 0.25rem;
    text-align: left;
  }
  .t3 .instruction-list li {
    padding-left: 34px;
  }
  .t3 .instruction-list li:before {
    display: inline-block;
    margin-bottom: 0;
    margin-left: -34px;
    margin-right: 0.625rem;
    margin-top: -4px;
    padding-left: 0;
    position: absolute;
  }
}
@media print and (min-width: 6px) {
  .t3 .instruction-list {
    padding-top: 0.25rem;
    text-align: left;
  }
  .t3 .instruction-list li {
    padding-left: 34px;
  }
  .t3 .instruction-list li:before {
    display: inline-block;
    margin-bottom: 0;
    margin-left: -34px;
    margin-right: 0.625rem;
    margin-top: -4px;
    padding-left: 0;
    position: absolute;
  }
}
@media (min-width: 1280px) {
  .t3 .instruction-list li {
    padding-left: 0;
  }
}
@media print and (min-width: 7px) {
  .t3 .instruction-list li {
    padding-left: 0;
  }
}
.t3 .requirements-list {
  margin-bottom: 1.5rem;
}
.t3 .requirements-list dt {
  font-size: 1rem;
  line-height: 1.125;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  color: #000;
  margin-top: 1.5rem;
  text-align: center;
}
.t3 .requirements-list dd {
  font-size: 1rem;
  line-height: 1.375;
  color: #363534;
  text-align: center;
}
@media (min-width: 768px) {
  .t3 .requirements-list dt {
    text-align: left;
  }
  .t3 .requirements-list dd {
    text-align: left;
  }
}
@media print and (min-width: 6px) {
  .t3 .requirements-list dt {
    text-align: left;
  }
  .t3 .requirements-list dd {
    text-align: left;
  }
}
.t3 .list-separated li {
  text-align: center;
  position: relative;
}
.t3 .list-separated li:after {
  background-color: #a71930;
  bottom: 0;
  content: " ";
  display: block;
  height: 1px;
  opacity: 1;
  position: relative;
  left: 0;
  margin: 0.75rem auto;
  width: 10px;
}
.t3 .list-separated li:last-child {
  margin-bottom: 1rem;
}
.t3 .list-separated li:last-child:after {
  display: none;
}
@media (min-width: 768px) {
  .t3 .list-separated li {
    text-align: left;
  }
  .t3 .list-separated li:after {
    margin: 0.75rem 0;
  }
}
@media print and (min-width: 6px) {
  .t3 .list-separated li {
    text-align: left;
  }
  .t3 .list-separated li:after {
    margin: 0.75rem 0;
  }
}

.t3 .title-primary {
  font-size: 1.625rem;
  line-height: 1;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  color: #a71930;
  line-height: 1;
  margin-bottom: 1.25rem;
  text-align: center;
}
.t3 .title-secondary {
  font-size: 1.5rem;
  line-height: 1.0833333333;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  color: #000;
  margin-bottom: 1rem;
  text-align: center;
}
.t3 .section-title {
  font-size: 1rem;
  line-height: 1.125;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  color: #000;
  margin-bottom: 1rem;
  text-align: center;
}
.t3 .subtitle {
  font-size: 1rem;
  line-height: 1.125;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: #000;
  margin-bottom: 1rem;
  text-align: center;
}
.t3 .font-bold {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
}
.t3 p {
  font-size: 1rem;
  line-height: 1.125;
  color: #363534;
  text-align: center;
}
.t3 .description {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  color: #363534;
  text-align: center;
}
.t3 .cta-link {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  color: #000;
  display: block;
  margin: 0 auto 1rem;
  text-align: center;
  text-decoration: underline;
}
.t3 .cta-link:focus, .t3 .cta-link:hover {
  cursor: pointer;
  text-decoration: none;
}
.t3 .cta-link.center {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.t3 .cta-box {
  background-color: #fff;
  display: block;
  height: 0;
  padding: 100% 0 0;
  position: relative;
  text-align: left;
  transition: background-color 0.15s ease-in-out;
  width: 100%;
}
.t3 .cta-box:focus, .t3 .cta-box:hover {
  background-color: #363534;
  border-color: #363534;
  color: #fff;
}
.t3 .cta-box:focus > span, .t3 .cta-box:hover > span {
  color: #fff;
}
.t3 .cta-box:focus svg, .t3 .cta-box:hover svg {
  stroke: #fff;
}
.t3 .cta-box > span {
  font-size: 1rem;
  line-height: 1;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  color: #000;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  left: 0;
  padding: 1.25rem;
  position: absolute;
  top: 0;
  transition: color 0.15s ease-in-out;
  width: 100%;
}
.t3 .cta-box .cta-description {
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  display: none;
}
.t3 .cta-box .cta-title {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
}
.t3 .cta-box svg {
  bottom: 1.25rem;
  height: 14px;
  margin-bottom: 2px;
  position: absolute;
  right: 1.25rem;
  stroke: #000;
  transition: stroke 0.15s ease-in-out;
  width: 8px;
}
.t3 a[href^="tel:"] {
  white-space: nowrap;
}
.t3 .no-wrap {
  white-space: nowrap;
}
.t3 .dark-mode {
  background-color: #000;
  color: #eee;
  position: relative;
}
.t3 .dark-mode p {
  color: #eee;
}
@media (min-width: 768px) {
  .t3 .title-primary {
    font-size: 50px;
    text-align: left;
  }
  .t3 .title-secondary {
    text-align: left;
  }
  .t3 .section-title {
    text-align: left;
  }
  .t3 .subtitle {
    text-align: left;
  }
  .t3 p {
    text-align: left;
  }
  .t3 .description {
    text-align: left;
  }
  .t3 .cta-link {
    margin: 0 0 1rem;
    text-align: left;
  }
  .t3 .cta-box .cta-description {
    display: block;
  }
  .t3 .cta-box.description-cta > span {
    padding: 1.875rem;
  }
  .t3 .cta-box.description-cta svg {
    bottom: 1.875rem;
    right: 1.875rem;
  }
}
@media print and (min-width: 6px) {
  .t3 .title-primary {
    font-size: 50px;
    text-align: left;
  }
  .t3 .title-secondary {
    text-align: left;
  }
  .t3 .section-title {
    text-align: left;
  }
  .t3 .subtitle {
    text-align: left;
  }
  .t3 p {
    text-align: left;
  }
  .t3 .description {
    text-align: left;
  }
  .t3 .cta-link {
    margin: 0 0 1rem;
    text-align: left;
  }
  .t3 .cta-box .cta-description {
    display: block;
  }
  .t3 .cta-box.description-cta > span {
    padding: 1.875rem;
  }
  .t3 .cta-box.description-cta svg {
    bottom: 1.875rem;
    right: 1.875rem;
  }
}

/* Heart & Vascular */
.hv-title {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
}
@media (min-width: 768px) {
  .hv-title {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .hv-title {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .hv-title {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .hv-title {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .hv-title {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .hv-title {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.hv-title.has-background {
  background-color: #eee;
}

.heart-and-vascular .btn {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  background-color: #363534;
  border-color: #363534;
  border-radius: 2px;
  color: #fff;
  max-width: 100%;
  padding: 0.75rem 2.5rem;
  white-space: pre-wrap;
  text-decoration: none;
}
.heart-and-vascular .btn:hover, .heart-and-vascular .btn:focus {
  text-decoration: none;
}
.heart-and-vascular .btn-primary {
  background-color: #363534;
  border-color: #363534;
  color: #fff;
}
.heart-and-vascular .btn-secondary {
  background-color: #fff;
  border-color: #bfbfbf;
  color: #000;
}
.heart-and-vascular .btn-secondary:hover, .heart-and-vascular .btn-secondary:focus {
  background-color: #363534;
  border-color: #363534;
  color: #fff;
}
.heart-and-vascular .btn-sm {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  padding: 0.5rem 1.5rem;
}

.primary-description .lead {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirLight, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
}
.primary-description .lead a {
  color: #00759a;
  text-decoration: none;
}
.primary-description .lead a:hover, .primary-description .lead a.focus {
  text-decoration: underline;
}
@media (min-width: 1280px) {
  .primary-description .lead {
    font-size: 1.5rem;
    line-height: 1.3333333333;
    margin-bottom: 2.375rem;
  }
}
@media print and (min-width: 7px) {
  .primary-description .lead {
    font-size: 1.5rem;
    line-height: 1.3333333333;
    margin-bottom: 2.375rem;
  }
}

.heart-and-vascular h1,
.heart-and-vascular .heading-1,
.pls h1,
.pls .heading-1 {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: #a71930;
  margin-bottom: 1.25rem;
}
.heart-and-vascular h2,
.heart-and-vascular .heading-2,
.pls h2,
.pls .heading-2 {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  color: #000;
  margin-bottom: 0.75rem;
}
.heart-and-vascular h2 a,
.heart-and-vascular .heading-2 a,
.pls h2 a,
.pls .heading-2 a {
  color: #000;
}
.heart-and-vascular h3,
.heart-and-vascular .heading-3,
.pls h3,
.pls .heading-3 {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
  margin-bottom: 0.5rem;
}
.heart-and-vascular h4,
.heart-and-vascular .heading-4,
.pls h4,
.pls .heading-4 {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
  margin-bottom: 0.5rem;
}
.heart-and-vascular .hero-copy,
.pls .hero-copy {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirLight, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 0.75rem;
}
.heart-and-vascular p,
.heart-and-vascular .body-copy,
.pls p,
.pls .body-copy {
  font-size: 1rem;
  line-height: 1.5;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
  margin-bottom: 1.5rem;
}
.heart-and-vascular a:not[class],
.pls a:not[class] {
  color: #00759a;
  text-decoration: underline;
}
.heart-and-vascular a:not[class]:hover, .heart-and-vascular a:not[class]:focus,
.pls a:not[class]:hover,
.pls a:not[class]:focus {
  text-decoration: none;
}
@media (min-width: 768px) {
  .heart-and-vascular h1,
.heart-and-vascular .heading-1,
.pls h1,
.pls .heading-1 {
    font-size: 1.875rem;
    line-height: 1.2;
    font-family: AvenirLight, Helvetica, sans-serif;
    font-weight: normal;
  }
  .heart-and-vascular h2,
.heart-and-vascular .heading-2,
.pls h2,
.pls .heading-2 {
    font-size: 1.25rem;
    line-height: 1.2;
    font-family: AvenirMedium, Helvetica, sans-serif;
    font-weight: normal;
  }
  .heart-and-vascular .hero-copy,
.pls .hero-copy {
    font-size: 1.5rem;
    line-height: 1.3333333333;
  }
}
@media print and (min-width: 6px) {
  .heart-and-vascular h1,
.heart-and-vascular .heading-1,
.pls h1,
.pls .heading-1 {
    font-size: 1.875rem;
    line-height: 1.2;
    font-family: AvenirLight, Helvetica, sans-serif;
    font-weight: normal;
  }
  .heart-and-vascular h2,
.heart-and-vascular .heading-2,
.pls h2,
.pls .heading-2 {
    font-size: 1.25rem;
    line-height: 1.2;
    font-family: AvenirMedium, Helvetica, sans-serif;
    font-weight: normal;
  }
  .heart-and-vascular .hero-copy,
.pls .hero-copy {
    font-size: 1.5rem;
    line-height: 1.3333333333;
  }
}

/* NGDE Portal */
.portal select {
  appearance: none;
  background-color: white;
  padding: 0.8em 1em;
  border: none;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
}

.portal .heading-1 {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirLight, Helvetica, sans-serif;
  font-weight: normal;
  color: #a71930;
  margin-bottom: 1rem;
}
.portal .heading-2 {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
  margin-bottom: 1rem;
}
.portal .heading-3 {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
  margin-bottom: 1rem;
}
.portal .description {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
  margin-bottom: 2rem;
}
.portal .description:last-child {
  margin-bottom: 0;
}
.portal .description p {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
  margin-bottom: 1.25rem;
}
.portal .description .btn-link {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  display: inline;
}
.portal .overview-text {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirLight, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
  margin-bottom: 3rem;
}
.portal .overview-text a {
  cursor: pointer;
}
.portal .data-label {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
  margin-bottom: 1.25rem;
}
.portal .rich-text {
  margin-bottom: 3rem;
}
.portal .rich-text h1,
.portal .rich-text h2,
.portal .rich-text h3,
.portal .rich-text h4,
.portal .rich-text h5 {
  color: #363534;
  margin-bottom: 1rem;
}
.portal .rich-text h1,
.portal .rich-text h2 {
  font-size: 1.625rem;
  line-height: 1.2307692308;
  font-family: AvenirLight, Helvetica, sans-serif;
  font-weight: normal;
}
.portal .rich-text h3 {
  font-size: 1.375rem;
  line-height: 1.4545454545;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
}
.portal .rich-text h4 {
  font-size: 1.25rem;
  line-height: 1.5;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
}
.portal .rich-text h5 {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
}
.portal .rich-text p {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  color: #363534;
}
.portal .rich-text ul,
.portal .rich-text ol {
  margin-left: 1.5rem;
  margin-bottom: 1.5rem;
  padding-left: 3rem;
}
.portal .rich-text li {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  color: #363534;
  padding-bottom: 0.5rem;
}
.portal .rich-text .mobile-card-list {
  padding-left: 0;
  margin-left: 0;
}
.portal .rich-text .mobile-card-data-list {
  padding: 1rem;
  background: #eee;
}
.portal fieldset {
  border: 0 none;
  margin: 0;
  padding: 0;
}
.portal fieldset legend {
  padding: 0;
}
@media (min-width: 768px) {
  .portal .heading-1 {
    font-size: 1.875rem;
    line-height: 1;
  }
  .portal .overview-text {
    font-size: 1.5rem;
    line-height: 1.3333333333;
  }
}
@media print and (min-width: 6px) {
  .portal .heading-1 {
    font-size: 1.875rem;
    line-height: 1;
  }
  .portal .overview-text {
    font-size: 1.5rem;
    line-height: 1.3333333333;
  }
}

/* Molecules (e.g. Hero, Primary Navigation, Search) */
.action-block {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  align-items: center;
  background-color: #eee;
  color: #000;
  display: block;
  margin: 0.25rem 0;
  padding: 10px 20px;
  text-align: left;
  width: 100%;
}
.action-block .secondary-title {
  font-size: 0.75rem;
  line-height: 1.5;
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  align-items: center;
  color: #00759a;
  display: flex;
  pointer-events: none;
}
.action-block .secondary-title .icon {
  height: 0.75rem;
  margin-left: 0.5rem;
  stroke: #00759a;
  width: 0.75rem;
}
.action-block .secondary-title button {
  pointer-events: auto;
}
.action-block:hover .secondary-title, .action-block:focus .secondary-title {
  text-decoration: underline;
}
.action-block.transparent-bg {
  background-color: transparent;
}
.action-block.links-block a {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  color: #00759a;
  margin-bottom: 1rem;
}
.action-block.links-block a:hover, .action-block.links-block a:focus {
  color: #00759a;
  text-decoration: underline;
}
.action-block.links-block:hover .secondary-title, .action-block.links-block:focus .secondary-title {
  text-decoration: none;
}
@media (min-width: 768px) {
  .action-block {
    display: flex;
    justify-content: space-between;
  }
  .action-block.links-block a {
    margin-bottom: 0;
  }
}
@media print and (min-width: 6px) {
  .action-block {
    display: flex;
    justify-content: space-between;
  }
  .action-block.links-block a {
    margin-bottom: 0;
  }
}

.breadcrumb {
  padding: 0.75rem 0;
  margin-bottom: 0.5rem;
}
.breadcrumb .breadcrumb-link {
  font-size: 0.75rem;
  line-height: 1.5;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  align-items: center;
  color: #000;
  display: inline-flex;
  text-decoration: none;
}
.breadcrumb .breadcrumb-link:hover, .breadcrumb .breadcrumb-link:focus {
  text-decoration: underline;
}
.breadcrumb .breadcrumb-link .icon-arrow-right {
  height: 0.625rem;
  margin-right: 0.25rem;
  transform: rotate(180deg) translateY(1px);
  width: 0.625rem;
}
@media (min-width: 768px) {
  .breadcrumb {
    align-self: flex-end;
    padding: 0;
  }
  .breadcrumb .breadcrumb-link {
    font-size: 0.875rem;
    line-height: 1.4285714286;
    font-family: AvenirRoman, Helvetica, sans-serif;
    font-weight: normal;
  }
}
@media print and (min-width: 6px) {
  .breadcrumb {
    align-self: flex-end;
    padding: 0;
  }
  .breadcrumb .breadcrumb-link {
    font-size: 0.875rem;
    line-height: 1.4285714286;
    font-family: AvenirRoman, Helvetica, sans-serif;
    font-weight: normal;
  }
}

.two-column-content {
  margin-bottom: 1.5rem;
}
@media (min-width: 768px) {
  .two-column-content {
    columns: 2;
  }
}
@media print and (min-width: 6px) {
  .two-column-content {
    columns: 2;
  }
}

@media (min-width: 768px) {
  .three-column-content {
    columns: 2;
    margin-bottom: 1.5rem;
  }
}
@media print and (min-width: 6px) {
  .three-column-content {
    columns: 2;
    margin-bottom: 1.5rem;
  }
}
@media (min-width: 1280px) {
  .three-column-content {
    columns: 3;
  }
}
@media print and (min-width: 7px) {
  .three-column-content {
    columns: 3;
  }
}

@media (min-width: 768px) {
  .four-column-content {
    columns: 2;
    margin-bottom: 1.5rem;
  }
}
@media print and (min-width: 6px) {
  .four-column-content {
    columns: 2;
    margin-bottom: 1.5rem;
  }
}
@media (min-width: 1280px) {
  .four-column-content {
    columns: 3;
  }
}
@media print and (min-width: 7px) {
  .four-column-content {
    columns: 3;
  }
}
@media (min-width: 1440px) {
  .four-column-content {
    columns: 4;
  }
}
@media print and (min-width: 1440px) {
  .four-column-content {
    columns: 4;
  }
}

.last-updated {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  text-align: center;
  margin-bottom: 4rem;
  margin-top: 0.3rem;
}
@media (min-width: 768px) {
  .last-updated {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .last-updated {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .last-updated {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .last-updated {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .last-updated {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .last-updated {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.last-updated.has-search {
  display: none;
}
.search-loaded .last-updated.has-search {
  display: block;
  margin-top: 15px;
}
.detail-view-loaded .last-updated.has-search {
  margin-left: auto;
  margin-right: auto;
}
.detail-view-loaded .last-updated.has-search div {
  max-width: 100% !important;
}
.last-updated a {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: #00759a;
}
.last-updated > div {
  font-size: 0.875rem;
  line-height: 2.1428571429;
}
@media (min-width: 768px) {
  .last-updated {
    margin-bottom: 4.5rem;
  }
  .last-updated a {
    text-decoration: none;
  }
  .last-updated a:hover, .last-updated a:focus {
    text-decoration: underline;
  }
}
@media print and (min-width: 6px) {
  .last-updated {
    margin-bottom: 4.5rem;
  }
  .last-updated a {
    text-decoration: none;
  }
  .last-updated a:hover, .last-updated a:focus {
    text-decoration: underline;
  }
}
@media (min-width: 1280px) {
  .last-updated {
    margin-bottom: 4rem;
  }
  .search-filters-open .last-updated > div {
    max-width: calc(66.6667% - 10px);
    margin: 0 0 0 auto;
  }
}
@media print and (min-width: 7px) {
  .last-updated {
    margin-bottom: 4rem;
  }
  .search-filters-open .last-updated > div {
    max-width: calc(66.6667% - 10px);
    margin: 0 0 0 auto;
  }
}

.link-block {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  text-align: center;
  margin-bottom: 0rem;
  margin-top: 2.5rem;
}
@media (min-width: 768px) {
  .link-block {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .link-block {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .link-block {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .link-block {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .link-block {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .link-block {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.link-block.has-search {
  display: none;
}
.search-loaded .link-block.has-search {
  display: block;
  margin-top: 15px;
}
.detail-view-loaded .link-block.has-search {
  margin-left: auto;
  margin-right: auto;
}
.detail-view-loaded .link-block.has-search div {
  max-width: 100% !important;
}
.link-block a {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: #00759a;
}
.link-block > div a {
  font-size: 0.875rem;
  line-height: 2.1428571429;
}
@media (min-width: 768px) {
  .link-block {
    margin-bottom: 0rem;
  }
  .link-block a {
    text-decoration: none;
  }
  .link-block a:hover, .link-block a:focus {
    text-decoration: underline;
  }
}
@media print and (min-width: 6px) {
  .link-block {
    margin-bottom: 0rem;
  }
  .link-block a {
    text-decoration: none;
  }
  .link-block a:hover, .link-block a:focus {
    text-decoration: underline;
  }
}
@media (min-width: 1280px) {
  .link-block {
    margin-bottom: 0rem;
  }
  .search-filters-open .link-block > div {
    max-width: calc(66.6667% - 10px);
    margin: 0 0 0 auto;
  }
}
@media print and (min-width: 7px) {
  .link-block {
    margin-bottom: 0rem;
  }
  .search-filters-open .link-block > div {
    max-width: calc(66.6667% - 10px);
    margin: 0 0 0 auto;
  }
}

.location-info-container .location-image {
  display: block;
  position: relative;
  text-decoration: none;
  width: 100%;
}
.location-info-container .location-image img {
  width: 100%;
}
.location-info-container .location-image .distance-overlay {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  background-color: #fff;
  border: 1px solid #707070;
  border-radius: 0.25rem;
  color: #363534;
  left: 1.25rem;
  padding: 0.375rem 0.5rem;
  position: absolute;
  top: 1.25rem;
}
.location-info-container .location-info {
  margin: 1rem 2rem;
}
.location-info-container .location-info .location-name {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.3333333333;
  color: #000;
  margin-bottom: 1rem;
}
.location-info-container .location-info .location-data {
  margin-bottom: 1.5rem;
}
.location-info-container .location-info .location-data p,
.location-info-container .location-info .location-data a {
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.4285714286;
  margin-bottom: 0;
}
.location-info-container .location-info .location-data a {
  color: #00759a;
  text-decoration: none;
  box-sizing: border-box;
  height: 22.04px;
}
.location-info-container .location-info .location-data a:hover {
  border-bottom: 1px solid #00759a;
}
.location-info-container .contact-website .contact-title {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 0.4rem;
}
.location-info-container .office-hours .is-expanded .icon-arrow-down {
  padding-bottom: 6px;
  padding-top: 0;
  transform: rotate(180deg);
}
.location-info-container .office-hours .read-more {
  margin-bottom: 0;
}
.location-info-container .office-hours .icon-arrow-down {
  margin-left: 4px;
  padding-top: 6px;
  stroke: #00759a;
}
.location-info-container .office-hours .toggle-expand {
  margin-top: 0;
}
.location-info-container .office-hours .read-more-body {
  margin-bottom: 0.5rem;
}
.location-info-container .office-hours p span {
  display: inline-block;
  min-width: 2.25rem;
}

.location-select {
  position: relative;
  width: 100%;
}
.location-select.is-selected .location-item {
  display: none;
}
.location-select.is-selected .location-item.active {
  display: block;
}
.location-select.is-scheduling .location-select-nav .location-list-btn {
  border-right: none;
}
.location-select.is-scheduling .location-select-nav .location-schedule-btn {
  display: none;
}
.location-select.is-scheduling .schedule-info-container .schedule-header .reset-btn {
  margin-top: 5rem;
}
.location-select.is-scheduling .schedule-info-container .appointment-types {
  margin-top: 5rem;
  width: calc(100% - 2rem);
}
.location-select .location-item {
  position: relative;
}
.location-select .location-item.active {
  padding-top: 50px;
}
.location-select .location-item.active .location-info-container,
.location-select .location-item.active .schedule-info-container {
  width: 100%;
}
.location-select .location-item.active.scheduling .location-info-container {
  display: none;
}
.location-select .location-item.active.scheduling .schedule-info-container {
  display: block;
}
.location-select .location-item.active .location-select-btn {
  display: none;
}
.location-select .location-item.active .location-select-nav {
  display: flex;
}
.location-select .location-item.active .location-info-container {
  display: block;
}
.location-select .location-info-container,
.location-select .schedule-info-container {
  display: none;
}
.location-select .location-select-btn {
  align-items: center;
  background-color: #eee;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.125rem;
  margin-top: 0.125rem;
  padding: 0.75rem 1.25rem;
  text-align: left;
  width: 100%;
}
.location-select .location-label {
  padding-right: 1rem;
}
.location-select .location-name {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
}
.location-select .icon-arrow-right {
  stroke-width: 1px;
}
.location-select .location-select-nav {
  display: none;
  justify-content: space-evenly;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.location-select .location-select-nav .location-list-btn,
.location-select .location-select-nav .location-schedule-btn {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  align-items: center;
  background-color: rgba(112, 112, 112, 0.95);
  border-top: 1px solid #fff;
  color: #fff;
  display: flex;
  flex-grow: 1;
  height: 3rem;
  justify-content: center;
  padding: 0 1.875rem;
}
.location-select .location-select-nav .location-list-btn span,
.location-select .location-select-nav .location-schedule-btn span {
  padding: 0 0.5rem;
}
.location-select .location-select-nav .location-list-btn .icon,
.location-select .location-select-nav .location-schedule-btn .icon {
  height: 12px;
  stroke: #fff;
  stroke-width: 1px;
  width: 20px;
}
.location-select .location-select-nav .location-list-btn {
  border-right: 1px solid #fff;
  justify-content: flex-start;
}
.location-select .location-select-nav .location-schedule-btn {
  justify-content: flex-end;
}
.location-select .location-info-container .location-image .distance-overlay {
  bottom: 1.25rem;
  top: auto;
}
.location-select .location-list-desktop-btn {
  display: none;
  font-weight: 900;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .location-select .location-list-desktop-btn {
    display: block;
  }
  .location-select .location-item.active {
    padding-top: 0px;
  }
  .location-select .location-item .location-list-btn {
    display: none;
  }
  .location-select.is-selected .location-item.active {
    display: flex;
    justify-content: space-around;
  }
  .location-select.is-selected .location-item.active .schedule-info-container {
    display: block;
  }
  .location-select.is-selected .schedule-info-default {
    display: none;
  }
  .location-select .location-select-btn,
.location-select .location-select-nav {
    max-width: 33.3333333333%;
    flex-basis: 33.3333333333%;
  }
  .location-select .location-select-nav .location-list-btn {
    border-right: none;
  }
  .location-select .location-select-nav .location-schedule-btn {
    display: none;
  }
  .location-select .schedule-info-default {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    width: 66.6666666667%;
  }
}
@media print and (min-width: 6px) {
  .location-select .location-list-desktop-btn {
    display: block;
  }
  .location-select .location-item.active {
    padding-top: 0px;
  }
  .location-select .location-item .location-list-btn {
    display: none;
  }
  .location-select.is-selected .location-item.active {
    display: flex;
    justify-content: space-around;
  }
  .location-select.is-selected .location-item.active .schedule-info-container {
    display: block;
  }
  .location-select.is-selected .schedule-info-default {
    display: none;
  }
  .location-select .location-select-btn,
.location-select .location-select-nav {
    max-width: 33.3333333333%;
    flex-basis: 33.3333333333%;
  }
  .location-select .location-select-nav .location-list-btn {
    border-right: none;
  }
  .location-select .location-select-nav .location-schedule-btn {
    display: none;
  }
  .location-select .schedule-info-default {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    width: 66.6666666667%;
  }
}

.page-title {
  margin-bottom: 2rem;
}
.page-title h1 {
  font-size: 2.5rem;
  line-height: 1;
  margin: 0;
}
.page-title .title-inner {
  margin-right: auto;
  margin-left: auto;
  max-width: calc(1440px - 30px * 2);
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  align-items: center;
  background-color: #00759a;
  color: #fff;
  display: flex;
  height: 155px;
}
@media (min-width: 768px) {
  .page-title .title-inner {
    max-width: calc(
			1440px - 30px * 2
		);
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .page-title .title-inner {
    max-width: calc(
			1440px - 30px * 2
		);
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .page-title .title-inner {
    max-width: calc(
			1440px - 70px * 2
		);
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .page-title .title-inner {
    max-width: calc(
			1440px - 70px * 2
		);
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .page-title .title-inner {
    max-width: calc(
			1440px - 70px * 2
		);
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .page-title .title-inner {
    max-width: calc(
			1440px - 70px * 2
		);
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 768px) {
  .page-title h1 {
    font-size: 3.75rem;
    line-height: 1;
  }
  .page-title .title-inner {
    height: 200px;
  }
}
@media print and (min-width: 6px) {
  .page-title h1 {
    font-size: 3.75rem;
    line-height: 1;
  }
  .page-title .title-inner {
    height: 200px;
  }
}

.nav-accordion {
  background-color: #fff;
  position: relative;
}
.nav-accordion.filter-accordion > .accordion-item[aria-expanded=true].closing > .accordion-item-body {
  transform: translateX(100%);
}
.nav-accordion.filter-accordion > .accordion-item[aria-expanded=true] > .accordion-item-body {
  transform: translateX(0);
  z-index: 1;
  overflow-x: hidden;
  visibility: visible;
}
.nav-accordion.filter-accordion > .accordion-item > .accordion-item-body {
  background-color: #eee;
  display: block;
  left: 0;
  min-height: 100vh;
  position: fixed;
  top: 0;
  transform: translateX(100%);
  transition: all 0.2s ease-in-out;
  visibility: hidden;
  width: 100%;
}
.nav-accordion.filter-accordion > .accordion-item > .accordion-item-body > .accordion-item-body-inner {
  padding: 1rem 30px 6rem;
}
.filter-list .nav-accordion.filter-accordion > .accordion-item > .accordion-item-body {
  min-height: calc(100vh - 80px);
}
.nav-accordion.filter-accordion > .accordion-item .mobile-back-btn {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  align-items: center;
  color: #fff;
  display: flex;
  height: 20px;
  margin-left: -1rem;
}
.nav-accordion.filter-accordion > .accordion-item .mobile-back-btn .icon {
  fill: #707070;
  height: 0.75rem;
  stroke: #fff;
  width: 1.25rem;
}
.nav-accordion.filter-accordion[data-accordion-opened=true] > .accordion-item[aria-expanded=false] {
  display: none;
}
.nav-accordion.flush .accordion-item.opening > .accordion-item-body {
  overflow: hidden;
  transition: height 0.4s ease-in-out;
}
.nav-accordion.flush .accordion-item[aria-expanded=true] > .accordion-item-body {
  display: block;
  height: auto;
}
.nav-accordion.flush .accordion-item[aria-expanded=true].closing > .accordion-item-title .accordion-item-toggle {
  font-family: AvenirLight, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
}
.nav-accordion.flush .accordion-item[aria-expanded=true].closing > .accordion-item-title .accordion-item-toggle:hover, .nav-accordion.flush .accordion-item[aria-expanded=true].closing > .accordion-item-title .accordion-item-toggle:focus {
  color: #df7a00;
}
.nav-accordion.flush .accordion-item[aria-expanded=true].closing > .accordion-item-title .accordion-item-toggle .icon-arrow-down {
  transform: translateY(-50%);
}
.nav-accordion.flush .accordion-item[aria-expanded=true].closing > .accordion-item-title .accordion-item-toggle .label-secondary {
  opacity: 1;
}
.nav-accordion.flush .accordion-item[aria-expanded=true].closing > .accordion-item-title .accordion-item-tags {
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}
.nav-accordion.flush .accordion-item[aria-expanded=true].closing > .accordion-item-body {
  overflow: hidden;
  transition: height 0.4s ease-in-out;
}
.nav-accordion .accordion-item {
  border-bottom: 1px solid #eee;
  padding: 0;
}
.nav-accordion .accordion-item[aria-expanded=true] > .accordion-item-title .accordion-item-toggle {
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  color: #00759a;
}
.nav-accordion .accordion-item .accordion-item-title {
  position: relative;
}
.nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle {
  font-size: 1.625rem;
  line-height: 1.2307692308;
  font-family: AvenirLight, Helvetica, sans-serif;
  font-weight: normal;
  background: none;
  border: none;
  color: #363534;
  display: flex;
  margin: 0;
  padding: 1.5rem 2.5rem 1.5rem 20px;
  position: relative;
  text-align: left;
  width: 100%;
}
.nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle:hover, .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle:focus {
  color: #df7a00;
  outline: 0;
}
.nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle:hover .icon-arrow-down, .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle:focus .icon-arrow-down {
  fill: #913d00;
}
.nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle .accordion-item-label {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  white-space: nowrap;
}
.nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle .label-secondary {
  font-family: AvenirLight, Helvetica, sans-serif;
  font-weight: normal;
  color: #595756;
  overflow: hidden;
  padding-left: 0.5rem;
  text-overflow: ellipsis;
  transition: opacity 0.4s ease-in-out;
  white-space: nowrap;
}
.nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle .icon-arrow-down {
  fill: #df7a00;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: rotate(-90deg) translateX(50%);
}
.nav-accordion .accordion-item .accordion-item-title .accordion-item-tags {
  background-color: #fff;
  transition: opacity 0.2s ease-in-out;
  z-index: 1;
}
.nav-accordion .accordion-item .accordion-item-title .accordion-item-tags .tag-list {
  padding: 0 1rem 0.5rem;
}
.nav-accordion .accordion-item .accordion-item-title .accordion-item-tags .tag-list:last-of-type {
  padding-bottom: 1rem;
}
.nav-accordion .accordion-item .accordion-item-title .accordion-item-tags .tag-list .tag:first-of-type {
  padding-top: 0;
}
.nav-accordion .accordion-item .accordion-item-body {
  display: none;
  height: 0;
}
@media (min-width: 1280px) {
  .nav-accordion.filter-accordion > .accordion-item[aria-expanded=true].closing > .accordion-item-body {
    transform: none;
    overflow: hidden;
  }
  .nav-accordion.filter-accordion > .accordion-item[aria-expanded=true] .accordion-item-title .accordion-item-toggle .icon-arrow-down {
    transform: rotate(180deg) translateY(50%);
  }
  .nav-accordion.filter-accordion > .accordion-item[aria-expanded=true] > .accordion-item-body {
    display: block;
    height: auto;
    overflow-x: unset;
  }
  .nav-accordion.filter-accordion > .accordion-item.opening > .accordion-item-body {
    overflow: hidden;
    transition: height 0.4s ease-in-out;
  }
  .nav-accordion.filter-accordion > .accordion-item .accordion-item-title .accordion-item-toggle {
    padding-left: 1rem;
  }
  .nav-accordion.filter-accordion > .accordion-item .accordion-item-title .accordion-item-toggle .icon-arrow-down {
    right: 1rem;
    transform: translateY(-50%);
  }
  .nav-accordion.filter-accordion > .accordion-item .accordion-item-body {
    display: none;
    height: 0;
    min-height: 0;
    position: relative;
    transition: all 0.4s ease-in-out;
    transform: none;
  }
  .filter-list .nav-accordion.filter-accordion > .accordion-item .accordion-item-body {
    min-height: 0;
  }
  [class^=translated-] .nav-accordion.filter-accordion > .accordion-item .accordion-item-body {
    min-height: 0;
    top: 0;
  }
  .nav-accordion.filter-accordion > .accordion-item .accordion-item-body .accordion-item-body-inner {
    padding: 0 1rem 1rem;
  }
  .nav-accordion.filter-accordion > .accordion-item .mobile-back-btn {
    font-size: 1.125rem;
    line-height: 1.3333333333;
    font-family: AvenirMedium, Helvetica, sans-serif;
    font-weight: normal;
    align-items: center;
    color: #fff;
    display: flex;
    height: 20px;
    margin-left: -1rem;
  }
  .nav-accordion.filter-accordion > .accordion-item .mobile-back-btn .icon {
    fill: #707070;
    height: 3rem;
    margin-right: -0.5rem;
    margin-top: -3px;
    stroke: #fff;
    width: 3rem;
  }
  .nav-accordion.filter-accordion[data-accordion-opened=true] > .accordion-item[aria-expanded=false] {
    display: block;
  }
  .nav-accordion.nav-accordion.flush > .accordion-item > .accordion-item-title > .accordion-item-toggle,
.nav-accordion.nav-accordion.flush > .accordion-item > .accordion-item-body > .accordion-item-body-inner {
    padding-left: 0;
    padding-right: 0;
  }
  .nav-accordion.nav-accordion.flush > .accordion-item > .accordion-item-title > .accordion-item-toggle > .icon-arrow-down {
    transform: none;
  }
  .nav-accordion.nav-accordion.flush > .accordion-item[aria-expanded=true] > .accordion-item-title .accordion-item-toggle > .icon-arrow-down {
    transform: rotate(180deg);
  }
}
@media print and (min-width: 7px) {
  .nav-accordion.filter-accordion > .accordion-item[aria-expanded=true].closing > .accordion-item-body {
    transform: none;
    overflow: hidden;
  }
  .nav-accordion.filter-accordion > .accordion-item[aria-expanded=true] .accordion-item-title .accordion-item-toggle .icon-arrow-down {
    transform: rotate(180deg) translateY(50%);
  }
  .nav-accordion.filter-accordion > .accordion-item[aria-expanded=true] > .accordion-item-body {
    display: block;
    height: auto;
    overflow-x: unset;
  }
  .nav-accordion.filter-accordion > .accordion-item.opening > .accordion-item-body {
    overflow: hidden;
    transition: height 0.4s ease-in-out;
  }
  .nav-accordion.filter-accordion > .accordion-item .accordion-item-title .accordion-item-toggle {
    padding-left: 1rem;
  }
  .nav-accordion.filter-accordion > .accordion-item .accordion-item-title .accordion-item-toggle .icon-arrow-down {
    right: 1rem;
    transform: translateY(-50%);
  }
  .nav-accordion.filter-accordion > .accordion-item .accordion-item-body {
    display: none;
    height: 0;
    min-height: 0;
    position: relative;
    transition: all 0.4s ease-in-out;
    transform: none;
  }
  .filter-list .nav-accordion.filter-accordion > .accordion-item .accordion-item-body {
    min-height: 0;
  }
  [class^=translated-] .nav-accordion.filter-accordion > .accordion-item .accordion-item-body {
    min-height: 0;
    top: 0;
  }
  .nav-accordion.filter-accordion > .accordion-item .accordion-item-body .accordion-item-body-inner {
    padding: 0 1rem 1rem;
  }
  .nav-accordion.filter-accordion > .accordion-item .mobile-back-btn {
    font-size: 1.125rem;
    line-height: 1.3333333333;
    font-family: AvenirMedium, Helvetica, sans-serif;
    font-weight: normal;
    align-items: center;
    color: #fff;
    display: flex;
    height: 20px;
    margin-left: -1rem;
  }
  .nav-accordion.filter-accordion > .accordion-item .mobile-back-btn .icon {
    fill: #707070;
    height: 3rem;
    margin-right: -0.5rem;
    margin-top: -3px;
    stroke: #fff;
    width: 3rem;
  }
  .nav-accordion.filter-accordion[data-accordion-opened=true] > .accordion-item[aria-expanded=false] {
    display: block;
  }
  .nav-accordion.nav-accordion.flush > .accordion-item > .accordion-item-title > .accordion-item-toggle,
.nav-accordion.nav-accordion.flush > .accordion-item > .accordion-item-body > .accordion-item-body-inner {
    padding-left: 0;
    padding-right: 0;
  }
  .nav-accordion.nav-accordion.flush > .accordion-item > .accordion-item-title > .accordion-item-toggle > .icon-arrow-down {
    transform: none;
  }
  .nav-accordion.nav-accordion.flush > .accordion-item[aria-expanded=true] > .accordion-item-title .accordion-item-toggle > .icon-arrow-down {
    transform: rotate(180deg);
  }
}

.nav-link {
  text-decoration: none;
  display: inline-block;
  position: relative;
  color: #363534;
  padding: 5px 18px;
}
.nav-link::after {
  content: "";
  opacity: 0;
  position: absolute;
  display: block;
  height: 1px;
  width: 20px;
  bottom: 0;
  left: 40px;
  background-color: #a71930;
  transition: left 0.25s ease-in-out, opacity 0.25s ease-out;
}
.nav-link:hover, .nav-link:active, .nav-link:focus, .nav-link.active {
  color: #000;
}
.nav-link:hover::after, .nav-link:active::after, .nav-link:focus::after, .nav-link.active::after {
  opacity: 1;
  left: 18px;
}
.nav-link.alt {
  color: #913d00;
}
.nav-link.alt:hover, .nav-link.alt:active, .nav-link.alt:focus, .nav-link.alt.active {
  color: #df7a00;
}
.nav-link.alt::after {
  background-color: #000;
}
.nav-link.active::after {
  display: none;
}

.main-navigation {
  display: flex;
}
.main-navigation .icon-menu-open {
  fill: #363534;
}
.main-navigation .icon-menu-close {
  position: relative;
  stroke: #363534;
  stroke-width: 3px;
}
.main-navigation .full-nav {
  display: none;
}
.main-navigation .nav-open,
.main-navigation .nav-close {
  background-color: transparent;
  border: 0;
  line-height: 0;
  padding: 0;
}
@media (min-width: 1024px) {
  .hv-header .main-navigation .full-nav {
    display: flex;
    overflow: hidden;
    padding-left: 2rem;
  }
  .hv-header .main-navigation .full-nav .search-btn {
    padding: 7px 18px 5px;
  }
  .hv-header .main-navigation .nav-open {
    display: none;
  }
}
@media (min-width: 1280px) {
  .main-navigation .full-nav {
    display: flex;
    overflow: hidden;
    padding-left: 2rem;
  }
  .main-navigation .full-nav .search-btn {
    padding: 7px 18px 5px;
  }
  .main-navigation .nav-open {
    display: none;
  }
}
@media print and (min-width: 7px) {
  .main-navigation .full-nav {
    display: flex;
    overflow: hidden;
    padding-left: 2rem;
  }
  .main-navigation .full-nav .search-btn {
    padding: 7px 18px 5px;
  }
  .main-navigation .nav-open {
    display: none;
  }
}
.main-navigation .message-badge {
  position: relative;
  top: -0.35rem;
  right: 0.2rem;
  padding: 0rem 0.3rem;
  border-radius: 100%;
  background: red;
  color: #ffefef;
}

.message-block {
  background: #f9f9f9;
  padding: 1rem;
  margin: 1rem -1rem 0;
}
.message-block > :last-child {
  margin-bottom: 0;
}
.message-block .heading {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.3333333333;
  margin-bottom: 0.25rem;
}
.message-block .description {
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.4285714286;
}
.message-block .description a {
  color: #00759a;
  text-decoration: none;
}
.message-block .description a:hover {
  text-decoration: underline;
}
.message-block > :last-child {
  margin-bottom: 0;
}

.mobile-nav {
  background-color: #fff;
  display: none;
  height: 100vh;
  margin-top: -100vh;
  overflow: auto;
  transition: margin-top 0.3s ease-in-out;
  width: 100vw;
}
.mobile-nav.visible {
  display: block;
}
.mobile-nav-open .mobile-nav {
  margin-top: 0;
  position: fixed;
  z-index: 11;
}
.mobile-nav .mobile-nav-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 30px;
}
.mobile-nav .mobile-nav-container .at-footer-container,
.mobile-nav .mobile-nav-container .pls-footer-container {
  padding-bottom: 10rem;
}
.mobile-nav .mobile-nav-logo {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  width: 100%;
}
@media (min-width: 768px) {
  .mobile-nav .mobile-nav-logo {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .mobile-nav .mobile-nav-logo {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .mobile-nav .mobile-nav-logo {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .mobile-nav .mobile-nav-logo {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .mobile-nav .mobile-nav-logo {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .mobile-nav .mobile-nav-logo {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.mobile-nav .mobile-nav-logo .logo-container {
  height: auto;
  padding-top: 0.625rem;
}
.mobile-nav .mobile-nav-logo .icon-phs-logo {
  fill: #a71930;
  height: 32.288581px;
  max-width: 279.929px;
  width: 100%;
}
.mobile-nav .mobile-nav-logo .logo-link:focus {
  outline: 0;
}
.mobile-nav .mobile-nav-logo .logo-subtitle {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirLight, Helvetica, sans-serif;
  font-weight: normal;
  display: block;
  margin-bottom: 0;
  padding-left: 2.125rem;
}
.mobile-nav .logo-container {
  height: auto;
  padding-right: 1rem;
}
.mobile-nav .mobile-nav-close {
  display: flex;
}
.mobile-nav .primary-nav {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  flex-grow: 1;
}
@media (min-width: 768px) {
  .mobile-nav .primary-nav {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .mobile-nav .primary-nav {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .mobile-nav .primary-nav {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .mobile-nav .primary-nav {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .mobile-nav .primary-nav {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .mobile-nav .primary-nav {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.mobile-nav .nav-links {
  list-style: none;
  margin-bottom: 0.5rem;
  padding: 0;
}
.mobile-nav .nav-links li {
  display: block;
  margin: 0;
  padding-bottom: 0.5rem;
}
.mobile-nav .nav-links li:before {
  display: none;
}
.mobile-nav .nav-links li.active {
  position: relative;
}
.mobile-nav .nav-links li.active a,
.mobile-nav .nav-links li.active button {
  color: #00759a;
  position: relative;
  text-decoration: none;
}
.mobile-nav .nav-links li.active a::after,
.mobile-nav .nav-links li.active button::after {
  background-color: #a71930;
  bottom: 0;
  content: "";
  display: none;
  height: 1px;
  left: 0;
  opacity: 1;
  position: absolute;
  width: 20px;
}
.mobile-nav .nav-links li a,
.mobile-nav .nav-links li button {
  font-size: 1rem;
  line-height: 1.375;
  color: #000;
  display: block;
  outline: 0;
  padding: 1.25rem 0 0.75rem;
  text-decoration: none;
}
.mobile-nav .nav-links li a:hover, .mobile-nav .nav-links li a:focus,
.mobile-nav .nav-links li button:hover,
.mobile-nav .nav-links li button:focus {
  text-decoration: underline;
}
.mobile-nav .nav-links li .message-badge {
  position: relative;
  top: -0.35rem;
  right: 0.2rem;
  padding: 0rem 0.3rem;
  border-radius: 100%;
  background: red;
  color: #ffefef;
}
.mobile-nav .t3-mobile-nav-footer {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  background-color: #363534;
  margin-top: auto;
  padding-bottom: 8rem;
  padding-top: 2rem;
}
@media (min-width: 768px) {
  .mobile-nav .t3-mobile-nav-footer {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .mobile-nav .t3-mobile-nav-footer {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .mobile-nav .t3-mobile-nav-footer {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .mobile-nav .t3-mobile-nav-footer {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .mobile-nav .t3-mobile-nav-footer {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .mobile-nav .t3-mobile-nav-footer {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.mobile-nav .t3-mobile-nav-footer .help-title {
  color: #00b0e6;
}
.mobile-nav .t3-mobile-nav-footer .link-list {
  margin-bottom: 2rem;
}
.mobile-nav .t3-mobile-nav-footer p {
  font-size: 1rem;
  line-height: 1.375;
  color: #fff;
}
.mobile-nav .t3-mobile-nav-footer p:last-of-type {
  margin-bottom: 0;
}
.mobile-nav .t3-mobile-nav-footer a {
  color: #fff;
}
@media (min-width: 1280px) {
  .mobile-nav {
    display: none;
  }
  .mobile-nav.visible {
    display: none;
  }
}
@media print and (min-width: 7px) {
  .mobile-nav {
    display: none;
  }
  .mobile-nav.visible {
    display: none;
  }
}

.pagination-nav {
  display: flex;
  justify-content: center;
  margin: 2.6rem 0 15px;
}
.pagination-nav .pagination {
  align-items: center;
  display: flex;
}
.pagination-nav .page-item {
  align-items: center;
  display: flex;
}
.pagination-nav .page-item.active .page-link {
  color: #a71930;
}
.pagination-nav .page-item.disabled {
  pointer-events: none;
  color: #eee;
}
.pagination-nav .page-item.disabled svg {
  stroke: #eee;
}
.pagination-nav .page-link {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  padding: 0.625rem;
  line-height: 1;
}
.pagination-nav .page-link:hover {
  color: #bfbfbf;
}
.pagination-nav .page-link:hover svg {
  stroke: #bfbfbf;
}
.pagination-nav .page-link .left {
  transform: rotate(180deg);
}

.ratings {
  display: flex;
}
.radio-wrapper .ratings .average-rating {
  margin-left: 0.375rem;
}
.radio-wrapper .ratings .average-rating,
.radio-wrapper .ratings .rating-count {
  color: #363534;
  margin-top: 1px;
}
.disabled .ratings .average-rating,
.disabled .ratings .rating-count {
  color: #bfbfbf;
}
.ratings .average-rating {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  margin: 2px 0.25rem 0 0.625rem;
}
.ratings .rating-count {
  color: #595756;
  margin-top: 2px;
}

.rating-stars {
  display: flex;
  justify-content: space-between;
  width: 100px;
}
.disabled .rating-stars .star svg {
  fill: #fff;
}
.disabled .rating-stars .star .star-fill svg {
  fill: #bfbfbf;
}
.rating-stars .star {
  display: inline-block;
  position: relative;
  height: 18px;
  width: 18px;
}
.rating-stars .star svg {
  fill: #bfbfbf;
  height: 18px;
  width: 18px;
  position: absolute;
  top: 0;
  left: 0;
}
.rating-stars .star .star-fill {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 0;
  height: 18px;
}
.rating-stars .star .star-fill svg {
  fill: #ff8c00;
}
.rating-stars .star:nth-child(1) .star-fill {
  max-width: 100%;
  width: calc((var(--rating) - (1 - 1)) * 100%);
}
.rating-stars .star:nth-child(2) .star-fill {
  max-width: 100%;
  width: calc((var(--rating) - (2 - 1)) * 100%);
}
.rating-stars .star:nth-child(3) .star-fill {
  max-width: 100%;
  width: calc((var(--rating) - (3 - 1)) * 100%);
}
.rating-stars .star:nth-child(4) .star-fill {
  max-width: 100%;
  width: calc((var(--rating) - (4 - 1)) * 100%);
}
.rating-stars .star:nth-child(5) .star-fill {
  max-width: 100%;
  width: calc((var(--rating) - (5 - 1)) * 100%);
}

.rating-icon-info-alert {
  padding-top: 3px;
  padding-left: 9px;
  display: flex;
}

.read-more {
  margin-bottom: 2rem;
  position: relative;
}
.read-more.is-expanded > .read-more-body {
  max-height: none;
}
.read-more.is-expanded > .read-more-condensed {
  display: none;
}
.read-more.is-expanded .read-more-expanded {
  display: block;
}
.read-more .read-more-body {
  margin-bottom: 2rem;
  max-height: 280px;
}
.read-more p {
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.4285714286;
}
.read-more p:last-of-type {
  margin-bottom: 0.5rem;
}
.read-more .read-more-expanded {
  display: none;
}
.read-more .read-more-link {
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.4285714286;
  display: inline-block;
}
.read-more .read-more-link-alt {
  background-color: #e5f1f5;
  border-bottom: 1px solid #00759a;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 100%;
}
.read-more .read-more-link-alt span {
  color: #00759a;
  padding-right: 0.5rem;
}
.read-more .read-more-link-alt .icon {
  stroke: #00759a;
}
.read-more .button-container {
  display: flex;
  justify-content: center;
}
.read-more .btn.toggle-expand .icon {
  height: 14px;
  fill: #fff;
  width: 28px;
}
.read-more .read-more-link.is-expanded > .expandToggleLabel,
.read-more .button-container .btn.is-expanded > .expandToggleLabel {
  display: none;
}
.read-more .read-more-link.is-expanded > .collapseToggleLabel,
.read-more .button-container .btn.is-expanded > .collapseToggleLabel {
  display: inline;
}
.read-more .read-more-link.is-expanded > .icon,
.read-more .button-container .btn.is-expanded > .icon {
  transform: rotate(180deg);
}
.read-more .read-more-link > .collapseToggleLabel,
.read-more .button-container .btn > .collapseToggleLabel {
  display: none;
}
@media (min-width: 768px) {
  .read-more {
    margin-bottom: 4rem;
  }
  .sc-edit-mode .read-more .read-more-condensed:after {
    content: "(Hidden when expanded)";
    display: block;
    font-style: italic;
    color: #bfbfbf;
    margin-bottom: 1rem;
  }
  .sc-edit-mode .read-more .read-more-expanded {
    display: block;
  }
  .sc-edit-mode .read-more .read-more-expanded:after {
    content: "(Hidden until expanded)";
    display: block;
    font-style: italic;
    color: #bfbfbf;
    margin-bottom: 1rem;
  }
  .sc-edit-mode .read-more .read-more-link {
    display: none;
  }
}
@media print and (min-width: 6px) {
  .read-more {
    margin-bottom: 4rem;
  }
  .sc-edit-mode .read-more .read-more-condensed:after {
    content: "(Hidden when expanded)";
    display: block;
    font-style: italic;
    color: #bfbfbf;
    margin-bottom: 1rem;
  }
  .sc-edit-mode .read-more .read-more-expanded {
    display: block;
  }
  .sc-edit-mode .read-more .read-more-expanded:after {
    content: "(Hidden until expanded)";
    display: block;
    font-style: italic;
    color: #bfbfbf;
    margin-bottom: 1rem;
  }
  .sc-edit-mode .read-more .read-more-link {
    display: none;
  }
}

.schedule-btns {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  max-width: 275px;
}
.schedule-btns .btn {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  flex-basis: calc(50% - 5px);
  margin: 0;
  max-width: 265px;
  min-width: calc(50% - 5px);
  padding: 0.625rem 0.25rem 0.5rem;
}
.schedule-btns .btn:only-child {
  flex-basis: 100%;
  min-width: 100%;
}
.schedule-btns .btn.open-scheduling-btn {
  background-color: #363534;
  border: 0;
  color: #fff;
}
.schedule-btns .btn.open-scheduling-btn:hover, .schedule-btns .btn.open-scheduling-btn:focus {
  background-color: #000;
}
.schedule-btns p {
  flex-basis: calc(50% - 5px);
  min-width: calc(50% - 5px);
}
@media (min-width: 768px) {
  .schedule-btns {
    justify-content: space-around;
  }
  .schedule-info-container .schedule-btns {
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
  }
  .schedule-info-container .schedule-btns .btn {
    font-size: 1.125rem;
    line-height: 1.3333333333;
    flex-basis: calc(50% - 10px);
    min-width: calc(50% - 10px);
    padding: 1rem 0.5rem;
  }
  .schedule-info-container .schedule-btns .btn:only-child {
    min-width: 0;
  }
}
@media print and (min-width: 6px) {
  .schedule-btns {
    justify-content: space-around;
  }
  .schedule-info-container .schedule-btns {
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
  }
  .schedule-info-container .schedule-btns .btn {
    font-size: 1.125rem;
    line-height: 1.3333333333;
    flex-basis: calc(50% - 10px);
    min-width: calc(50% - 10px);
    padding: 1rem 0.5rem;
  }
  .schedule-info-container .schedule-btns .btn:only-child {
    min-width: 0;
  }
}

.schedule-header {
  line-height: 1;
}
.schedule-header .icon-calendar {
  height: 1.6875rem;
  margin-bottom: 0.5rem;
  width: 1.875rem;
}

.schedule-info-container {
  padding: 1rem;
  position: relative;
  text-align: center;
}
.schedule-info-container.show-appointment-types .reset-btn, .schedule-info-container.show-direct-scheduling .reset-btn, .schedule-info-container.show-open-scheduling .reset-btn {
  display: block;
}
.schedule-info-container.show-direct-scheduling .schedule-default, .schedule-info-container.show-open-scheduling .schedule-default {
  display: none;
}
.schedule-info-container.show-appointment-types .appointment-types {
  display: block;
}
.schedule-info-container.show-appointment-types .schedule-header .reset-btn {
  display: none;
}
.schedule-info-container.show-direct-scheduling .direct-scheduling {
  display: block;
}
.schedule-info-container.show-open-scheduling .open-scheduling {
  display: block;
}
.schedule-info-container.urgent-care-scheduling.show-terms-of-use .terms-of-use {
  display: block;
}
.schedule-info-container.urgent-care-scheduling .appointment-types .reset-btn {
  display: none;
}
.schedule-info-container.loading .loader {
  display: block;
}
.schedule-info-container .schedule-title {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1.3333333333;
  margin-bottom: 1rem;
}
.schedule-info-container .reset-btn {
  display: none;
  position: absolute;
  right: 1.25rem;
  top: 0;
}
.schedule-info-container .reset-btn .icon {
  height: 1.5rem;
  width: 1.5rem;
}
.schedule-info-container .appointment-types,
.schedule-info-container .direct-scheduling,
.schedule-info-container .open-scheduling {
  display: none;
}
.schedule-info-container .appointment-types {
  background-color: rgba(255, 255, 255, 0.95);
  height: 100%;
  padding-top: 4.5rem;
  position: absolute;
  top: 0;
  width: 100%;
}
.schedule-info-container .appointment-types .schedule-btns {
  max-width: none;
}
.schedule-info-container .appointment-type-title {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.3333333333;
  margin-bottom: 1rem;
}
.schedule-info-container .appointment-type {
  font-size: 1.5rem;
  line-height: 1.3333333333;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 1.5rem;
}
.schedule-info-container .schedule-content .description {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  margin-bottom: 0.25rem;
}
.schedule-info-container .schedule-content .description-primary {
  margin-bottom: 3rem;
}
.schedule-info-container .schedule-btns {
  margin-left: auto;
  margin-right: auto;
}
.schedule-info-container .appointment-type-description {
  font-size: 0.875rem;
  line-height: 1.4285714286;
}
.schedule-info-container .terms-of-use {
  display: none;
  margin-left: auto;
  margin-right: auto;
  max-width: 547px;
  text-align: left;
}
.schedule-info-container .terms-of-use .reset-btn {
  display: block;
}
.schedule-info-container .terms-of-use p {
  font-size: 0.875rem;
  line-height: 1.4285714286;
}
.schedule-info-container .terms-of-use .checkbox-wrapper {
  align-items: flex-start;
  display: flex;
  min-height: 1.5rem;
  justify-items: flex-start;
  position: relative;
}
.schedule-info-container .terms-of-use .checkbox-wrapper input[type=checkbox] {
  height: 1.5rem;
  margin-top: 2px;
  opacity: 0;
  width: 1.5rem;
}
.schedule-info-container .terms-of-use .checkbox-wrapper input[type=checkbox]:checked + label:after {
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  content: "";
  display: block;
  height: 0.9rem;
  left: 0;
  margin: 0.25rem 0.5rem;
  position: absolute;
  top: 0;
  transform: rotate(45deg) translate(-1px, -1px);
  width: 0.45rem;
  border-bottom-width: 1px;
  border-right-width: 1px;
}
.schedule-info-container .terms-of-use .checkbox-wrapper input[type=checkbox]:not([disabled]):hover {
  cursor: pointer;
}
.schedule-info-container .terms-of-use .checkbox-wrapper input[type=checkbox]:not([disabled]):hover + label:before {
  border-color: #363534;
}
.schedule-info-container .terms-of-use .checkbox-wrapper input[type=checkbox]:not([disabled]):focus + label:before {
  border-color: #363534;
  border-width: 2px;
}
.schedule-info-container .terms-of-use .checkbox-wrapper input[type=checkbox]:not([disabled]) + label:hover {
  cursor: pointer;
}
.schedule-info-container .terms-of-use .checkbox-wrapper input[type=checkbox]:not([disabled]) + label:hover:before {
  border-color: #363534;
}
.schedule-info-container .terms-of-use .checkbox-wrapper input[type=checkbox] + label:before {
  background-color: #fff;
  border: 1px solid #707070;
  border-radius: 2px;
  content: "";
  display: block;
  height: 1.5rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 1.5rem;
}
.schedule-info-container .loader {
  display: none;
  float: right;
}
.schedule-info-container .loader:after {
  border: 2px solid #bfbfbf;
  border-bottom: 2px solid transparent;
  border-left: 2px solid transparent;
  content: " ";
  display: block;
  pointer-events: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  animation: spin 1.4s infinite linear;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media (min-width: 768px) {
  .schedule-info-container {
    background-color: #fff;
    flex: 0 1 66.6666666667%;
    margin-bottom: 4rem;
    max-width: 66.6666666667%;
    overflow: hidden;
    padding: 0;
  }
  .schedule-info-container .schedule-content .description-primary {
    font-size: 1.125rem;
    line-height: 1.3333333333;
    margin-bottom: 3.4375rem;
  }
  .schedule-info-container .schedule-content .description-secondary {
    font-size: 0.875rem;
    line-height: 1.4285714286;
    margin-bottom: 0;
  }
  .schedule-info-container .schedule-title {
    font-size: 1.5rem;
    line-height: 1.3333333333;
    color: #363534;
    margin-bottom: 1.5rem;
  }
  .schedule-info-container .schedule-container {
    height: 90%;
  }
  .schedule-info-container .direct-scheduling iframe,
.schedule-info-container .open-scheduling iframe {
    height: 375px;
    width: 698.666px;
  }
  .schedule-info-container .appointment-types .schedule-btns {
    max-width: 500px;
  }
}
@media print and (min-width: 6px) {
  .schedule-info-container {
    background-color: #fff;
    flex: 0 1 66.6666666667%;
    margin-bottom: 4rem;
    max-width: 66.6666666667%;
    overflow: hidden;
    padding: 0;
  }
  .schedule-info-container .schedule-content .description-primary {
    font-size: 1.125rem;
    line-height: 1.3333333333;
    margin-bottom: 3.4375rem;
  }
  .schedule-info-container .schedule-content .description-secondary {
    font-size: 0.875rem;
    line-height: 1.4285714286;
    margin-bottom: 0;
  }
  .schedule-info-container .schedule-title {
    font-size: 1.5rem;
    line-height: 1.3333333333;
    color: #363534;
    margin-bottom: 1.5rem;
  }
  .schedule-info-container .schedule-container {
    height: 90%;
  }
  .schedule-info-container .direct-scheduling iframe,
.schedule-info-container .open-scheduling iframe {
    height: 375px;
    width: 698.666px;
  }
  .schedule-info-container .appointment-types .schedule-btns {
    max-width: 500px;
  }
}

.tag-list {
  margin-bottom: 0.75rem;
}
.tag-list .tag-list-title {
  font-size: 0.75rem;
  line-height: 1.5;
  color: #595756;
  display: block;
  margin-bottom: 0.5rem;
}
.tag-list .tag {
  font-size: 0.75rem;
  line-height: 1.5;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  background-color: #fff;
  color: #6f6f6f;
  display: inline-block;
  padding: 0.5rem 0.625rem;
  margin: 0 0.5rem 0.5rem 0;
}
.tag-list .tag span {
  display: inline-flex;
}
.tag-list .tag .tag-remove-btn {
  font-size: 1.5rem;
  line-height: 1.3333333333;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  background: none;
  border: none;
  margin-left: 0.5rem;
  padding: 0;
}
.tag-list .tag .tag-remove-btn .icon {
  align-self: center;
  display: flex;
  stroke: #595756;
  height: 0.625rem;
  transform: translateY(-1px);
  width: 0.625rem;
}
.tag-list .tag .tag-remove-btn:hover, .tag-list .tag .tag-remove-btn:focus {
  outline: 0;
}
.tag-list .tag .tag-remove-btn:hover .icon, .tag-list .tag .tag-remove-btn:focus .icon {
  stroke: #222;
  stroke-width: 4px;
}
.tag-list .tag.star-tag {
  padding: 0.5rem 0.625rem 6px;
  transform: translateY(2px);
}
.tag-list .tag .star-tag-span {
  height: 18px;
}
.tag-list .tag .rating-stars {
  width: 80px;
}
.tag-list .tag .rating-stars .star {
  height: 14px;
  width: 14px;
}
.tag-list .tag .rating-stars .star svg {
  height: 14px;
  width: 14px;
}
.tag-list .tag .rating-stars .star .star-fill {
  height: 14px;
}

.typeahead-suggestion {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}
.typeahead-suggestion:hover {
  cursor: pointer;
}
.typeahead-suggestion.active {
  background-color: #f9f9f9;
}
.typeahead-suggestion.disabled {
  font-style: italic;
}
.typeahead-suggestion mark {
  background-color: #ffff99;
}

.placeholder-container {
  position: relative;
  animation: fadein 0.5s;
  -moz-animation: fadein 0.5s; /* Firefox */
  -webkit-animation: fadein 0.5s; /* Safari and Chrome */
  -o-animation: fadein 0.5s; /* Opera */
}
.placeholder-container .filtertypeahead-placeholder {
  position: absolute;
  top: 5px;
  left: 7px;
  z-index: 2000;
  font-size: 0.7rem;
  color: gray;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
#typeahead .active .typeahead-option {
  background-color: #eee !important;
}

.report-inaccuracy-block {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
}
@media (min-width: 768px) {
  .report-inaccuracy-block {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .report-inaccuracy-block {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .report-inaccuracy-block {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .report-inaccuracy-block {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .report-inaccuracy-block {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .report-inaccuracy-block {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.report-inaccuracy-block.has-search {
  display: none;
}
.detail-view-loaded .report-inaccuracy-block.has-search {
  display: block;
}
.report-inaccuracy-block a {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: #00759a;
}
.report-inaccuracy-block > div a {
  font-size: 0.875rem;
  line-height: 2.1428571429;
}
@media (min-width: 768px) {
  .report-inaccuracy-block {
    margin-bottom: 0;
  }
  .report-inaccuracy-block a {
    text-decoration: none;
  }
  .report-inaccuracy-block a:hover, .report-inaccuracy-block a:focus {
    text-decoration: underline;
  }
}
@media print and (min-width: 6px) {
  .report-inaccuracy-block {
    margin-bottom: 0;
  }
  .report-inaccuracy-block a {
    text-decoration: none;
  }
  .report-inaccuracy-block a:hover, .report-inaccuracy-block a:focus {
    text-decoration: underline;
  }
}
@media (min-width: 1280px) {
  .report-inaccuracy-block {
    margin-bottom: 0;
  }
}
@media print and (min-width: 7px) {
  .report-inaccuracy-block {
    margin-bottom: 0;
  }
}

.way-finder {
  margin-top: 4rem;
}
.way-finder .grid-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  justify-content: center;
}
.way-finder .header-block {
  padding-left: 1.2rem;
}
.way-finder .header-block .language {
  display: flex;
  justify-content: right;
}
.way-finder .inner-block {
  background-color: #eee;
  padding: 1.4rem 2.2rem;
  padding-bottom: 0px;
}
.way-finder .inner-block .description {
  font-size: 1.25rem;
  line-height: 1.5;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
}
.way-finder .helpertext {
  margin-left: auto;
  margin-right: auto;
  padding-top: 4rem;
  width: 100%;
  text-align: center;
  font-size: 0.875rem;
  font-family: "AvenirMedium";
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.way-finder .helpertext i:first-of-type {
  font-weight: bolder;
}
.way-finder .link-card {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  background-color: #fff;
  margin: 1.2rem 0;
  padding: 1rem 1.8rem;
}
.way-finder .link-card .btn-primary {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  background-color: #363534;
  color: #fff;
  padding: 1.125rem;
  white-space: normal;
  width: 100%;
}
.way-finder .link-card .btn-primary:hover {
  text-decoration: none;
  background-color: #000;
}
.way-finder .link-card p {
  font-size: 0.875rem;
  line-height: 1.4285714286;
}
.way-finder .link-card .icon {
  border: #74c15f;
  display: block;
  fill: #a71930;
  height: 6rem;
  margin: 1rem auto;
  strokes: transparent;
  stroke-width: 0px;
  width: 6rem;
}
@media (min-width: 768px) {
  .way-finder {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .way-finder {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 768px) and (min-width: 6px) {
  .way-finder {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 768px) and (min-width: 1280px) {
  .way-finder {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 768px) and (min-width: 7px) {
  .way-finder {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 768px) and (min-width: 1440px) {
  .way-finder {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 768px) and (min-width: 1440px) {
  .way-finder {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 768px) {
  .way-finder .header-block {
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .way-finder .inner-block {
    background-color: #eee;
    padding: 3.8rem 1.2rem 8.2rem;
    padding-bottom: 0px;
  }
}
@media (min-width: 768px) {
  .way-finder .link-card {
    background-color: #fff;
    margin: 2rem 1.2rem;
    padding: 1rem;
  }
}
@media print and (min-width: 6px) {
  .way-finder {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
}
@media print and (min-width: 6px) and (min-width: 768px) {
  .way-finder {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) and (min-width: 6px) {
  .way-finder {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) and (min-width: 1280px) {
  .way-finder {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 6px) and (min-width: 7px) {
  .way-finder {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 6px) and (min-width: 1440px) {
  .way-finder {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 6px) and (min-width: 1440px) {
  .way-finder {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 6px) {
  .way-finder .header-block {
    padding-left: 0;
  }
}
@media print and (min-width: 6px) {
  .way-finder .inner-block {
    background-color: #eee;
    padding: 3.8rem 1.2rem 8.2rem;
    padding-bottom: 0px;
  }
}
@media print and (min-width: 6px) {
  .way-finder .link-card {
    background-color: #fff;
    margin: 2rem 1.2rem;
    padding: 1rem;
  }
}
@media (min-width: 1280px) {
  .way-finder {
    margin-top: 3rem;
  }
}
@media print and (min-width: 7px) {
  .way-finder {
    margin-top: 3rem;
  }
}

.contact-us-methods {
  margin-top: 0rem;
}
.contact-us-methods .grid-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  justify-content: left;
}
.contact-us-methods .header-block {
  padding-left: 1.2rem;
}
.contact-us-methods .header-block .language {
  display: flex;
  justify-content: right;
}
.contact-us-methods .inner-block {
  background-color: #fff;
  padding: 0rem 0rem;
  padding-bottom: 0px;
}
.contact-us-methods .inner-block .description {
  font-size: 1.25rem;
  line-height: 1.5;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
}
.contact-us-methods .helpertext {
  margin-left: auto;
  margin-right: auto;
  padding-top: 0.5rem;
  padding-bottom: 0rem;
  width: 100%;
  font-size: 0.875rem;
  font-family: "AvenirMedium";
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.contact-us-methods .helpertext i:first-of-type {
  font-weight: bolder;
}
.contact-us-methods .helpertext p {
  font-size: 1rem;
  margin-top: 10px;
}
.contact-us-methods .link-box {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  background-color: #fff;
  margin: 1rem 1rem -2rem 1rem;
  padding: 3.2rem;
  border: 1px solid grey;
}
.contact-us-methods .link-box .btn-primary {
  font-size: 0.9rem;
  line-height: 1.4285714286;
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: bolder;
  background-color: #fff;
  color: #363534;
  padding: 0.4rem 0.1rem;
  white-space: normal;
  width: 100%;
  border: 1px solid gray;
}
.contact-us-methods .link-box .btn-primary:hover {
  text-decoration: none;
  background-color: #000;
  color: #fff;
}
.contact-us-methods .link-box p {
  font-size: 1.1rem;
  line-height: 1.4285714286;
  text-align: center;
  font-weight: bolder;
}
.contact-us-methods .link-box .icon {
  border: #74c15f;
  display: block;
  fill: #595756;
  height: 6rem;
  margin: 1rem auto;
  stroke: transparent;
  stroke-width: 0px;
  width: 6rem;
}
@media (min-width: 768px) {
  .contact-us-methods {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .contact-us-methods .header-block {
    padding-left: 0;
  }
  .contact-us-methods .inner-block {
    background-color: #fff;
    padding: 0rem 0rem 0rem;
    padding-bottom: 0px;
    width: 120%;
  }
  .contact-us-methods .link-box {
    background-color: #fff;
    margin: 1rem 1rem 1rem 1rem;
    padding: 1rem;
  }
}
@media print and (min-width: 6px) {
  .contact-us-methods {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .contact-us-methods .header-block {
    padding-left: 0;
  }
  .contact-us-methods .inner-block {
    background-color: #fff;
    padding: 0rem 0rem 0rem;
    padding-bottom: 0px;
    width: 120%;
  }
  .contact-us-methods .link-box {
    background-color: #fff;
    margin: 1rem 1rem 1rem 1rem;
    padding: 1rem;
  }
}
@media (min-width: 1280px) {
  .contact-us-methods {
    margin-top: 0rem;
    margin-bottom: -2rem;
  }
}
@media print and (min-width: 7px) {
  .contact-us-methods {
    margin-top: 0rem;
    margin-bottom: -2rem;
  }
}

.my-care-resources {
  margin-top: 0rem;
}
.my-care-resources .description {
  margin-bottom: 1rem;
}
.my-care-resources .sub-header {
  margin-top: 1rem;
  margin-bottom: 0rem;
}
.my-care-resources .helpertext {
  padding-top: 0rem;
  padding-bottom: 1rem;
  width: 100%;
  text-align: left;
  font-size: 0.875rem;
  font-weight: normal;
  font-style: italic;
  font-family: "AvenirMedium";
}
.my-care-resources .footer {
  margin-top: 2rem;
}
.my-care-resources .action-section {
  margin-bottom: 0.5rem;
}
.my-care-resources .action-section .btn-link {
  background-color: #363534;
  border-color: #363534;
  color: #fff;
  font-weight: normal;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  width: 100%;
}
.my-care-resources .action-section .btn-link:hover {
  text-decoration: none;
  background-color: #363534;
  color: #fff;
}
.my-care-resources .link-box {
  background-color: #fff;
  border: 1px solid grey;
  margin: 0.5rem 0.5rem 0rem 0.5rem;
  padding: 1rem;
}
.my-care-resources .link-box p {
  margin-bottom: 0rem;
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
}
@media (min-width: 768px) {
  .my-care-resources {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .my-care-resources .link-box {
    background-color: #fff;
    margin: 0.5rem 0.5rem 0rem 0.5rem;
    padding: 1rem;
  }
}
@media print and (min-width: 6px) {
  .my-care-resources {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .my-care-resources .link-box {
    background-color: #fff;
    margin: 0.5rem 0.5rem 0rem 0.5rem;
    padding: 1rem;
  }
}
@media (min-width: 1280px) {
  .my-care-resources {
    margin-top: 0rem;
  }
}
@media print and (min-width: 7px) {
  .my-care-resources {
    margin-top: 0rem;
  }
}

/* Styleguide */
.grid-example {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
}
@media (min-width: 768px) {
  .grid-example {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .grid-example {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .grid-example {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .grid-example {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .grid-example {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .grid-example {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.grid-example .grid-col:nth-child(1) {
  background-color: rgba(0, 117, 154, 0.1);
}
.grid-example .grid-col:nth-child(2) {
  background-color: rgba(0, 117, 154, 0.2);
}
.grid-example .grid-col:nth-child(3) {
  background-color: rgba(0, 117, 154, 0.3);
}
.grid-example .grid-col:nth-child(4) {
  background-color: rgba(0, 117, 154, 0.4);
}
.grid-example .grid-col:nth-child(5) {
  background-color: rgba(0, 117, 154, 0.5);
}
.grid-example .grid-col:nth-child(6) {
  background-color: rgba(0, 117, 154, 0.6);
}
.grid-example .grid-col:nth-child(7) {
  background-color: rgba(0, 117, 154, 0.7);
}
.grid-example .grid-col:nth-child(8) {
  background-color: rgba(0, 117, 154, 0.8);
}
.grid-example .grid-col:nth-child(9) {
  background-color: rgba(0, 117, 154, 0.9);
}
.grid-example .grid-col:nth-child(10) {
  background-color: #00759a;
}
.grid-example .grid-col:nth-child(11) {
  background-color: #006281;
}
.grid-example .grid-col:nth-child(12) {
  background-color: #004e67;
}
.grid-example .grid-content {
  color: #fff;
  height: 100%;
  padding: 1rem;
}
.grid-example p {
  margin-bottom: 0;
}
@media (min-width: 360px) {
  .grid-example .grid-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  .grid-example .grid-col {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 10px;
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
    padding: 0 10px;
    background-color: #eee;
    height: 100%;
  }
  .grid-example .example-three .grid-col {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .grid-example .example-seven .grid-col {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0;
    padding: 0 10px;
  }
}
@media print and (min-width: 3px) {
  .grid-example .grid-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  .grid-example .grid-col {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 10px;
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
    padding: 0 10px;
    background-color: #eee;
    height: 100%;
  }
  .grid-example .example-three .grid-col {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .grid-example .example-seven .grid-col {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0;
    padding: 0 10px;
  }
}
@media (min-width: 414px) {
  .grid-example .grid-col {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .grid-example .example-two .grid-col {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .grid-example .example-three .grid-col {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .grid-example .example-four .grid-col {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .grid-example .example-five .grid-col:nth-child(1) {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .grid-example .example-five .grid-col:nth-child(2) {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .grid-example .example-six .grid-col:nth-child(1) {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .grid-example .example-six .grid-col:nth-child(2) {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
}
@media print and (min-width: 5px) {
  .grid-example .grid-col {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .grid-example .example-two .grid-col {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .grid-example .example-three .grid-col {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .grid-example .example-four .grid-col {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .grid-example .example-five .grid-col:nth-child(1) {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .grid-example .example-five .grid-col:nth-child(2) {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .grid-example .example-six .grid-col:nth-child(1) {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .grid-example .example-six .grid-col:nth-child(2) {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
}
@media (min-width: 768px) {
  .grid-example aside {
    background-color: rgba(72, 185, 199, 0.4);
  }
  .grid-example .grid-col {
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .grid-example .example-two .grid-col {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
}
@media print and (min-width: 6px) {
  .grid-example aside {
    background-color: rgba(72, 185, 199, 0.4);
  }
  .grid-example .grid-col {
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .grid-example .example-two .grid-col {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
}
@media (min-width: 1280px) {
  .grid-example .grid-col {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
}
@media print and (min-width: 7px) {
  .grid-example .grid-col {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
}
@media (min-width: 1440px) {
  .grid-example .grid-col {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .grid-example .example-two .grid-col {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .grid-example .example-three .grid-col {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
}
@media print and (min-width: 1440px) {
  .grid-example .grid-col {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .grid-example .example-two .grid-col {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .grid-example .example-three .grid-col {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
}

.grid-title {
  margin-bottom: 2rem;
}
.grid-title .grid-title-inner {
  margin-right: auto;
  margin-left: auto;
  max-width: calc(1440px - 30px * 2);
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  align-items: center;
  background-color: #eee;
  color: #000;
  display: flex;
}
@media (min-width: 768px) {
  .grid-title .grid-title-inner {
    max-width: calc(
			1440px - 30px * 2
		);
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .grid-title .grid-title-inner {
    max-width: calc(
			1440px - 30px * 2
		);
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .grid-title .grid-title-inner {
    max-width: calc(
			1440px - 70px * 2
		);
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .grid-title .grid-title-inner {
    max-width: calc(
			1440px - 70px * 2
		);
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .grid-title .grid-title-inner {
    max-width: calc(
			1440px - 70px * 2
		);
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .grid-title .grid-title-inner {
    max-width: calc(
			1440px - 70px * 2
		);
    padding-right: 70px;
    padding-left: 70px;
  }
}
.grid-title h2 {
  font-size: 2.5rem;
  line-height: 1;
  margin-bottom: 1rem;
}
.grid-title h2:after {
  font-size: 1.375rem;
  line-height: 1.4545454545;
  content: "XXS: <360px";
  display: block;
  margin-top: 2px;
}
@media (min-width: 360px) {
  .grid-title h2:after {
    content: "XS Breakpoint: ≥360px";
  }
}
@media print and (min-width: 3px) {
  .grid-title h2:after {
    content: "XS Breakpoint: ≥360px";
  }
}
@media (min-width: 414px) {
  .grid-title h2:after {
    content: "SM Breakpoint: ≥414px";
  }
}
@media print and (min-width: 5px) {
  .grid-title h2:after {
    content: "SM Breakpoint: ≥414px";
  }
}
@media (min-width: 768px) {
  .grid-title h2:after {
    content: "MD Breakpoint: ≥768px";
  }
}
@media print and (min-width: 6px) {
  .grid-title h2:after {
    content: "MD Breakpoint: ≥768px";
  }
}
@media (min-width: 1280px) {
  .grid-title h2:after {
    content: "LG Breakpoint: ≥1280px";
  }
}
@media print and (min-width: 7px) {
  .grid-title h2:after {
    content: "LG Breakpoint: ≥1280px";
  }
}
@media (min-width: 1440px) {
  .grid-title h2:after {
    content: "XL Breakpoint: ≥1440px";
  }
}
@media print and (min-width: 1440px) {
  .grid-title h2:after {
    content: "XL Breakpoint: ≥1440px";
  }
}

.styleguide-section-heading {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
}
@media (min-width: 768px) {
  .styleguide-section-heading {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .styleguide-section-heading {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .styleguide-section-heading {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .styleguide-section-heading {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .styleguide-section-heading {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .styleguide-section-heading {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.styleguide-section-heading h3 {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.styleguide-section-heading hr {
  border-top: 0;
  border-bottom: 1px solid #595756;
  border-style: solid;
  outline: 0;
}

/* Technology Test */
.image-summary {
  display: flex;
  flex-direction: column;
}
.image-summary.image-top .image-block {
  margin-bottom: 1.5rem;
}
.image-summary.image-top .image-block .image-container:not(:last-child) {
  margin-bottom: 1.5rem;
}
.image-summary .image-block {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 3rem;
  position: relative;
}
.image-summary .image-block.full-width {
  padding: 0;
}
.image-summary .image-block .image-container {
  height: 100%;
  position: relative;
  text-align: center;
  width: 100%;
}
.image-summary .image-block .image-container .hide-for-android {
  margin-top: -1rem;
  margin-bottom: -1rem;
}
.image-summary .image-block img {
  margin: auto;
  max-height: 500px;
}
.image-summary .image-block .image-caption {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  margin: -1rem 0 2rem -3rem;
  text-align: center;
  width: calc(100% + 6rem);
}
.is-android .image-summary .image-block .image-caption {
  margin-top: 0;
}
.image-summary .image-block .mobile-links {
  width: calc(100% + 6rem);
}
.image-summary .content-block > :last-child {
  margin-bottom: 0;
}
.image-summary .content-block .mobile-trim {
  margin-bottom: 0;
}
.image-summary .content-block .cta-link {
  margin-bottom: 0.25rem;
}
.image-summary .desktop-links {
  display: none;
}
.image-summary .title-secondary + .subtitle {
  margin-top: -0.75rem;
}
.image-summary .subtitle {
  margin-bottom: 0.25rem;
}
.image-summary .cta-link-wrapper {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  color: #000;
  margin-bottom: 0.25rem;
}
.image-summary .cta-link-wrapper .cta-link {
  display: inline;
  margin: 0;
}
.image-summary .btn-group {
  padding-top: 2rem;
}
.image-summary .btn {
  width: 100%;
}
@media (min-width: 768px) {
  .image-summary {
    align-items: center;
    flex-direction: row-reverse;
    justify-content: center;
    margin-top: 0;
    padding-bottom: 5.75rem;
    padding-top: 2rem;
  }
  .image-summary.image-top .image-block {
    margin-bottom: 0;
  }
  .image-summary.image-top.image-left {
    flex-direction: row;
    justify-content: flex-start;
  }
  .image-summary.half {
    justify-content: center;
    padding-top: 4.5rem;
  }
  .image-summary.half .image-block {
    flex-basis: 510px;
    max-width: 510px;
    padding: 0 1.25rem 0 1.75rem;
  }
  .image-summary.half .content-block {
    flex-basis: 510px;
    max-width: 510px;
    padding: 0 0 0 3rem;
  }
  .image-summary.half.image-top .image-block {
    align-items: center;
  }
  .image-summary.half.image-top .content-block {
    flex-basis: 510px;
    max-width: 510px;
    padding: 0 3rem 0 0;
  }
  .image-summary .image-block {
    align-items: flex-end;
    margin-top: 0;
    max-width: 650px;
    padding: 0 5rem 0 4rem;
  }
  .image-summary .image-block .image-caption {
    margin-top: -1.5rem;
    position: absolute;
    top: 100%;
  }
  .is-android .image-summary .image-block .image-caption {
    margin-top: 0;
  }
  .image-summary .image-block .image-container .hide-for-android {
    margin-top: 0;
  }
  .image-summary .content-block {
    flex: 1 1 0;
    flex-basis: 460px;
    max-width: 460px;
  }
  .image-summary .content-block .mobile-trim {
    margin-bottom: 1.5rem;
  }
  .image-summary .mobile-links {
    display: none;
  }
  .image-summary .desktop-links {
    display: block;
  }
  .image-summary .title-secondary + .subtitle {
    margin-top: 0;
  }
  .image-summary .subtitle {
    margin-bottom: 1rem;
  }
  .image-summary .btn {
    width: auto;
  }
  .image-summary .form-label {
    text-align: left;
  }
}
@media print and (min-width: 6px) {
  .image-summary {
    align-items: center;
    flex-direction: row-reverse;
    justify-content: center;
    margin-top: 0;
    padding-bottom: 5.75rem;
    padding-top: 2rem;
  }
  .image-summary.image-top .image-block {
    margin-bottom: 0;
  }
  .image-summary.image-top.image-left {
    flex-direction: row;
    justify-content: flex-start;
  }
  .image-summary.half {
    justify-content: center;
    padding-top: 4.5rem;
  }
  .image-summary.half .image-block {
    flex-basis: 510px;
    max-width: 510px;
    padding: 0 1.25rem 0 1.75rem;
  }
  .image-summary.half .content-block {
    flex-basis: 510px;
    max-width: 510px;
    padding: 0 0 0 3rem;
  }
  .image-summary.half.image-top .image-block {
    align-items: center;
  }
  .image-summary.half.image-top .content-block {
    flex-basis: 510px;
    max-width: 510px;
    padding: 0 3rem 0 0;
  }
  .image-summary .image-block {
    align-items: flex-end;
    margin-top: 0;
    max-width: 650px;
    padding: 0 5rem 0 4rem;
  }
  .image-summary .image-block .image-caption {
    margin-top: -1.5rem;
    position: absolute;
    top: 100%;
  }
  .is-android .image-summary .image-block .image-caption {
    margin-top: 0;
  }
  .image-summary .image-block .image-container .hide-for-android {
    margin-top: 0;
  }
  .image-summary .content-block {
    flex: 1 1 0;
    flex-basis: 460px;
    max-width: 460px;
  }
  .image-summary .content-block .mobile-trim {
    margin-bottom: 1.5rem;
  }
  .image-summary .mobile-links {
    display: none;
  }
  .image-summary .desktop-links {
    display: block;
  }
  .image-summary .title-secondary + .subtitle {
    margin-top: 0;
  }
  .image-summary .subtitle {
    margin-bottom: 1rem;
  }
  .image-summary .btn {
    width: auto;
  }
  .image-summary .form-label {
    text-align: left;
  }
}
@media (min-width: 1280px) {
  .image-summary {
    justify-content: flex-end;
  }
  .image-summary .image-block {
    min-width: 530px;
  }
}
@media print and (min-width: 7px) {
  .image-summary {
    justify-content: flex-end;
  }
  .image-summary .image-block {
    min-width: 530px;
  }
}

.featured-images {
  overflow: hidden;
}
.featured-images .title-primary {
  margin-bottom: 0.5rem;
  text-align: center;
  width: 100%;
}
.featured-images .title-secondary {
  margin: auto;
  margin-bottom: 0.25rem;
}
.featured-images .description {
  margin: auto;
  margin-bottom: 0.25rem;
}
.featured-images .cta-buttons {
  display: flex;
  justify-content: space-between;
}
.featured-images .cta-left,
.featured-images .cta-right {
  max-width: 200px;
  width: calc(50% - 0.375rem);
}
.featured-images .images-container {
  align-items: center;
  display: flex;
  flex: 1 1 0;
  justify-content: center;
  margin-bottom: 2rem;
  margin-left: -16%;
  position: relative;
  width: 128%;
}
.featured-images .mobile-image {
  z-index: 1;
}
.featured-images .mobile-image img {
  margin-left: 35.5%;
  max-height: 445px;
}
.is-android .featured-images .mobile-image img {
  margin-top: 5%;
  max-height: 400px;
}
.featured-images .desktop-image img {
  margin-top: 4.5%;
  margin-left: -11.5%;
  max-height: 352px;
}
@media (min-width: 768px) {
  .featured-images .featured-images-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 10px;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0;
    padding: 0 10px;
    margin: auto;
  }
  .featured-images .centered-container {
    align-items: center;
    display: flex;
    justify-content: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  .featured-images .images-container {
    margin: 0 auto;
    width: 100%;
  }
  .featured-images .desktop-image {
    margin-right: -2%;
  }
  .featured-images .cta-left,
.featured-images .cta-right {
    align-items: center;
    display: flex;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 140px;
    z-index: 1;
  }
  .featured-images .cta-left.description-cta,
.featured-images .cta-right.description-cta {
    width: 240px;
  }
  .featured-images .cta-right {
    left: auto;
    right: 0;
  }
}
@media print and (min-width: 6px) {
  .featured-images .featured-images-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 10px;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0;
    padding: 0 10px;
    margin: auto;
  }
  .featured-images .centered-container {
    align-items: center;
    display: flex;
    justify-content: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  .featured-images .images-container {
    margin: 0 auto;
    width: 100%;
  }
  .featured-images .desktop-image {
    margin-right: -2%;
  }
  .featured-images .cta-left,
.featured-images .cta-right {
    align-items: center;
    display: flex;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 140px;
    z-index: 1;
  }
  .featured-images .cta-left.description-cta,
.featured-images .cta-right.description-cta {
    width: 240px;
  }
  .featured-images .cta-right {
    left: auto;
    right: 0;
  }
}
@media (min-width: 1280px) {
  .featured-images .featured-images-wrapper {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .featured-images .images-container {
    max-width: 100%;
  }
}
@media print and (min-width: 7px) {
  .featured-images .featured-images-wrapper {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .featured-images .images-container {
    max-width: 100%;
  }
}

.t3-main-navigation {
  display: flex;
}
.modal-open .t3-main-navigation {
  display: none;
}
.t3-main-navigation .icon-menu-open {
  fill: #363534;
}
.t3-main-navigation .icon-menu-close {
  position: relative;
  stroke: #363534;
  stroke-width: 3px;
}
.t3-main-navigation .full-nav {
  display: none;
}
.t3-main-navigation .nav-open,
.t3-main-navigation .nav-close {
  background-color: transparent;
  border: 0;
  line-height: 0;
  padding: 0;
}
@media (min-width: 890px) {
  .t3-main-navigation {
    align-self: flex-end;
  }
  .t3-main-navigation .full-nav {
    align-self: flex-start;
    display: flex;
    overflow: hidden;
    padding-top: 16px;
  }
  .t3-main-navigation .nav-open {
    display: none;
  }
  .t3-main-navigation .nav-link::after {
    background-color: #00b0e6;
  }
  .t3-main-navigation .nav-link.active {
    color: #00759a;
  }
  .t3-main-navigation .nav-link:last-of-type {
    padding-right: 0;
  }
}

/* Appointment Tracker */
.at-footer-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  background-color: #363534;
  color: #fafafa;
  padding-bottom: 52px;
  padding-top: 35px;
}
@media (min-width: 768px) {
  .at-footer-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .at-footer-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .at-footer-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .at-footer-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .at-footer-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .at-footer-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.mobile-nav .at-footer-container .back-to-top-btn {
  display: none;
}
.at-footer-container a {
  color: #fafafa;
}
.at-footer-container .assistance-title {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.3333333333;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  color: #fafafa;
  text-align: left;
  display: flex;
}
.at-footer-container .back-to-top-btn {
  align-items: center;
  display: flex;
  padding: 0;
}
.at-footer-container .back-to-top-btn .icon-arrow-down {
  stroke: #fafafa;
  stroke-width: 4px;
  transform: rotate(180deg);
}
.at-footer-container .customer-service {
  margin-bottom: 32px;
}
.at-footer-container .customer-service p {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  margin-bottom: 0;
}
.at-footer-container .customer-service-desktop {
  display: none;
}
.at-footer-container .doximity-section {
  margin-top: 2rem;
}
.at-footer-container .doximity-section p {
  font-size: 1rem;
  line-height: 1.375;
}
.at-footer-container .link-list {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  columns: 2;
}
.at-footer-container .link-list li {
  margin-bottom: 5px;
}
.at-footer-container .help-title {
  display: none;
}
@media (min-width: 768px) {
  .at-footer-container {
    display: flex;
  }
  .at-footer-container div {
    flex: 1 1 auto;
  }
  .at-footer-container .back-to-top-btn {
    display: none;
  }
  .at-footer-container .help-title {
    color: #00b0e6;
    display: block;
  }
  .at-footer-container .customer-service {
    margin-bottom: 0;
    padding-right: 50px;
  }
  .at-footer-container .customer-service-desktop {
    display: block;
  }
  .at-footer-container .customer-service-mobile {
    display: none;
  }
  .at-footer-container .doximity-section {
    margin-top: 20px;
  }
  .at-footer-container .assistance-title {
    display: block;
    flex: 0 0 20%;
    margin-bottom: 0;
    padding-right: 1rem;
  }
  .at-footer-container p {
    margin-bottom: 0.2rem;
  }
}
@media print and (min-width: 6px) {
  .at-footer-container {
    display: flex;
  }
  .at-footer-container div {
    flex: 1 1 auto;
  }
  .at-footer-container .back-to-top-btn {
    display: none;
  }
  .at-footer-container .help-title {
    color: #00b0e6;
    display: block;
  }
  .at-footer-container .customer-service {
    margin-bottom: 0;
    padding-right: 50px;
  }
  .at-footer-container .customer-service-desktop {
    display: block;
  }
  .at-footer-container .customer-service-mobile {
    display: none;
  }
  .at-footer-container .doximity-section {
    margin-top: 20px;
  }
  .at-footer-container .assistance-title {
    display: block;
    flex: 0 0 20%;
    margin-bottom: 0;
    padding-right: 1rem;
  }
  .at-footer-container p {
    margin-bottom: 0.2rem;
  }
}
@media (min-width: 1280px) {
  .at-footer-container .customer-service {
    padding-right: 100px;
  }
}
@media print and (min-width: 7px) {
  .at-footer-container .customer-service {
    padding-right: 100px;
  }
}

.title-banner {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  padding-bottom: 2rem;
}
@media (min-width: 768px) {
  .title-banner {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .title-banner {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .title-banner {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .title-banner {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .title-banner {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .title-banner {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.title-banner.ready .appointment-confirm .appointment-soon {
  display: none;
}
.title-banner.ready .appointment-confirm .appointment-complete {
  display: inline;
}
.title-banner .icon-success {
  height: 30px;
  width: 30px;
}
.title-banner .back-link {
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25;
  align-items: center;
  color: #595756;
  display: inline-flex;
  text-decoration: none;
  margin-left: -2rem;
  margin-top: -1.5rem;
}
.title-banner .back-link svg {
  stroke: #595756;
  margin-top: -3px;
  transition: all 0.2s ease-in-out;
}
.title-banner .back-link:hover svg, .title-banner .back-link:focus svg {
  transform: translateX(-5px);
}
.title-banner .title-container {
  margin-bottom: 1.8rem;
  margin-top: 2rem;
}
.title-banner .title {
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 2.5rem;
  line-height: 1;
  color: #a71930;
  margin-bottom: 2rem;
}
.title-banner .title-info {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.3333333333;
  color: #000;
}
.title-banner .confirm-container {
  flex-wrap: wrap;
  margin-bottom: 2.25rem;
}
.title-banner .appointment-confirm {
  background-color: #fff;
  flex-wrap: wrap;
}
.title-banner .appointment-confirm .row-primary {
  padding: 1.8rem 1.2rem;
}
.title-banner .appointment-confirm .appointment-complete {
  display: none;
}
.title-banner .appointment-confirm .appointment-time {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.3333333333;
  color: #363534;
  margin-bottom: 0;
}
.title-banner .appointment-confirm p {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  color: #363534;
}
.title-banner .appointment-confirm p:last-of-type {
  margin-bottom: 0;
}
.title-banner .banner-btn {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.2222222222;
  align-items: center;
  background-color: #00b0e6;
  color: #000;
  display: flex;
  margin-bottom: 1.25rem;
  padding: 30px 20px;
  width: 100%;
  text-decoration: none;
}
.title-banner .banner-btn:hover, .title-banner .banner-btn:focus {
  background-color: #000;
  color: #fff;
}
.title-banner .sections {
  background-color: #fff;
  padding: 1.8rem 1.2rem;
}
.title-banner .sections.on-time {
  border-bottom: 4px solid #74c15f;
}
.title-banner .sections.on-time .appointment-eta {
  background-color: #74c15f;
  color: #000;
}
.title-banner .sections.on-time .scheduled-time {
  color: #498d37;
}
.title-banner .sections.delayed {
  border-bottom: 4px solid #f09135;
}
.title-banner .sections.delayed .appointment-eta {
  background-color: #f09135;
  color: #000;
}
.title-banner .sections.delayed .estimated-time,
.title-banner .sections.delayed .delay {
  color: #b5600e;
}
.title-banner .sections.delayed .scheduled-time {
  font-size: 0.75rem;
  line-height: 1.5;
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  text-decoration: line-through;
}
.title-banner .appointment-eta {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: center;
  margin: -1.8rem -1.2rem 1.8rem -1.2rem;
  width: calc(100% + 2.4rem);
}
.title-banner .section-block {
  color: #000;
  margin-bottom: 2rem;
}
.title-banner .section-block a {
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25;
  color: #00759a;
  text-decoration: none;
}
.title-banner .section-block a:hover, .title-banner .section-block a:focus {
  text-decoration: underline;
}
.title-banner .section-block:last-of-type {
  margin-bottom: 0;
}
.title-banner .section-block-inner {
  display: flex;
}
.title-banner .section-block-img {
  height: 105px;
  min-width: 105px;
  overflow: hidden;
  position: relative;
  flex-basis: 105px;
  width: 105px;
  margin-right: 1rem;
}
.title-banner .section-block-img img {
  height: 100%;
  object-fit: cover;
  object-position: center 25%;
  font-family: "object-fit: cover; object-position: center 25%;";
  width: 100%;
}
.title-banner .section-block-content {
  flex: 1;
}
.title-banner .section-type {
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25;
  margin-bottom: 0.25rem;
}
.title-banner .section-title,
.title-banner .appointment-type {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25;
  margin-bottom: 0;
}
.title-banner .section-title .delay,
.title-banner .appointment-type .delay {
  font-size: 0.875rem;
  line-height: 1.4285714286;
}
.title-banner .appointment-type {
  font-size: 0.75rem;
  line-height: 1.5;
  color: #707070;
  margin-bottom: 1.5rem;
  text-transform: capitalize;
}
.title-banner .section-subtitle {
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25;
  margin-bottom: 0;
}
.title-banner .section-help {
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25;
  margin-bottom: 0;
  margin-top: 1.5rem;
}
@media (min-width: 768px) {
  .title-banner {
    margin-top: -2rem;
  }
  .title-banner .back-link {
    font-family: AvenirHeavy, Helvetica, sans-serif;
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 1.2222222222;
  }
  .title-banner .back-link svg {
    height: 70px;
    width: 70px;
  }
  .title-banner .back-link:hover svg, .title-banner .back-link:focus svg {
    transform: translateX(-8px);
  }
  .title-banner .back-link + .title-container {
    margin-top: 1rem;
  }
  .title-banner .title-container {
    display: flex;
    justify-content: space-between;
  }
  .title-banner .title {
    margin-bottom: 0;
  }
  .title-banner .title-info {
    text-align: right;
  }
  .title-banner .confirm-container .appointment-confirm {
    display: flex;
    flex-wrap: nowrap;
  }
  .title-banner .confirm-container .appointment-confirm .appointment-time,
.title-banner .confirm-container .appointment-confirm p {
    font-size: 1.5rem;
    line-height: 1.3333333333;
  }
  .title-banner .confirm-container .appointment-confirm .row-primary-button {
    margin: 0 0 0 auto;
    padding: 1rem 1.25rem;
    width: 285px;
  }
  .title-banner .sections {
    display: flex;
    padding-top: 2.75rem;
    position: relative;
  }
  .title-banner .sections.on-time {
    border-top: 4px solid #74c15f;
  }
  .title-banner .sections.delayed {
    border-top: 4px solid #f09135;
  }
  .title-banner .sections .section-block {
    flex: 0 0 50%;
    margin-bottom: 0;
    max-width: 50%;
  }
  .title-banner .appointment-eta {
    margin: -4px 0 0 -1.2rem;
    position: absolute;
    top: 0;
    width: auto;
    width: 95px;
  }
  .title-banner .section-title {
    font-size: 1.25rem;
    line-height: 1.2;
  }
  .title-banner .office-half {
    display: flex;
  }
  .title-banner .office-half > div {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media print and (min-width: 6px) {
  .title-banner {
    margin-top: -2rem;
  }
  .title-banner .back-link {
    font-family: AvenirHeavy, Helvetica, sans-serif;
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 1.2222222222;
  }
  .title-banner .back-link svg {
    height: 70px;
    width: 70px;
  }
  .title-banner .back-link:hover svg, .title-banner .back-link:focus svg {
    transform: translateX(-8px);
  }
  .title-banner .back-link + .title-container {
    margin-top: 1rem;
  }
  .title-banner .title-container {
    display: flex;
    justify-content: space-between;
  }
  .title-banner .title {
    margin-bottom: 0;
  }
  .title-banner .title-info {
    text-align: right;
  }
  .title-banner .confirm-container .appointment-confirm {
    display: flex;
    flex-wrap: nowrap;
  }
  .title-banner .confirm-container .appointment-confirm .appointment-time,
.title-banner .confirm-container .appointment-confirm p {
    font-size: 1.5rem;
    line-height: 1.3333333333;
  }
  .title-banner .confirm-container .appointment-confirm .row-primary-button {
    margin: 0 0 0 auto;
    padding: 1rem 1.25rem;
    width: 285px;
  }
  .title-banner .sections {
    display: flex;
    padding-top: 2.75rem;
    position: relative;
  }
  .title-banner .sections.on-time {
    border-top: 4px solid #74c15f;
  }
  .title-banner .sections.delayed {
    border-top: 4px solid #f09135;
  }
  .title-banner .sections .section-block {
    flex: 0 0 50%;
    margin-bottom: 0;
    max-width: 50%;
  }
  .title-banner .appointment-eta {
    margin: -4px 0 0 -1.2rem;
    position: absolute;
    top: 0;
    width: auto;
    width: 95px;
  }
  .title-banner .section-title {
    font-size: 1.25rem;
    line-height: 1.2;
  }
  .title-banner .office-half {
    display: flex;
  }
  .title-banner .office-half > div {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 1280px) {
  .title-banner .back-link {
    margin-left: -70px;
  }
}
@media print and (min-width: 7px) {
  .title-banner .back-link {
    margin-left: -70px;
  }
}

.ready-snack-bar {
  background-color: #595756;
  color: #fff;
  display: none;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1rem 2rem 1rem 1.25rem;
  width: 100%;
}
.getting-ready .ready-snack-bar {
  display: flex;
}
.ready-snack-bar .ready-message {
  align-items: flex-start;
  display: flex;
  margin-bottom: 2rem;
  width: 100%;
}
.ready-snack-bar .icon-information {
  fill: #fff;
  height: 25px;
  margin-right: 0.5rem;
  width: 50px;
}
.ready-snack-bar p {
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.4285714286;
  margin-bottom: 0;
}
.ready-snack-bar .countdown {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
}
.ready-snack-bar .ready-action {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
}
.ready-snack-bar .ready-action .btn {
  margin: 0 0.2rem 1.5rem;
  min-width: 126px;
  padding: 0.625rem 0.425rem;
}
.ready-snack-bar .ready-action .btn-secondary {
  background-color: transparent;
  border-color: #fff;
  border-width: 2px;
  color: #fff;
}
.ready-snack-bar .ready-action .btn-secondary:hover {
  background-color: #fff;
  color: #363534;
}
@media (min-width: 768px) {
  .ready-snack-bar {
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
  }
  .ready-snack-bar .ready-message {
    align-items: center;
    flex-grow: 1;
    margin-bottom: 0;
    padding-right: 0.5rem;
  }
  .ready-snack-bar .ready-action {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    width: 324px;
  }
  .ready-snack-bar .ready-action .btn {
    margin: 0;
  }
  .ready-snack-bar .ready-action .btn-primary {
    margin-right: 0.4rem;
  }
}
@media print and (min-width: 6px) {
  .ready-snack-bar {
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
  }
  .ready-snack-bar .ready-message {
    align-items: center;
    flex-grow: 1;
    margin-bottom: 0;
    padding-right: 0.5rem;
  }
  .ready-snack-bar .ready-action {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    width: 324px;
  }
  .ready-snack-bar .ready-action .btn {
    margin: 0;
  }
  .ready-snack-bar .ready-action .btn-primary {
    margin-right: 0.4rem;
  }
}

.row-primary-button {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  padding: 1.08rem 2rem 0.83rem 1.25rem;
  text-decoration: none;
  width: 100%;
}
.row-primary-button:hover {
  background-color: #363534;
  color: #fff;
}
.row-primary-button:hover .appointment-type {
  color: #eee;
}
.row-primary-button:hover .icon-office-visit,
.row-primary-button:hover .icon-phone-visit,
.row-primary-button:hover .icon-video-visit,
.row-primary-button:hover .icon-arrow-right {
  fill: #fff;
  stroke: #fff;
}
.row-primary-button:hover .icon-action-needed {
  border: 2px solid #fff;
}
.row-primary-button:hover.on-time .estimated-time,
.row-primary-button:hover.on-time .scheduled-time,
.row-primary-button:hover.on-time .delay, .row-primary-button:hover.delayed .estimated-time,
.row-primary-button:hover.delayed .scheduled-time,
.row-primary-button:hover.delayed .delay {
  color: #fff;
}
.row-primary-button.on-time .appointment-eta {
  background-color: #74c15f;
  color: #000;
}
.row-primary-button.on-time .scheduled-time {
  color: #498d37;
}
.row-primary-button.delayed .appointment-eta {
  background-color: #f09135;
  color: #000;
}
.row-primary-button.delayed .estimated-time,
.row-primary-button.delayed .delay {
  color: #b5600e;
}
.row-primary-button.delayed .appointment-date .scheduled-time {
  font-size: 0.75rem;
  line-height: 1.5;
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  text-decoration: line-through;
}
.row-primary-button .appointment-eta {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: center;
  margin: -1.08rem -2rem 1.75rem -1.25rem;
  width: calc(100% + 3.25rem);
}
.row-primary-button .row-text {
  text-align: left;
  width: 100%;
}
.row-primary-button .row-icon-right {
  display: flex;
  margin: auto 0 6px auto;
}
.row-primary-button.row-secondary-button {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  background-color: #00b0e6;
  color: #000;
  min-height: 80px;
}
.row-primary-button.row-secondary-button:hover {
  background-color: #000;
  color: #fff;
}
.row-primary-button.row-secondary-button:hover .icon-arrow-right {
  stroke: #fff;
}
.row-primary-button.row-secondary-button.action-complete {
  align-items: center;
  background-color: #fff;
  border-top: 4px solid #595756;
  color: #595756;
  pointer-events: none;
}
.row-primary-button.row-secondary-button.action-complete .row-icon-success {
  display: inline;
  height: 30px;
  padding-right: 25px;
}
.row-primary-button.row-secondary-button.action-complete .row-icon-right {
  display: none;
}
.row-primary-button.row-secondary-button.action-complete .icon-success {
  fill: #595756;
  stroke: #fff;
}
.row-primary-button.row-secondary-button .row-icon-success {
  display: none;
}
.row-primary-button.row-secondary-button .row-text {
  flex: 1;
  margin-bottom: 0;
  min-width: 0;
}
.row-primary-button.row-secondary-button .row-icon-right {
  display: flex;
  margin: auto 0 auto auto;
}
@media (min-width: 768px) {
  .row-primary-button {
    align-items: center;
    flex-wrap: nowrap;
    min-height: 120px;
    padding: 1.875rem 2rem 1.875rem 0;
    position: relative;
  }
  .row-primary-button.row-secondary-button {
    padding: 1.25rem 2.8125rem 1.25rem 1.25rem;
    width: 360px;
  }
  .row-primary-button.on-time {
    border-top: 0.25rem solid #74c15f;
    padding-top: 1.625rem;
  }
  .row-primary-button.delayed {
    border-top: 0.25rem solid #f09135;
    padding-top: 1.625rem;
  }
  .row-primary-button .appointment-eta {
    margin: -4px 0 0 0;
    position: absolute;
    top: 0;
    width: auto;
    width: 95px;
  }
  .row-primary-button .row-text {
    order: 2;
  }
  .row-primary-button .row-icon {
    display: flex;
    flex: 0 0 113px;
    justify-content: center;
    max-width: 113px;
    order: 1;
  }
  .row-primary-button .row-icon-right {
    margin: auto 0 auto auto;
    order: 3;
  }
}
@media print and (min-width: 6px) {
  .row-primary-button {
    align-items: center;
    flex-wrap: nowrap;
    min-height: 120px;
    padding: 1.875rem 2rem 1.875rem 0;
    position: relative;
  }
  .row-primary-button.row-secondary-button {
    padding: 1.25rem 2.8125rem 1.25rem 1.25rem;
    width: 360px;
  }
  .row-primary-button.on-time {
    border-top: 0.25rem solid #74c15f;
    padding-top: 1.625rem;
  }
  .row-primary-button.delayed {
    border-top: 0.25rem solid #f09135;
    padding-top: 1.625rem;
  }
  .row-primary-button .appointment-eta {
    margin: -4px 0 0 0;
    position: absolute;
    top: 0;
    width: auto;
    width: 95px;
  }
  .row-primary-button .row-text {
    order: 2;
  }
  .row-primary-button .row-icon {
    display: flex;
    flex: 0 0 113px;
    justify-content: center;
    max-width: 113px;
    order: 1;
  }
  .row-primary-button .row-icon-right {
    margin: auto 0 auto auto;
    order: 3;
  }
}

.pls-footer-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  background-color: #363534;
  color: #fafafa;
  padding-bottom: 52px;
  padding-top: 35px;
  position: relative;
}
@media (min-width: 768px) {
  .pls-footer-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .pls-footer-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .pls-footer-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .pls-footer-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .pls-footer-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .pls-footer-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.pls-footer-container .link-list > li > a {
  color: #fafafa;
}
.pls-footer-container a {
  color: #fafafa;
}
.pls-footer-container .back-to-top-container {
  margin-bottom: 24px;
  display: flex;
  position: absolute;
  top: 35px;
  right: 30px;
}
.mobile-nav .pls-footer-container .back-to-top-container {
  display: none;
}
.pls-footer-container .back-to-top-btn {
  align-items: center;
  display: flex;
  margin-left: auto;
  padding: 0;
}
.pls-footer-container .back-to-top-btn .icon-arrow-down {
  stroke: #fafafa;
  stroke-width: 4px;
  transform: rotate(180deg);
}
.pls-footer-container .copyright {
  text-align: left;
  margin-bottom: 32px;
  padding-right: 5rem;
}
.pls-footer-container .copyright p {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  margin-bottom: 0;
  color: inherit;
}
.pls-footer-container .link-list {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  columns: 2;
}
.pls-footer-container .link-list li {
  margin-bottom: 5px;
}
.pls-footer-container .legal-description {
  font-size: 0.75rem;
  line-height: 1.5;
  margin-bottom: 0;
  margin-top: 2rem;
}
@media (min-width: 768px) {
  .pls-footer-container {
    display: flex;
  }
  .pls-footer-container div {
    flex: 1 1 auto;
  }
  .pls-footer-container .legal-content {
    flex: 0 1 40%;
  }
  .pls-footer-container .back-to-top-container {
    display: none;
  }
  .pls-footer-container .back-to-top-btn {
    display: none;
  }
  .pls-footer-container .copyright {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    padding-right: 50px;
  }
  .pls-footer-container .copyright .tech-support {
    margin-bottom: 0;
  }
  .pls-footer-container .tech-support {
    margin-top: auto;
  }
  .pls-footer-container .tech-support span {
    display: block;
  }
  .pls-footer-container p {
    margin-bottom: 0.2rem;
  }
}
@media print and (min-width: 6px) {
  .pls-footer-container {
    display: flex;
  }
  .pls-footer-container div {
    flex: 1 1 auto;
  }
  .pls-footer-container .legal-content {
    flex: 0 1 40%;
  }
  .pls-footer-container .back-to-top-container {
    display: none;
  }
  .pls-footer-container .back-to-top-btn {
    display: none;
  }
  .pls-footer-container .copyright {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    padding-right: 50px;
  }
  .pls-footer-container .copyright .tech-support {
    margin-bottom: 0;
  }
  .pls-footer-container .tech-support {
    margin-top: auto;
  }
  .pls-footer-container .tech-support span {
    display: block;
  }
  .pls-footer-container p {
    margin-bottom: 0.2rem;
  }
}
@media (min-width: 1280px) {
  .pls-footer-container .copyright {
    padding-right: 100px;
  }
  .pls-footer-container .copyright p {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
}
@media print and (min-width: 7px) {
  .pls-footer-container .copyright {
    padding-right: 100px;
  }
  .pls-footer-container .copyright p {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
}

/* Heart & Vascular */
.hv-breadcrumb {
  display: none;
}
@media (min-width: 768px) {
  .hv-breadcrumb {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
    display: block;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .hv-breadcrumb {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 768px) and (min-width: 6px) {
  .hv-breadcrumb {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 768px) and (min-width: 1280px) {
  .hv-breadcrumb {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 768px) and (min-width: 7px) {
  .hv-breadcrumb {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 768px) and (min-width: 1440px) {
  .hv-breadcrumb {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 768px) and (min-width: 1440px) {
  .hv-breadcrumb {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 768px) {
  .hv-breadcrumb .breadcrumb-list {
    display: flex;
    list-style-type: none;
  }
}
@media (min-width: 768px) {
  .hv-breadcrumb .breadcrumb-item {
    color: #595756;
    display: inline-block;
    margin-left: 1.5rem;
    position: relative;
  }
  .hv-breadcrumb .breadcrumb-item:first-child {
    margin-left: 0;
  }
  .hv-breadcrumb .breadcrumb-item:first-child:before {
    display: none;
  }
  .hv-breadcrumb .breadcrumb-item:before {
    content: "/";
    left: -1rem;
    position: absolute;
    top: 1px;
  }
}
@media (min-width: 768px) {
  .hv-breadcrumb .breadcrumb-link {
    font-size: 0.875rem;
    line-height: 1.4285714286;
    font-family: AvenirRoman, Helvetica, sans-serif;
    font-weight: normal;
    align-items: center;
    color: #595756;
    display: inline-flex;
    height: 20px;
    text-decoration: none;
  }
  .hv-breadcrumb .breadcrumb-link:hover, .hv-breadcrumb .breadcrumb-link:focus {
    text-decoration: underline;
  }
  .hv-breadcrumb .breadcrumb-link .icon {
    height: 0.5rem;
    width: 0.8125rem;
  }
}
@media print and (min-width: 6px) {
  .hv-breadcrumb {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
    display: block;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
}
@media print and (min-width: 6px) and (min-width: 768px) {
  .hv-breadcrumb {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) and (min-width: 6px) {
  .hv-breadcrumb {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) and (min-width: 1280px) {
  .hv-breadcrumb {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 6px) and (min-width: 7px) {
  .hv-breadcrumb {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 6px) and (min-width: 1440px) {
  .hv-breadcrumb {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 6px) and (min-width: 1440px) {
  .hv-breadcrumb {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 6px) {
  .hv-breadcrumb .breadcrumb-list {
    display: flex;
    list-style-type: none;
  }
}
@media print and (min-width: 6px) {
  .hv-breadcrumb .breadcrumb-item {
    color: #595756;
    display: inline-block;
    margin-left: 1.5rem;
    position: relative;
  }
  .hv-breadcrumb .breadcrumb-item:first-child {
    margin-left: 0;
  }
  .hv-breadcrumb .breadcrumb-item:first-child:before {
    display: none;
  }
  .hv-breadcrumb .breadcrumb-item:before {
    content: "/";
    left: -1rem;
    position: absolute;
    top: 1px;
  }
}
@media print and (min-width: 6px) {
  .hv-breadcrumb .breadcrumb-link {
    font-size: 0.875rem;
    line-height: 1.4285714286;
    font-family: AvenirRoman, Helvetica, sans-serif;
    font-weight: normal;
    align-items: center;
    color: #595756;
    display: inline-flex;
    height: 20px;
    text-decoration: none;
  }
  .hv-breadcrumb .breadcrumb-link:hover, .hv-breadcrumb .breadcrumb-link:focus {
    text-decoration: underline;
  }
  .hv-breadcrumb .breadcrumb-link .icon {
    height: 0.5rem;
    width: 0.8125rem;
  }
}

.hv-two-col {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
}
@media (min-width: 768px) {
  .hv-two-col {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .hv-two-col {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .hv-two-col {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .hv-two-col {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .hv-two-col {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .hv-two-col {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.hv-two-col .providers-live-help ul {
  margin-bottom: 1rem;
}
.hv-two-col .providers-live-help li {
  margin-bottom: 0.1rem;
}
.hv-two-col .providers-live-help .title {
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  color: #a71930;
}
@media (min-width: 768px) {
  .hv-two-col .hv-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
    flex-direction: row-reverse;
  }
  .hv-two-col .hv-rail {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 10px;
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 0;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
  }
  .hv-two-col .hv-rail h2 {
    margin-bottom: 1.25rem;
  }
  .hv-two-col .hv-content {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 10px;
    flex: 0 0 75%;
    max-width: 75%;
    margin-bottom: 0;
    padding: 0 10px;
  }
}
@media print and (min-width: 6px) {
  .hv-two-col .hv-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
    flex-direction: row-reverse;
  }
  .hv-two-col .hv-rail {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 10px;
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 0;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
  }
  .hv-two-col .hv-rail h2 {
    margin-bottom: 1.25rem;
  }
  .hv-two-col .hv-content {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 10px;
    flex: 0 0 75%;
    max-width: 75%;
    margin-bottom: 0;
    padding: 0 10px;
  }
}

/* NGDE */
.portal .data-summary {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  margin-left: -30px;
  width: 100vw;
}
.portal .data-summary .data-section {
  background-color: rgba(239, 239, 239, 0.4039215686);
  border-right: 1px solid #bfbfbf;
  display: flex;
  flex-direction: column;
  padding: 35px 30px 22px;
}
.portal .data-summary .data-section .data-item-container {
  display: flex;
  flex-flow: row;
  width: 100%;
}
.portal .data-summary .data-section .you-pay {
  background-color: #e5f1f5;
  margin: 0 -30px 0 -30px;
  padding: 12px 30px;
  width: 100vw;
}
.portal .data-summary .data-section .you-pay dt,
.portal .data-summary .data-section .you-pay dd {
  margin: 0;
}
.portal .data-summary .data-section dl {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  align-content: flex-start;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: space-between;
}
.portal .data-summary .data-section dl:last-child {
  padding-right: 0;
}
.portal .data-summary .data-section dt {
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 0.75rem;
  padding-right: 0.25rem;
  width: 40%;
}
.portal .data-summary .data-section dd {
  color: #616161;
  display: flex;
  margin-bottom: 0.75rem;
  margin-left: auto;
  padding-right: 0.5rem;
  width: 60%;
}
.portal .data-summary .link-section {
  padding: 35px 30px 22px;
}
.portal .data-summary .link-list-header,
.portal .data-summary .link-list-item {
  font-size: 0.875rem;
  line-height: 1.4285714286;
}
.portal .data-summary .link-list-header p,
.portal .data-summary .link-list-item p {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  margin-bottom: 0;
}
.portal .data-summary .link-list-header {
  margin-bottom: 0.25rem;
}
.portal .data-summary .link-list-header .icon {
  margin-left: 1rem;
}
.portal .data-summary .link-list {
  margin-bottom: 1rem;
  margin-left: 0;
}
.portal .data-summary .link-list:last-child {
  margin-bottom: 0;
}
.portal .data-summary .link-list-item {
  margin-bottom: 0.5rem;
}
.portal .data-summary .link-list-item .btn-link {
  margin-bottom: 0;
  text-align: left;
}
@media (min-width: 1280px) {
  .portal .data-summary {
    border: 1px solid #bfbfbf;
    flex-direction: row;
    margin-left: 0;
    width: 100%;
  }
  .portal .data-summary .data-section {
    flex-basis: 68%;
    flex-direction: row;
    max-width: 68%;
  }
  .portal .data-summary .data-section dl {
    padding-right: 1rem;
  }
  .portal .data-summary .data-section .you-pay {
    margin-top: 1rem;
    padding: 1rem 30px;
    width: calc(100% + 60px);
  }
  .portal .data-summary .link-section {
    flex-basis: 32%;
    max-width: 32%;
  }
}
@media print and (min-width: 7px) {
  .portal .data-summary {
    border: 1px solid #bfbfbf;
    flex-direction: row;
    margin-left: 0;
    width: 100%;
  }
  .portal .data-summary .data-section {
    flex-basis: 68%;
    flex-direction: row;
    max-width: 68%;
  }
  .portal .data-summary .data-section dl {
    padding-right: 1rem;
  }
  .portal .data-summary .data-section .you-pay {
    margin-top: 1rem;
    padding: 1rem 30px;
    width: calc(100% + 60px);
  }
  .portal .data-summary .link-section {
    flex-basis: 32%;
    max-width: 32%;
  }
}

.ngde-header-background {
  height: 450px;
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;
}
.ngde-header-background img {
  width: 100%;
  height: 450px;
  object-fit: cover;
}

.portal .notification {
  background-color: #d9edf7;
  display: flex;
  margin: 1rem -1.875rem 2rem -1.875rem;
  padding: 1.25rem 1.875rem;
}
.portal .notification .notification-icon {
  display: flex;
  margin-right: 0.625rem;
}
.portal .notification .icon-info-alert {
  height: 19px;
  width: 19px;
}
.portal .notification .notification-content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}
.portal .notification .btn-link {
  display: inline-block;
  font-size: inherit;
  line-height: inherit;
  margin-bottom: 0;
  margin-top: 0.5rem;
  text-decoration: underline;
}
@media (min-width: 768px) {
  .portal .notification {
    align-items: center;
    margin: 1rem 0 2rem;
  }
  .portal .notification .notification-content {
    flex-direction: row;
    justify-content: center;
  }
  .portal .notification .btn-link {
    margin-left: 0.625rem;
    margin-top: 0;
    text-decoration: none;
  }
  .portal .notification .btn-link:hover {
    text-decoration: underline;
  }
}
@media print and (min-width: 6px) {
  .portal .notification {
    align-items: center;
    margin: 1rem 0 2rem;
  }
  .portal .notification .notification-content {
    flex-direction: row;
    justify-content: center;
  }
  .portal .notification .btn-link {
    margin-left: 0.625rem;
    margin-top: 0;
    text-decoration: none;
  }
  .portal .notification .btn-link:hover {
    text-decoration: underline;
  }
}

.donut-chart-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 90%;
}
.donut-chart-wrapper .circular-chart {
  display: block;
  max-width: 100%;
  width: 200px;
  max-height: 250px;
  margin-bottom: 0rem;
  margin-top: -1.7rem;
}
.donut-chart-wrapper .circle-bg {
  fill: none;
  stroke: #bfbfbf;
  stroke-width: 4.5;
}
.donut-chart-wrapper .circle {
  stroke: rgb(19, 126, 19);
  fill: none;
  stroke-width: 4.2;
  stroke-linecap: square;
  animation: progress 1s ease-out forwards;
  stroke-dasharray: 50 100;
}
.donut-chart-wrapper .percentage {
  text-anchor: middle;
  font-size: 7px;
  font-weight: 900;
  letter-spacing: 0.2px;
  font-kerning: normal;
}
.donut-chart-wrapper h2.text-center {
  font-size: 1rem;
}
.donut-chart-wrapper canvas {
  display: block;
  box-sizing: border-box;
  height: 13rem;
  width: 300px;
}
@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

@media (min-width: 414px) {
  .donut-chart-wrapper {
    padding: 2rem 0;
    margin: -30px 11px 2px 20px;
  }
}
@media print and (min-width: 5px) {
  .donut-chart-wrapper {
    padding: 2rem 0;
    margin: -30px 11px 2px 20px;
  }
}
.ngde-form {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  margin-top: 1.5rem;
}
.ngde-form.errorEmail .email-input {
  border-bottom: 2px solid #a71930;
}
.ngde-form.errorPhone .phone-input {
  border-bottom: 2px solid #a71930;
}
.ngde-form.errorClaim .claim-input {
  border-bottom: 2px solid #a71930;
}
.ngde-form .nav-btn {
  display: block;
  margin-bottom: 0.8rem;
}
.ngde-form .active {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  color: #000;
}
.ngde-form .grid-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}
.ngde-form .action-btn {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #00759a;
  display: block;
  margin: 0 auto 1.5rem auto;
  padding: 0 10px;
  text-decoration: underline;
}
.ngde-form .action-btn:hover, .ngde-form .action-btn:focus {
  text-decoration: none;
}
.ngde-form .action-btn.clear-btn {
  text-decoration: none;
}
.ngde-form .action-btn.clear-btn:hover, .ngde-form .action-btn.clear-btn:focus {
  text-decoration: underline;
}
.ngde-form .content-col .current-plan-card {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  padding: 1.5rem 0;
}
.ngde-form .content-col .current-plan-card .portal-id-card {
  margin-bottom: 0.6875rem;
}
.ngde-form .icon {
  stroke-width: 0px;
}
.ngde-form .icon-success,
.ngde-form .error {
  min-height: 22px;
  min-width: 22px;
}
.ngde-form .icon-question {
  min-height: 19px;
  min-width: 19px;
}
.ngde-form .icon-arrow-down {
  min-height: 15px;
  min-width: 7.5px;
  stroke-width: 1px;
}
.ngde-form .btn-primary {
  background-color: #363534;
  border: 1px solid #363534;
  color: #fff;
  text-align: center;
  width: 100%;
}
.ngde-form .btn-primary:hover, .ngde-form .btn-primary:active {
  background-color: #000;
  color: #fff;
}
.ngde-form .btn-primary:disabled {
  background-color: #fff;
  border: 1px solid #bfbfbf;
  color: #707070;
}
.ngde-form.success .success-notification-wrap {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  background-color: #e2f5e4;
  display: flex;
  margin: 1rem 0 2rem 0;
  padding: 1.25rem;
}
.ngde-form.success .success-notification-wrap .text-wrapper {
  margin-left: 1.25rem;
}
.ngde-form.error .error-notification-wrap {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  background-color: #ffefef;
  display: flex;
  margin: 1rem 0 2rem 0;
  padding: 1.25rem;
}
.ngde-form.error .error-notification-wrap .text-wrapper {
  margin-left: 1.25rem;
}
.ngde-form .success-notification-wrap,
.ngde-form .error-notification-wrap,
.ngde-form .status-updating {
  display: none;
}
.ngde-form .form .form-control {
  max-width: 100%;
}
.ngde-form .checkbox-wrapper {
  align-items: flex-start;
  display: flex;
  min-height: 1.5rem;
  justify-items: flex-start;
  position: relative;
  color: #707070;
}
.ngde-form .checkbox-wrapper input[type=checkbox] {
  height: 1.5rem;
  margin-top: 2px;
  opacity: 0;
  width: 1.5rem;
}
.ngde-form .checkbox-wrapper input[type=checkbox]:checked + label:after {
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  content: "";
  display: block;
  height: 0.9rem;
  left: 0;
  margin: 0.25rem 0.5rem;
  position: absolute;
  top: 0;
  transform: rotate(45deg) translate(-1px, -1px);
  width: 0.45rem;
  border-bottom-width: 1px;
  border-right-width: 1px;
}
.ngde-form .checkbox-wrapper input[type=checkbox]:not([disabled]):hover {
  cursor: pointer;
}
.ngde-form .checkbox-wrapper input[type=checkbox]:not([disabled]):hover + label:before {
  border-color: #363534;
}
.ngde-form .checkbox-wrapper input[type=checkbox]:not([disabled]):focus + label:before {
  border-color: #363534;
  border-width: 2px;
}
.ngde-form .checkbox-wrapper input[type=checkbox]:not([disabled]) + label:hover {
  cursor: pointer;
}
.ngde-form .checkbox-wrapper input[type=checkbox]:not([disabled]) + label:hover:before {
  border-color: #363534;
}
.ngde-form .checkbox-wrapper input[type=checkbox] + label:before {
  background-color: #fff;
  border: 1px solid #707070;
  border-radius: 2px;
  content: "";
  display: block;
  height: 1.5rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 1.5rem;
}
.ngde-form .checkbox-wrapper label {
  font-size: 1rem;
  line-height: 1.375;
  color: #707070;
}
.ngde-form .checkbox-wrapper.checkbox-span {
  display: flex;
  width: 100%;
}
.ngde-form .checkbox-wrapper.checkbox-span .pull-right {
  margin-left: auto;
}
.ngde-form .toggle .label-icon {
  padding-right: 0.625rem;
}
.ngde-form .toggle .label-name {
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
}
.ngde-form .toggle .label-status {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  color: #bfbfbf;
  padding-right: 0.625rem;
}
.ngde-form .toggle .label-status.status-yes {
  color: #363534;
}
.ngde-form.updating .status-updating {
  display: block;
}
.ngde-form.checked .status-no {
  display: none;
}
.ngde-form.checked .status-yes {
  display: block;
}
.ngde-form.not-checked .status-no {
  display: block;
}
.ngde-form.not-checked .status-yes {
  display: none;
}
.ngde-form .radio-wrapper input[type=radio] + label:before {
  border: 1px solid #707070;
}
.ngde-form .radio-wrapper input[type=radio]:focus-visible + label {
  box-shadow: 0 0 0 2px #000;
}
.ngde-form .radio-wrapper input[type=radio]:focus-visible + label:before {
  box-shadow: 0 0 0 1px #000;
}
.ngde-form .radio-wrapper .display-name {
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.375;
  color: #363534;
}
.ngde-form .checkbox-list {
  margin-bottom: 2rem;
  padding-inline-start: 0;
}
.ngde-form input[type=text] {
  background-color: #eee;
  border: 1px solid #eee;
  color: #000;
  padding-top: 1.8rem;
  text-align: left;
}
.ngde-form input[type=text]:disabled {
  background-color: #fff;
  border-color: #bfbfbf;
}
.ngde-form .floating-label {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #707070;
  left: 1.05rem;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  top: 4px;
}
.ngde-form .error-message {
  padding: 0 0 0.625rem 0;
}
.ngde-form .select-wrapper {
  width: calc((100% - 15px) / 2 - 1px);
  left: 0;
}
.ngde-form .select-wrapper svg {
  stroke: #000;
  pointer-events: none;
  top: 50%;
}
.ngde-form .select-wrapper.audio-list-wrapper {
  left: auto;
  right: 15px;
}
.ngde-form .text-box {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  background-color: #eee;
  border: 1px solid #eee;
  height: 200px;
  padding: 1.5rem 1.05rem;
  resize: none;
  width: 100%;
}
.ngde-form .text-box:focus-visible {
  outline: none;
}
.ngde-form .text-counter-wrapper {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #707070;
  padding: 0 0 1.625rem 1.25rem;
}
.ngde-form .select-input-wrapper {
  position: relative;
  padding-bottom: 12px;
  width: 100%;
}
.ngde-form .select-input-wrapper .select-wrapper {
  width: 100%;
}
.ngde-form .select-input-wrapper .select {
  background-color: #eee;
  height: 60px;
  padding-top: 1.4rem;
}
.ngde-form .select-input-wrapper .icon-arrow-down {
  pointer-events: none;
  position: absolute;
  right: 1.25rem;
  top: 30%;
}

.navigation-col {
  display: none;
  padding-bottom: 2rem;
}
.navigation-col .text-content li {
  margin-bottom: 1rem;
}
.navigation-col .rail-title .nav-link-title {
  margin-bottom: 1rem;
  padding: 0 2rem;
}
.navigation-col .nav-btn {
  font-size: 0.875rem;
  line-height: 1.4285714286;
}
.navigation-col hr {
  border-style: none;
  background-color: #bfbfbf;
  height: 1px;
  margin: 0 auto;
  width: 85%;
}
.navigation-col .selected-with-links.loading .loading-overlay-section,
.navigation-col .selected-no-links.loading .loading-overlay-section {
  opacity: 1;
}
.navigation-col .selected-with-links .nav-link-title,
.navigation-col .selected-no-links .nav-link-title {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
}
.navigation-col .selected-with-links {
  background-color: #eee;
}
.navigation-col .selected-with-links .link-list-header {
  margin-left: 2.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}
.navigation-col .selected-with-links .second-section-header {
  margin-top: 0rem;
}
.navigation-col .selected-with-links .link-list {
  padding-left: 2.5rem;
  padding-bottom: 1.5rem;
}
.navigation-col .selected-no-links {
  display: block;
}
.navigation-col .nav-link-title {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  color: #000;
  display: block;
  margin-bottom: 0;
  padding: 1.5625rem 2rem;
}
.navigation-col .link-list-header {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
  margin-bottom: 0.5rem;
  margin-left: 2rem;
  padding-bottom: 0;
  padding-left: 0;
}
.navigation-col .link-list-header .icon-arrow-up {
  height: 7.42px;
  margin-left: 0.75rem;
  width: 7.5px;
}
.navigation-col .link-list-header .icon-download-sm {
  height: 11.89px;
  margin-left: 0.75rem;
  width: 10px;
}
.navigation-col .link-list {
  color: #363534;
  padding: 0 2rem;
}
.navigation-col .link-list ul {
  padding-top: 1rem;
}
.navigation-col .link-list .link-list {
  color: #363534;
  padding: 0 0 1rem 2.5rem;
}
.navigation-col .link-list .link-list-item {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  padding-bottom: 0.5rem;
}
.navigation-col .link-list .btn-link {
  margin-bottom: 0;
  text-align: left;
}
.navigation-col .has-sub-nav.expanded .nav-link-title {
  padding-bottom: 0.5rem;
}
.navigation-col .sub-nav-list {
  padding: 0 0 0.5rem 2.5rem;
}
.navigation-col .sub-nav-list .sub-nav-item {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  padding: 0.5rem 0;
}
.navigation-col .sub-nav-list .sub-nav-item.selected {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  color: #000;
}
.navigation-col .sub-nav-list .sub-nav-item.selected a {
  color: #000;
}
@media (min-width: 1280px) {
  .navigation-col {
    display: block;
  }
}
@media print and (min-width: 7px) {
  .navigation-col {
    display: block;
  }
}

.navigation-col-mobile .navigation-col {
  display: none;
}
.navigation-col-mobile .mobile-nav-accordion h2.second-header {
  background-color: #eee;
  padding: 25px 19px 24px 60px;
  margin: -15px 2px 20px -31px;
  width: 130%;
}
.navigation-col-mobile .down-arrow-container {
  margin-left: 50%;
}
.navigation-col-mobile .mobile-nav-col .navigation-col {
  display: block;
}
.navigation-col-mobile .mobile-nav-trigger {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  align-items: center;
  background-color: #eee;
  display: flex;
  height: 70px;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 2rem;
  margin-left: -40px;
  padding: 0 30px;
  width: 100vw;
}
.navigation-col-mobile .nav-list-container {
  background-color: #fff;
  display: none;
  height: 100%;
  left: 0;
  overflow: auto;
  opacity: 0;
  padding-bottom: 80px;
  pointer-events: none;
  position: fixed;
  top: 20vh;
  transition: visibility 0s ease-in-out 400ms, opacity 0.2s ease-in-out, top 0.2s ease-in-out;
  width: 100%;
  z-index: 11;
}
.navigation-col-mobile .nav-list-container hr {
  width: 90%;
  margin: 18px 25px 18px 25px;
}
.navigation-col-mobile .mobile-nav-header {
  align-items: center;
  background-color: #eee;
  display: flex;
  height: 70px;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 0 30px;
}
.navigation-col-mobile .mobile-nav-header .mobile-close-container .mobile-close-btn:focus-visible {
  outline: #000 solid 2px;
}
.navigation-col-mobile .mobile-nav-header .nav-heading {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 0;
}
.navigation-col-mobile .nav-list {
  display: flex;
  flex-direction: column;
  padding: 0 0px;
}
.navigation-col-mobile .nav-item {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  align-items: center;
  display: flex;
  min-height: 40px;
  padding: 0.5rem 20px;
}
.navigation-col-mobile .nav-item a {
  color: #363534;
  text-decoration: none;
  font-size: 0.875rem;
  margin-left: 10px;
  margin-top: 4px;
}
.navigation-col-mobile .nav-item.active {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  border-left-color: #363534;
}
.navigation-col-mobile .icon.icon-menu-close {
  stroke-width: 2px;
}
.navigation-col-mobile.nav-open .nav-list-container {
  display: block;
  opacity: 1;
  pointer-events: auto;
  top: 0;
  transition-delay: 0s;
}
@media (min-width: 1280px) {
  .navigation-col-mobile .navigation-col {
    display: block;
  }
  .navigation-col-mobile .mobile-nav-trigger,
.navigation-col-mobile .mobile-nav-header,
.navigation-col-mobile .heading-1,
.navigation-col-mobile .nav-list-container {
    display: none;
  }
  .navigation-col-mobile.nav-open .nav-list-container {
    display: none;
  }
}
@media print and (min-width: 7px) {
  .navigation-col-mobile .navigation-col {
    display: block;
  }
  .navigation-col-mobile .mobile-nav-trigger,
.navigation-col-mobile .mobile-nav-header,
.navigation-col-mobile .heading-1,
.navigation-col-mobile .nav-list-container {
    display: none;
  }
  .navigation-col-mobile.nav-open .nav-list-container {
    display: none;
  }
}

.smartbanner {
  position: fixed !important;
  z-index: 10 !important;
  box-shadow: inset 0 4px 0 #a71930 !important;
}

/* Organisms (e.g. Header, Footer, Article, SitecoreComponent) */
.action-column {
  margin: 1rem 0 2rem;
}
.action-column.has-search .action-column-container {
  display: none;
}
.search-loaded .action-column.has-search .action-column-container {
  display: block;
}
.action-column.desktop {
  display: none;
}
.action-column.desktop.column-fixed .action-column-container {
  position: fixed;
  top: 50px;
}
.action-column.desktop .action-column-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.action-column.is-portal .action-btn:hover .icon, .action-column.is-portal .action-btn:focus .icon {
  opacity: 1;
}
.action-column.is-portal .action-btn .icon {
  opacity: 0.5;
}
.action-column.is-portal-desktop {
  display: none;
}
.action-column.is-portal-desktop.column-fixed .action-column-container {
  position: fixed;
  top: 295px;
}
.action-column.is-portal-desktop .action-column-container {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 295px;
}
.action-column.is-portal-desktop .action-column-container .icon-mail,
.action-column.is-portal-desktop .action-column-container .icon-chat {
  stroke-width: 2px;
}
.action-column .action-column-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  pointer-events: none;
}
@media (min-width: 768px) {
  .action-column .action-column-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .action-column .action-column-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .action-column .action-column-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .action-column .action-column-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .action-column .action-column-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .action-column .action-column-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.action-column .column-icons {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: auto;
  position: relative;
}
.action-column .action-btn {
  display: flex;
  margin: 0 0.5rem;
  pointer-events: auto;
}
.action-column .action-btn.hidden {
  display: none;
}
.action-column .action-btn:hover .icon, .action-column .action-btn:focus .icon {
  fill: #000;
  stroke: #000;
}
.action-column .action-btn .icon {
  width: 22px;
  height: 22px;
  fill: #707070;
  stroke: #707070;
  pointer-events: none;
}
@media (min-width: 1280px) {
  .action-column {
    display: none;
    margin: 0;
    position: relative;
    z-index: 9;
  }
  .action-column.desktop {
    display: block;
  }
  .action-column.is-portal-desktop {
    display: block;
  }
  .action-column.has-search .column-icons {
    top: 44px;
  }
  .action-column .column-icons {
    flex-direction: column;
    justify-content: unset;
    transform: translateX(100%);
    width: 70px;
    position: absolute;
    right: 70px;
    top: 0;
    margin-top: 0.375rem;
  }
  .action-column .action-btn {
    margin: 0 0 1rem;
  }
}
@media print and (min-width: 7px) {
  .action-column {
    display: none;
    margin: 0;
    position: relative;
    z-index: 9;
  }
  .action-column.desktop {
    display: block;
  }
  .action-column.is-portal-desktop {
    display: block;
  }
  .action-column.has-search .column-icons {
    top: 44px;
  }
  .action-column .column-icons {
    flex-direction: column;
    justify-content: unset;
    transform: translateX(100%);
    width: 70px;
    position: absolute;
    right: 70px;
    top: 0;
    margin-top: 0.375rem;
  }
  .action-column .action-btn {
    margin: 0 0 1rem;
  }
}

.accordion.action-block-accordion .accordion-item {
  border: none;
  margin-bottom: 0.25rem;
}
.accordion.action-block-accordion .accordion-item:last-of-type {
  border: none;
  margin-bottom: 0;
}
.accordion.action-block-accordion .accordion-item.active .closed-title {
  display: none;
}
.accordion.action-block-accordion .accordion-item.active .open-title {
  display: block;
}
.accordion.action-block-accordion .accordion-item.active .icon-arrow-down {
  transform: rotate(180deg);
}
.accordion.action-block-accordion .accordion-item-toggle {
  background-color: #eee;
  padding: 0;
}
.accordion.action-block-accordion .action-block {
  margin-bottom: 0;
}
.accordion.action-block-accordion .open-title {
  display: none;
}
.accordion.action-block-accordion .accordion-item-body {
  background-color: #eee;
  margin: 0;
  padding: 10px 20px 20px;
}
.accordion.hv-accordion.search-accordion .accordion-item:last-of-type {
  border-bottom: none;
}
.accordion.hv-accordion.search-accordion .accordion-item.active .accordion-item-body {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-right: 0;
}
@media (min-width: 1280px) {
  .accordion.hv-accordion.search-accordion .accordion-item.active .accordion-item-body {
    flex-direction: row;
  }
}
@media print and (min-width: 7px) {
  .accordion.hv-accordion.search-accordion .accordion-item.active .accordion-item-body {
    flex-direction: row;
  }
}
.accordion.hv-accordion.search-accordion .accordion-item-toggle {
  background-color: #fafafa;
}
.accordion.hv-accordion.search-accordion .accordion-item-body {
  background-color: #eee;
  margin: 0;
  padding: 0;
}
.accordion.hv-accordion .accordion-item {
  border-top: none;
  padding-bottom: 1px;
  border-bottom: 1px solid #bfbfbf;
}
.accordion.hv-accordion .accordion-item:last-of-type {
  border-bottom: 1px solid #bfbfbf;
}
.accordion.hv-accordion .accordion-item.active .accordion-item-body {
  display: block;
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
}
.accordion.hv-accordion .accordion-item.active .accordion-item-body a {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  color: #00759a;
  display: inline-block;
  text-decoration: none;
}
.accordion.hv-accordion .accordion-item.active .accordion-item-body a:focus, .accordion.hv-accordion .accordion-item.active .accordion-item-body a:hover {
  text-decoration: underline;
}
.accordion.hv-accordion .accordion-item.active .accordion-item-body .accordion-content {
  margin-bottom: 1.5rem;
}
.accordion.hv-accordion .accordion-item.active .accordion-item-body .external-link span {
  padding-right: 0.25rem;
}
.accordion.hv-accordion .accordion-item.active .accordion-item-body .external-link:focus, .accordion.hv-accordion .accordion-item.active .accordion-item-body .external-link:hover {
  text-decoration: none;
}
.accordion.hv-accordion .accordion-item.active .accordion-item-body .external-link:focus span, .accordion.hv-accordion .accordion-item.active .accordion-item-body .external-link:hover span {
  text-decoration: underline;
}
.accordion.hv-accordion .accordion-item.active .accordion-item-body .external-link .icon-external {
  height: 10px;
  stroke: #00759a;
  width: 10px;
}
.accordion.hv-accordion .accordion-item.active .accordion-item-toggle .icon-arrow-down {
  transform: rotate(180deg);
}
.accordion.hv-accordion .accordion-item .toggle-container .section-title {
  display: inline-block;
  max-width: 85%;
  overflow-wrap: normal;
}
.accordion.hv-accordion .accordion-item-toggle {
  padding: 0.5rem 0;
}
.accordion.hv-accordion .accordion-item-toggle:hover {
  background-color: #fafafa;
}
.accordion.hv-accordion .accordion-item-toggle span {
  width: 100%;
}
.accordion.hv-accordion .toggle-container {
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  color: #000;
  padding-left: 0px;
  padding-right: 20px;
}
.accordion.hv-accordion .accordion-item-more-less {
  background-color: white;
}
.accordion.hv-accordion .accordion-item-more-less.active .show-less {
  display: block;
}
.accordion.hv-accordion .accordion-item-more-less.active .show-more {
  display: none;
}
.accordion.hv-accordion .accordion-item-more-less .accordion-item-body {
  margin-top: 0;
  margin-bottom: 0;
}
.accordion.hv-accordion .accordion-item-more-less .show-less {
  display: none;
}
.accordion.hv-accordion .accordion-item-more-less .show-more {
  display: block;
}
.accordion.hv-accordion .accordion-item-more-less .toggle-container-more-less {
  display: flex;
  justify-content: center;
}
.accordion.hv-accordion .accordion-item-more-less .toggle-container-more-less .icon-arrow-down {
  height: 0.4rem;
  margin-left: 0.5rem;
}
.accordion.hv-accordion .accordion-item-body {
  padding-left: 0px;
}
.accordion .accordion-item {
  border-top: 2px solid #bfbfbf;
}
.accordion .accordion-item:last-of-type {
  border-bottom: 2px solid #bfbfbf;
}
.accordion .accordion-item.active .accordion-item-body {
  display: block;
}
.accordion .accordion-item.active .accordion-item-toggle .icon-modal-close {
  transform: none;
}
.accordion .accordion-item-toggle {
  padding: 30px 0;
  display: block;
  width: 100%;
}
.accordion .accordion-item-toggle .icon-modal-close {
  height: 12px;
  stroke: #000;
  stroke-width: 6px;
  width: 12px;
  max-width: 12px;
  flex: 0 0 12px;
  transform: rotate(-45deg);
}
.accordion .toggle-container {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  font-size: 1rem;
  line-height: 1.375;
  margin: 0;
  align-items: center;
  color: #363534;
  display: flex;
  justify-content: space-between;
  text-align: left;
  pointer-events: none;
}
@media (min-width: 768px) {
  .accordion .toggle-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .accordion .toggle-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .accordion .toggle-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .accordion .toggle-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .accordion .toggle-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .accordion .toggle-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.accordion .accordion-item-body {
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  font-size: 1rem;
  line-height: 1.375;
  display: none;
  color: #363534;
  margin-bottom: 40px;
  margin-top: 40px;
}
@media (min-width: 768px) {
  .accordion .accordion-item-body {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .accordion .accordion-item-body {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .accordion .accordion-item-body {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .accordion .accordion-item-body {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .accordion .accordion-item-body {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .accordion .accordion-item-body {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.accordion .accordion-item-body p {
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.375;
}

.header .utility-header {
  display: none;
}
.header .main-header {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  align-items: center;
  display: flex;
  height: 60px;
  justify-content: space-between;
  transition: margin-top 0.3s ease-in-out;
}
@media (min-width: 768px) {
  .header .main-header {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .header .main-header {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .header .main-header {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .header .main-header {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .header .main-header {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .header .main-header {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.header .main-header .icon-phs-logo {
  fill: #a71930;
}
.header .main-header .logo-container {
  height: 32px;
}
.header .main-header .logo-subtitle {
  display: none;
}
.navbar-fixed .header {
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 9;
}
.navbar-fixed .header .main-header {
  margin-top: -60px;
  transition: none;
}
.navbar-scroll-up .header {
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 9;
}
[class^=translated-] .navbar-scroll-up .header {
  top: 39px;
}
.navbar-scroll-up .header .main-header {
  animation: mobile-nav-slide-down 0.2s;
  margin-top: 0;
}
@keyframes mobile-nav-slide-down {
  from {
    margin-top: -60px;
  }
  to {
    margin-top: 0;
  }
}
.navbar-scroll-down .header .main-header {
  animation: mobile-nav-slide-up 0.2s;
  margin-top: -60px;
  pointer-events: none;
  position: fixed;
}
@keyframes mobile-nav-slide-up {
  from {
    margin-top: 0;
  }
  to {
    margin-top: -60px;
  }
}
@media (min-width: 1280px) {
  .navbar-fixed .header, .navbar-scroll-up .header {
    position: relative;
  }
  .navbar-fixed .header .main-header, .navbar-scroll-up .header .main-header {
    margin-top: 0;
  }
  .search-open .header .utility-header {
    display: none;
  }
  .header .main-header {
    height: 90px;
  }
  .header .main-header .logo-container {
    height: 65px;
  }
  .header .main-header .logo-subtitle {
    font-size: 1.25rem;
    line-height: 1.2;
    color: #000;
    display: block;
    margin-bottom: 0;
    padding-left: 34px;
  }
  .header .main-header .logo-subtitle .logo-beta {
    color: #595756;
  }
  .header .icon-phs-logo {
    height: 32.288581px;
    width: 279.929px;
  }
  .header .utility-header {
    background: linear-gradient(#363534, #000);
    display: block;
    transition: margin-top 0.3s ease-in-out;
  }
  .header .utility-header .utility-navigation {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
    align-items: center;
    display: flex;
    height: 40px;
  }
}
@media (min-width: 1280px) and (min-width: 768px) {
  .header .utility-header .utility-navigation {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 1280px) and (min-width: 6px) {
  .header .utility-header .utility-navigation {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) and (min-width: 1280px) {
  .header .utility-header .utility-navigation {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1280px) and (min-width: 7px) {
  .header .utility-header .utility-navigation {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1280px) and (min-width: 1440px) {
  .header .utility-header .utility-navigation {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1280px) and (min-width: 1440px) {
  .header .utility-header .utility-navigation {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1280px) {
  .header .utility-header a {
    color: #bfbfbf;
    display: inline-block;
    padding: 0.5rem 2.5rem 0.5rem 0;
    text-decoration: none;
  }
  .header .utility-header a:hover, .header .utility-header a:focus {
    color: #eee;
  }
}
@media print and (min-width: 7px) {
  .navbar-fixed .header, .navbar-scroll-up .header {
    position: relative;
  }
  .navbar-fixed .header .main-header, .navbar-scroll-up .header .main-header {
    margin-top: 0;
  }
  .search-open .header .utility-header {
    display: none;
  }
  .header .main-header {
    height: 90px;
  }
  .header .main-header .logo-container {
    height: 65px;
  }
  .header .main-header .logo-subtitle {
    font-size: 1.25rem;
    line-height: 1.2;
    color: #000;
    display: block;
    margin-bottom: 0;
    padding-left: 34px;
  }
  .header .main-header .logo-subtitle .logo-beta {
    color: #595756;
  }
  .header .icon-phs-logo {
    height: 32.288581px;
    width: 279.929px;
  }
  .header .utility-header {
    background: linear-gradient(#363534, #000);
    display: block;
    transition: margin-top 0.3s ease-in-out;
  }
  .header .utility-header .utility-navigation {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
    align-items: center;
    display: flex;
    height: 40px;
  }
}
@media print and (min-width: 7px) and (min-width: 768px) {
  .header .utility-header .utility-navigation {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 7px) and (min-width: 6px) {
  .header .utility-header .utility-navigation {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 7px) and (min-width: 1280px) {
  .header .utility-header .utility-navigation {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) and (min-width: 7px) {
  .header .utility-header .utility-navigation {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) and (min-width: 1440px) {
  .header .utility-header .utility-navigation {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) and (min-width: 1440px) {
  .header .utility-header .utility-navigation {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .header .utility-header a {
    color: #bfbfbf;
    display: inline-block;
    padding: 0.5rem 2.5rem 0.5rem 0;
    text-decoration: none;
  }
  .header .utility-header a:hover, .header .utility-header a:focus {
    color: #eee;
  }
}
@media (min-width: 1280px) {
  .header .utility-header {
    transition: none;
  }
  .header .main-header {
    transition: none;
  }
}
@media print and (min-width: 7px) {
  .header .utility-header {
    transition: none;
  }
  .header .main-header {
    transition: none;
  }
}

.hero {
  color: #363534;
  height: 100vh;
  min-height: 700px;
  text-align: center;
}
.hero p {
  margin-bottom: 0px;
}
.hero .hero-background {
  background: url("../images/light-bg-image.png");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  mix-blend-mode: darken;
  width: 100%;
}
.hero .hero-content {
  padding: 60px 30px 0px 30px;
}
.hero .message {
  font-size: 1rem;
  line-height: 1.375;
  margin-bottom: 1.5rem;
}
.hero .main-title {
  font-size: 3rem;
  line-height: 1;
  font-family: AvenirLight, Helvetica, sans-serif;
  font-weight: normal;
  color: #a71930;
  margin-bottom: 1.875rem;
}
.hero .scroll-btn .icon {
  height: 19px;
  margin-bottom: 1.5rem;
  stroke: #a71930;
  stroke-width: 3px;
  width: 40px;
}
.hero .scroll-btn .label {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #a71930;
  display: block;
  text-transform: uppercase;
}
.hero .sub-title {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirBlack, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 2.625rem;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .hero .hero-background {
    background-image: url("../images/light-bg-image-2x.png");
  }
  .hero .message {
    font-size: 1.25rem;
    line-height: 1.5;
  }
  .hero .main-title {
    font-size: 3.75rem;
    line-height: 1;
  }
  .hero .sub-title {
    font-size: 1.875rem;
    line-height: 1;
  }
}
@media print and (min-width: 6px) {
  .hero .hero-background {
    background-image: url("../images/light-bg-image-2x.png");
  }
  .hero .message {
    font-size: 1.25rem;
    line-height: 1.5;
  }
  .hero .main-title {
    font-size: 3.75rem;
    line-height: 1;
  }
  .hero .sub-title {
    font-size: 1.875rem;
    line-height: 1;
  }
}

.feature-label-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2rem;
  width: 100%;
}
.feature-label-list .feature-label {
  font-size: 1.375rem;
  line-height: 1.4545454545;
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  align-items: center;
  background-color: #ffefef;
  display: flex;
  font-style: oblique;
  justify-content: center;
  margin-bottom: 1rem;
  min-height: 125px;
  padding: 2rem 1rem;
  text-align: center;
  width: calc(50% - 0.5rem);
}
@media (min-width: 768px) {
  .feature-label-list .feature-label {
    width: calc(33.3333333333% - 0.5rem);
  }
}
@media print and (min-width: 6px) {
  .feature-label-list .feature-label {
    width: calc(33.3333333333% - 0.5rem);
  }
}

.feedback-banner {
  background-color: #e5f1f5;
  margin-bottom: 1rem;
}
.feedback-banner.closed {
  display: none;
}
.feedback-banner.error {
  background-color: #ffefef;
}
.feedback-banner.success {
  background-color: #dff0d8;
}
.hv-hero + .feedback-banner {
  margin-top: -1rem;
}
.feedback-banner .banner-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  align-items: flex-start;
  display: flex;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}
@media (min-width: 768px) {
  .feedback-banner .banner-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .feedback-banner .banner-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .feedback-banner .banner-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .feedback-banner .banner-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .feedback-banner .banner-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .feedback-banner .banner-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.feedback-banner .icon {
  height: 20px;
  width: 20px;
}
.feedback-banner .icon-container {
  display: flex;
}
.feedback-banner .icon-information {
  stroke-width: 0;
}
.feedback-banner .banner-text {
  padding: 0 0.75rem;
}
.feedback-banner .banner-heading {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  color: #000;
  margin-bottom: 0.25rem;
}
.feedback-banner p {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  color: #000;
}
.feedback-banner p:last-of-type {
  margin-bottom: 0;
}
.feedback-banner p a {
  color: #00759a;
}
.feedback-banner a {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  color: #00759a;
}
.feedback-banner .banner-close {
  align-items: center;
  display: flex;
}
.feedback-banner .banner-close:hover .icon, .feedback-banner .banner-close:focus .icon {
  stroke: #595756;
}
.feedback-banner .icon-modal-close {
  height: 12px;
  stroke: #000;
  stroke-width: 3px;
  width: 12px;
}
@media (min-width: 768px) {
  .feedback-banner .banner-container {
    position: relative;
  }
  .feedback-banner .banner-close {
    position: absolute;
    right: 9px;
  }
}
@media print and (min-width: 6px) {
  .feedback-banner .banner-container {
    position: relative;
  }
  .feedback-banner .banner-close {
    position: absolute;
    right: 9px;
  }
}
@media (min-width: 1280px) {
  .hv-hero + .feedback-banner {
    margin-top: -3rem;
  }
  .feedback-banner .banner-container {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }
  .feedback-banner .banner-text {
    display: flex;
    margin-right: 4rem;
  }
  .feedback-banner .banner-heading {
    flex-shrink: 0;
    margin-bottom: 0;
    margin-right: 0.5rem;
  }
  .feedback-banner .banner-close {
    margin-top: 0.25rem;
    right: 29px;
  }
}
@media print and (min-width: 7px) {
  .hv-hero + .feedback-banner {
    margin-top: -3rem;
  }
  .feedback-banner .banner-container {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }
  .feedback-banner .banner-text {
    display: flex;
    margin-right: 4rem;
  }
  .feedback-banner .banner-heading {
    flex-shrink: 0;
    margin-bottom: 0;
    margin-right: 0.5rem;
  }
  .feedback-banner .banner-close {
    margin-top: 0.25rem;
    right: 29px;
  }
}

.filter-list {
  background-color: #eee;
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: auto;
  padding-bottom: 80px;
  pointer-events: none;
  position: fixed;
  top: 20vh;
  transition: visibility 0s ease-in-out 400ms, opacity 0.2s ease-in-out, top 0.2s ease-in-out;
  visibility: hidden;
  width: 100%;
  z-index: 9;
}
.filter-list.filters-open {
  display: block;
  opacity: 1;
  pointer-events: auto;
  top: 0;
  transition-delay: 0s;
  visibility: visible;
}
[class^=translated-] .filter-list.filters-open {
  height: calc(100% - 39px);
  top: 39px;
}
.filter-list.view-all-open > .accordion-container {
  display: none;
}
.filter-list .disabled-filter {
  color: #999;
}
.filter-list .accordion-container {
  padding: 10px;
}
.filter-list .pharmacy-filter {
  padding: 15px 10px 0;
}
.filter-list .filter-title {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
  margin-bottom: 1rem;
}
.filter-list .mobile-filter-header {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  align-items: center;
  background-color: #707070;
  color: #363534;
  display: flex;
  height: 48px;
  justify-content: space-between;
  position: relative;
}
@media (min-width: 768px) {
  .filter-list .mobile-filter-header {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .filter-list .mobile-filter-header {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .filter-list .mobile-filter-header {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .filter-list .mobile-filter-header {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .filter-list .mobile-filter-header {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .filter-list .mobile-filter-header {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.filter-list .mobile-filter-header .mobile-close-btn {
  font-size: 1.875rem;
  line-height: 1;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  background: none;
  border: none;
  color: #595756;
  line-height: 0;
  margin-left: -1rem;
  padding: 1rem;
  transform: translateX(1rem);
}
.filter-list .mobile-filter-header .mobile-close-btn .icon {
  height: 0.75rem;
  stroke: #fff;
  width: 0.75rem;
}
.filter-list .mobile-filter-header .mobile-close-btn:hover {
  outline: 0;
}
.filter-list .mobile-filter-header .mobile-close-btn:hover .icon {
  fill: #00759a;
}
.filter-list .mobile-filter-header .mobile-close-btn:focus-visible {
  outline: #fff solid 2px;
}
.filter-list .mobile-filter-header .icon-arrow-right {
  transform: rotate(180deg);
}
.filter-list .mobile-filter-header .filter-heading {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: #fff;
  margin: 0;
}
.filter-list .mobile-filter-header .filter-link {
  color: #00759a;
}
.filter-list .input-icon-container {
  margin-top: 1rem;
  padding: 0 10px;
  position: relative;
}
.filter-list .input-icon-container input {
  font-size: 1rem;
  line-height: 1.375;
  background-color: #fff;
  height: 60px;
  margin-bottom: 0;
  padding: 1.125rem 2.75rem 1.125rem 20px;
  text-align: left;
  width: 100%;
}
.filter-list .input-icon-container input::placeholder {
  color: #999;
  text-align: left;
  font-size: 0.8rem;
}
.filter-list .input-icon-container button,
.filter-list .input-icon-container > .icon {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}
.filter-list .input-icon-container > .icon {
  pointer-events: none;
}
.filter-list .nav-accordion {
  background-color: #eee;
}
.filter-list .nav-accordion .accordion-item {
  border-color: #bfbfbf;
}
.filter-list .nav-accordion .accordion-item:last-of-type {
  border-bottom: none;
}
.filter-list .nav-accordion .accordion-item[aria-expanded=true] .accordion-item-title .accordion-item-toggle {
  color: #363534;
}
.filter-list .nav-accordion .accordion-item[aria-expanded=true] .accordion-item-title .accordion-item-toggle .label-secondary.fade-out {
  opacity: 0;
}
.filter-list .nav-accordion .accordion-item[aria-expanded=true].closing > .accordion-item-title .accordion-item-toggle {
  color: #363534;
}
.filter-list .nav-accordion .accordion-item[aria-expanded=true].closing > .accordion-item-title .accordion-item-toggle:hover, .filter-list .nav-accordion .accordion-item[aria-expanded=true].closing > .accordion-item-title .accordion-item-toggle:focus {
  color: #595756;
}
.filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle {
  color: #000;
}
.filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle:hover {
  color: #00759a;
}
.filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle:hover .icon:not(.icon-star) {
  fill: #00759a;
}
.filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle:focus-visible {
  color: #00759a;
  outline: -webkit-focus-ring-color auto 1px;
}
.filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle:focus-visible .icon:not(.icon-star) {
  fill: #00759a;
}
.filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle > h2 {
  margin: 0;
  color: inherit;
}
.filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle .label-secondary {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  color: #595756;
  display: flex;
  margin-left: auto;
  margin-right: 0.5rem;
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
}
.filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle .label-secondary .rating-stars {
  margin-right: 0.375rem;
}
.filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle .icon:not(.icon-star) {
  fill: #fff;
}
.filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-tags {
  background-color: #363534;
}
.filter-list .nav-accordion .accordion-item .accordion-item-body {
  color: #222;
}
.filter-list .nav-accordion .accordion-item .accordion-item-body .input-icon-container {
  margin-bottom: 1rem;
  margin-left: -20px;
  padding: 0;
  width: calc(100% + 40px);
}
.filter-list .checkbox-filter {
  align-items: center;
  display: flex;
  padding: 1rem;
}
.filter-list .checkbox-filter .checkbox-wrapper {
  margin: 0 1rem 0 0;
}
.filter-list .checkbox-filter .checkbox-wrapper input[type=checkbox] + label {
  color: #fff;
}
.filter-list .checkbox-filter img {
  display: none;
}
.filter-list .checkbox-filter.isMHMG-container {
  display: none;
}
.filter-list .distance-radio {
  display: flex;
  margin-left: -30px;
  width: calc(100% + 60px);
}
.filter-list .distance-radio .distance-radio-wrapper {
  flex: 1;
  height: 40px;
  position: relative;
}
.filter-list .distance-radio .distance-radio-wrapper input[type=radio] {
  height: 0;
  width: 0;
}
.filter-list .distance-radio .distance-radio-wrapper input[type=radio]:checked + label {
  background-color: #363534;
  color: #fff;
}
.filter-list .distance-radio .distance-radio-wrapper input[type=radio]:focus + label, .filter-list .distance-radio .distance-radio-wrapper input[type=radio]:hover + label {
  box-shadow: inset 0 0 1px 1px #eee;
}
.filter-list .distance-radio .distance-radio-wrapper label {
  font-size: 0.75rem;
  line-height: 1.5;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  align-items: center;
  background-color: #bfbfbf;
  color: #363534;
  display: flex;
  height: 40px;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
}
.filter-list .distance-radio .distance-radio-wrapper label:hover {
  cursor: pointer;
}
.filter-list .mobile-filter-show-btn {
  align-items: center;
  background-color: #363534;
  bottom: 0;
  display: flex;
  height: 80px;
  padding: 0 15px;
  position: fixed;
  width: 100%;
}
.filter-list .mobile-filter-show-btn .filter-show-btn {
  background-color: #00759a;
  border-color: #00759a;
  width: 100%;
}
.filter-list .mobile-filter-show-btn .filter-show-btn:hover {
  background-color: #00759a;
  border-color: #00759a;
}
.filter-list .mobile-filter-show-btn .filter-show-btn:focus {
  background-color: #00759a;
  border-color: #00759a;
  box-shadow: 0 0 0 0.2rem rgba(145, 61, 0, 0.5);
}
.filter-list .mobile-filter-show-btn.loading .filter-show-btn {
  color: transparent;
  position: relative;
}
.filter-list .mobile-filter-show-btn.loading .filter-show-btn:after {
  border: 2px solid #fff;
  border-bottom: 2px solid transparent;
  border-left: 2px solid transparent;
  content: " ";
  display: block;
  pointer-events: none;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  animation: spin 1.4s infinite linear;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.filter-list .mobile-filter-show-btn.loading .filter-show-btn:after {
  left: 50%;
  margin-left: -13px;
  margin-top: -13px;
  position: absolute;
  top: 50%;
}
.filter-list .filter-header {
  display: none;
}
.filter-list .filter-description {
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.4285714286;
}
.filter-list .filter-link,
.filter-list .show-more-trigger {
  font-size: 0.875rem;
  line-height: 1;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  background: none;
  border: 0;
  color: #000;
  margin: 0;
  outline: 0;
  padding: 0;
}
.filter-list .filter-link:hover, .filter-list .filter-link:focus,
.filter-list .show-more-trigger:hover,
.filter-list .show-more-trigger:focus {
  text-decoration: underline;
}
.filter-list .filter-link:disabled,
.filter-list .show-more-trigger:disabled {
  color: #707070;
  pointer-events: none;
}
.filter-list .geolocation-link {
  color: #00759a;
  display: inline-flex;
  margin-bottom: 2rem;
  margin-top: -0.5rem;
}
.filter-list .geolocation-link .icon-geolocate {
  margin-right: 0.25rem;
  stroke: #00759a;
}
.filter-list .geolocation-link.geolocating {
  text-decoration: none;
}
.filter-list .geolocation-link.geolocating:after {
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "…"; /* ascii code for the ellipsis character */
  display: inline-block;
  overflow: hidden;
  vertical-align: bottom;
  width: 0px;
}
@keyframes ellipsis {
  to {
    width: 20px;
  }
}
@-webkit-keyframes ellipsis {
  to {
    width: 20px;
  }
}
.filter-list .range-container {
  margin: 0 1rem;
  padding-top: 1.5rem;
}
.filter-list .view-all-trigger {
  font-size: 0.875rem;
  line-height: 1;
  align-items: center;
  color: #00759a;
  display: flex;
  height: 19px;
  margin-top: 2rem;
  overflow: hidden;
}
.filter-list .view-all-trigger .icon {
  height: 0.75rem;
  margin-left: 5px;
  stroke: #00759a;
  transition: margin 0.1s ease-in-out;
  width: 1.25rem;
}
.filter-list .view-all-trigger:hover .icon, .filter-list .view-all-trigger:focus .icon {
  margin-left: 10px;
}
.filter-list .view-all-panel {
  background-color: #eee;
  display: block;
  height: calc(100vh - 80px);
  left: 0;
  overflow-y: auto;
  position: fixed;
  top: 0;
  transform: translateX(100%);
  transition: all 0.2s ease-in-out;
  width: 100%;
  visibility: hidden;
}
.filter-list .view-all-panel.open {
  transform: translateX(0);
  visibility: visible;
}
.filter-list .view-all-panel + .filter-title {
  margin-top: 2rem;
}
.filter-list .view-all-panel .view-all-back-btn {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  align-items: center;
  color: #00759a;
  display: flex;
  margin-left: 1.25rem;
  margin-top: 2rem;
}
.filter-list .view-all-panel .view-all-back-btn .icon {
  stroke: #00759a;
}
.filter-list .view-all-panel .view-all-close-btn {
  display: none;
}
.filter-list .view-all-panel .view-all-body {
  margin-bottom: 80px;
  padding: 1rem 10px;
}
.filter-list .view-all-panel .view-all-title-container,
.filter-list .view-all-panel .tag-list {
  padding: 0 18px;
}
.filter-list .view-all-panel .range-container {
  margin: 0 2rem;
  padding: 1.5rem 0 0;
}
.filter-list .view-all-panel .accordion-label {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: #000;
  margin: 2rem 1rem 0;
}
.filter-list .view-all-panel .nav-accordion {
  border-top: 1px solid #595756;
  margin-top: 1rem;
}
.filter-list .view-all-panel .nav-accordion .object-section {
  display: flex;
  padding: 0.5rem 1rem 0.5rem 0;
}
.filter-list .view-all-panel .nav-accordion .object-section .object-section-label {
  font-size: 1.5rem;
  line-height: 1.3333333333;
  margin-left: 1.25rem;
  margin-right: 2rem;
  min-width: 16px;
}
.filter-list .view-all-panel .nav-accordion .object-section .object-section-content {
  border-bottom: 1px solid #eee;
  padding-bottom: 0.5rem;
  width: 100%;
}
.filter-list .view-all-panel .nav-accordion .object-section:last-of-type .object-section-content {
  border-bottom: 0;
}
.filter-list .view-all-panel .view-all-no-accordion {
  border-top: none;
  padding: 0 18px;
}
.filter-list .filter-availability {
  padding: 0.25rem 0;
}
.filter-list .filter-availability hr {
  border-color: #595756;
  margin: 1.5rem 0;
}
.filter-list .filter-availability .secondary-filter-title {
  font-size: 1.625rem;
  line-height: 1.2307692308;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  color: #fff;
}
.filter-list .filter-availability p {
  font-size: 1.5rem;
  line-height: 1.3333333333;
}
.filter-list .filter-availability .radio-btns {
  padding-top: 0.25rem;
}
.filter-list .filter-availability .radio-btns input[type=radio]:checked + label {
  background-color: #00759a;
  border-color: #00759a;
}
.filter-list .filter-availability .radio-btns input[type=radio]:focus + label {
  background-color: #00759a;
  border-color: #00759a;
  box-shadow: 0 0 0 0.2rem rgba(145, 61, 0, 0.5);
}
.filter-list .filter-availability .radio-btns input[type=radio] + label {
  background-color: #00759a;
  border-color: #00759a;
}
.filter-list .filter-availability .radio-btns input[type=radio]:disabled + label {
  background-color: #eee;
  border-color: #eee;
  pointer-events: none;
}
.filter-list .filter-availability .radio-btns input[type=radio]:disabled:checked + label {
  background-color: #eee;
  border-color: #eee;
}
.filter-list .filter-gender {
  padding: 0.25rem 0;
}
.filter-list .filter-gender .radio-wrapper:last-of-type {
  margin-bottom: 0;
}
.filter-list .clear-btn-container {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 0.75rem 20px 0;
}
.filter-list .react-autosuggest__container {
  position: relative;
}
.filter-list .react-autosuggest__container--open .react-autosuggest__suggestions-container {
  border-bottom: 1px solid #bfbfbf;
}
.filter-list .react-autosuggest__suggestions-container {
  background-color: #fff;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 2;
}
.filter-list .react-autosuggest__suggestion--highlighted {
  background-color: #f9f9f9;
}
.filter-list .mobile-filter-actions {
  align-items: center;
  background-color: #fff;
  bottom: 0;
  display: flex;
  height: 80px;
  position: fixed;
  width: 100%;
  z-index: 1;
}
.filter-list .mobile-filter-actions .buttons-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .filter-list .mobile-filter-actions .buttons-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .filter-list .mobile-filter-actions .buttons-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .filter-list .mobile-filter-actions .buttons-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .filter-list .mobile-filter-actions .buttons-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .filter-list .mobile-filter-actions .buttons-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .filter-list .mobile-filter-actions .buttons-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.filter-list .mobile-clear-btn {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  align-items: center;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  color: #000;
  display: flex;
  height: 37px;
  justify-content: center;
  width: calc(50% - 0.5rem);
}
.filter-list .mobile-apply-btn {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  align-items: center;
  background-color: #363534;
  border-radius: 2px;
  color: #fff;
  display: flex;
  height: 37px;
  justify-content: center;
  width: calc(50% - 0.5rem);
}
@media (min-width: 768px) {
  .filter-list .input-icon-container input {
    font-size: 0.875rem;
    line-height: 1.4285714286;
    padding: 1.25rem 2.75rem 1.25rem 1.25rem;
  }
}
@media print and (min-width: 6px) {
  .filter-list .input-icon-container input {
    font-size: 0.875rem;
    line-height: 1.4285714286;
    padding: 1.25rem 2.75rem 1.25rem 1.25rem;
  }
}
@media (min-width: 1280px) {
  .filter-list {
    align-self: flex-start;
    background-color: #eee;
    display: block;
    height: auto;
    left: auto;
    opacity: 1;
    overflow: visible;
    pointer-events: auto;
    position: relative;
    top: auto;
    visibility: visible;
    width: auto;
    z-index: 7;
  }
  .filter-list.view-all-open {
    overflow: visible;
  }
  .filter-list .input-icon-container {
    margin: 0;
    padding: 0;
  }
  .filter-list .input-icon-container input {
    padding: 1.25rem 2.75rem 1.25rem 1rem;
  }
  .filter-list .input-icon-container button,
.filter-list .input-icon-container > .icon {
    right: 1rem;
  }
  .filter-list .accordion-container {
    margin-bottom: 0;
    padding: 0;
  }
  .filter-list .nav-accordion {
    background-color: #eee;
  }
  .filter-list .nav-accordion .accordion-item {
    border-color: #bfbfbf;
  }
  .filter-list .nav-accordion .accordion-item[aria-expanded=true] .accordion-item-title .accordion-item-toggle {
    color: #363534;
  }
  .filter-list .nav-accordion .accordion-item[aria-expanded=true].closing > .accordion-item-title .accordion-item-toggle {
    color: #363534;
  }
  .filter-list .nav-accordion .accordion-item[aria-expanded=true].closing > .accordion-item-title .accordion-item-toggle:hover, .filter-list .nav-accordion .accordion-item[aria-expanded=true].closing > .accordion-item-title .accordion-item-toggle:focus {
    color: #595756;
  }
  .filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle {
    color: #363534;
    cursor: pointer;
  }
  .filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle:hover, .filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle:focus {
    color: #00759a;
  }
  .filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle:hover .icon:not(.icon-star), .filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle:focus .icon:not(.icon-star) {
    fill: #00759a;
  }
  .filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle .icon:not(.icon-star) {
    fill: #595756;
  }
  .filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-tags {
    background-color: #eee;
  }
  .filter-list .nav-accordion .accordion-item .accordion-item-body {
    color: #222;
  }
  .filter-list .nav-accordion .accordion-item .accordion-item-body .tag-list {
    display: none;
  }
  .filter-list .nav-accordion .accordion-item .accordion-item-body .input-icon-container {
    margin-left: -1rem;
    width: calc(100% + 2rem);
  }
  .filter-list .checkbox-filter .checkbox-wrapper input[type=checkbox] + label {
    color: #363534;
  }
  .filter-list .checkbox-filter img {
    display: block;
    height: auto;
    width: 80px;
  }
  .filter-list .distance-radio {
    margin-left: -1rem;
    width: calc(100% + 2rem);
  }
  .filter-list .filter-title.mobile-only {
    display: none;
  }
  .filter-list .mobile-filter-show-btn {
    display: none;
  }
  .filter-list .mobile-filter-header {
    display: none;
  }
  .filter-list .filter-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }
  .filter-list .filter-header .filter-heading {
    font-size: 1rem;
    line-height: 1.375;
    font-family: AvenirMedium, Helvetica, sans-serif;
    font-weight: normal;
    color: #363534;
    margin: 0;
    padding-left: 1rem;
  }
  .filter-list .filter-header .clear-btn-container {
    margin-right: 2px;
  }
  .filter-list .clear-btn-container {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 0.25rem 0 1rem;
  }
  .filter-list .filter-link,
.filter-list .show-more-trigger {
    color: #00759a;
  }
  .filter-list .range-container {
    margin: 0;
  }
  .filter-list .view-all-panel {
    color: #222;
    display: none;
    height: auto;
    min-height: 0;
    transform: none;
  }
  .filter-list .view-all-panel.open {
    display: block;
    overflow: visible;
    position: relative;
  }
  .filter-list .view-all-panel .mobile-filter-header {
    display: none;
  }
  .filter-list .view-all-panel .view-all-title-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: 0;
  }
  .filter-list .view-all-panel .view-all-title-container .filter-title {
    margin-bottom: 0;
  }
  .filter-list .view-all-panel .view-all-title-container .view-all-close-btn {
    display: block;
  }
  .filter-list .view-all-panel .view-all-title-container .view-all-close-btn .icon-menu-close {
    height: 1rem;
    stroke: #363534;
    width: 1rem;
  }
  .filter-list .view-all-panel .view-all-title-container .view-all-close-btn:hover .icon-menu-close, .filter-list .view-all-panel .view-all-title-container .view-all-close-btn:focus .icon-menu-close {
    stroke: #000;
  }
  .filter-list .view-all-panel .view-all-body {
    margin-bottom: 0;
    padding: 0;
  }
  .filter-list .view-all-panel .rbt {
    margin: 0;
  }
  .filter-list .view-all-panel .range-container {
    margin: 0;
    padding: 1.5rem 0 0;
  }
  .filter-list .view-all-panel .tag-list {
    padding: 0 0.1rem;
  }
  .filter-list .view-all-panel .accordion-label {
    margin: 2rem 0 0;
  }
  .filter-list .view-all-panel .nav-accordion {
    border-top: 1px solid #eee;
  }
  .filter-list .view-all-panel .nav-accordion .object-section {
    padding-right: 1.5rem;
  }
  .filter-list .view-all-panel .view-all-no-accordion {
    border-color: #bfbfbf;
    padding: 0;
  }
  .filter-list .view-all-panel .view-all-back-btn {
    margin-left: 0;
  }
  .filter-list .view-all-panel .view-all-back-btn:hover, .filter-list .view-all-panel .view-all-back-btn:focus {
    text-decoration: underline;
  }
  .filter-list .view-all-panel .view-all-back-btn:hover .icon, .filter-list .view-all-panel .view-all-back-btn:focus .icon {
    margin-left: -0.3125rem;
    margin-right: 10px;
    transition: margin 0.1s ease-in-out;
  }
  .filter-list .filter-availability hr {
    border-color: rgba(0, 0, 0, 0.1);
  }
  .filter-list .filter-availability .secondary-filter-title {
    color: #363534;
  }
  .filter-list .filter-availability .radio-btns input[type=radio]:checked + label {
    background-color: #363534;
    border-color: #363534;
  }
  .filter-list .filter-availability .radio-btns input[type=radio]:focus + label {
    background-color: #363534;
    border-color: #363534;
    box-shadow: 0 0 0 0.2rem rgba(54, 53, 52, 0.35);
  }
  .filter-list .filter-availability .radio-btns input[type=radio] + label {
    background-color: #595756;
    border-color: #595756;
  }
  .filter-list .filter-gender {
    padding: 0.25rem 0;
  }
  .filter-list .filter-gender .radio-wrapper:last-of-type {
    margin-bottom: 0;
  }
  .filter-list .mobile-filter-actions {
    display: none;
  }
}
@media print and (min-width: 7px) {
  .filter-list {
    align-self: flex-start;
    background-color: #eee;
    display: block;
    height: auto;
    left: auto;
    opacity: 1;
    overflow: visible;
    pointer-events: auto;
    position: relative;
    top: auto;
    visibility: visible;
    width: auto;
    z-index: 7;
  }
  .filter-list.view-all-open {
    overflow: visible;
  }
  .filter-list .input-icon-container {
    margin: 0;
    padding: 0;
  }
  .filter-list .input-icon-container input {
    padding: 1.25rem 2.75rem 1.25rem 1rem;
  }
  .filter-list .input-icon-container button,
.filter-list .input-icon-container > .icon {
    right: 1rem;
  }
  .filter-list .accordion-container {
    margin-bottom: 0;
    padding: 0;
  }
  .filter-list .nav-accordion {
    background-color: #eee;
  }
  .filter-list .nav-accordion .accordion-item {
    border-color: #bfbfbf;
  }
  .filter-list .nav-accordion .accordion-item[aria-expanded=true] .accordion-item-title .accordion-item-toggle {
    color: #363534;
  }
  .filter-list .nav-accordion .accordion-item[aria-expanded=true].closing > .accordion-item-title .accordion-item-toggle {
    color: #363534;
  }
  .filter-list .nav-accordion .accordion-item[aria-expanded=true].closing > .accordion-item-title .accordion-item-toggle:hover, .filter-list .nav-accordion .accordion-item[aria-expanded=true].closing > .accordion-item-title .accordion-item-toggle:focus {
    color: #595756;
  }
  .filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle {
    color: #363534;
    cursor: pointer;
  }
  .filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle:hover, .filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle:focus {
    color: #00759a;
  }
  .filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle:hover .icon:not(.icon-star), .filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle:focus .icon:not(.icon-star) {
    fill: #00759a;
  }
  .filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle .icon:not(.icon-star) {
    fill: #595756;
  }
  .filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-tags {
    background-color: #eee;
  }
  .filter-list .nav-accordion .accordion-item .accordion-item-body {
    color: #222;
  }
  .filter-list .nav-accordion .accordion-item .accordion-item-body .tag-list {
    display: none;
  }
  .filter-list .nav-accordion .accordion-item .accordion-item-body .input-icon-container {
    margin-left: -1rem;
    width: calc(100% + 2rem);
  }
  .filter-list .checkbox-filter .checkbox-wrapper input[type=checkbox] + label {
    color: #363534;
  }
  .filter-list .checkbox-filter img {
    display: block;
    height: auto;
    width: 80px;
  }
  .filter-list .distance-radio {
    margin-left: -1rem;
    width: calc(100% + 2rem);
  }
  .filter-list .filter-title.mobile-only {
    display: none;
  }
  .filter-list .mobile-filter-show-btn {
    display: none;
  }
  .filter-list .mobile-filter-header {
    display: none;
  }
  .filter-list .filter-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }
  .filter-list .filter-header .filter-heading {
    font-size: 1rem;
    line-height: 1.375;
    font-family: AvenirMedium, Helvetica, sans-serif;
    font-weight: normal;
    color: #363534;
    margin: 0;
    padding-left: 1rem;
  }
  .filter-list .filter-header .clear-btn-container {
    margin-right: 2px;
  }
  .filter-list .clear-btn-container {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 0.25rem 0 1rem;
  }
  .filter-list .filter-link,
.filter-list .show-more-trigger {
    color: #00759a;
  }
  .filter-list .range-container {
    margin: 0;
  }
  .filter-list .view-all-panel {
    color: #222;
    display: none;
    height: auto;
    min-height: 0;
    transform: none;
  }
  .filter-list .view-all-panel.open {
    display: block;
    overflow: visible;
    position: relative;
  }
  .filter-list .view-all-panel .mobile-filter-header {
    display: none;
  }
  .filter-list .view-all-panel .view-all-title-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: 0;
  }
  .filter-list .view-all-panel .view-all-title-container .filter-title {
    margin-bottom: 0;
  }
  .filter-list .view-all-panel .view-all-title-container .view-all-close-btn {
    display: block;
  }
  .filter-list .view-all-panel .view-all-title-container .view-all-close-btn .icon-menu-close {
    height: 1rem;
    stroke: #363534;
    width: 1rem;
  }
  .filter-list .view-all-panel .view-all-title-container .view-all-close-btn:hover .icon-menu-close, .filter-list .view-all-panel .view-all-title-container .view-all-close-btn:focus .icon-menu-close {
    stroke: #000;
  }
  .filter-list .view-all-panel .view-all-body {
    margin-bottom: 0;
    padding: 0;
  }
  .filter-list .view-all-panel .rbt {
    margin: 0;
  }
  .filter-list .view-all-panel .range-container {
    margin: 0;
    padding: 1.5rem 0 0;
  }
  .filter-list .view-all-panel .tag-list {
    padding: 0 0.1rem;
  }
  .filter-list .view-all-panel .accordion-label {
    margin: 2rem 0 0;
  }
  .filter-list .view-all-panel .nav-accordion {
    border-top: 1px solid #eee;
  }
  .filter-list .view-all-panel .nav-accordion .object-section {
    padding-right: 1.5rem;
  }
  .filter-list .view-all-panel .view-all-no-accordion {
    border-color: #bfbfbf;
    padding: 0;
  }
  .filter-list .view-all-panel .view-all-back-btn {
    margin-left: 0;
  }
  .filter-list .view-all-panel .view-all-back-btn:hover, .filter-list .view-all-panel .view-all-back-btn:focus {
    text-decoration: underline;
  }
  .filter-list .view-all-panel .view-all-back-btn:hover .icon, .filter-list .view-all-panel .view-all-back-btn:focus .icon {
    margin-left: -0.3125rem;
    margin-right: 10px;
    transition: margin 0.1s ease-in-out;
  }
  .filter-list .filter-availability hr {
    border-color: rgba(0, 0, 0, 0.1);
  }
  .filter-list .filter-availability .secondary-filter-title {
    color: #363534;
  }
  .filter-list .filter-availability .radio-btns input[type=radio]:checked + label {
    background-color: #363534;
    border-color: #363534;
  }
  .filter-list .filter-availability .radio-btns input[type=radio]:focus + label {
    background-color: #363534;
    border-color: #363534;
    box-shadow: 0 0 0 0.2rem rgba(54, 53, 52, 0.35);
  }
  .filter-list .filter-availability .radio-btns input[type=radio] + label {
    background-color: #595756;
    border-color: #595756;
  }
  .filter-list .filter-gender {
    padding: 0.25rem 0;
  }
  .filter-list .filter-gender .radio-wrapper:last-of-type {
    margin-bottom: 0;
  }
  .filter-list .mobile-filter-actions {
    display: none;
  }
}

.footer .link-list {
  list-style: none;
  margin: 0;
  padding-bottom: 2rem;
}
.footer .link-list li {
  line-height: 0;
  margin: 0;
  padding: 0 0 0.5rem;
}
.footer .link-list li:before {
  display: none;
}
.footer .link-list a {
  font-size: 1rem;
  line-height: 1.375;
  color: #df7a00;
  display: inline-block;
  padding: 0.125rem 0;
  text-decoration: none;
}
.footer .link-list a:hover, .footer .link-list a:focus {
  text-decoration: underline;
}
.footer .footer-cta {
  background-color: #363534;
  padding-top: 30px;
}
.footer .footer-cta .footer-cta-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
}
@media (min-width: 768px) {
  .footer .footer-cta .footer-cta-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .footer .footer-cta .footer-cta-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .footer .footer-cta .footer-cta-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .footer .footer-cta .footer-cta-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .footer .footer-cta .footer-cta-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .footer .footer-cta .footer-cta-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.footer .footer-cta .footer-cta-heading {
  font-size: 1rem;
  line-height: 1.375;
  color: #fff;
  margin-bottom: 0.25rem;
}
.footer .footer-cta .footer-cta-description {
  font-size: 1rem;
  line-height: 1.375;
  color: #eee;
  margin-bottom: 3rem;
}
.footer .footer-navigation {
  background-color: #000;
  color: #fff;
  overflow: hidden;
}
.footer .footer-navigation .footer-navigation-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  display: flex;
  padding-bottom: 32px;
  padding-top: 41px;
  position: relative;
}
@media (min-width: 768px) {
  .footer .footer-navigation .footer-navigation-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .footer .footer-navigation .footer-navigation-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .footer .footer-navigation .footer-navigation-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .footer .footer-navigation .footer-navigation-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .footer .footer-navigation .footer-navigation-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .footer .footer-navigation .footer-navigation-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.footer .footer-navigation .back-to-top-btn {
  bottom: 31px;
  left: 31px;
  position: absolute;
}
.footer .footer-navigation .back-to-top-btn .icon-arrow-down {
  height: 9px;
  stroke: #fff;
  transform: rotate(180deg);
  width: 19px;
}
.footer .footer-navigation .links-feedback,
.footer .footer-navigation .links-legal {
  flex-basis: 50%;
}
.footer .footer-navigation a {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #bfbfbf;
}
.footer .footer-navigation .copyright {
  bottom: 19px;
  color: #bfbfbf;
  font-size: 9px;
  left: calc(100% - 19px);
  position: absolute;
  transform: rotate(-90deg);
  transform-origin: top left 0;
  width: 100%;
}
@media (min-width: 1280px) {
  .footer .footer-cta .grid-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  .footer .footer-cta .grid-col {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .footer .footer-cta .grid-col:nth-child(1) {
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .footer .footer-cta .grid-col:nth-child(2) {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .footer .footer-cta .grid-col:nth-child(3) {
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .footer .footer-cta .footer-cta-heading {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
  .footer .footer-cta .footer-cta-description {
    margin-bottom: 3rem;
    padding-right: 2rem;
  }
  .footer .footer-cta .link-list li {
    padding: 0 0 0.25rem;
  }
  .footer .footer-cta .link-list a {
    padding: 0;
  }
  .footer .footer-navigation {
    padding-bottom: 0;
    padding-top: 0;
  }
  .footer .footer-navigation .footer-navigation-container {
    align-items: center;
    flex-direction: row;
    height: 60px;
  }
  .footer .footer-navigation .footer-navigation-container .back-to-top-btn {
    bottom: auto;
    left: auto;
    padding-left: 2rem;
    position: relative;
  }
  .footer .footer-navigation .footer-navigation-container .back-to-top-btn .icon-arrow-down {
    height: 17px;
    stroke-width: 1px;
    width: 34px;
  }
  .footer .footer-navigation .footer-navigation-container .link-list {
    padding-bottom: 0;
  }
  .footer .footer-navigation .footer-navigation-container .link-list li {
    display: inline-block;
    padding: 0;
  }
  .footer .footer-navigation .footer-navigation-container a {
    padding: 0.5rem 1rem;
  }
  .footer .footer-navigation .footer-navigation-container .copyright {
    bottom: auto;
    left: auto;
    margin-left: auto;
    position: relative;
    transform: none;
    width: auto;
  }
  .footer .footer-navigation .footer-navigation-container .links-feedback,
.footer .footer-navigation .footer-navigation-container .links-legal {
    flex-basis: unset;
  }
  .footer .footer-navigation .footer-navigation-container .links-feedback {
    padding-right: 1rem;
    position: relative;
  }
  .footer .footer-navigation .footer-navigation-container .links-feedback:after {
    border-right: 1px solid #bfbfbf;
    content: "";
    display: block;
    height: 20px;
    position: absolute;
    right: 0;
    top: 7px;
    width: 1px;
  }
  .footer .footer-navigation .footer-navigation-container .links-legal {
    padding-left: 1rem;
  }
}
@media print and (min-width: 7px) {
  .footer .footer-cta .grid-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  .footer .footer-cta .grid-col {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .footer .footer-cta .grid-col:nth-child(1) {
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .footer .footer-cta .grid-col:nth-child(2) {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .footer .footer-cta .grid-col:nth-child(3) {
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .footer .footer-cta .footer-cta-heading {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
  .footer .footer-cta .footer-cta-description {
    margin-bottom: 3rem;
    padding-right: 2rem;
  }
  .footer .footer-cta .link-list li {
    padding: 0 0 0.25rem;
  }
  .footer .footer-cta .link-list a {
    padding: 0;
  }
  .footer .footer-navigation {
    padding-bottom: 0;
    padding-top: 0;
  }
  .footer .footer-navigation .footer-navigation-container {
    align-items: center;
    flex-direction: row;
    height: 60px;
  }
  .footer .footer-navigation .footer-navigation-container .back-to-top-btn {
    bottom: auto;
    left: auto;
    padding-left: 2rem;
    position: relative;
  }
  .footer .footer-navigation .footer-navigation-container .back-to-top-btn .icon-arrow-down {
    height: 17px;
    stroke-width: 1px;
    width: 34px;
  }
  .footer .footer-navigation .footer-navigation-container .link-list {
    padding-bottom: 0;
  }
  .footer .footer-navigation .footer-navigation-container .link-list li {
    display: inline-block;
    padding: 0;
  }
  .footer .footer-navigation .footer-navigation-container a {
    padding: 0.5rem 1rem;
  }
  .footer .footer-navigation .footer-navigation-container .copyright {
    bottom: auto;
    left: auto;
    margin-left: auto;
    position: relative;
    transform: none;
    width: auto;
  }
  .footer .footer-navigation .footer-navigation-container .links-feedback,
.footer .footer-navigation .footer-navigation-container .links-legal {
    flex-basis: unset;
  }
  .footer .footer-navigation .footer-navigation-container .links-feedback {
    padding-right: 1rem;
    position: relative;
  }
  .footer .footer-navigation .footer-navigation-container .links-feedback:after {
    border-right: 1px solid #bfbfbf;
    content: "";
    display: block;
    height: 20px;
    position: absolute;
    right: 0;
    top: 7px;
    width: 1px;
  }
  .footer .footer-navigation .footer-navigation-container .links-legal {
    padding-left: 1rem;
  }
}

/* $hue-range is in degrees (-360 to 360) */
.grid-overlay {
  display: none;
}
.grid-overlay.show-grid {
  display: block;
}
.grid-overlay.active .grid-example {
  display: flex;
}
.grid-overlay.active .toggle-grid-btn .on {
  display: block;
}
.grid-overlay.active .toggle-grid-btn .off {
  display: none;
}
.grid-overlay .toggle-grid-btn {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  left: 0;
  min-width: 0;
  opacity: 0.5;
  padding: 0.25rem;
  position: fixed;
  top: 0;
  z-index: 9;
}
.grid-overlay .toggle-grid-btn .on {
  display: none;
}
.grid-overlay .toggle-grid-btn .off {
  display: block;
}
.grid-overlay .grid-example {
  display: none;
  bottom: 0;
  left: 0;
  opacity: 0.8;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 8;
}
.grid-overlay .grid-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
}
@media (min-width: 768px) {
  .grid-overlay .grid-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .grid-overlay .grid-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .grid-overlay .grid-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .grid-overlay .grid-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .grid-overlay .grid-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .grid-overlay .grid-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.grid-overlay .grid-row {
  flex-grow: 1;
}
.grid-overlay .grid-row .grid-col {
  background-color: transparent;
  height: 100vh;
}
.grid-overlay .grid-row .grid-col:nth-child(1) .grid-content {
  background-color: rgba(72, 199, 197, 0.2);
}
.grid-overlay .grid-row .grid-col:nth-child(2) .grid-content {
  background-color: rgba(72, 199, 181, 0.2);
}
.grid-overlay .grid-row .grid-col:nth-child(3) .grid-content {
  background-color: rgba(72, 199, 165, 0.2);
}
.grid-overlay .grid-row .grid-col:nth-child(4) .grid-content {
  background-color: rgba(72, 199, 150, 0.2);
}
.grid-overlay .grid-row .grid-col:nth-child(5) .grid-content {
  background-color: rgba(72, 199, 134, 0.2);
}
.grid-overlay .grid-row .grid-col:nth-child(6) .grid-content {
  background-color: rgba(72, 199, 118, 0.2);
}
.grid-overlay .grid-row .grid-col:nth-child(7) .grid-content {
  background-color: rgba(72, 199, 102, 0.2);
}
.grid-overlay .grid-row .grid-col:nth-child(8) .grid-content {
  background-color: rgba(72, 199, 86, 0.2);
}
.grid-overlay .grid-row .grid-col:nth-child(9) .grid-content {
  background-color: rgba(74, 199, 72, 0.2);
}
.grid-overlay .grid-row .grid-col:nth-child(10) .grid-content {
  background-color: rgba(90, 199, 72, 0.2);
}
.grid-overlay .grid-row .grid-col:nth-child(11) .grid-content {
  background-color: rgba(106, 199, 72, 0.2);
}
.grid-overlay .grid-row .grid-col:nth-child(12) .grid-content {
  background-color: rgba(122, 199, 72, 0.2);
}

.location-result {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  background-color: #f9f9f9;
  color: #363534;
  height: 100%;
}
.location-result p {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
}
.location-result a {
  color: #00759a;
}
.location-result .profile-image-container {
  width: 100%;
  background-color: #eee;
}
.location-result .profile-image {
  padding-bottom: 66.6667%;
  display: block;
  height: 0;
  overflow: hidden;
  position: relative;
}
.location-result .profile-image img {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  font-family: "object-fit: cover; object-position: center 25%;";
  object-fit: cover;
  object-position: center 25%;
}
.location-result .provider-website .icon-external {
  height: 0.5rem;
  margin-left: 0.25rem;
  stroke: #00759a;
  width: 0.5rem;
}
.location-result .location-hours {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  display: flex;
  justify-content: space-between;
  height: 40px;
  padding: 12px 20px;
}
.location-result .location-hours .tooltip-link {
  align-items: flex-start;
  color: #00759a;
  display: flex;
}
.location-result .location-hours .tooltip-link:hover, .location-result .location-hours .tooltip-link:focus {
  text-decoration: underline;
}
.location-result .location-hours .tooltip:not([flow]):after, .location-result .location-hours .tooltip[flow^=up]:after, .location-result .location-hours .tooltip[flow^=left]:after {
  padding: 0.2rem;
}
.location-result .location-hours span {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
}
.location-result .location-details {
  padding: 1.5rem 30px;
}
.location-result .location-name {
  font-size: 1.25rem;
  line-height: 1.2;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  color: #000;
}
.location-result .distance-and-location {
  margin: 1rem 0;
}
.location-result .open-weekend-icon-container {
  display: flex;
}
.location-result .location-address {
  display: flex;
  flex-direction: column-reverse;
}
.location-result .location-address .location-distance-phone {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}
.location-result .location-address address {
  font-size: 0.875rem;
  line-height: 1.2857142857;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  margin-top: 16px;
}
.location-result .detail-bottom {
  margin-top: 16px;
}
.location-result .detail-bottom p {
  margin-bottom: 0;
}
.location-result .schedule-title {
  display: none;
}
.location-result .schedule-call-container {
  display: none;
}
.location-result .schedule-online .btn-outline {
  width: 100%;
}
@media (min-width: 768px) {
  .location-result.grid-result .location-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(53% - 40px);
  }
  .location-result:not(.grid-result) {
    display: flex;
    padding: 20px;
    margin-bottom: 30px;
  }
  .location-result:not(.grid-result) .left {
    width: 265px;
  }
  .location-result:not(.grid-result) .right {
    width: 145px;
    padding-top: 4px;
  }
  .location-result:not(.grid-result) .profile-image-container {
    width: 245px;
    height: 100%;
  }
  .location-result:not(.grid-result) .profile-image-container .profile-image {
    padding-bottom: 62.041%;
  }
  .location-result:not(.grid-result) .location-hours {
    height: 70px;
    padding: 19px 15px;
  }
  .location-result:not(.grid-result) .location-type {
    margin-bottom: 5px;
  }
  .location-result:not(.grid-result) .location-name {
    font-size: 1.125rem;
    line-height: 1.3333333333;
    display: block;
    margin-bottom: 5px;
  }
  .location-result:not(.grid-result) .location-details {
    padding: 0 0 0 20px;
    width: calc(100% - 180px);
  }
  .location-result:not(.grid-result) .detail-top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 50%;
  }
  .location-result:not(.grid-result) .detail-top > div {
    margin: 0;
  }
  .location-result:not(.grid-result) .detail-bottom {
    border-top: 1px solid #bfbfbf;
    padding-top: 16px;
  }
  .location-result:not(.grid-result) .location-address {
    flex-direction: column;
  }
  .location-result:not(.grid-result) .location-address .location-distance-phone {
    display: block;
    margin-top: 0;
  }
  .location-result:not(.grid-result) .schedule-title {
    display: block;
    font-size: 1.125rem;
    line-height: 1.3333333333;
    font-family: AvenirHeavy, Helvetica, sans-serif;
    font-weight: normal;
    color: #000;
    margin-bottom: 6px;
  }
  .location-result:not(.grid-result) .schedule-call-container {
    display: block;
  }
  .location-result:not(.grid-result) .schedule-options {
    display: flex;
    justify-content: space-between;
  }
}
@media print and (min-width: 6px) {
  .location-result.grid-result .location-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(53% - 40px);
  }
  .location-result:not(.grid-result) {
    display: flex;
    padding: 20px;
    margin-bottom: 30px;
  }
  .location-result:not(.grid-result) .left {
    width: 265px;
  }
  .location-result:not(.grid-result) .right {
    width: 145px;
    padding-top: 4px;
  }
  .location-result:not(.grid-result) .profile-image-container {
    width: 245px;
    height: 100%;
  }
  .location-result:not(.grid-result) .profile-image-container .profile-image {
    padding-bottom: 62.041%;
  }
  .location-result:not(.grid-result) .location-hours {
    height: 70px;
    padding: 19px 15px;
  }
  .location-result:not(.grid-result) .location-type {
    margin-bottom: 5px;
  }
  .location-result:not(.grid-result) .location-name {
    font-size: 1.125rem;
    line-height: 1.3333333333;
    display: block;
    margin-bottom: 5px;
  }
  .location-result:not(.grid-result) .location-details {
    padding: 0 0 0 20px;
    width: calc(100% - 180px);
  }
  .location-result:not(.grid-result) .detail-top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 50%;
  }
  .location-result:not(.grid-result) .detail-top > div {
    margin: 0;
  }
  .location-result:not(.grid-result) .detail-bottom {
    border-top: 1px solid #bfbfbf;
    padding-top: 16px;
  }
  .location-result:not(.grid-result) .location-address {
    flex-direction: column;
  }
  .location-result:not(.grid-result) .location-address .location-distance-phone {
    display: block;
    margin-top: 0;
  }
  .location-result:not(.grid-result) .schedule-title {
    display: block;
    font-size: 1.125rem;
    line-height: 1.3333333333;
    font-family: AvenirHeavy, Helvetica, sans-serif;
    font-weight: normal;
    color: #000;
    margin-bottom: 6px;
  }
  .location-result:not(.grid-result) .schedule-call-container {
    display: block;
  }
  .location-result:not(.grid-result) .schedule-options {
    display: flex;
    justify-content: space-between;
  }
}
@media (min-width: 1280px) {
  .location-result:not(.grid-result) .location-details {
    width: calc(100% - 245px);
  }
  .filters-closed .location-result:not(.grid-result) .location-details {
    width: calc(100% - 380px);
  }
  .filters-closed .location-result:not(.grid-result) .left {
    width: 360px;
  }
  .filters-closed .location-result:not(.grid-result) .right {
    width: 265px;
  }
}
@media print and (min-width: 7px) {
  .location-result:not(.grid-result) .location-details {
    width: calc(100% - 245px);
  }
  .filters-closed .location-result:not(.grid-result) .location-details {
    width: calc(100% - 380px);
  }
  .filters-closed .location-result:not(.grid-result) .left {
    width: 360px;
  }
  .filters-closed .location-result:not(.grid-result) .right {
    width: 265px;
  }
}

.modal {
  display: none;
  height: 100%;
  left: 0;
  opacity: 0;
  outline: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.3s ease-in-out;
  width: 100%;
  z-index: 10;
}
.modal.visible {
  display: block;
  background-color: #fff;
}
.modal.open {
  opacity: 1;
  pointer-events: auto;
}
.modal.iframe-modal .modal-inner,
.modal.iframe-modal .iframe-container {
  height: calc(100vh - 60px);
}
.modal.iframe-modal .modal-inner {
  padding: 0;
  text-align: center;
}
.modal.iframe-modal iframe {
  height: calc(100vh - 60px);
}
.modal.iframe-modal .schedule-header,
.modal.iframe-modal .modal-title {
  display: none;
}
.modal.full-screen .modal-inner-container {
  display: flex;
  flex-direction: column;
}
.modal.full-screen .modal-row {
  flex-grow: 1;
}
.at .modal {
  height: 100%;
  width: 100%;
}
.at .modal .modal-container {
  background-color: #eee;
}
.at .modal .modal-header, .at .modal .echeckin-modal-header {
  background-color: #fff;
}
.at .modal p {
  font-size: 1.625rem;
  line-height: 1.1538461538;
}
.at .modal a {
  font-size: 1.625rem;
  line-height: 1.1538461538;
  color: #00b0e6;
  display: inline-block;
  margin-top: 0.25rem;
  text-decoration: none;
  word-wrap: break-word;
}
.at .modal a:hover, .at .modal a:focus {
  color: #000;
  text-decoration: none;
}
.at .modal .modal-description {
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1.3333333333;
}
.at .modal .modal-subdescription {
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.375;
}
.at .modal .modal-subdescription a {
  font-size: 1rem;
  line-height: 1.375;
  color: #00759a;
}
.at .modal .banner-alert {
  margin-top: 0rem;
  width: 100%;
}
.at .modal .banner-alert p {
  font-size: 1rem;
  line-height: 1.375;
}
.modal .desktop-only {
  display: none;
}
.modal .btn-alert-close {
  background-color: #363534 !important;
  color: white !important;
  height: auto !important;
  padding: 0.5rem !important;
  float: right !important;
  margin-top: 2rem !important;
}
.modal .modal-close-btn {
  background-color: transparent;
  border: none;
  height: 1rem;
  outline: 0;
  padding: 0;
  width: 1rem;
}
.modal .modal-close-btn:hover, .modal .modal-close-btn:focus {
  cursor: pointer;
}
.modal .modal-close-btn:hover .icon, .modal .modal-close-btn:focus .icon {
  stroke: #222;
  stroke-width: 5px;
}
.modal .modal-close-btn:focus-visible {
  border: 2px solid #000;
}
.modal .modal-close-btn.text-close {
  color: #00759a;
  height: auto;
  transform: none;
  width: auto;
}
.modal .modal-close-btn.text-close:hover, .modal .modal-close-btn.text-close:focus {
  text-decoration: underline;
}
.modal .modal-close-btn .icon {
  height: 1rem;
  stroke: #000;
  stroke-width: 4px;
  width: 1rem;
}
.modal .modal-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.modal .modal-header, .modal .echeckin-modal-header {
  background-color: #eee;
  display: flex;
  min-height: 60px;
  align-items: center;
}
.modal .modal-header .container, .modal .echeckin-modal-header .container {
  display: flex;
  justify-content: space-between;
}
.modal .modal-header .heading-3, .modal .echeckin-modal-header .heading-3 {
  font-size: 1.125rem;
  line-height: 1;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 0;
}
.modal .modal-inner-container {
  overflow: auto;
}
.modal .modal-body {
  flex-grow: 1;
  overflow-y: auto;
}
.modal .modal-inner {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  overflow: auto;
  padding: 1.5rem 30px 4rem;
}
@media (min-width: 768px) {
  .modal .modal-inner {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .modal .modal-inner {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .modal .modal-inner {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .modal .modal-inner {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .modal .modal-inner {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .modal .modal-inner {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.modal .modal-inner .container {
  padding: 0;
}
.modal .heading-3 {
  font-size: 2.5rem;
  line-height: 1.2;
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
}
.modal p,
.modal li {
  font-size: 0.875rem;
  line-height: 1.4285714286;
}
.modal p .btn-link,
.modal li .btn-link {
  text-align: left;
}
.modal a {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #00759a;
  display: inline-block;
  text-decoration: none;
  word-wrap: break-word;
}
.modal a:hover, .modal a:focus {
  text-decoration: underline;
}
.modal .button-group {
  display: flex;
  flex-direction: column;
}
.modal .button-group .btn-primary {
  margin-bottom: 1rem;
  width: 100%;
}
.modal .button-group :last-child {
  margin-bottom: 0;
}
.modal .submit-btn {
  background: linear-gradient(to right, transparent 50%, #00b0e6 50%);
  background-size: calc(200% + 2px) 100%;
  background-position: left 100%;
  position: relative;
  transition: color 0.1s linear, background-position 0.1s linear;
  z-index: 0;
  font-size: 1.5rem;
  line-height: 1.3333333333;
  background-color: transparent;
  border: none;
  color: #fff;
  float: right;
  opacity: 0;
  padding: 0;
  transition: opacity 0.2s ease-in-out, color 0.1s linear, background-position 0.1s linear;
}
.modal .submit-btn:before {
  background-color: transparent;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 3px;
  z-index: -1;
  transition: background-color 0.05s linear;
}
.modal .submit-btn:hover, .modal .submit-btn:focus {
  background-position: -100% 100%;
  text-decoration: none;
}
.modal .submit-btn:hover:before, .modal .submit-btn:focus:before {
  background-color: #00b0e6;
}
.modal .submit-btn:hover, .modal .submit-btn:focus {
  color: #000;
  cursor: pointer;
}
.modal .modal-title {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1.25;
  margin-bottom: 1rem;
}
.modal .modal-description {
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.4285714286;
}
.modal .banner-btn {
  background-color: #00b0e6;
  display: block;
  margin-bottom: 0;
  padding: 30px 0;
  width: 100%;
  text-decoration: none;
}
.modal .banner-btn.disabled {
  background-color: #bfbfbf;
  color: #000;
}
.modal .banner-btn.disabled:hover, .modal .banner-btn.disabled:focus {
  background-color: #bfbfbf;
}
.modal .banner-btn.disabled:hover span, .modal .banner-btn.disabled:focus span {
  color: #000;
}
.modal .banner-btn.disabled:hover span svg, .modal .banner-btn.disabled:focus span svg {
  stroke: #000;
}
.modal .banner-btn.disabled:hover span .icon-arrow-right, .modal .banner-btn.disabled:focus span .icon-arrow-right {
  stroke: #000;
}
.modal .banner-btn span {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.2222222222;
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  align-items: center;
  color: #000;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .modal .banner-btn span {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .modal .banner-btn span {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .modal .banner-btn span {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .modal .banner-btn span {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .modal .banner-btn span {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .modal .banner-btn span {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.modal .banner-btn span svg {
  stroke: #000;
}
.modal .banner-btn span .icon-arrow-right {
  stroke: #000;
}
.modal .banner-btn:hover, .modal .banner-btn:focus {
  background-color: #000;
}
.modal .banner-btn:hover span, .modal .banner-btn:focus span {
  color: #fff;
}
.modal .banner-btn:hover span svg, .modal .banner-btn:focus span svg {
  stroke: #fff;
}
.modal .banner-btn:hover span .icon-arrow-right, .modal .banner-btn:focus span .icon-arrow-right {
  stroke: #fff;
}
.modal .no-span {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.2222222222;
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  align-items: center;
  color: #000;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .modal .no-span {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .modal .no-span {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .modal .no-span {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .modal .no-span {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .modal .no-span {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .modal .no-span {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.modal .no-span svg {
  stroke: #000;
}
.modal .no-span .icon-arrow-right {
  stroke: #000;
}
.modal .no-span:hover, .modal .no-span:focus {
  color: #fff;
}
.modal .no-span:hover svg, .modal .no-span:focus svg {
  stroke: #fff;
}
.modal .no-span:hover .icon-arrow-right, .modal .no-span:focus .icon-arrow-right {
  stroke: #fff;
}
.modal .content-slides .sign-in-btn {
  display: none;
}
.modal .form.ready.invalid input {
  padding-left: 1rem;
}
.modal .form.ready .error-message {
  padding-left: 1rem;
}
.modal .form .error-message {
  align-items: left;
  justify-content: left;
}
.modal input[type=text] {
  background-color: white;
  padding-left: 1rem;
  text-align: left;
  padding-top: 2rem;
}
@media (min-width: 1280px) {
  .modal {
    background-color: rgba(54, 53, 52, 0.75);
    overflow-y: scroll;
  }
  .modal.visible {
    align-items: center;
    background-color: rgba(54, 53, 52, 0.75);
    display: flex;
  }
  .modal.iframe-modal .modal-inner {
    height: 500px;
    width: 698.666px;
  }
  .modal.iframe-modal .iframe-container {
    height: calc(454px - (2.1875rem + 4px));
  }
  .modal.iframe-modal iframe {
    height: 500px;
    width: 698.666px;
  }
  .modal.iframe-modal .schedule-header,
.modal.iframe-modal .modal-title {
    display: block;
  }
  .modal.iframe-modal .schedule-header:first-child,
.modal.iframe-modal .modal-title:first-child {
    display: none;
  }
  .at .modal .modal-container {
    flex-direction: column;
    height: 100%;
    max-width: none;
    padding: 0;
    width: 100%;
  }
  .at .modal .modal-header, .at .modal .echeckin-modal-header {
    width: 100%;
  }
  .at .modal .modal-header .heading-3, .at .modal .echeckin-modal-header .heading-3 {
    display: block;
  }
  .at .modal .modal-header .modal-close-btn, .at .modal .echeckin-modal-header .modal-close-btn {
    transform: none;
  }
  .at .modal .modal-header .modal-close-btn .icon, .at .modal .echeckin-modal-header .modal-close-btn .icon {
    height: 1rem;
    stroke-width: 4px;
    width: 1rem;
  }
  .at .modal .modal-body {
    width: 100%;
  }
  .at .modal .modal-inner {
    padding: 1.5rem 70px 4rem;
  }
  .modal.full-screen .modal-container,
.modal.full-screen .modal-inner-container {
    flex-direction: column;
    height: 100%;
    margin: 0;
    max-width: none;
    padding: 0;
    width: 100%;
  }
  .modal.full-screen .modal-inner-container {
    flex: 1;
  }
  .modal.full-screen .modal-header, .modal.full-screen .echeckin-modal-header {
    background-color: #eee;
    display: flex;
    min-height: 60px;
    align-items: center;
    width: 100%;
  }
  .modal.full-screen .modal-header .heading-3, .modal.full-screen .echeckin-modal-header .heading-3 {
    display: block;
  }
  .modal.full-screen .modal-header .modal-close-btn, .modal.full-screen .echeckin-modal-header .modal-close-btn {
    transform: none;
  }
  .modal.full-screen .modal-header .modal-close-btn .icon, .modal.full-screen .echeckin-modal-header .modal-close-btn .icon {
    height: 1rem;
    stroke-width: 4px;
    width: 1rem;
  }
  .modal.full-screen .modal-row {
    align-items: center;
    display: flex;
  }
  .modal.full-screen .modal-body {
    width: 100%;
  }
  .modal.full-screen .modal-inner {
    padding: 1.5rem 70px 4rem;
  }
  .modal .modal-container {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
    align-items: flex-start;
    flex-direction: row;
    height: auto;
    background-color: transparent;
    justify-content: center;
    max-height: 100vh;
    pointer-events: auto;
  }
}
@media (min-width: 1280px) and (min-width: 768px) {
  .modal .modal-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 1280px) and (min-width: 6px) {
  .modal .modal-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) and (min-width: 1280px) {
  .modal .modal-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1280px) and (min-width: 7px) {
  .modal .modal-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1280px) and (min-width: 1440px) {
  .modal .modal-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1280px) and (min-width: 1440px) {
  .modal .modal-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1280px) {
  .modal .modal-inner-container {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
    background-color: #fff;
    margin: 3rem 0;
    padding: 0;
    pointer-events: auto;
  }
}
@media (min-width: 1280px) {
  .modal .modal-header, .modal .echeckin-modal-header {
    background-color: transparent;
  }
  .modal .modal-header .heading-3, .modal .echeckin-modal-header .heading-3 {
    display: none;
  }
  .modal .modal-header .modal-close-btn, .modal .echeckin-modal-header .modal-close-btn {
    height: auto;
    margin-left: auto;
    transform: translate(100%, 20px);
    width: auto;
    pointer-events: auto;
  }
  .modal .modal-header .modal-close-btn:hover .icon, .modal .echeckin-modal-header .modal-close-btn:hover .icon, .modal .modal-header .modal-close-btn:focus .icon, .modal .echeckin-modal-header .modal-close-btn:focus .icon {
    stroke: #222;
    stroke-width: 4px;
  }
  .modal .modal-header .modal-close-btn .icon, .modal .echeckin-modal-header .modal-close-btn .icon {
    height: 1.25rem;
    stroke-width: 2px;
    width: 1.25rem;
  }
}
@media (min-width: 1280px) {
  .modal .modal-body {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 1280px) {
  .modal .modal-inner {
    padding: 0 95px 95px;
  }
}
@media (min-width: 1280px) {
  .modal .button-group-container {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 1280px) {
  .modal .button-group {
    display: inline-flex;
    margin: auto;
  }
}
@media print and (min-width: 7px) {
  .modal {
    background-color: rgba(54, 53, 52, 0.75);
    overflow-y: scroll;
  }
  .modal.visible {
    align-items: center;
    background-color: rgba(54, 53, 52, 0.75);
    display: flex;
  }
  .modal.iframe-modal .modal-inner {
    height: 500px;
    width: 698.666px;
  }
  .modal.iframe-modal .iframe-container {
    height: calc(454px - (2.1875rem + 4px));
  }
  .modal.iframe-modal iframe {
    height: 500px;
    width: 698.666px;
  }
  .modal.iframe-modal .schedule-header,
.modal.iframe-modal .modal-title {
    display: block;
  }
  .modal.iframe-modal .schedule-header:first-child,
.modal.iframe-modal .modal-title:first-child {
    display: none;
  }
  .at .modal .modal-container {
    flex-direction: column;
    height: 100%;
    max-width: none;
    padding: 0;
    width: 100%;
  }
  .at .modal .modal-header, .at .modal .echeckin-modal-header {
    width: 100%;
  }
  .at .modal .modal-header .heading-3, .at .modal .echeckin-modal-header .heading-3 {
    display: block;
  }
  .at .modal .modal-header .modal-close-btn, .at .modal .echeckin-modal-header .modal-close-btn {
    transform: none;
  }
  .at .modal .modal-header .modal-close-btn .icon, .at .modal .echeckin-modal-header .modal-close-btn .icon {
    height: 1rem;
    stroke-width: 4px;
    width: 1rem;
  }
  .at .modal .modal-body {
    width: 100%;
  }
  .at .modal .modal-inner {
    padding: 1.5rem 70px 4rem;
  }
  .modal.full-screen .modal-container,
.modal.full-screen .modal-inner-container {
    flex-direction: column;
    height: 100%;
    margin: 0;
    max-width: none;
    padding: 0;
    width: 100%;
  }
  .modal.full-screen .modal-inner-container {
    flex: 1;
  }
  .modal.full-screen .modal-header, .modal.full-screen .echeckin-modal-header {
    background-color: #eee;
    display: flex;
    min-height: 60px;
    align-items: center;
    width: 100%;
  }
  .modal.full-screen .modal-header .heading-3, .modal.full-screen .echeckin-modal-header .heading-3 {
    display: block;
  }
  .modal.full-screen .modal-header .modal-close-btn, .modal.full-screen .echeckin-modal-header .modal-close-btn {
    transform: none;
  }
  .modal.full-screen .modal-header .modal-close-btn .icon, .modal.full-screen .echeckin-modal-header .modal-close-btn .icon {
    height: 1rem;
    stroke-width: 4px;
    width: 1rem;
  }
  .modal.full-screen .modal-row {
    align-items: center;
    display: flex;
  }
  .modal.full-screen .modal-body {
    width: 100%;
  }
  .modal.full-screen .modal-inner {
    padding: 1.5rem 70px 4rem;
  }
  .modal .modal-container {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
    align-items: flex-start;
    flex-direction: row;
    height: auto;
    background-color: transparent;
    justify-content: center;
    max-height: 100vh;
    pointer-events: auto;
  }
}
@media print and (min-width: 7px) and (min-width: 768px) {
  .modal .modal-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 7px) and (min-width: 6px) {
  .modal .modal-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 7px) and (min-width: 1280px) {
  .modal .modal-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) and (min-width: 7px) {
  .modal .modal-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) and (min-width: 1440px) {
  .modal .modal-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) and (min-width: 1440px) {
  .modal .modal-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .modal .modal-inner-container {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
    background-color: #fff;
    margin: 3rem 0;
    padding: 0;
    pointer-events: auto;
  }
}
@media print and (min-width: 7px) {
  .modal .modal-header, .modal .echeckin-modal-header {
    background-color: transparent;
  }
  .modal .modal-header .heading-3, .modal .echeckin-modal-header .heading-3 {
    display: none;
  }
  .modal .modal-header .modal-close-btn, .modal .echeckin-modal-header .modal-close-btn {
    height: auto;
    margin-left: auto;
    transform: translate(100%, 20px);
    width: auto;
    pointer-events: auto;
  }
  .modal .modal-header .modal-close-btn:hover .icon, .modal .echeckin-modal-header .modal-close-btn:hover .icon, .modal .modal-header .modal-close-btn:focus .icon, .modal .echeckin-modal-header .modal-close-btn:focus .icon {
    stroke: #222;
    stroke-width: 4px;
  }
  .modal .modal-header .modal-close-btn .icon, .modal .echeckin-modal-header .modal-close-btn .icon {
    height: 1.25rem;
    stroke-width: 2px;
    width: 1.25rem;
  }
}
@media print and (min-width: 7px) {
  .modal .modal-body {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
  }
}
@media print and (min-width: 7px) {
  .modal .modal-inner {
    padding: 0 95px 95px;
  }
}
@media print and (min-width: 7px) {
  .modal .button-group-container {
    display: flex;
    justify-content: center;
  }
}
@media print and (min-width: 7px) {
  .modal .button-group {
    display: inline-flex;
    margin: auto;
  }
}

.provider-comparison {
  margin-top: -4rem;
  max-width: 1440px;
  padding-left: 72px;
  padding-right: 72px;
  margin-left: auto;
  margin-right: auto;
}
.provider-comparison .provider-comparison-list {
  display: none;
}
.provider-comparison .provider-result.removed {
  opacity: 0.3;
}
.provider-comparison.comparison-list-view.show-additional-info .additional-info {
  display: block;
}
.provider-comparison.comparison-list-view.show-additional-info .additional-info-toggle svg {
  transform: rotate(-90deg);
}
.provider-comparison.comparison-list-view .provider-list {
  display: none;
}
.provider-comparison.comparison-list-view .provider-comparison-list {
  display: block;
}
.provider-comparison.comparison-list-view .comparison-top {
  margin-bottom: 50px;
}
.provider-comparison.comparison-list-view .provider-comparison-list {
  display: flex;
  overflow-x: auto;
  padding: 0 30px;
  width: 100%;
}
.provider-comparison.comparison-list-view .provider-result {
  font-size: 0.75rem;
  line-height: 1.5;
  background-color: #f9f9f9;
  flex-basis: calc(50% - 3px);
  margin-right: 4px;
  min-width: calc(50% - 3px);
  padding: 0;
}
.provider-comparison.comparison-list-view .provider-result:last-of-type {
  margin-right: 0;
}
.provider-comparison.comparison-list-view .provider-result:nth-of-type(even) {
  background-color: #fff;
}
.provider-comparison.comparison-list-view .provider-result:nth-of-type(even) .comparison-row:nth-child(n+3):nth-child(odd) {
  background-color: #fffbef;
}
.provider-comparison.comparison-list-view .comparison-row {
  border-bottom: 2px solid #fff;
  border-top: 2px solid #fff;
  margin: 0;
  padding: 0.75rem 1rem 0.75rem 0.625rem;
}
.provider-comparison.comparison-list-view .comparison-row:last-child {
  background-color: transparent;
}
.provider-comparison.comparison-list-view .comparison-row:nth-child(n+3):nth-child(odd) {
  background-color: #fff8da;
}
.provider-comparison.comparison-list-view .comparison-row:nth-child(n+3):nth-child(odd) .star-fill svg {
  fill: #e46e18;
}
.provider-comparison.comparison-list-view .comparison-row.view-all-expanded .view-all-list {
  display: block;
}
.provider-comparison.comparison-list-view .view-all-list {
  display: none;
}
.provider-comparison.comparison-list-view .group-and-specialty {
  min-height: 86px;
}
.provider-comparison.comparison-list-view .comparison-specialty,
.provider-comparison.comparison-list-view .comparison-title {
  display: block;
}
.provider-comparison.comparison-list-view .comparison-text {
  display: block;
}
.provider-comparison.comparison-list-view .comparison-text.accepting {
  color: #137e13;
}
.provider-comparison.comparison-list-view .comparison-specialty {
  display: block;
}
.provider-comparison.comparison-list-view .new-patient-text {
  display: none;
}
.provider-comparison.comparison-list-view .comparison-title {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  color: #000;
  font-size: 13px;
}
.provider-comparison.comparison-list-view .provider-name {
  font-size: 1rem;
  line-height: 1.375;
  padding: 0.5rem 1rem 0 0.625rem;
}
.provider-comparison.comparison-list-view .group-and-specialty {
  padding: 0 1rem 0.75rem 0.625rem;
}
.provider-comparison.comparison-list-view .group-and-specialty .specialty {
  display: none;
}
.provider-comparison.comparison-list-view .medical-group {
  font-size: 0.75rem;
  line-height: 1.5;
}
.provider-comparison.comparison-list-view .provider-details {
  padding: 0;
}
.provider-comparison.comparison-list-view .detail-top .ratings {
  display: none;
}
.provider-comparison.comparison-list-view .ratings {
  margin: 0 0 0.25rem;
}
.provider-comparison.comparison-list-view .ratings .rating-stars {
  width: 80px;
}
.provider-comparison.comparison-list-view .ratings .rating-stars .star {
  height: 14px;
  width: 14px;
}
.provider-comparison.comparison-list-view .ratings .rating-stars .star svg {
  height: 14px;
  width: 14px;
}
.provider-comparison.comparison-list-view .ratings .rating-stars .star .star-fill {
  height: 14px;
}
.provider-comparison.comparison-list-view .ratings .average-rating {
  font-size: 0.75rem;
  line-height: 1.5;
  margin-left: 0.5rem;
  margin-top: 0;
}
.provider-comparison.comparison-list-view .ratings .rating-count {
  font-size: 0.75rem;
  line-height: 1.5;
  margin-top: 0;
}
.provider-comparison.comparison-list-view .comparison-new-patient {
  border-top: 0;
}
.provider-comparison.comparison-list-view .phone-number {
  display: none;
}
.provider-comparison.comparison-list-view .location-address {
  display: none;
}
.provider-comparison.comparison-list-view .detail-bottom {
  display: none;
}
.provider-comparison.comparison-list-view .comparison-schedule-btn {
  display: block;
  padding: 0.75rem 1rem 0.75rem 0.625rem;
}
.provider-comparison.comparison-list-view .comparison-schedule-btn .btn {
  min-width: 0;
  padding: 0.625rem 0.5rem 0.5rem;
  width: 100%;
}
.provider-comparison.comparison-list-view .comparison-schedule-btn.desktop {
  display: none;
}
.provider-comparison.comparison-list-view ul {
  list-style: none;
  padding: 0;
}
.provider-comparison.comparison-list-view ul li {
  margin-bottom: 0.5rem;
}
.provider-comparison.comparison-list-view .view-all-btn {
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  border: none;
  color: #006dac;
  margin: 0;
  padding: 0;
  text-decoration: underline;
}
.provider-comparison.comparison-list-view .view-all-btn:hover, .provider-comparison.comparison-list-view .view-all-btn:focus {
  text-decoration: none;
}
.provider-comparison.comparison-list-view .section-link {
  font-size: 0.75rem;
  line-height: 1.5;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  display: inline-block;
}
.provider-comparison.comparison-list-view .additional-info-toggle {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  align-items: center;
  color: #363534;
  display: flex;
  height: 50px;
  left: 30px;
  margin-top: -50px;
  position: absolute;
  width: calc(100% - 60px);
}
.provider-comparison.comparison-list-view .additional-info-toggle svg {
  margin-bottom: 1px;
  margin-left: 0.5rem;
  stroke: #363534;
  transform: rotate(90deg);
}
.provider-comparison.comparison-list-view .additional-info {
  display: none;
}
.provider-comparison .controls-container {
  font-size: 0.75rem;
  line-height: 1.5;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
  padding-right: 1.875rem;
}
.provider-comparison .view-controls {
  align-items: center;
  background-color: #eee;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  width: 70px;
}
.provider-comparison .view-controls .list-view-btn,
.provider-comparison .view-controls .comparison-view-btn {
  align-items: center;
  display: flex;
  height: 1.125rem;
  justify-content: center;
  width: 1.125rem;
}
.provider-comparison .view-controls .list-view-btn.selected svg,
.provider-comparison .view-controls .comparison-view-btn.selected svg {
  fill: #363534;
}
.provider-comparison .view-controls .list-view-btn svg,
.provider-comparison .view-controls .comparison-view-btn svg {
  fill: #bfbfbf;
  pointer-events: none;
}
.provider-comparison .view-controls .comparison-view-btn svg {
  height: 18px;
  transform: rotate(90deg);
  width: 18px;
}
@media (min-width: 340px) {
  .provider-comparison {
    margin-top: 0;
    padding: 0;
  }
  .provider-comparison.comparison-list-view {
    padding: 0;
  }
  .provider-comparison.comparison-list-view .provider-comparison-list {
    padding: 0 16px;
  }
}
@media print and (min-width: ) {
  .provider-comparison {
    margin-top: 0;
    padding: 0;
  }
  .provider-comparison.comparison-list-view {
    padding: 0;
  }
  .provider-comparison.comparison-list-view .provider-comparison-list {
    padding: 0 16px;
  }
}
@media (min-width: 360px) {
  .provider-comparison {
    margin-top: 0;
    padding: 0;
  }
  .provider-comparison.comparison-list-view {
    padding: 0;
  }
  .provider-comparison.comparison-list-view .provider-comparison-list {
    padding: 0 16px;
  }
}
@media print and (min-width: 3px) {
  .provider-comparison {
    margin-top: 0;
    padding: 0;
  }
  .provider-comparison.comparison-list-view {
    padding: 0;
  }
  .provider-comparison.comparison-list-view .provider-comparison-list {
    padding: 0 16px;
  }
}
@media (min-width: 414px) {
  .provider-comparison {
    margin-top: 0;
    padding: 0;
  }
  .provider-comparison.comparison-list-view {
    padding: 0;
  }
  .provider-comparison.comparison-list-view .provider-comparison-list {
    padding: 0 16px;
  }
}
@media print and (min-width: 5px) {
  .provider-comparison {
    margin-top: 0;
    padding: 0;
  }
  .provider-comparison.comparison-list-view {
    padding: 0;
  }
  .provider-comparison.comparison-list-view .provider-comparison-list {
    padding: 0 16px;
  }
}
@media (min-width: 768px) {
  .provider-comparison {
    margin-top: 0;
  }
  .provider-comparison.comparison-list-view {
    padding: 0;
  }
  .provider-comparison.comparison-list-view .provider-result {
    font-size: 0.875rem;
    line-height: 1.4285714286;
    display: block;
    flex-basis: calc(25% - 15px);
    margin-right: 20px;
    min-width: calc(25% - 15px);
  }
  .provider-comparison.comparison-list-view .provider-result .profile-image-container {
    padding-bottom: 66.666%;
  }
  .provider-comparison.comparison-list-view .provider-result .profile-image-outer {
    padding: 0;
    width: 100%;
  }
  .provider-comparison.comparison-list-view .comparison-title {
    font-size: 15px;
    margin-bottom: 0.375rem;
  }
  .provider-comparison.comparison-list-view .provider-name {
    font-size: 1.25rem;
    line-height: 1.5;
    margin-bottom: 0;
    padding: 1.125rem 1.25rem 0 1.125rem;
  }
  .provider-comparison.comparison-list-view .group-and-specialty {
    margin-bottom: 0;
    padding: 0 1.25rem 0.5rem 1.125rem;
  }
  .provider-comparison.comparison-list-view .group-and-specialty .specialty {
    display: block;
  }
  .provider-comparison.comparison-list-view .medical-group {
    font-size: 0.875rem;
    line-height: 1.4285714286;
  }
  .provider-comparison.comparison-list-view .provider-details {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .provider-comparison.comparison-list-view .detail-top {
    display: block;
  }
  .provider-comparison.comparison-list-view .comparison-specialty,
.provider-comparison.comparison-list-view .provider-ratings,
.provider-comparison.comparison-list-view .provider-languages,
.provider-comparison.comparison-list-view .distance-and-location,
.provider-comparison.comparison-list-view .comparison-new-patient,
.provider-comparison.comparison-list-view .provider-insurance {
    padding: 1rem 1.25rem 1rem 1.125rem;
  }
  .provider-comparison.comparison-list-view .comparison-schedule-btn.mobile {
    display: none;
  }
  .provider-comparison.comparison-list-view .comparison-schedule-btn.desktop {
    display: block;
  }
  .provider-comparison.comparison-list-view .section-link {
    font-size: 0.875rem;
    line-height: 1.4285714286;
    margin-top: 1rem;
    text-decoration: none;
  }
  .provider-comparison.comparison-list-view .section-link:hover, .provider-comparison.comparison-list-view .section-link:focus {
    text-decoration: underline;
  }
  .provider-comparison.comparison-list-view .additional-info-toggle {
    display: none;
  }
  .provider-comparison.comparison-list-view .additional-info {
    display: block;
  }
  .provider-comparison.comparison-list-view .action-column .column-icons {
    right: 70px;
  }
  .provider-comparison .comparison-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 110px;
  }
  .provider-comparison .provider-list {
    order: 1;
  }
  .provider-comparison .controls-container {
    order: 2;
    margin-bottom: 1rem;
    padding-right: 0;
  }
  .provider-comparison .action-column .column-icons {
    top: 0;
    right: 0;
  }
}
@media print and (min-width: 6px) {
  .provider-comparison {
    margin-top: 0;
  }
  .provider-comparison.comparison-list-view {
    padding: 0;
  }
  .provider-comparison.comparison-list-view .provider-result {
    font-size: 0.875rem;
    line-height: 1.4285714286;
    display: block;
    flex-basis: calc(25% - 15px);
    margin-right: 20px;
    min-width: calc(25% - 15px);
  }
  .provider-comparison.comparison-list-view .provider-result .profile-image-container {
    padding-bottom: 66.666%;
  }
  .provider-comparison.comparison-list-view .provider-result .profile-image-outer {
    padding: 0;
    width: 100%;
  }
  .provider-comparison.comparison-list-view .comparison-title {
    font-size: 15px;
    margin-bottom: 0.375rem;
  }
  .provider-comparison.comparison-list-view .provider-name {
    font-size: 1.25rem;
    line-height: 1.5;
    margin-bottom: 0;
    padding: 1.125rem 1.25rem 0 1.125rem;
  }
  .provider-comparison.comparison-list-view .group-and-specialty {
    margin-bottom: 0;
    padding: 0 1.25rem 0.5rem 1.125rem;
  }
  .provider-comparison.comparison-list-view .group-and-specialty .specialty {
    display: block;
  }
  .provider-comparison.comparison-list-view .medical-group {
    font-size: 0.875rem;
    line-height: 1.4285714286;
  }
  .provider-comparison.comparison-list-view .provider-details {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .provider-comparison.comparison-list-view .detail-top {
    display: block;
  }
  .provider-comparison.comparison-list-view .comparison-specialty,
.provider-comparison.comparison-list-view .provider-ratings,
.provider-comparison.comparison-list-view .provider-languages,
.provider-comparison.comparison-list-view .distance-and-location,
.provider-comparison.comparison-list-view .comparison-new-patient,
.provider-comparison.comparison-list-view .provider-insurance {
    padding: 1rem 1.25rem 1rem 1.125rem;
  }
  .provider-comparison.comparison-list-view .comparison-schedule-btn.mobile {
    display: none;
  }
  .provider-comparison.comparison-list-view .comparison-schedule-btn.desktop {
    display: block;
  }
  .provider-comparison.comparison-list-view .section-link {
    font-size: 0.875rem;
    line-height: 1.4285714286;
    margin-top: 1rem;
    text-decoration: none;
  }
  .provider-comparison.comparison-list-view .section-link:hover, .provider-comparison.comparison-list-view .section-link:focus {
    text-decoration: underline;
  }
  .provider-comparison.comparison-list-view .additional-info-toggle {
    display: none;
  }
  .provider-comparison.comparison-list-view .additional-info {
    display: block;
  }
  .provider-comparison.comparison-list-view .action-column .column-icons {
    right: 70px;
  }
  .provider-comparison .comparison-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 110px;
  }
  .provider-comparison .provider-list {
    order: 1;
  }
  .provider-comparison .controls-container {
    order: 2;
    margin-bottom: 1rem;
    padding-right: 0;
  }
  .provider-comparison .action-column .column-icons {
    top: 0;
    right: 0;
  }
}
@media (min-width: 1280px) {
  .provider-comparison.comparison-list-view .ratings .rating-stars {
    width: 100px;
  }
  .provider-comparison.comparison-list-view .ratings .rating-stars .star {
    height: 18px;
    width: 18px;
  }
  .provider-comparison.comparison-list-view .ratings .rating-stars .star svg {
    height: 18px;
    width: 18px;
  }
  .provider-comparison.comparison-list-view .ratings .rating-stars .star .star-fill {
    height: 18px;
  }
  .provider-comparison.comparison-list-view .ratings .average-rating {
    font-size: 0.875rem;
    line-height: 1.4285714286;
    margin-left: 0.625rem;
    margin-top: 2px;
  }
  .provider-comparison.comparison-list-view .ratings .rating-count {
    font-size: 0.875rem;
    line-height: 1.4285714286;
    margin-top: 2px;
  }
  .provider-comparison .action-column .column-icon {
    right: 0;
  }
}
@media print and (min-width: 7px) {
  .provider-comparison.comparison-list-view .ratings .rating-stars {
    width: 100px;
  }
  .provider-comparison.comparison-list-view .ratings .rating-stars .star {
    height: 18px;
    width: 18px;
  }
  .provider-comparison.comparison-list-view .ratings .rating-stars .star svg {
    height: 18px;
    width: 18px;
  }
  .provider-comparison.comparison-list-view .ratings .rating-stars .star .star-fill {
    height: 18px;
  }
  .provider-comparison.comparison-list-view .ratings .average-rating {
    font-size: 0.875rem;
    line-height: 1.4285714286;
    margin-left: 0.625rem;
    margin-top: 2px;
  }
  .provider-comparison.comparison-list-view .ratings .rating-count {
    font-size: 0.875rem;
    line-height: 1.4285714286;
    margin-top: 2px;
  }
  .provider-comparison .action-column .column-icon {
    right: 0;
  }
}

.provider-result {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  background-color: #f9f9f9;
  color: #363534;
  margin-bottom: 1.5rem;
  padding-bottom: 2rem;
}
.provider-result.simplified {
  display: flex;
  flex-wrap: wrap;
  min-height: 95px;
  padding-bottom: 0;
}
.provider-result.simplified .profile-image-container {
  padding-bottom: 100%;
  width: 95px;
}
.provider-result.simplified .provider-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.25rem 1rem 0.25rem 0.625rem;
  width: calc(100% - 95px);
}
.provider-result.simplified .provider-name {
  font-size: 1rem;
  line-height: 1.375;
}
.provider-result.simplified .credit {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  width: 100%;
}
.provider-result.simplified .credit .date {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  color: #000;
  margin-right: 1rem;
}
.provider-result.simplified .credit .icon-share {
  margin-bottom: 0.375rem;
}
.provider-result p {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
}
.provider-result .location-name {
  color: black;
}
.provider-result a {
  color: #00759a;
  cursor: pointer;
}
.provider-result a.profile-image:focus-visible {
  border: #222 2px solid;
}
.provider-result .btn {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  margin: 0;
  padding: 0.625rem 0.25rem 0.5rem;
}
.provider-result .profile-image-container {
  display: block;
  height: 0;
  overflow: hidden;
  padding-bottom: 66.666%;
  position: relative;
  width: 100%;
}
.provider-result .profile-image {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.provider-result .profile-image img {
  font-family: "object-fit: cover; object-position: center 25%;";
  height: 100%;
  object-fit: cover;
  object-position: center 25%;
  width: 100%;
}
.provider-result .provider-details {
  padding: 1.5rem 30px;
}
.provider-result .provider-list-btn {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  align-items: center;
  border-bottom: 1px solid #bfbfbf;
  display: flex;
  height: 40px;
  justify-content: center;
  text-decoration: none;
  width: 100%;
}
.provider-result .provider-list-btn svg {
  margin-right: 0.5rem;
}
.provider-result .provider-list-btn .checkbox-wrapper {
  align-items: flex-start;
  display: flex;
  min-height: 1.5rem;
  justify-items: flex-start;
  position: relative;
  margin-bottom: 0;
  margin-top: 4px;
}
.provider-result .provider-list-btn .checkbox-wrapper input[type=checkbox] {
  height: 1.5rem;
  margin-top: 2px;
  opacity: 0;
  width: 1.5rem;
}
.provider-result .provider-list-btn .checkbox-wrapper input[type=checkbox]:checked + label:after {
  border-bottom: 1px solid #137e13;
  border-right: 1px solid #137e13;
  content: "";
  display: block;
  height: 0.9rem;
  left: 0;
  margin: 0.25rem 0.5rem;
  position: absolute;
  top: 0;
  transform: rotate(45deg) translate(-1px, -1px);
  width: 0.45rem;
  border-bottom-width: 2px;
  border-right-width: 2px;
}
.provider-result .provider-list-btn .checkbox-wrapper input[type=checkbox]:not([disabled]):hover {
  cursor: pointer;
}
.provider-result .provider-list-btn .checkbox-wrapper input[type=checkbox]:not([disabled]):hover + label:before {
  border-color: #707070;
}
.provider-result .provider-list-btn .checkbox-wrapper input[type=checkbox]:not([disabled]):focus + label:before {
  border-color: #707070;
  border-width: 2px;
}
.provider-result .provider-list-btn .checkbox-wrapper input[type=checkbox]:not([disabled]) + label:hover {
  cursor: pointer;
}
.provider-result .provider-list-btn .checkbox-wrapper input[type=checkbox]:not([disabled]) + label:hover:before {
  border-color: #707070;
}
.provider-result .provider-list-btn .checkbox-wrapper input[type=checkbox] + label:before {
  background-color: #fff;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  content: "";
  display: block;
  height: 1.5rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 1.5rem;
}
.provider-result .provider-list-btn .checkbox-wrapper label {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
}
.provider-result .provider-name {
  font-size: 1.25rem;
  line-height: 1.5;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  color: #000;
  display: block;
  cursor: pointer !important;
  margin-bottom: 0.25rem;
}
.provider-result .medical-group {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
}
.provider-result .medical-group.presbyterian {
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: #a71930;
}
.provider-result .comparison-specialty,
.provider-result .comparison-title {
  display: none;
}
.provider-result .ratings {
  margin: 1rem 0;
}
.provider-result .distance-and-location {
  margin: 1rem 0;
}
.provider-result .distance-and-location .location-count {
  margin-left: 0.25rem;
}
.provider-result .provider-website .icon-external {
  height: 0.5rem;
  margin-left: 0.25rem;
  stroke: #00759a;
  width: 0.5rem;
}
.provider-result .location-address {
  margin: 1rem 0;
}
.provider-result .location-address span {
  display: block;
}
.provider-result .new-patient-icon-container {
  display: flex;
}
.provider-result .new-patient-icon-container .icon {
  margin-bottom: 1px;
  margin-right: 0.5rem;
  margin-top: 3px;
}
.provider-result .open-weekend-icon-container {
  display: flex;
}
.provider-result .open-weekend-icon-container .icon {
  margin-bottom: 1px;
  margin-right: 0.5rem;
  margin-top: 3px;
}
.provider-result .telehealth-indicator-icon-container {
  display: flex;
}
.provider-result .telehealth-indicator-icon-container .icon {
  margin-bottom: 1px;
  margin-right: 0.5rem;
  margin-top: -4px;
}
.provider-result .detail-bottom {
  border-top: 1px solid #bfbfbf;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
}
.provider-result .detail-bottom .schedule-description {
  margin-bottom: 0.5rem;
}
.provider-result .detail-bottom p {
  margin-bottom: 0;
}
.provider-result .schedule-title {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.3333333333;
  margin-bottom: 0.375rem;
}
.provider-result .schedule-options {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.provider-result .schedule-options .schedule-btns-container,
.provider-result .schedule-options .schedule-call-container {
  min-width: calc(50% - 5px);
  flex-basis: calc(50% - 5px);
}
.provider-result .appointment-types {
  text-align: center;
}
.provider-result .appointment-types .reset-btn {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.provider-result .appointment-types .appointment-type-title {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.4285714286;
  margin-bottom: 0.5rem;
}
.provider-result .appointment-types .appointment-type {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 0.75rem;
}
.provider-result .appointment-types .schedule-btns {
  max-width: none;
}
.provider-result .comparison-schedule-btn {
  display: none;
}
.provider-result .comparison-bottom {
  display: none;
}
@media (min-width: 768px) {
  .provider-result.simplified:not(.grid-result) {
    flex-wrap: unset;
  }
  .provider-result.simplified:not(.grid-result) .profile-image-outer {
    width: 95px;
    padding: 0;
  }
  .provider-result.simplified:not(.grid-result) .provider-details {
    padding: 0.25rem 1.25rem 0.25rem 1.25rem;
    flex: 1;
  }
  .provider-result.simplified:not(.grid-result) .detail-top .group-and-specialty {
    margin-bottom: 0;
  }
  .provider-result.simplified:not(.grid-result) .credit {
    width: auto;
    padding: 1rem 1rem 1rem 0;
  }
  .provider-result:not(.grid-result) {
    display: flex;
    padding-bottom: 0;
  }
  .provider-result:not(.grid-result) .provider-list-btn {
    border-bottom: none;
  }
  .provider-result:not(.grid-result) .provider-name {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
  .provider-result:not(.grid-result) .profile-image-outer {
    padding: 0.625rem 0 0 0.625rem;
    width: 250px;
  }
  .provider-result:not(.grid-result) .provider-details {
    padding: 1.25rem 1.5rem;
    width: calc(100% - 250px);
  }
  .provider-result:not(.grid-result) .detail-top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .provider-result:not(.grid-result) .detail-top > div {
    flex-basis: calc(50% - 5px);
    margin: 0;
    min-width: calc(50% - 5px);
  }
  .provider-result:not(.grid-result) .detail-top .group-and-specialty {
    margin-bottom: 1.5rem;
    order: 0;
  }
  .provider-result:not(.grid-result) .detail-top .distance-and-location {
    margin-bottom: 1.5rem;
    order: 3;
  }
  .provider-result:not(.grid-result) .detail-top .ratings {
    order: 2;
  }
  .provider-result:not(.grid-result) .detail-top .new-patient-open-weekend {
    margin-left: auto;
    order: 4;
  }
  .provider-result:not(.grid-result) .detail-top .telehealth-indicator {
    margin-left: auto;
    order: 5;
  }
  .provider-result:not(.grid-result) .detail-top .location-address {
    order: 1;
  }
  .provider-result:not(.grid-result) .schedule-title {
    color: #363534;
  }
  .provider-result:not(.grid-result) .schedule-options {
    flex-direction: row;
  }
  .provider-result .appointment-types .schedule-btns {
    justify-content: space-around;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
  }
  .provider-result .appointment-types .schedule-btns .btn {
    max-width: 200px;
    min-width: 0;
  }
}
@media print and (min-width: 6px) {
  .provider-result.simplified:not(.grid-result) {
    flex-wrap: unset;
  }
  .provider-result.simplified:not(.grid-result) .profile-image-outer {
    width: 95px;
    padding: 0;
  }
  .provider-result.simplified:not(.grid-result) .provider-details {
    padding: 0.25rem 1.25rem 0.25rem 1.25rem;
    flex: 1;
  }
  .provider-result.simplified:not(.grid-result) .detail-top .group-and-specialty {
    margin-bottom: 0;
  }
  .provider-result.simplified:not(.grid-result) .credit {
    width: auto;
    padding: 1rem 1rem 1rem 0;
  }
  .provider-result:not(.grid-result) {
    display: flex;
    padding-bottom: 0;
  }
  .provider-result:not(.grid-result) .provider-list-btn {
    border-bottom: none;
  }
  .provider-result:not(.grid-result) .provider-name {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
  .provider-result:not(.grid-result) .profile-image-outer {
    padding: 0.625rem 0 0 0.625rem;
    width: 250px;
  }
  .provider-result:not(.grid-result) .provider-details {
    padding: 1.25rem 1.5rem;
    width: calc(100% - 250px);
  }
  .provider-result:not(.grid-result) .detail-top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .provider-result:not(.grid-result) .detail-top > div {
    flex-basis: calc(50% - 5px);
    margin: 0;
    min-width: calc(50% - 5px);
  }
  .provider-result:not(.grid-result) .detail-top .group-and-specialty {
    margin-bottom: 1.5rem;
    order: 0;
  }
  .provider-result:not(.grid-result) .detail-top .distance-and-location {
    margin-bottom: 1.5rem;
    order: 3;
  }
  .provider-result:not(.grid-result) .detail-top .ratings {
    order: 2;
  }
  .provider-result:not(.grid-result) .detail-top .new-patient-open-weekend {
    margin-left: auto;
    order: 4;
  }
  .provider-result:not(.grid-result) .detail-top .telehealth-indicator {
    margin-left: auto;
    order: 5;
  }
  .provider-result:not(.grid-result) .detail-top .location-address {
    order: 1;
  }
  .provider-result:not(.grid-result) .schedule-title {
    color: #363534;
  }
  .provider-result:not(.grid-result) .schedule-options {
    flex-direction: row;
  }
  .provider-result .appointment-types .schedule-btns {
    justify-content: space-around;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
  }
  .provider-result .appointment-types .schedule-btns .btn {
    max-width: 200px;
    min-width: 0;
  }
}
@media (min-width: 1280px) {
  .provider-result.simplified:not(.grid-result) .credit {
    padding-right: 4rem;
  }
  .provider-result:not(.grid-result) .profile-image-outer {
    width: 350px;
  }
  .provider-result:not(.grid-result) .provider-details {
    width: calc(100% - 350px);
  }
  .provider-result:not(.grid-result) .provider-details .provider-details-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .provider-result:not(.grid-result) .provider-details .provider-details-header .location-name {
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: 700;
    flex-basis: calc(50% - 5px);
    color: black;
  }
}
@media print and (min-width: 7px) {
  .provider-result.simplified:not(.grid-result) .credit {
    padding-right: 4rem;
  }
  .provider-result:not(.grid-result) .profile-image-outer {
    width: 350px;
  }
  .provider-result:not(.grid-result) .provider-details {
    width: calc(100% - 350px);
  }
  .provider-result:not(.grid-result) .provider-details .provider-details-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .provider-result:not(.grid-result) .provider-details .provider-details-header .location-name {
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: 700;
    flex-basis: calc(50% - 5px);
    color: black;
  }
}

.search-banner {
  background-color: #f2f2f2;
  display: flex;
  margin-bottom: 1rem;
  padding: 30px;
  position: relative;
}
.search-banner.desktop-only {
  display: none;
}
.search-page .search-page-results .search-banner + .results-controls {
  margin-top: 1rem;
}
.search-banner .close-btn {
  align-items: center;
  display: flex;
  height: 15px;
  justify-content: center;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 15px;
}
.search-banner .close-btn .icon {
  width: 11px;
  height: 11px;
}
.search-banner .close-btn:hover .icon, .search-banner .close-btn:focus .icon {
  stroke-width: 3;
}
.search-banner .overflow-wrap {
  white-space: nowrap;
  overflow: hidden;
  overflow-wrap: breakF-word;
  text-overflow: ellipsis;
  width: 250px;
}
.search-banner .drop-down-icon {
  align-items: center;
  display: flex;
  height: 18px;
  justify-content: center;
  position: absolute;
  stroke: #707070;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
}
.search-banner .drop-down-icon--is-rotate {
  transform: rotate(180deg) translateY(50%);
}
.search-banner .image-container {
  flex: 0 1 55px;
  max-width: 55px;
}
.search-banner .image-container img {
  width: 31px;
}
.search-banner .image-container .icon-map-marker {
  width: 31px;
  height: 37px;
  fill: #a71930;
}
.search-banner .banner-content {
  flex: 1;
}
.search-banner p {
  font-size: 0.875rem;
  line-height: 1.3571428571;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: #000;
  margin-bottom: 0.625rem;
}
.search-banner p:last-child {
  margin-bottom: 0;
}
.search-banner a {
  font-size: 0.875rem;
  line-height: 1.3571428571;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: #00759a;
}
.search-banner a.multiplan-website .icon-external {
  height: 0.5rem;
  margin-left: 0.25rem;
  stroke: #00759a;
  width: 0.5rem;
}
@media (min-width: 768px) {
  .search-banner {
    padding: 30px 35px;
  }
  .search-banner .close-btn {
    right: 20px;
  }
}
@media print and (min-width: 6px) {
  .search-banner {
    padding: 30px 35px;
  }
  .search-banner .close-btn {
    right: 20px;
  }
}
@media (min-width: 1280px) {
  .search-banner.desktop-only {
    display: flex;
  }
}
@media print and (min-width: 7px) {
  .search-banner.desktop-only {
    display: flex;
  }
}

.search-landing {
  display: flex;
  flex-direction: column;
  min-height: 300px;
}
.search-landing .search-landing-header .heading-1 {
  margin: 0;
  padding: 0.75rem 0 0.75rem;
}
.search-landing .search-landing-title {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
}
.search-landing .search-landing-body {
  background-color: #eee;
  flex: 1;
}
.search-landing .search-landing-content {
  padding: 1rem 30px;
}
.search-landing .search-landing-content .contracted-link-container {
  align-items: center;
  display: flex;
  justify-content: center;
}
.search-landing .search-landing-content .contracted-link-container svg {
  fill: #707070;
  height: 1.25rem;
  margin-right: 0.5rem;
  min-width: 1.25rem;
  transform: translateY(-2px);
  width: 1.25rem;
}
.search-landing .search-landing-content .contracted-link {
  display: block;
}
.search-landing .select-wrapper {
  margin-bottom: 50px;
  margin-left: -1.25rem;
  position: relative;
  width: calc(100% + 2.5rem);
}
.search-landing .select-wrapper select {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
  background-color: #fff;
  border: 0;
  color: #000;
  height: 45px;
  padding: 0 3rem 0 1.25rem;
  width: 100%;
  /* For IE10 */
}
.search-landing .select-wrapper select::-ms-expand {
  display: none;
}
.search-landing .select-wrapper select:hover {
  cursor: pointer;
}
.search-landing .select-wrapper svg {
  pointer-events: none;
  position: absolute;
  right: 1.25rem;
  top: 50%;
  transform: translateY(-50%);
}
.search-landing .input-icon-container {
  margin-bottom: 1.75rem;
  margin-left: -1.25rem;
  position: relative;
  width: calc(100% + 2.5rem);
}
.search-landing .input-icon-container input {
  font-size: 1rem;
  line-height: 1.375;
  background-color: #fff;
  height: 60px;
  margin-bottom: 0;
  padding: 1.125rem 2.75rem 1.125rem 1.25rem;
  text-align: left;
  width: 100%;
}
.search-landing .input-icon-container input::placeholder {
  color: #999;
  text-align: left;
}
.search-landing .input-icon-container button,
.search-landing .input-icon-container > .icon {
  position: absolute;
  right: 1.25rem;
  top: 50%;
  transform: translateY(-50%);
}
.search-landing .input-icon-container > .icon {
  pointer-events: none;
}
.search-landing .react-autosuggest__container {
  position: relative;
}
.search-landing .react-autosuggest__container--open .react-autosuggest__suggestions-container {
  border-bottom: 1px solid #bfbfbf;
}
.search-landing .react-autosuggest__suggestions-container {
  background-color: #fff;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 2;
}
.search-landing .react-autosuggest__suggestion--highlighted {
  background-color: #f9f9f9;
}
.search-landing .checkbox-wrapper {
  margin-bottom: 1.5rem;
}
.search-landing .search-btn {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  background-color: #363534;
  border-color: #363534;
  border-radius: 2px;
  color: #fff;
  max-width: 100%;
  padding: 0.625rem 1.5rem;
  white-space: pre-wrap;
  width: 100%;
}
.search-landing .search-btn[disabled] {
  background-color: #bfbfbf;
  border-color: #bfbfbf;
  cursor: default;
  transition: none;
}
.search-landing .search-btn.geolocating {
  text-decoration: none;
}
.search-landing .search-btn.geolocating:after {
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "…"; /* ascii code for the ellipsis character */
  display: inline-block;
  overflow: hidden;
  vertical-align: bottom;
  width: 0px;
}
@keyframes ellipsis {
  to {
    width: 20px;
  }
}
@-webkit-keyframes ellipsis {
  to {
    width: 20px;
  }
}
.search-landing .btn-container {
  text-align: center;
}
.search-landing .btn-link {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 0.625rem;
  padding: 0;
}
.search-landing .btn-link:hover, .search-landing .btn-link:focus {
  text-decoration: underline;
}
@media (min-width: 768px) {
  .search-landing {
    min-height: calc(100vh - 250px);
  }
  .search-landing .search-landing-content {
    padding-top: 2rem;
  }
  .search-landing .search-landing-title-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.625rem;
  }
  .search-landing .search-landing-title-container .btn-link {
    margin-bottom: 0;
  }
  .search-landing .search-landing-title {
    font-size: 1.125rem;
    line-height: 1.3333333333;
    font-family: AvenirMedium, Helvetica, sans-serif;
    font-weight: normal;
    margin-bottom: 0;
  }
  .search-landing .input-icon-container {
    margin-bottom: 1.5rem;
    margin-left: 0;
    width: 100%;
  }
  .search-landing .input-icon-container input {
    font-size: 0.875rem;
    line-height: 1.4285714286;
    padding: 1.25rem 2.75rem 1.25rem 1.25rem;
  }
  .search-landing .checkbox-select-row {
    align-items: center;
    display: flex;
    margin-bottom: 3rem;
  }
  .search-landing .checkbox-select-row .checkbox-outer-container {
    align-items: center;
    display: flex;
    flex: 0 1 50%;
    justify-content: center;
    margin-bottom: 0;
    max-width: 50%;
  }
  .search-landing .checkbox-select-row .checkbox-outer-container .checkbox-wrapper {
    display: inline-flex;
    margin-bottom: 0;
  }
  .search-landing .checkbox-select-row .select-wrapper {
    flex: 0 1 50%;
    margin-bottom: 0;
    margin-left: 0;
    max-width: 50%;
  }
  .search-landing .search-btn {
    min-width: 300px;
    width: auto;
  }
}
@media print and (min-width: 6px) {
  .search-landing {
    min-height: calc(100vh - 250px);
  }
  .search-landing .search-landing-content {
    padding-top: 2rem;
  }
  .search-landing .search-landing-title-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.625rem;
  }
  .search-landing .search-landing-title-container .btn-link {
    margin-bottom: 0;
  }
  .search-landing .search-landing-title {
    font-size: 1.125rem;
    line-height: 1.3333333333;
    font-family: AvenirMedium, Helvetica, sans-serif;
    font-weight: normal;
    margin-bottom: 0;
  }
  .search-landing .input-icon-container {
    margin-bottom: 1.5rem;
    margin-left: 0;
    width: 100%;
  }
  .search-landing .input-icon-container input {
    font-size: 0.875rem;
    line-height: 1.4285714286;
    padding: 1.25rem 2.75rem 1.25rem 1.25rem;
  }
  .search-landing .checkbox-select-row {
    align-items: center;
    display: flex;
    margin-bottom: 3rem;
  }
  .search-landing .checkbox-select-row .checkbox-outer-container {
    align-items: center;
    display: flex;
    flex: 0 1 50%;
    justify-content: center;
    margin-bottom: 0;
    max-width: 50%;
  }
  .search-landing .checkbox-select-row .checkbox-outer-container .checkbox-wrapper {
    display: inline-flex;
    margin-bottom: 0;
  }
  .search-landing .checkbox-select-row .select-wrapper {
    flex: 0 1 50%;
    margin-bottom: 0;
    margin-left: 0;
    max-width: 50%;
  }
  .search-landing .search-btn {
    min-width: 300px;
    width: auto;
  }
}
@media (min-width: 1280px) {
  .search-landing {
    margin-top: 2.5rem;
    min-height: 0;
  }
  .search-landing .search-landing-header .heading-1 {
    margin-bottom: 0.5rem;
    padding: 0;
  }
  .search-landing .search-landing-body {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
    background-color: transparent;
  }
}
@media (min-width: 1280px) and (min-width: 768px) {
  .search-landing .search-landing-body {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 1280px) and (min-width: 6px) {
  .search-landing .search-landing-body {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) and (min-width: 1280px) {
  .search-landing .search-landing-body {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1280px) and (min-width: 7px) {
  .search-landing .search-landing-body {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1280px) and (min-width: 1440px) {
  .search-landing .search-landing-body {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1280px) and (min-width: 1440px) {
  .search-landing .search-landing-body {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1280px) {
  .search-landing .search-landing-content {
    background-color: #eee;
    padding: 2.75rem 4rem;
  }
}
@media print and (min-width: 7px) {
  .search-landing {
    margin-top: 2.5rem;
    min-height: 0;
  }
  .search-landing .search-landing-header .heading-1 {
    margin-bottom: 0.5rem;
    padding: 0;
  }
  .search-landing .search-landing-body {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
    background-color: transparent;
  }
}
@media print and (min-width: 7px) and (min-width: 768px) {
  .search-landing .search-landing-body {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 7px) and (min-width: 6px) {
  .search-landing .search-landing-body {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 7px) and (min-width: 1280px) {
  .search-landing .search-landing-body {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) and (min-width: 7px) {
  .search-landing .search-landing-body {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) and (min-width: 1440px) {
  .search-landing .search-landing-body {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) and (min-width: 1440px) {
  .search-landing .search-landing-body {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .search-landing .search-landing-content {
    background-color: #eee;
    padding: 2.75rem 4rem;
  }
}

.selected-filters-list {
  padding: 10px 10px 0;
}
.selected-filters-list .tag-list {
  margin-bottom: 0;
}
@media (min-width: 1280px) {
  .selected-filters-list {
    padding: 0;
  }
}
@media print and (min-width: 7px) {
  .selected-filters-list {
    padding: 0;
  }
}

.selected-providers {
  background-color: #fff;
  position: fixed;
  bottom: 0;
  padding: 0.75rem 30px 0 30px;
  width: 100%;
  z-index: 8;
}
.selected-providers .selected-providers-list {
  display: flex;
  overflow: auto;
  width: calc(100% - 40px);
  padding-bottom: 0;
}
.selected-providers .selected-provider-item {
  display: block;
  width: 50px;
  margin-right: 1rem;
  flex-basis: 52px;
  min-width: 50px;
  padding: 1px;
}
.selected-providers .selected-provider-item .compare-provider-checkbox {
  visibility: hidden;
}
.selected-providers .selected-provider-item.list-link {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  align-items: center;
  background-color: #eee;
  display: flex;
  flex-direction: column;
  font-size: 10px;
  height: 50px;
  justify-content: center;
  text-decoration: none;
  margin-right: 0;
  position: absolute;
  right: 30px;
}
.selected-providers .selected-provider-item.list-link.disabled {
  cursor: default;
  color: #bfbfbf;
}
.selected-providers .selected-provider-item.list-link.disabled .checkbox-wrapper input[type=checkbox] + label:after {
  border-color: #bfbfbf;
}
.selected-providers .selected-provider-item > a:hover .profile-image-outer, .selected-providers .selected-provider-item > a:focus .profile-image-outer {
  outline: 1px solid #000;
}
.selected-providers .remove-btn {
  color: #707070;
  font-size: 11px;
  margin-top: 0.5rem;
  text-align: center;
  width: 100%;
}
.selected-providers .remove-btn:hover, .selected-providers .remove-btn:focus {
  color: #000;
  text-decoration: underline;
}
.selected-providers .desktop-remove-btn {
  display: none;
}
.selected-providers .profile-image-container {
  display: block;
  height: 0;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
}
.selected-providers .profile-image {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.selected-providers .profile-image img {
  font-family: "object-fit: cover; object-position: center 25%;";
  height: 100%;
  object-fit: cover;
  object-position: center 25%;
  width: 100%;
}
.selected-providers .checkbox-wrapper {
  align-items: flex-start;
  display: flex;
  min-height: 1.5rem;
  justify-items: flex-start;
  position: relative;
  margin-top: -8px;
  margin-left: 13px;
  margin-bottom: -8px;
  pointer-events: none;
}
.selected-providers .checkbox-wrapper input[type=checkbox] {
  height: 1.5rem;
  margin-top: 2px;
  opacity: 0;
  width: 1.5rem;
}
.selected-providers .checkbox-wrapper input[type=checkbox]:checked + label:after {
  border-bottom: 1px solid #137e13;
  border-right: 1px solid #137e13;
  content: "";
  display: block;
  height: 0.9rem;
  left: 0;
  margin: 0.25rem 0.5rem;
  position: absolute;
  top: 0;
  transform: rotate(45deg) translate(-1px, -1px);
  width: 0.45rem;
  border-bottom-width: 2px;
  border-right-width: 2px;
}
.selected-providers .checkbox-wrapper input[type=checkbox]:not([disabled]):hover {
  cursor: pointer;
}
.selected-providers .checkbox-wrapper input[type=checkbox]:not([disabled]):hover + label:before {
  border-color: black;
}
.selected-providers .checkbox-wrapper input[type=checkbox]:not([disabled]):focus + label:before {
  border-color: black;
  border-width: 2px;
}
.selected-providers .checkbox-wrapper input[type=checkbox]:not([disabled]) + label:hover {
  cursor: pointer;
}
.selected-providers .checkbox-wrapper input[type=checkbox]:not([disabled]) + label:hover:before {
  border-color: black;
}
.selected-providers .checkbox-wrapper input[type=checkbox] + label:before {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 2px;
  content: "";
  display: block;
  height: 1.5rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 1.5rem;
}
.selected-providers ul {
  display: inherit;
  list-style: none;
  padding: inherit;
}
.selected-providers ul li {
  display: inherit;
  padding: inherit;
}
@media (min-width: 1280px) {
  .selected-providers {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
    display: block;
    position: relative;
    background-color: transparent;
    padding: 0;
  }
}
@media (min-width: 1280px) and (min-width: 768px) {
  .selected-providers {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 1280px) and (min-width: 6px) {
  .selected-providers {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) and (min-width: 1280px) {
  .selected-providers {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1280px) and (min-width: 7px) {
  .selected-providers {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1280px) and (min-width: 1440px) {
  .selected-providers {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1280px) and (min-width: 1440px) {
  .selected-providers {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1280px) {
  .selected-providers .selected-providers-list {
    display: flex;
    flex-direction: column;
    padding-right: 1rem;
    position: absolute;
    left: 0;
    overflow: visible;
    transform: translateX(-100%);
    width: auto;
  }
}
@media (min-width: 1280px) {
  .selected-providers .selected-provider-item {
    margin-right: 0;
    margin-bottom: 10px;
    width: 50px;
    flex-basis: 50px;
    min-width: 50px;
    position: relative;
  }
  .selected-providers .selected-provider-item.list-link {
    font-family: AvenirHeavy, Helvetica, sans-serif;
    font-weight: normal;
    align-items: center;
    background-color: #eee;
    display: flex;
    flex-direction: column;
    font-size: 10px;
    height: 50px;
    justify-content: center;
    text-decoration: none;
    position: relative;
    right: 0;
  }
}
@media (min-width: 1280px) {
  .selected-providers .remove-btn {
    margin-top: 0;
    padding-top: 0.25rem;
  }
}
@media (min-width: 1280px) {
  .selected-providers ul {
    display: block;
    padding: 0;
  }
}
@media print and (min-width: 7px) {
  .selected-providers {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
    display: block;
    position: relative;
    background-color: transparent;
    padding: 0;
  }
}
@media print and (min-width: 7px) and (min-width: 768px) {
  .selected-providers {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 7px) and (min-width: 6px) {
  .selected-providers {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 7px) and (min-width: 1280px) {
  .selected-providers {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) and (min-width: 7px) {
  .selected-providers {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) and (min-width: 1440px) {
  .selected-providers {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) and (min-width: 1440px) {
  .selected-providers {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .selected-providers .selected-providers-list {
    display: flex;
    flex-direction: column;
    padding-right: 1rem;
    position: absolute;
    left: 0;
    overflow: visible;
    transform: translateX(-100%);
    width: auto;
  }
}
@media print and (min-width: 7px) {
  .selected-providers .selected-provider-item {
    margin-right: 0;
    margin-bottom: 10px;
    width: 50px;
    flex-basis: 50px;
    min-width: 50px;
    position: relative;
  }
  .selected-providers .selected-provider-item.list-link {
    font-family: AvenirHeavy, Helvetica, sans-serif;
    font-weight: normal;
    align-items: center;
    background-color: #eee;
    display: flex;
    flex-direction: column;
    font-size: 10px;
    height: 50px;
    justify-content: center;
    text-decoration: none;
    position: relative;
    right: 0;
  }
}
@media print and (min-width: 7px) {
  .selected-providers .remove-btn {
    margin-top: 0;
    padding-top: 0.25rem;
  }
}
@media print and (min-width: 7px) {
  .selected-providers ul {
    display: block;
    padding: 0;
  }
}

.sp-outer {
  position: fixed;
  bottom: 0;
  z-index: 1000;
  background-color: #eee;
  width: 100%;
  left: 0;
  box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.2), 0 10px 14px 1px rgba(0, 0, 0, 0.1411764706), 0 4px 18px 3px rgba(0, 0, 0, 0.1215686275);
}
.sp-outer .remove-btn {
  display: block;
}
.sp-outer .sp-header {
  padding-left: 5%;
  padding-bottom: 5px;
  padding-right: 5%;
  margin: 10px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.sp-outer .sp-header .col2 {
  display: flex;
  align-items: baseline;
}
.sp-outer .sp-header .col2 a {
  padding-right: 10px;
  text-wrap: nowrap;
}
.sp-outer .sp-header .red-button {
  background-color: #a71930;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition: transform 0.2s; /* Animation */
}
.sp-outer .sp-header .red-button:hover {
  transform: scale(1.1);
}
.sp-outer .sp-container {
  display: flex;
  flex-wrap: no-wrap;
  justify-content: space-between;
  padding-left: 5%;
  padding-bottom: 2%;
  padding-right: 5%;
}
.sp-outer .sp-container .sp-card {
  display: grid;
  align-items: flex-start;
  justify-content: space-between;
  width: 24%;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: transform 0.2s ease;
  height: 8rem;
}
.sp-outer .sp-container .sp-card:hover {
  transform: scale(1.05);
}
.sp-outer .sp-container .sp-card > * {
  flex: 1 1 auto;
  min-width: 0;
}
.sp-outer .sp-container .sp-card .row1 {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  margin-top: -10px;
}
.sp-outer .sp-container .sp-card .row1 .close-icon {
  position: relative;
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.2s ease;
}
.sp-outer .sp-container .sp-card .row1 .close-icon::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 2px;
  background-color: #333;
  transition: transform 0.2s ease;
  transform: rotate(45deg);
}
.sp-outer .sp-container .sp-card .row1 .close-icon::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 2px;
  background-color: #333;
  transition: transform 0.2s ease;
  transform: rotate(-45deg);
}
.sp-outer .sp-container .sp-card .row1 .close-icon:hover {
  background-color: #a71930;
  transform: scale(1.1);
}
.sp-outer .sp-container .sp-card .row1 .close-icon:hover::before {
  background-color: #fff;
}
.sp-outer .sp-container .sp-card .row1 .close-icon:hover::after {
  background-color: #fff;
}
.sp-outer .sp-container .sp-card .row2 a {
  display: flex;
  text-decoration: none !important;
  align-items: flex-start;
}
.sp-outer .sp-container .sp-card .row2 img {
  width: 30%;
  height: auto;
  margin-right: 15px;
  object-fit: cover;
}
.sp-outer .sp-container .sp-card .row2 .sp-description {
  flex-grow: 1;
  width: 50%;
}
.sp-outer .sp-container .sp-card .row2 .sp-description h3 {
  font-size: 1rem;
  margin: 10px 0;
}
.sp-outer .sp-container .sp-card .row2 .sp-description p {
  font-size: 0.8rem;
}

@media (max-width: 600px) {
  .sp-outer .sp-header {
    padding-left: 0;
    padding-right: 0;
    line-height: 10px;
  }
  .sp-outer .sp-header .col1 h2 {
    font-size: 0.9rem;
  }
  .sp-outer .sp-header .col2 {
    font-size: 0.85rem;
  }
  .sp-outer .sp-header .col2 .red-button {
    font-size: 0.9rem;
  }
  .sp-outer .sp-container .sp-card {
    min-width: 20%;
    flex-direction: column;
    max-height: 6rem;
    max-width: 18%;
  }
  .sp-outer .sp-container .sp-card .row1 {
    margin-top: -5px !important;
  }
  .sp-outer .sp-container .sp-card .row1 .close-icon {
    width: 20px !important;
    height: 20px !important;
  }
  .sp-outer .sp-container .sp-card .row1 .close-icon::after, .sp-outer .sp-container .sp-card .row1 .close-icon::before {
    width: 14px;
    height: 2px;
  }
  .sp-outer .sp-container .sp-card .row2 a {
    display: grid !important;
  }
  .sp-outer .sp-container .sp-card .row2 a img {
    width: 100%;
    min-width: 4rem;
    margin-right: 0;
  }
  .sp-outer .sp-container .sp-card .row2 a .sp-description {
    width: 100%;
  }
  .sp-outer .sp-container .sp-card .row2 a .sp-description p {
    display: none;
  }
  .sp-outer .sp-container .sp-card .row2 a .sp-description h3 {
    font-size: 0.6rem !important;
    text-align: center;
  }
}
.slim-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}
[class^=translated-] .slim-header {
  top: 39px;
}
.slim-header .skip-link {
  position: absolute;
  top: -50px;
  background: #fff;
  padding: 5px;
}
.slim-header .skip-link:focus {
  top: 0;
  z-index: 10;
}
.slim-header .main-header-outer {
  background-color: #a71930;
}
.slim-header .main-header {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  align-items: center;
  display: flex;
  height: 60px;
  justify-content: space-between;
  transition: margin-top 0.3s ease-in-out;
}
@media (min-width: 768px) {
  .slim-header .main-header {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .slim-header .main-header {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .slim-header .main-header {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .slim-header .main-header {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .slim-header .main-header {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .slim-header .main-header {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.slim-header .main-header .icon-phs-logo {
  fill: #fff;
}
.slim-header .main-header .logo-container {
  height: 32px;
}
.slim-header .main-header .logo-subtitle {
  display: none;
}
.slim-header .main-navigation .icon-menu-open {
  fill: #fff;
}
@media (min-width: 1280px) {
  .slim-header {
    height: 40px;
    margin-bottom: 2.75rem;
    position: relative;
  }
  .slim-header .main-header-outer {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9;
  }
  [class^=translated-] .slim-header .main-header-outer {
    top: 39px;
  }
  .slim-header .main-header {
    height: 40px;
    transition: none;
  }
  .slim-header .main-header .logo-container {
    height: 32px;
    margin-top: -4px;
  }
  .slim-header .icon-phs-logo {
    height: 32.288581px;
    width: 279.929px;
  }
  .slim-header .icon-phs-logo {
    height: 30.700618px;
    width: 266.162px;
  }
  .slim-header .nav-link {
    align-items: center;
    color: #fff;
    display: flex;
    font-size: 13px;
  }
  .slim-header .nav-link::after {
    display: none;
  }
  .slim-header .search-btn svg {
    stroke: #fff;
  }
}
@media print and (min-width: 7px) {
  .slim-header {
    height: 40px;
    margin-bottom: 2.75rem;
    position: relative;
  }
  .slim-header .main-header-outer {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9;
  }
  [class^=translated-] .slim-header .main-header-outer {
    top: 39px;
  }
  .slim-header .main-header {
    height: 40px;
    transition: none;
  }
  .slim-header .main-header .logo-container {
    height: 32px;
    margin-top: -4px;
  }
  .slim-header .icon-phs-logo {
    height: 32.288581px;
    width: 279.929px;
  }
  .slim-header .icon-phs-logo {
    height: 30.700618px;
    width: 266.162px;
  }
  .slim-header .nav-link {
    align-items: center;
    color: #fff;
    display: flex;
    font-size: 13px;
  }
  .slim-header .nav-link::after {
    display: none;
  }
  .slim-header .search-btn svg {
    stroke: #fff;
  }
}

.social-share .btn {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  background-color: #fff;
  border-color: #bfbfbf;
  border-radius: 2px;
  color: #000;
  margin-bottom: 10px;
  max-width: 100%;
  padding: 0.75rem 2.5rem;
  text-decoration: none;
  white-space: pre-wrap;
  width: 100%;
}
.social-share .btn:hover, .social-share .btn:focus {
  background-color: #363534;
  border-color: #363534;
  color: #fff;
  text-decoration: none;
}
.social-share .btn-facebook {
  background-color: #117cef;
  border-color: #117cef;
  color: #fff;
}
.social-share .btn-facebook:hover, .social-share .btn-facebook:focus {
  background-color: #0f74e2;
  border-color: #0f74e2;
}
.social-share .btn-twitter {
  background-color: #1ea4f0;
  border-color: #1ea4f0;
  color: #fff;
}
.social-share .btn-twitter:hover, .social-share .btn-twitter:focus {
  background-color: #1b94d8;
  border-color: #1b94d8;
}
@media (min-width: 360px) {
  .social-share .social-share-row {
    display: flex;
    justify-content: space-between;
  }
  .social-share .social-share-row .btn {
    flex: 0 1 calc(50% - 5px);
    margin-bottom: 0;
  }
}
@media print and (min-width: 3px) {
  .social-share .social-share-row {
    display: flex;
    justify-content: space-between;
  }
  .social-share .social-share-row .btn {
    flex: 0 1 calc(50% - 5px);
    margin-bottom: 0;
  }
}

.email-share .email-share-row {
  display: flex;
  justify-content: space-between;
}
.email-share .email-share-row .btn {
  flex: 0 1 calc(50% - 5px);
  margin-bottom: 0;
}
@media (min-width: 1280px) {
  .email-share .modal-container {
    padding-left: 200px;
    padding-right: 200px;
  }
}
@media print and (min-width: 7px) {
  .email-share .modal-container {
    padding-left: 200px;
    padding-right: 200px;
  }
}
.email-share .form .form-control {
  max-width: 100%;
  text-align: center;
}
.email-share .form .form-control input.email-input._ar_hide_ {
  background-color: #eee;
  max-width: 100%;
  width: 100%;
  height: 52px;
}
.email-share .success .success-notification-wrap {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  background-color: #e2f5e4;
  display: flex;
  margin: 1rem 0 2rem 0;
  padding: 1.25rem;
}
.email-share .success .success-notification-wrap .text-wrapper {
  margin-left: 1.25rem;
}
.email-share .in-progress .in-progress-notification-wrap {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  background-color: #fff8da;
  display: flex;
  margin: 1rem 0 2rem 0;
  padding: 1.25rem;
}
.email-share .in-progress .in-progress-notification-wrap .text-wrapper {
  margin-left: 1.25rem;
}
.email-share .errorEmail .error-notification-wrap,
.email-share .errorFail .error-notification-wrap {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  background-color: #ffefef;
  display: flex;
  margin: 1rem 0 2rem 0;
  padding: 1.25rem;
}
.email-share .icon {
  stroke-width: 0px;
}
.email-share .icon-success,
.email-share .error {
  min-height: 22px;
  min-width: 22px;
}
.email-share .icon-question {
  min-height: 19px;
  min-width: 19px;
}
.email-share .icon-arrow-down {
  min-height: 15px;
  min-width: 7.5px;
  stroke-width: 1px;
}
.email-share .floating-label {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #707070;
  left: 1.05rem;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  top: 4px;
}
.email-share .error-message {
  padding: 0 0 0.625rem 0;
}
.email-share .errorEmail .text-wrapper-email {
  margin-left: 1.25rem;
  display: flex;
}
.email-share .errorEmail .text-wrapper-fail {
  display: none;
}
.email-share .errorEmail .email-input {
  border-bottom: 2px solid #a71930;
}
.email-share .errorFail .text-wrapper-email {
  display: none;
}
.email-share .errorFail .text-wrapper-fail {
  margin-left: 1.25rem;
  display: flex;
}
.email-share .success-notification-wrap,
.email-share .error-notification-wrap,
.email-share .in-progress-notification-wrap {
  display: none;
}
.email-share .clear-btn {
  color: #00759a;
  text-decoration: none;
  margin: auto calc(50% - 24px);
}
.email-share .btn {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  background-color: #363534;
  border-color: #bfbfbf;
  border-radius: 2px;
  color: #fff;
  margin-bottom: 10px;
  max-width: 100%;
  padding: 0.75rem 2.5rem;
  text-decoration: none;
  white-space: pre-wrap;
  width: 100%;
}
.email-share .btn:hover, .email-share .btn:focus {
  background-color: #363534;
  border-color: #363534;
  color: #fff;
  text-decoration: none;
}
.email-share .btn:disabled {
  background-color: #bfbfbf;
  border: 1px solid #bfbfbf;
  color: #fff;
}
.email-share .btn-facebook {
  background-color: #117cef;
  border-color: #117cef;
  color: #fff;
}
.email-share .btn-facebook:hover, .email-share .btn-facebook:focus {
  background-color: #0f74e2;
  border-color: #0f74e2;
}
.email-share .btn-twitter {
  background-color: #1ea4f0;
  border-color: #1ea4f0;
  color: #fff;
}
.email-share .btn-twitter:hover, .email-share .btn-twitter:focus {
  background-color: #1b94d8;
  border-color: #1b94d8;
}

.title-bar {
  align-items: flex-start;
  display: flex;
  flex-flow: column-reverse;
  margin: 60px 30px 0.75rem;
  padding-top: 1.5rem;
}
.title-bar .language {
  margin-left: auto;
}
.title-bar.has-search .title-and-links {
  display: none;
}
.search-loaded .title-bar.has-search {
  margin-bottom: 0.75rem;
  margin-top: 134px;
}
.search-loaded .title-bar.has-search .title-and-links {
  display: grid;
  gap: 10px;
}
.title-bar .title {
  margin-bottom: 0.25rem;
}
.title-bar .btn-link {
  margin-bottom: 0;
  margin-top: 0.25rem;
  text-align: left;
}
@media (min-width: 768px) {
  .title-bar {
    align-items: center;
    display: flex;
    flex-flow: initial;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    padding-top: 2rem;
  }
  .title-bar.has-search {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
  }
  .title-bar.has-search .title-and-links {
    display: grid;
    visibility: hidden;
  }
  .search-loaded .title-bar.has-search {
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }
  .search-loaded .title-bar.has-search .title-and-links {
    visibility: visible;
  }
  .title-bar.has-search .title {
    margin-bottom: 0;
  }
  .title-bar .title {
    margin-bottom: 0.375rem;
  }
}
@media print and (min-width: 6px) {
  .title-bar {
    align-items: center;
    display: flex;
    flex-flow: initial;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    padding-top: 2rem;
  }
  .title-bar.has-search {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
  }
  .title-bar.has-search .title-and-links {
    display: grid;
    visibility: hidden;
  }
  .search-loaded .title-bar.has-search {
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }
  .search-loaded .title-bar.has-search .title-and-links {
    visibility: visible;
  }
  .title-bar.has-search .title {
    margin-bottom: 0;
  }
  .title-bar .title {
    margin-bottom: 0.375rem;
  }
}
@media (min-width: 1280px) {
  .title-bar {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
    margin-top: 0;
    padding-top: 0;
  }
}
@media (min-width: 1280px) and (min-width: 768px) {
  .title-bar {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 1280px) and (min-width: 6px) {
  .title-bar {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) and (min-width: 1280px) {
  .title-bar {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1280px) and (min-width: 7px) {
  .title-bar {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1280px) and (min-width: 1440px) {
  .title-bar {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1280px) and (min-width: 1440px) {
  .title-bar {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1280px) {
  .title-bar.has-search {
    margin-top: 0;
  }
  .search-loaded .title-bar.has-search {
    margin-top: 0;
  }
}
@media print and (min-width: 7px) {
  .title-bar {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
    margin-top: 0;
    padding-top: 0;
  }
}
@media print and (min-width: 7px) and (min-width: 768px) {
  .title-bar {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 7px) and (min-width: 6px) {
  .title-bar {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 7px) and (min-width: 1280px) {
  .title-bar {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) and (min-width: 7px) {
  .title-bar {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) and (min-width: 1440px) {
  .title-bar {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) and (min-width: 1440px) {
  .title-bar {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .title-bar.has-search {
    margin-top: 0;
  }
  .search-loaded .title-bar.has-search {
    margin-top: 0;
  }
}

.three-up {
  margin-bottom: 2rem;
}
.heart-and-vascular .three-up {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.heart-and-vascular .three-up .three-up-image-container {
  padding-bottom: 56.25%;
}
.heart-and-vascular .three-up .three-up-item {
  flex: 0 1 100%;
}
.heart-and-vascular .three-up .three-up-content {
  padding: 1rem;
}
.three-up.card-4-by-3 .three-up-image-container {
  padding-bottom: 75%;
}
.three-up.card-16-by-9 .three-up-image-container {
  padding-bottom: 56.25%;
}
.three-up .three-up-item {
  background-color: #eee;
  margin-bottom: 1.25rem;
}
.three-up .three-up-item.link-item {
  display: flex;
  flex-direction: column;
  text-decoration: none;
}
.three-up .three-up-item.link-item:hover .three-up-content, .three-up .three-up-item.link-item:focus .three-up-content {
  text-decoration: underline;
}
.three-up .three-up-item.link-item .three-up-content {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  align-items: center;
  color: #00759a;
  display: flex;
  flex: 1;
  justify-content: center;
  min-height: 60px;
  padding: 1rem 0.5rem;
  text-align: center;
}
.three-up .three-up-image-container {
  display: block;
  height: 0;
  overflow: hidden;
  padding-bottom: 66.666%;
  position: relative;
  width: 100%;
}
.three-up .three-up-image {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.three-up .three-up-image img {
  font-family: "object-fit: cover; object-position: center 25%;";
  height: 100%;
  object-fit: cover;
  object-position: center 25%;
  width: 100%;
}
.three-up .three-up-content {
  padding: 24px 30px 30px;
}
.three-up .three-up-content :last-child {
  margin-bottom: 0;
}
.three-up .three-up-title {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 0.75rem;
}
.three-up p {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
}
.three-up p a {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
}
.three-up .btn {
  min-width: 0;
  width: 100%;
}
@media (min-width: 360px) {
  .heart-and-vascular .three-up .three-up-item {
    flex: 0 1 calc(50% - 0.5rem);
    max-width: calc(50% - 0.5rem);
  }
}
@media print and (min-width: 3px) {
  .heart-and-vascular .three-up .three-up-item {
    flex: 0 1 calc(50% - 0.5rem);
    max-width: calc(50% - 0.5rem);
  }
}
@media (min-width: 768px) {
  .three-up {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .three-up {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 768px) and (min-width: 6px) {
  .three-up {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 768px) and (min-width: 1280px) {
  .three-up {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 768px) and (min-width: 7px) {
  .three-up {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 768px) and (min-width: 1440px) {
  .three-up {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 768px) and (min-width: 1440px) {
  .three-up {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 768px) {
  .heart-and-vascular .three-up {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .heart-and-vascular .three-up .three-up-item {
    flex: 0 1 calc(33.3333333333% - 1rem);
    max-width: calc(33.3333333333% - 1rem);
  }
}
@media (min-width: 768px) {
  .container .three-up, .hv-two-col .three-up {
    padding: 0;
  }
}
@media (min-width: 768px) {
  .three-up:after {
    content: " ";
    flex: 0 1 calc(33.3333333333% - 1rem);
    max-width: calc(33.3333333333% - 1rem);
  }
}
@media (min-width: 768px) {
  .three-up .three-up-item {
    flex: 0 1 calc(33.3333333333% - 1rem);
    max-width: calc(33.3333333333% - 1rem);
  }
}
@media print and (min-width: 6px) {
  .three-up {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media print and (min-width: 6px) and (min-width: 768px) {
  .three-up {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) and (min-width: 6px) {
  .three-up {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) and (min-width: 1280px) {
  .three-up {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 6px) and (min-width: 7px) {
  .three-up {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 6px) and (min-width: 1440px) {
  .three-up {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 6px) and (min-width: 1440px) {
  .three-up {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 6px) {
  .heart-and-vascular .three-up {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .heart-and-vascular .three-up .three-up-item {
    flex: 0 1 calc(33.3333333333% - 1rem);
    max-width: calc(33.3333333333% - 1rem);
  }
}
@media print and (min-width: 6px) {
  .container .three-up, .hv-two-col .three-up {
    padding: 0;
  }
}
@media print and (min-width: 6px) {
  .three-up:after {
    content: " ";
    flex: 0 1 calc(33.3333333333% - 1rem);
    max-width: calc(33.3333333333% - 1rem);
  }
}
@media print and (min-width: 6px) {
  .three-up .three-up-item {
    flex: 0 1 calc(33.3333333333% - 1rem);
    max-width: calc(33.3333333333% - 1rem);
  }
}
@media (min-width: 1280px) {
  .heart-and-vascular .three-up .three-up-content {
    padding: 24px 30px 30px;
  }
  .three-up .three-up-item.link-item .three-up-content {
    font-size: 1.125rem;
    line-height: 1.3333333333;
    padding: 30px 1rem;
  }
  .three-up.four-up:before {
    content: " ";
    flex: 0 1 calc(25% - 1rem);
    max-width: calc(25% - 1rem);
    order: 1;
  }
  .three-up.four-up:after {
    content: " ";
    flex: 0 1 calc(25% - 1rem);
  }
  .three-up.four-up .three-up-item {
    flex: 0 1 calc(25% - 1rem);
    max-width: calc(25% - 1rem);
  }
  .three-up.four-up .three-up-item.link-item .three-up-content {
    font-size: 0.875rem;
    line-height: 1.4285714286;
    min-height: 65px;
    padding: 15px;
  }
}
@media print and (min-width: 7px) {
  .heart-and-vascular .three-up .three-up-content {
    padding: 24px 30px 30px;
  }
  .three-up .three-up-item.link-item .three-up-content {
    font-size: 1.125rem;
    line-height: 1.3333333333;
    padding: 30px 1rem;
  }
  .three-up.four-up:before {
    content: " ";
    flex: 0 1 calc(25% - 1rem);
    max-width: calc(25% - 1rem);
    order: 1;
  }
  .three-up.four-up:after {
    content: " ";
    flex: 0 1 calc(25% - 1rem);
  }
  .three-up.four-up .three-up-item {
    flex: 0 1 calc(25% - 1rem);
    max-width: calc(25% - 1rem);
  }
  .three-up.four-up .three-up-item.link-item .three-up-content {
    font-size: 0.875rem;
    line-height: 1.4285714286;
    min-height: 65px;
    padding: 15px;
  }
}

.language-dropdown {
  min-height: 66px;
  padding: 0 0 0.75rem;
}
.language-dropdown .language-list img {
  display: inline;
}
@media (min-width: 768px) {
  .language-dropdown {
    min-height: 42px;
    padding: 0;
    text-align: right;
  }
}
@media print and (min-width: 6px) {
  .language-dropdown {
    min-height: 42px;
    padding: 0;
    text-align: right;
  }
}

.portal .language-dropdown {
  padding-top: 10px;
}
.portal .goog-te-gadget .goog-te-combo {
  margin: 2px 0;
}
.portal .goog-logo-link,
.portal .goog-te-gadget {
  color: #fff;
}
.portal .goog-logo-link a,
.portal .goog-logo-link a:hover,
.portal .goog-te-gadget a,
.portal .goog-te-gadget a:hover {
  font-size: 0.75rem;
  line-height: 1.5;
  font-family: AvenirLight, Helvetica, sans-serif;
  font-weight: normal;
  color: #fff;
  text-decoration: none;
}
@media (min-width: 768px) {
  .portal .language-dropdown {
    padding-top: 0px;
  }
}
@media print and (min-width: 6px) {
  .portal .language-dropdown {
    padding-top: 0px;
  }
}

.global-search {
  height: 100px;
  width: 100%;
  background-color: #fafafa;
  display: none;
  justify-content: center;
  align-items: center;
}
.global-search.homepage-search-results, .global-search.search-results-page {
  background-color: transparent;
  display: flex;
  height: 60px;
  padding-top: 0;
  margin: 0;
}
.global-search.homepage-search-results .global-search-row, .global-search.search-results-page .global-search-row {
  padding: 0;
}
.global-search.homepage-search-results .global-search-row .global-search-button, .global-search.search-results-page .global-search-row .global-search-button {
  display: none;
}
.global-search.homepage-search-results .global-search-row .search-btn, .global-search.search-results-page .global-search-row .search-btn {
  display: block;
  position: absolute;
  right: 2rem;
  top: 1.2rem;
}
.global-search.homepage-search-results .global-search-row .search-btn .icon-search, .global-search.search-results-page .global-search-row .search-btn .icon-search {
  stroke: #707070;
}
.global-search.homepage-search-results .global-search-row .global-search-input, .global-search.search-results-page .global-search-row .global-search-input {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirLight, Helvetica, sans-serif;
  font-weight: normal;
  background-color: #eee;
  border: 0px;
  width: 100%;
}
.global-search.homepage-search-results .global-search-button, .global-search.search-results-page .global-search-button {
  background-color: #eee;
}
.global-search.homepage-search-results .search-close, .global-search.search-results-page .search-close {
  display: none;
}
.global-search.search-results-page {
  margin-bottom: 2rem;
}
.search-open .global-search {
  display: none;
}
.global-search .global-search-row {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
}
.global-search .global-search-row .global-form-search {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.global-search .global-search-row .global-search-input {
  font-size: 1.5rem;
  line-height: 1.3333333333;
  font-family: AvenirLight, Helvetica, sans-serif;
  font-weight: normal;
  background-color: white;
  height: 60px;
  width: 75%;
  border: 1px solid #bfbfbf;
  margin-bottom: 0;
  text-align: left;
  padding-left: 2rem;
}
.global-search .global-search-row .global-search-input::placeholder {
  color: #707070;
  text-align: left;
}
.global-search .global-search-row .global-search-button {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  float: left;
  width: 150px;
  height: 60px;
  background: white;
  font-size: 17px;
  border: 1px solid #bfbfbf;
  border-left: none;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}
.global-search .global-search-row .global-search-button:hover, .global-search .global-search-row .global-search-button:focus {
  background-color: #363534;
  border-color: #363534;
  color: #fff;
}
.global-search .global-search-row .search-btn {
  display: none;
}
.global-search .global-search-row .search-close {
  position: relative;
  left: 3%;
}
@media (min-width: 768px) {
  .global-search.search-results-page {
    height: auto;
  }
  .global-search.search-results-page .global-search-row {
    padding: 60px 30px;
  }
  .global-search.search-results-page .global-search-row .global-search-input {
    font-size: 1.5rem;
    line-height: 1.3333333333;
    font-family: AvenirLight, Helvetica, sans-serif;
    font-weight: normal;
    background-color: white;
    height: 65px;
    width: 100%;
    border: 1px solid #bfbfbf;
    margin-bottom: 0;
    text-align: left;
    padding-left: 2rem;
  }
  .global-search.search-results-page .global-search-row .global-search-input::placeholder {
    color: #707070;
    text-align: left;
  }
  .global-search.search-results-page .global-search-row .global-search-button {
    display: block;
    height: 65px;
  }
  .global-search.search-results-page .global-search-row .search-btn {
    display: none;
  }
  .global-search.search-results-page .global-search-row .search-close {
    position: relative;
    left: 3%;
  }
  .global-search.search-results-page .global-search-row {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .global-search.search-results-page .global-search-row {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 768px) and (min-width: 6px) {
  .global-search.search-results-page .global-search-row {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 768px) and (min-width: 1280px) {
  .global-search.search-results-page .global-search-row {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 768px) and (min-width: 7px) {
  .global-search.search-results-page .global-search-row {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 768px) and (min-width: 1440px) {
  .global-search.search-results-page .global-search-row {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 768px) and (min-width: 1440px) {
  .global-search.search-results-page .global-search-row {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 768px) {
  .global-search.search-results-page .global-form-search {
    padding: 0;
  }
}
@media print and (min-width: 6px) {
  .global-search.search-results-page {
    height: auto;
  }
  .global-search.search-results-page .global-search-row {
    padding: 60px 30px;
  }
  .global-search.search-results-page .global-search-row .global-search-input {
    font-size: 1.5rem;
    line-height: 1.3333333333;
    font-family: AvenirLight, Helvetica, sans-serif;
    font-weight: normal;
    background-color: white;
    height: 65px;
    width: 100%;
    border: 1px solid #bfbfbf;
    margin-bottom: 0;
    text-align: left;
    padding-left: 2rem;
  }
  .global-search.search-results-page .global-search-row .global-search-input::placeholder {
    color: #707070;
    text-align: left;
  }
  .global-search.search-results-page .global-search-row .global-search-button {
    display: block;
    height: 65px;
  }
  .global-search.search-results-page .global-search-row .search-btn {
    display: none;
  }
  .global-search.search-results-page .global-search-row .search-close {
    position: relative;
    left: 3%;
  }
  .global-search.search-results-page .global-search-row {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
  }
}
@media print and (min-width: 6px) and (min-width: 768px) {
  .global-search.search-results-page .global-search-row {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) and (min-width: 6px) {
  .global-search.search-results-page .global-search-row {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) and (min-width: 1280px) {
  .global-search.search-results-page .global-search-row {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 6px) and (min-width: 7px) {
  .global-search.search-results-page .global-search-row {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 6px) and (min-width: 1440px) {
  .global-search.search-results-page .global-search-row {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 6px) and (min-width: 1440px) {
  .global-search.search-results-page .global-search-row {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 6px) {
  .global-search.search-results-page .global-form-search {
    padding: 0;
  }
}
@media (min-width: 1024px) {
  .search-open .global-search {
    display: flex;
  }
  .global-search.search-results-page .global-search-row {
    justify-content: flex-start;
  }
  .global-search.search-results-page .global-search-row .global-form-search {
    flex: 0 0 75%;
    max-width: 75%;
    margin-bottom: 0;
    padding: 0 10px;
    margin-left: 0;
    padding: 0;
  }
  .global-search .global-search-input {
    height: 65px;
  }
  .global-search .global-search-button {
    height: 65px;
  }
}
@media (min-width: 1280px) {
  .global-search {
    height: 140px;
  }
  .global-search .global-form-search {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
  }
}
@media (min-width: 1280px) and (min-width: 768px) {
  .global-search .global-form-search {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 1280px) and (min-width: 6px) {
  .global-search .global-form-search {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) and (min-width: 1280px) {
  .global-search .global-form-search {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1280px) and (min-width: 7px) {
  .global-search .global-form-search {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1280px) and (min-width: 1440px) {
  .global-search .global-form-search {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1280px) and (min-width: 1440px) {
  .global-search .global-form-search {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .global-search {
    height: 140px;
  }
  .global-search .global-form-search {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
  }
}
@media print and (min-width: 7px) and (min-width: 768px) {
  .global-search .global-form-search {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 7px) and (min-width: 6px) {
  .global-search .global-form-search {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 7px) and (min-width: 1280px) {
  .global-search .global-form-search {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) and (min-width: 7px) {
  .global-search .global-form-search {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) and (min-width: 1440px) {
  .global-search .global-form-search {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) and (min-width: 1440px) {
  .global-search .global-form-search {
    padding-right: 70px;
    padding-left: 70px;
  }
}

.global-search-locations {
  margin-bottom: 1.5rem;
}
.global-search-locations ul {
  list-style-type: none;
  padding-left: 1.5rem;
}
.global-search-locations ul .title {
  list-style-image: url("/assets/images/icons/map_view.svg");
}
.global-search-locations ul .title h4 {
  font-size: 0.875rem;
  line-height: 1.4285714286;
}
.global-search-locations p {
  font-size: 0.75rem;
  line-height: 1.5;
  margin: 0;
}

.global-search-results {
  margin-bottom: 1rem;
}
.global-search-results h4 {
  font-size: 1.25rem;
  line-height: 1.5;
}

.horizontal-card {
  display: flex;
  flex-direction: column;
}
.horizontal-card.four-by-three .horizontal-card-image-container {
  padding-bottom: 75%;
}
.horizontal-card .horizontal-card-image-outer {
  flex: 1;
}
.horizontal-card .horizontal-card-image-container {
  display: block;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
  min-width: 170px;
}
.horizontal-card .horizontal-card-image-container img {
  width: 100%;
  margin: 0;
  display: block;
}
.horizontal-card .content {
  flex: 3;
  margin-left: 0;
  margin-top: 2rem;
}
@media (min-width: 414px) {
  .horizontal-card {
    flex-direction: row;
  }
  .horizontal-card.four-by-three .horizontal-card-image-container {
    padding-bottom: 75%;
  }
  .horizontal-card .content {
    margin-top: 0;
    margin-left: 1rem;
  }
}
@media print and (min-width: 5px) {
  .horizontal-card {
    flex-direction: row;
  }
  .horizontal-card.four-by-three .horizontal-card-image-container {
    padding-bottom: 75%;
  }
  .horizontal-card .content {
    margin-top: 0;
    margin-left: 1rem;
  }
}

.article-list-paginated .article-list {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 2rem;
}
.article-list-paginated .article-list ul {
  padding-left: 0;
}
.article-list-paginated .article-listing {
  display: none;
}
.article-list-paginated .article-listing.active {
  display: block;
}
.article-list-paginated .article-listing:last-of-type {
  margin-bottom: 2.5rem;
}
.article-list-paginated .article-listing a {
  color: #000;
}
.article-list-paginated .pagination-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.article-list-paginated .pagination-controls .pagination-counts {
  justify-content: center;
}
.article-list-paginated .pagination-controls .page-link .left {
  transform: rotate(180deg);
}
.article-list-paginated .results-count {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .article-list-paginated {
    margin-bottom: 2rem;
    padding-right: 30px;
  }
  .article-list-paginated .pagination-controls {
    align-items: flex-start;
  }
  .article-list-paginated .results-count {
    font-size: 0.875rem;
    line-height: 1.4285714286;
  }
}
@media print and (min-width: 6px) {
  .article-list-paginated {
    margin-bottom: 2rem;
    padding-right: 30px;
  }
  .article-list-paginated .pagination-controls {
    align-items: flex-start;
  }
  .article-list-paginated .results-count {
    font-size: 0.875rem;
    line-height: 1.4285714286;
  }
}
@media (min-width: 1280px) {
  .article-list-paginated {
    padding-right: 70px;
  }
}
@media print and (min-width: 7px) {
  .article-list-paginated {
    padding-right: 70px;
  }
}

.inaccuracy-form .success .success-notification-wrap {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  background-color: #e2f5e4;
  display: flex;
  margin: 1rem 0 2rem 0;
  padding: 1.25rem;
}
.inaccuracy-form .success .success-notification-wrap .text-wrapper {
  margin-left: 1.25rem;
}
.inaccuracy-form .errorName .error-notification-wrap,
.inaccuracy-form .errorCheckbox .error-notification-wrap,
.inaccuracy-form .errorEmail .error-notification-wrap,
.inaccuracy-form .errorFail .error-notification-wrap {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  background-color: #ffefef;
  display: flex;
  margin: 1rem 0 2rem 0;
  padding: 1.25rem;
}
.inaccuracy-form .icon {
  stroke-width: 0px;
}
.inaccuracy-form .icon-phs-logo {
  fill: #a71930;
}
.inaccuracy-form .icon-success,
.inaccuracy-form .error {
  min-height: 22px;
  min-width: 22px;
  margin-right: 17px;
}
.inaccuracy-form .icon-question {
  min-height: 19px;
  min-width: 19px;
}
.inaccuracy-form .icon-arrow-down {
  min-height: 15px;
  min-width: 7.5px;
  stroke-width: 1px;
}
.inaccuracy-form .break {
  flex-basis: 100%;
  height: 0;
}
.inaccuracy-form .floating-label {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #707070;
  left: 1.05rem;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  top: 4px;
}
.inaccuracy-form .error-message {
  padding: 0 0 0.625rem 0;
}
.inaccuracy-form .errorName .text-wrapper-checkbox {
  display: none;
}
.inaccuracy-form .errorName .text-wrapper-email {
  display: none;
}
.inaccuracy-form .errorName .text-wrapper-name {
  margin-left: 1.25rem;
  display: flex;
}
.inaccuracy-form .errorName .text-wrapper-fail {
  display: none;
}
.inaccuracy-form .errorName .name-input {
  border-bottom: 2px solid #a71930 !important;
}
.inaccuracy-form .errorCheckbox .text-wrapper-checkbox {
  display: flex;
}
.inaccuracy-form .errorCheckbox .text-wrapper-email {
  display: none;
}
.inaccuracy-form .errorCheckbox .text-wrapper-name {
  margin-left: 1.25rem;
  display: none;
}
.inaccuracy-form .errorCheckbox .text-wrapper-fail {
  display: none;
}
.inaccuracy-form .in-progress .in-progress-notification-wrap {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  background-color: #fff8da;
  display: flex;
  margin: 1rem 0 2rem 0;
  padding: 1.25rem;
}
.inaccuracy-form .in-progress .in-progress-notification-wrap .text-wrapper {
  margin-left: 1.25rem;
}
.inaccuracy-form .errorEmail .text-wrapper-checkbox {
  margin-left: 1.25rem;
  display: none;
}
.inaccuracy-form .errorEmail .text-wrapper-email {
  margin-left: 1.25rem;
  display: flex;
}
.inaccuracy-form .errorEmail .text-wrapper-fail {
  display: none;
}
.inaccuracy-form .errorEmail .text-wrapper-name {
  display: none;
}
.inaccuracy-form .errorEmail .email-input {
  border-bottom: 2px solid #a71930 !important;
}
.inaccuracy-form .errorName.errorEmail .text-wrapper-checkbox {
  margin-left: 1.25rem;
  display: none;
}
.inaccuracy-form .errorName.errorEmail .text-wrapper-email {
  margin-left: 1.25rem;
  display: flex;
}
.inaccuracy-form .errorName.errorEmail .text-wrapper-name {
  display: flex;
}
.inaccuracy-form .errorName.errorEmail .text-wrapper-fail {
  display: none;
}
.inaccuracy-form .errorName.errorEmail .email-input {
  border-bottom: 2px solid #a71930 !important;
}
.inaccuracy-form .errorName.errorEmail .name-input {
  border-bottom: 2px solid #a71930 !important;
}
.inaccuracy-form .errorFail .text-wrapper-name {
  display: none;
}
.inaccuracy-form .errorFail .text-wrapper-email {
  display: none;
}
.inaccuracy-form .errorFail .text-wrapper-fail {
  margin-left: 1.25rem;
  display: flex;
}
.inaccuracy-form .success-notification-wrap,
.inaccuracy-form .error-notification-wrap,
.inaccuracy-form .in-progress-notification-wrap {
  display: none;
}
.inaccuracy-form .clear-btn {
  color: #00759a;
  text-decoration: none;
  margin: auto calc(50% - 24px);
}
.inaccuracy-form .content-col {
  margin-left: -15px;
}
.inaccuracy-form .checkbox-wrapper {
  font-size: 0.875rem;
  line-height: 1.2857142857;
  width: 49%;
}
.inaccuracy-form .checkbox-wrapper input[type=checkbox] {
  height: 1.5rem;
  margin-top: 2px;
  opacity: 0;
  width: 1.5rem;
  border: 1px solid #bfbfbf;
}
.inaccuracy-form .checkbox-wrapper input[type=checkbox] + label:before {
  background-color: #fff;
  border: 1px solid #707070;
  border-radius: 2px;
  content: "";
  display: block;
  height: 1.5rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 1.5rem;
}
.inaccuracy-form .inaccuracy-list-btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column: 2;
  width: 100%;
}
.inaccuracy-form .hide-disable {
  display: none;
}
.inaccuracy-form .input-field {
  max-width: 100%;
  margin-bottom: 20px;
  justify-content: center;
  position: relative;
  max-width: 100%;
}
.inaccuracy-form .input-field textarea.text-box._ar_hide_ {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  background-color: #eee;
  border: 1px solid #eee;
  height: 200px;
  padding: 1.5rem 1.05rem;
  resize: none;
  width: 20rem;
}
.inaccuracy-form .input-field :focus {
  border-bottom: 1px solid #999999 !important;
}
.inaccuracy-form .input-field :focus-visible {
  border-bottom: 1px solid #999999 !important;
}
.inaccuracy-form .input-field input.name-input._ar_hide_,
.inaccuracy-form .input-field input.email-input._ar_hide_ {
  background-color: #eee;
  border: 1px solid #eee;
  color: #000;
  padding-top: 1.8rem;
  text-align: left;
}
.inaccuracy-form .input-field :focus {
  border-bottom: 1px solid #999999 !important;
}
.inaccuracy-form .input-field span.floating-label {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #707070;
  left: 1.05rem;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  top: 4px;
}
.inaccuracy-form .input-field .text-box {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  background-color: #eee;
  border: 1px solid #eee;
  height: 200px;
  padding: 1.5rem 1.05rem;
  resize: none;
  width: 100%;
}
.inaccuracy-form .input-field .text-box:focus-visible {
  outline: none;
}
.inaccuracy-form .input-field .text-counter-wrapper {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #707070;
  padding: 0 0 1.625rem 1.25rem;
}
.inaccuracy-form .inaccuracyform-cta {
  display: block;
  margin-top: 10px;
}
.inaccuracy-form .inaccuracyform-cta .submit-button.send-button {
  background-color: #363534;
  border: 1px solid #bfbfbf;
  color: #fff;
  margin-bottom: 1.5rem;
  min-width: 192px;
  margin-right: 57px;
}
.inaccuracy-form .inaccuracyform-cta button.btn.btn-primary.submit-button.send-button[disabled] {
  background-color: #bfbfbf;
}
.inaccuracy-form .inaccuracyform-cta .cancel-button {
  background-color: #fff;
  border: 1px solid #bfbfbf;
  color: #707070;
}
.inaccuracy-form input.email-input._ar_hide_:focus {
  border-bottom: 1px solid #999999 !important;
}
@media (min-width: 414px) {
  .inaccuracy-form .input-field textarea.text-box._ar_hide_ {
    width: 44rem;
  }
  .inaccuracy-form .inaccuracy-form .inaccuracyform-cta {
    display: flex;
  }
}
@media print and (min-width: 5px) {
  .inaccuracy-form .input-field textarea.text-box._ar_hide_ {
    width: 44rem;
  }
  .inaccuracy-form .inaccuracy-form .inaccuracyform-cta {
    display: flex;
  }
}

.generating-pdf-message {
  margin-top: 150px;
  text-align: center;
}

.pdf-loading {
  margin-top: 10px;
  position: relative;
}
.pdf-loading.loading {
  pointer-events: none;
}
.pdf-loading.loading .loading-overlay {
  opacity: 1;
}

.pls-pdf-container {
  margin-top: 60px;
}
.pls-pdf-container iframe {
  width: 100%;
  height: 800px;
}

.download-link {
  margin-top: 150px;
  text-align: center;
}

/* Provider Profile */
@media (min-width: 768px) {
  .provider-background .accordion-item-body {
    columns: 3;
    column-gap: 0;
  }
  .provider-background .accordion-item-body > div {
    break-inside: avoid-column;
  }
}
@media print and (min-width: 6px) {
  .provider-background .accordion-item-body {
    columns: 3;
    column-gap: 0;
  }
  .provider-background .accordion-item-body > div {
    break-inside: avoid-column;
  }
}

@media (min-width: 768px) {
  .provider-special-services .accordion-item-body {
    columns: 3;
    column-gap: 0;
  }
  .provider-special-services .accordion-item-body > div {
    break-inside: avoid-column;
  }
}
@media print and (min-width: 6px) {
  .provider-special-services .accordion-item-body {
    columns: 3;
    column-gap: 0;
  }
  .provider-special-services .accordion-item-body > div {
    break-inside: avoid-column;
  }
}

.provider-locations {
  padding: 0;
  padding-left: 0px;
  padding-right: 0px;
}
.provider-locations .modal .modal-inner {
  padding: 0;
}
@media (min-width: 768px) {
  .provider-locations .provider-locations {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .provider-locations .location-block {
    display: flex;
    flex-wrap: nowrap;
  }
  .provider-locations .location-block .location-info-container,
.provider-locations .location-block .location-list-nav {
    flex: 0 1 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .provider-locations .location-block .location-info {
    margin: 1rem 0;
  }
  .provider-locations .location-block .location-info-list {
    display: flex;
    flex-wrap: wrap;
    justify-items: flex-start;
    width: 100%;
  }
  .provider-locations .location-block .location-info-list .location-item {
    display: flex;
    flex-basis: 50%;
    max-width: 50%;
  }
  .provider-locations .location-block .location-info-list .location-info-container {
    flex: none;
    padding-right: 2px;
    max-width: none;
    width: 100%;
  }
}
@media print and (min-width: 6px) {
  .provider-locations .provider-locations {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .provider-locations .location-block {
    display: flex;
    flex-wrap: nowrap;
  }
  .provider-locations .location-block .location-info-container,
.provider-locations .location-block .location-list-nav {
    flex: 0 1 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .provider-locations .location-block .location-info {
    margin: 1rem 0;
  }
  .provider-locations .location-block .location-info-list {
    display: flex;
    flex-wrap: wrap;
    justify-items: flex-start;
    width: 100%;
  }
  .provider-locations .location-block .location-info-list .location-item {
    display: flex;
    flex-basis: 50%;
    max-width: 50%;
  }
  .provider-locations .location-block .location-info-list .location-info-container {
    flex: none;
    padding-right: 2px;
    max-width: none;
    width: 100%;
  }
}
@media (min-width: 1280px) {
  .provider-locations .location-block .location-info-list .location-item {
    display: flex;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
}
@media print and (min-width: 7px) {
  .provider-locations .location-block .location-info-list .location-item {
    display: flex;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
}

.provider-profile-detail .profile-inner {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  background-color: #fafafa;
  color: #363534;
  padding-bottom: 2rem;
}
.provider-profile-detail p {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
}
.provider-profile-detail a {
  color: #00759a;
  cursor: pointer;
}
.provider-profile-detail .icon-transport {
  fill: black;
  width: 2rem;
  height: 2rem;
}
.provider-profile-detail .transport-info-wrapper {
  display: flex;
  gap: 3px;
  margin-top: 10px;
}
.provider-profile-detail .transport-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.provider-profile-detail .transport-item-container p {
  font-size: 12px;
}
.provider-profile-detail .btn {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  margin: 0;
  padding: 0.625rem 1rem 0.5rem;
}
.provider-profile-detail .profile-image-container {
  display: block;
  height: 0;
  overflow: hidden;
  padding-bottom: 66.666%;
  position: relative;
  width: 100%;
}
.provider-profile-detail .profile-image {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.provider-profile-detail .profile-image img {
  font-family: "object-fit: cover; object-position: center 25%;";
  height: 100%;
  object-fit: cover;
  object-position: center 25%;
  width: 100%;
}
.provider-profile-detail .provider-details {
  padding: 1.5rem 30px;
}
.provider-profile-detail .provider-details .provider-list-btn {
  display: none;
}
.provider-profile-detail .provider-list-btn {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  align-items: center;
  border-bottom: 1px solid #bfbfbf;
  display: flex;
  height: 40px;
  justify-content: center;
  text-decoration: none;
  width: 100%;
}
.provider-profile-detail .provider-list-btn svg {
  margin-right: 0.5rem;
}
.provider-profile-detail .provider-list-btn .checkbox-wrapper {
  align-items: flex-start;
  display: flex;
  min-height: 1.5rem;
  justify-items: flex-start;
  position: relative;
  margin-bottom: 0;
  margin-top: 4px;
}
.provider-profile-detail .provider-list-btn .checkbox-wrapper input[type=checkbox] {
  height: 1.5rem;
  margin-top: 2px;
  opacity: 0;
  width: 1.5rem;
}
.provider-profile-detail .provider-list-btn .checkbox-wrapper input[type=checkbox]:checked + label:after {
  border-bottom: 1px solid #137e13;
  border-right: 1px solid #137e13;
  content: "";
  display: block;
  height: 0.9rem;
  left: 0;
  margin: 0.25rem 0.5rem;
  position: absolute;
  top: 0;
  transform: rotate(45deg) translate(-1px, -1px);
  width: 0.45rem;
  border-bottom-width: 2px;
  border-right-width: 2px;
}
.provider-profile-detail .provider-list-btn .checkbox-wrapper input[type=checkbox]:not([disabled]):hover {
  cursor: pointer;
}
.provider-profile-detail .provider-list-btn .checkbox-wrapper input[type=checkbox]:not([disabled]):hover + label:before {
  border-color: #707070;
}
.provider-profile-detail .provider-list-btn .checkbox-wrapper input[type=checkbox]:not([disabled]):focus + label:before {
  border-color: #707070;
  border-width: 2px;
}
.provider-profile-detail .provider-list-btn .checkbox-wrapper input[type=checkbox]:not([disabled]) + label:hover {
  cursor: pointer;
}
.provider-profile-detail .provider-list-btn .checkbox-wrapper input[type=checkbox]:not([disabled]) + label:hover:before {
  border-color: #707070;
}
.provider-profile-detail .provider-list-btn .checkbox-wrapper input[type=checkbox] + label:before {
  background-color: #fff;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  content: "";
  display: block;
  height: 1.5rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 1.5rem;
}
.provider-profile-detail .provider-list-btn .checkbox-wrapper label {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
}
.provider-profile-detail .provider-name {
  font-size: 1.25rem;
  line-height: 1.5;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  color: #000;
  display: block;
  margin-bottom: 0.25rem;
}
.provider-profile-detail .medical-group {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 0.625rem;
}
.provider-profile-detail .medical-group.presbyterian {
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: #a71930;
}
.provider-profile-detail .new-patient {
  margin: 1rem 0;
}
.provider-profile-detail .telehealth-indicator {
  margin-top: -11px;
}
.provider-profile-detail .provider-website .icon-external {
  height: 0.5rem;
  margin-left: 0.25rem;
  stroke: #00759a;
  width: 0.5rem;
}
.provider-profile-detail .ratings {
  margin: 0.75rem 0 1.25rem;
}
.provider-profile-detail .new-patient-icon-container,
.provider-profile-detail .open-weekend-icon-container {
  align-items: center;
  display: flex;
  margin-bottom: 1.5rem;
}
.provider-profile-detail .new-patient-icon-container .icon,
.provider-profile-detail .open-weekend-icon-container .icon {
  margin-bottom: 1px;
  margin-right: 0.5rem;
}
.provider-profile-detail .open-weekend-icon-container {
  align-items: center;
  display: flex;
  margin-bottom: 1.5rem;
}
.provider-profile-detail .open-weekend-icon-container .icon {
  margin-bottom: 1px;
  margin-right: 0.5rem;
}
.provider-profile-detail .telehealth-indicator-icon-container {
  align-items: center;
  display: flex;
  margin-bottom: 1.5rem;
}
.provider-profile-detail .telehealth-indicator-icon-container .icon {
  margin-bottom: 1px;
  margin-right: 0.5rem;
}
.provider-profile-detail .telehealth-indicator-icon-container svg {
  height: 21px;
  width: 16px;
  margin-right: 7px;
}
.provider-profile-detail .virtual-care {
  margin-bottom: 0.25rem;
}
.provider-profile-detail .provider-profile-navigation {
  display: none;
}
.provider-profile-detail .accessibility-label {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 5px;
}
@media (min-width: 768px) {
  .provider-profile-detail {
    margin-bottom: 1.5rem;
  }
  .provider-profile-detail.simplified .provider-details,
.provider-profile-detail.simplified .location-info-container {
    flex: 0 1 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .provider-profile-detail.simplified .detail-top,
.provider-profile-detail.simplified .detail-bottom {
    flex: 0 1 100%;
    max-width: 100%;
    padding: 0 1rem;
  }
  .provider-profile-detail.simplified .location-info-container {
    border-left: 2px solid #fff;
  }
  .provider-profile-detail.simplified .detail-top {
    display: flex;
    flex-direction: column;
  }
  .provider-profile-detail .profile-inner {
    background-color: #f9f9f9;
    display: flex;
    position: relative;
    padding: 0;
    margin-bottom: 2px;
  }
  .provider-profile-detail .profile-image-outer {
    flex: 0 1 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .provider-profile-detail .profile-image-outer .provider-list-btn {
    display: none;
  }
  .provider-profile-detail .profile-image-container {
    padding-bottom: 100%;
  }
  .provider-profile-detail .provider-details {
    flex: 0 1 66.6666666667%;
    max-width: 66.6666666667%;
    padding: 1.5rem 0;
  }
  .provider-profile-detail .provider-details .provider-list-btn {
    display: block;
  }
  .provider-profile-detail .provider-list-btn {
    border-bottom: none;
    display: none;
    justify-content: flex-start;
    width: auto;
    margin-bottom: 2.5rem;
  }
  .provider-profile-detail .provider-details-inner {
    display: flex;
    height: 100%;
  }
  .provider-profile-detail .provider-name {
    font-size: 1.5rem;
    line-height: 1.3333333333;
    padding: 0 1rem;
  }
  .provider-profile-detail .profile-biography {
    margin-bottom: 0;
  }
  .provider-profile-detail .detail-top,
.provider-profile-detail .detail-bottom {
    flex: 0 1 50%;
    max-width: 50%;
    padding: 0 1rem;
  }
  .provider-profile-detail .detail-bottom {
    display: flex;
    justify-content: center;
  }
  .provider-profile-detail .detail-bottom-inner {
    display: flex;
    flex-direction: column;
  }
  .provider-profile-detail .new-patient-icon-container,
.provider-profile-detail .open-weekend-icon-container {
    display: flex;
    margin-bottom: 1rem;
    align-items: flex-start;
    line-height: 18px;
  }
  .provider-profile-detail .open-weekend-icon-container {
    margin-bottom: 1rem;
    align-items: flex-start;
    line-height: 18px;
  }
  .provider-profile-detail .telehealth-indicator-icon-container {
    margin-bottom: 1rem;
    margin-top: 1rem;
    align-items: flex-start;
    line-height: 18px;
  }
  .provider-profile-detail .virtual-care,
.provider-profile-detail .message-link {
    margin-bottom: 1rem;
    margin-left: 1.5rem;
  }
  .provider-profile-detail .provider-profile-navigation {
    align-items: stretch;
    display: flex;
    justify-content: space-evenly;
  }
  .provider-profile-detail .provider-profile-navigation a {
    font-size: 1rem;
    line-height: 1.375;
    font-family: AvenirRoman, Helvetica, sans-serif;
    font-weight: normal;
    align-items: center;
    background-color: #eee;
    border-right: 2px solid #fff;
    color: #363534;
    display: flex;
    height: 50px;
    flex: 1;
    justify-content: center;
    position: relative;
    text-decoration: none;
    transition: background-color 0.4s ease, color 0.4s ease;
    z-index: 1;
  }
  .provider-profile-detail .provider-profile-navigation a:last-child {
    border-right: none;
  }
  .provider-profile-detail .provider-profile-navigation a:focus, .provider-profile-detail .provider-profile-navigation a:hover {
    color: #000;
    background-color: #bfbfbf;
  }
}
@media print and (min-width: 6px) {
  .provider-profile-detail {
    margin-bottom: 1.5rem;
  }
  .provider-profile-detail.simplified .provider-details,
.provider-profile-detail.simplified .location-info-container {
    flex: 0 1 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .provider-profile-detail.simplified .detail-top,
.provider-profile-detail.simplified .detail-bottom {
    flex: 0 1 100%;
    max-width: 100%;
    padding: 0 1rem;
  }
  .provider-profile-detail.simplified .location-info-container {
    border-left: 2px solid #fff;
  }
  .provider-profile-detail.simplified .detail-top {
    display: flex;
    flex-direction: column;
  }
  .provider-profile-detail .profile-inner {
    background-color: #f9f9f9;
    display: flex;
    position: relative;
    padding: 0;
    margin-bottom: 2px;
  }
  .provider-profile-detail .profile-image-outer {
    flex: 0 1 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .provider-profile-detail .profile-image-outer .provider-list-btn {
    display: none;
  }
  .provider-profile-detail .profile-image-container {
    padding-bottom: 100%;
  }
  .provider-profile-detail .provider-details {
    flex: 0 1 66.6666666667%;
    max-width: 66.6666666667%;
    padding: 1.5rem 0;
  }
  .provider-profile-detail .provider-details .provider-list-btn {
    display: block;
  }
  .provider-profile-detail .provider-list-btn {
    border-bottom: none;
    display: none;
    justify-content: flex-start;
    width: auto;
    margin-bottom: 2.5rem;
  }
  .provider-profile-detail .provider-details-inner {
    display: flex;
    height: 100%;
  }
  .provider-profile-detail .provider-name {
    font-size: 1.5rem;
    line-height: 1.3333333333;
    padding: 0 1rem;
  }
  .provider-profile-detail .profile-biography {
    margin-bottom: 0;
  }
  .provider-profile-detail .detail-top,
.provider-profile-detail .detail-bottom {
    flex: 0 1 50%;
    max-width: 50%;
    padding: 0 1rem;
  }
  .provider-profile-detail .detail-bottom {
    display: flex;
    justify-content: center;
  }
  .provider-profile-detail .detail-bottom-inner {
    display: flex;
    flex-direction: column;
  }
  .provider-profile-detail .new-patient-icon-container,
.provider-profile-detail .open-weekend-icon-container {
    display: flex;
    margin-bottom: 1rem;
    align-items: flex-start;
    line-height: 18px;
  }
  .provider-profile-detail .open-weekend-icon-container {
    margin-bottom: 1rem;
    align-items: flex-start;
    line-height: 18px;
  }
  .provider-profile-detail .telehealth-indicator-icon-container {
    margin-bottom: 1rem;
    margin-top: 1rem;
    align-items: flex-start;
    line-height: 18px;
  }
  .provider-profile-detail .virtual-care,
.provider-profile-detail .message-link {
    margin-bottom: 1rem;
    margin-left: 1.5rem;
  }
  .provider-profile-detail .provider-profile-navigation {
    align-items: stretch;
    display: flex;
    justify-content: space-evenly;
  }
  .provider-profile-detail .provider-profile-navigation a {
    font-size: 1rem;
    line-height: 1.375;
    font-family: AvenirRoman, Helvetica, sans-serif;
    font-weight: normal;
    align-items: center;
    background-color: #eee;
    border-right: 2px solid #fff;
    color: #363534;
    display: flex;
    height: 50px;
    flex: 1;
    justify-content: center;
    position: relative;
    text-decoration: none;
    transition: background-color 0.4s ease, color 0.4s ease;
    z-index: 1;
  }
  .provider-profile-detail .provider-profile-navigation a:last-child {
    border-right: none;
  }
  .provider-profile-detail .provider-profile-navigation a:focus, .provider-profile-detail .provider-profile-navigation a:hover {
    color: #000;
    background-color: #bfbfbf;
  }
}
@media (min-width: 1280px) {
  .provider-profile-detail {
    margin-bottom: 1.5rem;
  }
  .provider-profile-detail.simplified .provider-details,
.provider-profile-detail.simplified .new-patient-icon-container,
.provider-profile-detail.simplified .open-weekend-icon-container {
    margin-bottom: 1rem;
  }
}
@media print and (min-width: 7px) {
  .provider-profile-detail {
    margin-bottom: 1.5rem;
  }
  .provider-profile-detail.simplified .provider-details,
.provider-profile-detail.simplified .new-patient-icon-container,
.provider-profile-detail.simplified .open-weekend-icon-container {
    margin-bottom: 1rem;
  }
}

.pls-profile-section {
  position: relative;
}
.pls-profile-section:not(:first-child) {
  margin-top: 2px;
}
.pls-profile-section:before {
  content: "";
  display: block;
  height: 60px;
  margin-top: -60px;
  pointer-events: none;
  visibility: hidden;
}
.pls-profile-section .accordion {
  position: relative;
}
.pls-profile-section .accordion .accordion-item {
  border-top: 0;
  border-bottom: 0;
}
.pls-profile-section .accordion .accordion-item:last-of-type {
  border-bottom: 0;
}
.pls-profile-section .accordion .accordion-item-toggle {
  display: none;
  background-color: #eee;
  padding: 24px 0 23px;
  position: relative;
  z-index: 1;
}
.pls-profile-section .accordion .accordion-item-toggle[aria-expanded=true] .icon-arrow-down {
  transform: rotate(180deg);
}
.pls-profile-section .accordion .accordion-item-toggle.show-on-mobile {
  display: block;
}
.pls-profile-section .accordion .toggle-container {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
}
.pls-profile-section .accordion .accordion-item-body {
  margin: 0;
}
.pls-profile-section .modal-open-btn {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  align-items: center;
  background-color: #eee;
  display: flex;
  justify-content: space-between;
  padding: 26px 30px;
  position: relative;
  z-index: 1;
}
.pls-profile-section .modal-open-btn .icon-arrow-down {
  transform: rotate(-90deg);
}
.pls-profile-section .modal-header, .pls-profile-section .echeckin-modal-header {
  background-color: #eee;
  height: 70px;
}
.pls-profile-section .modal-header .heading-3, .pls-profile-section .echeckin-modal-header .heading-3 {
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
}
.pls-profile-section .modal-body {
  background-color: #fff;
}
.pls-profile-section .profile-title {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.3333333333;
  margin-bottom: 0.25rem;
}
.pls-profile-section .provider-profile-list {
  margin-bottom: 1.5rem;
}
.pls-profile-section .provider-profile-list li {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #363534;
  margin-bottom: 0.25rem;
}
.pls-profile-section .provider-profile-list li a {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #00759a;
}
.pls-profile-section .input-icon-container {
  position: relative;
  margin-bottom: 2rem;
}
.pls-profile-section .input-icon-container input {
  font-size: 1rem;
  line-height: 1.375;
  background-color: #fff;
  border: 2px solid #bfbfbf;
  height: 60px;
  margin-bottom: 0;
  padding: 1.125rem 1.25rem;
  text-align: left;
  width: 100%;
}
.pls-profile-section .input-icon-container input::placeholder {
  color: #999;
  text-align: left;
}
.pls-profile-section .input-icon-container .icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
}
.pls-profile-section .modal.visible .accordion-item-body,
.pls-profile-section .modal.open .accordion-item-body {
  display: block;
  padding: 0;
}
@media (min-width: 768px) {
  .pls-profile-section .accordion .accordion-item:last-of-type {
    border-bottom: 2px solid #bfbfbf;
  }
  .pls-profile-section .accordion .accordion-item-toggle {
    display: block;
    background-color: transparent;
    padding: 30px 0;
  }
  .pls-profile-section .accordion .toggle-container {
    font-size: 1.25rem;
    line-height: 1.2;
    font-family: AvenirMedium, Helvetica, sans-serif;
    font-weight: normal;
    padding: 0 20px 0 0;
  }
  .pls-profile-section .accordion .toggle-container .icon {
    fill: #363534;
    height: 1.5rem;
    width: 1.5rem;
  }
  .pls-profile-section .accordion .accordion-item-body {
    padding: 0 0 30px;
  }
  .pls-profile-section .input-icon-container input {
    font-size: 0.875rem;
    line-height: 1.4285714286;
    padding: 1.25rem;
  }
  .pls-profile-section .modal {
    background-color: transparent;
    display: block;
    height: auto;
    opacity: 1;
    overflow: hidden;
    position: relative;
    z-index: 0;
  }
  .pls-profile-section .modal-container {
    max-height: none;
    padding: 0;
  }
  .pls-profile-section .modal-header, .pls-profile-section .echeckin-modal-header {
    display: none;
  }
  .pls-profile-section .modal-inner {
    padding: 0;
  }
  .pls-profile-section .modal-body {
    background-color: transparent;
    overflow: hidden;
  }
  .pls-profile-section .modal-open-btn {
    display: none;
  }
}
@media print and (min-width: 6px) {
  .pls-profile-section .accordion .accordion-item:last-of-type {
    border-bottom: 2px solid #bfbfbf;
  }
  .pls-profile-section .accordion .accordion-item-toggle {
    display: block;
    background-color: transparent;
    padding: 30px 0;
  }
  .pls-profile-section .accordion .toggle-container {
    font-size: 1.25rem;
    line-height: 1.2;
    font-family: AvenirMedium, Helvetica, sans-serif;
    font-weight: normal;
    padding: 0 20px 0 0;
  }
  .pls-profile-section .accordion .toggle-container .icon {
    fill: #363534;
    height: 1.5rem;
    width: 1.5rem;
  }
  .pls-profile-section .accordion .accordion-item-body {
    padding: 0 0 30px;
  }
  .pls-profile-section .input-icon-container input {
    font-size: 0.875rem;
    line-height: 1.4285714286;
    padding: 1.25rem;
  }
  .pls-profile-section .modal {
    background-color: transparent;
    display: block;
    height: auto;
    opacity: 1;
    overflow: hidden;
    position: relative;
    z-index: 0;
  }
  .pls-profile-section .modal-container {
    max-height: none;
    padding: 0;
  }
  .pls-profile-section .modal-header, .pls-profile-section .echeckin-modal-header {
    display: none;
  }
  .pls-profile-section .modal-inner {
    padding: 0;
  }
  .pls-profile-section .modal-body {
    background-color: transparent;
    overflow: hidden;
  }
  .pls-profile-section .modal-open-btn {
    display: none;
  }
}
@media (min-width: 1280px) {
  .pls-profile-section:before {
    height: 40px;
    margin-top: -40px;
  }
}
@media print and (min-width: 7px) {
  .pls-profile-section:before {
    height: 40px;
    margin-top: -40px;
  }
}

.pls-profile-insurance {
  margin-bottom: 3rem;
}
.pls-profile-insurance .insurance-container {
  color: #363534;
}
.pls-profile-insurance .insurance-title {
  font-size: 1.5rem;
  line-height: 1.3333333333;
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 10px;
}
.pls-profile-insurance .location-search-input {
  margin-top: 18px;
}
.pls-profile-insurance .insurance-inner.error .error-message {
  background-color: #ffefef;
  display: block;
  margin-bottom: 1.5rem;
  padding: 1rem;
}
.pls-profile-insurance .insurance-inner.error .error-message :last-child {
  margin-bottom: 0;
}
.pls-profile-insurance .insurance-inner.error .insurance-message {
  display: none;
}
.pls-profile-insurance .search-message {
  display: block;
}
.pls-profile-insurance .error-message {
  display: none;
}
.pls-profile-insurance .insurance-message p,
.pls-profile-insurance .error-message p {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
}
.pls-profile-insurance .insurance-message p a,
.pls-profile-insurance .error-message p a {
  color: #00759a;
  display: inline;
}
.pls-profile-insurance .highlight {
  background-color: #ffff99;
}
.pls-profile-insurance ul {
  padding-left: 16px;
}
.pls-profile-insurance .insurance-list li {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 0.25rem;
}
.pls-profile-insurance .insurance-plans .profile-title {
  margin: 30px 0 0.25rem;
}
.pls-profile-insurance .insurance-plans .profile-title.new-patients {
  margin-top: 6px;
}
@media (min-width: 768px) {
  .pls-profile-insurance .insurance-inner {
    display: flex;
  }
  .pls-profile-insurance .insurance-search-input {
    flex: 0 1 33.3333333333%;
    margin-top: 0;
    max-width: 33.3333333333%;
    padding-right: 2rem;
  }
  .pls-profile-insurance .insurance-list {
    flex: 0 1 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .pls-profile-insurance .insurance-list ul {
    columns: 2;
  }
}
@media print and (min-width: 6px) {
  .pls-profile-insurance .insurance-inner {
    display: flex;
  }
  .pls-profile-insurance .insurance-search-input {
    flex: 0 1 33.3333333333%;
    margin-top: 0;
    max-width: 33.3333333333%;
    padding-right: 2rem;
  }
  .pls-profile-insurance .insurance-list {
    flex: 0 1 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .pls-profile-insurance .insurance-list ul {
    columns: 2;
  }
}

.pls-footer {
  background-color: #363534;
  position: relative;
}
.pls-footer.fixed .pls-footer-navigation {
  bottom: 0;
  position: fixed;
  top: auto;
  margin-bottom: 0;
  transition: margin-bottom 0.3s ease-in-out;
}
.mobile-nav-open .pls-footer.fixed .pls-footer-navigation {
  margin-bottom: -100vh;
}
.pls-footer .pls-footer-navigation {
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  background-color: #000;
  display: flex;
  width: 100%;
}
.pls-footer .pls-footer-navigation .feedback-btn {
  color: #bfbfbf;
  display: block;
  padding: 1rem 0;
  text-align: left;
  width: 100%;
}
.pls-footer .pls-footer-navigation .feedback-btn span {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  align-items: center;
  display: flex;
}
@media (min-width: 768px) {
  .pls-footer .pls-footer-navigation .feedback-btn span {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .pls-footer .pls-footer-navigation .feedback-btn span {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .pls-footer .pls-footer-navigation .feedback-btn span {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .pls-footer .pls-footer-navigation .feedback-btn span {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .pls-footer .pls-footer-navigation .feedback-btn span {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .pls-footer .pls-footer-navigation .feedback-btn span {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.pls-footer .pls-footer-navigation .feedback-btn:hover, .pls-footer .pls-footer-navigation .feedback-btn:focus {
  background-color: #bfbfbf;
  color: #000;
}
@media (min-width: 768px) {
  .pls-footer {
    min-height: 160px;
  }
}
@media print and (min-width: 6px) {
  .pls-footer {
    min-height: 160px;
  }
}

/* Location Profile */
.location-facility-info .facility-data-value {
  font-size: 14px;
  font-weight: 400;
}
.location-facility-info .facility-data-wrapper {
  columns: 4 313px;
  margin: 30px 0 0;
}
.location-facility-info .facility-data-item {
  break-inside: avoid;
  margin-bottom: 25px;
}
.location-facility-info .facility-data-item ul {
  list-style-type: disc;
}
.location-facility-info .facility-data-item .facility-data-list {
  list-style: none;
}
.location-facility-info .facility-data-value {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
}
.location-facility-info .facility-data-link {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 0.3rem;
}
.location-facility-info .facility-data-link a {
  color: #00759a;
}
@media (min-width: 768px) {
  .location-facility-info .facility-data-wrapper {
    margin: 5px 0 0;
  }
}
@media print and (min-width: 6px) {
  .location-facility-info .facility-data-wrapper {
    margin: 5px 0 0;
  }
}

.location-overview {
  margin-bottom: 4rem;
}
.location-overview .accordion .title {
  font-size: 1.5rem;
  line-height: 1.3333333333;
  margin-bottom: 10px;
}
.location-overview .accordion .accordion-item {
  border-top: 1px solid #fff;
}
.location-overview .accordion .accordion-item:last-of-type {
  border-bottom: 0;
}
.location-overview .accordion .accordion-item-toggle {
  background-color: #f9f9f9;
  padding: 25px 0 22px;
}
.location-overview .tabs {
  display: none;
}
@media (min-width: 768px) {
  .location-overview {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
    margin-top: 4rem;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .location-overview {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 768px) and (min-width: 6px) {
  .location-overview {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 768px) and (min-width: 1280px) {
  .location-overview {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 768px) and (min-width: 7px) {
  .location-overview {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 768px) and (min-width: 1440px) {
  .location-overview {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 768px) and (min-width: 1440px) {
  .location-overview {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 768px) {
  .location-overview .accordion {
    display: none;
  }
}
@media (min-width: 768px) {
  .location-overview .tabs {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
    display: flex;
    flex-direction: row-reverse;
    margin: 0;
  }
  .location-overview .tabs .title {
    font-size: 1.5rem;
    line-height: 1.3333333333;
    margin-bottom: 10px;
  }
  .location-overview .tabs .tabs-header {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 10px;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
    background-color: transparent;
    margin-top: 0;
    padding: 0 0 0 20px;
  }
  .location-overview .tabs .tab-panel {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 10px;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
    padding: 0;
  }
  .location-overview .tabs .tab-list {
    background-color: #fafafa;
    display: block;
    padding: 0;
  }
  .location-overview .tabs .tab-btn {
    background-color: #eeeeee;
    display: block;
    margin-bottom: 1px;
    padding: 28px 20px 26px 50px;
    text-align: left;
    width: 100%;
  }
  .location-overview .tabs .tab-btn.active {
    background-color: #f9f9f9;
    border-top: 0;
    margin-top: 0;
  }
  .location-overview .tabs .tab-btn.active:focus {
    background-color: #f9f9f9;
  }
  .location-overview .tabs .tab-content {
    padding: 0;
    padding-right: 40px;
  }
  .location-overview .tabs .tab-content .content-container {
    max-width: 100%;
  }
}
@media print and (min-width: 6px) {
  .location-overview {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
    margin-top: 4rem;
  }
}
@media print and (min-width: 6px) and (min-width: 768px) {
  .location-overview {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) and (min-width: 6px) {
  .location-overview {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) and (min-width: 1280px) {
  .location-overview {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 6px) and (min-width: 7px) {
  .location-overview {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 6px) and (min-width: 1440px) {
  .location-overview {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 6px) and (min-width: 1440px) {
  .location-overview {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 6px) {
  .location-overview .accordion {
    display: none;
  }
}
@media print and (min-width: 6px) {
  .location-overview .tabs {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
    display: flex;
    flex-direction: row-reverse;
    margin: 0;
  }
  .location-overview .tabs .title {
    font-size: 1.5rem;
    line-height: 1.3333333333;
    margin-bottom: 10px;
  }
  .location-overview .tabs .tabs-header {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 10px;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
    background-color: transparent;
    margin-top: 0;
    padding: 0 0 0 20px;
  }
  .location-overview .tabs .tab-panel {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 10px;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
    padding: 0;
  }
  .location-overview .tabs .tab-list {
    background-color: #fafafa;
    display: block;
    padding: 0;
  }
  .location-overview .tabs .tab-btn {
    background-color: #eeeeee;
    display: block;
    margin-bottom: 1px;
    padding: 28px 20px 26px 50px;
    text-align: left;
    width: 100%;
  }
  .location-overview .tabs .tab-btn.active {
    background-color: #f9f9f9;
    border-top: 0;
    margin-top: 0;
  }
  .location-overview .tabs .tab-btn.active:focus {
    background-color: #f9f9f9;
  }
  .location-overview .tabs .tab-content {
    padding: 0;
    padding-right: 40px;
  }
  .location-overview .tabs .tab-content .content-container {
    max-width: 100%;
  }
}

.location-profile-detail {
  padding: 0;
  margin-bottom: 1.2rem;
}
.location-profile-detail address {
  font: inherit;
}
.location-profile-detail address p {
  font: inherit;
  margin-bottom: 0px;
}
.location-profile-detail .icon-transport {
  fill: black;
  width: 2rem;
  height: 2rem;
}
.location-profile-detail .transport-info-wrapper {
  display: flex;
  gap: 3px;
  margin-top: 10px;
}
.location-profile-detail .transport-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.location-profile-detail .transport-item-container p {
  font-size: 12px;
}
.location-profile-detail .location-profile-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0;
}
.location-profile-detail .location-profile-heading {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  color: #a71930;
  line-height: 18px;
  margin-bottom: 0;
  padding: 25px 30px;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
}
.location-profile-detail .location-image-heading {
  margin-left: auto;
}
.location-profile-detail .location-image-heading img {
  height: 75px;
  width: auto;
}
.location-profile-detail .open-weekend-icon-container {
  display: flex;
  font-size: 0.875rem;
}
.location-profile-detail .open-weekend-icon-container p {
  font: inherit;
}
.location-profile-detail .location-detail {
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #363534;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
}
.location-profile-detail .location-detail > div {
  background: #fff;
  min-height: auto;
  width: 100%;
}
.location-profile-detail .location-detail-info {
  order: 2;
  padding: 0px;
}
.location-profile-detail .location-detail-info .info-inner {
  padding: 16px 30px 0;
}
.location-profile-detail .location-detail-info .location-type {
  margin-bottom: 10px;
}
.location-profile-detail .location-detail-info .location-name {
  color: #000;
  font-family: "AvenirHeavy", Helvetica, sans-serif;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 10px;
}
.location-profile-detail .location-detail-info .location-specs {
  margin-bottom: 1rem;
  min-height: 40px;
}
.location-profile-detail .location-detail-info .scheduling-btn {
  margin-bottom: 1.25rem;
}
.location-profile-detail .location-address {
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.2857142857;
  margin-bottom: 25px;
}
.location-profile-detail .location-address .location-miles {
  color: #006dac;
  text-decoration: none;
}
.location-profile-detail .location-address .location-miles:hover, .location-profile-detail .location-address .location-miles:focus {
  text-decoration: underline;
}
.location-profile-detail .location-address address {
  font: inherit;
}
.location-profile-detail .location-office-hours .office-title {
  font-family: "AvenirHeavy", Helvetica, sans-serif;
  margin-bottom: 5px;
}
.location-profile-detail .location-office-hours .office-hours-list li {
  line-height: 18px;
}
.location-profile-detail .location-office-hours .office-hours-list li span {
  display: inline-block;
  margin-right: 3px;
  width: 37px;
}
.location-profile-detail .location-detail-contact {
  order: 3;
  padding: 0px;
}
.location-profile-detail .location-detail-contact img {
  display: none;
  width: 100%;
}
.location-profile-detail .location-contact {
  padding: 0 30px;
  margin: 20px 0 10px;
  position: relative;
  z-index: 2;
}
.location-profile-detail .location-contact .contact-title {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
}
.location-profile-detail .location-contact .contact-phone a {
  color: #006dac;
  text-decoration: none;
}
.location-profile-detail .location-contact .contact-phone a:hover, .location-profile-detail .location-contact .contact-phone a:focus {
  text-decoration: underline;
}
.location-profile-detail .location-contact .contact-fax {
  margin-bottom: 20px;
}
.location-profile-detail .location-contact .contact-fax a {
  text-decoration: none;
}
.location-profile-detail .location-contact .contact-fax a:hover, .location-profile-detail .location-contact .contact-fax a:focus {
  text-decoration: underline;
}
.location-profile-detail .location-contact .contact-email a {
  color: #006dac;
  text-decoration: none;
}
.location-profile-detail .location-contact .contact-email a:hover, .location-profile-detail .location-contact .contact-email a:focus {
  text-decoration: underline;
}
.location-profile-detail .location-contact .contact-website {
  padding-top: 0.8rem;
  padding-bottom: 2rem;
}
.location-profile-detail .location-contact .contact-website a {
  color: #006dac;
  text-decoration: none;
}
.location-profile-detail .location-contact .contact-website a:hover, .location-profile-detail .location-contact .contact-website a:focus {
  text-decoration: underline;
}
.location-profile-detail .location-contact .contact-website p {
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.4285714286;
  margin-bottom: 0;
}
.location-profile-detail .location-contact .contact-link a {
  color: #006dac;
  text-decoration: none;
}
.location-profile-detail .location-contact .contact-link a:hover, .location-profile-detail .location-contact .contact-link a:focus {
  text-decoration: underline;
}
.location-profile-detail .location-hours-contact-info .location-contact {
  padding: 0;
}
.location-profile-detail .location-info-map {
  min-height: 240px;
  order: 1;
  padding: 0px;
  aspect-ratio: 3/2;
}
.location-profile-detail .location-info-map .location-map-image {
  height: 100%;
  position: relative;
}
.location-profile-detail .location-info-map .location-map-image img {
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
@media (min-width: 768px) {
  .location-profile-detail .location-profile-heading {
    font-size: 1.875rem;
    line-height: 1;
    padding: 40px 0px;
  }
  .location-profile-detail .location-detail {
    flex-wrap: nowrap;
    margin: 0 -10px;
  }
  .location-profile-detail .location-detail-info {
    order: 1;
    padding: 0px 10px;
  }
  .location-profile-detail .location-detail-info .info-inner {
    background: #eee;
    height: 100%;
  }
  .location-profile-detail .location-detail-info.col-md-4 .info-inner {
    padding: 16px 20px 20px;
  }
  .location-profile-detail .location-detail-info .scheduling-btn {
    margin-bottom: 1rem;
  }
  .location-profile-detail .location-detail-contact {
    order: 2;
    padding: 0px 10px;
  }
  .location-profile-detail .location-detail-contact img {
    display: block;
    height: 100%;
  }
  .location-profile-detail .location-detail-contact .contact-inner {
    background: #eee;
    height: 100%;
  }
  .location-profile-detail .location-detail-contact .location-contact {
    padding: 0 20px;
  }
  .location-profile-detail .location-hours-contact-info {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-between;
  }
  .location-profile-detail .location-hours-contact-info .location-contact {
    margin: 0;
  }
  .location-profile-detail .location-hours-contact-info .location-contact .contact-title {
    margin-bottom: 5px;
  }
  .location-profile-detail .location-info-map {
    order: 3;
    padding: 0 10px;
    aspect-ratio: unset;
  }
  .location-profile-detail .location-image-heading {
    display: none;
  }
}
@media print and (min-width: 6px) {
  .location-profile-detail .location-profile-heading {
    font-size: 1.875rem;
    line-height: 1;
    padding: 40px 0px;
  }
  .location-profile-detail .location-detail {
    flex-wrap: nowrap;
    margin: 0 -10px;
  }
  .location-profile-detail .location-detail-info {
    order: 1;
    padding: 0px 10px;
  }
  .location-profile-detail .location-detail-info .info-inner {
    background: #eee;
    height: 100%;
  }
  .location-profile-detail .location-detail-info.col-md-4 .info-inner {
    padding: 16px 20px 20px;
  }
  .location-profile-detail .location-detail-info .scheduling-btn {
    margin-bottom: 1rem;
  }
  .location-profile-detail .location-detail-contact {
    order: 2;
    padding: 0px 10px;
  }
  .location-profile-detail .location-detail-contact img {
    display: block;
    height: 100%;
  }
  .location-profile-detail .location-detail-contact .contact-inner {
    background: #eee;
    height: 100%;
  }
  .location-profile-detail .location-detail-contact .location-contact {
    padding: 0 20px;
  }
  .location-profile-detail .location-hours-contact-info {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-between;
  }
  .location-profile-detail .location-hours-contact-info .location-contact {
    margin: 0;
  }
  .location-profile-detail .location-hours-contact-info .location-contact .contact-title {
    margin-bottom: 5px;
  }
  .location-profile-detail .location-info-map {
    order: 3;
    padding: 0 10px;
    aspect-ratio: unset;
  }
  .location-profile-detail .location-image-heading {
    display: none;
  }
}

.location-scheduling .location-scheduling-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
}
@media (min-width: 768px) {
  .location-scheduling .location-scheduling-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .location-scheduling .location-scheduling-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .location-scheduling .location-scheduling-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .location-scheduling .location-scheduling-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .location-scheduling .location-scheduling-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .location-scheduling .location-scheduling-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.location-scheduling .schedule-info-wrapper {
  margin-bottom: 3rem;
  padding-top: 3rem;
  position: relative;
}
.location-scheduling .schedule-info-container {
  padding-left: 0;
  padding-right: 0;
}
.location-scheduling .appointment-types {
  padding-top: 1rem;
  position: relative;
}
.location-scheduling .appointment-type-description a {
  display: block;
}
@media (min-width: 768px) {
  .location-scheduling .location-scheduling-container {
    display: flex;
    flex-wrap: nowrap;
  }
  .location-scheduling .location-about {
    flex: 0 1 33.3333333333%;
    padding: 20px;
  }
  .location-scheduling .location-about .title {
    font-size: 1.25rem;
    line-height: 1.2;
    font-family: AvenirMedium, Helvetica, sans-serif;
    font-weight: normal;
  }
  .location-scheduling .location-about p {
    font-size: 0.875rem;
    line-height: 1.4285714286;
  }
  .location-scheduling .schedule-info-wrapper {
    align-items: center;
    display: flex;
    flex: 0 1 66.6666666667%;
    flex-direction: column;
    padding-top: 4rem;
  }
  .location-scheduling .schedule-info-container {
    flex: 0 1 100%;
    max-width: none;
    width: 100%;
  }
}
@media print and (min-width: 6px) {
  .location-scheduling .location-scheduling-container {
    display: flex;
    flex-wrap: nowrap;
  }
  .location-scheduling .location-about {
    flex: 0 1 33.3333333333%;
    padding: 20px;
  }
  .location-scheduling .location-about .title {
    font-size: 1.25rem;
    line-height: 1.2;
    font-family: AvenirMedium, Helvetica, sans-serif;
    font-weight: normal;
  }
  .location-scheduling .location-about p {
    font-size: 0.875rem;
    line-height: 1.4285714286;
  }
  .location-scheduling .schedule-info-wrapper {
    align-items: center;
    display: flex;
    flex: 0 1 66.6666666667%;
    flex-direction: column;
    padding-top: 4rem;
  }
  .location-scheduling .schedule-info-container {
    flex: 0 1 100%;
    max-width: none;
    width: 100%;
  }
}

.related-providers {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  margin-bottom: 4rem;
}
@media (min-width: 768px) {
  .related-providers {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .related-providers {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .related-providers {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .related-providers {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .related-providers {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .related-providers {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.related-providers .related-providers-info {
  margin-bottom: 2rem;
  padding: 0;
  width: 100%;
}
.related-providers .title {
  font-size: 1.5rem;
  line-height: 1.3333333333;
  margin-bottom: 10px;
}
.related-providers .related-provider-inner {
  margin-bottom: 20px;
}
.related-providers .profile-image-outer {
  max-width: 100%;
}
.related-providers .profile-image-container {
  display: block;
  height: 0;
  overflow: hidden;
  padding-bottom: 66%;
  position: relative;
  width: 100%;
}
.related-providers a.profile-image:focus-visible {
  border: #222 2px solid;
}
.related-providers .profile-image {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.related-providers .profile-image img {
  font-family: "object-fit: cover; object-position: center 25%;";
  height: 100%;
  object-fit: cover;
  object-position: center 25%;
  width: 100%;
}
.related-providers .provider-details {
  padding: 16px 0px 13px;
}
.related-providers .provider-details .provider-name {
  display: block;
  margin-bottom: 6px;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
}
.related-providers .provider-details .related-specialty {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
  margin-bottom: 10px;
}
.related-providers .provider-details .ratings {
  margin-bottom: 12px;
}
.related-providers .provider-details .ratings .rating-stars {
  width: 70px;
}
.related-providers .provider-details .ratings .rating-stars .star,
.related-providers .provider-details .ratings .rating-stars .star svg {
  height: 12px;
  width: 12px;
}
.related-providers .provider-details .ratings .average-rating {
  font-size: 0.75rem;
  line-height: 1;
}
.related-providers .provider-details .ratings .rating-count {
  font-size: 0.75rem;
  line-height: 1;
  color: #595756;
}
.related-providers .provider-details .new-patient svg {
  width: 13px;
}
.related-providers .provider-details .new-patient .new-patient-text {
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.5;
  color: #363534;
  vertical-align: top;
}
.related-providers .related-providers-link {
  border-bottom: 1px solid #bfbfbf;
  padding-bottom: 30px;
}
.related-providers .related-providers-link .view-all {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: #006dac;
  text-decoration: none;
}
.related-providers .related-providers-link .view-all:hover, .related-providers .related-providers-link .view-all:focus {
  text-decoration: underline;
}
@media (min-width: 768px) {
  .related-providers .related-providers-grid {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 30px;
  }
  .related-providers .provider-details {
    padding: 16px 20px 13px;
  }
  .related-providers .related-provider-inner {
    background-color: #f9f9f9;
    display: flex;
    width: 50%;
  }
  .related-providers .profile-image-outer {
    width: 200px;
  }
  .related-providers .profile-image-container {
    padding-bottom: 100%;
  }
  .related-providers .related-providers-link {
    padding-right: 40px;
  }
}
@media print and (min-width: 6px) {
  .related-providers .related-providers-grid {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 30px;
  }
  .related-providers .provider-details {
    padding: 16px 20px 13px;
  }
  .related-providers .related-provider-inner {
    background-color: #f9f9f9;
    display: flex;
    width: 50%;
  }
  .related-providers .profile-image-outer {
    width: 200px;
  }
  .related-providers .profile-image-container {
    padding-bottom: 100%;
  }
  .related-providers .related-providers-link {
    padding-right: 40px;
  }
}

.related-specialties {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
  margin-bottom: 4rem;
}
@media (min-width: 768px) {
  .related-specialties {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .related-specialties {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .related-specialties {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .related-specialties {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .related-specialties {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .related-specialties {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.related-specialties .related-specialties-info {
  margin: 0px auto 30px 0;
  padding: 0;
  width: 100%;
}
.related-specialties .title {
  font-size: 1.5rem;
  line-height: 1.3333333333;
  margin-bottom: 10px;
}
.related-specialties ul {
  list-style: none;
  padding: 0;
}
.related-specialties ul li {
  font-size: 0.875rem;
  line-height: 1.2857142857;
  padding-bottom: 10px;
}
.related-specialties ul li:last-child {
  margin-top: 30px;
}
.related-specialties .view-all {
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: #006dac;
  text-decoration: underline;
}
.related-specialties .view-all:hover, .related-specialties .view-all:focus {
  text-decoration: underline;
}
@media (min-width: 768px) {
  .related-specialties .related-specialties-info ul,
.related-specialties .related-specialties-list ul {
    columns: 3;
  }
  .related-specialties .related-specialties-info ul li,
.related-specialties .related-specialties-list ul li {
    max-width: 200px;
  }
  .related-specialties .related-specialties-info ul li:last-child,
.related-specialties .related-specialties-list ul li:last-child {
    margin-top: 0px;
  }
  .related-specialties .related-specialties-info .view-all,
.related-specialties .related-specialties-list .view-all {
    text-decoration: none;
  }
}
@media print and (min-width: 6px) {
  .related-specialties .related-specialties-info ul,
.related-specialties .related-specialties-list ul {
    columns: 3;
  }
  .related-specialties .related-specialties-info ul li,
.related-specialties .related-specialties-list ul li {
    max-width: 200px;
  }
  .related-specialties .related-specialties-info ul li:last-child,
.related-specialties .related-specialties-list ul li:last-child {
    margin-top: 0px;
  }
  .related-specialties .related-specialties-info .view-all,
.related-specialties .related-specialties-list .view-all {
    text-decoration: none;
  }
}

.parking {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  margin-bottom: 4rem;
}
@media (min-width: 768px) {
  .parking {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .parking {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .parking {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .parking {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .parking {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .parking {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.parking .title {
  font-size: 1.5rem;
  line-height: 1.3333333333;
  margin-bottom: 10px;
}
.parking img {
  display: none;
}
.parking .parking-info {
  align-items: flex-start;
  display: flex;
}
@media (min-width: 768px) {
  .parking img {
    display: block;
  }
  .parking .parking-info .parking-description {
    padding-left: 20px;
  }
}
@media print and (min-width: 6px) {
  .parking img {
    display: block;
  }
  .parking .parking-info .parking-description {
    padding-left: 20px;
  }
}

/* Technology Test */
.appointment-tips {
  margin: 0 auto;
  max-width: 1000px;
}
.appointment-tips hr {
  background-color: #bfbfbf;
  border: none;
  color: #bfbfbf;
  height: 2px;
  margin-bottom: 1.5rem;
}
@media (min-width: 768px) {
  .appointment-tips hr {
    margin: 1.2rem 0px;
  }
  .appointment-tips .grid-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  .appointment-tips .grid-col {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 10px;
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
    padding: 0 10px;
    height: 100%;
  }
}
@media print and (min-width: 6px) {
  .appointment-tips hr {
    margin: 1.2rem 0px;
  }
  .appointment-tips .grid-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  .appointment-tips .grid-col {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 10px;
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
    padding: 0 10px;
    height: 100%;
  }
}

.bandwidth-tester.warming-up .dot-pulse {
  display: flex;
}
.bandwidth-tester.warming-up .speedometer {
  display: none;
}
.bandwidth-tester.test-pass .test, .bandwidth-tester.test-warn .test, .bandwidth-tester.test-fail .test {
  display: none;
}
.bandwidth-tester.test-pass .pass {
  display: flex;
}
.bandwidth-tester.test-warn .warn {
  display: flex;
}
.bandwidth-tester.test-fail .fail {
  display: flex;
}
.bandwidth-tester.show-details .pass,
.bandwidth-tester.show-details .warn,
.bandwidth-tester.show-details .fail {
  display: none;
}
.bandwidth-tester.show-details .details {
  display: flex;
}
.bandwidth-tester.show-details .details .fail-details {
  display: none;
}
.bandwidth-tester.show-details.test-warn .fail-details {
  display: block;
}
.bandwidth-tester.show-details.test-fail .fail-details {
  display: block;
}
.bandwidth-tester section {
  text-align: center;
}
.bandwidth-tester section .title-secondary,
.bandwidth-tester section p,
.bandwidth-tester section .cta-link,
.bandwidth-tester section .section-title {
  text-align: center;
}
.bandwidth-tester section p {
  margin: 1rem auto;
  max-width: 550px;
}
.bandwidth-tester .pass,
.bandwidth-tester .warn,
.bandwidth-tester .fail,
.bandwidth-tester .details {
  display: none;
}
.bandwidth-tester .loader {
  margin-bottom: 2.5rem;
}
.bandwidth-tester .loader:after {
  border: 8px solid #00b0e6;
  border-bottom: 8px solid transparent;
  border-left: 8px solid transparent;
  content: " ";
  display: block;
  pointer-events: none;
  width: 138px;
  height: 138px;
  border-radius: 50%;
  animation: spin 1.4s infinite linear;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.bandwidth-tester .loader:after {
  animation: spin 10s infinite linear;
  margin: 0 auto;
}
.bandwidth-tester .dot-pulse {
  position: relative;
  display: flex;
  width: 22px;
  align-items: center;
  justify-content: space-between;
  display: none;
  margin: 0.625rem auto 1.5rem;
}
.bandwidth-tester .dot-pulse .dot, .bandwidth-tester .dot-pulse::before, .bandwidth-tester .dot-pulse::after {
  content: "";
  display: block;
  position: relative;
  top: 0;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}
.bandwidth-tester .dot-pulse::before {
  animation-delay: 0s;
}
.bandwidth-tester .dot-pulse::after {
  animation-delay: 0.5s;
}
@keyframes dotPulse {
  0% {
    transform: scale(0.5);
  }
  30% {
    transform: scale(1);
  }
  60%, 100% {
    transform: scale(0.5);
  }
}
.bandwidth-tester .status {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 0;
  text-transform: capitalize;
}
.bandwidth-tester .speedometer {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  color: #00b0e6;
  height: 24px;
  margin-bottom: 1rem;
}
.bandwidth-tester .icon-success,
.bandwidth-tester .icon-danger {
  height: 80px;
  margin-bottom: 1.75rem;
  width: 80px;
}
.bandwidth-tester .icon-caution {
  height: 88px;
  margin-bottom: 1.75rem;
  width: 102px;
}
.bandwidth-tester p {
  font-size: 1rem;
  line-height: 1.375;
  margin-bottom: 1rem;
}
.bandwidth-tester ul li {
  font-size: 0.875rem;
  line-height: 1.4285714286;
}
.bandwidth-tester .btn-group {
  justify-content: center;
  margin-bottom: 1.5rem;
}
.bandwidth-tester .btn-group .btn-primary {
  margin-bottom: 0;
}
.bandwidth-tester .btn-primary {
  margin-bottom: 1.5rem;
}
.bandwidth-tester .btn-show-details {
  margin: 0 auto;
}
.bandwidth-tester .details {
  background-color: #eee;
  padding-top: 4rem;
  position: relative;
}
.bandwidth-tester .details .btn-hide-details {
  padding: 0;
  position: absolute;
  right: 30px;
  top: 30px;
}
.bandwidth-tester .details .title-secondary {
  margin-bottom: 2rem;
}
.bandwidth-tester .details .bandwidth-details-description {
  text-align: center;
}
.bandwidth-tester .details .bandwidth-details-description,
.bandwidth-tester .details li {
  font-size: 1rem;
  line-height: 1.375;
  color: #363534;
}
.bandwidth-tester .bandwidth-details-list {
  display: inline-block;
  margin-bottom: 2.5rem;
}
.bandwidth-tester .bandwidth-details-list ul {
  margin-top: 0;
}
.bandwidth-tester .bandwidth-details-list ul li {
  padding-bottom: 0.5rem;
}
.bandwidth-tester .bandwidth-details-list .test-result.test-passed .pass {
  display: inline-flex;
}
.bandwidth-tester .bandwidth-details-list .test-result.test-passed .fail {
  display: none;
}
.bandwidth-tester .bandwidth-details-list .test-result .pass {
  display: none;
}
.bandwidth-tester .bandwidth-details-list .status-icon {
  display: inline-flex;
  margin-right: 0.25rem;
  width: 1rem;
}
.bandwidth-tester .bandwidth-details-list .icon-menu-close {
  height: 0.75rem;
  margin-left: 1px;
  stroke-width: 4px;
  width: 0.75rem;
}
.bandwidth-tester .bandwidth-details-list .label {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
}
.bandwidth-tester .inline-link {
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  display: inline;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .bandwidth-tester .btn-primary {
    width: auto;
  }
  .bandwidth-tester .list-separated li {
    text-align: center;
  }
  .bandwidth-tester .list-separated li:after {
    margin: 0.75rem auto;
  }
  .bandwidth-tester .list-separated li:last-child {
    margin-bottom: 0;
  }
}
@media print and (min-width: 6px) {
  .bandwidth-tester .btn-primary {
    width: auto;
  }
  .bandwidth-tester .list-separated li {
    text-align: center;
  }
  .bandwidth-tester .list-separated li:after {
    margin: 0.75rem auto;
  }
  .bandwidth-tester .list-separated li:last-child {
    margin-bottom: 0;
  }
}
@media (min-width: 1280px) {
  .bandwidth-tester section p {
    margin: 1rem auto;
    max-width: 550px;
  }
  .bandwidth-tester .btn-primary {
    width: auto;
  }
  .bandwidth-tester .details {
    text-align: left;
  }
  .bandwidth-tester .details p {
    text-align: left;
  }
  .bandwidth-tester .details .title-secondary,
.bandwidth-tester .details .section-title {
    text-align: left;
  }
  .bandwidth-tester .details .bandwidth-details-description,
.bandwidth-tester .details .cta-link,
.bandwidth-tester .details li {
    text-align: left;
    margin-right: 0;
    margin-left: 0;
  }
  .bandwidth-tester .bandwidth-details-list {
    text-align: left;
  }
  .bandwidth-tester .list-separated li {
    text-align: left;
  }
  .bandwidth-tester .list-separated li:after {
    margin: 0.75rem 0;
  }
}
@media print and (min-width: 7px) {
  .bandwidth-tester section p {
    margin: 1rem auto;
    max-width: 550px;
  }
  .bandwidth-tester .btn-primary {
    width: auto;
  }
  .bandwidth-tester .details {
    text-align: left;
  }
  .bandwidth-tester .details p {
    text-align: left;
  }
  .bandwidth-tester .details .title-secondary,
.bandwidth-tester .details .section-title {
    text-align: left;
  }
  .bandwidth-tester .details .bandwidth-details-description,
.bandwidth-tester .details .cta-link,
.bandwidth-tester .details li {
    text-align: left;
    margin-right: 0;
    margin-left: 0;
  }
  .bandwidth-tester .bandwidth-details-list {
    text-align: left;
  }
  .bandwidth-tester .list-separated li {
    text-align: left;
  }
  .bandwidth-tester .list-separated li:after {
    margin: 0.75rem 0;
  }
}

.content-slides [data-slide] {
  display: none;
}
.content-slides [data-slide].active {
  display: block;
}
.content-slides [data-slide].active.image-summary {
  display: flex;
}
.content-slides [data-slide].active.invisible {
  opacity: 0;
}
.content-slides [data-slide].animate .content-block .animate-in {
  animation: fade-in 0.4s;
  opacity: 1;
  animation-fill-mode: both;
}
.content-slides [data-slide].animate .content-block .animate-in + .animate-in {
  animation-delay: 0.15s;
}
.content-slides [data-slide].animate .content-block .animate-in + .animate-in + .animate-in {
  animation-delay: 0.3s;
}
.content-slides [data-slide].animate .content-block .animate-in + .animate-in + .animate-in + .animate-in {
  animation-delay: 0.45s;
}
@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateX(40px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.content-slides .prev,
.content-slides .next {
  align-items: center;
  border: 2px solid #fff;
  border-radius: 50%;
  display: flex;
  height: 30px;
  justify-content: center;
  padding-left: 2px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(100%, -50%);
  transition: border-color 0.15s ease-in-out;
  width: 30px;
}
.content-slides .prev:hover, .content-slides .prev:focus,
.content-slides .next:hover,
.content-slides .next:focus {
  border-color: #00b0e6;
  cursor: pointer;
}
.content-slides .prev.prev,
.content-slides .next.prev {
  left: 0;
  padding-left: 0;
  padding-right: 2px;
  right: auto;
  transform: translate(-100%, -50%);
}
.content-slides .prev.prev svg,
.content-slides .next.prev svg {
  transform: rotate(180deg);
}
.content-slides .slide-indicators {
  display: inline-flex;
}
.content-slides .slide-indicators .indicator {
  background-color: transparent;
  display: flex;
  height: 2px;
  width: 12px;
  margin-right: 0.5rem;
  padding: 0.5rem 0;
  position: relative;
}
.content-slides .slide-indicators .indicator:after {
  content: " ";
  display: block;
  background-color: #000;
  position: absolute;
  width: 100%;
  height: 2px;
  top: 50%;
  transform: translateY(-50%);
}
.content-slides .slide-indicators .indicator.active {
  width: 30px;
}
.content-slides .slide-indicators .indicator.active:after {
  background-color: #00b0e6;
}
.content-slides .slide-indicators .indicator:last-of-type {
  margin-right: 0;
}
.content-slides .slide-indicators .indicator:focus {
  outline: #00b0e6 solid 2px;
}
.using-mouse .content-slides .slide-indicators .indicator:focus {
  outline: none;
}
.content-slides .slide-indicators .indicator:hover, .content-slides .slide-indicators .indicator:focus {
  cursor: pointer;
}

.custom-error .title-primary {
  text-align: left;
}
.custom-error .custom-error-content p,
.custom-error .custom-error-content span,
.custom-error .custom-error-content li {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  color: #363534;
  text-align: left;
}
.custom-error .custom-error-content .list-title {
  margin-bottom: 0.5rem;
}
.custom-error .custom-error-content ul {
  margin-bottom: 1rem;
}
.custom-error .custom-error-content li {
  padding-bottom: 0.375rem;
}
.custom-error .custom-error-content .error-code {
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: #a71930;
}
@media (min-width: 768px) {
  .custom-error .custom-error-content p,
.custom-error .custom-error-content span,
.custom-error .custom-error-content li {
    font-size: 1.5rem;
    line-height: 1.3333333333;
  }
}
@media print and (min-width: 6px) {
  .custom-error .custom-error-content p,
.custom-error .custom-error-content span,
.custom-error .custom-error-content li {
    font-size: 1.5rem;
    line-height: 1.3333333333;
  }
}

.display-settings {
  opacity: 0;
}
.initialized .display-settings {
  opacity: 1;
}
.display-settings .desktop-only,
.display-settings .android-only,
.display-settings .firefox-only,
.display-settings .ios-only,
.display-settings .ios-unsupported-only,
.display-settings .ipad-only,
.display-settings .mac-only,
.display-settings .mac-only-inline,
.display-settings .telehealth-enabled-only,
.display-settings .upgrade-required-only {
  display: none;
}

.telehealth-enabled .telehealth-enabled-only {
  display: block;
}
.telehealth-enabled .telehealth-disabled-only {
  display: none;
}

.is-desktop .desktop-only {
  display: block;
}
.is-desktop .mobile-only {
  display: none;
}

.is-android .android-only {
  display: block;
}
.is-android .hide-for-android {
  display: none;
}

.is-firefox .firefox-only {
  display: block;
}
.is-firefox .chrome-only {
  display: none;
}

.is-ios .ios-only {
  display: block;
}
.is-ios .hide-for-ios {
  display: none;
}

.is-mac .mac-only {
  display: block;
}
.is-mac .mac-only-inline {
  display: inline;
}

.ios-unsupported .ios-unsupported-only {
  display: block;
}
.ios-unsupported .ios-supported-only {
  display: none;
}

.is-ipad .ipad-only {
  display: block;
}
.is-ipad .hide-for-ipad {
  display: none;
}

.upgrade-required .upgrade-required-only {
  display: block;
}
.upgrade-required .upgrade-not-required {
  display: none;
}

.media-device.still-pending.pending .media-permissions-pending {
  opacity: 1;
}
.media-device.pending .media-device-test {
  display: none;
}
.media-device.pending .media-permissions-pending {
  display: flex;
  height: calc(100vh - 60px - 50px);
  justify-content: center;
  opacity: 0;
  transition: opacity 0.4s;
}
.media-device.pending .media-permissions-pending .mobile-only {
  height: 100%;
}
@supports (-webkit-touch-callout: none) {
  .media-device.pending .media-permissions-pending {
    height: calc(
					100vh - 60px - 50px - 122px
				);
  }
}
.media-device.success .test-status .dot-pulse {
  display: none;
}
.media-device.success .test-status svg {
  display: block;
  height: 38px;
  width: 38px;
}
.media-device.success .default-message {
  display: none;
}
.media-device.success .success-message {
  display: block;
}
.media-device.fail-permissions .media-device-test {
  display: none;
}
.media-device.fail-permissions .media-device-permissions {
  display: flex;
  justify-content: center;
}
.media-device.fail-permissions .device-help {
  background-color: #363534;
  display: block;
  margin-bottom: -1px;
  margin-top: 0;
  overflow: auto;
  padding-bottom: 0;
}
.media-device.fail-permissions .device-help img {
  max-height: 370px;
  margin: 2.5rem auto 0;
}
.media-device.fail-permissions .device-help .container > :last-child {
  margin-bottom: 0;
}
.media-device.fail-permissions .device-help .side-by-side {
  display: flex;
  justify-content: center;
}
.media-device.fail-permissions .device-help .side-by-side img {
  margin: 0 2px;
  max-width: calc(50% - 4px);
}
.media-device.fail-permissions .device-help .subtitle {
  color: #fff;
}
.is-android .media-device.fail-permissions .device-help img {
  margin: 4rem auto;
}
.is-android .media-device.fail-permissions .device-help .side-by-side {
  margin: 4rem auto;
}
.is-android .media-device.fail-permissions .device-help .side-by-side img {
  margin: 0 2px;
}
.media-device .media-device-test {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  display: block;
}
@media (min-width: 768px) {
  .media-device .media-device-test {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .media-device .media-device-test {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .media-device .media-device-test {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .media-device .media-device-test {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .media-device .media-device-test {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .media-device .media-device-test {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.media-device .content-container {
  display: block;
}
.media-device .media-permissions-pending {
  display: none;
}
.media-device .pending-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .media-device .pending-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .media-device .pending-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .media-device .pending-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .media-device .pending-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .media-device .pending-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .media-device .pending-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.media-device .pending-container div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.media-device .pending-container div :last-child {
  margin-bottom: 0;
}
.media-device .pending-container div .icon-error {
  height: 40px;
  margin: 0 auto 1rem;
  width: 40px;
}
.is-android .media-device .pending-container div:last-of-type {
  margin-bottom: 3.5rem;
}
.media-device .pending-container .title-secondary {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  color: #fff;
}
.media-device .pending-container img {
  margin: 1.5rem 0 2rem;
  width: 125px;
}
.media-device .pending-container p {
  margin-bottom: 1rem;
  text-align: center;
}
.media-device .media-device-permissions {
  display: none;
}
.media-device .permissions-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 768px) {
  .media-device .permissions-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .media-device .permissions-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .media-device .permissions-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .media-device .permissions-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .media-device .permissions-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .media-device .permissions-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.media-device .permissions-container .title-secondary {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  color: #fff;
}
.media-device .permissions-container img {
  margin: 1.25rem 0 2rem;
  width: 125px;
}
.media-device .permissions-container img.mobile-only {
  width: 100px;
}
.media-device .permissions-container p {
  margin-bottom: 1rem;
  text-align: center;
}
.media-device .refresh {
  font-size: 1rem;
  line-height: 1.375;
  color: #00b0e6;
  margin-bottom: 1rem;
  text-decoration: none;
}
.media-device .device-help {
  display: none;
}
.media-device .media-container {
  max-width: 500px;
  margin: 0 auto 2rem;
  position: relative;
  width: 100%;
}
.media-device .video-container {
  height: 0;
  padding-top: 100%;
  width: 100%;
  position: relative;
}
.media-device .video {
  background: black;
  height: 100%;
  width: calc(100% - 15px);
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.media-device .select-wrapper {
  bottom: 0;
  position: absolute;
  width: calc((100% - 15px) / 2 - 1px);
  left: 0;
}
.media-device .select-wrapper svg {
  stroke: #fff;
  pointer-events: none;
  position: absolute;
  right: 1.25rem;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
}
.media-device .select-wrapper.audio-list-wrapper {
  left: auto;
  right: 15px;
}
.media-device .device-list-video,
.media-device .device-list-audio {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 0;
  bottom: 0;
  color: #fff;
  height: 60px;
  left: 0;
  padding: 1rem 2.5rem 1rem 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-appearance: none;
  white-space: nowrap;
  width: 100%;
}
.media-device .meter-container {
  right: 0;
  bottom: 0;
  height: 100%;
  background-color: #363534;
  position: absolute;
  width: 15px;
}
.media-device .sound-meter {
  background-color: #00b0e6;
  bottom: 0;
  height: 0;
  max-height: 100%;
  position: absolute;
  transition: height 0.1s ease-in-out;
  width: 100%;
}
.media-device .test-status {
  align-items: center;
  background-color: rgba(54, 53, 52, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 1.25rem;
  left: 1.25rem;
  width: 38px;
  height: 38px;
}
.media-device .test-status svg {
  display: none;
}
.media-device .audio {
  margin: 0 0 1.5em 0;
  width: 400px;
}
.media-device .device-row {
  display: block;
}
.media-device .dot-pulse {
  position: relative;
  display: flex;
  width: 22px;
  align-items: center;
  justify-content: space-between;
}
.media-device .dot-pulse .dot, .media-device .dot-pulse::before, .media-device .dot-pulse::after {
  content: "";
  display: block;
  position: relative;
  top: 0;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}
.media-device .dot-pulse::before {
  animation-delay: 0s;
}
.media-device .dot-pulse::after {
  animation-delay: 0.5s;
}
@keyframes dotPulse {
  0% {
    transform: scale(0.5);
  }
  30% {
    transform: scale(1);
  }
  60%, 100% {
    transform: scale(0.5);
  }
}
.media-device .success-message {
  display: none;
}
.media-device .btn {
  width: 100%;
}
.media-device .icon-error {
  height: 80px;
  margin: 0 auto 1.75rem;
  width: 80px;
}
@media (min-width: 768px) {
  .media-device .btn {
    width: auto;
  }
  .media-device .media-device-test {
    align-items: center;
    display: flex;
  }
  .media-device.pending .media-permissions-pending {
    height: calc(100vh - 60px - 160px);
  }
  .media-device .media-container {
    margin: 0 2rem 0 0;
    width: 50%;
  }
  .media-device .content-container {
    max-width: calc(50% - 2rem);
  }
}
@media print and (min-width: 6px) {
  .media-device .btn {
    width: auto;
  }
  .media-device .media-device-test {
    align-items: center;
    display: flex;
  }
  .media-device.pending .media-permissions-pending {
    height: calc(100vh - 60px - 160px);
  }
  .media-device .media-container {
    margin: 0 2rem 0 0;
    width: 50%;
  }
  .media-device .content-container {
    max-width: calc(50% - 2rem);
  }
}
@media (min-width: 1280px) {
  .media-device .media-container {
    margin: 0 120px 0 0;
    width: 100%;
  }
  .media-device .content-container {
    max-width: 460px;
  }
}
@media print and (min-width: 7px) {
  .media-device .media-container {
    margin: 0 120px 0 0;
    width: 100%;
  }
  .media-device .content-container {
    max-width: 460px;
  }
}

.security-requirements {
  padding-bottom: 5rem;
}
.security-requirements .security-requirements-content hr {
  background-color: #bfbfbf;
  border: none;
  color: #bfbfbf;
  height: 2px;
  margin-bottom: 1.5rem;
}
.security-requirements .security-requirements-content .btn-primary {
  display: block;
  margin: 1.5rem auto;
}
@media (min-width: 768px) {
  .security-requirements .security-requirements-content hr {
    margin: 1.2rem 0px;
  }
  .security-requirements .security-requirements-content .btn-secondary {
    margin: 5rem 0 0;
    width: 100%;
  }
  .security-requirements .security-requirements-content .grid-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  .security-requirements .security-requirements-content .grid-col {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 10px;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
    height: 100%;
  }
}
@media print and (min-width: 6px) {
  .security-requirements .security-requirements-content hr {
    margin: 1.2rem 0px;
  }
  .security-requirements .security-requirements-content .btn-secondary {
    margin: 5rem 0 0;
    width: 100%;
  }
  .security-requirements .security-requirements-content .grid-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  .security-requirements .security-requirements-content .grid-col {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 10px;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
    height: 100%;
  }
}

.t3-footer {
  background-color: #363534;
  padding-top: 56px;
  position: relative;
}
.t3-footer.fixed .t3-footer-navigation {
  bottom: 0;
  position: fixed;
  top: auto;
  transition: margin-bottom 0.3s ease-in-out;
}
.mobile-nav-open .t3-footer.fixed .t3-footer-navigation {
  margin-bottom: -100vh;
}
.t3-footer .t3-footer-navigation {
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  background-color: #000;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.t3-footer .t3-footer-navigation .t3-footer-navigation-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  display: flex;
}
@media (min-width: 768px) {
  .t3-footer .t3-footer-navigation .t3-footer-navigation-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .t3-footer .t3-footer-navigation .t3-footer-navigation-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .t3-footer .t3-footer-navigation .t3-footer-navigation-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .t3-footer .t3-footer-navigation .t3-footer-navigation-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .t3-footer .t3-footer-navigation .t3-footer-navigation-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .t3-footer .t3-footer-navigation .t3-footer-navigation-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.t3-footer .t3-footer-navigation .btn {
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  padding: 1rem 0;
  flex: 0 0 50%;
  max-width: 50%;
}
.t3-footer .t3-footer-navigation .feedback-btn {
  color: #bfbfbf;
  text-align: left;
}
.t3-footer .t3-footer-navigation .scroll-btn {
  color: #00b0e6;
  text-align: right;
}
.t3-footer .t3-footer-cta {
  padding: 80px 0;
}
.t3-footer .t3-footer-cta .t3-footer-cta-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
}
@media (min-width: 768px) {
  .t3-footer .t3-footer-cta .t3-footer-cta-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .t3-footer .t3-footer-cta .t3-footer-cta-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .t3-footer .t3-footer-cta .t3-footer-cta-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .t3-footer .t3-footer-cta .t3-footer-cta-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .t3-footer .t3-footer-cta .t3-footer-cta-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .t3-footer .t3-footer-cta .t3-footer-cta-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.t3-footer .t3-footer-cta a {
  color: #fafafa;
}
.t3-footer .t3-footer-cta p {
  font-family: AvenirLight, Helvetica, sans-serif;
  font-weight: normal;
  color: #fafafa;
  margin-bottom: 2rem;
}
.t3-footer .t3-footer-cta p:last-child {
  margin-bottom: 0;
}
.t3-footer .t3-footer-cta .back-to-top-btn {
  display: block;
  margin: -50px auto 0;
  padding: 2rem;
}
.t3-footer .t3-footer-cta .back-to-top-btn .icon-arrow-down {
  stroke: #fafafa;
  stroke-width: 4px;
  transform: rotate(180deg);
  width: 1rem;
}
.t3-footer .t3-footer-cta .help-title {
  display: none;
}
@media (min-width: 768px) {
  .t3-footer {
    min-height: 160px;
    padding: 35px 0 34px;
  }
  .t3-footer .t3-footer-navigation {
    display: none;
  }
  .t3-footer .t3-footer-cta {
    padding: 0;
  }
  .t3-footer .t3-footer-cta .back-to-top-btn {
    display: none;
  }
  .t3-footer .t3-footer-cta .help-title {
    color: #00b0e6;
    display: block;
  }
  .t3-footer .t3-footer-cta p {
    display: block;
    margin-bottom: 0.2rem;
  }
}
@media print and (min-width: 6px) {
  .t3-footer {
    min-height: 160px;
    padding: 35px 0 34px;
  }
  .t3-footer .t3-footer-navigation {
    display: none;
  }
  .t3-footer .t3-footer-cta {
    padding: 0;
  }
  .t3-footer .t3-footer-cta .back-to-top-btn {
    display: none;
  }
  .t3-footer .t3-footer-cta .help-title {
    color: #00b0e6;
    display: block;
  }
  .t3-footer .t3-footer-cta p {
    display: block;
    margin-bottom: 0.2rem;
  }
}

.t3-header {
  background-color: #363534;
  margin-bottom: 2rem;
}
.t3-header .main-header {
  height: 60px;
  position: relative;
}
.t3-header .main-header a .icon-phs-logo,
.t3-header .main-header a .icon-phs-icon {
  fill: #fff;
}
.t3-header .main-header .logo-container {
  align-self: flex-start;
  height: auto;
  padding-top: 15px;
  padding-bottom: 19px;
}
.t3-header .main-header .logo-container .icon-phs-logo {
  display: block;
  height: 26px;
  width: 226px;
}
.t3-header .main-header .logo-container a {
  display: block;
  line-height: 1;
}
.t3-header .main-header .mobile-logo-container {
  align-items: center;
  display: flex;
  text-decoration: none;
}
.t3-header .main-header .mobile-logo-container a {
  display: flex;
}
.t3-header .main-header .mobile-logo-container .icon-phs-icon {
  height: 25px;
  margin-bottom: 8px;
  margin-right: 0.25rem;
  width: 22px;
}
.t3-header .main-header .mobile-logo-container .logo-subtitle {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: #fff;
  display: block;
  margin: 0;
  padding-left: 0.25rem;
}
.t3-header .main-header .phs-link {
  display: none;
}
.t3-header .icon-menu-open {
  fill: #fff;
}
@media (min-width: 768px) {
  .t3-header {
    margin-bottom: 60px;
  }
}
@media print and (min-width: 6px) {
  .t3-header {
    margin-bottom: 60px;
  }
}
@media (min-width: 890px) {
  .t3-header {
    margin-bottom: 60px;
  }
  .t3-header .main-header {
    flex-direction: column;
  }
  .t3-header .main-header .logo-subtitle {
    font-size: 1.125rem;
    line-height: 1.3333333333;
    font-family: AvenirLight, Helvetica, sans-serif;
    font-weight: normal;
    color: #fff;
    margin-bottom: 1px;
    padding-left: 0.75rem;
  }
  .t3-header .main-header .phs-link {
    font-size: 0.875rem;
    line-height: 1.4285714286;
    bottom: 15px;
    color: #bfbfbf;
    display: block;
    position: absolute;
    right: 30px;
    text-decoration: none;
  }
  .t3-header .main-header .phs-link:hover, .t3-header .main-header .phs-link:focus {
    text-decoration: underline;
  }
}
@media (min-width: 1280px) {
  .t3-header .main-header .phs-link {
    right: 70px;
  }
}
@media print and (min-width: 7px) {
  .t3-header .main-header .phs-link {
    right: 70px;
  }
}
@media (min-width: 1440px) {
  .t3-header .main-header .phs-link {
    right: 70px;
  }
}
@media print and (min-width: 1440px) {
  .t3-header .main-header .phs-link {
    right: 70px;
  }
}

.t3 .link-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 2rem;
  text-align: center;
}
.t3 .link-list li {
  display: flex;
  padding: 0;
}
.t3 .link-list li:after {
  color: #bfbfbf;
  content: "|";
}
.t3 .link-list li:last-child:after {
  display: none;
}
.t3 .link-list li a {
  display: inline-block;
  padding: 0 0.75rem;
}
@media (min-width: 768px) {
  .t3 .link-list {
    justify-content: flex-start;
    margin: 0.2rem 0;
  }
  .t3 .link-list li:first-child a {
    padding-left: 0;
  }
}
@media print and (min-width: 6px) {
  .t3 .link-list {
    justify-content: flex-start;
    margin: 0.2rem 0;
  }
  .t3 .link-list li:first-child a {
    padding-left: 0;
  }
}

.browser-check {
  text-align: center;
}
.browser-check .title-secondary,
.browser-check p,
.browser-check .cta-link {
  text-align: center;
}
.browser-check hr {
  margin: 1.2rem 0px;
}
.browser-check .images-container {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 2rem;
}
.browser-check .images-container img {
  padding: 0 4px;
}
.browser-check .icon-danger {
  height: 80px;
  margin-bottom: 1.75rem;
  width: 80px;
}
.browser-check .browser-list .cta-link {
  display: inline-block;
}
.is-ipad .browser-check .desktop-only {
  display: none;
}
@media (min-width: 768px) {
  .browser-check {
    text-align: left;
  }
  .browser-check .grid-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  .browser-check .grid-col {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 10px;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .browser-check p,
.browser-check .subtitle,
.browser-check .title-secondary {
    text-align: left;
  }
  .browser-check .no-space {
    margin-bottom: 0;
  }
  .browser-check .cta-link {
    margin: 0;
    text-align: left;
  }
  .browser-check .images-container {
    justify-content: flex-start;
  }
  .browser-check .images-container img {
    padding: 0 2rem 0 0;
  }
}
@media print and (min-width: 6px) {
  .browser-check {
    text-align: left;
  }
  .browser-check .grid-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  .browser-check .grid-col {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 10px;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .browser-check p,
.browser-check .subtitle,
.browser-check .title-secondary {
    text-align: left;
  }
  .browser-check .no-space {
    margin-bottom: 0;
  }
  .browser-check .cta-link {
    margin: 0;
    text-align: left;
  }
  .browser-check .images-container {
    justify-content: flex-start;
  }
  .browser-check .images-container img {
    padding: 0 2rem 0 0;
  }
}

.is-android .video-call-features {
  margin-top: 0;
}
.is-android .video-call-features .pulse {
  left: 20%;
  top: 15%;
}
.is-android .video-call-features .features-modal {
  padding: 2rem 0;
}
.is-android .video-call-features .image-btn-container img {
  max-height: calc(100vh - 6rem);
}
.is-android .video-call-features .content-slides {
  margin-top: 0;
}
.video-call-features .pulse {
  border-radius: 50%;
  height: 40px;
  left: 25%;
  pointer-events: none;
  position: absolute;
  top: 25%;
  width: 40px;
}
.video-call-features .pulse:before {
  border-radius: 50%;
  content: "";
  height: calc(100% + 12px);
  width: calc(100% + 12px);
  border: 4px solid #ff00ff;
  margin-left: -6px;
  margin-top: -6px;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  animation: pulse infinite ease-in-out 7s;
}
.video-call-features .pulse:after {
  border-radius: 50%;
  content: "";
  height: calc(100% + 12px);
  width: calc(100% + 12px);
  border: 2px solid #ff00ff;
  margin-left: -6px;
  margin-top: -6px;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  animation: pulse-outward infinite ease-in-out 7s;
}
@keyframes pulse {
  0% {
    opacity: 0;
  }
  8.5714285714% {
    opacity: 1;
  }
  28.5714285714% {
    opacity: 0;
  }
}
@keyframes pulse-outward {
  0% {
    transform: scale(1, 1);
    opacity: 0;
  }
  4.2857142857% {
    opacity: 0;
  }
  7.1428571429% {
    opacity: 0.5;
  }
  28.5714285714% {
    transform: scale(1.5, 1.5);
    opacity: 0;
  }
}
.video-call-features .features-modal {
  background-color: #eee;
  display: none;
  height: 100%;
  left: 0;
  margin-top: 0;
  opacity: 0;
  outline: 0;
  overflow-y: auto;
  padding: 0 0 2rem;
  position: fixed;
  top: 0;
  transition: opacity 0.2s ease-in-out;
  width: 100%;
  z-index: 2;
}
.video-call-features .features-modal.visible {
  display: block;
}
.video-call-features .features-modal.open {
  opacity: 1;
}
.video-call-features .features-modal.no-animate .feature-btn-container button:before {
  animation: none;
}
.video-call-features .features-modal.no-animate .feature-btn-container button:after {
  display: none;
}
.video-call-features .features-modal .container {
  padding-left: 0;
  padding-right: 0;
}
.video-call-features .features-modal p {
  text-align: center;
}
.video-call-features .close-modal-btn {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 1;
}
.video-call-features .video-call-ui {
  display: flex;
  justify-content: center;
}
.video-call-features .image-btn-container {
  position: relative;
}
.video-call-features .image-btn-container img {
  max-height: calc(100vh - 2rem);
}
.video-call-features .image-btn-container.hide-for-android {
  margin-top: -0.5rem;
}
.video-call-features .image-btn-container.android-only .feature-btn-container {
  bottom: 16.15%;
  width: 9.15%;
}
.video-call-features .image-btn-container.android-only .feature-btn-container.camera-off-btn {
  left: 17.3%;
}
.video-call-features .image-btn-container.android-only .feature-btn-container.mute-btn {
  left: 31.17%;
}
.video-call-features .image-btn-container.android-only .feature-btn-container.mute-btn button::before, .video-call-features .image-btn-container.android-only .feature-btn-container.mute-btn button::after {
  animation-delay: 0.85s;
}
.video-call-features .image-btn-container.android-only .feature-btn-container.end-call-btn {
  bottom: 15.35%;
  left: 45.35%;
  width: 11.85%;
}
.video-call-features .image-btn-container.android-only .feature-btn-container.end-call-btn button::before, .video-call-features .image-btn-container.android-only .feature-btn-container.end-call-btn button::after {
  animation-delay: 1.7s;
}
.video-call-features .image-btn-container.android-only .feature-btn-container.camera-switch-btn {
  left: 61.8%;
}
.video-call-features .image-btn-container.android-only .feature-btn-container.camera-switch-btn button::before, .video-call-features .image-btn-container.android-only .feature-btn-container.camera-switch-btn button::after {
  animation-delay: 2.55s;
}
.video-call-features .feature-btn-container {
  bottom: 25.96%;
  height: auto;
  line-height: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 8.5%;
}
.video-call-features .feature-btn-container button {
  border-radius: 50%;
  height: 0;
  padding-top: 100%;
  pointer-events: auto;
  width: 100%;
}
.video-call-features .feature-btn-container button:before {
  border-radius: 50%;
  content: "";
  height: calc(100% + 12px);
  width: calc(100% + 12px);
  border: 4px solid #ff00ff;
  margin-left: -6px;
  margin-top: -6px;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  animation: pulse infinite ease-in-out 7s;
}
.video-call-features .feature-btn-container button:after {
  border-radius: 50%;
  content: "";
  height: calc(100% + 12px);
  width: calc(100% + 12px);
  border: 2px solid #ff00ff;
  margin-left: -6px;
  margin-top: -6px;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  animation: pulse-outward infinite ease-in-out 7s;
}
@keyframes pulse {
  0% {
    opacity: 0;
  }
  8.5714285714% {
    opacity: 1;
  }
  28.5714285714% {
    opacity: 0;
  }
}
@keyframes pulse-outward {
  0% {
    transform: scale(1, 1);
    opacity: 0;
  }
  4.2857142857% {
    opacity: 0;
  }
  7.1428571429% {
    opacity: 0.5;
  }
  28.5714285714% {
    transform: scale(1.5, 1.5);
    opacity: 0;
  }
}
.video-call-features .feature-btn-container button.active::before {
  opacity: 1;
}
.video-call-features .feature-btn-container.camera-off-btn {
  left: 29.5%;
}
.video-call-features .feature-btn-container.mute-btn {
  left: 40.5%;
}
.video-call-features .feature-btn-container.mute-btn button::before, .video-call-features .feature-btn-container.mute-btn button::after {
  animation-delay: 0.85s;
}
.video-call-features .feature-btn-container.camera-switch-btn {
  left: 51.3%;
}
.video-call-features .feature-btn-container.camera-switch-btn button::before, .video-call-features .feature-btn-container.camera-switch-btn button::after {
  animation-delay: 1.7s;
}
.video-call-features .feature-btn-container.end-call-btn {
  left: 62.1%;
}
.video-call-features .feature-btn-container.end-call-btn button::before, .video-call-features .feature-btn-container.end-call-btn button::after {
  animation-delay: 2.55s;
}
.video-call-features .feature-btn-container .feature-btn:focus {
  box-shadow: 0 0 2pt 2pt #ff00ff;
}
.using-mouse .video-call-features .feature-btn-container .feature-btn:focus {
  box-shadow: none;
}
.video-call-features .content-slides {
  margin-top: -6.5vh;
  padding: 0 30px;
}
.video-call-features .content-slides .title-secondary {
  font-size: 1.125rem;
  line-height: 1.3333333333;
}
@media (min-width: 768px) {
  .video-call-features .content-slides .title-secondary {
    font-size: 1.5rem;
    line-height: 1.3333333333;
  }
  .video-call-features .image-btn-container.hide-for-android {
    margin-top: 0;
  }
}
@media print and (min-width: 6px) {
  .video-call-features .content-slides .title-secondary {
    font-size: 1.5rem;
    line-height: 1.3333333333;
  }
  .video-call-features .image-btn-container.hide-for-android {
    margin-top: 0;
  }
}

/* Appointment Tracker */
.at-footer {
  background-color: #363534;
  padding-top: 56px;
  position: relative;
}
.at-footer.fixed .at-footer-navigation {
  bottom: 0;
  position: fixed;
  top: auto;
  margin-bottom: 0;
  transition: margin-bottom 0.3s ease-in-out;
}
.mobile-nav-open .at-footer.fixed .at-footer-navigation {
  margin-bottom: -100vh;
}
.at-footer .at-footer-navigation {
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  background-color: #000;
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
}
.at-footer .at-footer-navigation .btn {
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  padding: 1rem 0;
}
.at-footer .at-footer-navigation .btn span {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  display: block;
  max-width: calc(1440px / 2);
}
@media (min-width: 768px) {
  .at-footer .at-footer-navigation .btn span {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .at-footer .at-footer-navigation .btn span {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .at-footer .at-footer-navigation .btn span {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .at-footer .at-footer-navigation .btn span {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .at-footer .at-footer-navigation .btn span {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .at-footer .at-footer-navigation .btn span {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.at-footer .at-footer-navigation .feedback-btn {
  color: #bfbfbf;
  text-align: left;
  flex: 0 0 50%;
  max-width: 50%;
}
.at-footer .at-footer-navigation .feedback-btn span {
  margin-right: 0;
}
.at-footer .at-footer-navigation .feedback-btn:hover, .at-footer .at-footer-navigation .feedback-btn:focus {
  background-color: #fff;
  color: #000;
}
.at-footer .at-footer-navigation .scroll-btn {
  color: #00b0e6;
  text-align: right;
  flex: 0 0 50%;
  max-width: 50%;
}
.at-footer .at-footer-navigation .scroll-btn span {
  margin-left: 0;
}
.at-footer .at-footer-navigation .scroll-btn:hover, .at-footer .at-footer-navigation .scroll-btn:focus {
  background-color: #00b0e6;
  color: #fff;
}
@media (min-width: 768px) {
  .at-footer {
    min-height: 160px;
  }
}
@media print and (min-width: 6px) {
  .at-footer {
    min-height: 160px;
  }
}

.at-header {
  background-color: #363534;
  margin-bottom: 2rem;
}
.at-header .main-header {
  height: 60px;
  position: relative;
}
.at-header .main-header a .icon-phs-logo,
.at-header .main-header a .icon-phs-icon {
  fill: #fff;
}
.at-header .main-header .logo-container {
  align-self: flex-start;
  height: auto;
  padding-bottom: 19px;
  padding-top: 15px;
}
.at-header .main-header .logo-container .icon-phs-logo {
  display: block;
  height: 26px;
  width: 226px;
}
.at-header .main-header .logo-container a {
  display: block;
  line-height: 1;
}
.at-header .main-header .mobile-logo-container {
  align-items: center;
  display: flex;
  text-decoration: none;
}
.at-header .main-header .mobile-logo-container a {
  display: flex;
}
.at-header .main-header .mobile-logo-container .icon-phs-icon {
  height: 25px;
  margin-bottom: 8px;
  margin-right: 0.25rem;
  width: 22px;
}
.at-header .main-header .mobile-logo-container .logo-subtitle {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: #fff;
  display: block;
  margin: 0;
  padding-left: 0.25rem;
}
.at-header .main-header .phs-link {
  display: none;
}
.at-header .icon-menu-open {
  fill: #fff;
}
@media (min-width: 768px) {
  .at-header {
    margin-bottom: 60px;
  }
}
@media print and (min-width: 6px) {
  .at-header {
    margin-bottom: 60px;
  }
}
@media (min-width: 890px) {
  .at-header {
    margin-bottom: 60px;
  }
  .at-header .main-header .logo-subtitle {
    font-size: 1.125rem;
    line-height: 1.3333333333;
    font-family: AvenirLight, Helvetica, sans-serif;
    font-weight: normal;
    color: #fff;
    margin-bottom: 1px;
    padding-left: 0.75rem;
  }
  .at-header .main-header .nav-link {
    color: #fff;
  }
}
@media (min-width: 1280px) {
  .at-header .main-header .phs-link {
    right: 70px;
  }
}
@media print and (min-width: 7px) {
  .at-header .main-header .phs-link {
    right: 70px;
  }
}
@media (min-width: 1440px) {
  .at-header .main-header .phs-link {
    right: 70px;
  }
}
@media print and (min-width: 1440px) {
  .at-header .main-header .phs-link {
    right: 70px;
  }
}

.banner-alert {
  background-color: #363534;
}
.banner-alert.closed {
  display: none;
}
.banner-alert .banner-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  padding-bottom: 2rem;
  padding-top: 2rem;
}
@media (min-width: 768px) {
  .banner-alert .banner-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .banner-alert .banner-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .banner-alert .banner-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .banner-alert .banner-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .banner-alert .banner-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .banner-alert .banner-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.banner-alert .icons-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.75rem;
}
.banner-alert .icons-wrapper .icon {
  height: 25px;
  width: 25px;
}
.banner-alert .icons-wrapper .icon-modal-close {
  height: 17px;
  stroke: #fff;
  stroke-width: 4px;
  width: 17px;
}
.banner-alert .icon-container {
  display: flex;
}
.banner-alert .banner-close {
  align-items: center;
  display: flex;
}
.banner-alert .banner-close:hover .icon, .banner-alert .banner-close:focus .icon {
  stroke: #aaa;
}
.banner-alert .banner-heading {
  font-size: 1rem;
  line-height: 1.375;
  color: #fff;
  font-family: "AvenirHeavy", Helvetica, sans-serif;
  margin-bottom: 0;
}
.banner-alert a {
  color: #fff;
}
.banner-alert p {
  font-size: 1rem;
  line-height: 1.375;
  color: #fff;
  font-family: "AvenirBook", Helvetica, sans-serif;
}
.banner-alert p:last-of-type {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .banner-alert .banner-text {
    margin-right: 4rem;
    max-width: 1025px;
  }
}
@media print and (min-width: 6px) {
  .banner-alert .banner-text {
    margin-right: 4rem;
    max-width: 1025px;
  }
}
@media (min-width: 1280px) {
  .banner-alert .banner-container {
    overflow: hidden;
    position: relative;
  }
  .banner-alert .icons-wrapper {
    align-items: center;
    left: 50%;
    margin-bottom: 0;
    padding: 0 12px;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .banner-alert .icons-wrapper .icon {
    height: 35px;
    width: 35px;
  }
  .banner-alert .icons-wrapper .icon-modal-close {
    height: 18px;
    pointer-events: auto;
    width: 18px;
  }
}
@media print and (min-width: 7px) {
  .banner-alert .banner-container {
    overflow: hidden;
    position: relative;
  }
  .banner-alert .icons-wrapper {
    align-items: center;
    left: 50%;
    margin-bottom: 0;
    padding: 0 12px;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .banner-alert .icons-wrapper .icon {
    height: 35px;
    width: 35px;
  }
  .banner-alert .icons-wrapper .icon-modal-close {
    height: 18px;
    pointer-events: auto;
    width: 18px;
  }
}

.portal .banner-alert {
  background-color: rgba(255, 239, 239, 0.95);
  margin-top: 0;
  margin-bottom: 0;
}
.portal .banner-alert .banner-container {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.portal .banner-alert .banner-text {
  margin-right: 0;
  max-width: 98%;
}
.portal .banner-alert .banner-heading {
  color: #000;
}
.portal .banner-alert p {
  color: #000;
}
.portal .banner-alert .icon-modal-close {
  stroke: #000;
}
.portal .banner-warning {
  background-color: rgba(255, 248, 218, 0.95);
  margin-top: 0;
}
.portal .banner-warning .banner-text {
  color: #000;
  margin-right: 0;
  max-width: 98%;
}
.portal .banner-info {
  background-color: rgba(229, 241, 245, 0.95);
  margin-top: 0;
}
.portal .banner-info .banner-text {
  margin-right: 0;
  max-width: 98%;
}
.portal a {
  color: #00759a;
}
.portal .system-error {
  height: 65px;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 9999;
}

.pls .banner-wrapper {
  margin-top: 0px;
}
.pls .banner-wrapper:first-of-type {
  margin-top: 135px;
}
.pls .banner-alert {
  margin-bottom: none !important;
  background-color: rgba(255, 239, 239, 0.95);
  width: 100%;
}
.pls .banner-alert .icon {
  height: 40px;
  width: 40px;
}
.pls .banner-alert .banner-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: stretch;
  padding-left: 25px;
  gap: 15px;
  padding-right: 25px;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  margin-left: 0px;
  margin-right: 0px;
  max-width: 100%;
}
.pls .banner-alert .icon-container {
  flex-grow: 0;
  flex-shrink: 0;
}
.pls .banner-alert .banner-close {
  flex-grow: 0;
  flex-shrink: 0;
}
.pls .banner-alert .banner-text {
  margin-right: 0;
  flex: 1 1 200px;
  width: 100%;
  text-align: left;
  max-width: 100%;
}
.pls .banner-alert .banner-heading {
  color: #000;
}
.pls .banner-alert p {
  color: #000;
}
.pls .banner-alert .icon-modal-close {
  stroke: #000;
  width: 20px;
}
.pls.has-banner .title-bar {
  /*margin-top: 30px;*/
}
.pls.detail-view-loaded .title-bar {
  margin-top: 60px;
}
@media (min-width: 1280px) {
  .pls .banner-wrapper:first-of-type {
    margin-top: -45px;
  }
}
@media print and (min-width: 7px) {
  .pls .banner-wrapper:first-of-type {
    margin-top: -45px;
  }
}
@media (min-width: 1440px) {
  .pls .banner-wrapper:first-of-type {
    margin-top: -45px;
  }
  .pls .banner-alert {
    width: 100%;
  }
}
@media print and (min-width: 1440px) {
  .pls .banner-wrapper:first-of-type {
    margin-top: -45px;
  }
  .pls .banner-alert {
    width: 100%;
  }
}
.pls .banner-warning {
  background-color: rgba(255, 248, 218, 0.95);
}
.pls .banner-warning .banner-text {
  color: #000;
  margin-right: 0;
  max-width: 98%;
}
.pls .banner-info {
  background-color: rgba(229, 241, 245, 0.95);
  margin-top: 0;
}
.pls .banner-info .banner-text {
  margin-right: 0;
  max-width: 98%;
}
.pls a {
  color: #00759a;
}
.pls .system-error {
  height: 65px;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 9999;
}

.at .content-slides {
  margin-bottom: 2rem;
}
.at .content-slides .desktop-only {
  display: none;
}
.at .content-slides .prev,
.at .content-slides .next {
  display: inline-flex;
  margin-bottom: 25px;
  position: relative;
  transform: none;
}
.at .content-slides .prev:disabled,
.at .content-slides .next:disabled {
  pointer-events: none;
}
.at .content-slides .prev:disabled .icon,
.at .content-slides .next:disabled .icon {
  stroke: #bfbfbf;
}
.at .content-slides .prev {
  margin-right: 6px;
}
.at .content-slides .slide-indicators {
  margin-top: 1rem;
}
@media (min-width: 1280px) {
  .at .content-slides .desktop-only {
    display: flex;
  }
  .at .content-slides .mobile-only {
    display: none;
  }
  .at .content-slides .prev {
    margin-right: 10px;
  }
}
@media print and (min-width: 7px) {
  .at .content-slides .desktop-only {
    display: flex;
  }
  .at .content-slides .mobile-only {
    display: none;
  }
  .at .content-slides .prev {
    margin-right: 10px;
  }
}

#mychartframe {
  min-height: auto !important;
  height: 100% !important;
}

.echeckin-modal.iframe-loaded .loading-indicator {
  display: none;
}
.echeckin-modal.iframe-loaded .echeckin-modal-info {
  display: block;
}
.echeckin-modal.mychart-down .mychart-error-message {
  display: block;
}
.echeckin-modal.mychart-down iframe,
.echeckin-modal.mychart-down .loading-indicator {
  display: none;
}
.echeckin-modal .mychart-error-message {
  display: none;
}
.echeckin-modal .modal-body {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}
.echeckin-modal .loading-indicator {
  padding: 3rem 2rem;
}
.echeckin-modal .echeckin-modal-info {
  float: right;
  display: none;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 1.125rem;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  align-self: flex-end;
}

.echeckin-modal-header {
  background-color: #363534 !important;
}
.echeckin-modal-header .heading-3 {
  color: #fff;
  padding-bottom: 20px;
  padding-top: 25px;
}
.echeckin-modal-header a .icon-phs-logo,
.echeckin-modal-header a .icon-phs-icon {
  fill: #fff;
}
.echeckin-modal-header .modal-logo-container {
  display: none;
  align-self: flex-start;
  height: auto;
  padding-bottom: 19px;
  padding-top: 15px;
}
.echeckin-modal-header .modal-logo-container .icon-phs-logo {
  display: block;
  height: 26px;
  width: 226px;
}
.echeckin-modal-header .modal-logo-container a {
  display: block;
  line-height: 1;
}
.echeckin-modal-header .modal-close-btn {
  background-color: transparent;
  border: none;
  height: 1rem;
  outline: 0;
  padding: 0;
  width: 1rem;
  padding-bottom: 20px;
  padding-top: 25px;
}
.echeckin-modal-header .modal-close-btn svg {
  height: 1rem;
  stroke: #fff;
  stroke-width: 4px;
  width: 1rem;
}
.echeckin-modal-header .modal-close-btn:hover, .echeckin-modal-header .modal-close-btn:focus {
  cursor: pointer;
}
.echeckin-modal-header .modal-close-btn:hover svg, .echeckin-modal-header .modal-close-btn:focus svg {
  stroke: rgba(255, 255, 255, 0.5);
}
@media (min-width: 890px) {
  .echeckin-modal-header .modal-logo-container {
    display: block;
  }
  .echeckin-modal-header .heading-3 {
    padding-right: 250px;
  }
}

.at .image-summary {
  align-items: flex-start;
  justify-content: flex-start;
}
.at .image-summary h1 {
  font-size: 1.25rem;
  line-height: 1.5;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 20px;
}
.at .image-summary p {
  font-size: 1rem;
  line-height: 1.375;
}
.at .image-summary .image-block {
  margin: 0 auto;
  padding: 0 2.5rem 1rem;
}
.at .image-summary .image-block img {
  max-height: 55vh;
}
.at .image-summary .slide-indicators {
  display: none;
}
@media (min-width: 1280px) {
  .at .image-summary {
    flex-direction: row-reverse;
  }
  .at .image-summary h1 {
    font-size: 1.5rem;
    line-height: 1.3333333333;
    font-family: AvenirHeavy, Helvetica, sans-serif;
    font-weight: normal;
    margin-bottom: 20px;
  }
  .at .image-summary p {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
  .at .image-summary .image-block {
    max-width: 700px;
    padding: 0 2rem;
  }
}
@media print and (min-width: 7px) {
  .at .image-summary {
    flex-direction: row-reverse;
  }
  .at .image-summary h1 {
    font-size: 1.5rem;
    line-height: 1.3333333333;
    font-family: AvenirHeavy, Helvetica, sans-serif;
    font-weight: normal;
    margin-bottom: 20px;
  }
  .at .image-summary p {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
  .at .image-summary .image-block {
    max-width: 700px;
    padding: 0 2rem;
  }
}

.tabs .tabs-header {
  background-color: #fafafa;
  margin-top: 24px;
}
.tabs .tab-list {
  display: flex;
}
.tabs .tab-btn {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1;
  background: #fafafa;
  border: none;
  color: #595756;
  display: flex;
  flex: 1 1 0;
  padding: 1.5rem 1.25rem 2.25rem;
}
.tabs .tab-btn:hover, .tabs .tab-btn:focus {
  background: #595756;
  color: #fff;
}
.tabs .tab-btn.active {
  background: #fff;
  border-top: 4px solid #000;
  color: #000;
  margin-top: -24px;
  pointer-events: none;
}
.tabs .tab-btn.active.complete {
  border-top-color: #74c15f;
}
.tabs .tab-btn.active.has-cta {
  border-top-color: #00b0e6;
}
.tabs .tab-btn.active:hover, .tabs .tab-btn.active:focus {
  background: #fff;
  color: #000;
}
.tabs .tab-btn .desktop-title {
  display: none;
}
.tabs .tab-btn span {
  pointer-events: none;
}
.tabs .tab-panel {
  display: none;
}
.tabs .tab-panel.active {
  background-color: #fff;
  display: block;
}
.tabs .tab-content {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  padding-bottom: 40px;
  padding-top: 30px;
}
@media (min-width: 768px) {
  .tabs .tab-content {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .tabs .tab-content {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .tabs .tab-content {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .tabs .tab-content {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .tabs .tab-content {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .tabs .tab-content {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.tabs .tab-content + .tab-content {
  padding-top: 0;
}
.tabs .tab-content > :last-child {
  margin-bottom: 0;
}
.tabs .tab-content.has-image .image-container {
  margin-top: 2rem;
}
.tabs .tab-content p {
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.2222222222;
  color: #363534;
}
.tabs .large-icon {
  margin-bottom: 0.75rem;
}
.tabs .large-icon .icon {
  height: 40px;
  width: 40px;
}
.tabs .tab-title {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.2222222222;
  color: #363534;
  margin-bottom: 0;
}
.tabs .due-date {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.375;
  color: #363534;
}
.tabs .banner-btn {
  background-color: #00b0e6;
  display: block;
  padding: 30px 0;
  width: 100%;
  text-decoration: none;
}
.tabs .banner-btn span {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.2222222222;
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .tabs .banner-btn span {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .tabs .banner-btn span {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .tabs .banner-btn span {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .tabs .banner-btn span {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .tabs .banner-btn span {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .tabs .banner-btn span {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.tabs .banner-btn span svg {
  stroke: #000;
}
.tabs .banner-btn:hover, .tabs .banner-btn:focus {
  background-color: #000;
}
.tabs .banner-btn:hover span, .tabs .banner-btn:focus span {
  color: #fff;
}
.tabs .banner-btn:hover span svg, .tabs .banner-btn:focus span svg {
  stroke: #fff;
}
.tabs .banner-btn.gray {
  background-color: #bfbfbf;
}
.tabs .banner-btn.gray:hover, .tabs .banner-btn.gray:focus {
  background-color: #000;
}
.tabs .banner-tested {
  border-top: 2px solid #bfbfbf;
  display: block;
  padding: 30px 0;
  width: 100%;
}
.tabs .banner-tested div {
  font-size: 1.125rem;
  line-height: 1.2222222222;
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  background-color: #fff;
  color: #000;
  display: flex;
  height: 21px;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .tabs .banner-tested div {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .tabs .banner-tested div {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .tabs .banner-tested div {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .tabs .banner-tested div {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .tabs .banner-tested div {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .tabs .banner-tested div {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.tabs .banner-tested div strong {
  display: block;
}
.tabs .banner-tested div .icon-success {
  fill: #fff;
  stroke: #86bf6b;
  height: 3rem;
  width: 3rem;
  transform: translateX(0.5rem);
}
.tabs .check-in-item {
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.375;
  display: flex;
  margin-bottom: 1.25rem;
}
.tabs .check-in-item:last-of-type {
  margin-bottom: 0;
}
.tabs .check-in-item.update-needed:before {
  border: 2px solid #bfbfbf;
  border-radius: 50%;
  display: block;
  content: "";
  height: 18px;
  width: 18px;
  max-width: 18px;
  flex: 0 0 18px;
  margin-right: 1.25rem;
}
.tabs .check-in-item .check-in-label {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  display: block;
}
.tabs .check-in-item .icon-success {
  height: 18px;
  width: 18px;
  max-width: 18px;
  flex: 0 0 18px;
  margin-right: 1.25rem;
}
.tabs .location-block {
  margin-top: 2rem;
}
.tabs .location-block .location-image {
  margin: 0 -30px 1.5rem;
  text-decoration: none;
}
.tabs .location-block .location-image img {
  width: 100%;
}
.tabs .location-block .location-name {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.375;
  color: #000;
  margin-bottom: 1rem;
}
.tabs .location-block .location-info p,
.tabs .location-block .location-info a {
  font-size: 1rem;
  line-height: 1.375;
  color: #000;
  margin-bottom: 0;
}
.tabs .location-block .location-info .location-bold {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  margin-top: 1.5rem;
}
.tabs .location-block .location-data {
  margin-bottom: 1.5rem;
}
.tabs hr {
  border-top: 5px solid #eee;
  margin: 0;
}
@media (min-width: 768px) {
  .tabs .tab-list {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .tabs .tab-list {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 768px) and (min-width: 6px) {
  .tabs .tab-list {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 768px) and (min-width: 1280px) {
  .tabs .tab-list {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 768px) and (min-width: 7px) {
  .tabs .tab-list {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 768px) and (min-width: 1440px) {
  .tabs .tab-list {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 768px) and (min-width: 1440px) {
  .tabs .tab-list {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 768px) {
  .tabs .tab-btn .mobile-title {
    display: none;
  }
  .tabs .tab-btn .desktop-title {
    display: block;
  }
}
@media (min-width: 768px) {
  .tabs .tab-content {
    padding-bottom: 60px;
    padding-top: 50px;
  }
  .tabs .tab-content.has-image {
    display: flex;
  }
  .tabs .tab-content.has-image .image-container {
    flex: 0 0 40%;
    margin-top: 0;
    max-width: 40%;
  }
  .tabs .tab-content.has-border {
    border-top: 5px solid #fafafa;
  }
  .tabs .tab-content .content-container {
    max-width: 80%;
  }
  .tabs .tab-content p {
    font-size: 1.5rem;
    line-height: 1.1666666667;
  }
}
@media (min-width: 768px) {
  .tabs .tab-title {
    font-size: 1.5rem;
    line-height: 1.1666666667;
  }
}
@media (min-width: 768px) {
  .tabs .banner-tested {
    padding: 36px 0;
  }
  .tabs .banner-tested div strong {
    display: inline;
  }
}
@media (min-width: 768px) {
  .tabs .banner-btn {
    padding: 36px 0;
  }
}
@media (min-width: 768px) {
  .tabs .check-in-item .check-in-label {
    display: inline;
  }
}
@media (min-width: 768px) {
  .tabs .location-block {
    display: flex;
  }
  .tabs .location-block .location-image {
    flex: 0 0 25%;
    margin: 0;
    max-width: 25%;
  }
  .tabs .location-block .location-info-container {
    flex: 1;
    margin-left: 1.25rem;
  }
  .tabs .location-block .location-info {
    display: flex;
    justify-content: space-between;
  }
  .tabs .location-block .location-data {
    flex: 1 1 0;
    margin-right: 1rem;
  }
  .tabs .location-block .location-data:last-of-type {
    margin-right: 0;
  }
}
@media print and (min-width: 6px) {
  .tabs .tab-list {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
  }
}
@media print and (min-width: 6px) and (min-width: 768px) {
  .tabs .tab-list {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) and (min-width: 6px) {
  .tabs .tab-list {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) and (min-width: 1280px) {
  .tabs .tab-list {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 6px) and (min-width: 7px) {
  .tabs .tab-list {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 6px) and (min-width: 1440px) {
  .tabs .tab-list {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 6px) and (min-width: 1440px) {
  .tabs .tab-list {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 6px) {
  .tabs .tab-btn .mobile-title {
    display: none;
  }
  .tabs .tab-btn .desktop-title {
    display: block;
  }
}
@media print and (min-width: 6px) {
  .tabs .tab-content {
    padding-bottom: 60px;
    padding-top: 50px;
  }
  .tabs .tab-content.has-image {
    display: flex;
  }
  .tabs .tab-content.has-image .image-container {
    flex: 0 0 40%;
    margin-top: 0;
    max-width: 40%;
  }
  .tabs .tab-content.has-border {
    border-top: 5px solid #fafafa;
  }
  .tabs .tab-content .content-container {
    max-width: 80%;
  }
  .tabs .tab-content p {
    font-size: 1.5rem;
    line-height: 1.1666666667;
  }
}
@media print and (min-width: 6px) {
  .tabs .tab-title {
    font-size: 1.5rem;
    line-height: 1.1666666667;
  }
}
@media print and (min-width: 6px) {
  .tabs .banner-tested {
    padding: 36px 0;
  }
  .tabs .banner-tested div strong {
    display: inline;
  }
}
@media print and (min-width: 6px) {
  .tabs .banner-btn {
    padding: 36px 0;
  }
}
@media print and (min-width: 6px) {
  .tabs .check-in-item .check-in-label {
    display: inline;
  }
}
@media print and (min-width: 6px) {
  .tabs .location-block {
    display: flex;
  }
  .tabs .location-block .location-image {
    flex: 0 0 25%;
    margin: 0;
    max-width: 25%;
  }
  .tabs .location-block .location-info-container {
    flex: 1;
    margin-left: 1.25rem;
  }
  .tabs .location-block .location-info {
    display: flex;
    justify-content: space-between;
  }
  .tabs .location-block .location-data {
    flex: 1 1 0;
    margin-right: 1rem;
  }
  .tabs .location-block .location-data:last-of-type {
    margin-right: 0;
  }
}
@media (min-width: 1280px) {
  .tabs .tab-content.has-image {
    display: flex;
  }
  .tabs .tab-content.has-image .image-container {
    max-width: 33.3333333333%;
    flex: 0 0 33.3333333333%;
  }
}
@media print and (min-width: 7px) {
  .tabs .tab-content.has-image {
    display: flex;
  }
  .tabs .tab-content.has-image .image-container {
    max-width: 33.3333333333%;
    flex: 0 0 33.3333333333%;
  }
}

.upcoming-appointments {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
}
@media (min-width: 768px) {
  .upcoming-appointments {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .upcoming-appointments {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .upcoming-appointments {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .upcoming-appointments {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .upcoming-appointments {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .upcoming-appointments {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.upcoming-appointments.hide-future .future-section {
  display: none;
  height: 0;
}
.upcoming-appointments.hide-future .section-header .icon {
  transform: none;
}
.upcoming-appointments .icon-check {
  stroke: #000;
}
.upcoming-appointments .icon-success {
  fill: #86bf6b;
  height: 30px;
  stroke: #000;
  width: 30px;
}
.upcoming-appointments .icon-action-needed {
  border: 2px solid #bfbfbf;
  border-radius: 50%;
  color: #fff;
  content: "";
  display: block;
  height: 30px;
  width: 30px;
}
.upcoming-appointments .title-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.2rem;
  margin-top: 2rem;
}
.upcoming-appointments .title-container .title-main {
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 2.5rem;
  line-height: 1;
  color: #a71930;
  margin-bottom: 1.2rem;
  min-width: 100%;
}
.upcoming-appointments .title-container .title-info {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.3333333333;
  color: #000;
}
.upcoming-appointments .main-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.upcoming-appointments .row-title {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.375;
  margin-bottom: 1.5rem;
}
.upcoming-appointments .full-row {
  margin-bottom: 2rem;
}
.upcoming-appointments .full-row .row-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}
.upcoming-appointments .appointment-date {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
}
.upcoming-appointments .appointment-date span {
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
}
.upcoming-appointments .appointment-date .estimated-time,
.upcoming-appointments .appointment-date .scheduled-time {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
}
.upcoming-appointments .appointment-date .delay {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
}
.upcoming-appointments .provider {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  display: block;
}
.upcoming-appointments .appointment-type {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.5;
  color: #707070;
  margin-bottom: 1.5rem;
  text-transform: capitalize;
}
.upcoming-appointments .section-header {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  align-items: center;
  background-color: #bfbfbf;
  display: flex;
  justify-content: center;
  line-height: 1;
  margin: 0;
  padding: 0.75rem 2rem;
  width: 100%;
}
.upcoming-appointments .section-header.section-toggle-button:hover {
  background-color: #363534;
  color: #fff;
  cursor: pointer;
}
.upcoming-appointments .section-header.section-toggle-button:hover .icon {
  stroke: #fafafa;
}
.upcoming-appointments .section-header .section-header-text {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
}
.upcoming-appointments .section-header .icon {
  align-items: center;
  display: flex;
  margin-left: auto;
  stroke-width: 4px;
  transform: rotate(180deg);
}
.upcoming-appointments .future-section {
  margin-bottom: 5rem;
}
@media (min-width: 768px) {
  .upcoming-appointments .title-container {
    justify-content: space-between;
  }
  .upcoming-appointments .title-container .title-main {
    margin-bottom: 0;
    min-width: 0;
  }
  .upcoming-appointments .title-container .title-info {
    text-align: right;
  }
  .upcoming-appointments .row-container {
    min-height: 95px;
  }
  .upcoming-appointments .row-container .main-row {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }
  .upcoming-appointments .provider {
    display: inline;
  }
  .upcoming-appointments .appointment-type {
    margin-bottom: 0;
  }
}
@media print and (min-width: 6px) {
  .upcoming-appointments .title-container {
    justify-content: space-between;
  }
  .upcoming-appointments .title-container .title-main {
    margin-bottom: 0;
    min-width: 0;
  }
  .upcoming-appointments .title-container .title-info {
    text-align: right;
  }
  .upcoming-appointments .row-container {
    min-height: 95px;
  }
  .upcoming-appointments .row-container .main-row {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }
  .upcoming-appointments .provider {
    display: inline;
  }
  .upcoming-appointments .appointment-type {
    margin-bottom: 0;
  }
}

.welcome-page .title-primary {
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 2.5rem;
  line-height: 1;
  color: #a71930;
  margin-bottom: 2rem;
}
.welcome-page ul {
  font-size: 1.375rem;
  line-height: 1.4545454545;
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 3rem;
}
.welcome-page .content-slides .container {
  padding: 0;
}
.welcome-page .content-slides .sign-in-btn {
  background-color: #00b0e6;
  min-width: 250px;
}
.welcome-page .content-slides .sign-in-btn:hover, .welcome-page .content-slides .sign-in-btn:focus {
  background-color: #000;
}
.welcome-page .banner-btn {
  background-color: #00b0e6;
  display: block;
  padding: 30px 0;
  text-decoration: none;
  width: 100%;
}
.welcome-page .banner-btn span {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.2222222222;
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  align-items: center;
  color: #000;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .welcome-page .banner-btn span {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .welcome-page .banner-btn span {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .welcome-page .banner-btn span {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .welcome-page .banner-btn span {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .welcome-page .banner-btn span {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .welcome-page .banner-btn span {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.welcome-page .banner-btn span svg {
  stroke: #000;
}
.welcome-page .banner-btn:hover, .welcome-page .banner-btn:focus {
  background-color: #000;
}
.welcome-page .banner-btn:hover span, .welcome-page .banner-btn:focus span {
  color: #fff;
}
.welcome-page .banner-btn:hover span svg, .welcome-page .banner-btn:focus span svg {
  stroke: #fff;
}

/* Heart & Vascular */
.hv-no-results .no-results-wrapper {
  background-color: #ffefef;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.hv-no-results h4 {
  font-size: 1.25rem;
  line-height: 1.5;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
}
.hv-no-results ul {
  list-style-position: inside;
  padding-left: 0;
}
.hv-no-results a {
  color: #00759a;
  padding-left: 0.8rem;
}
.hv-no-results .reset-filters {
  display: flex;
  flex-direction: row;
  padding-top: 1.5rem;
}
.hv-no-results .reset-filters p {
  margin-bottom: 0;
}
.hv-no-results .text {
  font-size: 1rem;
  line-height: 1.375;
}
@media (min-width: 1280px) {
  .hv-no-results .no-results-wrapper {
    padding-left: 6rem;
    padding-right: 0;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
@media print and (min-width: 7px) {
  .hv-no-results .no-results-wrapper {
    padding-left: 6rem;
    padding-right: 0;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.hv-isc-listing-module {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 2rem;
  border-bottom: 2px solid #e7e7e7;
  width: 100%;
  height: auto;
}
.hv-isc-listing-module h4 {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 1.375rem;
}
.hv-isc-listing-module .name-nct {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  margin: 0;
}
.hv-isc-listing-module .updated {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 0.7rem;
}
.hv-isc-listing-module .isc-location {
  margin-bottom: 0.7rem;
}
.hv-isc-listing-module .pcc-name {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  margin: 0;
}
.hv-isc-listing-module .albuquerque {
  font-size: 1rem;
  line-height: 1.375;
}
.hv-isc-listing-module .description-row {
  font-size: 1rem;
  line-height: 1.375;
  display: none;
}
.hv-isc-listing-module .description-col {
  font-size: 1rem;
  line-height: 1.375;
}
.hv-isc-listing-module .left-side-listing {
  padding-left: 1.5rem;
  width: 100%;
  text-align: left;
}
.hv-isc-listing-module .right-side-listing {
  text-align: left;
  padding-left: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 2rem;
}
.hv-isc-listing-module .c-trials-button {
  border-color: #00b0e6;
  color: black;
  border: 2px solid #00b0e6;
  background-color: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-top: 0.5rem;
}
@media (min-width: 768px) {
  .hv-isc-listing-module {
    flex-direction: row;
  }
  .hv-isc-listing-module .updated {
    margin-bottom: 1.5rem;
  }
  .hv-isc-listing-module .isc-location {
    margin-bottom: 1.5rem;
  }
  .hv-isc-listing-module .c-trials-button {
    margin-top: 1.5rem;
  }
  .hv-isc-listing-module .description-row {
    display: block;
  }
  .hv-isc-listing-module .description-col {
    display: none;
  }
  .hv-isc-listing-module .left-side-listing {
    padding-left: 0;
    padding-right: 0.5rem;
    width: 65%;
    text-align: left;
  }
  .hv-isc-listing-module .right-side-listing {
    padding-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
@media print and (min-width: 6px) {
  .hv-isc-listing-module {
    flex-direction: row;
  }
  .hv-isc-listing-module .updated {
    margin-bottom: 1.5rem;
  }
  .hv-isc-listing-module .isc-location {
    margin-bottom: 1.5rem;
  }
  .hv-isc-listing-module .c-trials-button {
    margin-top: 1.5rem;
  }
  .hv-isc-listing-module .description-row {
    display: block;
  }
  .hv-isc-listing-module .description-col {
    display: none;
  }
  .hv-isc-listing-module .left-side-listing {
    padding-left: 0;
    padding-right: 0.5rem;
    width: 65%;
    text-align: left;
  }
  .hv-isc-listing-module .right-side-listing {
    padding-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.hv-blockquote {
  text-align: center;
  margin-bottom: 2rem;
  background: #eee;
  padding: 3rem 1.25rem;
}
.hv-blockquote .icon-quote {
  flex: 3;
  width: 3.3rem;
  height: 2.7rem;
  fill: #bfbfbf;
}
.hv-blockquote .quote {
  font-size: 1.375rem;
  line-height: 1.4545454545;
  font-family: AvenirBlack, Helvetica, sans-serif;
  font-weight: normal;
  font-style: oblique;
}
@media (min-width: 768px) {
  .hv-blockquote {
    padding: 3rem 6rem;
  }
}
@media print and (min-width: 6px) {
  .hv-blockquote {
    padding: 3rem 6rem;
  }
}

.hv-related-content {
  background: #eee;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding-bottom: 25px;
  padding-left: 20px;
  padding-top: 20px;
  width: 100%;
}
.hv-related-content.one-block {
  justify-content: space-around;
}
.hv-related-content .related-content-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 2rem;
  padding-right: 30px;
}
.hv-related-content .related-content-block:last-child {
  padding-bottom: 0px;
}
.hv-related-content .related-content-top {
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
}
.hv-related-content .related-content-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.hv-related-content h4 {
  font-size: 1.25rem;
  line-height: 1.5;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
}
.hv-related-content li {
  padding-bottom: 0.25rem;
}
.hv-related-content a {
  font-size: 0.875rem;
  line-height: 1.2857142857;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  color: #00759a;
  display: inline-block;
  text-decoration: none;
}
.hv-related-content a:focus, .hv-related-content a:hover {
  text-decoration: underline;
}
.hv-related-content .related-content-cta span {
  padding-right: 0.25rem;
}
.hv-related-content .related-content-cta:focus, .hv-related-content .related-content-cta:hover {
  text-decoration: none;
}
.hv-related-content .related-content-cta:focus span, .hv-related-content .related-content-cta:hover span {
  text-decoration: underline;
}
.hv-related-content .icon-external {
  height: 8px;
  stroke: #00759a;
  width: 8px;
}
@media (min-width: 360px) {
  .hv-related-content.one-block .related-content-list {
    column-gap: 1rem;
    columns: 2;
  }
}
@media print and (min-width: 3px) {
  .hv-related-content.one-block .related-content-list {
    column-gap: 1rem;
    columns: 2;
  }
}
@media (min-width: 414px) {
  .hv-related-content.three-blocks {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media print and (min-width: 5px) {
  .hv-related-content.three-blocks {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media (min-width: 768px) {
  .hv-related-content {
    flex-direction: row;
  }
  .hv-related-content.one-block .related-content-list {
    columns: 2;
  }
  .hv-related-content .related-content-block {
    padding-bottom: 0px;
  }
}
@media print and (min-width: 6px) {
  .hv-related-content {
    flex-direction: row;
  }
  .hv-related-content.one-block .related-content-list {
    columns: 2;
  }
  .hv-related-content .related-content-block {
    padding-bottom: 0px;
  }
}
@media (min-width: 1280px) {
  .hv-related-content:not(.three-blocks) .related-content-list {
    columns: 2;
  }
}
@media print and (min-width: 7px) {
  .hv-related-content:not(.three-blocks) .related-content-list {
    columns: 2;
  }
}

.hv-callout {
  margin-bottom: 3rem;
  position: relative;
  width: 100%;
}
.hv-callout.has-background {
  background-color: #d3d3d3;
  padding: 43px 30px 50px;
}
.hv-callout.has-background .callout-description.warning {
  width: 100%;
  left: 0;
  padding-left: 30px;
  padding-right: 30px;
}
.hv-callout .icon-share-this {
  left: 10px;
  position: absolute;
  top: 46px;
}
.hv-callout .title {
  font-size: 1.25rem;
  line-height: 1.5;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 1.375rem;
}
.hv-callout .description {
  font-size: 1rem;
  line-height: 1.375;
}
.hv-callout .check-list {
  list-style: none;
  padding-left: 1.7rem;
}
.hv-callout .check-list li {
  font-size: 1rem;
  line-height: 1.5;
  padding-bottom: 0.5rem;
  position: relative;
}
.hv-callout .check-list li:before {
  background-image: url("/assets/images/check-mark-green.png");
  background-size: cover;
  content: "";
  display: block;
  height: 9px;
  left: -1.5rem;
  position: absolute;
  top: 5px;
  width: 12px;
}
.hv-callout .left-aligned-text {
  text-align: left;
}
.hv-callout .columns {
  display: flex;
  margin-bottom: 1rem;
  text-align: center;
  flex-wrap: wrap;
}
.hv-callout .columns .image-with-caption {
  width: 40%;
  margin: 0 auto;
}
.hv-callout .callout-description {
  font-size: 1rem;
  line-height: 1.375;
  background-color: #eee;
  padding: 0.75rem 1.5rem;
}
.hv-callout .callout-description.warning {
  position: relative;
  background-color: #ffefef;
  width: 100vw;
  left: -30px;
  padding-left: 30px;
  padding-right: 30px;
}
@media (min-width: 768px) {
  .hv-callout.has-background {
    padding: 43px 95px 50px;
  }
  .hv-callout .callout-description.warning {
    width: 100%;
    left: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .hv-callout .columns .image-with-caption {
    width: 26.6666666667%;
  }
  .hv-callout .icon-share-this {
    left: 57px;
  }
}
@media print and (min-width: 6px) {
  .hv-callout.has-background {
    padding: 43px 95px 50px;
  }
  .hv-callout .callout-description.warning {
    width: 100%;
    left: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .hv-callout .columns .image-with-caption {
    width: 26.6666666667%;
  }
  .hv-callout .icon-share-this {
    left: 57px;
  }
}
@media (min-width: 1280px) {
  .hv-callout .check-list {
    padding-left: 0;
  }
}
@media print and (min-width: 7px) {
  .hv-callout .check-list {
    padding-left: 0;
  }
}

.hv-content-nav.nav-open .nav-list-container {
  display: block;
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
  top: 0;
  transition-delay: 0s;
}
.hv-content-nav.trigger-fixed {
  height: 70px;
  margin-bottom: 2rem;
}
.hv-content-nav.trigger-fixed .mobile-nav-trigger {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 8;
  transition: top 0.2s;
}
.navbar-scroll-up .hv-content-nav.trigger-fixed .mobile-nav-trigger {
  top: 60px;
}
.navbar-scroll-down .hv-content-nav.trigger-fixed .mobile-nav-trigger {
  top: 0;
}
.body-fixed .hv-content-nav.trigger-fixed .mobile-nav-trigger {
  transition: top 0.3s ease-in-out;
}
.mobile-nav-open .hv-content-nav.trigger-fixed .mobile-nav-trigger {
  top: 100vh;
}
.hv-content-nav .mobile-nav-trigger {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  align-items: center;
  background-color: #eee;
  display: flex;
  height: 70px;
  justify-content: space-between;
  margin-bottom: 2rem;
  margin-left: -30px;
  padding: 0 30px;
  width: 100vw;
}
.hv-content-nav .nav-list-container {
  background-color: #fff;
  pointer-events: none;
  overflow: auto;
  visibility: hidden;
  opacity: 0;
  top: 20vh;
  padding-bottom: 80px;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  transition: visibility 0s ease-in-out 400ms, opacity 0.2s ease-in-out, top 0.2s ease-in-out;
  z-index: 9;
}
.hv-content-nav .mobile-nav-header {
  align-items: center;
  background-color: #eee;
  display: flex;
  height: 70px;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 0 30px;
}
.hv-content-nav .mobile-nav-header .mobile-close-container .mobile-close-btn:focus {
  outline: none !important;
}
.hv-content-nav .mobile-nav-header .nav-heading {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 0;
}
.hv-content-nav .heading-1 {
  padding: 0 30px;
}
.hv-content-nav .nav-list {
  display: flex;
  flex-direction: column;
  padding: 0 30px;
}
.hv-content-nav .nav-item {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  align-items: center;
  border-left: 2px solid #bfbfbf;
  display: flex;
  min-height: 60px;
  padding: 0.5rem 20px;
}
.hv-content-nav .nav-item a {
  color: #363534;
  text-decoration: none;
}
.hv-content-nav .nav-item.active {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  border-left-color: #363534;
}
@media (min-width: 768px) {
  .hv-content-nav.column-fixed .nav-list {
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 0;
    padding: 0 10px;
    padding: 0;
    position: fixed;
    top: 50px;
  }
  .hv-content-nav.column-end {
    margin-top: auto;
  }
  .hv-content-nav.column-end .nav-list {
    position: relative;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .hv-content-nav .nav-list {
    padding: 0;
  }
  .hv-content-nav .mobile-nav-trigger,
.hv-content-nav .mobile-nav-header,
.hv-content-nav .heading-1 {
    display: none;
  }
  .hv-content-nav .nav-list-container {
    background-color: transparent;
    display: block;
    height: auto;
    opacity: 1;
    overflow: visible;
    pointer-events: auto;
    visibility: visible;
    position: relative;
    top: auto;
    left: auto;
    width: auto;
    z-index: 0;
  }
}
@media print and (min-width: 6px) {
  .hv-content-nav.column-fixed .nav-list {
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 0;
    padding: 0 10px;
    padding: 0;
    position: fixed;
    top: 50px;
  }
  .hv-content-nav.column-end {
    margin-top: auto;
  }
  .hv-content-nav.column-end .nav-list {
    position: relative;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .hv-content-nav .nav-list {
    padding: 0;
  }
  .hv-content-nav .mobile-nav-trigger,
.hv-content-nav .mobile-nav-header,
.hv-content-nav .heading-1 {
    display: none;
  }
  .hv-content-nav .nav-list-container {
    background-color: transparent;
    display: block;
    height: auto;
    opacity: 1;
    overflow: visible;
    pointer-events: auto;
    visibility: visible;
    position: relative;
    top: auto;
    left: auto;
    width: auto;
    z-index: 0;
  }
}

.hv-footer p {
  color: #fff;
}
.hv-footer p a {
  color: #fff;
}
.hv-footer li a {
  color: #fff;
}
.hv-footer .copyright p {
  margin-bottom: 1.5rem;
}
@media (min-width: 768px) {
  .hv-footer li a, .hv-footer p a {
    text-decoration: underline;
  }
  .hv-footer li a:hover, .hv-footer li a:focus, .hv-footer p a:hover, .hv-footer p a:focus {
    text-decoration: none;
  }
}
@media print and (min-width: 6px) {
  .hv-footer li a, .hv-footer p a {
    text-decoration: underline;
  }
  .hv-footer li a:hover, .hv-footer li a:focus, .hv-footer p a:hover, .hv-footer p a:focus {
    text-decoration: none;
  }
}

.hv-header {
  position: relative;
}
.hv-header .main-header {
  background-color: #a71930;
}
.hv-header .main-header .icon-phs-logo {
  fill: #fff;
}
.hv-header .main-header .logo-subtitle {
  color: #fff;
}
.hv-header .main-navigation .icon-menu-open {
  fill: #fff;
}
.hv-header .nav-link:hover, .hv-header .nav-link:focus {
  text-decoration: none;
}
@media (min-width: 1024px) {
  .hv-header {
    z-index: 1;
  }
  .navbar-fixed .hv-header, .navbar-scroll-up .hv-header, .navbar-scroll-down .hv-header {
    position: relative;
  }
  .navbar-fixed .hv-header .main-header, .navbar-scroll-up .hv-header .main-header, .navbar-scroll-down .hv-header .main-header {
    animation: none;
    margin-top: 0;
    position: relative;
  }
  .search-open .hv-header .main-header-outer {
    display: none;
  }
  .hv-header .utility-header {
    background: linear-gradient(#363534, #000);
    display: block;
    transition: margin-top 0.3s ease-in-out;
  }
  .hv-header .utility-header .utility-navigation {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
    align-items: center;
    display: flex;
    height: 35px;
  }
}
@media (min-width: 1024px) and (min-width: 768px) {
  .hv-header .utility-header .utility-navigation {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 1024px) and (min-width: 6px) {
  .hv-header .utility-header .utility-navigation {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1024px) and (min-width: 1280px) {
  .hv-header .utility-header .utility-navigation {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1024px) and (min-width: 7px) {
  .hv-header .utility-header .utility-navigation {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1024px) and (min-width: 1440px) {
  .hv-header .utility-header .utility-navigation {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1024px) and (min-width: 1440px) {
  .hv-header .utility-header .utility-navigation {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1024px) {
  .hv-header .utility-header a {
    font-size: 0.875rem;
    line-height: 1.4285714286;
    color: #bfbfbf;
    display: inline-block;
    padding: 0.5rem 2.5rem 0.5rem 0;
    text-decoration: none;
  }
  .hv-header .utility-header a:hover, .hv-header .utility-header a:focus {
    color: #eee;
  }
}
@media (min-width: 1024px) {
  .hv-header .utility-header .about-link {
    margin-left: auto;
    padding-right: 0;
  }
}
@media (min-width: 1024px) {
  .hv-header .main-header-outer {
    background-color: rgba(167, 25, 48, 0.5);
    mix-blend-mode: multiply;
    position: relative;
  }
  .hv-header .main-header-outer:before {
    background-color: rgba(167, 25, 48, 0.5);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    mix-blend-mode: multiply;
    top: 0;
    width: 100%;
  }
  .hv-header .main-header-outer:after {
    background-color: rgba(167, 25, 48, 0.5);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    mix-blend-mode: multiply;
    top: 0;
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .hv-header .main-header {
    background-color: transparent;
    height: 100px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .hv-header .main-header .logo-subtitle {
    font-size: 22px;
  }
}
@media (min-width: 1024px) {
  .hv-header .logo-container,
.hv-header .full-nav {
    z-index: 1;
  }
}
@media (min-width: 1024px) {
  .hv-header .nav-link {
    font-size: 0.875rem;
    line-height: 1.4285714286;
    font-family: AvenirRoman, Helvetica, sans-serif;
    font-weight: normal;
    color: #fff;
  }
  .hv-header .nav-link:after {
    background-color: #fff;
  }
}
@media (min-width: 1024px) {
  .hv-header .full-nav .search-btn .icon-search {
    stroke: #fff;
  }
}
@media (min-width: 1280px) {
  .hv-header .nav-link {
    font-size: 1rem;
    line-height: 1.375;
  }
  .hv-header .main-header {
    padding-left: 30px;
    padding-right: 30px;
  }
  .hv-header .utility-header .utility-navigation {
    height: 40px;
  }
  .hv-header .utility-header a {
    font-size: 1rem;
    line-height: 1.375;
  }
}
@media print and (min-width: 7px) {
  .hv-header .nav-link {
    font-size: 1rem;
    line-height: 1.375;
  }
  .hv-header .main-header {
    padding-left: 30px;
    padding-right: 30px;
  }
  .hv-header .utility-header .utility-navigation {
    height: 40px;
  }
  .hv-header .utility-header a {
    font-size: 1rem;
    line-height: 1.375;
  }
}

.hv-hero {
  margin-bottom: 1rem;
}
.hv-hero .hero-img-container {
  height: 0;
  padding-bottom: 41.666%;
  position: relative;
}
.hv-hero .hero-img {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.hv-hero .hero-img img,
.hv-hero .hero-img source {
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
}
.hv-hero .hero-content {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  background-color: #fafafa;
  padding-top: 1rem;
  padding-bottom: 2rem;
}
@media (min-width: 768px) {
  .hv-hero .hero-content {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .hv-hero .hero-content {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .hv-hero .hero-content {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .hv-hero .hero-content {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .hv-hero .hero-content {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .hv-hero .hero-content {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.hv-hero .hero-heading {
  font-size: 1.25rem;
  line-height: 1.4;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 0.5rem;
}
.hv-hero .hero-text-and-read-more {
  margin-bottom: 1.5rem;
}
.hv-hero .hero-text {
  font-size: 0.875rem;
  line-height: 1.5714285714;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  display: inline;
  color: #363534;
}
.hv-hero .cta {
  margin-bottom: 0;
  width: 100%;
  text-decoration: none;
}
.hv-hero .cta:hover, .hv-hero .cta:focus {
  background-color: #363534;
  border-color: #363534;
  color: #fff;
  text-decoration: none;
}
.hv-hero .read-more-btn {
  font-size: 0.875rem;
  line-height: 1.5714285714;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  color: #00759a;
}
@media (min-width: 768px) {
  .hv-hero {
    position: relative;
    margin-bottom: 3rem;
  }
  .hv-hero .hero-img-container {
    height: 350px;
    padding-bottom: 0;
  }
  .hv-hero .hero-content {
    background-color: rgba(255, 255, 255, 0.85);
    position: absolute;
    top: 0;
    right: 30px;
    width: 40%;
  }
}
@media print and (min-width: 6px) {
  .hv-hero {
    position: relative;
    margin-bottom: 3rem;
  }
  .hv-hero .hero-img-container {
    height: 350px;
    padding-bottom: 0;
  }
  .hv-hero .hero-content {
    background-color: rgba(255, 255, 255, 0.85);
    position: absolute;
    top: 0;
    right: 30px;
    width: 40%;
  }
}
@media (min-width: 1024px) {
  .hv-hero .hero-img-container {
    height: 500px;
    padding-bottom: 0;
  }
  .hv-hero:first-child {
    margin-top: -100px;
  }
  .hv-hero .hero-content {
    background-color: transparent;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;
  }
  .hv-hero .hero-content-inner {
    background-color: rgba(255, 255, 255, 0.85);
    padding: 130px 30px 2rem;
    width: 345px;
    position: absolute;
    right: 130px;
  }
}

.hv-isc-results {
  margin-bottom: 4rem;
}

.hv-providers .provider-result {
  text-decoration: none;
}
@media (min-width: 768px) {
  .hv-providers {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .hv-rail .hv-providers {
    display: block;
  }
  .hv-rail .hv-providers .provider-result {
    display: flex;
    background-color: transparent;
    margin-bottom: 0;
    padding-bottom: 1rem;
  }
  .hv-rail .hv-providers .provider-result .profile-image-outer,
.hv-rail .hv-providers .provider-result .profile-image-container {
    width: 65px;
  }
  .hv-rail .hv-providers .provider-result .provider-details {
    padding: 0 0 0 10px;
    width: auto;
    justify-content: flex-start;
  }
  .hv-rail .hv-providers .provider-result .provider-name {
    font-size: 0.875rem;
    line-height: 1.4285714286;
  }
  .hv-rail .hv-providers .provider-result .detail-top .group-and-specialty {
    font-size: 0.75rem;
    line-height: 1.5;
    flex-basis: 100%;
  }
  .hv-rail .hv-providers .provider-result .ratings {
    margin-top: 0.375rem;
  }
  .hv-rail .hv-providers .provider-result .ratings .rating-stars {
    width: 70px;
  }
  .hv-rail .hv-providers .provider-result .ratings .star {
    width: 11px;
    height: 11px;
  }
  .hv-rail .hv-providers .provider-result .ratings .star svg {
    height: 11px;
    width: 11px;
  }
  .hv-rail .hv-providers .provider-result .ratings .star .star-fill {
    height: 11px;
  }
  .hv-rail .hv-providers .provider-result .ratings .average-rating,
.hv-rail .hv-providers .provider-result .ratings .rating-count {
    display: none;
  }
  .hv-providers .provider-result {
    flex: 0 1 calc(50% - 0.5rem);
  }
  .hv-providers .provider-result:only-child {
    flex: 1;
  }
  .hv-providers .provider-result:hover .provider-name, .hv-providers .provider-result:focus .provider-name {
    text-decoration: underline;
  }
}
@media print and (min-width: 6px) {
  .hv-providers {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .hv-rail .hv-providers {
    display: block;
  }
  .hv-rail .hv-providers .provider-result {
    display: flex;
    background-color: transparent;
    margin-bottom: 0;
    padding-bottom: 1rem;
  }
  .hv-rail .hv-providers .provider-result .profile-image-outer,
.hv-rail .hv-providers .provider-result .profile-image-container {
    width: 65px;
  }
  .hv-rail .hv-providers .provider-result .provider-details {
    padding: 0 0 0 10px;
    width: auto;
    justify-content: flex-start;
  }
  .hv-rail .hv-providers .provider-result .provider-name {
    font-size: 0.875rem;
    line-height: 1.4285714286;
  }
  .hv-rail .hv-providers .provider-result .detail-top .group-and-specialty {
    font-size: 0.75rem;
    line-height: 1.5;
    flex-basis: 100%;
  }
  .hv-rail .hv-providers .provider-result .ratings {
    margin-top: 0.375rem;
  }
  .hv-rail .hv-providers .provider-result .ratings .rating-stars {
    width: 70px;
  }
  .hv-rail .hv-providers .provider-result .ratings .star {
    width: 11px;
    height: 11px;
  }
  .hv-rail .hv-providers .provider-result .ratings .star svg {
    height: 11px;
    width: 11px;
  }
  .hv-rail .hv-providers .provider-result .ratings .star .star-fill {
    height: 11px;
  }
  .hv-rail .hv-providers .provider-result .ratings .average-rating,
.hv-rail .hv-providers .provider-result .ratings .rating-count {
    display: none;
  }
  .hv-providers .provider-result {
    flex: 0 1 calc(50% - 0.5rem);
  }
  .hv-providers .provider-result:only-child {
    flex: 1;
  }
  .hv-providers .provider-result:hover .provider-name, .hv-providers .provider-result:focus .provider-name {
    text-decoration: underline;
  }
}
@media (min-width: 1024px) {
  .hv-rail .hv-providers .provider-result .profile-image-outer,
.hv-rail .hv-providers .provider-result .profile-image-container {
    width: 75px;
  }
  .hv-rail .hv-providers .provider-result .provider-details {
    padding: 0 20px;
  }
}

.hv-search-and-filter {
  margin-bottom: 2rem;
}
.hv-search-and-filter .toggle-container {
  height: 2.375rem;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
}
.hv-search-and-filter .toggle-label {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
}
.hv-search-and-filter .search-form {
  padding: 1rem 2rem 1.5rem;
}
.hv-search-and-filter .filter-label {
  font-size: 0.875rem;
  line-height: 2.8571428571;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
}
.hv-search-and-filter .input-with-icon {
  position: relative;
  height: 3rem;
  margin-bottom: 0.25rem;
}
.hv-search-and-filter .input-with-icon .icon {
  position: absolute;
  top: 50%;
  right: 1.25rem;
  transform: translateY(-50%);
  stroke: #707070;
}
.hv-search-and-filter .filter-input {
  font-size: 1rem;
  line-height: 1.375;
  background-color: #fff;
  border-color: #fff;
  color: #000;
  height: 3rem;
  text-align: left;
  padding-left: 1rem;
}
.hv-search-and-filter .filter-input:focus {
  background-color: #fff;
}
.hv-search-and-filter .filter-input::placeholder {
  color: #707070;
  text-align: left;
}
.hv-search-and-filter .select-with-icon {
  height: 3rem;
  position: relative;
  margin-bottom: 0.5rem;
}
.hv-search-and-filter .select-with-icon .icon {
  position: absolute;
  top: 50%;
  right: 1.25rem;
  transform: translateY(-50%);
}
.hv-search-and-filter .filter-select {
  font-size: 1rem;
  line-height: 1.375;
  padding: 10px 16px;
  appearance: none;
  background-color: #fff;
  border-color: #fff;
  color: #000;
  height: 3rem;
  width: 100%;
  margin-bottom: 0.75rem;
}
@media (min-width: 1280px) {
  .hv-search-and-filter .toggle-container {
    padding: 0 1.25rem;
  }
  .hv-search-and-filter .search-form {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .hv-search-and-filter .filter-control {
    flex-grow: 4;
    padding-right: 1.25rem;
  }
  .hv-search-and-filter .filter-control:last-child {
    flex-grow: 3;
    padding-right: 0;
  }
}
@media print and (min-width: 7px) {
  .hv-search-and-filter .toggle-container {
    padding: 0 1.25rem;
  }
  .hv-search-and-filter .search-form {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .hv-search-and-filter .filter-control {
    flex-grow: 4;
    padding-right: 1.25rem;
  }
  .hv-search-and-filter .filter-control:last-child {
    flex-grow: 3;
    padding-right: 0;
  }
}

.hv-awards-and-accreditations {
  border-bottom: 1px solid #bfbfbf;
  padding-bottom: 2.5rem;
  margin-bottom: 2.5rem;
}
.hv-awards-and-accreditations .title {
  margin-bottom: 0.75rem;
}
.hv-awards-and-accreditations .awards-paragraph {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 0.5rem;
}
.hv-awards-and-accreditations .read-more-link {
  color: #00759a;
  border-right: 1px solid #bfbfbf;
  height: 16px;
  padding-right: 0.5rem;
  text-decoration: none;
}
.hv-awards-and-accreditations .read-more-link:hover {
  text-decoration: underline;
}
.hv-awards-and-accreditations .award-site {
  text-decoration: none;
}
.hv-awards-and-accreditations .award-site:hover {
  text-decoration: underline;
}
.hv-awards-and-accreditations .award-site-inner {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: #00759a;
  padding-left: 0.5rem;
}
.hv-awards-and-accreditations .icon-arrow-down {
  height: 0.4rem;
  stroke: #00759a;
  stroke-width: 3px;
}
.hv-awards-and-accreditations .icon-external {
  height: 0.5rem;
  stroke: #00759a;
}

/* NDGE Portal */
.claims-date-filter {
  position: relative;
}
.claims-date-filter .claim-button-input.filter-input {
  align-items: center;
  color: #707070;
  display: flex;
  padding-right: 30px;
}
.claims-date-filter .claim-button-input.filter-input .date-input {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  border-radius: 2px;
  background-color: #e5f1f5;
  color: #006dac;
  height: auto;
  text-align: center;
  padding: 5px;
  pointer-events: none;
  margin: 0 5px 0 0;
  min-width: 0;
  border: none;
}
.claims-date-filter .claim-button-input.filter-input .icon {
  position: absolute;
  height: 18px;
  width: 18px;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.claims-date-filter .date-filter-container {
  align-items: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.29);
  position: absolute;
  margin: 1.25rem -2rem 0 0;
  background: #fff;
  border: 1px solid #d5d6d2;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  left: 50%;
  text-align: center;
  top: 100%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 9;
}
.claims-date-filter .date-filter-container:before {
  display: block;
  content: " ";
  width: 0;
  height: 0;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-bottom: 18px solid #d5d6d2;
  position: absolute;
  margin-right: 10px;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
}
.claims-date-filter .date-filter-container:after {
  display: block;
  content: " ";
  width: 0;
  height: 0;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid #fff;
  position: absolute;
  margin-right: 10px;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
}
.claims-date-filter .filter-tabs {
  background-color: #eee;
  border: 1px solid #bfbfbf;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  padding: 0.125rem;
}
.claims-date-filter .filter-tab {
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 15px;
  padding: 0.125rem 1rem;
}
.claims-date-filter .filter-tab.active {
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  color: #fff;
  background-color: #006dac;
}
.claims-date-filter .tab-content {
  position: relative;
  padding: 1rem;
}
.claims-date-filter .year-container {
  display: flex;
  padding: 0 2rem;
}
.claims-date-filter .year-title {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
  margin-bottom: 1.25rem;
}
.claims-date-filter .DayPickerNavigation {
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  position: absolute;
  top: 0;
  width: 100%;
}
.claims-date-filter .year-nav-btns {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  position: absolute;
  left: 0;
  top: 1rem;
  width: 100%;
  z-index: 2;
  pointer-events: none;
}
.claims-date-filter .year-nav-btns .year-nav-btn {
  pointer-events: auto;
}
.claims-date-filter .calendar-btn {
  display: flex;
  background-color: #fff;
  border: 1px solid #bfbfbf;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  transform: translateY(17px);
}
.claims-date-filter .calendar-btn .icon {
  height: 0.5rem;
  width: 0.5rem;
}
.claims-date-filter .calendar-btn.double .icon {
  width: 0.25rem;
}
.claims-date-filter .calendar-btn.left .icon {
  transform: rotate(180deg);
}
.claims-date-filter .CalendarDay__selected {
  background: #d5d6d2;
  border: 1px solid #e4e7e7;
  color: #000;
}
.claims-date-filter .CalendarDay__selected:hover {
  background: #707070;
  border: 1px solid #e4e7e7;
  color: #000;
}
.claims-date-filter .CalendarDay__selected_span {
  background: #eee;
  border: 1px solid #e4e7e7;
  color: #000;
}
.claims-date-filter .CalendarDay__hovered_span:hover,
.claims-date-filter .CalendarDay__hovered_span {
  background: #eee;
  border: 1px solid #e4e7e7;
  color: #000;
}
.claims-date-filter .month-text {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
}
.claims-date-filter .radio-wrapper input[type=radio] + label:before {
  border: 1px solid #bfbfbf;
}
@media (min-width: 768px) {
  .claims-date-filter .date-filter-container {
    width: 650px;
  }
}
@media print and (min-width: 6px) {
  .claims-date-filter .date-filter-container {
    width: 650px;
  }
}

.PresetDateRangePicker_panel {
  padding: 0 22px 11px;
}

.PresetDateRangePicker_button {
  position: relative;
  height: 100%;
  text-align: center;
  background: 0 0;
  border: 2px solid #00a699;
  color: #00a699;
  padding: 4px 12px;
  margin-right: 8px;
  font: inherit;
  font-weight: 700;
  line-height: normal;
  overflow: visible;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}

.PresetDateRangePicker_button:active {
  outline: 0;
}

.PresetDateRangePicker_button__selected {
  color: #fff;
  background: #00a699;
}

.SingleDatePickerInput {
  display: inline-block;
  background-color: #fff;
}

.SingleDatePickerInput__withBorder {
  border-radius: 2px;
  border: 1px solid #dbdbdb;
}

.SingleDatePickerInput__rtl {
  direction: rtl;
}

.SingleDatePickerInput__disabled {
  background-color: #f2f2f2;
}

.SingleDatePickerInput__block {
  display: block;
}

.SingleDatePickerInput__showClearDate {
  padding-right: 30px;
}

.SingleDatePickerInput_clearDate {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
  background: #dbdbdb;
  border-radius: 50%;
}

.SingleDatePickerInput_clearDate__small {
  padding: 6px;
}

.SingleDatePickerInput_clearDate__hide {
  visibility: hidden;
}

.SingleDatePickerInput_clearDate_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle;
}

.SingleDatePickerInput_clearDate_svg__small {
  height: 9px;
}

.SingleDatePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px;
}

.SingleDatePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle;
}

.SingleDatePicker {
  position: relative;
  display: inline-block;
}

.SingleDatePicker__block {
  display: block;
}

.SingleDatePicker_picker {
  z-index: 1;
  background-color: #fff;
  position: absolute;
}

.SingleDatePicker_picker__rtl {
  direction: rtl;
}

.SingleDatePicker_picker__directionLeft {
  left: 0;
}

.SingleDatePicker_picker__directionRight {
  right: 0;
}

.SingleDatePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.SingleDatePicker_picker__fullScreenPortal {
  background-color: #fff;
}

.SingleDatePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2;
}

.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
  color: #b0b3b4;
  text-decoration: none;
}

.SingleDatePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd;
}

.DayPickerKeyboardShortcuts_buttonReset {
  background: 0 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  font-size: 14px;
}

.DayPickerKeyboardShortcuts_buttonReset:active {
  outline: 0;
}

.DayPickerKeyboardShortcuts_show {
  width: 33px;
  height: 26px;
  position: absolute;
  z-index: 2;
}

.DayPickerKeyboardShortcuts_show::before {
  content: "";
  display: block;
  position: absolute;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  bottom: 0;
  right: 0;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-top: 26px solid transparent;
  border-right: 33px solid #00a699;
  bottom: 0;
  right: 0;
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
  border-right: 33px solid #008489;
}

.DayPickerKeyboardShortcuts_show__topRight {
  top: 0;
  right: 0;
}

.DayPickerKeyboardShortcuts_show__topRight::before {
  border-bottom: 26px solid transparent;
  border-right: 33px solid #00a699;
  top: 0;
  right: 0;
}

.DayPickerKeyboardShortcuts_show__topRight:hover::before {
  border-right: 33px solid #008489;
}

.DayPickerKeyboardShortcuts_show__topLeft {
  top: 0;
  left: 0;
}

.DayPickerKeyboardShortcuts_show__topLeft::before {
  border-bottom: 26px solid transparent;
  border-left: 33px solid #00a699;
  top: 0;
  left: 0;
}

.DayPickerKeyboardShortcuts_show__topLeft:hover::before {
  border-left: 33px solid #008489;
}

.DayPickerKeyboardShortcuts_showSpan {
  color: #fff;
  position: absolute;
}

.DayPickerKeyboardShortcuts_showSpan__bottomRight {
  bottom: 0;
  right: 5px;
}

.DayPickerKeyboardShortcuts_showSpan__topRight {
  top: 1px;
  right: 5px;
}

.DayPickerKeyboardShortcuts_showSpan__topLeft {
  top: 1px;
  left: 5px;
}

.DayPickerKeyboardShortcuts_panel {
  overflow: auto;
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  padding: 22px;
  margin: 33px;
  text-align: left;
}

.DayPickerKeyboardShortcuts_title {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}

.DayPickerKeyboardShortcuts_list {
  list-style: none;
  padding: 0;
  font-size: 14px;
}

.DayPickerKeyboardShortcuts_close {
  position: absolute;
  right: 22px;
  top: 22px;
  z-index: 2;
}

.DayPickerKeyboardShortcuts_close:active {
  outline: 0;
}

.DayPickerKeyboardShortcuts_closeSvg {
  height: 15px;
  width: 15px;
  fill: #cacccd;
}

.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
  fill: #82888a;
}

.CalendarDay {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}

.CalendarDay:active {
  outline: 0;
}

.CalendarDay__defaultCursor {
  cursor: default;
}

.CalendarDay__default {
  border: 1px solid #e4e7e7;
  color: #484848;
  background: #fff;
}

.CalendarDay__default:hover {
  background: #e4e7e7;
  border: 1px solid #e4e7e7;
  color: inherit;
}

.CalendarDay__hovered_offset {
  background: #f4f5f5;
  border: 1px double #e4e7e7;
  color: inherit;
}

.CalendarDay__outside {
  border: 0;
  background: #fff;
  color: #484848;
}

.CalendarDay__outside:hover {
  border: 0;
}

.CalendarDay__blocked_minimum_nights {
  background: #fff;
  border: 1px solid #eceeee;
  color: #cacccd;
}

.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
  background: #fff;
  color: #cacccd;
}

.CalendarDay__highlighted_calendar {
  background: #ffe8bc;
  color: #484848;
}

.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
  background: #ffce71;
  color: #484848;
}

.CalendarDay__selected_span {
  background: #66e2da;
  border: 1px double #33dacd;
  color: #fff;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #33dacd;
  border: 1px double #33dacd;
  color: #fff;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #00a699;
  border: 1px double #00a699;
  color: #fff;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #b2f1ec;
  border: 1px double #80e8e0;
  color: #007a87;
}

.CalendarDay__hovered_span:active {
  background: #80e8e0;
  border: 1px double #80e8e0;
  color: #007a87;
}

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: #cacccd;
  border: 1px solid #cacccd;
  color: #82888a;
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: #fff;
  border: 1px solid #e4e7e7;
  color: #cacccd;
}

.CalendarDay__hovered_start_first_possible_end {
  background: #eceeee;
  border: 1px double #eceeee;
}

.CalendarDay__hovered_start_blocked_min_nights {
  background: #eceeee;
  border: 1px double #e4e7e7;
}

.CalendarMonth {
  background: #fff;
  text-align: center;
  vertical-align: top;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.CalendarMonth_table {
  border-collapse: collapse;
  border-spacing: 0;
}

.CalendarMonth_verticalSpacing {
  border-collapse: separate;
}

.CalendarMonth_caption {
  color: #484848;
  font-size: 18px;
  text-align: center;
  padding-top: 22px;
  padding-bottom: 37px;
  caption-side: initial;
}

.CalendarMonth_caption__verticalScrollable {
  padding-top: 12px;
  padding-bottom: 7px;
}

.CalendarMonthGrid {
  background: #fff;
  text-align: left;
  z-index: 0;
}

.CalendarMonthGrid__animating {
  z-index: 1;
}

.CalendarMonthGrid__horizontal {
  position: absolute;
  left: 9px;
}

.CalendarMonthGrid__vertical,
.CalendarMonthGrid__vertical_scrollable {
  margin: 0 auto;
}

.CalendarMonthGrid_month__horizontal {
  display: inline-block;
  vertical-align: top;
  min-height: 100%;
}

.CalendarMonthGrid_month__hideForAnimation {
  position: absolute;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}

.CalendarMonthGrid_month__hidden {
  visibility: hidden;
}

.DayPickerNavigation {
  position: relative;
  z-index: 2;
}

.DayPickerNavigation__horizontal {
  height: 0;
}

.DayPickerNavigation__verticalScrollable_prevNav {
  z-index: 1;
}

.DayPickerNavigation__verticalDefault {
  position: absolute;
  width: 100%;
  height: 52px;
  bottom: 0;
  left: 0;
}

.DayPickerNavigation__verticalScrollableDefault {
  position: relative;
}

.DayPickerNavigation__bottom {
  height: auto;
}

.DayPickerNavigation__bottomDefault {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.DayPickerNavigation_button {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  padding: 0;
  margin: 0;
}

.DayPickerNavigation_button__default {
  border: 1px solid #e4e7e7;
  background-color: #fff;
  color: #757575;
}

.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
  border: 1px solid #c4c4c4;
}

.DayPickerNavigation_button__default:active {
  background: #f2f2f2;
}

.DayPickerNavigation_button__disabled {
  cursor: default;
  border: 1px solid #f2f2f2;
}

.DayPickerNavigation_button__disabled:focus,
.DayPickerNavigation_button__disabled:hover {
  border: 1px solid #f2f2f2;
}

.DayPickerNavigation_button__disabled:active {
  background: 0 0;
}

.DayPickerNavigation_button__horizontalDefault {
  position: absolute;
  top: 18px;
  line-height: 0.78;
  border-radius: 3px;
  padding: 6px 9px;
}

.DayPickerNavigation_bottomButton__horizontalDefault {
  position: static;
  margin: -10px 22px 30px;
}

.DayPickerNavigation_leftButton__horizontalDefault {
  left: 22px;
}

.DayPickerNavigation_rightButton__horizontalDefault {
  right: 22px;
}

.DayPickerNavigation_button__verticalDefault {
  padding: 5px;
  background: #fff;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  display: inline-block;
  text-align: center;
  height: 100%;
  width: 50%;
}

.DayPickerNavigation_nextButton__verticalDefault {
  border-left: 0;
}

.DayPickerNavigation_nextButton__verticalScrollableDefault,
.DayPickerNavigation_prevButton__verticalScrollableDefault {
  width: 100%;
}

.DayPickerNavigation_svg__horizontal {
  height: 19px;
  width: 19px;
  fill: #82888a;
  display: block;
}

.DayPickerNavigation_svg__vertical {
  height: 42px;
  width: 42px;
  fill: #484848;
}

.DayPickerNavigation_svg__disabled {
  fill: #f2f2f2;
}

.DayPicker {
  background: #fff;
  position: relative;
  text-align: left;
}

.DayPicker__horizontal {
  background: #fff;
}

.DayPicker__verticalScrollable {
  height: 100%;
}

.DayPicker__hidden {
  visibility: hidden;
}

.DayPicker__withBorder {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
  border-radius: 3px;
}

.DayPicker_portal__horizontal {
  box-shadow: none;
  position: absolute;
  left: 50%;
  top: 50%;
}

.DayPicker_portal__vertical {
  position: initial;
}

.DayPicker_focusRegion {
  outline: 0;
}

.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
  display: inline-block;
  vertical-align: top;
}

.DayPicker_weekHeaders {
  position: relative;
}

.DayPicker_weekHeaders__horizontal {
  margin-left: 9px;
}

.DayPicker_weekHeader {
  color: #757575;
  position: absolute;
  top: 62px;
  z-index: 2;
  text-align: left;
}

.DayPicker_weekHeader__vertical {
  left: 50%;
}

.DayPicker_weekHeader__verticalScrollable {
  top: 0;
  display: table-row;
  border-bottom: 1px solid #dbdbdb;
  background: #fff;
  margin-left: 0;
  left: 0;
  width: 100%;
  text-align: center;
}

.DayPicker_weekHeader_ul {
  list-style: none;
  margin: 1px 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
}

.DayPicker_weekHeader_li {
  display: inline-block;
  text-align: center;
}

.DayPicker_transitionContainer {
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}

.DayPicker_transitionContainer__horizontal {
  -webkit-transition: height 0.2s ease-in-out;
  -moz-transition: height 0.2s ease-in-out;
  transition: height 0.2s ease-in-out;
}

.DayPicker_transitionContainer__vertical {
  width: 100%;
}

.DayPicker_transitionContainer__verticalScrollable {
  padding-top: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: scroll;
}

.DateInput {
  margin: 0;
  padding: 0;
  background: #fff;
  position: relative;
  display: inline-block;
  width: 130px;
  vertical-align: middle;
}

.DateInput__small {
  width: 97px;
}

.DateInput__block {
  width: 100%;
}

.DateInput__disabled {
  background: #f2f2f2;
  color: #dbdbdb;
}

.DateInput_input {
  font-weight: 200;
  font-size: 19px;
  line-height: 24px;
  color: #484848;
  background-color: #fff;
  width: 100%;
  padding: 11px 11px 9px;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid transparent;
  border-left: 0;
  border-radius: 0;
}

.DateInput_input__small {
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.2px;
  padding: 7px 7px 5px;
}

.DateInput_input__regular {
  font-weight: auto;
}

.DateInput_input__readOnly {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.DateInput_input__focused {
  outline: 0;
  background: #fff;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid #008489;
  border-left: 0;
}

.DateInput_input__disabled {
  background: #f2f2f2;
  font-style: italic;
}

.DateInput_screenReaderMessage {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.DateInput_fang {
  position: absolute;
  width: 20px;
  height: 10px;
  left: 22px;
  z-index: 2;
}

.DateInput_fangShape {
  fill: #fff;
}

.DateInput_fangStroke {
  stroke: #dbdbdb;
  fill: transparent;
}

.DateRangePickerInput {
  background-color: #fff;
  display: inline-block;
}

.DateRangePickerInput__disabled {
  background: #f2f2f2;
}

.DateRangePickerInput__withBorder {
  border-radius: 2px;
  border: 1px solid #dbdbdb;
}

.DateRangePickerInput__rtl {
  direction: rtl;
}

.DateRangePickerInput__block {
  display: block;
}

.DateRangePickerInput__showClearDates {
  padding-right: 30px;
}

.DateRangePickerInput_arrow {
  display: inline-block;
  vertical-align: middle;
  color: #484848;
}

.DateRangePickerInput_arrow_svg {
  vertical-align: middle;
  fill: #484848;
  height: 24px;
  width: 24px;
}

.DateRangePickerInput_clearDates {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.DateRangePickerInput_clearDates__small {
  padding: 6px;
}

.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
  background: #dbdbdb;
  border-radius: 50%;
}

.DateRangePickerInput_clearDates__hide {
  visibility: hidden;
}

.DateRangePickerInput_clearDates_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle;
}

.DateRangePickerInput_clearDates_svg__small {
  height: 9px;
}

.DateRangePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px;
}

.DateRangePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle;
}

.DateRangePicker {
  position: relative;
  display: inline-block;
}

.DateRangePicker__block {
  display: block;
}

.DateRangePicker_picker {
  z-index: 1;
  background-color: #fff;
  position: absolute;
}

.DateRangePicker_picker__rtl {
  direction: rtl;
}

.DateRangePicker_picker__directionLeft {
  left: 0;
}

.DateRangePicker_picker__directionRight {
  right: 0;
}

.DateRangePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.DateRangePicker_picker__fullScreenPortal {
  background-color: #fff;
}

.DateRangePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2;
}

.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
  color: #b0b3b4;
  text-decoration: none;
}

.DateRangePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd;
}

.logout-modal {
  text-align: center;
}
.logout-modal .modal-header, .logout-modal .echeckin-modal-header,
.logout-modal .modal-close-btn {
  display: none;
}
.logout-modal .modal-container {
  justify-content: center;
}
.logout-modal .logout-title {
  font-size: 1.875rem;
  line-height: 1.2;
  color: #a71930;
  margin-bottom: 1rem;
}
.logout-modal .logout-title .time-remaining {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  display: block;
  margin-top: 1rem;
}
.logout-modal p {
  font-size: 1rem;
  line-height: 1.375;
}
.logout-modal .btn-remain {
  background-color: #363534;
  border-color: #363534;
  color: #fff;
  width: 100%;
}
.logout-modal .btn-remain:hover, .logout-modal .btn-remain:focus {
  background-color: #000;
  border-color: #000;
}
@media (min-width: 768px) {
  .logout-modal .logout-title {
    font-size: 2.5rem;
    line-height: 1.2;
  }
  .logout-modal .btn-remain {
    min-width: 240px;
    width: auto;
  }
}
@media print and (min-width: 6px) {
  .logout-modal .logout-title {
    font-size: 2.5rem;
    line-height: 1.2;
  }
  .logout-modal .btn-remain {
    min-width: 240px;
    width: auto;
  }
}
@media (min-width: 1280px) {
  .logout-modal .modal-header, .logout-modal .echeckin-modal-header {
    display: block;
  }
}
@media print and (min-width: 7px) {
  .logout-modal .modal-header, .logout-modal .echeckin-modal-header {
    display: block;
  }
}

.claims-overview .claims-summary {
  margin-bottom: 30px;
}
.claims-overview .claims-summary-inner {
  background-color: #fafafa;
  margin-bottom: 2rem;
  margin-left: -30px;
  margin-right: -30px;
  padding: 1.25rem 30px;
}
.claims-overview .claims-summary-inner dt,
.claims-overview .claims-summary-inner dd {
  font-size: 0.875rem;
  line-height: 1.4285714286;
}
.claims-overview .claims-summary-inner dd {
  margin-bottom: 0.625rem;
}
.claims-overview .total-claims {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
  margin-bottom: 1rem;
}
.claims-overview .claims-wrap .mobile-filters {
  background-color: #fff;
  display: none;
  height: 100%;
  left: 0;
  overflow: auto;
  pointer-events: none;
  position: fixed;
  width: 100%;
  z-index: 11;
}
.claims-overview .claims-wrap .mobile-filters.filters-open {
  display: block;
  pointer-events: auto;
  top: 0;
}
.claims-overview .claims-wrap .mobile-filters .mobile-filter-header {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  align-items: center;
  background-color: #363534;
  color: #363534;
  display: flex;
  height: 54px;
  justify-content: center;
  position: relative;
}
@media (min-width: 768px) {
  .claims-overview .claims-wrap .mobile-filters .mobile-filter-header {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .claims-overview .claims-wrap .mobile-filters .mobile-filter-header {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .claims-overview .claims-wrap .mobile-filters .mobile-filter-header {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .claims-overview .claims-wrap .mobile-filters .mobile-filter-header {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .claims-overview .claims-wrap .mobile-filters .mobile-filter-header {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .claims-overview .claims-wrap .mobile-filters .mobile-filter-header {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.claims-overview .claims-wrap .mobile-filters .mobile-filter-header .mobile-close-btn {
  font-size: 1.875rem;
  line-height: 1;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  background: none;
  border: none;
  color: #595756;
  line-height: 0;
  margin-left: -1rem;
  padding: 1rem;
  transform: translateX(1rem);
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
}
.claims-overview .claims-wrap .mobile-filters .mobile-filter-header .mobile-close-btn .icon {
  height: 0.75rem;
  stroke: #fff;
  width: 0.75rem;
}
.claims-overview .claims-wrap .mobile-filters .mobile-filter-header .mobile-close-btn:hover, .claims-overview .claims-wrap .mobile-filters .mobile-filter-header .mobile-close-btn:focus {
  outline: 0;
}
.claims-overview .claims-wrap .mobile-filters .mobile-filter-header .mobile-close-btn:hover .icon, .claims-overview .claims-wrap .mobile-filters .mobile-filter-header .mobile-close-btn:focus .icon {
  fill: #00759a;
}
.claims-overview .claims-wrap .mobile-filters .mobile-filter-header .filter-heading {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  color: #fff;
  margin: 0;
}
.claims-overview .claims-wrap .mobile-filters .table-filters-inner {
  padding: 30px;
}
.claims-overview .claims-wrap .mobile-filters .download .mobile-filter-label {
  text-align: center;
}
.claims-overview .claims-wrap .mobile-filters .download .btn-outline {
  color: #000;
  margin-bottom: 0.75rem;
  width: 100%;
}
.claims-overview .claims-wrap .mobile-filters .download .btn-outline + .mobile-filter-label {
  margin: 2rem 0 0;
}
.claims-overview .claims-wrap .mobile-filters .mobile-clear-btn-container {
  text-align: center;
}
.claims-overview .claims-wrap .mobile-filters .mobile-clear-btn-container .mobile-clear-btn {
  text-decoration: underline;
}
.claims-overview .claims-wrap .mobile-filters .sort-radio-set {
  margin-bottom: 1.75rem;
}
.claims-overview .claims-wrap .mobile-filters .radio-wrapper input[type=radio] + label:before {
  border: 1px solid #bfbfbf;
}
.claims-overview .claims-wrap .mobile-filters .DayPicker_transitionContainer {
  height: 600px !important;
}
.claims-overview .claims-wrap .table-filters {
  display: none;
}
.claims-overview .data-summary {
  border: none;
  margin-bottom: 0;
}
.claims-overview .data-summary .data-section {
  background-color: transparent;
  border-right: none;
  flex-basis: 50%;
  max-width: 50%;
  padding: 1.375rem 1.875rem;
}
.claims-overview .data-summary .data-section dt {
  width: 40%;
}
.claims-overview .data-summary .data-section dd {
  flex-direction: column;
  width: 60%;
}
.claims-overview .data-summary .data-section dd.has-icon {
  display: flex;
  flex-direction: row;
}
@media (min-width: 768px) {
  .claims-overview .claims-summary {
    background-color: #fafafa;
    display: flex;
    padding: 20px;
  }
  .claims-overview .total-and-summary-container {
    background-color: #fafafa;
    display: flex;
    margin-bottom: 0.625rem;
    padding: 1.5rem 1.25rem;
  }
  .claims-overview .claims-summary-inner {
    background-color: transparent;
    display: flex;
    flex-grow: 1;
    margin: 0;
    padding: 0;
  }
  .claims-overview .claims-summary-inner dl {
    flex-grow: 1;
  }
  .claims-overview .total-claims {
    display: flex;
    margin-right: 100px;
  }
  .claims-overview .claims-wrap .table-filters {
    align-items: center;
    display: flex;
    margin-bottom: 1.25rem;
    margin-top: 2rem;
  }
  .claims-overview .claims-wrap .label-filter {
    margin-right: 1.5rem;
  }
  .claims-overview .claims-wrap .table-filters-inner {
    display: flex;
    flex: 1;
    justify-content: space-between;
  }
  .claims-overview .claims-wrap .table-filters-inner > * {
    flex: 0 1 calc(25% - 3px);
    max-width: calc(25% - 3px);
  }
  .claims-overview .claims-wrap .claims-mobile-container {
    display: none;
  }
  .claims-overview .claims-wrap .input-icon-container .icon-search {
    margin: 0;
    stroke: #707070;
  }
  .claims-overview .claims-wrap .new-drop-down__button,
.claims-overview .claims-wrap .filter-input {
    margin-bottom: 0;
  }
  .claims-overview .claims-wrap .download-wrap {
    margin-left: 1.5rem;
    position: relative;
  }
  .claims-overview .claims-wrap .download-wrap:hover {
    cursor: pointer;
  }
  .claims-overview .claims-wrap .download-wrap .icon.download {
    height: 20px;
    stroke: #363534;
    stroke-width: 1px;
    width: 20px;
  }
  .claims-overview .claims-wrap .download-wrap .download-header,
.claims-overview .claims-wrap .download-wrap .btn-container {
    display: block;
  }
  .claims-overview .claims-wrap .link-button {
    color: #000;
    margin: auto;
    margin-bottom: 0.5rem;
    width: 90%;
  }
  .claims-overview .claims-wrap .link-button:focus, .claims-overview .claims-wrap .link-button:hover, .claims-overview .claims-wrap .link-button:active {
    color: #fff;
  }
  .claims-overview .claims-wrap .link-button a {
    text-decoration: solid #00b0e6;
  }
}
@media print and (min-width: 6px) {
  .claims-overview .claims-summary {
    background-color: #fafafa;
    display: flex;
    padding: 20px;
  }
  .claims-overview .total-and-summary-container {
    background-color: #fafafa;
    display: flex;
    margin-bottom: 0.625rem;
    padding: 1.5rem 1.25rem;
  }
  .claims-overview .claims-summary-inner {
    background-color: transparent;
    display: flex;
    flex-grow: 1;
    margin: 0;
    padding: 0;
  }
  .claims-overview .claims-summary-inner dl {
    flex-grow: 1;
  }
  .claims-overview .total-claims {
    display: flex;
    margin-right: 100px;
  }
  .claims-overview .claims-wrap .table-filters {
    align-items: center;
    display: flex;
    margin-bottom: 1.25rem;
    margin-top: 2rem;
  }
  .claims-overview .claims-wrap .label-filter {
    margin-right: 1.5rem;
  }
  .claims-overview .claims-wrap .table-filters-inner {
    display: flex;
    flex: 1;
    justify-content: space-between;
  }
  .claims-overview .claims-wrap .table-filters-inner > * {
    flex: 0 1 calc(25% - 3px);
    max-width: calc(25% - 3px);
  }
  .claims-overview .claims-wrap .claims-mobile-container {
    display: none;
  }
  .claims-overview .claims-wrap .input-icon-container .icon-search {
    margin: 0;
    stroke: #707070;
  }
  .claims-overview .claims-wrap .new-drop-down__button,
.claims-overview .claims-wrap .filter-input {
    margin-bottom: 0;
  }
  .claims-overview .claims-wrap .download-wrap {
    margin-left: 1.5rem;
    position: relative;
  }
  .claims-overview .claims-wrap .download-wrap:hover {
    cursor: pointer;
  }
  .claims-overview .claims-wrap .download-wrap .icon.download {
    height: 20px;
    stroke: #363534;
    stroke-width: 1px;
    width: 20px;
  }
  .claims-overview .claims-wrap .download-wrap .download-header,
.claims-overview .claims-wrap .download-wrap .btn-container {
    display: block;
  }
  .claims-overview .claims-wrap .link-button {
    color: #000;
    margin: auto;
    margin-bottom: 0.5rem;
    width: 90%;
  }
  .claims-overview .claims-wrap .link-button:focus, .claims-overview .claims-wrap .link-button:hover, .claims-overview .claims-wrap .link-button:active {
    color: #fff;
  }
  .claims-overview .claims-wrap .link-button a {
    text-decoration: solid #00b0e6;
  }
}

.ngde-header {
  z-index: 10;
}
.ngde-header.no-plan-switch .plan-switch {
  display: none;
}
.ngde-header .skip-link {
  position: absolute;
  top: -134px;
  background: #fff;
  padding: 5px;
}
.ngde-header .skip-link:focus {
  top: 0;
  z-index: 10;
}
.ngde-header .select-wrapper-members {
  display: none;
}
.ngde-header .collapsed {
  height: 0px !important;
}
.ngde-header .fix {
  position: fixed;
  right: 0;
  width: 100%;
}
.ngde-header .hide {
  display: none;
}
.ngde-header .collapse {
  overflow: hidden;
  flex: 0;
}
.ngde-header .ngde-portal-header {
  z-index: 2;
}
.ngde-header .ngde-select-column {
  display: none;
}
.ngde-header .links-container,
.ngde-header .user-span,
.ngde-header .utility-separator {
  display: none;
}
.ngde-header .logo-container .icon-phs-logo {
  fill: #fff;
  height: 16px;
  width: 141px;
}
.ngde-header .ngde-utility-header {
  background-color: #363534;
  display: flex;
  height: 65px;
  overflow: hidden;
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.ngde-header .ngde-utility-header .utility-navigation {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  display: flex;
}
@media (min-width: 768px) {
  .ngde-header .ngde-utility-header .utility-navigation {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .ngde-header .ngde-utility-header .utility-navigation {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .ngde-header .ngde-utility-header .utility-navigation {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .ngde-header .ngde-utility-header .utility-navigation {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .ngde-header .ngde-utility-header .utility-navigation {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .ngde-header .ngde-utility-header .utility-navigation {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.ngde-header .ngde-utility-header .utility-right {
  margin-left: auto;
}
.ngde-header .ngde-utility-header .logo-container {
  align-items: center;
  display: flex;
  padding-right: 2rem;
}
.ngde-header .ngde-utility-header .language {
  display: flex;
  align-items: center;
  /* padding-right: 1rem; */
}
.ngde-header .ngde-utility-header .language select {
  background-color: #363534;
  color: #fff;
  width: 200px;
  /* padding: 0.4em 1em; */
}
.ngde-header .ngde-utility-header .language .arrow-icon-wrapper .arrow-down {
  stroke: #fff;
}
.ngde-header .ngde-utility-header .language-span {
  color: #fff;
  display: inline-block;
  padding: 1rem 0rem 1rem 0;
  text-decoration: none;
}
.ngde-header .ngde-utility-header .globe-icon-wrapper {
  display: flex;
  align-items: center;
  padding: 0;
  text-decoration: none;
}
.ngde-header .ngde-utility-header .globe-icon-wrapper .icon-geolocate {
  stroke: #fff;
}
.ngde-header .ngde-utility-header .select-wrapper-language {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #363534;
  position: relative;
}
.ngde-header .ngde-utility-header .select-wrapper-language .arrow-icon-wrapper {
  pointer-events: none;
  position: absolute;
  right: 1.25rem;
  top: 30%;
}
.ngde-header .ngde-utility-header .select-wrapper-language .arrow-icon-wrapper .arrow-down {
  stroke: #fff;
}
.ngde-header .ngde-utility-header .select-wrapper-language:focus-within .arrow-down {
  transform: rotate(180deg);
  transition: 0.2s ease;
}
.ngde-header .ngde-utility-header .select-wrapper-language .select-title {
  /* @include text(mu); */
  font-size: 1rem;
  background-color: #363534;
  color: #fff;
}
.ngde-header .ngde-utility-header .select-wrapper-language select {
  cursor: pointer;
  padding-left: 5px;
  padding-right: 0px;
}
.ngde-header .ngde-utility-header .select-wrapper-language select:active {
  outline: none;
}
.ngde-header .ngde-utility-header .select-wrapper-language option:hover {
  background-color: #fff;
  color: #000;
}
.ngde-header .user-profile-link-wrapper {
  display: flex;
  align-items: center;
}
.ngde-header .user-profile-link-wrapper .user-icon-notification {
  stroke: none;
}
.ngde-header .user-profile-link-wrapper .arrow-icon-wrapper {
  margin-left: 0.5em;
}
.ngde-header .user-profile-link-wrapper .arrow-icon-wrapper .arrow-down {
  stroke: white;
}
.ngde-header .profile-menu-button {
  display: block;
}
.ngde-header .profile-menu-button .icon {
  stroke-width: 0px;
}
.ngde-header .top-mobile-menu-banner {
  position: fixed;
  width: 100%;
  background: #363534;
  padding: 1rem 0;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  z-index: 2;
}
.ngde-header .top-mobile-menu-banner .menu-title {
  color: white;
}
.ngde-header .top-mobile-menu-banner .icon {
  stroke: white;
}
.ngde-header .top-mobile-menu-select-container {
  padding: 1.5rem 30px;
  background: #eee;
  margin-top: 60px;
}
.ngde-header .top-mobile-menu-select-container select {
  margin: 0.5rem 0;
}
.ngde-header .top-mobile-menu-select-container .select-wrapper-plan .arrow-icon-wrapper {
  top: 38%;
}
.ngde-header .main-header-outer .title-container {
  z-index: 1;
}
.ngde-header .main-header-outer .title-container a {
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.875rem;
  line-height: 1;
  color: #fff;
}
.ngde-header .main-header-outer .title-container .nav-link {
  padding-left: 0;
}
.ngde-header .main-header-outer .title-container .nav-link:hover::after, .ngde-header .main-header-outer .title-container .nav-link:active::after, .ngde-header .main-header-outer .title-container .nav-link:focus::after, .ngde-header .main-header-outer .title-container .nav-link.active::after {
  opacity: 0;
  left: 18px;
}
.ngde-header .main-header-outer .title-container .icon.icon-mypres-member-logo {
  fill: white;
  width: 186px;
  top: -15px;
}
.ngde-header .main-header-mobile {
  display: flex;
}
.ngde-header .main-header-mobile .icon.icon-menu-open {
  fill: white;
}
@media (min-width: 1280px) {
  .ngde-header .main-header-mobile {
    display: none;
  }
}
@media print and (min-width: 7px) {
  .ngde-header .main-header-mobile {
    display: none;
  }
}
.ngde-header .main-header-desktop {
  display: none;
}
@media (min-width: 1280px) {
  .ngde-header .main-header-desktop {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
    display: flex;
  }
}
@media (min-width: 1280px) and (min-width: 768px) {
  .ngde-header .main-header-desktop {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 1280px) and (min-width: 6px) {
  .ngde-header .main-header-desktop {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) and (min-width: 1280px) {
  .ngde-header .main-header-desktop {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1280px) and (min-width: 7px) {
  .ngde-header .main-header-desktop {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1280px) and (min-width: 1440px) {
  .ngde-header .main-header-desktop {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1280px) and (min-width: 1440px) {
  .ngde-header .main-header-desktop {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .ngde-header .main-header-desktop {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
    display: flex;
  }
}
@media print and (min-width: 7px) and (min-width: 768px) {
  .ngde-header .main-header-desktop {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 7px) and (min-width: 6px) {
  .ngde-header .main-header-desktop {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 7px) and (min-width: 1280px) {
  .ngde-header .main-header-desktop {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) and (min-width: 7px) {
  .ngde-header .main-header-desktop {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) and (min-width: 1440px) {
  .ngde-header .main-header-desktop {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) and (min-width: 1440px) {
  .ngde-header .main-header-desktop {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.ngde-header .mobile-profile-menu .mobile-nav-container {
  padding-top: 50px;
}
.ngde-header .mobile-nav-link-divider {
  width: 100%;
  height: 1px;
  background: #bfbfbf;
}
.ngde-header .mobile-nav .nav-links li {
  padding: 1rem 0;
}
.ngde-header .mobile-nav-bottom-links {
  margin: 2rem 0;
}
.ngde-header .mobile-nav-bottom-links a {
  text-decoration-color: #00759a;
}
.ngde-header .mobile-nav-bottom-links .mobile-nav-bottom-links-list-item {
  padding: 0.75rem 0;
  color: #00759a;
}
.ngde-header .ngde-personalization-background {
  height: 45px;
  overflow: hidden;
  position: relative;
  transition: height 0.3s;
}
.ngde-header .ngde-personalization-background .ngde-personalization-bar {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  height: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
@media (min-width: 768px) {
  .ngde-header .ngde-personalization-background .ngde-personalization-bar {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .ngde-header .ngde-personalization-background .ngde-personalization-bar {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .ngde-header .ngde-personalization-background .ngde-personalization-bar {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .ngde-header .ngde-personalization-background .ngde-personalization-bar {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .ngde-header .ngde-personalization-background .ngde-personalization-bar {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .ngde-header .ngde-personalization-background .ngde-personalization-bar {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.ngde-header .ngde-personalization-background .ngde-personalization-bar .row {
  height: 100%;
}
.ngde-header .ngde-personalization-background .ngde-personalization-bar .plan-switch select {
  padding: 0.6125rem 1rem;
}
.ngde-header .ngde-portal-header {
  background-color: rgba(0, 0, 0, 0.4);
  display: block;
}
.ngde-header .ngde-portal-header .welcome-text-column {
  display: flex;
  align-items: center;
}
.ngde-header .ngde-portal-header .welcome-text-column .welcome-message-text {
  font-family: AvenirLight, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.5;
  color: #fff;
  margin-bottom: 0;
}
.ngde-header .user-profile-navigation-links {
  background: #363534;
  position: absolute;
  margin-left: -50px;
  z-index: 3;
  border-radius: 3px;
  list-style: none;
}
.ngde-header .user-profile-navigation-links li {
  margin: 1em;
}
.ngde-header .user-profile-navigation-links li button {
  color: white;
}
.ngde-header .messages-list-item {
  display: flex;
}
.ngde-header .notification-number {
  background: #a71930;
  border-radius: 90px;
  height: 15px;
  width: 15px;
  padding: 0.6rem;
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
@media (min-width: 1024px) {
  .ngde-header .profile-menu-button,
.ngde-header .nav-open {
    z-index: 2;
  }
}
@media (min-width: 1280px) {
  .ngde-header .profile-menu-button,
.ngde-header .nav-open {
    display: none;
  }
  .ngde-header .ngde-portal-header {
    display: block;
  }
  .ngde-header .ngde-portal-header .welcome-text-column .welcome-message-text {
    font-size: 1.5rem;
    line-height: 1.3333333333;
  }
  .ngde-header .ngde-select-column {
    display: block;
  }
  .ngde-header .col-2.ngde-select-column .select-wrapper {
    display: none;
  }
  .ngde-header .ngde-utility-header {
    transition: height 0.3s;
  }
  .ngde-header .ngde-utility-header .utility-navigation {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
    align-items: center;
    display: flex;
  }
}
@media (min-width: 1280px) and (min-width: 768px) {
  .ngde-header .ngde-utility-header .utility-navigation {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 1280px) and (min-width: 6px) {
  .ngde-header .ngde-utility-header .utility-navigation {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) and (min-width: 1280px) {
  .ngde-header .ngde-utility-header .utility-navigation {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1280px) and (min-width: 7px) {
  .ngde-header .ngde-utility-header .utility-navigation {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1280px) and (min-width: 1440px) {
  .ngde-header .ngde-utility-header .utility-navigation {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1280px) and (min-width: 1440px) {
  .ngde-header .ngde-utility-header .utility-navigation {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1280px) {
  .ngde-header .ngde-utility-header .utility-right {
    display: flex;
    justify-content: flex-end;
    width: 40%;
  }
}
@media (min-width: 1280px) {
  .ngde-header .ngde-utility-header .links-container {
    display: flex;
    width: 100%;
  }
}
@media (min-width: 1280px) {
  .ngde-header .ngde-utility-header .user-span {
    color: #fff;
    display: inline-block;
    padding: 1rem 1.2rem 1rem 0;
    text-decoration: none;
  }
  .ngde-header .ngde-utility-header .user-span:hover, .ngde-header .ngde-utility-header .user-span:focus {
    color: #eee;
  }
  .ngde-header .ngde-utility-header .user-span a {
    color: white;
    text-decoration: none;
    cursor: pointer;
  }
}
@media (min-width: 1280px) {
  .ngde-header .ngde-utility-header .utility-separator {
    color: #fff;
    display: flex;
    padding: 0rem 1rem 0rem 1rem;
    text-decoration: none;
    align-items: center;
  }
}
@media (min-width: 1280px) {
  .ngde-header .ngde-utility-header a {
    color: white;
  }
}
@media (min-width: 1280px) {
  .ngde-header .ngde-select-column {
    display: block;
  }
}
@media (min-width: 1280px) {
  .ngde-header .ngde-personalization-background {
    height: 70px;
  }
  .ngde-header .ngde-personalization-background .ngde-personalization-bar {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
  }
}
@media (min-width: 1280px) {
  .ngde-header .main-header-outer .title-container {
    z-index: 1;
  }
  .ngde-header .main-header-outer .title-container a {
    font-family: AvenirMedium, Helvetica, sans-serif;
    font-weight: normal;
    font-size: 1.875rem;
    line-height: 1;
    color: #fff;
  }
  .ngde-header .main-header-outer .title-container .nav-link {
    padding-left: 0;
  }
  .ngde-header .main-header-outer .title-container .nav-link::after {
    background-color: rgba(255, 255, 255, 0);
  }
  .ngde-header .main-header-outer .title-container .icon.icon-mypres-member-logo {
    fill: white;
    width: 260px;
    top: -25px;
  }
}
@media print and (min-width: 7px) {
  .ngde-header .profile-menu-button,
.ngde-header .nav-open {
    display: none;
  }
  .ngde-header .ngde-portal-header {
    display: block;
  }
  .ngde-header .ngde-portal-header .welcome-text-column .welcome-message-text {
    font-size: 1.5rem;
    line-height: 1.3333333333;
  }
  .ngde-header .ngde-select-column {
    display: block;
  }
  .ngde-header .col-2.ngde-select-column .select-wrapper {
    display: none;
  }
  .ngde-header .ngde-utility-header {
    transition: height 0.3s;
  }
  .ngde-header .ngde-utility-header .utility-navigation {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
    align-items: center;
    display: flex;
  }
}
@media print and (min-width: 7px) and (min-width: 768px) {
  .ngde-header .ngde-utility-header .utility-navigation {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 7px) and (min-width: 6px) {
  .ngde-header .ngde-utility-header .utility-navigation {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 7px) and (min-width: 1280px) {
  .ngde-header .ngde-utility-header .utility-navigation {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) and (min-width: 7px) {
  .ngde-header .ngde-utility-header .utility-navigation {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) and (min-width: 1440px) {
  .ngde-header .ngde-utility-header .utility-navigation {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) and (min-width: 1440px) {
  .ngde-header .ngde-utility-header .utility-navigation {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .ngde-header .ngde-utility-header .utility-right {
    display: flex;
    justify-content: flex-end;
    width: 40%;
  }
}
@media print and (min-width: 7px) {
  .ngde-header .ngde-utility-header .links-container {
    display: flex;
    width: 100%;
  }
}
@media print and (min-width: 7px) {
  .ngde-header .ngde-utility-header .user-span {
    color: #fff;
    display: inline-block;
    padding: 1rem 1.2rem 1rem 0;
    text-decoration: none;
  }
  .ngde-header .ngde-utility-header .user-span:hover, .ngde-header .ngde-utility-header .user-span:focus {
    color: #eee;
  }
  .ngde-header .ngde-utility-header .user-span a {
    color: white;
    text-decoration: none;
    cursor: pointer;
  }
}
@media print and (min-width: 7px) {
  .ngde-header .ngde-utility-header .utility-separator {
    color: #fff;
    display: flex;
    padding: 0rem 1rem 0rem 1rem;
    text-decoration: none;
    align-items: center;
  }
}
@media print and (min-width: 7px) {
  .ngde-header .ngde-utility-header a {
    color: white;
  }
}
@media print and (min-width: 7px) {
  .ngde-header .ngde-select-column {
    display: block;
  }
}
@media print and (min-width: 7px) {
  .ngde-header .ngde-personalization-background {
    height: 70px;
  }
  .ngde-header .ngde-personalization-background .ngde-personalization-bar {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
  }
}
@media print and (min-width: 7px) {
  .ngde-header .main-header-outer .title-container {
    z-index: 1;
  }
  .ngde-header .main-header-outer .title-container a {
    font-family: AvenirMedium, Helvetica, sans-serif;
    font-weight: normal;
    font-size: 1.875rem;
    line-height: 1;
    color: #fff;
  }
  .ngde-header .main-header-outer .title-container .nav-link {
    padding-left: 0;
  }
  .ngde-header .main-header-outer .title-container .nav-link::after {
    background-color: rgba(255, 255, 255, 0);
  }
  .ngde-header .main-header-outer .title-container .icon.icon-mypres-member-logo {
    fill: white;
    width: 260px;
    top: -25px;
  }
}

.ngde-portal-cards-grid {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
}
@media (min-width: 768px) {
  .ngde-portal-cards-grid {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .ngde-portal-cards-grid {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .ngde-portal-cards-grid {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .ngde-portal-cards-grid {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .ngde-portal-cards-grid {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .ngde-portal-cards-grid {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.ngde-portal-cards-grid .campaign-portlet {
  background-color: #eee;
  padding-bottom: 2.2rem;
}
.ngde-portal-cards-grid .campaign-portlet .heading-2 {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  padding: 0.5rem 2rem;
}
.ngde-portal-cards-grid .campaign-portlet .section-description {
  padding: 0rem 2rem;
  margin-bottom: 0.4rem;
}
.ngde-portal-cards-grid .campaign-portlet .text-center {
  text-align: left;
  padding: 0rem 2rem;
}
.ngde-portal-cards-grid .icon-external-secondary {
  height: 0.5rem;
  margin-left: 0.25rem;
  stroke: #00759a;
  width: 0.5rem;
}
.grid-row .ngde-portal-cards-grid {
  padding: 0;
}
.ngde-portal-cards-grid .portal-id-card-col.loading {
  height: 300px;
  background-color: #fff;
}
.ngde-portal-cards-grid.article-list .portal-card-col {
  margin-top: 0;
  margin-bottom: 0;
}
.ngde-portal-cards-grid.article-list .grid-row {
  margin-bottom: 2rem;
}
.ngde-portal-cards-grid.article-list h2,
.ngde-portal-cards-grid.article-list .heading-2 {
  color: #363534;
  margin-bottom: 0.25rem;
}
.ngde-portal-cards-grid.article-list .btn {
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
}
.ngde-portal-cards-grid.article-list .btn:hover {
  text-decoration: none;
}
.ngde-portal-cards-grid h2,
.ngde-portal-cards-grid h2.heading-2 {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 1rem;
}
.ngde-portal-cards-grid h3,
.ngde-portal-cards-grid .heading-3 {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 0.5rem;
}
.ngde-portal-cards-grid p,
.ngde-portal-cards-grid p.section-description {
  font-size: 0.875rem;
  line-height: 1.4285714286;
}
.ngde-portal-cards-grid .grid-row {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: 5rem;
}
.ngde-portal-cards-grid .btn {
  width: 100%;
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  background-color: #363534;
  color: white;
  padding: 1.125rem;
  white-space: normal;
}
.ngde-portal-cards-grid .btn.auto-width {
  width: auto;
}
.ngde-portal-cards-grid .btn:hover {
  text-decoration: none;
}
.ngde-portal-cards-grid .deductible-chart-details {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}
.ngde-portal-cards-grid .deductible-chart-details .deductible-chart-details-text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -20px;
  padding-left: 22px;
  padding-right: 0;
}
.ngde-portal-cards-grid .btn:hover {
  background-color: #222;
}
.ngde-portal-cards-grid .portal-picture-card.loading .loading-overlay-section {
  opacity: 1;
}
.ngde-portal-cards-grid .portal-picture-card-img {
  height: 200px;
  object-fit: cover;
  width: 100%;
  margin-bottom: 1rem;
}
.ngde-portal-cards-grid .portal-logo-card-img {
  height: 200px;
  object-fit: contain;
  width: 100%;
  margin-bottom: 1rem;
}
.ngde-portal-cards-grid .large-portal-picture-card-img {
  height: 300px;
  object-fit: cover;
  width: 100%;
  margin-bottom: 1rem;
}
.ngde-portal-cards-grid .no-stretch-image-portal-picture-card-img {
  height: 200px;
  margin-bottom: 1rem;
  padding: 0 20px;
}
.ngde-portal-cards-grid .phs-logo-wrap .icon-phs-logo {
  fill: #a71930;
  margin-bottom: 0.5rem;
}
.ngde-portal-cards-grid .icon-external {
  height: 0.5rem;
  margin-left: 0.25rem;
  stroke: white;
  width: 0.5rem;
}

.svg-content-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.svg-content-wrap .svg-container {
  padding-right: 1rem;
  margin-bottom: 1rem;
}

.member-card-details {
  padding: 0rem 5px;
  margin-bottom: 1rem;
}
.member-card-details h2 {
  margin-bottom: 0.5rem;
}

.portal-card-col {
  margin-top: -80px;
  margin-bottom: 7rem;
}
.portal-card-col.loading .loading-overlay-section {
  opacity: 1;
}

.portal-id-card {
  padding: 5px;
  border-radius: 7px;
  box-shadow: 0px -5px 23px 4px rgba(0, 0, 0, 0.15);
  background-color: white;
  margin-bottom: 2.5rem;
}
.portal-id-card .patient-details {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: 1rem;
}

.next-visit-card {
  padding: 2rem;
  border-radius: 7px;
  background: #eee;
  margin-bottom: 2rem;
}

.card-link {
  color: #00759a;
  font-size: 0.875rem;
  line-height: 1.4285714286;
}

.ngde-my-account {
  padding-top: 155px;
}
.ngde-my-account .hidden {
  display: none;
}
.ngde-my-account .first-header {
  font-size: 2.5rem;
  line-height: 1;
  padding-bottom: 75px;
}
.ngde-my-account input[type=text],
.ngde-my-account input[type=password] {
  background-color: #eee;
  max-width: 360px;
  padding: 1.25rem;
  width: 100%;
}
.ngde-my-account input[type=text]::placeholder,
.ngde-my-account input[type=password]::placeholder {
  text-align: left;
}
.ngde-my-account .form .form-control button {
  max-width: 360px;
  width: 360px;
  text-align: center;
}
.ngde-my-account .form .form-control button:disabled:hover {
  background-color: #fff;
  border-color: #bfbfbf;
  color: #bfbfbf;
}
.ngde-my-account .reset-button {
  text-align: center;
  max-width: 360px;
  width: 360px;
}
.ngde-my-account .form-message {
  background-color: #d9edf7;
  border-bottom: 2px solid #d9edf7;
  display: flex;
  padding: 1.25rem 2.25rem;
  margin-bottom: 1rem;
}
.ngde-my-account .form-message #reset-password-response {
  font-size: 1.125rem;
  line-height: 1;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 0;
}
.ngde-my-account .form-message .icon {
  height: 1rem;
  width: 1rem;
  margin-right: 0.5rem;
}
.ngde-my-account .form-message.success {
  background-color: #dff0d8;
}
.ngde-my-account .form-message.error {
  background-color: #f2dede;
}
.ngde-my-account .reset-password-form label.input-label {
  position: relative;
  display: inline-block;
  margin: 0;
  width: 100%;
}
.ngde-my-account .reset-password-form label.input-label > .icon {
  position: absolute;
  top: 40%;
  right: 15px;
  transform: translateY(-50%);
  color: silver;
  z-index: 100;
}
.ngde-my-account .reset-password-form label.input-label > input {
  padding-left: calc(1em + 10px + 8px);
  width: 100%;
  max-width: none;
}
.ngde-my-account .reset-password-form .rules-description {
  font-size: 1rem;
  line-height: 1.375;
  margin: 1rem 0 0;
}
.ngde-my-account .reset-password-form .form-control {
  max-width: none;
}
.ngde-my-account .reset-password-form .form-control .submit-button,
.ngde-my-account .reset-password-form .form-control .reset-button {
  max-width: none;
  width: 100%;
}
.ngde-my-account .reset-password-form .valid,
.ngde-my-account .reset-password-form .invalid {
  margin-top: 0.25rem;
}
.ngde-my-account .reset-password-form .checklist-icon {
  height: 1rem;
  width: 1rem;
  margin-top: 2px;
}
.ngde-my-account .reset-password-form .new-password-input.invalid-password {
  border-bottom-color: #a71930;
}
.ngde-my-account .reset-password-form .new-password-input.valid-password {
  border-bottom-color: #86bf6b;
}
.ngde-my-account .reset-password-form .submit-button {
  border-color: #363534;
  background-color: #363534;
  color: #fff;
  margin-top: 1rem;
  width: 100%;
}
.ngde-my-account .reset-password-form .submit-button:hover, .ngde-my-account .reset-password-form .submit-button:focus {
  border-color: #000;
  background-color: #000;
  cursor: pointer;
}
.ngde-my-account .reset-password-form .submit-button[disabled] {
  background-color: #fff;
  border-color: #bfbfbf;
  color: #bfbfbf;
}
.ngde-my-account .reset-password-form .submit-button[disabled]:hover, .ngde-my-account .reset-password-form .submit-button[disabled]:focus {
  background-color: #fff;
  border-color: #bfbfbf;
  color: #bfbfbf;
  cursor: default;
}
.ngde-my-account .reset-password-form .reset-button:hover {
  cursor: pointer;
}
.ngde-my-account .my-account-title {
  font-size: 1.5rem;
  line-height: 1.3333333333;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
  margin-bottom: 1rem;
}
.ngde-my-account .my-account-description {
  font-size: 1rem;
  line-height: 1.375;
}
.ngde-my-account .my-account-description .btn-link {
  font-size: 1rem;
  line-height: 1.375;
}
.ngde-my-account .btn-outline {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  padding: 18px 22px;
  margin-top: 0.25rem;
}
.ngde-my-account .create-account-providers,
.ngde-my-account .password-options {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  max-width: 360px;
  text-align: center;
}
.ngde-my-account .create-account .btn {
  color: #fff;
  border: 0;
  background: #363534;
}
.ngde-my-account .create-account .create-account-desc-list {
  font-size: 1rem;
  line-height: 1.375;
  list-style-type: disc;
  margin-left: 1rem;
  margin-bottom: 2rem;
}
.ngde-my-account .account-help-text {
  margin-top: 6rem;
  margin-bottom: 2rem;
}
.ngde-my-account .account-help-text p {
  font-size: 0.75rem;
  line-height: 1.5;
  margin-bottom: 0.25rem;
  text-align: center;
}
@media (min-width: 768px) {
  .ngde-my-account .centered-two-column {
    display: flex;
    margin: 0 auto;
    max-width: 1100px;
  }
  .ngde-my-account .centered-two-column .create-account {
    flex-basis: 55%;
    max-width: 55%;
    flex-grow: 1;
  }
  .ngde-my-account .centered-two-column .portal-login {
    flex-basis: 45%;
    max-width: 45%;
    flex-grow: 1;
  }
  .ngde-my-account .centered-two-column .portal-login .my-account-description {
    max-width: 360px;
  }
  .ngde-my-account .create-account {
    border-right: 1px solid #eee;
    margin-right: 3rem;
    margin-bottom: 3rem;
    padding-right: 4rem;
  }
}
@media print and (min-width: 6px) {
  .ngde-my-account .centered-two-column {
    display: flex;
    margin: 0 auto;
    max-width: 1100px;
  }
  .ngde-my-account .centered-two-column .create-account {
    flex-basis: 55%;
    max-width: 55%;
    flex-grow: 1;
  }
  .ngde-my-account .centered-two-column .portal-login {
    flex-basis: 45%;
    max-width: 45%;
    flex-grow: 1;
  }
  .ngde-my-account .centered-two-column .portal-login .my-account-description {
    max-width: 360px;
  }
  .ngde-my-account .create-account {
    border-right: 1px solid #eee;
    margin-right: 3rem;
    margin-bottom: 3rem;
    padding-right: 4rem;
  }
}
@media (min-width: 1280px) {
  .ngde-my-account .create-account {
    margin-right: 5rem;
    margin-bottom: 5rem;
    padding-right: 6rem;
  }
  .ngde-my-account input[type=text],
.ngde-my-account input[type=password] {
    width: 360px;
  }
  .ngde-my-account .btn {
    width: 360px;
  }
}
@media print and (min-width: 7px) {
  .ngde-my-account .create-account {
    margin-right: 5rem;
    margin-bottom: 5rem;
    padding-right: 6rem;
  }
  .ngde-my-account input[type=text],
.ngde-my-account input[type=password] {
    width: 360px;
  }
  .ngde-my-account .btn {
    width: 360px;
  }
}

.ngde-benefits-and-coverage {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  padding-top: 300px;
}
@media (min-width: 768px) {
  .ngde-benefits-and-coverage {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .ngde-benefits-and-coverage {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .ngde-benefits-and-coverage {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .ngde-benefits-and-coverage {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .ngde-benefits-and-coverage {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .ngde-benefits-and-coverage {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.ngde-benefits-and-coverage .claim-summary-card {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}
.ngde-benefits-and-coverage .claim-id a {
  font-size: 2.5rem;
  line-height: 1;
  color: #a71930;
  text-decoration: none;
}
.ngde-benefits-and-coverage .grid-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}
.ngde-benefits-and-coverage h2 {
  font-size: 1.875rem;
  line-height: 1;
}
.ngde-benefits-and-coverage h3 {
  font-size: 1.625rem;
  line-height: 1.2307692308;
}

.ngde-bills-and-claims {
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 168px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
}
@media (min-width: 768px) {
  .ngde-bills-and-claims {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .ngde-bills-and-claims {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .ngde-bills-and-claims {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .ngde-bills-and-claims {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .ngde-bills-and-claims {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .ngde-bills-and-claims {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.ngde-bills-and-claims.no-padding {
  padding-top: 0 !important;
}
.ngde-bills-and-claims .back-link {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #000;
  display: inline-block;
  margin-bottom: 1rem;
}
.ngde-bills-and-claims .back-link .icon-arrow-right {
  height: 8.9px;
  margin-right: 0.5rem;
  transform: rotate(180deg);
  width: 4.45px;
}
.ngde-bills-and-claims .collapsed {
  display: none !important;
}
.ngde-bills-and-claims .plan-inactive {
  display: none;
}
.ngde-bills-and-claims.set-active .plan-inactive {
  display: none;
}
.ngde-bills-and-claims.set-active .plan-active,
.ngde-bills-and-claims.set-active .plan-list-active {
  display: block;
}
.ngde-bills-and-claims.set-inactive .plan-inactive {
  display: block;
}
.ngde-bills-and-claims.set-inactive .plan-active,
.ngde-bills-and-claims.set-inactive .plan-list-active {
  display: none;
}
.ngde-bills-and-claims a {
  color: #00759a;
  text-decoration: none;
}
.ngde-bills-and-claims a li {
  margin-bottom: 0.5rem;
}
.ngde-bills-and-claims .grid-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}
.ngde-bills-and-claims .grid-row section {
  overflow: hidden;
  width: 100%;
}
.ngde-bills-and-claims .claims-wrap {
  margin-bottom: 4rem;
  position: relative;
}
.ngde-bills-and-claims .claims-wrap.loading {
  min-height: 400px;
  pointer-events: none;
}
.ngde-bills-and-claims .claims-wrap.loading .hide-while-loading,
.ngde-bills-and-claims .claims-wrap.loading .total-and-summary-container {
  display: none;
}
.ngde-bills-and-claims .claims-wrap.loading .loading-overlay {
  opacity: 1;
}
.ngde-bills-and-claims .claims-wrap .loading-overlay {
  background-color: transparent;
  padding-top: 150px;
}
.ngde-bills-and-claims .mobile-card-list .mobile-card-list-item {
  padding-bottom: 1.25rem;
}
.ngde-bills-and-claims .portal-table {
  display: none;
}
.ngde-bills-and-claims .in-network-icon {
  width: 19px;
  height: 18px;
  margin-right: 0.5rem;
}
.ngde-bills-and-claims .mobile-claim-card {
  border: solid 1px #d5d6d2;
  padding: 1.625rem 1.375rem;
}
.ngde-bills-and-claims .mobile-claim-card > div {
  display: flex;
  flex-flow: row;
  margin-bottom: 0.3125rem;
}
.ngde-bills-and-claims .mobile-claim-card a,
.ngde-bills-and-claims .mobile-claim-card dt,
.ngde-bills-and-claims .mobile-claim-card dd {
  font-size: 0.875rem;
  line-height: 1.4285714286;
}
.ngde-bills-and-claims .mobile-claim-card dt {
  margin-right: 0.25rem;
}
.ngde-bills-and-claims .mobile-claim-card dd {
  overflow: hidden;
  text-overflow: ellipsis;
}
.ngde-bills-and-claims .mobile-claim-card a {
  text-decoration: underline;
}
.ngde-bills-and-claims .mobile-claim-card .view-claim-detail {
  border-bottom: 1px solid #d5d6d2;
  display: block;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}
.ngde-bills-and-claims .claims-mobile-container {
  display: flex;
  justify-content: space-between;
}
.ngde-bills-and-claims .claims-mobile-filters .mobile-filter-btn {
  margin-left: 0.75rem;
}
.ngde-bills-and-claims .claims-mobile-filters .mobile-filter-btn .icon-download-sm {
  height: 15.89px;
  width: 14px;
}
.ngde-bills-and-claims .claim-summary-title {
  font-size: 1.25rem;
  line-height: 1.5;
  margin-bottom: 1rem;
}
.ngde-bills-and-claims .claim-details-title {
  font-size: 1.125rem;
  line-height: 1.3333333333;
}
.ngde-bills-and-claims .claim-details-heading {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.ngde-bills-and-claims .claim-details {
  padding: 0 10px;
  width: 100%;
}
.ngde-bills-and-claims .claims-data .data-summary {
  margin-left: -20px;
}
.ngde-bills-and-claims .claims-data .data-summary .data-section {
  padding-bottom: 0;
}
.ngde-bills-and-claims .downloads-col {
  background: white;
}
.ngde-bills-and-claims .download-tab {
  background: #fff;
  border: 1px solid #d5d6d2;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.29);
  margin: 1.25rem -2rem 0 0;
  padding: 1rem 40px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 100%;
  width: 275px;
  z-index: 9;
}
.ngde-bills-and-claims .download-tab:before {
  border-bottom: 18px solid #d5d6d2;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  content: " ";
  display: block;
  height: 0;
  margin-right: 10px;
  position: absolute;
  right: 2rem;
  top: 0;
  transform: translate(50%, -100%);
  width: 0;
}
.ngde-bills-and-claims .download-tab:after {
  border-bottom: 16px solid #fff;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  content: " ";
  display: block;
  height: 0;
  margin-right: 10px;
  position: absolute;
  right: 2rem;
  top: 0;
  transform: translate(50%, -100%);
  width: 0;
}
.ngde-bills-and-claims .download-content h4,
.ngde-bills-and-claims .download-content .heading-4 {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
  margin-bottom: 1rem;
}
.ngde-bills-and-claims .download-content .btn-container {
  font-size: 1rem;
  margin-bottom: 0.625rem;
  text-align: center;
}
.ngde-bills-and-claims .download-content .download-header {
  font-size: 0.75rem;
  line-height: 1.5;
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
  margin-bottom: 0.25rem;
  margin-top: 1.5rem;
}
.ngde-bills-and-claims .download-content .download-header-secondary {
  font-size: 10px;
}
.ngde-bills-and-claims .download-content input {
  margin: auto;
  width: 90%;
}
.ngde-bills-and-claims .drop-down__list-item {
  align-items: flex-start;
  display: flex;
  margin-bottom: 10px;
}
.ngde-bills-and-claims .drop-down__list-item.disabled > input {
  display: none;
}
.ngde-bills-and-claims .drop-down__list-item.disabled > label {
  margin-left: 0;
}
.ngde-bills-and-claims .drop-down__list-item:first-child {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
}
.ngde-bills-and-claims .drop-down__list-item.title-divider {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  border-top: 1px solid #bfbfbf;
  box-sizing: content-box;
  margin-top: 1rem;
  padding-top: 0.75rem;
}
.ngde-bills-and-claims .drop-down__list-item > input {
  height: 20px;
  margin: 0;
  min-width: 20px;
  width: 20px;
}
.ngde-bills-and-claims .drop-down__list-item > input + label {
  margin-left: 0.5rem;
}
.ngde-bills-and-claims .drop-down__list-item > label {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #707070;
  margin-left: 0;
}
.ngde-bills-and-claims .new-drop-down__button {
  font-size: 1rem;
  line-height: 1.375;
  border: 1px solid #bfbfbf;
  color: #707070;
  cursor: pointer;
  font-family: inherit;
  margin-bottom: 1rem;
  padding: 0.5rem;
  position: relative;
  text-align: left;
  width: 100%;
}
.ngde-bills-and-claims .label-filter {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
  line-height: 2.5;
  padding: 1rem;
}
.ngde-bills-and-claims .drop-down-icon {
  align-items: center;
  display: flex;
  height: 18px;
  justify-content: center;
  position: absolute;
  right: 0.75rem;
  stroke: #707070;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
}
.ngde-bills-and-claims .drop-down-icon--is-rotate {
  transform: rotate(180deg) translateY(50%);
}
.ngde-bills-and-claims .new-drop-down {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  display: none;
  position: relative;
  width: 100%;
  z-index: 1;
}
.ngde-bills-and-claims button.new-drop-down__button.new-drop-down--is-open {
  color: #363534;
}
.ngde-bills-and-claims button.new-drop-down__button.new-drop-down--is-open span {
  color: #363534;
}
.ngde-bills-and-claims .new-drop-down__list-wrapper {
  background: white;
  border: 1px solid #bfbfbf;
  border: 1px solid #eee;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  padding: 1rem;
  position: absolute;
  top: 45px;
  width: 100%;
}
.ngde-bills-and-claims .mobile-member-list {
  margin: 10px 0 50px;
}
.ngde-bills-and-claims .mobile-member-list .drop-down__list-item {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.ngde-bills-and-claims .mobile-member-list .drop-down__list-item.disabled, .ngde-bills-and-claims .mobile-member-list .drop-down__list-item.title-divider {
  flex-direction: row;
}
.ngde-bills-and-claims .mobile-member-list .drop-down__list-item > input + label {
  margin-left: 0;
}
.ngde-bills-and-claims .mobile-filter-label {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
  display: block;
  margin-bottom: 0.5rem;
}
.ngde-bills-and-claims .member-list {
  background-color: #eee;
  margin: 0 -30px;
  padding: 1rem 30px;
}
.ngde-bills-and-claims .filter-input {
  font-size: 1rem;
  line-height: 1.375;
  background-color: #fff;
  border: 1px solid #bfbfbf;
  color: #707070;
  cursor: pointer;
  font-family: inherit;
  height: 2.5rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
  position: relative;
  text-align: left;
  width: 100%;
}
.ngde-bills-and-claims .filter-input:focus {
  background-color: #fff;
}
.ngde-bills-and-claims .filter-input::placeholder {
  color: #707070;
  text-align: left;
}
.ngde-bills-and-claims .input-icon-container {
  position: relative;
}
.ngde-bills-and-claims svg.icon.icon-search {
  position: absolute;
  right: 10px;
  stroke-width: 2px;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
}
.ngde-bills-and-claims .claims-wrap .search-icon-wrapper {
  margin-top: -2.85rem;
  position: absolute;
  right: 16.5rem;
}
.ngde-bills-and-claims .claim-button-input {
  color: #363534;
  text-align: center;
}
.ngde-bills-and-claims .no-display {
  display: none;
}
.ngde-bills-and-claims .filter-wrapper {
  display: flex;
  justify-content: space-between;
}
.ngde-bills-and-claims .filter-wrapper .dropdown-title {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  text-align: center;
}
.ngde-bills-and-claims .filter-wrapper .dropdown-subtitle {
  margin-bottom: 0;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.4285714286;
}
.ngde-bills-and-claims .filter-wrapper .dropdown-text {
  font-size: 10px;
  margin-bottom: 0;
  text-align: center;
}
.ngde-bills-and-claims .filter-wrapper .top-margin {
  margin-top: 1.5rem;
}
@media (min-width: 360px) {
  .ngde-bills-and-claims {
    padding-top: 168px;
  }
}
@media print and (min-width: 3px) {
  .ngde-bills-and-claims {
    padding-top: 168px;
  }
}
@media (min-width: 768px) {
  .ngde-bills-and-claims {
    padding-top: 200px;
  }
  .ngde-bills-and-claims .mobile-member-list {
    display: none;
  }
  .ngde-bills-and-claims .new-drop-down {
    display: block;
  }
}
@media print and (min-width: 6px) {
  .ngde-bills-and-claims {
    padding-top: 200px;
  }
  .ngde-bills-and-claims .mobile-member-list {
    display: none;
  }
  .ngde-bills-and-claims .new-drop-down {
    display: block;
  }
}
@media (min-width: 1280px) {
  .ngde-bills-and-claims {
    padding-top: 300px;
  }
  .ngde-bills-and-claims .grid-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  .ngde-bills-and-claims .grid-row section {
    overflow: unset;
    width: unset;
  }
  .ngde-bills-and-claims .claims-wrap .loading-overlay {
    padding-top: 100px;
  }
  .ngde-bills-and-claims .claims-data .data-summary {
    margin-left: 0;
  }
  .ngde-bills-and-claims .claims-data .data-summary .data-section {
    padding-bottom: 22px;
  }
  .ngde-bills-and-claims .claim-details {
    padding: 0px;
  }
  .ngde-bills-and-claims .portal-table {
    display: table;
  }
  .ngde-bills-and-claims .mobile-card-list {
    display: none;
  }
  .ngde-bills-and-claims .label-filter {
    font-family: AvenirRoman, Helvetica, sans-serif;
    font-weight: normal;
    padding: 0;
  }
}
@media print and (min-width: 7px) {
  .ngde-bills-and-claims {
    padding-top: 300px;
  }
  .ngde-bills-and-claims .grid-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  .ngde-bills-and-claims .grid-row section {
    overflow: unset;
    width: unset;
  }
  .ngde-bills-and-claims .claims-wrap .loading-overlay {
    padding-top: 100px;
  }
  .ngde-bills-and-claims .claims-data .data-summary {
    margin-left: 0;
  }
  .ngde-bills-and-claims .claims-data .data-summary .data-section {
    padding-bottom: 22px;
  }
  .ngde-bills-and-claims .claim-details {
    padding: 0px;
  }
  .ngde-bills-and-claims .portal-table {
    display: table;
  }
  .ngde-bills-and-claims .mobile-card-list {
    display: none;
  }
  .ngde-bills-and-claims .label-filter {
    font-family: AvenirRoman, Helvetica, sans-serif;
    font-weight: normal;
    padding: 0;
  }
}

.change-primary-care-provider .ngde-form {
  flex-direction: column;
  margin: 0;
}
.change-primary-care-provider .ngde-form .form-control {
  margin-bottom: 1.5rem;
}
.change-primary-care-provider .ngde-form input {
  margin-bottom: 0;
}
.change-primary-care-provider .form-control {
  margin-bottom: 3rem;
}
.change-primary-care-provider .form-control label {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  display: block;
  margin-bottom: 0.75rem;
}
.change-primary-care-provider .error-message,
.change-primary-care-provider .success-message {
  display: none;
}
.change-primary-care-provider .error-message.active,
.change-primary-care-provider .success-message.active {
  display: block;
}
.change-primary-care-provider .success-message {
  color: #498d37;
}
.change-primary-care-provider .error-message {
  color: #a71930;
}
.change-primary-care-provider .disclaimer {
  font-size: 0.75rem;
  line-height: 1.5;
}

.ngde-current-coverage {
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  padding-top: 168px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
}
@media (min-width: 768px) {
  .ngde-current-coverage {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .ngde-current-coverage {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .ngde-current-coverage {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .ngde-current-coverage {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .ngde-current-coverage {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .ngde-current-coverage {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.ngde-current-coverage .who-covered-section.loading .loading-overlay-section,
.ngde-current-coverage .current-plan-card-wrap.loading .loading-overlay-section {
  opacity: 1;
}
.ngde-current-coverage .plan-inactive {
  display: none;
}
.ngde-current-coverage.set-active .plan-inactive {
  display: none;
}
.ngde-current-coverage.set-active .plan-active,
.ngde-current-coverage.set-active .plan-list-active {
  display: block;
}
.ngde-current-coverage.set-inactive .plan-inactive {
  display: block;
}
.ngde-current-coverage.set-inactive .plan-active,
.ngde-current-coverage.set-inactive .plan-list-active {
  display: none;
}
.ngde-current-coverage.errorLeft .left-error {
  display: flex;
}
.ngde-current-coverage.errorRight .right-error {
  display: flex;
}
.ngde-current-coverage .error-notification-wrap {
  font-size: 1rem;
  line-height: 1.375;
  background-color: #ffefef;
  padding: 1.25rem;
  width: 90%;
}
.ngde-current-coverage .error-notification-wrap .text-wrapper {
  margin-left: 1.25rem;
}
.ngde-current-coverage .left-error,
.ngde-current-coverage .right-error {
  display: none;
}
.ngde-current-coverage a {
  color: #00759a;
  text-decoration: none;
}
.ngde-current-coverage a li {
  margin-bottom: 0.5rem;
}
.ngde-current-coverage .grid-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}
.ngde-current-coverage .content-col .whos-covered-wrap-container {
  position: sticky;
  overflow-x: scroll;
  overflow-y: hidden;
}
.ngde-current-coverage .content-col .whos-covered-wrap-container .whos-covered-wrap {
  padding: 0.5rem 0.5rem 2.5rem 0.5rem;
  width: 800px;
}
.ngde-current-coverage .content-col .box {
  padding: 0px;
}
.ngde-current-coverage .content-col .wrapper {
  display: grid;
  row-gap: 7px;
  column-gap: 17px;
  grid-template-columns: repeat(auto-fill, minmax(100px, 5fr) minmax(100px, 1fr));
  font-size: 0.9rem;
}
.ngde-current-coverage .content-col .container-grid {
  padding-right: 10%;
  padding-left: 6%;
}
.ngde-current-coverage .whos-covered-wrap {
  padding: 0.5rem 0.5rem 2.5rem 0.5rem;
}
.ngde-current-coverage .whos-covered-wrap .whos-covered-title-row,
.ngde-current-coverage .whos-covered-wrap .whos-covered-data-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  padding: 1rem 0;
}
.ngde-current-coverage .whos-covered-wrap .whos-covered-title-row {
  background-color: #eee;
  border-bottom: solid #bfbfbf 1px;
}
.ngde-current-coverage .whos-covered-wrap .whos-covered-data-row {
  border: solid #bfbfbf 1px;
  border-top: none;
}
.ngde-current-coverage .download-list .btn-link {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #00759a;
  margin-bottom: 0rem;
  text-align: left;
}
.ngde-current-coverage .tax-forms-wrap .text-content li {
  margin-bottom: 0.3rem;
}
.ngde-current-coverage .websites-wrap {
  margin-top: 1rem;
}
.ngde-current-coverage .websites-wrap .text-content {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #00759a;
  margin-bottom: 0rem;
  margin-top: 0.3rem;
  text-align: left;
}
.ngde-current-coverage .whos-covered-table {
  width: 100%;
}
.ngde-current-coverage .whos-covered-table th {
  text-align: left;
}
@media (min-width: 414px) {
  .ngde-current-coverage {
    padding-top: 168px;
  }
  .ngde-current-coverage .content-col .container-grid {
    padding-right: 10%;
    padding-left: 6%;
  }
  .ngde-current-coverage .content-col .whos-covered-wrap-container {
    position: sticky;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .ngde-current-coverage .content-col .whos-covered-wrap-container .whos-covered-wrap {
    padding: 0.5rem 0.5rem 2.5rem 0.5rem;
    width: 800px;
  }
}
@media print and (min-width: 5px) {
  .ngde-current-coverage {
    padding-top: 168px;
  }
  .ngde-current-coverage .content-col .container-grid {
    padding-right: 10%;
    padding-left: 6%;
  }
  .ngde-current-coverage .content-col .whos-covered-wrap-container {
    position: sticky;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .ngde-current-coverage .content-col .whos-covered-wrap-container .whos-covered-wrap {
    padding: 0.5rem 0.5rem 2.5rem 0.5rem;
    width: 800px;
  }
}
@media (min-width: 768px) {
  .ngde-current-coverage {
    padding-top: 200px;
  }
  .ngde-current-coverage .content-col .container-grid {
    padding-right: 20px;
    padding-left: 13px;
  }
  .ngde-current-coverage .content-col .whos-covered-wrap-container {
    width: 100%;
    position: sticky;
    overflow-x: visible;
    overflow-y: visible;
  }
  .ngde-current-coverage .content-col .whos-covered-wrap-container .whos-covered-wrap {
    padding: 0.5rem 0.5rem 2.5rem 0.5rem;
    width: 100%;
  }
}
@media print and (min-width: 6px) {
  .ngde-current-coverage {
    padding-top: 200px;
  }
  .ngde-current-coverage .content-col .container-grid {
    padding-right: 20px;
    padding-left: 13px;
  }
  .ngde-current-coverage .content-col .whos-covered-wrap-container {
    width: 100%;
    position: sticky;
    overflow-x: visible;
    overflow-y: visible;
  }
  .ngde-current-coverage .content-col .whos-covered-wrap-container .whos-covered-wrap {
    padding: 0.5rem 0.5rem 2.5rem 0.5rem;
    width: 100%;
  }
}
@media (min-width: 1280px) {
  .ngde-current-coverage {
    padding-top: 300px;
  }
  .ngde-current-coverage .content-col .whos-covered-wrap-container {
    width: 100%;
    overflow: none;
  }
  .ngde-current-coverage .content-col .whos-covered-wrap-container .whos-covered-wrap {
    padding: 0.5rem 0.5rem 2.5rem 0.5rem;
    width: 100%;
  }
  .ngde-current-coverage .content-col .wrapper {
    column-gap: 17px;
    grid-template-columns: repeat(auto-fill, minmax(100px, 5fr) minmax(100px, 1fr));
  }
}
@media print and (min-width: 7px) {
  .ngde-current-coverage {
    padding-top: 300px;
  }
  .ngde-current-coverage .content-col .whos-covered-wrap-container {
    width: 100%;
    overflow: none;
  }
  .ngde-current-coverage .content-col .whos-covered-wrap-container .whos-covered-wrap {
    padding: 0.5rem 0.5rem 2.5rem 0.5rem;
    width: 100%;
  }
  .ngde-current-coverage .content-col .wrapper {
    column-gap: 17px;
    grid-template-columns: repeat(auto-fill, minmax(100px, 5fr) minmax(100px, 1fr));
  }
}

.ngde-coverage-history {
  padding-top: 160px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
}
@media (min-width: 768px) {
  .ngde-coverage-history {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .ngde-coverage-history {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .ngde-coverage-history {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .ngde-coverage-history {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .ngde-coverage-history {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .ngde-coverage-history {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.ngde-coverage-history a {
  text-decoration: none;
}
.ngde-coverage-history .grid-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}
.ngde-coverage-history .grid-row section {
  overflow: hidden;
  width: 100%;
}
.ngde-coverage-history .padbot {
  padding-bottom: 15px;
}
.ngde-coverage-history .summary-of-coverage-wrap-container {
  position: sticky;
  overflow-x: scroll;
  overflow-y: hidden;
}
.ngde-coverage-history .summary-of-coverage-wrap-container.loading .loading-overlay {
  opacity: 1;
}
.ngde-coverage-history .summary-of-coverage-wrap-container .summary-of-coverage-wrap {
  margin-bottom: 3rem;
  margin-left: 0.5rem;
  padding-left: -1rem;
  width: 862px;
}
.ngde-coverage-history .summary-of-coverage-wrap-container .summary-of-coverage-wrap .summary-of-coverage-table {
  min-width: 800px;
  max-width: 100%;
  /* overflow-y: hidden;
  overflow-x: scroll; */
}
.ngde-coverage-history .summary-of-coverage-wrap-container .summary-of-coverage-wrap svg.icon.arrow-down-triangle {
  /* margin-top: -2rem; */
  position: relative;
  top: 3px;
  stroke-width: 0px;
  fill: #aaa;
  margin-left: 20px;
}
.ngde-coverage-history .summary-of-coverage-wrap-container .summary-of-coverage-wrap svg.icon.arrow-down {
  /* margin-top: -2rem; */
  position: relative;
  top: 3px;
  stroke-width: 0px;
  fill: #aaa;
  margin-left: 20px;
}
.ngde-coverage-history .summary-of-coverage-wrap-container .summary-of-coverage-wrap svg.icon.arrow-up-triangle {
  /* margin-top: -2rem; */
  position: relative;
  top: 3px;
  stroke-width: 0px;
  fill: #707070;
  margin-left: 20px;
}
.ngde-coverage-history .summary-of-coverage-wrap-container .summary-of-coverage-wrap svg.icon.arrow-up {
  /* margin-top: -2rem; */
  position: relative;
  top: 3px;
  stroke-width: 0px;
}
.ngde-coverage-history .summary-of-coverage-wrap-container .summary-of-coverage-wrap .summary-of-coverage-title-row {
  background-color: #eee;
  padding: 1rem 1rem;
  border-bottom: solid #bfbfbf 1px;
  cursor: pointer;
}
.ngde-coverage-history .summary-of-coverage-wrap-container .summary-of-coverage-wrap .summary-of-coverage-data-row {
  padding: 2rem 1rem;
  border: solid #bfbfbf 1px;
  border-top: none;
}
.ngde-coverage-history .summary-of-coverage-wrap-container .summary-of-coverage-wrap .summary-of-coverage-group,
.ngde-coverage-history .summary-of-coverage-wrap-container .summary-of-coverage-wrap .summary-of-coverage-member {
  font-size: 0.9rem;
}
.ngde-coverage-history .summary-of-coverage-wrap-container .summary-of-coverage-wrap .coverage-label {
  font-style: oblique;
}
.ngde-coverage-history .summary-of-coverage-wrap-container .content-col h1 {
  font-size: 2.5rem;
  line-height: 1;
}
@media (min-width: 768px) {
  .ngde-coverage-history {
    padding-top: 200px;
  }
  .ngde-coverage-history .grid-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  .ngde-coverage-history .grid-row section {
    overflow: unset;
    width: unset;
  }
  .ngde-coverage-history .summary-of-coverage-wrap-container {
    width: 100%;
    position: sticky;
    overflow-x: unset;
    overflow-y: unset;
  }
  .ngde-coverage-history .summary-of-coverage-wrap-container .summary-of-coverage-wrap {
    width: 100%;
  }
  .ngde-coverage-history .summary-of-coverage-wrap-container .summary-of-coverage-wrap .summary-of-coverage-table {
    width: 185%;
    overflow-y: unset;
    overflow-x: unset;
  }
}
@media print and (min-width: 6px) {
  .ngde-coverage-history {
    padding-top: 200px;
  }
  .ngde-coverage-history .grid-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  .ngde-coverage-history .grid-row section {
    overflow: unset;
    width: unset;
  }
  .ngde-coverage-history .summary-of-coverage-wrap-container {
    width: 100%;
    position: sticky;
    overflow-x: unset;
    overflow-y: unset;
  }
  .ngde-coverage-history .summary-of-coverage-wrap-container .summary-of-coverage-wrap {
    width: 100%;
  }
  .ngde-coverage-history .summary-of-coverage-wrap-container .summary-of-coverage-wrap .summary-of-coverage-table {
    width: 185%;
    overflow-y: unset;
    overflow-x: unset;
  }
}
@media (min-width: 1280px) {
  .ngde-coverage-history {
    padding-top: 300px;
  }
}
@media print and (min-width: 7px) {
  .ngde-coverage-history {
    padding-top: 300px;
  }
}

.ngde-health-and-wellness {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  padding-top: 300px;
}
@media (min-width: 768px) {
  .ngde-health-and-wellness {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .ngde-health-and-wellness {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .ngde-health-and-wellness {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .ngde-health-and-wellness {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .ngde-health-and-wellness {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .ngde-health-and-wellness {
    padding-right: 70px;
    padding-left: 70px;
  }
}

.ngde-member-id-cards {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  padding-bottom: 100px;
  padding-top: 200px;
  /* Safari 10.1+ */
}
@media (min-width: 768px) {
  .ngde-member-id-cards {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .ngde-member-id-cards {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .ngde-member-id-cards {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .ngde-member-id-cards {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .ngde-member-id-cards {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .ngde-member-id-cards {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.ngde-member-id-cards .order-card.loading .loading-overlay-section,
.ngde-member-id-cards .card-section.loading .loading-overlay-section,
.ngde-member-id-cards .card-image-section.loading .loading-overlay-section {
  opacity: 1;
}
.ngde-member-id-cards .order-card.loading .loading-screen-reader,
.ngde-member-id-cards .card-section.loading .loading-screen-reader,
.ngde-member-id-cards .card-image-section.loading .loading-screen-reader {
  display: block;
}
.ngde-member-id-cards .loading-screen-reader {
  display: none;
}
.ngde-member-id-cards .plan-inactive {
  display: none;
}
.ngde-member-id-cards .no-card {
  display: none;
}
.ngde-member-id-cards.set-active .plan-inactive {
  display: none;
}
.ngde-member-id-cards.set-active .no-card {
  display: none;
}
.ngde-member-id-cards.set-active .plan-active,
.ngde-member-id-cards.set-active .plan-list-active {
  display: block;
}
.ngde-member-id-cards.set-inactive .plan-inactive {
  display: block;
}
.ngde-member-id-cards.set-inactive .no-card {
  display: none;
}
.ngde-member-id-cards.set-inactive .plan-active,
.ngde-member-id-cards.set-inactive .plan-list-active {
  display: none;
}
.ngde-member-id-cards.set-no-card .plan-inactive {
  display: none;
}
.ngde-member-id-cards.set-no-card .no-card {
  display: block;
}
.ngde-member-id-cards.set-no-card .plan-active,
.ngde-member-id-cards.set-no-card .plan-list-active {
  display: none;
}
.ngde-member-id-cards .member-id-card-section {
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.5;
  position: relative;
}
.ngde-member-id-cards .section-description {
  font-size: 1rem;
  line-height: 1.375;
}
.ngde-member-id-cards p.section-description {
  width: 96%;
}
.ngde-member-id-cards .customer-service {
  display: inline-block;
}
.ngde-member-id-cards .member-id-card-span {
  position: absolute;
  top: -150px;
}
.ngde-member-id-cards a,
.ngde-member-id-cards .nav-btn {
  color: #00759a;
  text-decoration: none;
}
.ngde-member-id-cards a li,
.ngde-member-id-cards .nav-btn li {
  margin-bottom: 0.5rem;
}
.ngde-member-id-cards a:hover,
.ngde-member-id-cards .nav-btn:hover {
  text-decoration: underline;
}
.ngde-member-id-cards .nav-btn {
  display: block;
  margin-bottom: 0.8rem;
}
.ngde-member-id-cards .active {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  color: #000;
}
.ngde-member-id-cards .grid-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}
.ngde-member-id-cards .action-btn {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #00759a;
  padding: 0 10px;
  text-decoration: underline;
}
.ngde-member-id-cards .action-btn:hover, .ngde-member-id-cards .action-btn:focus {
  text-decoration: none;
}
.ngde-member-id-cards .content-col .current-plan-card {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  padding: 1.5rem 0;
}
.ngde-member-id-cards .content-col .current-plan-card .portal-id-card {
  margin-bottom: 0.6875rem;
}
.ngde-member-id-cards .card-action-wrap {
  text-align: center;
}
.ngde-member-id-cards .card-action-wrap .card-action-first {
  padding: 0 10px 0 0;
}
.ngde-member-id-cards h1 {
  color: #a71930 !important;
  margin-bottom: 1rem;
  font-size: 1.125rem;
  line-height: 1.3333333333;
}
.ngde-member-id-cards h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  line-height: 1.3333333333;
}
.ngde-member-id-cards h3,
.ngde-member-id-cards .sub-header {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.3333333333;
  color: #000;
  margin-bottom: 1.5rem;
}
.ngde-member-id-cards .icon-success,
.ngde-member-id-cards .error {
  min-height: 22px;
  min-width: 22px;
}
.ngde-member-id-cards .icon {
  stroke-width: 0px;
}
.ngde-member-id-cards .btn-primary {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  background-color: #363534;
  border: 1px solid #363534;
  color: #fff;
  text-align: center;
  width: 100%;
}
.ngde-member-id-cards .btn-primary:hover, .ngde-member-id-cards .btn-primary:active {
  background-color: #000;
  color: #fff;
}
.ngde-member-id-cards .btn-primary:disabled {
  background-color: #fff;
  border: 1px solid #bfbfbf;
  color: #000;
}
.ngde-member-id-cards .fade {
  opacity: 0;
}
.ngde-member-id-cards .front-source,
.ngde-member-id-cards .back-source {
  opacity: 1;
}
.ngde-member-id-cards .front-source.fade,
.ngde-member-id-cards .back-source.fade {
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;
}
.ngde-member-id-cards .card-section,
.ngde-member-id-cards .order-card {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  margin-top: 1.5rem;
}
.ngde-member-id-cards .card-section.success .success-notification-wrap,
.ngde-member-id-cards .order-card.success .success-notification-wrap {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  background-color: #e2f5e4;
  display: flex;
  margin: 1rem 0 2rem 0;
  padding: 1.25rem;
}
.ngde-member-id-cards .card-section.success .success-notification-wrap .text-wrapper,
.ngde-member-id-cards .order-card.success .success-notification-wrap .text-wrapper {
  margin-left: 1.25rem;
}
.ngde-member-id-cards .card-section .plan-inactive,
.ngde-member-id-cards .order-card .plan-inactive {
  display: none;
}
.ngde-member-id-cards .card-section .to-fax-number,
.ngde-member-id-cards .order-card .to-fax-number {
  white-space: nowrap;
}
.ngde-member-id-cards .card-section.errorEmail .error-notification-wrap, .ngde-member-id-cards .card-section.errorFax .error-notification-wrap, .ngde-member-id-cards .card-section.errorFail .error-notification-wrap, .ngde-member-id-cards .card-section.errorCheckBox .error-notification-wrap,
.ngde-member-id-cards .order-card.errorEmail .error-notification-wrap,
.ngde-member-id-cards .order-card.errorFax .error-notification-wrap,
.ngde-member-id-cards .order-card.errorFail .error-notification-wrap,
.ngde-member-id-cards .order-card.errorCheckBox .error-notification-wrap {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  background-color: #ffefef;
  display: flex;
  margin-bottom: 2rem;
  padding: 1.25rem;
}
.ngde-member-id-cards .card-section.errorEmail .error-wrapper, .ngde-member-id-cards .card-section.errorFax .error-wrapper, .ngde-member-id-cards .card-section.errorFail .error-wrapper, .ngde-member-id-cards .card-section.errorCheckBox .error-wrapper,
.ngde-member-id-cards .order-card.errorEmail .error-wrapper,
.ngde-member-id-cards .order-card.errorFax .error-wrapper,
.ngde-member-id-cards .order-card.errorFail .error-wrapper,
.ngde-member-id-cards .order-card.errorCheckBox .error-wrapper {
  margin-left: 1.25rem;
}
.ngde-member-id-cards .card-section.errorEmail .text-wrapper-email,
.ngde-member-id-cards .order-card.errorEmail .text-wrapper-email {
  margin-left: 1.25rem;
  display: flex;
}
.ngde-member-id-cards .card-section.errorEmail .text-wrapper-fax,
.ngde-member-id-cards .card-section.errorEmail .text-wrapper-fail,
.ngde-member-id-cards .order-card.errorEmail .text-wrapper-fax,
.ngde-member-id-cards .order-card.errorEmail .text-wrapper-fail {
  display: none;
}
.ngde-member-id-cards .card-section.errorEmail .email-input,
.ngde-member-id-cards .order-card.errorEmail .email-input {
  border-bottom: 2px solid #a71930;
}
.ngde-member-id-cards .card-section.errorFax .text-wrapper-email,
.ngde-member-id-cards .card-section.errorFax .text-wrapper-fail,
.ngde-member-id-cards .order-card.errorFax .text-wrapper-email,
.ngde-member-id-cards .order-card.errorFax .text-wrapper-fail {
  display: none;
}
.ngde-member-id-cards .card-section.errorFax .text-wrapper-fax,
.ngde-member-id-cards .order-card.errorFax .text-wrapper-fax {
  margin-left: 1.25rem;
  display: flex;
}
.ngde-member-id-cards .card-section.errorFax .fax-input,
.ngde-member-id-cards .order-card.errorFax .fax-input {
  border-bottom: 2px solid #a71930;
}
.ngde-member-id-cards .card-section.errorFail .text-wrapper-email,
.ngde-member-id-cards .card-section.errorFail .text-wrapper-fax,
.ngde-member-id-cards .order-card.errorFail .text-wrapper-email,
.ngde-member-id-cards .order-card.errorFail .text-wrapper-fax {
  display: none;
}
.ngde-member-id-cards .card-section.errorFail .text-wrapper-fail,
.ngde-member-id-cards .order-card.errorFail .text-wrapper-fail {
  margin-left: 1.25rem;
  display: flex;
}
.ngde-member-id-cards .card-section .action-btn,
.ngde-member-id-cards .order-card .action-btn {
  display: block;
  margin: 0 auto 1.5rem auto;
}
.ngde-member-id-cards .card-section .success-notification-wrap,
.ngde-member-id-cards .card-section .error-notification-wrap,
.ngde-member-id-cards .order-card .success-notification-wrap,
.ngde-member-id-cards .order-card .error-notification-wrap {
  display: none;
}
.ngde-member-id-cards .card-section .form .form-control,
.ngde-member-id-cards .order-card .form .form-control {
  max-width: 100%;
}
.ngde-member-id-cards .card-section .checkbox-wrapper,
.ngde-member-id-cards .order-card .checkbox-wrapper {
  align-items: flex-start;
  display: flex;
  min-height: 1.5rem;
  justify-items: flex-start;
  position: relative;
  color: #707070;
}
.ngde-member-id-cards .card-section .checkbox-wrapper input[type=checkbox],
.ngde-member-id-cards .order-card .checkbox-wrapper input[type=checkbox] {
  height: 1.5rem;
  margin-top: 2px;
  opacity: 0;
  width: 1.5rem;
}
.ngde-member-id-cards .card-section .checkbox-wrapper input[type=checkbox]:checked + label:after,
.ngde-member-id-cards .order-card .checkbox-wrapper input[type=checkbox]:checked + label:after {
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  content: "";
  display: block;
  height: 0.9rem;
  left: 0;
  margin: 0.25rem 0.5rem;
  position: absolute;
  top: 0;
  transform: rotate(45deg) translate(-1px, -1px);
  width: 0.45rem;
  border-bottom-width: 1px;
  border-right-width: 1px;
}
.ngde-member-id-cards .card-section .checkbox-wrapper input[type=checkbox]:not([disabled]):hover,
.ngde-member-id-cards .order-card .checkbox-wrapper input[type=checkbox]:not([disabled]):hover {
  cursor: pointer;
}
.ngde-member-id-cards .card-section .checkbox-wrapper input[type=checkbox]:not([disabled]):hover + label:before,
.ngde-member-id-cards .order-card .checkbox-wrapper input[type=checkbox]:not([disabled]):hover + label:before {
  border-color: #363534;
}
.ngde-member-id-cards .card-section .checkbox-wrapper input[type=checkbox]:not([disabled]):focus + label:before,
.ngde-member-id-cards .order-card .checkbox-wrapper input[type=checkbox]:not([disabled]):focus + label:before {
  border-color: #363534;
  border-width: 2px;
}
.ngde-member-id-cards .card-section .checkbox-wrapper input[type=checkbox]:not([disabled]) + label:hover,
.ngde-member-id-cards .order-card .checkbox-wrapper input[type=checkbox]:not([disabled]) + label:hover {
  cursor: pointer;
}
.ngde-member-id-cards .card-section .checkbox-wrapper input[type=checkbox]:not([disabled]) + label:hover:before,
.ngde-member-id-cards .order-card .checkbox-wrapper input[type=checkbox]:not([disabled]) + label:hover:before {
  border-color: #363534;
}
.ngde-member-id-cards .card-section .checkbox-wrapper input[type=checkbox] + label:before,
.ngde-member-id-cards .order-card .checkbox-wrapper input[type=checkbox] + label:before {
  background-color: #fff;
  border: 1px solid #707070;
  border-radius: 2px;
  content: "";
  display: block;
  height: 1.5rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 1.5rem;
}
.ngde-member-id-cards .card-section .checkbox-wrapper label,
.ngde-member-id-cards .order-card .checkbox-wrapper label {
  font-size: 1rem;
  line-height: 1.375;
  color: #707070;
}
.ngde-member-id-cards .card-section .checkbox-list,
.ngde-member-id-cards .order-card .checkbox-list {
  margin-bottom: 2rem;
  padding-inline-start: 0;
}
.ngde-member-id-cards .card-section input[type=text],
.ngde-member-id-cards .order-card input[type=text] {
  background-color: #eee;
  border: 1px solid #eee;
  color: #000;
  padding-top: 1.5rem;
  text-align: left;
}
.ngde-member-id-cards .card-section input[type=text]:disabled,
.ngde-member-id-cards .order-card input[type=text]:disabled {
  background-color: #fff;
  border: 1px solid #bfbfbf;
  color: #707070;
}
.ngde-member-id-cards .card-section .floating-label,
.ngde-member-id-cards .order-card .floating-label {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #707070;
  left: 1.2rem;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  top: 4px;
}
.ngde-member-id-cards .card-section .error-message,
.ngde-member-id-cards .order-card .error-message {
  padding: 0 0 0.625rem 0;
}
@media (min-width: 1280px) {
  .ngde-member-id-cards {
    padding-top: 300px;
  }
  .ngde-member-id-cards .card-action-wrap {
    text-align: left;
  }
  .ngde-member-id-cards .card-action-wrap .card-action {
    text-decoration: none;
  }
}
@media print and (min-width: 7px) {
  .ngde-member-id-cards {
    padding-top: 300px;
  }
  .ngde-member-id-cards .card-action-wrap {
    text-align: left;
  }
  .ngde-member-id-cards .card-action-wrap .card-action {
    text-decoration: none;
  }
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .ngde-member-id-cards form.form div.form-control div.input-field input[type=text]:disabled {
      -webkit-text-fill-color: #707070;
      opacity: 1; /* required on iOS */
    }
  }
}

.portal .ngde-modal .modal-inner-container {
  border-top: 0.75rem solid #a71930;
}
.portal .ngde-modal .modal-inner {
  margin-bottom: 3rem;
  margin-left: 30px;
  margin-right: 30px;
  max-width: calc(100vw - 60px);
  padding: 0;
}
.portal .ngde-modal .modal-description {
  font-size: 1rem;
  line-height: 1.375;
}
.portal .ngde-modal .ngde-form {
  margin: 0;
}
.portal .ngde-modal .select-wrapper {
  min-height: 56px;
  position: relative;
  width: 100%;
}
.portal .ngde-modal .select-wrapper .select {
  background-color: #eee;
  min-height: 56px;
  width: 100%;
}
.portal .ngde-modal .select-wrapper .icon {
  fill: #222;
  position: absolute;
  right: 1rem;
  top: calc(50% - 0.5rem);
}
.portal .ngde-modal .form-control {
  margin-bottom: 1rem;
  max-width: none;
}
.portal .ngde-modal input[type=text],
.portal .ngde-modal input[type=password] {
  max-width: none;
  width: 100%;
}
.portal .ngde-modal input[type=text]:focus-visible,
.portal .ngde-modal input[type=password]:focus-visible {
  outline: -webkit-focus-ring-color auto 1px;
}
.portal .ngde-modal .btn {
  max-width: none;
  width: 100%;
}
.portal .ngde-modal .submit-button:focus-visible, .portal .ngde-modal .submit-button:focus {
  outline: #00b0e6 auto 3px;
}
.portal .ngde-modal input {
  font-size: 1rem;
  line-height: 1.375;
  background-color: #e8e8e8;
  height: auto;
  min-height: 56px;
  padding-bottom: 0;
  padding-top: 0;
  text-align: left;
  width: 100%;
}
.portal .ngde-modal input::placeholder {
  text-align: left;
}
.portal .ngde-modal .radio-group {
  display: flex;
}
.portal .ngde-modal .radio-group .radio-wrapper {
  padding-right: 2rem;
}
.portal .ngde-modal .radio-group .radio-wrapper label {
  font-size: 1.125rem;
  line-height: 1.3333333333;
}
.portal .ngde-modal .radio-group .radio-wrapper input[type=radio] + label:before {
  border: 1px solid #bfbfbf;
}
@media (min-width: 768px) {
  .portal .ngde-modal .modal-inner {
    margin: 0 95px;
    max-width: 550px;
  }
}
@media print and (min-width: 6px) {
  .portal .ngde-modal .modal-inner {
    margin: 0 95px;
    max-width: 550px;
  }
}

.ngde-my-profile {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  padding-bottom: 100px;
  padding-top: 200px;
}
@media (min-width: 768px) {
  .ngde-my-profile {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .ngde-my-profile {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .ngde-my-profile {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .ngde-my-profile {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .ngde-my-profile {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .ngde-my-profile {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1280px) {
  .ngde-my-profile {
    padding-top: 300px;
  }
  .ngde-my-profile .card-action-wrap {
    text-align: left;
  }
  .ngde-my-profile .card-action-wrap .card-action {
    text-decoration: none;
  }
}
@media print and (min-width: 7px) {
  .ngde-my-profile {
    padding-top: 300px;
  }
  .ngde-my-profile .card-action-wrap {
    text-align: left;
  }
  .ngde-my-profile .card-action-wrap .card-action {
    text-decoration: none;
  }
}
.ngde-my-profile .paperless-preference-member-email {
  font-weight: bold;
}

.preferences-modal .btn-primary {
  background-color: #000;
  color: #fff;
}
.preferences-modal .modal-container .modal-inner-container {
  border-top: 0;
}

.ngde-contact-information .multiple-errors {
  display: none;
}
.ngde-contact-information.errors .multiple-errors {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  background-color: #ffefef;
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0 2rem 0;
  padding: 1.25rem;
  width: 100%;
}
.ngde-contact-information.errors .multiple-errors .error {
  margin-right: 1.25rem;
}
.ngde-contact-information.errors .email-error {
  display: none;
}
.ngde-contact-information.errors .mobile-error {
  display: none;
}
.ngde-contact-information.errors .other-error {
  display: none;
}
.ngde-contact-information.errors .alt-phone-type-error {
  display: none;
}
.ngde-contact-information.first .email {
  border-bottom: 2px solid #a71930;
}
.ngde-contact-information.first .email-error {
  display: block;
}
.ngde-contact-information.second .mobile-number {
  border-bottom: 2px solid #a71930;
}
.ngde-contact-information.second .mobile-error {
  display: block;
}
.ngde-contact-information.third .other-number {
  border-bottom: 2px solid #a71930;
}
.ngde-contact-information.third .other-error {
  display: block;
}
.ngde-contact-information.fourth .type-select {
  border-bottom: 2px solid #a71930;
}
.ngde-contact-information.fourth .alt-phone-type-error {
  display: block;
}
.ngde-contact-information .select-wrapper {
  position: relative;
  margin-bottom: 1rem;
  width: 100%;
}
.ngde-contact-information .select-wrapper .type-select {
  background-color: #eee;
  display: flex;
  height: 60px;
}
.ngde-contact-information .select-wrapper .icon-arrow-down {
  position: absolute;
  right: 20px;
  top: 30%;
}
.ngde-contact-information .select-wrapper :focus {
  border: 2px solid black;
}
@media (min-width: 1280px) {
  .ngde-contact-information .select-input-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .ngde-contact-information .select-input-wrapper .input-field {
    width: calc((100% - 15px) / 1.5 - 1px);
  }
  .ngde-contact-information .select-input-wrapper .select-wrapper {
    position: relative;
    width: calc((100% - 15px) / 3 - 1px);
  }
}
@media print and (min-width: 7px) {
  .ngde-contact-information .select-input-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .ngde-contact-information .select-input-wrapper .input-field {
    width: calc((100% - 15px) / 1.5 - 1px);
  }
  .ngde-contact-information .select-input-wrapper .select-wrapper {
    position: relative;
    width: calc((100% - 15px) / 3 - 1px);
  }
}
.ngde-contact-information .heading-1 {
  color: #000;
}
.ngde-contact-information .verify-communication-type-form {
  /*&.send-code-form {
  	.input-field {
  		width: 105rem;
  	}
  }

  &.submit-code-form {
  	.input-field {
  		width: 100%;
  	}
  }*/
}
.ngde-contact-information .verify-communication-type-form .heading-1 {
  color: #000;
}
.ngde-contact-information .verify-communication-type-form .consent-text {
  flex-direction: column;
}
.ngde-contact-information .verify-communication-type-form .btn-with-instruction {
  margin-bottom: 1rem;
}
.ngde-contact-information .verify-communication-type-form .btn-with-instruction .btn {
  width: 100%;
  margin-bottom: 0.3rem;
}
.ngde-contact-information .verify-communication-type-form .btn-instruction-text {
  color: #a71930;
}
.ngde-contact-information .verify-communication-type-form .verify-communication-type-field {
  flex-direction: column;
}
.ngde-contact-information .verify-communication-type-form .verify-communication-type-field .input-field {
  width: 100%;
}
.ngde-contact-information .verify-communication-type-form .verify-communication-type-field .btn-with-instruction {
  width: 100%;
  /*.btn {

  }*/
}
@media (min-width: 1280px) {
  .ngde-contact-information .verify-communication-type-form .verify-communication-type-field {
    flex-direction: row;
  }
  .ngde-contact-information .verify-communication-type-form .verify-communication-type-field .input-field {
    width: 65%;
  }
  .ngde-contact-information .verify-communication-type-form .verify-communication-type-field .btn-with-instruction {
    width: 35%;
    /*.btn {

    }*/
  }
}
@media print and (min-width: 7px) {
  .ngde-contact-information .verify-communication-type-form .verify-communication-type-field {
    flex-direction: row;
  }
  .ngde-contact-information .verify-communication-type-form .verify-communication-type-field .input-field {
    width: 65%;
  }
  .ngde-contact-information .verify-communication-type-form .verify-communication-type-field .btn-with-instruction {
    width: 35%;
    /*.btn {

    }*/
  }
}
.ngde-contact-information .terms-instruction {
  color: #a71930;
}

.ngde-contact-us {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  padding-top: 192px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
}
@media (min-width: 768px) {
  .ngde-contact-us {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .ngde-contact-us {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .ngde-contact-us {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .ngde-contact-us {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .ngde-contact-us {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .ngde-contact-us {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.ngde-contact-us .contact-section:before {
  content: "";
  display: block;
  height: 150px;
  margin-top: -150px;
  pointer-events: none;
  visibility: hidden;
}
.ngde-contact-us svg.icon.arrow-down-anchor {
  display: none;
}
.ngde-contact-us h1 {
  color: #a71930;
  font-size: 1.375rem;
  line-height: 1.4545454545;
  margin-bottom: 1rem;
}
.ngde-contact-us .above-header {
  margin-bottom: 2rem;
}
.ngde-contact-us a {
  text-decoration: none;
}
.ngde-contact-us .grid-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}
.ngde-contact-us .grid-row section {
  overflow: hidden;
  width: 100%;
}
.ngde-contact-us svg.icon.my-chart-logo {
  width: 13rem;
  height: 3.2rem;
  stroke: none;
}
.ngde-contact-us svg.icon-info-alert {
  stroke: #00759a;
  stroke-linecap: round;
  stroke-width: 0px;
  width: 60px;
  margin: 0px 11px;
}
.ngde-contact-us .icon.icon-menu-close {
  color: #000;
  height: 11px;
  width: 11px;
}
.ngde-contact-us p.section-description {
  padding-right: 10px;
}
.ngde-contact-us p.section-description.blue-alert {
  background-color: #d9edf7;
  padding: 25px 87px 25px 22px;
  display: flex;
  margin: 11px -34px;
  width: 131%;
  min-width: 300px;
}
.ngde-contact-us p.section-description.appeal {
  margin-bottom: 120px;
  font-size: 1rem;
  line-height: 1.375;
}
.ngde-contact-us p.section-description a {
  font-size: 1rem;
  line-height: 1.375;
}
.ngde-contact-us p.mychart-message {
  font-size: 1rem;
  width: 100%;
  margin-left: 10px;
  margin-top: 10px;
}
.ngde-contact-us span.blue-alert-message {
  padding: -2px;
  display: inline-block;
}
.ngde-contact-us .chat-with-us-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ngde-contact-us .chat-with-us-content {
  padding-top: 30px;
}
.ngde-contact-us .chat-boxes {
  text-align: center;
  transform: scale(0.85);
  margin-bottom: 46px;
}
.ngde-contact-us .chat-boxes img {
  width: 100%;
}
.ngde-contact-us .talk-bubble {
  margin: 17px 12px;
  display: inline-block;
  position: relative;
  width: 275px;
  height: 53px;
}
.ngde-contact-us .border {
  background-color: #eee;
  border: 0px solid #bfbfbf;
}
.ngde-contact-us .border:first-of-type {
  background-color: #00759a;
  border: 0px solid #bfbfbf;
  color: #fff;
  margin-left: 113px;
  margin-bottom: 1px;
}
.ngde-contact-us .round {
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
}
.ngde-contact-us .tri-right.border.btm-left-in:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 30px;
  right: auto;
  top: auto;
  bottom: -40px;
  border: 0px solid;
  border-color: #707070 transparent transparent #707070;
}
.ngde-contact-us .tri-right.border.btm-left-in:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 38px;
  right: auto;
  top: auto;
  bottom: -20px;
  border: 12px solid;
  border-color: #eee transparent transparent #eee;
}
.ngde-contact-us .tri-right.border.btm-right-in:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 30px;
  bottom: -40px;
  border: 0px solid;
  border-color: #00759a #00759a transparent transparent;
}
.ngde-contact-us .tri-right.border.btm-right-in:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 30px;
  top: auto;
  bottom: -20px;
  border: 12px solid;
  border-color: #00759a #00759a transparent transparent;
}
.ngde-contact-us .talktext {
  padding: 1em;
  text-align: left;
  line-height: 1.5em;
}
.ngde-contact-us .talktext p {
  -webkit-margin-before: 0em;
  font-size: 1rem;
  line-height: 1.375;
  vertical-align: middle;
  text-align: center;
}
.ngde-contact-us .navigation-col {
  display: block;
}
.ngde-contact-us .mobile-nav-accordion h2.second-header {
  background-color: #eee;
  padding: 25px 19px 24px 60px;
  margin: -15px 2px 20px -31px;
  width: 130%;
}
.ngde-contact-us .down-arrow-container {
  margin-left: 50%;
}
.ngde-contact-us .mobile-nav-col .navigation-col {
  display: block;
}
.ngde-contact-us .mobile-nav-col .navigation-col .not-selected h2 {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  color: #000;
  margin-bottom: 0rem;
  padding: 1rem 2rem;
}
.ngde-contact-us .accordion {
  border-radius: 8px;
  border-width: 1px;
  flex-direction: column;
  height: auto;
  background-color: #eee;
  width: 125%;
  margin-left: -34px;
  padding-left: 40px;
}
.ngde-contact-us .accordion .accordion-item.active .accordion-item-body {
  display: block;
  width: 128%;
}
.ngde-contact-us .accordion.action-block-accordion .action-block {
  display: flex;
  justify-content: space-between;
  width: 88%;
  margin: 6px 0px;
}
.ngde-contact-us .accordion.action-block-accordion .accordion-item-body {
  background-color: #fff;
  margin: 0 0 0 -44px;
}
.ngde-contact-us .accordion.action-block-accordion .accordion-item.active .icon-arrow-down {
  display: none;
}
.ngde-contact-us .accordion.action-block-accordion .accordion-item.active .icon.icon-menu-close {
  display: block;
}
.ngde-contact-us .accordion.action-block-accordion .accordion-item .icon.icon-menu-close {
  display: none;
}
.ngde-contact-us .mobile-nav-trigger {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  align-items: center;
  background-color: #eee;
  display: flex;
  height: 70px;
  justify-content: space-between;
  margin-bottom: 2rem;
  margin-left: -30px;
  padding: 0 30px;
  width: 100vw;
}
.ngde-contact-us .nav-list-container {
  background-color: #fff;
  pointer-events: none;
  overflow: auto;
  visibility: hidden;
  opacity: 0;
  top: 20vh;
  padding-bottom: 80px;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  transition: visibility 0s ease-in-out 400ms, opacity 0.2s ease-in-out, top 0.2s ease-in-out;
  z-index: 9;
}
.ngde-contact-us .nav-list-container hr {
  width: 90%;
  margin: 18px 25px 18px 25px;
}
.ngde-contact-us .mobile-nav-header {
  align-items: center;
  background-color: #eee;
  display: flex;
  height: 70px;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 0 30px;
}
.ngde-contact-us .mobile-nav-header .mobile-close-container .mobile-close-btn:focus {
  outline: none !important;
}
.ngde-contact-us .mobile-nav-header .nav-heading {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 0;
}
.ngde-contact-us .heading-1 {
  padding: 0 30px;
}
.ngde-contact-us .nav-list {
  display: flex;
  flex-direction: column;
  padding: 0 0px;
}
.ngde-contact-us .nav-item {
  font-size: 1rem;
  line-height: 1.375;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  align-items: center;
  display: flex;
  min-height: 40px;
  padding: 0.5rem 20px;
}
.ngde-contact-us .nav-item a {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #363534;
  text-decoration: none;
  margin-left: 10px;
  margin-top: 4px;
}
.ngde-contact-us .nav-item.active {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  border-left-color: #363534;
}
.ngde-contact-us .section-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.ngde-contact-us option:disabled {
  display: none;
}
.ngde-contact-us .content-wrapper {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  border-bottom: 1px solid #bfbfbf;
  border-radius: 0px;
  color: #000;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 0px 8px 0px;
  width: 100%;
}
.ngde-contact-us .content-wrapper .content-label {
  max-width: 75%;
  flex-wrap: wrap;
}
.ngde-contact-us .content-wrapper .content-number {
  color: #00759a;
  text-align: right;
}
.ngde-contact-us span#complaint h2.second-header,
.ngde-contact-us span#chat h2.second-header,
.ngde-contact-us span#phonenumbers h2.second-header,
.ngde-contact-us span#contactus h2.second-header {
  font-size: 1.125rem;
  line-height: 1.3333333333;
}
.ngde-contact-us.nav-open .nav-list-container {
  display: block;
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
  top: 0;
  transition-delay: 0s;
}
.ngde-contact-us.trigger-fixed {
  margin-bottom: 2rem;
}
.ngde-contact-us.trigger-fixed .mobile-nav-trigger {
  top: 0;
  z-index: 8;
  transition: top 0.2s;
}
.navbar-scroll-up .ngde-contact-us.trigger-fixed .mobile-nav-trigger {
  top: 60px;
}
.navbar-scroll-down .ngde-contact-us.trigger-fixed .mobile-nav-trigger {
  top: 0;
}
.body-fixed .ngde-contact-us.trigger-fixed .mobile-nav-trigger {
  transition: top 0.3s ease-in-out;
}
.mobile-nav-open .ngde-contact-us.trigger-fixed .mobile-nav-trigger {
  top: 100vh;
}
@media (min-width: 768px) {
  .ngde-contact-us {
    padding-top: 200px;
  }
  .ngde-contact-us h1 {
    font-size: 2.5rem;
    line-height: 1;
  }
  .ngde-contact-us .chat-with-us-wrapper {
    flex-direction: unset;
    justify-content: unset;
  }
  .ngde-contact-us .chat-boxes {
    margin-right: -24px;
    text-align: unset;
    transform: scale(0.75);
    margin-left: -44px;
  }
  .ngde-contact-us svg.icon.icon-info-alert {
    width: 19px;
  }
  .ngde-contact-us p.section-description {
    padding-right: 10px;
  }
  .ngde-contact-us p.section-description.blue-alert {
    background-color: #d9edf7;
    padding: 20px;
    display: flex;
    margin: unset;
    width: unset;
    height: unset;
  }
  .ngde-contact-us p.mychart-message {
    font-size: 0.875rem;
    line-height: 1.4285714286;
    width: 70%;
    margin-left: 28px;
    margin-top: 10px;
  }
  .ngde-contact-us .mobile-nav-col {
    display: none;
  }
  .ngde-contact-us .mobile-nav-accordion h2.second-header {
    display: none;
  }
  .ngde-contact-us.column-fixed .nav-list {
    padding: 0;
    top: 50px;
  }
  .ngde-contact-us.column-end {
    margin-top: auto;
  }
  .ngde-contact-us.column-end .nav-list {
    position: relative;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ngde-contact-us .nav-list {
    padding: 0;
  }
  .ngde-contact-us .mobile-nav-trigger,
.ngde-contact-us .mobile-nav-header,
.ngde-contact-us .heading-1 {
    display: none;
  }
  .ngde-contact-us .nav-list-container {
    background-color: transparent;
    display: block;
    height: auto;
    opacity: 1;
    overflow: visible;
    pointer-events: auto;
    visibility: visible;
    position: relative;
    top: auto;
    left: auto;
    width: auto;
    z-index: 0;
  }
}
@media print and (min-width: 6px) {
  .ngde-contact-us {
    padding-top: 200px;
  }
  .ngde-contact-us h1 {
    font-size: 2.5rem;
    line-height: 1;
  }
  .ngde-contact-us .chat-with-us-wrapper {
    flex-direction: unset;
    justify-content: unset;
  }
  .ngde-contact-us .chat-boxes {
    margin-right: -24px;
    text-align: unset;
    transform: scale(0.75);
    margin-left: -44px;
  }
  .ngde-contact-us svg.icon.icon-info-alert {
    width: 19px;
  }
  .ngde-contact-us p.section-description {
    padding-right: 10px;
  }
  .ngde-contact-us p.section-description.blue-alert {
    background-color: #d9edf7;
    padding: 20px;
    display: flex;
    margin: unset;
    width: unset;
    height: unset;
  }
  .ngde-contact-us p.mychart-message {
    font-size: 0.875rem;
    line-height: 1.4285714286;
    width: 70%;
    margin-left: 28px;
    margin-top: 10px;
  }
  .ngde-contact-us .mobile-nav-col {
    display: none;
  }
  .ngde-contact-us .mobile-nav-accordion h2.second-header {
    display: none;
  }
  .ngde-contact-us.column-fixed .nav-list {
    padding: 0;
    top: 50px;
  }
  .ngde-contact-us.column-end {
    margin-top: auto;
  }
  .ngde-contact-us.column-end .nav-list {
    position: relative;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ngde-contact-us .nav-list {
    padding: 0;
  }
  .ngde-contact-us .mobile-nav-trigger,
.ngde-contact-us .mobile-nav-header,
.ngde-contact-us .heading-1 {
    display: none;
  }
  .ngde-contact-us .nav-list-container {
    background-color: transparent;
    display: block;
    height: auto;
    opacity: 1;
    overflow: visible;
    pointer-events: auto;
    visibility: visible;
    position: relative;
    top: auto;
    left: auto;
    width: auto;
    z-index: 0;
  }
}
@media (min-width: 1280px) {
  .ngde-contact-us {
    padding-top: 300px;
    margin-bottom: 0rem;
  }
  .ngde-contact-us h1 {
    font-size: 2.5rem;
    line-height: 1;
  }
  .ngde-contact-us svg.icon.icon-info-alert {
    width: 22px;
    margin-right: 13px;
  }
  .ngde-contact-us .navigation-col {
    display: block;
  }
  .ngde-contact-us .content-wrapper {
    padding: 20px 0px 8px 0px;
    width: 49%;
  }
  .ngde-contact-us .modal-inner-container {
    max-width: 50% !important;
  }
}
@media print and (min-width: 7px) {
  .ngde-contact-us {
    padding-top: 300px;
    margin-bottom: 0rem;
  }
  .ngde-contact-us h1 {
    font-size: 2.5rem;
    line-height: 1;
  }
  .ngde-contact-us svg.icon.icon-info-alert {
    width: 22px;
    margin-right: 13px;
  }
  .ngde-contact-us .navigation-col {
    display: block;
  }
  .ngde-contact-us .content-wrapper {
    padding: 20px 0px 8px 0px;
    width: 49%;
  }
  .ngde-contact-us .modal-inner-container {
    max-width: 50% !important;
  }
}
.ngde-contact-us hr.contact-section {
  /* color: #8d2a2a2e; */
  border-color: #f3eded;
  background-color: #cdc5c5;
  border: 0.5px #e9e4e4 solid;
  margin-bottom: 26px;
}

.ngde-prior-authorizations {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4rem;
  max-width: 1440px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 168px;
  width: 100%;
}
@media (min-width: 768px) {
  .ngde-prior-authorizations {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .ngde-prior-authorizations {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .ngde-prior-authorizations {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .ngde-prior-authorizations {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .ngde-prior-authorizations {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .ngde-prior-authorizations {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.ngde-prior-authorizations.no-padding {
  padding-top: 0 !important;
}
.ngde-prior-authorizations .back-link {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #000;
  display: inline-block;
  margin-bottom: 1rem;
}
.ngde-prior-authorizations .back-link .icon-arrow-right {
  height: 8.9px;
  margin-right: 0.5rem;
  transform: rotate(180deg);
  width: 4.45px;
}
.ngde-prior-authorizations .claims-wrap {
  margin-bottom: 4rem;
  position: relative;
}
.ngde-prior-authorizations .claims-wrap.loading {
  min-height: 300px;
  pointer-events: none;
}
.ngde-prior-authorizations .claims-wrap.loading .hide-while-loading,
.ngde-prior-authorizations .claims-wrap.loading .total-and-summary-container {
  display: none;
}
.ngde-prior-authorizations .claims-wrap.loading .loading-overlay {
  opacity: 1;
}
.ngde-prior-authorizations .claims-wrap .claims-section {
  min-height: 150px;
  position: relative;
}
.ngde-prior-authorizations .claims-wrap .loading-overlay {
  background-color: transparent;
  padding-top: 50px;
}
.ngde-prior-authorizations .claims-wrap .filter-section {
  display: none;
}
.ngde-prior-authorizations a {
  color: #00759a;
  text-decoration: none;
}
.ngde-prior-authorizations a li {
  margin-bottom: 0.5rem;
}
.ngde-prior-authorizations .prior-auth-details-title {
  display: flex;
  justify-content: space-between;
}
.ngde-prior-authorizations .prior-auth-header-row {
  background: #eee;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.ngde-prior-authorizations .prior-auth-header-title {
  padding: 1rem 1rem 0.5rem;
}
.ngde-prior-authorizations .prior-auth-header-status {
  padding: 0 1rem 1rem;
}
.ngde-prior-authorizations .prior-auth-header-status .icon {
  margin-right: 0.5rem;
}
.ngde-prior-authorizations .no-prior-auths-message {
  font-size: 1rem;
  line-height: 1.375;
}
.ngde-prior-authorizations .expandable-summaries {
  margin-bottom: 5rem;
}
.ngde-prior-authorizations .expandable-summaries .claims-header-title {
  display: inline-block;
  padding: 15px 30px 15px 0;
}
.ngde-prior-authorizations .expandable-summaries .data-summary {
  border: 0;
  margin: 0;
  width: auto;
}
.ngde-prior-authorizations .expandable-summaries .data-summary .data-section {
  background-color: white;
  border-right: none;
}
.ngde-prior-authorizations .expandable-summaries span.claims-header-title {
  display: inline-block;
  padding: 15px 0 15px 30px;
  width: 50%;
}
.ngde-prior-authorizations .expandable-summaries span.claims-header-title.right {
  padding: 15px 30px 15px 0;
  text-align: right;
}
.ngde-prior-authorizations .expandable-summaries .expandable {
  border: 1px solid #d3d3d3;
  border-bottom: none;
}
.ngde-prior-authorizations .expandable-summaries .expandable:last-of-type {
  border-bottom: 1px solid #d3d3d3;
  margin-bottom: 50px;
}
.ngde-prior-authorizations .expandable-summaries .expandable .expander {
  cursor: pointer;
  margin-bottom: 0;
}
.ngde-prior-authorizations .expandable-summaries .expandable .expander .icon {
  height: 9px;
  margin-right: 1rem;
  width: 5px;
}
.ngde-prior-authorizations .expandable-summaries .expandable .header {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  text-align: left;
  padding: 20px;
  width: 100%;
}
.ngde-prior-authorizations .expandable-summaries .expandable.expanded {
  background-color: white;
}
.ngde-prior-authorizations .expandable-summaries .expandable.expanded .additional-info {
  display: flex;
}
.ngde-prior-authorizations .expandable-summaries .expandable.expanded .expander .icon {
  transform: rotate(90deg);
}
.ngde-prior-authorizations .expandable-summaries .additional-info {
  background-color: white;
  border: none;
  display: none;
  margin-bottom: 0;
}
.ngde-prior-authorizations .grid-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}
.ngde-prior-authorizations .grid-row section {
  overflow: hidden;
  width: 100%;
}
.ngde-prior-authorizations .prior-auth-note {
  background-color: #e5f1f5;
  margin-bottom: 2rem;
  padding: 1rem;
}
.ngde-prior-authorizations .prior-auth-note .info-icon-wrapper {
  font-size: 1rem;
  line-height: 1.375;
}
.ngde-prior-authorizations .prior-auth-note .info-icon-wrapper .icon {
  margin-right: 10px;
  stroke: none;
}
.ngde-prior-authorizations .prior-auth-notification-wrap {
  background-color: #d9edf7;
  display: flex;
  margin: 1rem 0 2rem 0;
  min-width: 800px;
  padding: 1.2rem;
}
.ngde-prior-authorizations .prior-auth-notification-wrap .icon-info-alert {
  height: 1rem;
  margin-right: 0.625rem;
  width: 1rem;
}
.ngde-prior-authorizations .prior-auth-notification-wrap .btn-link {
  display: inline-block;
  margin-left: 0.625rem;
}
.ngde-prior-authorizations .drop-down__list-item {
  align-items: center;
  display: flex;
  height: 30px;
}
.ngde-prior-authorizations .drop-down__list-item.disabled > input {
  display: none;
}
.ngde-prior-authorizations .drop-down__list-item:first-child {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
}
.ngde-prior-authorizations .drop-down__list-item.title-divider {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
}
.ngde-prior-authorizations .drop-down__list-item > input {
  height: 20px;
  margin: 0.5rem 0;
  width: 20px;
}
.ngde-prior-authorizations .drop-down__list-item > label {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #707070;
  margin-left: 0.5rem;
}
.ngde-prior-authorizations .new-drop-down__button {
  font-size: 1rem;
  line-height: 1.375;
  border: 1px solid #bfbfbf;
  color: #707070;
  cursor: pointer;
  font-family: inherit;
  margin-bottom: 0;
  padding: 0.5rem;
  position: relative;
  text-align: left;
  width: 100%;
}
.ngde-prior-authorizations .label-filter {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
  line-height: 2.5;
  padding: 0;
}
.ngde-prior-authorizations .drop-down-icon {
  align-items: center;
  display: flex;
  height: 20px;
  justify-content: center;
}
.ngde-prior-authorizations .drop-down-icon--is-rotate {
  transform: rotate(180deg) translateY(50%);
}
.ngde-prior-authorizations .new-drop-down {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  width: 100%;
}
.ngde-prior-authorizations .new-drop-down {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  position: relative;
  width: 100%;
  z-index: 1;
}
.ngde-prior-authorizations button.new-drop-down__button.new-drop-down--is-open {
  color: #363534;
}
.ngde-prior-authorizations button.new-drop-down__button.new-drop-down--is-open span {
  color: #363534;
}
.ngde-prior-authorizations .new-drop-down__list-wrapper {
  background: white;
  border: 1px solid #bfbfbf;
  border: 1px solid #eee;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  padding: 1rem;
  position: absolute;
  top: 45px;
  width: 100%;
}
.ngde-prior-authorizations .filter-wrapper {
  display: flex;
  justify-content: space-between;
}
.ngde-prior-authorizations .filter-wrapper .dropdown-title {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  text-align: center;
}
.ngde-prior-authorizations .filter-wrapper .dropdown-subtitle {
  margin-bottom: 0;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.4285714286;
}
.ngde-prior-authorizations .filter-wrapper .dropdown-text {
  font-size: 10px;
  margin-bottom: 0;
  text-align: center;
}
.ngde-prior-authorizations .filter-wrapper .top-margin {
  margin-top: 1.5rem;
}
@media (min-width: 360px) {
  .ngde-prior-authorizations {
    padding-top: 168px;
  }
  .ngde-prior-authorizations .prior-mobile-container {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    padding: 0 0.5rem;
  }
  .ngde-prior-authorizations .prior-mobile-container .prior-mobile-section-title {
    font-size: 1.125rem;
    line-height: 1.3333333333;
    font-family: AvenirHeavy, Helvetica, sans-serif;
    font-weight: normal;
  }
  .ngde-prior-authorizations .prior-mobile-container .prior-mobile-action-button {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    width: auto;
  }
  .ngde-prior-authorizations .prior-mobile-container .prior-mobile-action-button .moble-right-btn {
    padding: 0.5rem 0.5rem 0;
  }
  .ngde-prior-authorizations .prior-mobile-container .prior-mobile-action-button .moble-right-btn .icon {
    stroke: #363534;
  }
  .ngde-prior-authorizations .prior-mobile-container .prior-mobile-action-button .moble-right-btn:first-child .icon {
    stroke: none;
  }
}
@media print and (min-width: 3px) {
  .ngde-prior-authorizations {
    padding-top: 168px;
  }
  .ngde-prior-authorizations .prior-mobile-container {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    padding: 0 0.5rem;
  }
  .ngde-prior-authorizations .prior-mobile-container .prior-mobile-section-title {
    font-size: 1.125rem;
    line-height: 1.3333333333;
    font-family: AvenirHeavy, Helvetica, sans-serif;
    font-weight: normal;
  }
  .ngde-prior-authorizations .prior-mobile-container .prior-mobile-action-button {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    width: auto;
  }
  .ngde-prior-authorizations .prior-mobile-container .prior-mobile-action-button .moble-right-btn {
    padding: 0.5rem 0.5rem 0;
  }
  .ngde-prior-authorizations .prior-mobile-container .prior-mobile-action-button .moble-right-btn .icon {
    stroke: #363534;
  }
  .ngde-prior-authorizations .prior-mobile-container .prior-mobile-action-button .moble-right-btn:first-child .icon {
    stroke: none;
  }
}
@media (min-width: 768px) {
  .ngde-prior-authorizations {
    padding-top: 200px;
  }
  .ngde-prior-authorizations .grid-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  .ngde-prior-authorizations .grid-row section {
    overflow: unset;
    width: unset;
  }
  .ngde-prior-authorizations .claims-wrap .filter-section {
    display: block;
    margin-bottom: 2rem;
  }
  .ngde-prior-authorizations .prior-auth-header-row {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
  }
  .ngde-prior-authorizations .prior-auth-header-title,
.ngde-prior-authorizations .prior-auth-header-status {
    padding: 0;
  }
  .ngde-prior-authorizations .claims-mobile-filters {
    display: none;
  }
  .ngde-prior-authorizations .expandable-summaries .claims-header-title {
    text-align: right;
    width: 50%;
  }
  .ngde-prior-authorizations .data-summary .data-section {
    flex-basis: 80%;
    max-width: 80%;
  }
}
@media print and (min-width: 6px) {
  .ngde-prior-authorizations {
    padding-top: 200px;
  }
  .ngde-prior-authorizations .grid-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  .ngde-prior-authorizations .grid-row section {
    overflow: unset;
    width: unset;
  }
  .ngde-prior-authorizations .claims-wrap .filter-section {
    display: block;
    margin-bottom: 2rem;
  }
  .ngde-prior-authorizations .prior-auth-header-row {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
  }
  .ngde-prior-authorizations .prior-auth-header-title,
.ngde-prior-authorizations .prior-auth-header-status {
    padding: 0;
  }
  .ngde-prior-authorizations .claims-mobile-filters {
    display: none;
  }
  .ngde-prior-authorizations .expandable-summaries .claims-header-title {
    text-align: right;
    width: 50%;
  }
  .ngde-prior-authorizations .data-summary .data-section {
    flex-basis: 80%;
    max-width: 80%;
  }
}
@media (min-width: 1280px) {
  .ngde-prior-authorizations {
    padding-top: 300px;
  }
}
@media print and (min-width: 7px) {
  .ngde-prior-authorizations {
    padding-top: 300px;
  }
}
@media print {
  .ngde-prior-authorizations .expandable-summaries .additional-info {
    display: flex;
  }
}

.ngde-chat-box {
  display: none;
  left: 0;
  opacity: 0;
  outline: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.3s ease-in-out;
  width: 100%;
  z-index: 10;
}
.ngde-chat-box.visible {
  display: block;
  background-color: #fff;
}
.ngde-chat-box.open {
  opacity: 1;
}
.ngde-chat-box .modal-header, .ngde-chat-box .echeckin-modal-header {
  background-color: #eee;
  display: flex;
  min-height: 60px;
  align-items: center;
}
.ngde-chat-box .modal-header .container, .ngde-chat-box .echeckin-modal-header .container {
  display: flex;
  justify-content: space-between;
}
.ngde-chat-box .modal-header .heading-3, .ngde-chat-box .echeckin-modal-header .heading-3 {
  font-size: 1.125rem;
  line-height: 1;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 0;
}
.ngde-chat-box .modal-inner,
.ngde-chat-box .iframe-container {
  height: calc(100vh - 60px);
}
.ngde-chat-box .modal-row {
  height: calc(100vh - 60px);
  width: 100%;
}
.ngde-chat-box .modal-row .modal-inner {
  height: calc(100vh - 60px);
  width: 100%;
}
@media (min-width: 1280px) {
  .ngde-chat-box.visible {
    display: block;
    background-color: transparent;
  }
  .ngde-chat-box .modal-container {
    bottom: 0;
    background-color: #fff;
    height: 545px;
    position: fixed;
    pointer-events: none;
    overflow: hidden;
    right: 0;
  }
  .ngde-chat-box .modal-inner-container {
    display: inline-block;
    vertical-align: bottom;
    height: 300px;
    pointer-events: auto;
    width: 500px;
    overflow: none;
  }
}
@media print and (min-width: 7px) {
  .ngde-chat-box.visible {
    display: block;
    background-color: transparent;
  }
  .ngde-chat-box .modal-container {
    bottom: 0;
    background-color: #fff;
    height: 545px;
    position: fixed;
    pointer-events: none;
    overflow: hidden;
    right: 0;
  }
  .ngde-chat-box .modal-inner-container {
    display: inline-block;
    vertical-align: bottom;
    height: 300px;
    pointer-events: auto;
    width: 500px;
    overflow: none;
  }
}

.ngde-mychart-card {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
}
@media (min-width: 768px) {
  .ngde-mychart-card {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .ngde-mychart-card {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .ngde-mychart-card {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .ngde-mychart-card {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .ngde-mychart-card {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .ngde-mychart-card {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.ngde-mychart-card p {
  font-size: 1rem;
  line-height: 1.375;
}
.ngde-mychart-card .grid-row {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: 5rem;
}
.ngde-mychart-card .btn {
  width: 100%;
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirBook, Helvetica, sans-serif;
  font-weight: normal;
  background-color: #363534;
  color: #fff;
}
.ngde-mychart-card .btn:hover {
  background-color: #222;
}
.ngde-mychart-card .large-portal-picture-card-img {
  height: 300px;
  object-fit: cover;
  width: 100%;
  margin-bottom: 1rem;
}
.ngde-mychart-card .phs-logo-wrap .icon-phs-logo {
  fill: #a71930;
  margin-bottom: 0.5rem;
}
.ngde-mychart-card .icon-external {
  height: 0.5rem;
  margin-left: 0.25rem;
  stroke: #fff;
  width: 0.5rem;
}

.mychart-card {
  background-color: #eee;
  padding: 2rem 2rem 3rem 2rem;
  margin: -1rem 0rem 0rem 0rem;
}
.mychart-card .icon-external {
  height: 0.5rem;
  margin-left: 0.25rem;
  stroke: #00759a;
  width: 0.5rem;
}

.mychart-card-activate {
  background-color: #eee;
  padding: 1rem 1.5rem 3rem 1.5rem;
  margin: 0rem 0rem 0rem 0rem;
  border-radius: 8px;
}
.mychart-card-activate .btn {
  background-color: #fff;
  color: #222;
  text-align: center;
}
.mychart-card-activate .btn:hover {
  background-color: #eee;
}
.mychart-card-activate input[type=text] {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  line-height: 1.3333333333;
  font-family: "AvenirBook", Helvetica, sans-serif;
  font-weight: "AvenirBook", Helvetica, sans-serif;
  background-color: #fafafa;
  color: #363534;
  border-bottom: 2px solid #bfbfbf;
  padding-top: 22px;
  text-align: left;
}
.mychart-card-activate input[type=text] ::placeholder {
  text-align: left;
}
.mychart-card-activate .input-down {
  padding-top: 36px;
}
.mychart-card-activate .form .form-control {
  max-width: 100%;
}
.mychart-card-activate .floating-label {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #707070;
  left: 1.05rem;
  opacity: 0;
  pointer-events: none;
  position: relative;
  top: 4px;
  top: -67px;
}
.mychart-card-activate p.section-description {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #363534;
}
.mychart-card-activate h2.second-header {
  font-size: 1.125rem;
  line-height: 1.3333333333;
}
@media (min-width: 1280px) {
  .mychart-card-activate input[type=text] {
    padding-right: 21rem;
  }
}
@media print and (min-width: 7px) {
  .mychart-card-activate input[type=text] {
    padding-right: 21rem;
  }
}
@media (min-width: 1440px) {
  .mychart-card-activate input[type=text] {
    padding-right: 11rem;
  }
}
@media print and (min-width: 1440px) {
  .mychart-card-activate input[type=text] {
    padding-right: 11rem;
  }
}

.mychart-card-logo {
  background-color: #eee;
  padding: 2rem 0rem 3rem 0rem;
}

.mychart-notification-wrap {
  background-color: #ffefef;
  display: -webkit-box;
  margin: 1rem 0 4rem 0;
  padding: 1.2rem;
}
.mychart-notification-wrap .icon {
  height: 2rem;
  stroke: unset;
  stroke-linecap: round;
  stroke-width: 2px;
  width: 4rem;
  margin-right: 5%;
}

.MyChartActivateLink {
  background-color: #eee;
  border-radius: 8px;
}
.MyChartActivateLink .portal-picture-card-img {
  height: 200px;
  object-fit: cover;
  width: 100%;
  margin-bottom: -2rem;
}

span.stauts-icon-wrapper {
  display: flex;
  width: 94%;
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #363534;
}

img.portal-picture-card-img.mychart-card-logo {
  height: 200px;
  -o-object-fit: cover;
  font-family: "object-fit:cover", "object-fit:cover";
  object-fit: fill;
  width: 100%;
  padding: 50px 50px 50px 21px;
  border-radius: 8px;
}

button.btn.btn-secondary.actsubmit-button.disabled {
  color: #bfbfbf;
}

.card-show {
  display: block;
}

.card-hide {
  display: none;
}

.centennial-card {
  padding: 20px;
}
.centennial-card h2 {
  margin-bottom: 0.25rem;
}
.centennial-card h3 {
  margin-bottom: 0.5rem;
}
.centennial-card .pcp-info {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.portal .portal-table {
  border-collapse: collapse;
  margin-bottom: 2rem;
  width: 100%;
}
.portal .portal-table thead tr {
  border: none;
}
.portal .portal-table th {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  background-color: #eee;
  cursor: pointer;
  padding: 1rem;
  position: relative;
  text-align: left;
  vertical-align: middle;
  white-space: nowrap;
  width: 1%;
}
.portal .portal-table th .table-header-title {
  color: #363534;
  display: inline;
  font-weight: normal;
  line-height: 1;
  padding: 10px 0;
}
.portal .portal-table th .table-header-title.active-sort {
  font-weight: bold;
}
.portal .portal-table th .arrow-icon-wrapper {
  bottom: -6px;
  display: inline-block;
  height: 24px;
  position: relative;
  width: 24px;
}
.portal .portal-table th .arrow-icon-wrapper.active-sort .icon {
  fill: #363534;
}
.portal .portal-table th .icon {
  fill: #bfbfbf;
  height: 24px;
  margin-left: 10px;
  stroke: #eee;
  width: 24px;
}
.portal .portal-table tr {
  border-top: 1px solid #d5d6d2;
}
.portal .portal-table tr.expandable.expanded {
  background-color: #fafafa;
}
.portal .portal-table tr.expandable.expanded + .additional-info {
  display: table-row;
}
.portal .portal-table tr.expandable.expanded .expander .icon {
  transform: rotate(90deg);
}
.portal .portal-table tr.additional-info {
  background-color: #fafafa;
  border: none;
  display: none;
}
.portal .portal-table td {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  padding: 1.5rem 1rem;
  width: 1%;
}
.portal .portal-table td.expander {
  cursor: pointer;
}
.portal .portal-table .status-icon-wrapper {
  display: flex;
}
.portal .portal-table .status-icon-wrapper .icon {
  margin-right: 0.5rem;
}
.portal .portal-table .icon-arrow-right {
  height: 10px;
  width: 6px;
}
.portal .portal-table .additional-info td {
  padding: 0 4rem 2rem;
}
.portal .portal-table tfoot tr {
  border: none;
}
.portal .portal-table tfoot td {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  background-color: #eee;
  padding: 1.5rem 1rem;
  position: relative;
  text-align: left;
  vertical-align: middle;
  white-space: nowrap;
  width: 1%;
}
.portal .portal-table tfoot .totals-label {
  text-transform: uppercase;
}

.recent-claims-card {
  background-color: #eee;
  border-radius: 8px;
  margin-bottom: 1rem;
  padding: 1.25rem 2.5rem;
}
.recent-claims-card.loading .loading-overlay-section {
  opacity: 1;
}
.recent-claims-card .loading-overlay-section {
  top: 3rem;
}
.recent-claims-card .recent-claims-card-title {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
  margin-bottom: 8px;
  text-align: center;
}
.recent-claims-card .recent-claims-list {
  min-height: 120px;
}
.recent-claims-card .recent-claim-data-list {
  padding: 10px;
}
.recent-claims-card .recent-claim-data-list.no-claims-found {
  padding-top: 20px;
}
.recent-claims-card .recent-claim-data-list > div {
  display: flex;
}
.recent-claims-card .recent-claim-data-list dt,
.recent-claims-card .recent-claim-data-list dd {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  margin-bottom: 0;
}
.recent-claims-card .recent-claim-data-list dt {
  margin-right: 0.5rem;
}
.recent-claims-card .claim-details-link {
  color: #000;
}
.recent-claims-card .claim-details-link:hover {
  text-decoration: none;
}
.recent-claims-card .claim-details-link:hover .claim-status {
  text-decoration: underline;
}
.recent-claims-card .claim-status {
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  color: #00759a;
  position: relative;
}
.recent-claims-card .claim-status dt {
  display: none;
}
.recent-claims-card .claim-status .status-icon-wrapper {
  left: -1.75rem;
  position: absolute;
  top: -0.125rem;
}
.recent-claims-card .claim-status .status-icon-wrapper .icon {
  height: 16px;
  margin-top: 2px;
  width: 16px;
}
.recent-claims-card .view-more-link {
  margin-top: 2rem;
  padding: 10px;
  text-align: center;
  width: 100%;
}
.recent-claims-card .view-more-link a {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
}

.deductible-card {
  background-color: #eee;
  border-radius: 8px;
  margin-bottom: 1rem;
  padding: 1.25rem 2.5rem;
}
.deductible-card.loading .loading-overlay-section {
  opacity: 1;
}
.deductible-card.hidden {
  opacity: 0;
}
.deductible-card .deductible-chart-details.hidden {
  opacity: 0;
}
.deductible-card h2.text-center.heading-2.hidden {
  opacity: 0;
}
.deductible-card .loading-overlay-section {
  top: 3rem;
}
.deductible-card .deductible-card-title {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
  margin-bottom: 8px;
  text-align: center;
}
.deductible-card .rdeductible-list {
  min-height: 120px;
}
.deductible-card .deductible-data-list {
  padding: 10px;
}
.deductible-card .deductible-data-list.no-deductible-found {
  padding-top: 20px;
}
.deductible-card .deductible-data-list > div {
  display: flex;
}
.deductible-card .deductible-data-list dt,
.deductible-card .deductible-data-list dd {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  margin-bottom: 0;
}
.deductible-card .deductible-data-list dt {
  margin-right: 0.5rem;
}
.deductible-card .deductible-details-link {
  color: #000;
}
.deductible-card .deductible-details-link:hover {
  text-decoration: none;
}
.deductible-card .deductible-details-link:hover .deductible-status {
  text-decoration: underline;
}
.deductible-card .deductible-status {
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  color: #00759a;
  position: relative;
}
.deductible-card .deductible-status dt {
  display: none;
}
.deductible-card .deductible-status .status-icon-wrapper {
  left: -1.75rem;
  position: absolute;
  top: -0.125rem;
}
.deductible-card .deductible-status .status-icon-wrapper .icon {
  height: 16px;
  margin-top: 2px;
  width: 16px;
}
.deductible-card .view-more-link {
  margin-top: 2rem;
  padding: 10px;
  text-align: center;
  width: 100%;
}
.deductible-card .view-more-link a {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
}

.ngde-care-team {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  margin-bottom: 4rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 140px;
  width: 100%;
}
@media (min-width: 768px) {
  .ngde-care-team {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .ngde-care-team {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .ngde-care-team {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .ngde-care-team {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .ngde-care-team {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .ngde-care-team {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.ngde-care-team a {
  text-decoration: none;
}
.ngde-care-team .content-col {
  padding-top: 1.5rem;
}
.ngde-care-team .care-team-list {
  position: relative;
}
.ngde-care-team .care-team-list.loading {
  min-height: 400px;
}
.ngde-care-team .care-team-list.loading .loading-overlay {
  opacity: 1;
}
.ngde-care-team .care-team-list.primary .col-12 {
  display: flex;
}
.ngde-care-team .care-team-list.primary .care-team-card {
  flex-grow: 1;
}
.ngde-care-team .care-team-card {
  background-color: #f9f9f9;
  display: block;
  margin-bottom: 2rem;
  padding: 1rem 2rem 2rem;
}
.ngde-care-team .care-team-card .modal-open-btn {
  font-size: 0.75rem;
  line-height: 1.5;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  display: block;
  margin: 2rem 0 0;
}
.ngde-care-team .card-header {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  border-bottom: 1px solid #000;
  margin-bottom: 5px;
  width: fit-content;
}
.ngde-care-team .card-header:last-of-type {
  margin-bottom: 3rem;
}
.ngde-care-team .card-description {
  font-size: 0.75rem;
  line-height: 1.5;
  display: block;
  margin-bottom: 0.3125rem;
  width: fit-content;
}
.ngde-care-team .card-description a {
  display: inline;
}
.ngde-care-team .other-care-header {
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 1rem;
}
.ngde-care-team .section-description {
  font-size: 0.875rem;
  line-height: 1.4285714286;
}
.ngde-care-team .section-description .icon {
  height: 10px;
  margin-left: 10px;
  stroke: #00759a;
  width: 10px;
}
.ngde-care-team .phone-type {
  display: inline-block;
  text-transform: capitalize;
}
.ngde-care-team p.change-pcp-btn button.btn-link.modal-open-btn {
  color: #fff;
  padding: 0.7em 1.5em;
  background-color: #363534;
  text-decoration: none;
}
@media (min-width: 768px) {
  .ngde-care-team {
    padding-top: 168px;
  }
}
@media print and (min-width: 6px) {
  .ngde-care-team {
    padding-top: 168px;
  }
}
@media (min-width: 1280px) {
  .ngde-care-team {
    padding-top: 255px;
  }
}
@media print and (min-width: 7px) {
  .ngde-care-team {
    padding-top: 255px;
  }
}

.ngde-deductibles-moop {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  margin-bottom: 4rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 160px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
}
@media (min-width: 768px) {
  .ngde-deductibles-moop {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .ngde-deductibles-moop {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .ngde-deductibles-moop {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .ngde-deductibles-moop {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .ngde-deductibles-moop {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .ngde-deductibles-moop {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.ngde-deductibles-moop .hide {
  visibility: hidden;
}
.ngde-deductibles-moop a {
  text-decoration: none;
}
.ngde-deductibles-moop .content-col {
  padding-top: 1.5rem;
}
.ngde-deductibles-moop .deductibles-moop-list {
  position: relative;
}
.ngde-deductibles-moop .deductibles-moop-list.loading {
  min-height: 400px;
}
.ngde-deductibles-moop .deductibles-moop-list.loading .loading-overlay {
  opacity: 1;
}
.ngde-deductibles-moop .deductibles-moop-list.primary .col-12 {
  display: flex;
}
.ngde-deductibles-moop .deductibles-moop-list.primary .care-team-card {
  flex-grow: 1;
}
.ngde-deductibles-moop .deductibles-moop-card {
  background-color: #eee;
  display: block;
  margin-bottom: 2rem;
  padding: 1rem 2rem 2rem;
}
.ngde-deductibles-moop .deductibles-moop-card .modal-open-btn {
  font-size: 0.75rem;
  line-height: 1.5;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  display: block;
  margin: 2rem 0 0;
}
.ngde-deductibles-moop .card-header {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  border-bottom: 1px solid #000;
  margin-bottom: 5px;
  width: fit-content;
}
.ngde-deductibles-moop .card-header:last-of-type {
  margin-bottom: 3rem;
}
.ngde-deductibles-moop .card-description {
  font-size: 0.75rem;
  line-height: 1.5;
  display: block;
  margin-bottom: 0.3125rem;
  width: fit-content;
  line-height: 1.8;
}
.ngde-deductibles-moop .card-description canvas {
  display: block;
  box-sizing: border-box;
  height: 45px !important;
  margin: 0 0 5px 0;
  width: 400px;
}
.ngde-deductibles-moop .card-description a {
  display: inline;
}
.ngde-deductibles-moop .IndividualInNet,
.ngde-deductibles-moop .FamilyInNet,
.ngde-deductibles-moop .IndividualInNetMoop,
.ngde-deductibles-moop .FamilyInNetMoop {
  margin-top: -77px;
  padding-top: 0px;
  margin-bottom: -72px;
  position: relative;
  z-index: 9;
}
.ngde-deductibles-moop .IndividualOutNet,
.ngde-deductibles-moop .FamilyOutNet,
.ngde-deductibles-moop .IndividualOutNetMoop,
.ngde-deductibles-moop .FamilyOutNetMoop {
  margin-top: -77px;
  padding-top: 0px;
  margin-bottom: -72px;
  position: relative;
  z-index: 9;
}
.ngde-deductibles-moop .deductLabelCon {
  display: flex;
  font-size: 14px;
}
.ngde-deductibles-moop .deductLabelRt {
  position: relative;
  bottom: 0px;
  display: flex;
  justify-content: start;
  width: 95%;
}
.ngde-deductibles-moop .deductLabelLt {
  position: relative;
  bottom: 0px;
  display: flex;
  justify-content: end;
}
.ngde-deductibles-moop .BarGraphLabel {
  position: relative;
  bottom: 3px;
  font-size: 15px;
}
.ngde-deductibles-moop .other-care-header {
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 1rem;
}
.ngde-deductibles-moop .section-description {
  font-size: 0.875rem;
  line-height: 1.4285714286;
}
.ngde-deductibles-moop .section-description .icon {
  height: 10px;
  margin-left: 10px;
  stroke: #00759a;
  width: 10px;
}
.ngde-deductibles-moop .phone-type {
  display: inline-block;
  text-transform: capitalize;
}
@media (min-width: 768px) {
  .ngde-deductibles-moop {
    padding-top: 168px;
  }
}
@media print and (min-width: 6px) {
  .ngde-deductibles-moop {
    padding-top: 168px;
  }
}
@media (min-width: 1280px) {
  .ngde-deductibles-moop {
    padding-top: 255px;
  }
}
@media print and (min-width: 7px) {
  .ngde-deductibles-moop {
    padding-top: 255px;
  }
}
@media (min-width: 768px) {
  .ngde-deductibles-moop {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .ngde-deductibles-moop {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .ngde-deductibles-moop {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .ngde-deductibles-moop {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .ngde-deductibles-moop {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .ngde-deductibles-moop {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.ngde-deductibles-moop a {
  text-decoration: none;
}
.ngde-deductibles-moop .grid-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}
.ngde-deductibles-moop .grid-row section {
  overflow: hidden;
  width: 100%;
}
.ngde-deductibles-moop .padbot {
  padding-bottom: 15px;
}
.ngde-deductibles-moop .summary-of-coverage-wrap-container {
  position: sticky;
  overflow-x: scroll;
  overflow-y: hidden;
}
.ngde-deductibles-moop .summary-of-coverage-wrap-container.loading .loading-overlay {
  opacity: 1;
}
.ngde-deductibles-moop .summary-of-coverage-wrap-container .summary-of-coverage-wrap {
  margin-bottom: 3rem;
  margin-left: 0.5rem;
  padding-left: -1rem;
  width: 862px;
}
.ngde-deductibles-moop .summary-of-coverage-wrap-container .summary-of-coverage-wrap .summary-of-coverage-table {
  min-width: 800px;
  max-width: 100%;
  /* overflow-y: hidden;
  overflow-x: scroll; */
}
.ngde-deductibles-moop .summary-of-coverage-wrap-container .summary-of-coverage-wrap svg.icon.arrow-down-triangle {
  /* margin-top: -2rem; */
  position: relative;
  top: 3px;
  stroke-width: 0px;
  fill: #aaa;
  margin-left: 20px;
}
.ngde-deductibles-moop .summary-of-coverage-wrap-container .summary-of-coverage-wrap svg.icon.arrow-down {
  /* margin-top: -2rem; */
  position: relative;
  top: 3px;
  stroke-width: 0px;
  fill: #aaa;
  margin-left: 20px;
}
.ngde-deductibles-moop .summary-of-coverage-wrap-container .summary-of-coverage-wrap svg.icon.arrow-up-triangle {
  /* margin-top: -2rem; */
  position: relative;
  top: 3px;
  stroke-width: 0px;
  fill: #707070;
  margin-left: 20px;
}
.ngde-deductibles-moop .summary-of-coverage-wrap-container .summary-of-coverage-wrap svg.icon.arrow-up {
  /* margin-top: -2rem; */
  position: relative;
  top: 3px;
  stroke-width: 0px;
}
.ngde-deductibles-moop .summary-of-coverage-wrap-container .summary-of-coverage-wrap .summary-of-coverage-title-row {
  background-color: #eee;
  padding: 1rem 1rem;
  border-bottom: solid #bfbfbf 1px;
  cursor: pointer;
}
.ngde-deductibles-moop .summary-of-coverage-wrap-container .summary-of-coverage-wrap .summary-of-coverage-data-row {
  padding: 2rem 1rem;
  border: solid #bfbfbf 1px;
  border-top: none;
}
.ngde-deductibles-moop .summary-of-coverage-wrap-container .summary-of-coverage-wrap .summary-of-coverage-data-row .hide {
  display: none;
}
.ngde-deductibles-moop .summary-of-coverage-wrap-container .summary-of-coverage-wrap .summary-of-coverage-group,
.ngde-deductibles-moop .summary-of-coverage-wrap-container .summary-of-coverage-wrap .summary-of-coverage-member {
  font-size: 0.9rem;
}
.ngde-deductibles-moop .summary-of-coverage-wrap-container .summary-of-coverage-wrap .coverage-label {
  font-style: oblique;
}
.ngde-deductibles-moop .summary-of-coverage-wrap-container .content-col h1 {
  font-size: 2.5rem;
  line-height: 1;
}
@media (min-width: 768px) {
  .ngde-deductibles-moop {
    padding-top: 1px;
  }
  .ngde-deductibles-moop .grid-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  .ngde-deductibles-moop .grid-row section {
    overflow: unset;
    width: unset;
  }
  .ngde-deductibles-moop .summary-of-coverage-wrap-container {
    width: 99%;
    position: sticky;
    overflow-x: unset;
    overflow-y: unset;
  }
  .ngde-deductibles-moop .summary-of-coverage-wrap-container .summary-of-coverage-wrap {
    width: 100%;
  }
  .ngde-deductibles-moop .summary-of-coverage-wrap-container .summary-of-coverage-wrap .summary-of-coverage-table {
    width: 185%;
    overflow-y: unset;
    overflow-x: unset;
  }
}
@media print and (min-width: 6px) {
  .ngde-deductibles-moop {
    padding-top: 1px;
  }
  .ngde-deductibles-moop .grid-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  .ngde-deductibles-moop .grid-row section {
    overflow: unset;
    width: unset;
  }
  .ngde-deductibles-moop .summary-of-coverage-wrap-container {
    width: 99%;
    position: sticky;
    overflow-x: unset;
    overflow-y: unset;
  }
  .ngde-deductibles-moop .summary-of-coverage-wrap-container .summary-of-coverage-wrap {
    width: 100%;
  }
  .ngde-deductibles-moop .summary-of-coverage-wrap-container .summary-of-coverage-wrap .summary-of-coverage-table {
    width: 185%;
    overflow-y: unset;
    overflow-x: unset;
  }
}
@media (min-width: 1280px) {
  .ngde-deductibles-moop {
    padding-top: 1px;
  }
}
@media print and (min-width: 7px) {
  .ngde-deductibles-moop {
    padding-top: 1px;
  }
}

.verify-communication-type-form .heading-1 {
  color: #000;
}
.verify-communication-type-form .btn-primary {
  background-color: #fff;
  border: 1px solid #bfbfbf;
  color: #000;
  height: fit-content;
}
.verify-communication-type-form .btn-primary:disabled {
  background-color: #eeeeee;
  border: 1px solid #bfbfbf;
  color: #707070;
}
.verify-communication-type-form .btn-primary:hover:not([disabled]) {
  font-weight: bold;
  background-color: #000;
  color: #fff;
}
.verify-communication-type-form .form-control button {
  text-align: center;
}
.verify-communication-type-form .terms-instruction {
  color: #a71930;
}
.verify-communication-type-form .btn-instruction-text {
  color: #a71930;
}
.verify-communication-type-form .btn-with-instruction {
  margin-bottom: 1rem;
}
.verify-communication-type-form .btn-with-instruction .btn {
  width: 100%;
  margin-bottom: 0.3rem;
}
.verify-communication-type-form .verify-communication-type-field {
  flex-direction: column;
}
.verify-communication-type-form .verify-communication-type-field .input-field {
  width: 100%;
}
.verify-communication-type-form .verify-communication-type-field .btn-with-instruction {
  width: 100%;
  /*.btn {

  }*/
}
@media (min-width: 1280px) {
  .verify-communication-type-form .verify-communication-type-field {
    flex-direction: row;
  }
  .verify-communication-type-form .verify-communication-type-field .input-field {
    width: 65%;
  }
  .verify-communication-type-form .verify-communication-type-field .btn-with-instruction {
    width: 35%;
    /*.btn {

    }*/
  }
}
@media print and (min-width: 7px) {
  .verify-communication-type-form .verify-communication-type-field {
    flex-direction: row;
  }
  .verify-communication-type-form .verify-communication-type-field .input-field {
    width: 65%;
  }
  .verify-communication-type-form .verify-communication-type-field .btn-with-instruction {
    width: 35%;
    /*.btn {

    }*/
  }
}

.verify-communication-type-field {
  display: flex;
  flex-direction: row;
}
.verify-communication-type-field .input-field {
  width: 75rem;
  margin-right: 1rem;
}
.verify-communication-type-field .floating-check {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  right: 1rem;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  top: 1.5rem;
}
.verify-communication-type-field .btn {
  width: 25rem;
}

.success-icon-container {
  position: absolute;
  right: 1rem;
  top: calc(50% - 1.5rem);
}

.verifying-icon-container {
  position: absolute;
  right: 1rem;
  top: calc(50% - 0.75rem);
}

.verification-error {
  color: red;
}

.verification-code-placeholder {
  text-align: left;
  color: #707070;
  position: absolute;
  top: 1.2rem;
  padding-left: 1rem;
}

.resend-code {
  text-align: center;
}

.verify-consent-checkbox-wrapper {
  align-items: flex-start;
  display: flex;
  min-height: 1.5rem;
  justify-items: flex-start;
  position: relative;
  color: #707070;
}
.verify-consent-checkbox-wrapper input[type=checkbox] {
  height: 1.5rem;
  margin-top: 2px;
  opacity: 0;
  width: 1.5rem;
}
.verify-consent-checkbox-wrapper input[type=checkbox]:checked + label:after {
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  content: "";
  display: block;
  height: 0.9rem;
  left: 0;
  margin: 0.25rem 0.5rem;
  position: absolute;
  top: 0;
  transform: rotate(45deg) translate(-1px, -1px);
  width: 0.45rem;
  border-bottom-width: 1px;
  border-right-width: 1px;
}
.verify-consent-checkbox-wrapper input[type=checkbox]:not([disabled]):hover {
  cursor: pointer;
}
.verify-consent-checkbox-wrapper input[type=checkbox]:not([disabled]):hover + label:before {
  border-color: #363534;
}
.verify-consent-checkbox-wrapper input[type=checkbox]:not([disabled]):focus + label:before {
  border-color: #363534;
  border-width: 2px;
}
.verify-consent-checkbox-wrapper input[type=checkbox]:not([disabled]) + label:hover {
  cursor: pointer;
}
.verify-consent-checkbox-wrapper input[type=checkbox]:not([disabled]) + label:hover:before {
  border-color: #363534;
}
.verify-consent-checkbox-wrapper input[type=checkbox] + label:before {
  background-color: #fff;
  border: 1px solid #707070;
  border-radius: 2px;
  content: "";
  display: block;
  height: 1.5rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 1.5rem;
}
.verify-consent-checkbox-wrapper label {
  font-size: 1rem;
  line-height: 1.375;
  color: #707070 !important;
  display: flex !important;
  font-size: 1rem !important;
  padding-left: 1rem;
}
.verify-consent-checkbox-wrapper.checkbox-span {
  display: flex;
  width: 100%;
}
.verify-consent-checkbox-wrapper.checkbox-span .pull-right {
  margin-left: auto;
}

.input-field-with-validation {
  display: flex;
  flex-direction: column;
}
.input-field-with-validation.validation-error input {
  border-color: #a71930;
}

.validation-error-message {
  padding-left: 0.25rem;
  padding-bottom: 1rem;
  color: #a71930;
}

.secure-message-verify-modal .ngde-form {
  justify-content: center;
}
.secure-message-verify-modal .heading-1 {
  color: #000;
}
.secure-message-verify-modal .modal-inner {
  display: flex;
  justify-content: center;
}

.redirecting-icon-container .redirecting-loader {
  position: relative;
  height: 3.5rem;
  width: 3.5rem;
  display: inline-block;
  animation: around 5.4s infinite;
}
.redirecting-icon-container .redirecting-loader::after,
.redirecting-icon-container .redirecting-loader::before {
  content: "";
  background: white;
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-width: 2px;
  border-color: #333 #333 transparent transparent;
  border-style: solid;
  border-radius: 40px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  animation: around 0.7s ease-in-out infinite;
}
.redirecting-icon-container .redirecting-loader::after {
  animation: around 0.7s ease-in-out 0.1s infinite;
  background: transparent;
}

@keyframes around {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.authorized-rep-member-view .data-summary .data-section {
  max-width: 100%;
  flex-basis: 100%;
}
.authorized-rep-member-view .current-coverage-wrap .heading-1 {
  color: #000;
}
.authorized-rep-member-view .ngde-member-id-cards {
  padding-top: 20px;
}
.authorized-rep-member-view .ngde-care-team {
  padding-top: 20px;
}

.ngde-portal-cards-grid .authorized-rep-card {
  padding-bottom: 150px;
}
.ngde-portal-cards-grid .authorized-rep-card .authorized-rep-member-select {
  padding: 20px;
}
.ngde-portal-cards-grid .authorized-rep-card .authorized-rep-member-select .select-wrapper-plan {
  padding-bottom: 20px;
  width: 100%;
}
.ngde-portal-cards-grid .authorized-rep-card .authorized-rep-member-select .select-wrapper-plan select {
  background-color: #eee;
}

.sign-in-grid {
  display: grid;
  grid-template-rows: auto auto 1fr auto;
}

.main-sign-in {
  min-height: auto;
  background-color: #eeeeee;
}
.main-sign-in .registration-heading {
  margin-left: 2rem;
}

.sign-in-mypres-logo {
  width: 10rem;
  margin-top: 5rem;
  margin-left: 2rem;
}
@media (min-width: 1280px) {
  .sign-in-mypres-logo {
    width: 20rem;
    margin-top: 2rem;
    margin-left: 4rem;
  }
}
@media print and (min-width: 7px) {
  .sign-in-mypres-logo {
    width: 20rem;
    margin-top: 2rem;
    margin-left: 4rem;
  }
}

.sign-in-footer {
  text-align: center;
  margin: 0 1.5rem;
}

.register-user {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-top: 2rem;
  margin-right: 1rem;
}
@media (min-width: 1280px) {
  .register-user .verify-consent-checkbox-wrapper.non-member {
    margin-left: 24%;
    margin-right: 24%;
    min-width: 50rem;
  }
}
@media print and (min-width: 7px) {
  .register-user .verify-consent-checkbox-wrapper.non-member {
    margin-left: 24%;
    margin-right: 24%;
    min-width: 50rem;
  }
}
@media (min-width: 1280px) {
  .register-user .verify-consent-checkbox-wrapper {
    margin-left: auto;
    margin-right: auto;
    min-width: 50rem;
  }
}
@media print and (min-width: 7px) {
  .register-user .verify-consent-checkbox-wrapper {
    margin-left: auto;
    margin-right: auto;
    min-width: 50rem;
  }
}
.register-user .input-field {
  position: relative;
}
.register-user input[type=text],
.register-user input[type=password] {
  background-color: #fff;
  border: 1px solid #fff;
  color: #000;
  padding-top: 2rem;
  text-align: left;
  position: relative;
}
.register-user input[type=text]:disabled,
.register-user input[type=password]:disabled {
  background-color: #eee;
  border-color: #bfbfbf;
}
.register-user input[type=text]:focus + .floating-label, .register-user input[type=text]:not(:placeholder-shown) + .floating-label,
.register-user input[type=password]:focus + .floating-label,
.register-user input[type=password]:not(:placeholder-shown) + .floating-label {
  font-size: 0.875rem;
  left: 1.35rem;
  top: 0.25rem;
  position: absolute;
  color: #707070;
}
.register-user input[type=text]:focus + .floating-label.validation-error, .register-user input[type=text]:not(:placeholder-shown) + .floating-label.validation-error,
.register-user input[type=password]:focus + .floating-label.validation-error,
.register-user input[type=password]:not(:placeholder-shown) + .floating-label.validation-error {
  color: #a71930;
}
@media (min-width: 1280px) {
  .register-user input[type=text],
.register-user input[type=password] {
    padding-left: 1.35rem;
  }
}
@media print and (min-width: 7px) {
  .register-user input[type=text],
.register-user input[type=password] {
    padding-left: 1.35rem;
  }
}
.register-user input[type=text].validation-error,
.register-user input[type=password].validation-error {
  border-color: #a71930;
}
.register-user .floating-label {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-size: 1.125rem;
  line-height: 1.33;
  color: #707070;
  left: 1.35rem;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  top: 1.5rem;
}
.register-user .floating-label.validation-error {
  color: #a71930;
}
.register-user .forgot-password .input-label input {
  padding-left: 1.35rem;
  padding-top: 2rem;
  background-color: #fff;
}
.register-user .validation-error {
  color: #a71930;
}
.register-user .loading-overlay {
  height: unset;
  opacity: unset;
  position: relative;
  background-color: #eeeeee;
}
.register-user .loading-overlay ::after {
  position: absolute;
  left: 50%;
}
.register-user .verifying-membership {
  position: relative;
  display: flex;
  top: 2rem;
  justify-content: center;
}
@media (min-width: 1280px) {
  .register-user {
    margin-left: auto;
    margin-right: auto;
    min-width: 50rem;
  }
  .register-user .ngde-contact-information {
    margin-left: 20rem;
  }
  .register-user .ngde-contact-information .select-input-wrapper .input-field {
    width: calc((100% - 35px) / 1.5 - 1px);
  }
  .register-user .ngde-contact-information .select-input-wrapper .select-wrapper {
    width: calc((100% - 15px) / 3 + 15px);
  }
  .register-user .ngde-non-member-contact-information {
    min-width: 50rem;
    margin-left: auto;
    margin-right: auto;
  }
  .register-user .next-btn {
    width: auto;
    min-width: 50rem;
    margin-left: 25%;
    margin-right: 25%;
  }
}
@media print and (min-width: 7px) {
  .register-user {
    margin-left: auto;
    margin-right: auto;
    min-width: 50rem;
  }
  .register-user .ngde-contact-information {
    margin-left: 20rem;
  }
  .register-user .ngde-contact-information .select-input-wrapper .input-field {
    width: calc((100% - 35px) / 1.5 - 1px);
  }
  .register-user .ngde-contact-information .select-input-wrapper .select-wrapper {
    width: calc((100% - 15px) / 3 + 15px);
  }
  .register-user .ngde-non-member-contact-information {
    min-width: 50rem;
    margin-left: auto;
    margin-right: auto;
  }
  .register-user .next-btn {
    width: auto;
    min-width: 50rem;
    margin-left: 25%;
    margin-right: 25%;
  }
}
@media (min-width: 1280px) {
  .register-user .create-account-button {
    margin-left: 25%;
    margin-right: 25%;
    width: auto;
    min-width: 50rem;
  }
}
@media print and (min-width: 7px) {
  .register-user .create-account-button {
    margin-left: 25%;
    margin-right: 25%;
    width: auto;
    min-width: 50rem;
  }
}
.register-user .select-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
}
.register-user .select-wrapper select {
  background-color: #fff !important;
  height: 60px;
  width: 100%;
  padding-left: 1.15rem;
  padding-top: 1.35rem;
}
.register-user .select-wrapper select:focus + .floating-label, .register-user .select-wrapper select:not(:placeholder-shown) + .floating-label {
  font-size: 0.875rem;
  left: 1.35rem;
  top: 0.25rem;
  position: absolute;
  color: #707070;
}
.register-user .select-wrapper select:focus + .floating-label.validation-error, .register-user .select-wrapper select:not(:placeholder-shown) + .floating-label.validation-error {
  color: #a71930;
}
.register-user .select-wrapper select.validation-error {
  border-color: #a71930;
}
.register-user fieldset {
  border: none;
  display: flex;
  padding-left: 0;
}
.register-user .registration-user-info-secondary-links .left-link {
  float: left;
}
.register-user .registration-user-info-secondary-links .right-link {
  float: right;
}
.register-user .btn-primary {
  background-color: #363534;
  border: 1px solid #363534;
  color: #fff;
}
.register-user .registration-success-message-box {
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  padding: 2rem;
}
.register-user .loading-icon-container {
  position: absolute;
  right: 20px;
  top: calc(50% - 15px);
}
.register-user .loader {
  position: relative;
  height: 20px;
  width: 20px;
  display: inline-block;
  animation: around 5.4s infinite;
}
@keyframes around {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.register-user .loader::after,
.register-user .loader::before {
  content: "";
  background: white;
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-width: 2px;
  border-color: #333 #333 transparent transparent;
  border-style: solid;
  border-radius: 20px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  animation: around 0.7s ease-in-out infinite;
}
.register-user .loader::after {
  animation: around 0.7s ease-in-out 0.1s infinite;
  background: transparent;
}
.register-user .user-id-error-message {
  color: #a71930;
  padding-bottom: 1rem;
}
.register-user .user-id-input-error {
  border-color: #a71930 !important;
}
.register-user .password-match-error-message p {
  color: #a71930 !important;
}

#register-modal-root .heading-1 {
  color: #000;
}
#register-modal-root .modal-container .btn-primary {
  background-color: #363534;
  border: 1px solid #363534;
  color: #fff;
  text-align: center;
}

.forgot-password {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-top: 2rem;
  margin-right: 1rem;
  margin-bottom: 2rem;
  /*input[type='text'] {
  	padding-top: 1.35rem;
  	@include breakpoint(lg) {
  		padding-left: 1.25rem;
  	}
  }*/
}
@media (min-width: 1280px) {
  .forgot-password {
    margin-left: auto;
    margin-right: auto;
  }
}
@media print and (min-width: 7px) {
  .forgot-password {
    margin-left: auto;
    margin-right: auto;
  }
}
.forgot-password .input-field {
  position: relative;
}
.forgot-password input[type=text],
.forgot-password input[type=password] {
  background-color: #fff;
  border: 1px solid #fff;
  color: #000;
  padding-top: 2rem;
  text-align: left;
  position: relative;
}
.forgot-password input[type=text]:disabled,
.forgot-password input[type=password]:disabled {
  background-color: #eee;
  border-color: #bfbfbf;
}
.forgot-password input[type=text]:focus + .floating-label, .forgot-password input[type=text]:not(:placeholder-shown) + .floating-label,
.forgot-password input[type=password]:focus + .floating-label,
.forgot-password input[type=password]:not(:placeholder-shown) + .floating-label {
  font-size: 0.875rem;
  left: 1.35rem;
  top: 0.25rem;
  position: absolute;
  color: #707070;
}
@media (min-width: 1280px) {
  .forgot-password input[type=text],
.forgot-password input[type=password] {
    padding-left: 1.35rem;
  }
}
@media print and (min-width: 7px) {
  .forgot-password input[type=text],
.forgot-password input[type=password] {
    padding-left: 1.35rem;
  }
}
.forgot-password .floating-label {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-size: 1.125rem;
  line-height: 1.33;
  color: #707070;
  left: 1.35rem;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  top: 1.5rem;
}
.forgot-password .input-field {
  justify-content: center;
  position: relative;
}
.forgot-password .input-field input {
  position: relative;
  /* or, for legacy browsers */
}
.forgot-password .input-field input ::placeholder {
  text-align: left;
}
.forgot-password .input-field input ::-webkit-input-placeholder {
  text-align: left;
}
.forgot-password .input-field input :-moz-placeholder {
  /* Firefox 18- */
  text-align: left;
}
.forgot-password .input-field input ::-moz-placeholder {
  /* Firefox 19+ */
  text-align: left;
}
.forgot-password .input-field input :-ms-input-placeholder {
  text-align: left;
}
.forgot-password .input-field .error {
  display: none;
  height: 100%;
  position: absolute;
  padding-right: 0.5rem;
  right: 0;
  top: 10px;
}
.forgot-password .input-field .error .icon-error {
  height: 20px;
  position: relative;
  width: 20px;
  z-index: 2;
}
.forgot-password label.input-label {
  position: relative;
  display: inline-block;
  margin: 0;
  width: 100%;
}
.forgot-password label.input-label > .icon {
  position: absolute;
  top: 40%;
  right: 15px;
  transform: translateY(-50%);
  color: silver;
  z-index: 100;
}
.forgot-password label.input-label > input {
  padding-left: calc(1em + 10px + 8px);
  width: 100%;
  max-width: none;
  text-align: left;
  background-color: #eee;
}
.forgot-password .send-code-options {
  display: flex;
  flex-direction: row;
}
.forgot-password .send-code-options .send-code-option {
  padding-right: 1rem;
}
.forgot-password .rules-description {
  font-size: 1rem;
  line-height: 1.375;
  margin: 1rem 0 0;
}
.forgot-password .valid,
.forgot-password .invalid {
  margin-top: 0.25rem;
}
.forgot-password .checklist-icon {
  height: 1rem;
  width: 1rem;
  margin-top: 2px;
}
.forgot-password .new-password-input.invalid-password {
  border-color: #a71930;
}
.forgot-password .new-password-input.valid-password {
  border-bottom-color: #86bf6b;
}
.forgot-password .password-weak-text {
  color: #a71930;
}
.forgot-password .password-strong-text {
  color: #86bf6b;
}
.forgot-password .resend-code-text {
  display: flex;
  font-size: 0.875rem;
}
.forgot-password .resend-code-text .clear-btn {
  cursor: pointer;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 0;
}
.forgot-password .resend-code-text .resend-disabled {
  color: #00759a;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 0;
}
@media (min-width: 1280px) {
  .forgot-password {
    margin-left: auto;
    margin-right: auto;
    min-width: 50rem;
  }
}
@media print and (min-width: 7px) {
  .forgot-password {
    margin-left: auto;
    margin-right: auto;
    min-width: 50rem;
  }
}

.tsgHeaderNavBarWrapper {
  height: 50px;
  background-color: #f1ede3;
}

.tsgHeaderNavBar {
  max-width: 1030px;
  margin: 0 auto;
  position: relative;
  padding-top: 3px;
}

.tsgHeaderImage {
  width: 100%;
  height: 226px;
  background: url("https://mypres.phs.org/Style%20Library/img/wellness/theater_2000x298_shadow.jpg") 0 0 no-repeat;
  background-size: cover;
}

.wellnessLogoCanvas {
  max-width: 1003px;
  margin: 0px auto 75px auto;
  padding-top: 10px;
}

.wellnessLogo {
  height: 125px;
}

@media only screen and (max-width: 1024px) {
  .tsgHeaderImage {
    height: 94px;
    background: url("https://mypres.phs.org/Style%20Library/img/wellness/theater_2000x94_shadow.jpg") 0 0 no-repeat;
  }
  .wellnessLogoCanvas {
    max-width: 768px;
    padding-left: 10px;
  }
  .wellnessLogo {
    height: 50px;
  }
}
/* Pages (e.g. Search Page, Article Page) */
.search-page {
  /*.results-count {
  	@include text(iota);
  	@include font-weight(medium);
  	color: inherit;
  	display: none;
  }*/
}
.search-page #typeahead {
  z-index: 8999;
  background-color: white;
  color: black;
  padding: 15px;
}
.search-page #typeahead a {
  color: black;
  font-size: 14px;
  text-decoration: none;
}
.search-page #typeahead .highlight {
  font-weight: 600;
  color: black;
}
.search-page .rbt-aux {
  display: none;
}
.search-page .typeahead-option {
  background-color: white;
  padding: 15px;
  text-decoration: none;
  color: black;
  display: flex;
  gap: 10px;
}
.search-page .typeahead-option .icon-star,
.search-page .typeahead-option .icon-doctor {
  fill: none;
  stroke-width: 1px;
  width: 15px !important;
}
.search-page .typeahead-option p {
  font-size: 13px;
  margin-bottom: 0.25rem;
}
.search-page.location-search #typeahead,
.search-page.location-search .rbt .rbt-aux,
.search-page.location-search .rbt-menu {
  display: none !important;
}
.search-page .dropdown-item:hover {
  text-decoration: none;
}
.search-page .typeahead-option:hover {
  text-decoration: none;
  background-color: #eee;
}
.search-page .typeahead-option:not(:last-child) {
  border-bottom: 1px solid var(#363534);
}
.search-page .results-list li {
  margin: 0;
}
.search-page .results-list li:before {
  display: none;
}
.search-page .mobile-sort-container {
  background-color: #eee;
  pointer-events: none;
  overflow: auto;
  visibility: hidden;
  opacity: 0;
  top: 20vh;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  transition: visibility 0s ease-in-out 400ms, opacity 0.2s ease-in-out, top 0.2s ease-in-out;
  z-index: 9;
}
.search-page .mobile-sort-container.sort-open {
  display: block;
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
  top: 0;
  transition-delay: 0s;
}
[class^=translated-] .search-page .mobile-sort-container.sort-open {
  height: calc(100% - 39px);
  top: 39px;
}
.search-page .mobile-sort-container .mobile-sort-header {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  align-items: center;
  background-color: #707070;
  color: #363534;
  display: flex;
  height: 48px;
  justify-content: space-between;
  position: relative;
}
@media (min-width: 768px) {
  .search-page .mobile-sort-container .mobile-sort-header {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .search-page .mobile-sort-container .mobile-sort-header {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .search-page .mobile-sort-container .mobile-sort-header {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .search-page .mobile-sort-container .mobile-sort-header {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .search-page .mobile-sort-container .mobile-sort-header {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .search-page .mobile-sort-container .mobile-sort-header {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.search-page .mobile-sort-container .mobile-sort-header .mobile-close-btn {
  font-size: 1.875rem;
  line-height: 1;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  background: none;
  border: none;
  color: #595756;
  line-height: 0;
  margin-left: -1rem;
  padding: 1rem;
  transform: translateX(1rem);
}
.search-page .mobile-sort-container .mobile-sort-header .mobile-close-btn .icon {
  height: 0.75rem;
  stroke: #fff;
  width: 0.75rem;
}
.search-page .mobile-sort-container .mobile-sort-header .mobile-close-btn:hover, .search-page .mobile-sort-container .mobile-sort-header .mobile-close-btn:focus {
  outline: 0;
}
.search-page .mobile-sort-container .mobile-sort-header .mobile-close-btn:hover .icon, .search-page .mobile-sort-container .mobile-sort-header .mobile-close-btn:focus .icon {
  fill: #00759a;
}
.search-page .mobile-sort-container .mobile-sort-header .sort-heading {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: #fff;
  margin: 0;
}
.search-page .mobile-sort-container .mobile-sort-body {
  display: flex;
  height: calc(100% - 48px);
  flex-direction: column;
  padding: 30px;
}
.search-page .mobile-sort-container .radio-wrapper label {
  color: #000;
}
.search-page .mobile-sort-container .mobile-sort-apply-btn {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  background-color: #363534;
  border-color: #363534;
  color: #fff;
  margin-top: auto;
  padding: 0.5rem 1rem;
}
.search-page .clear-all-btn {
  color: #00759a;
  text-decoration: underline;
}
.search-page .controls-top {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.25rem;
  width: 100%;
}
.search-page .view-mode-controls-container {
  align-items: center;
  background-color: #595756;
  display: none;
  justify-content: space-between;
  padding: 0.5rem;
}
.search-page .desktop-filters-toggle:hover {
  text-decoration: underline;
}
.search-page .view-btn {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 0.75rem;
}
.search-page .view-btn:last-of-type {
  margin-right: 0;
}
.search-page .view-btn .icon {
  fill: #bfbfbf;
}
.search-page .view-btn:hover .icon, .search-page .view-btn:focus .icon {
  fill: #000;
}
.search-page .view-btn[disabled] .icon {
  fill: #000;
}
.search-page .results-controls {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  background-color: rgba(112, 112, 112, 0.9);
  color: #fff;
  height: auto;
  padding-bottom: 6px;
  padding-top: 10px;
  position: fixed;
  top: 60px;
  z-index: 8;
}
@media (min-width: 768px) {
  .search-page .results-controls {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .search-page .results-controls {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .search-page .results-controls {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .search-page .results-controls {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .search-page .results-controls {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .search-page .results-controls {
    padding-right: 70px;
    padding-left: 70px;
  }
}
[class^=translated-] .search-page .results-controls {
  top: 99px;
}
.search-page .results-controls .controls-bottom {
  display: flex;
  justify-content: space-between;
}
.search-page .results-controls .controls-bottom .mobile-filters-toggle,
.search-page .results-controls .controls-bottom .mobile-sort-toggle {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  background-color: transparent;
  border: none;
  color: #fff;
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding: none;
}
.search-page .results-controls .controls-bottom button:disabled,
.search-page .results-controls .controls-bottom button[disabled] {
  color: #bfbfbf;
}
.search-page .results-controls .controls-bottom button:disabled svg,
.search-page .results-controls .controls-bottom button[disabled] svg {
  fill: #bfbfbf;
}
.search-page .results-controls.mobile-sort-open {
  z-index: 9;
}
.search-page .sort-select-wrapper {
  display: none;
}
.search-page .desktop-filters-toggle {
  display: none;
}
.search-page .mobile-filters-toggle {
  align-items: center;
  display: flex;
  height: 1.5rem;
  justify-content: flex-end;
  margin-left: auto;
  overflow: hidden;
}
.search-page .mobile-filters-toggle svg {
  fill: #707070;
  /*height: 3rem;*/
  stroke: #fff;
  /*transform: rotate(-90deg) translate(2px, 10px);*/
  width: 1.5rem;
}
.search-page .mobile-filters-toggle .filter-count {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  align-items: center;
  background-color: #a71930;
  border-radius: 50%;
  display: inline-flex;
  font-size: 11px;
  height: 13px;
  justify-content: center;
  margin-left: 0.25rem;
  margin-top: -5px;
  width: 13px;
}
.search-page .mobile-filters-toggle .filter-count .text {
  margin-top: 2px;
}
.search-page .mobile-sort-toggle {
  align-items: center;
  display: inline-flex;
  justify-content: flex-start;
}
.search-page .mobile-sort-toggle svg {
  fill: #fff;
  height: 11px;
  margin-right: 0.55rem;
  width: 11px;
  transform: translateY(-2px);
}
.search-page .search-page-results .results-controls {
  display: none;
}
.search-page .results-list-container {
  position: relative;
}
.search-page .results-list-container.loading {
  pointer-events: none;
}
.search-page .results-list-container.loading .loading-overlay {
  opacity: 1;
}
.search-page .contracted-link-container {
  display: none;
}
.search-page .contracted-link {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #00759a;
  display: none;
  text-decoration: none;
}
.search-page .contracted-link:hover, .search-page .contracted-link:focus {
  text-decoration: underline;
}
.search-page .filter-list .contracted-link {
  display: block;
  padding: 1rem 30px 30px;
}
.search-page .pcp-tooltip {
  width: 100%;
}
@media (min-width: 768px) {
  .search-page {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .search-page {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 768px) and (min-width: 6px) {
  .search-page {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 768px) and (min-width: 1280px) {
  .search-page {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 768px) and (min-width: 7px) {
  .search-page {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 768px) and (min-width: 1440px) {
  .search-page {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 768px) and (min-width: 1440px) {
  .search-page {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 768px) {
  .search-page .results-list {
    padding: 0 30px;
  }
  .search-page .results-list.view-mode-grid {
    display: flex;
    flex-wrap: wrap;
  }
  .search-page .results-list.view-mode-grid .results-list-item {
    flex: calc(50% - 10px);
    margin-bottom: 1.25rem;
    max-width: calc(50% - 10px);
  }
  .search-page .results-list.view-mode-grid .results-list-item:nth-of-type(2n + 1) {
    margin-right: 10px;
  }
  .search-page .results-list.view-mode-grid .results-list-item:nth-of-type(2n + 2) {
    margin-left: 10px;
  }
  .search-page .results-list.view-mode-grid .provider-result {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 0;
    padding-bottom: 1rem;
  }
  .search-page .results-list.view-mode-grid .provider-result .provider-details {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .search-page .results-list.view-mode-grid .provider-result .detail-bottom {
    border: none;
    margin-top: auto;
    padding-top: 0;
  }
  .search-page .results-list.view-mode-grid .provider-result .schedule-title {
    border-top: 1px solid #bfbfbf;
    margin-top: 1.5rem;
    padding-top: 1.5rem;
  }
  .search-page .results-list.view-mode-grid .provider-result .group-and-specialty {
    min-height: 38px;
  }
  .search-page .results-list.view-mode-grid .provider-result .ratings {
    height: 21px;
  }
}
@media (min-width: 768px) {
  .search-page .view-mode-controls-container {
    display: flex;
  }
}
@media (min-width: 768px) {
  .search-page .pcp-tooltip {
    width: 300px;
  }
}
@media print and (min-width: 6px) {
  .search-page {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
  }
}
@media print and (min-width: 6px) and (min-width: 768px) {
  .search-page {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) and (min-width: 6px) {
  .search-page {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) and (min-width: 1280px) {
  .search-page {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 6px) and (min-width: 7px) {
  .search-page {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 6px) and (min-width: 1440px) {
  .search-page {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 6px) and (min-width: 1440px) {
  .search-page {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 6px) {
  .search-page .results-list {
    padding: 0 30px;
  }
  .search-page .results-list.view-mode-grid {
    display: flex;
    flex-wrap: wrap;
  }
  .search-page .results-list.view-mode-grid .results-list-item {
    flex: calc(50% - 10px);
    margin-bottom: 1.25rem;
    max-width: calc(50% - 10px);
  }
  .search-page .results-list.view-mode-grid .results-list-item:nth-of-type(2n + 1) {
    margin-right: 10px;
  }
  .search-page .results-list.view-mode-grid .results-list-item:nth-of-type(2n + 2) {
    margin-left: 10px;
  }
  .search-page .results-list.view-mode-grid .provider-result {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 0;
    padding-bottom: 1rem;
  }
  .search-page .results-list.view-mode-grid .provider-result .provider-details {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .search-page .results-list.view-mode-grid .provider-result .detail-bottom {
    border: none;
    margin-top: auto;
    padding-top: 0;
  }
  .search-page .results-list.view-mode-grid .provider-result .schedule-title {
    border-top: 1px solid #bfbfbf;
    margin-top: 1.5rem;
    padding-top: 1.5rem;
  }
  .search-page .results-list.view-mode-grid .provider-result .group-and-specialty {
    min-height: 38px;
  }
  .search-page .results-list.view-mode-grid .provider-result .ratings {
    height: 21px;
  }
}
@media print and (min-width: 6px) {
  .search-page .view-mode-controls-container {
    display: flex;
  }
}
@media print and (min-width: 6px) {
  .search-page .pcp-tooltip {
    width: 300px;
  }
}
.search-page .results-count {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  color: inherit;
}
.search-page .search-page-controls .results-count {
  display: none;
}
@media (min-width: 1280px) {
  .search-page {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
    /*.results-count {
    	@include text(lambda);
    	@include font-weight(medium);
    	margin-bottom: 0;
    	display: block;
    }*/
  }
}
@media (min-width: 1280px) and (min-width: 768px) {
  .search-page {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 1280px) and (min-width: 6px) {
  .search-page {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) and (min-width: 1280px) {
  .search-page {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1280px) and (min-width: 7px) {
  .search-page {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1280px) and (min-width: 1440px) {
  .search-page {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1280px) and (min-width: 1440px) {
  .search-page {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1280px) {
  .search-page .results-list {
    padding: 0;
  }
}
@media (min-width: 1280px) {
  .search-page .search-page-controls {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
    align-items: center;
  }
}
@media (min-width: 1280px) {
  .search-page .desktop-toggle-container {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 10px;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
}
@media (min-width: 1280px) {
  .search-page .view-mode-controls-container {
    background-color: transparent;
    padding: 0;
  }
}
@media (min-width: 1280px) {
  .search-page .results-controls {
    align-items: center;
    background-color: transparent;
    color: #000;
    display: none;
    height: 3rem;
    justify-content: space-between;
    padding: 0;
    position: relative;
    top: 0;
  }
  [class^=translated-] .search-page .results-controls {
    top: 0;
  }
}
@media (min-width: 1280px) {
  .search-page .sort-select-wrapper {
    display: block;
    position: relative;
    margin-left: auto;
    margin-right: 1rem;
  }
  .search-page .sort-select-wrapper select {
    font-size: 0.875rem;
    line-height: 1.4285714286;
    font-family: AvenirMedium, Helvetica, sans-serif;
    font-weight: normal;
    background-color: transparent;
    border: 0;
    color: #000;
    padding: 0 1.5rem 0 0;
    text-align-last: right;
    /* for Firefox */
    -moz-appearance: none;
    /* for Chrome */
    -webkit-appearance: none;
    /* For IE10 */
  }
  .search-page .sort-select-wrapper select::-ms-expand {
    display: none;
  }
  .search-page .sort-select-wrapper select:hover {
    cursor: pointer;
  }
  .search-page .sort-select-wrapper .icon {
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media (min-width: 1280px) {
  .search-page .search-page-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  .search-page .search-page-wrapper.filters-closed .filters-container {
    display: none;
  }
  .search-page .search-page-wrapper.filters-closed .search-page-results {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .search-page .search-page-wrapper.filters-closed .search-page-results .results-controls:first-child {
    justify-content: flex-end;
    margin-left: -10px;
    margin-right: -10px;
    pointer-events: none;
    width: calc(100% + 20px);
  }
  .search-page .search-page-wrapper.filters-closed .search-page-results .results-controls:first-child .controls-top {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 10px;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0;
    padding: 0 10px;
    padding-bottom: 0.25rem;
    pointer-events: auto;
    flex: 0 0 66.7%;
    max-width: 66.7%;
  }
  .search-page .search-page-wrapper.filters-closed .search-page-results .results-control {
    width: calc(50% - 10px);
    text-align: center;
  }
  .search-page .search-page-wrapper.filters-closed .results-list.view-mode-grid .results-list-item {
    flex: calc(33.333% - 13.3333333333px);
    max-width: calc(33.333% - 13.3333333333px);
  }
  .search-page .search-page-wrapper.filters-closed .results-list.view-mode-grid .results-list-item:nth-of-type(3n + 1) {
    margin-left: 0;
    margin-right: 10px;
  }
  .search-page .search-page-wrapper.filters-closed .results-list.view-mode-grid .results-list-item:nth-of-type(3n + 2) {
    margin-left: 10px;
    margin-right: 10px;
  }
  .search-page .search-page-wrapper.filters-closed .results-list.view-mode-grid .results-list-item:nth-of-type(3n + 3) {
    margin-left: 10px;
    margin-right: 0;
  }
  .search-page .search-page-wrapper.filters-closed .provider-result:not(.grid-result) .profile-image-outer {
    width: 350px;
  }
  .search-page .search-page-wrapper.filters-closed .provider-result:not(.grid-result) .profile-image-container {
    padding-bottom: 66.666%;
  }
  .search-page .search-page-wrapper.filters-closed .provider-result:not(.grid-result) .provider-details {
    width: calc(100% - 350px);
  }
}
@media (min-width: 1280px) {
  .search-page .filters-container {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 10px;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
}
@media (min-width: 1280px) {
  .search-page .filter-list {
    margin-bottom: 4rem;
    padding: 0.625rem 10px 0;
  }
  .search-page .filter-list .contracted-link {
    display: none;
  }
  .search-page .filter-list + .contracted-link-container {
    display: block;
    margin-top: -3rem;
    padding: 0 26px 1rem;
  }
  .search-page .filter-list + .contracted-link-container .contracted-link {
    display: inline-block;
    padding: 0;
  }
}
@media (min-width: 1280px) {
  .search-page .desktop-filters-toggle {
    font-size: 0.875rem;
    line-height: 1px;
    font-family: AvenirMedium, Helvetica, sans-serif;
    font-weight: normal;
    align-items: center;
    display: flex;
    margin: 1rem 0;
  }
  .search-page .desktop-filters-toggle .icon {
    height: 0.75rem;
    width: 1.25rem;
  }
}
@media (min-width: 1280px) {
  .search-page .mobile-filters-toggle,
.search-page .mobile-sort-toggle {
    display: none;
  }
}
@media (min-width: 1280px) {
  .search-page .search-page-results {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 10px;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .search-page .search-page-results .results-controls {
    display: flex;
    margin-top: -3rem;
    width: 100%;
  }
}
@media (min-width: 1280px) {
  .search-page .provider-result:not(.grid-result) .profile-image-outer {
    width: 160px;
  }
  .search-page .provider-result:not(.grid-result) .profile-image-container {
    padding-bottom: 100%;
  }
  .search-page .provider-result:not(.grid-result) .provider-details {
    width: calc(100% - 160px);
  }
}
@media (min-width: 1280px) {
  .search-page .pcp-tooltip {
    width: 100%;
  }
}
@media print and (min-width: 7px) {
  .search-page {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
    /*.results-count {
    	@include text(lambda);
    	@include font-weight(medium);
    	margin-bottom: 0;
    	display: block;
    }*/
  }
}
@media print and (min-width: 7px) and (min-width: 768px) {
  .search-page {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 7px) and (min-width: 6px) {
  .search-page {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 7px) and (min-width: 1280px) {
  .search-page {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) and (min-width: 7px) {
  .search-page {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) and (min-width: 1440px) {
  .search-page {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) and (min-width: 1440px) {
  .search-page {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .search-page .results-list {
    padding: 0;
  }
}
@media print and (min-width: 7px) {
  .search-page .search-page-controls {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
    align-items: center;
  }
}
@media print and (min-width: 7px) {
  .search-page .desktop-toggle-container {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 10px;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
}
@media print and (min-width: 7px) {
  .search-page .view-mode-controls-container {
    background-color: transparent;
    padding: 0;
  }
}
@media print and (min-width: 7px) {
  .search-page .results-controls {
    align-items: center;
    background-color: transparent;
    color: #000;
    display: none;
    height: 3rem;
    justify-content: space-between;
    padding: 0;
    position: relative;
    top: 0;
  }
  [class^=translated-] .search-page .results-controls {
    top: 0;
  }
}
@media print and (min-width: 7px) {
  .search-page .sort-select-wrapper {
    display: block;
    position: relative;
    margin-left: auto;
    margin-right: 1rem;
  }
  .search-page .sort-select-wrapper select {
    font-size: 0.875rem;
    line-height: 1.4285714286;
    font-family: AvenirMedium, Helvetica, sans-serif;
    font-weight: normal;
    background-color: transparent;
    border: 0;
    color: #000;
    padding: 0 1.5rem 0 0;
    text-align-last: right;
    /* for Firefox */
    -moz-appearance: none;
    /* for Chrome */
    -webkit-appearance: none;
    /* For IE10 */
  }
  .search-page .sort-select-wrapper select::-ms-expand {
    display: none;
  }
  .search-page .sort-select-wrapper select:hover {
    cursor: pointer;
  }
  .search-page .sort-select-wrapper .icon {
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media print and (min-width: 7px) {
  .search-page .search-page-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  .search-page .search-page-wrapper.filters-closed .filters-container {
    display: none;
  }
  .search-page .search-page-wrapper.filters-closed .search-page-results {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .search-page .search-page-wrapper.filters-closed .search-page-results .results-controls:first-child {
    justify-content: flex-end;
    margin-left: -10px;
    margin-right: -10px;
    pointer-events: none;
    width: calc(100% + 20px);
  }
  .search-page .search-page-wrapper.filters-closed .search-page-results .results-controls:first-child .controls-top {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 10px;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0;
    padding: 0 10px;
    padding-bottom: 0.25rem;
    pointer-events: auto;
    flex: 0 0 66.7%;
    max-width: 66.7%;
  }
  .search-page .search-page-wrapper.filters-closed .search-page-results .results-control {
    width: calc(50% - 10px);
    text-align: center;
  }
  .search-page .search-page-wrapper.filters-closed .results-list.view-mode-grid .results-list-item {
    flex: calc(33.333% - 13.3333333333px);
    max-width: calc(33.333% - 13.3333333333px);
  }
  .search-page .search-page-wrapper.filters-closed .results-list.view-mode-grid .results-list-item:nth-of-type(3n + 1) {
    margin-left: 0;
    margin-right: 10px;
  }
  .search-page .search-page-wrapper.filters-closed .results-list.view-mode-grid .results-list-item:nth-of-type(3n + 2) {
    margin-left: 10px;
    margin-right: 10px;
  }
  .search-page .search-page-wrapper.filters-closed .results-list.view-mode-grid .results-list-item:nth-of-type(3n + 3) {
    margin-left: 10px;
    margin-right: 0;
  }
  .search-page .search-page-wrapper.filters-closed .provider-result:not(.grid-result) .profile-image-outer {
    width: 350px;
  }
  .search-page .search-page-wrapper.filters-closed .provider-result:not(.grid-result) .profile-image-container {
    padding-bottom: 66.666%;
  }
  .search-page .search-page-wrapper.filters-closed .provider-result:not(.grid-result) .provider-details {
    width: calc(100% - 350px);
  }
}
@media print and (min-width: 7px) {
  .search-page .filters-container {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 10px;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 10px;
  }
}
@media print and (min-width: 7px) {
  .search-page .filter-list {
    margin-bottom: 4rem;
    padding: 0.625rem 10px 0;
  }
  .search-page .filter-list .contracted-link {
    display: none;
  }
  .search-page .filter-list + .contracted-link-container {
    display: block;
    margin-top: -3rem;
    padding: 0 26px 1rem;
  }
  .search-page .filter-list + .contracted-link-container .contracted-link {
    display: inline-block;
    padding: 0;
  }
}
@media print and (min-width: 7px) {
  .search-page .desktop-filters-toggle {
    font-size: 0.875rem;
    line-height: 1px;
    font-family: AvenirMedium, Helvetica, sans-serif;
    font-weight: normal;
    align-items: center;
    display: flex;
    margin: 1rem 0;
  }
  .search-page .desktop-filters-toggle .icon {
    height: 0.75rem;
    width: 1.25rem;
  }
}
@media print and (min-width: 7px) {
  .search-page .mobile-filters-toggle,
.search-page .mobile-sort-toggle {
    display: none;
  }
}
@media print and (min-width: 7px) {
  .search-page .search-page-results {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 10px;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    margin-bottom: 0;
    padding: 0 10px;
  }
  .search-page .search-page-results .results-controls {
    display: flex;
    margin-top: -3rem;
    width: 100%;
  }
}
@media print and (min-width: 7px) {
  .search-page .provider-result:not(.grid-result) .profile-image-outer {
    width: 160px;
  }
  .search-page .provider-result:not(.grid-result) .profile-image-container {
    padding-bottom: 100%;
  }
  .search-page .provider-result:not(.grid-result) .provider-details {
    width: calc(100% - 160px);
  }
}
@media print and (min-width: 7px) {
  .search-page .pcp-tooltip {
    width: 100%;
  }
}

.pls-profile-section .modal-open-btn {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
}
@media (min-width: 768px) {
  .pls-profile-section .modal-open-btn {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .pls-profile-section .modal-open-btn {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .pls-profile-section .modal-open-btn {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .pls-profile-section .modal-open-btn {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .pls-profile-section .modal-open-btn {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .pls-profile-section .modal-open-btn {
    padding-right: 70px;
    padding-left: 70px;
  }
}

/* Technology Test */
.form-page {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
}
@media (min-width: 768px) {
  .form-page {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media print and (min-width: 6px) {
  .form-page {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .form-page {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 7px) {
  .form-page {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .form-page {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media print and (min-width: 1440px) {
  .form-page {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.form-page.form-submitted .form-request {
  display: none;
}
.form-page.form-submitted .form-response {
  display: block;
}
.form-page.form-resubmitted .form-response button {
  display: none;
}
.form-page.form-resubmitted .form-response .alert {
  display: block;
  text-align: center;
}
.form-page .form-request {
  justify-content: center;
}
.form-page .form-response {
  display: none;
}
.form-page .form-response .alert {
  display: none;
}

/* Layouts (e.g. Main, Styleguide) */
.at {
  background-color: #eee;
}
.at main {
  background-color: #eee;
}

body {
  background-color: #fff;
  overflow-x: hidden;
}
body main {
  background-color: #fff;
  min-height: 300px;
}
@media (min-width: 768px) {
  body main {
    min-height: calc(100vh - 250px);
  }
}
@media print and (min-width: 6px) {
  body main {
    min-height: calc(100vh - 250px);
  }
}

.styleguide main section {
  margin: 4rem auto;
}
.styleguide hr {
  margin: 1.5rem 0;
}
.styleguide .table-of-contents a {
  font-size: 1.375rem;
  line-height: 1.4545454545;
}
.styleguide .color-box-list {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-direction: column;
}
.styleguide .color-box {
  color: #fff;
  height: 250px;
  padding-top: 1rem;
  text-align: center;
}
@media (min-width: 414px) {
  .styleguide .color-box-list {
    flex-direction: row;
  }
  .styleguide .color-box {
    width: 50%;
  }
}
@media print and (min-width: 5px) {
  .styleguide .color-box-list {
    flex-direction: row;
  }
  .styleguide .color-box {
    width: 50%;
  }
}
@media (min-width: 768px) {
  .styleguide .color-box {
    height: 324px;
    width: 33.3333333333%;
  }
}
@media print and (min-width: 6px) {
  .styleguide .color-box {
    height: 324px;
    width: 33.3333333333%;
  }
}
@media (min-width: 1280px) {
  .styleguide .color-box {
    height: 400px;
    width: 16.6666666667%;
  }
}
@media print and (min-width: 7px) {
  .styleguide .color-box {
    height: 400px;
    width: 16.6666666667%;
  }
}

.t3 {
  background-color: #eee;
}
.t3.showing-modal .main-header {
  overflow: hidden;
}
.t3 main {
  background-color: #eee;
}
.t3 section {
  align-items: center;
  display: flex;
  margin-top: -2rem;
  min-height: calc(100vh - 60px - 50px);
  padding-bottom: 2.5rem;
  padding-top: 2rem;
}
@supports (-webkit-touch-callout: none) {
  .t3 section {
    min-height: calc(
				100vh - 60px - 50px - 122px
			);
  }
}
@media (min-width: 768px) {
  .t3 section {
    margin-top: -60px;
    min-height: calc(100vh - 60px - 160px);
    padding-top: 6rem;
    padding-bottom: 5rem;
  }
}
@media print and (min-width: 6px) {
  .t3 section {
    margin-top: -60px;
    min-height: calc(100vh - 60px - 160px);
    padding-top: 6rem;
    padding-bottom: 5rem;
  }
}

.heart-and-vascular a {
  color: #00759a;
  text-decoration: underline;
}
.heart-and-vascular.navbar-fixed {
  padding-top: 60px;
  transition: margin-top 0.3s ease-in-out;
}
.heart-and-vascular.navbar-fixed.mobile-nav-open {
  margin-top: 100vh;
}
.heart-and-vascular.navbar-scroll-up {
  padding-top: 60px;
}
.heart-and-vascular section {
  padding-bottom: 0.75rem;
}
@media (min-width: 768px) {
  .heart-and-vascular a {
    text-decoration: none;
  }
  .heart-and-vascular a:hover {
    text-decoration: underline;
  }
}
@media print and (min-width: 6px) {
  .heart-and-vascular a {
    text-decoration: none;
  }
  .heart-and-vascular a:hover {
    text-decoration: underline;
  }
}
@media (min-width: 1024px) {
  .heart-and-vascular.navbar-fixed, .heart-and-vascular.navbar-scroll-up {
    padding-top: 0;
    transition: none;
  }
}

.portal .select-wrapper-plan,
.portal .select-wrapper {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #363534;
  position: relative;
}
.portal .select-wrapper-plan .arrow-icon-wrapper,
.portal .select-wrapper .arrow-icon-wrapper {
  pointer-events: none;
  position: absolute;
  right: 1.25rem;
  top: 30%;
}
.portal .select-wrapper-plan .arrow-icon-wrapper .arrow-down,
.portal .select-wrapper .arrow-icon-wrapper .arrow-down {
  stroke: #000;
}
.portal .select-wrapper-plan .select-title,
.portal .select-wrapper .select-title {
  font-size: 0.75rem;
  line-height: 1.5;
  color: #363534;
}
.portal .select-wrapper-plan[aria-expanded=true] .arrow-down,
.portal .select-wrapper[aria-expanded=true] .arrow-down {
  transform: rotate(180deg);
}
.portal .section-description {
  font-size: 1rem;
  line-height: 1.375;
}
.portal a,
.portal .nav-btn {
  color: #00759a;
  text-decoration: none;
}
.portal a li,
.portal .nav-btn li {
  margin-bottom: 0.5rem;
}
.portal a:hover,
.portal .nav-btn:hover {
  text-decoration: underline;
}
.portal .nav-btn {
  display: block;
  margin-bottom: 0.8rem;
}
.portal .title-section {
  margin-bottom: 2rem;
}
.portal .title-section .second-header {
  font-size: 1.5rem;
  line-height: 1.3333333333;
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  margin-bottom: 0.25rem;
}
.portal .title-section .section-description:last-of-type {
  margin-bottom: 0.75rem;
}
.portal .title-section .btn-link {
  background-color: #363534;
  border-color: #363534;
  color: #fff;
  margin-bottom: 2rem;
  width: 100%;
}
.portal .external-link {
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
}
.portal .external-link span {
  padding-right: 0.25rem;
}
.portal .external-link:focus, .portal .external-link:hover {
  text-decoration: none;
}
.portal .external-link:focus span, .portal .external-link:hover span {
  text-decoration: underline;
}
.portal .external-link .icon-external {
  height: 10px;
  stroke: #00759a;
  width: 10px;
}
.portal .print-btn:hover .icon-print, .portal .print-btn:focus .icon-print {
  stroke: #363534;
}
.portal .print-btn .icon-print {
  height: 20px;
  stroke: #595756;
  width: 20px;
}
.portal .portal-list {
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
}
.portal .portal-list li {
  border-bottom: 1px solid #bfbfbf;
  padding: 0.5rem;
}
.portal .active {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  color: #000;
}
.portal .grid-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}
.portal .first-header {
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.3333333333;
  color: #a71930 !important;
  margin-bottom: 1rem;
}
.portal .second-header-big {
  font-size: 1.125rem;
  line-height: 1.2777777778;
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  color: #363534;
  margin-bottom: 1rem;
  padding: 1rem 2rem;
}
.portal .second-header {
  font-family: AvenirMedium, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.5;
  margin-bottom: 1rem;
}
.portal .sub-header {
  font-family: AvenirHeavy, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.3333333333;
  color: #000;
  margin-bottom: 1.5rem;
}
.portal .informational {
  font-family: AvenirRoman, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.4285714286;
  margin: 0.5rem 0;
}
.portal .section-break {
  background-color: #bfbfbf;
  border: 1px solid #bfbfbf;
  margin-top: 2rem;
}
.portal .no-wrap {
  white-space: nowrap;
}
.portal .icon-arrow-up {
  height: 7.42px;
  width: 7.5px;
}
.portal .icon-download {
  height: 11.89px;
  width: 10px;
}
@media (min-width: 768px) {
  .portal .first-header {
    font-family: AvenirLight, Helvetica, sans-serif;
    font-weight: normal;
    font-size: 1.875rem;
    line-height: 1;
  }
}
@media print and (min-width: 6px) {
  .portal .first-header {
    font-family: AvenirLight, Helvetica, sans-serif;
    font-weight: normal;
    font-size: 1.875rem;
    line-height: 1;
  }
}
@media (min-width: 1280px) {
  .portal .select-wrapper-plan,
.portal .select-wrapper {
    font-size: 1rem;
    line-height: 1.375;
  }
  .portal .second-header {
    font-size: 1.25rem;
    line-height: 1.5;
    font-family: AvenirHeavy, Helvetica, sans-serif;
    font-weight: normal;
    color: #363534;
    margin-bottom: 1rem;
  }
  .portal .rail-header {
    font-size: 1.125rem;
    line-height: 1.2777777778;
    font-family: AvenirHeavy, Helvetica, sans-serif;
    font-weight: normal;
    color: #363534;
    margin-bottom: 1rem;
  }
  .portal .text-content li {
    margin-bottom: 1rem;
  }
  .portal main h1 {
    font-size: 1.875rem;
    line-height: 1;
    font-family: AvenirLight, Helvetica, sans-serif;
    font-weight: normal;
    color: #a71930;
    margin-bottom: 1rem;
  }
  .portal main .overview-text {
    font-size: 1.5rem;
    line-height: 1.3333333333;
    font-family: AvenirLight, Helvetica, sans-serif;
    font-weight: normal;
    color: #363534;
    margin-bottom: 3rem;
  }
  .portal .title-section .btn-link {
    width: 80%;
  }
}
@media print and (min-width: 7px) {
  .portal .select-wrapper-plan,
.portal .select-wrapper {
    font-size: 1rem;
    line-height: 1.375;
  }
  .portal .second-header {
    font-size: 1.25rem;
    line-height: 1.5;
    font-family: AvenirHeavy, Helvetica, sans-serif;
    font-weight: normal;
    color: #363534;
    margin-bottom: 1rem;
  }
  .portal .rail-header {
    font-size: 1.125rem;
    line-height: 1.2777777778;
    font-family: AvenirHeavy, Helvetica, sans-serif;
    font-weight: normal;
    color: #363534;
    margin-bottom: 1rem;
  }
  .portal .text-content li {
    margin-bottom: 1rem;
  }
  .portal main h1 {
    font-size: 1.875rem;
    line-height: 1;
    font-family: AvenirLight, Helvetica, sans-serif;
    font-weight: normal;
    color: #a71930;
    margin-bottom: 1rem;
  }
  .portal main .overview-text {
    font-size: 1.5rem;
    line-height: 1.3333333333;
    font-family: AvenirLight, Helvetica, sans-serif;
    font-weight: normal;
    color: #363534;
    margin-bottom: 3rem;
  }
  .portal .title-section .btn-link {
    width: 80%;
  }
}

:lang(en) .show-for-english {
  display: block;
}
:lang(en) .show-for-spanish {
  display: none;
}

:lang(es) .show-for-english {
  display: none;
}
:lang(es) .show-for-spanish {
  display: block;
}