.search-banner {
	background-color: $color-gray-providers-banner;
	display: flex;
	margin-bottom: 1rem;
	padding: 30px;
	position: relative;

	&.desktop-only {
		display: none;
	}

	.search-page .search-page-results & {
		+ .results-controls {
			margin-top: 1rem;
		}
	}

	.close-btn {
		align-items: center;
		display: flex;
		height: 15px;
		justify-content: center;
		position: absolute;
		right: 15px;
		top: 15px;
		width: 15px;

		.icon {
			width: 11px;
			height: 11px;
		}

		&:hover,
		&:focus {
			.icon {
				stroke-width: 3;
			}
		}
	}

	.overflow-wrap {
		white-space: nowrap;
		overflow: hidden;
		overflow-wrap: breakF-word;
		text-overflow: ellipsis;
		width: 250px;
	}
	.drop-down-icon {
		align-items: center;
		display: flex;
		height: 18px;
		justify-content: center;
		position: absolute;
		stroke: $color-gray-medium-light;
		top: 50%;
		transform: translateY(-50%);
		width: 18px;
	}
	.drop-down-icon--is-rotate {
		transform: rotate(180deg) translateY(50%);
	}

	.image-container {
		flex: 0 1 55px;
		max-width: 55px;

		img {
			width: 31px;
		}

		.icon-map-marker {
			width: 31px;
			height: 37px;
			fill: $color-red;
		}
	}

	.banner-content {
		flex: 1;
	}

	p {
		@include text(lambda, 19);
		@include font-weight(medium);
		color: $color-black;
		margin-bottom: 0.625rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	a {
		@include text(lambda, 19);
		@include font-weight(medium);
		color: $color-blue;

		&.multiplan-website {
			.icon-external {
				height: 0.5rem;
				margin-left: 0.25rem;
				stroke: $color-blue;
				width: 0.5rem;
			}
		}
	}

	@include breakpoint(md) {
		padding: 30px 35px;

		.close-btn {
			right: 20px;
		}
	}

	@include breakpoint(lg) {
		&.desktop-only {
			display: flex;
		}
	}
}
