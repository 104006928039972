.hv-awards-and-accreditations {
	border-bottom: 1px solid $color-gray-neutral;
	padding-bottom: 2.5rem;
	margin-bottom: 2.5rem;

	.title {
		margin-bottom: 0.75rem;
	}
	.awards-paragraph {
		@include text(kappa);
		@include font-weight(semi-bold);
		margin-bottom: 0.5rem;
	}

	.read-more-link {
		color: $color-blue;
		border-right: 1px solid $color-gray-neutral;
		height: 16px;
		padding-right: 0.5rem;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	.award-site {
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
	.award-site-inner {
		@include text(lambda);
		@include font-weight(medium);
		color: $color-blue;
		padding-left: 0.5rem;
	}

	.icon-arrow-down {
		height: 0.4rem;
		stroke: $color-blue;
		stroke-width: 3px;
	}

	.icon-external {
		height: 0.5rem;
		stroke: $color-blue;
	}
}
