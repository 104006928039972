.header {
	.utility-header {
		display: none;
	}

	.main-header {
		@include make-container();
		align-items: center;
		display: flex;
		height: 60px;
		justify-content: space-between;
		transition: margin-top 0.3s ease-in-out;

		.icon-phs-logo {
			fill: $color-red;
		}

		.logo-container {
			height: 32px;
		}

		.logo-subtitle {
			display: none;
		}
	}

	.navbar-fixed & {
		top: 0;
		position: fixed;
		width: 100%;
		z-index: 9;

		.main-header {
			margin-top: -60px;
			transition: none;
		}
	}

	.navbar-scroll-up & {
		top: 0;
		position: fixed;
		width: 100%;
		z-index: 9;

		[class^='translated-'] & {
			top: 39px;
		}

		.main-header {
			animation: mobile-nav-slide-down 0.2s;
			margin-top: 0;

			@keyframes mobile-nav-slide-down {
				from {
					margin-top: -60px;
				}
				to {
					margin-top: 0;
				}
			}
		}
	}

	.navbar-scroll-down & {
		.main-header {
			animation: mobile-nav-slide-up 0.2s;
			margin-top: -60px;
			pointer-events: none;
			position: fixed;

			@keyframes mobile-nav-slide-up {
				from {
					margin-top: 0;
				}
				to {
					margin-top: -60px;
				}
			}
		}
	}

	@include breakpoint(lg) {
		.navbar-fixed &,
		.navbar-scroll-up & {
			position: relative;

			.main-header {
				margin-top: 0;
			}
		}

		.search-open & {
			.utility-header {
				display: none;
			}
		}
		.main-header {
			height: 90px;

			.logo-container {
				height: 65px;
			}

			.logo-subtitle {
				@include text(theta, iota);
				color: $color-black;
				display: block;
				margin-bottom: 0;
				padding-left: 34px;

				.logo-beta {
					color: $color-gray-medium;
				}
			}
		}

		.icon-phs-logo {
			height: calc(40.717px * 0.793);
			width: calc(353px * 0.793);
		}

		.utility-header {
			background: linear-gradient($color-gray-dark, $color-black);
			display: block;
			transition: margin-top 0.3s ease-in-out;

			.utility-navigation {
				@include make-container();
				align-items: center;
				display: flex;
				height: 40px;
			}

			a {
				color: $color-gray-neutral;
				display: inline-block;
				padding: 0.5rem 2.5rem 0.5rem 0;
				text-decoration: none;

				&:hover,
				&:focus {
					color: $color-gray-light;
				}
			}
		}
	}

	@include breakpoint(lg) {
		.utility-header {
			transition: none;
		}

		.main-header {
			transition: none;
		}
	}
}
