.at-footer-container {
	@include make-container();
	background-color: $color-gray-dark;
	color: $color-off-white;
	padding-bottom: 52px;
	padding-top: 35px;

	.mobile-nav & {
		.back-to-top-btn {
			display: none;
		}
	}

	a {
		color: $color-off-white;
	}

	.assistance-title {
		@include font-weight(bold);
		@include text(iota);
		align-items: center;
		justify-content: space-between;
		margin-bottom: 24px;
		color: $color-off-white;
		text-align: left;
		display: flex;
	}

	.back-to-top-btn {
		align-items: center;
		display: flex;
		padding: 0;

		.icon-arrow-down {
			stroke: $color-off-white;
			stroke-width: 4px;
			transform: rotate(180deg);
		}
	}

	.customer-service {
		margin-bottom: 32px;

		p {
			@include text(iota);
			margin-bottom: 0;
		}
	}

	.customer-service-desktop {
		display: none;
	}

	.doximity-section {
		margin-top: 2rem;

		p {
			@include text(kappa);
		}
	}

	.link-list {
		@include text(lambda);
		columns: 2;

		li {
			margin-bottom: 5px;
		}
	}

	.help-title {
		display: none;
	}

	@include breakpoint(md) {
		display: flex;

		div {
			flex: 1 1 auto;
		}

		.back-to-top-btn {
			display: none;
		}

		.help-title {
			color: $color-blue-bright;
			display: block;
		}
		.customer-service {
			margin-bottom: 0;
			padding-right: 50px;
		}

		.customer-service-desktop {
			display: block;
		}

		.customer-service-mobile {
			display: none;
		}

		.doximity-section {
			margin-top: 20px;
		}

		.assistance-title {
			display: block;
			flex: 0 0 20%;
			margin-bottom: 0;
			padding-right: 1rem;
		}

		p {
			margin-bottom: 0.2rem;
		}
	}

	@include breakpoint(lg) {
		.customer-service {
			padding-right: 100px;
		}
	}
}
