.contact-us-methods {
    margin-top: 0rem;

    .grid-row {
        @include make-row();
        justify-content: left;
    }

    .header-block {
        padding-left: 1.2rem;

        .language {
            display: flex;
            justify-content: right;
        }
    }

    .inner-block {
        background-color: $color-white;
        padding: 0rem 0rem;

        .description {
            @include text(theta);
            @include font-weight(bold);
            color: $color-gray-dark;
        }

        padding-bottom: 0px;
    }

    .helpertext {
        margin-left: auto;
        margin-right: auto;
        padding-top: .5rem;
        padding-bottom: 0rem;
        width: 100%;
        //text-align: center;
        font-size: 0.875rem;
        font-family: 'AvenirMedium';

        i:first-of-type {
            font-weight: bolder;
        }

        display: flex;
        gap: 10px;
        flex-direction: column;

        p {
            font-size: 1.0rem;
            margin-top: 10px;
        }
    }

    .link-box {
        @include text(lambda);
        background-color: $color-white;
        margin: 1rem 1rem -2rem 1rem;
        padding: 3.2rem;
        border: 1px solid grey;

        .btn-primary {
            font-size: 0.9rem;
            line-height: 1.4285714286;
            font-family: AvenirBook, Helvetica, sans-serif;
            font-weight: bolder;
            background-color: #fff;
            color: #363534;
            padding: 0.4rem 0.1rem;
            white-space: normal;
            width: 100%;
            border: 1px solid gray;

            &:hover {
                text-decoration: none;
                background-color: $color-black;
                color: $color-white;
            }
        }

        p {
            font-size: 1.1rem;
            line-height: 1.4285714286;
            text-align: center;
            font-weight: bolder;
        }

        .icon {
            border: $color-green-t3;
            display: block;
            fill: #595756;
            height: 6rem;
            margin: 1rem auto;
            stroke: transparent;
            stroke-width: 0px;
            width: 6rem;
        }
    }

    @include breakpoint(md) {
        //@include make-container();
        margin-top: 0rem;
        margin-bottom: 0rem;

        .header-block {
            padding-left: 0;
        }

        .inner-block {
            background-color: $color-white;
            padding: 0rem 0rem 0rem;
            padding-bottom: 0px;
            width: 120%;
        }

        .link-box {
            background-color: $color-white;
            margin: 1rem 1rem 1rem 1rem;
            padding: 1rem;
        }
    }

    @include breakpoint(lg) {
        margin-top: 0rem;
        margin-bottom: -2rem;
    }
}
