.action-column {
	margin: 1rem 0 2rem;

	&.has-search {
		.action-column-container {
			display: none;
		}

		.search-loaded & {
			.action-column-container {
				display: block;
			}
		}
	}

	&.desktop {
		display: none;

		&.column-fixed {
			.action-column-container {
				position: fixed;
				top: 50px;
			}
		}

		.action-column-container {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	&.is-portal {
		.action-btn {
			&:hover,
			&:focus {
				.icon {
					opacity: 1;
				}
			}
			.icon {
				opacity: .5;
			}
		}
	}

	&.is-portal-desktop {
		display: none;

		&.column-fixed {
			.action-column-container {
				position: fixed;
				top: 295px;
			}
		}

		.action-column-container {
			position: fixed;
			left: 50%;
			transform: translateX(-50%);
			top: 295px;
			.icon-mail,
			.icon-chat {
				stroke-width: 2px;
			}
		}
	}

	.action-column-container {
		@include make-container();
		pointer-events: none;
	}

	.column-icons {
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: center;
		pointer-events: auto;
		position: relative;
	}

	.action-btn {
		display: flex;
		margin: 0 0.5rem;
		pointer-events: auto;

		&.hidden {
			display: none;
		}

		&:hover,
		&:focus {
			.icon {
				fill: $color-black;
				stroke: $color-black;
			}
		}

		.icon {
			width: 22px;
			height: 22px;
			fill: $color-gray-medium-light;
			stroke: $color-gray-medium-light;
			pointer-events: none;
		}
	}

	@include breakpoint(lg) {
		display: none;
		margin: 0;
		position: relative;
		z-index: 9; //The controls-top has an 8 so having this lower was showing tooltip behind the controls.

		&.desktop {
			display: block;
		}

		&.is-portal-desktop {
			display: block;
		}

		&.has-search {
			.column-icons {
				top: 44px; // height of .search-page-controls
			}
		}

		.column-icons {
			flex-direction: column;
			justify-content: unset;
			transform: translateX(100%);
			width: 70px;
			position: absolute;
			right: 70px;
			top: 0;
			margin-top: 0.375rem;
		}

		.action-btn {
			margin: 0 0 1rem;
		}
	}
}
