.t3 {
	.link-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-bottom: 2rem;
		text-align: center;

		li {
			display: flex;
			padding: 0;

			&:after {
				color: $color-gray-neutral;
				content: '|';
			}

			&:last-child {
				&:after {
					display: none;
				}
			}

			a {
				display: inline-block;
				padding: 0 0.75rem;
			}
		}

		@include breakpoint(md) {
			justify-content: flex-start;
			margin: 0.2rem 0;

			li {
				&:first-child {
					a {
						padding-left: 0;
					}
				}
			}
		}
	}
}
