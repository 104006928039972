//This blocks off just the section with the loading image.
.loading-overlay-container {
	position: relative;
	height: 100%;
	width: 100%;
	.loading-overlay-section {
		@include loader($color-gray-neutral, 100px, 5px);
		align-items: flex-start;
		display: flex;
		height: 100%;
		justify-content: center;
		left: 0;
		opacity: 0;
		pointer-events: none;
		position: absolute;
		top: 0;
		transition: opacity 0.4s ease-in-out;
		width: 100%;
		z-index: 2;
	}
}
