.bandwidth-tester {
	&.warming-up {
		.dot-pulse {
			display: flex;
		}
		.speedometer {
			display: none;
		}
	}

	&.test-pass,
	&.test-warn,
	&.test-fail {
		.test {
			display: none;
		}
	}

	&.test-pass {
		.pass {
			display: flex;
		}
	}

	&.test-warn {
		.warn {
			display: flex;
		}
	}

	&.test-fail {
		.fail {
			display: flex;
		}
	}

	&.show-details {
		.pass,
		.warn,
		.fail {
			display: none;
		}
		.details {
			display: flex;
			.fail-details {
				display: none;
			}
		}

		&.test-warn {
			.fail-details {
				display: block;
			}
		}
		&.test-fail {
			.fail-details {
				display: block;
			}
		}
	}

	section {
		text-align: center;

		.title-secondary,
		p,
		.cta-link,
		.section-title {
			text-align: center;
		}

		p {
			margin: 1rem auto;
			max-width: 550px;
		}
	}

	.pass,
	.warn,
	.fail,
	.details {
		display: none;
	}

	.loader {
		@include loader($color-blue-bright, 138px, 8px);
		margin-bottom: 2.5rem;

		&:after {
			animation: spin 10s infinite linear;
			margin: 0 auto;
		}
	}

	.dot-pulse {
		@include dot-pulse();
		display: none;
		margin: 0.625rem auto 1.5rem;
	}

	.status {
		@include text(iota);
		@include font-weight(bold);
		margin-bottom: 0;
		text-transform: capitalize;
	}

	.speedometer {
		@include text(iota);
		@include font-weight(bold);
		color: $color-blue-bright;
		height: 24px;
		margin-bottom: 1rem;
	}

	.icon-success,
	.icon-danger {
		height: 80px;
		margin-bottom: 1.75rem;
		width: 80px;
	}

	.icon-caution {
		height: 88px;
		margin-bottom: 1.75rem;
		width: 102px;
	}

	p {
		@include text(kappa);
		margin-bottom: 1rem; // TODO: consider making this mobile default
	}

	ul {
		li {
			@include text(lambda);
		}
	}

	.btn-group {
		justify-content: center;
		margin-bottom: 1.5rem;

		.btn-primary {
			margin-bottom: 0;
		}
	}

	.btn-primary {
		margin-bottom: 1.5rem;
	}

	.btn-show-details {
		margin: 0 auto;
	}

	.details {
		background-color: $color-gray-light;
		padding-top: 4rem;
		position: relative;

		.btn-hide-details {
			padding: 0;
			position: absolute;
			right: 30px;
			top: 30px;
		}

		.title-secondary {
			margin-bottom: 2rem;
		}

		.bandwidth-details-description {
			text-align: center;
		}

		.bandwidth-details-description,
		li {
			@include text(kappa);
			color: $color-gray-dark;
		}
	}

	.bandwidth-details-list {
		display: inline-block;
		margin-bottom: 2.5rem;

		ul {
			margin-top: 0;

			li {
				padding-bottom: 0.5rem;
			}
		}

		.test-result {
			&.test-passed {
				.pass {
					display: inline-flex;
				}
				.fail {
					display: none;
				}
			}

			.pass {
				display: none;
			}
		}

		.status-icon {
			display: inline-flex;
			margin-right: 0.25rem;
			width: 1rem;
		}

		.icon-menu-close {
			height: 0.75rem;
			margin-left: 1px;
			stroke-width: 4px;
			width: 0.75rem;
		}

		.label {
			@include font-weight(bold);
		}
	}

	.inline-link {
		@include font-weight(normal);
		display: inline;
		margin: 0 auto;
	}

	@include breakpoint(md) {
		.btn-primary {
			width: auto;
		}

		.list-separated {
			li {
				text-align: center;
				&:after {
					margin: 0.75rem auto;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	@include breakpoint(lg) {
		$header-height: 60px;
		$header-margin: 60px;
		$footer-height: 160px;

		section {
			p {
				margin: 1rem auto;
				max-width: 550px;
			}
		}

		.btn-primary {
			width: auto;
		}

		.details {
			text-align: left;

			p {
				text-align: left;
			}

			.title-secondary,
			.section-title {
				text-align: left;
			}

			.bandwidth-details-description,
			.cta-link,
			li {
				text-align: left;
				margin-right: 0;
				margin-left: 0;
			}
		}

		.bandwidth-details-list {
			text-align: left;
		}

		.list-separated {
			li {
				text-align: left;
				&:after {
					margin: 0.75rem 0;
				}
			}
		}
	}
}
