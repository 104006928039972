.ngde-deductibles-moop {
	@include make-container();
	margin-bottom: 4rem;
	margin-left: auto;
	margin-right: auto;
	max-width: $lg-xl-breakpoint;
	padding-left: 30px;
	padding-right: 30px;
	padding-top: 160px;
	width: 100%;

	.hide {
		visibility: hidden;
	}

	a {
		text-decoration: none;
	}

	.content-col {
		padding-top: 1.5rem;
	}

	.deductibles-moop-list {
		position: relative;

		&.loading {
			min-height: 400px;

			.loading-overlay {
				opacity: 1;
			}
		}

		&.primary {
			.col-12 {
				display: flex;
			}

			.care-team-card {
				flex-grow: 1;
			}
		}
	}

	.deductibles-moop-card {
		background-color: $color-gray-light;
		display: block;
		margin-bottom: 2rem;
		padding: 1rem 2rem 2rem;

		.modal-open-btn {
			@include text(mu);
			@include font-weight(medium);
			display: block;
			margin: 2rem 0 0;
		}
	}

	.card-header {
		@include text(lambda);
		@include font-weight(medium);
		border-bottom: 1px solid $color-black;
		margin-bottom: 5px;
		width: fit-content;

		&:last-of-type {
			margin-bottom: 3rem;
		}
	}

	.card-description {
		@include text(mu);
		display: block;
		margin-bottom: 0.3125rem;
		width: fit-content;
		line-height: 1.8;

		canvas {
			display: block;
			box-sizing: border-box;
			height: 45px !important;
			margin: 0 0 5px 0;
			width: 400px;
		}

		a {
			display: inline;
		}
	}

	.IndividualInNet,
	.FamilyInNet,
	.IndividualInNetMoop,
	.FamilyInNetMoop {
		margin-top: -77px;
		padding-top: 0px;
		margin-bottom: -72px;
		position: relative;
		z-index: 9;
	}

	.IndividualOutNet,
	.FamilyOutNet,
	.IndividualOutNetMoop,
	.FamilyOutNetMoop {
		margin-top: -77px;
		padding-top: 0px;
		margin-bottom: -72px;
		position: relative;
		z-index: 9;
	}

	.deductLabelCon {
		display: flex;
		font-size: 14px;
	}

	.deductLabelRt {
		position: relative;
		bottom: 0px;
		display: flex;
		justify-content: start;
		width: 95%;
	}

	.deductLabelLt {
		position: relative;
		bottom: 0px;
		display: flex;
		justify-content: end;
	}

	.BarGraphLabel {
		position: relative;
		bottom: 3px;
		font-size: 15px;
	}

	.other-care-header {
		@include font-weight(medium);
		margin-bottom: 1rem;
	}

	.section-description {
		@include text(lambda);

		.icon {
			height: 10px;
			margin-left: 10px;
			stroke: $color-blue;
			width: 10px;
		}
	}

	.phone-type {
		display: inline-block;
		text-transform: capitalize;
	}

	@include breakpoint(md) {
		padding-top: 168px;
	}

	@include breakpoint(lg) {
		padding-top: 255px;
	}

	@include make-container();

	a {
		text-decoration: none;
	}

	.grid-row {
		@include make-row();

		section {
			overflow: hidden;
			width: 100%;
		}
	}

	.padbot {
		padding-bottom: 15px;
	}

	.summary-of-coverage-wrap-container {
		&.loading {
			.loading-overlay {
				opacity: 1;
			}
		}

		position: sticky;
		overflow-x: scroll;
		overflow-y: hidden;

		.summary-of-coverage-wrap {
			margin-bottom: 3rem;
			margin-left: 0.5rem;
			padding-left: -1rem;
			width: 862px;

			.summary-of-coverage-table {
				min-width: 800px;
				max-width: 100%;
				/* overflow-y: hidden;
				overflow-x: scroll; */
			}

			svg.icon.arrow-down-triangle {
				/* margin-top: -2rem; */
				position: relative;
				top: 3px;
				stroke-width: 0px;
				fill: $color-gray-arrow;
				margin-left: 20px;
			}

			svg.icon.arrow-down {
				/* margin-top: -2rem; */
				position: relative;
				top: 3px;
				stroke-width: 0px;
				fill: $color-gray-arrow;
				margin-left: 20px;
			}

			svg.icon.arrow-up-triangle {
				/* margin-top: -2rem; */
				position: relative;
				top: 3px;
				stroke-width: 0px;
				fill: $color-gray-medium-light;
				margin-left: 20px;
			}

			svg.icon.arrow-up {
				/* margin-top: -2rem; */
				position: relative;
				top: 3px;
				stroke-width: 0px;
			}

			.summary-of-coverage-title-row {
				background-color: $color-gray-light;
				padding: 1rem 1rem;
				border-bottom: solid $color-gray-neutral 1px;
				cursor: pointer;
			}

			.summary-of-coverage-data-row {
				padding: 2rem 1rem;
				border: solid $color-gray-neutral 1px;
				border-top: none;

				.hide {
					display: none;
				}
			}

			.summary-of-coverage-group,
			.summary-of-coverage-member {
				font-size: 0.9rem;
			}

			.coverage-label {
				font-style: oblique;
			}
		}

		.content-col {
			h1 {
				@include text(gamma);
			}
		}
	}

	@include breakpoint(md) {
		padding-top: 1px;

		.grid-row {
			@include make-row();

			section {
				overflow: unset;
				width: unset;
			}
		}

		.summary-of-coverage-wrap-container {
			width: 99%;
			position: sticky;
			overflow-x: unset;
			overflow-y: unset;

			.summary-of-coverage-wrap {
				width: 100%;

				.summary-of-coverage-table {
					width: 185%;
					overflow-y: unset;
					overflow-x: unset;
				}
			}
		}
	}

	@include breakpoint(lg) {
		padding-top: 1px;
	}
}
