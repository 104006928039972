.email-share {
    .email-share-row {
        display: flex;
        justify-content: space-between;

        .btn {
            flex: 0 1 calc(50% - 5px);
            margin-bottom: 0;
        }
    }


    @include breakpoint(lg) {
        .modal-container {
            padding-left: 200px;
            padding-right: 200px;
        }
    }

    .form .form-control {
        max-width: 100%;
        text-align: center;

        input.email-input._ar_hide_ {
            background-color: $color-gray-light;
            max-width: 100%;
            width: 100%;
            height: 52px;
        }
    }

    .success {
        .success-notification-wrap {
            @include text(iota);
            background-color: $color-green-pale-alert;
            display: flex;
            margin: 1rem 0 2rem 0;
            padding: 1.25rem;

            .text-wrapper {
                margin-left: 1.25rem;
            }
        }
    }

    .in-progress {
        .in-progress-notification-wrap {
            @include text(iota);
            background-color: $color-neutral-yellow;
            display: flex;
            margin: 1rem 0 2rem 0;
            padding: 1.25rem;

            .text-wrapper {
                margin-left: 1.25rem;
            }
        }
    }


    .errorEmail,
    .errorFail {
        .error-notification-wrap {
            @include text(iota);
            background-color: $color-red-light;
            display: flex;
            margin: 1rem 0 2rem 0;
            padding: 1.25rem;
        }
    }

    .icon {
        stroke-width: 0px;
    }

    .icon-success,
    .error {
        //without this being min it was making it smaller on small screens.
        min-height: 22px;
        min-width: 22px;
    }

    .icon-question {
        min-height: 19px;
        min-width: 19px;
    }

    .icon-arrow-down {
        min-height: 15px;
        min-width: 7.5px;
        stroke-width: 1px;
    }

    .floating-label {
        @include text(lambda);
        color: $color-gray-medium-light;
        left: 1.05rem;
        opacity: 1;
        pointer-events: none;
        position: absolute;
        top: 4px;
    }

    .error-message {
        padding: 0 0 0.625rem 0;
    }

    .errorEmail {
        .text-wrapper-email {
            margin-left: 1.25rem;
            display: flex;
        }

        .text-wrapper-fail {
            display: none;
        }

        .email-input {
            border-bottom: 2px solid $color-red;
        }
    }

    .errorFail {
        .text-wrapper-email {
            display: none;
        }

        .text-wrapper-fail {
            margin-left: 1.25rem;
            display: flex;
        }
    }

    .success-notification-wrap,
    .error-notification-wrap,
    .in-progress-notification-wrap {
        display: none;
    }

    .clear-btn {
        color: $color-blue;
        text-decoration: none;
        margin: auto calc(50% - 24px);
    }

    .btn {
        @include text(kappa);
        @include font-weight(medium);
        background-color: $color-gray-dark;
        border-color: $color-gray-neutral;
        border-radius: 2px;
        color: $color-white;
        margin-bottom: 10px;
        max-width: 100%;
        padding: 0.75rem 2.5rem;
        text-decoration: none;
        white-space: pre-wrap;
        width: 100%;

        &:hover,
        &:focus {
            background-color: $color-gray-dark;
            border-color: $color-gray-dark;
            color: $color-white;
            text-decoration: none;
        }

        &:disabled {
            background-color: $color-gray-neutral;
            border: 1px solid $color-gray-neutral;
            color: $color-white;
        }
    }

    .btn-facebook {
        background-color: $color-facebook;
        border-color: $color-facebook;
        color: $color-white;

        &:hover,
        &:focus {
            background-color: $color-facebook-hover;
            border-color: $color-facebook-hover;
        }
    }

    .btn-twitter {
        background-color: $color-twitter;
        border-color: $color-twitter;
        color: $color-white;

        &:hover,
        &:focus {
            background-color: $color-twitter-hover;
            border-color: $color-twitter-hover;
        }
    }
}
