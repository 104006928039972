.sign-in-grid {
	display: grid;
	grid-template-rows: auto auto 1fr auto;
}

.main-sign-in {
	min-height: auto;
	background-color: #eeeeee;
	.registration-heading {
		margin-left: 2rem;
	}
}

.sign-in-mypres-logo {
	width: 10rem;
	margin-top: 5rem;
	margin-left: 2rem;

	@include breakpoint(lg) {
		width: 20rem;
		margin-top: 2rem;
		margin-left: 4rem;
	}
}

.sign-in-footer {
	text-align: center;
	margin: 0 1.5rem;
}

.register-user {
	display: flex;
	flex-direction: column;
	margin-left: 1rem;
	margin-top: 2rem;
	margin-right: 1rem;

	.verify-consent-checkbox-wrapper {
		&.non-member {
			@include breakpoint(lg) {
				margin-left: 24%;
				margin-right: 24%;
				min-width: 50rem;
			}
		}
		@include breakpoint(lg) {
			margin-left: auto;
			margin-right: auto;
			min-width: 50rem;
		}
	}
	.input-field {
		position: relative;
	}
	input[type='text'],
	input[type='password'] {
		background-color: $color-white;
		border: 1px solid $color-white;
		color: $color-black;
		padding-top: 2rem;
		text-align: left;
		position: relative;
		&:disabled {
			background-color: $color-gray-light;
			border-color: $color-gray-neutral;
		}

		&:focus,
		&:not(:placeholder-shown) {
			& + .floating-label {
				font-size: 0.875rem;
				left: 1.35rem;
				top: 0.25rem;
				position: absolute;
				color: $color-gray-medium-light;

				&.validation-error {
					color: $color-red;
				}
			}
		}

		@include breakpoint(lg) {
			padding-left: 1.35rem;
		}

		&.validation-error {
			border-color: $color-red;
		}
	}

	.floating-label {
		@include text(lambda);
		font-size: 1.125rem;
		line-height: 1.33;
		color: $color-gray-medium-light;
		left: 1.35rem;
		opacity: 1;
		pointer-events: none;
		position: absolute;
		top: 1.5rem;
		&.validation-error {
			color: $color-red;
		}
	}

	.forgot-password {
		.input-label {
			input {
				padding-left: 1.35rem;
				padding-top: 2rem;
				background-color: $color-white;
			}
		}
	}

	.validation-error {
		color: $color-red;
	}

	.loading-overlay {
		height: unset;
		opacity: unset;
		position: relative;
		background-color: #eeeeee;
		::after {
			position: absolute;
			left: 50%;
		}
	}

	.verifying-membership {
		position: relative;
		display: flex;
		top: 2rem;
		justify-content: center;
	}

	@include breakpoint(lg) {
		margin-left: auto;
		margin-right: auto;
		min-width: 50rem;

		.ngde-contact-information {
			margin-left: 20rem;

			.select-input-wrapper {
				.input-field {
					width: calc((100% - 35px) / 1.5 - 1px);
				}
				.select-wrapper {
					width: calc((100% - 15px) / 3 + 15px);
				}
			}
		}

		.ngde-non-member-contact-information {
			min-width: 50rem;
			margin-left: auto;
			margin-right: auto;
		}

		.next-btn {
			width: auto;
			min-width: 50rem;
			margin-left: 25%;
			margin-right: 25%;
		}
	}

	.create-account-button {
		@include breakpoint(lg) {
			margin-left: 25%;
			margin-right: 25%;
			width: auto;
			min-width: 50rem;
		}
	}
	.select-wrapper {
		position: relative;
		width: 100%;
		margin-bottom: 1rem;
		select {
			background-color: $color-white !important;
			height: 60px;
			width: 100%;

			padding-left: 1.15rem;
			padding-top: 1.35rem;

			&:focus,
			&:not(:placeholder-shown) {
				& + .floating-label {
					font-size: 0.875rem;
					left: 1.35rem;
					top: 0.25rem;
					position: absolute;
					color: $color-gray-medium-light;

					&.validation-error {
						color: $color-red;
					}
				}
			}

			&.validation-error {
				border-color: $color-red;
			}
		}
	}

	fieldset {
		border: none;
		display: flex;
		padding-left: 0;
	}

	.registration-user-info-secondary-links {
		.left-link {
			float: left;
		}
		.right-link {
			float: right;
		}
	}

	.btn-primary {
		background-color: $color-gray-dark;
		border: 1px solid $color-gray-dark;
		color: $color-white;
	}

	.registration-success-message-box {
		max-width: fit-content;
		margin-left: auto;
		margin-right: auto;
		background-color: $color-white;
		padding: 2rem;
	}

	.loading-icon-container {
		position: absolute;
		right: 20px;
		top: calc(50% - 15px);
	}
	.loader {
		position: relative;
		height: 20px;
		width: 20px;
		display: inline-block;
		animation: around 5.4s infinite;
	}

	@keyframes around {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	.loader::after,
	.loader::before {
		content: '';
		background: white;
		position: absolute;
		display: inline-block;
		width: 100%;
		height: 100%;
		border-width: 2px;
		border-color: #333 #333 transparent transparent;
		border-style: solid;
		border-radius: 20px;
		box-sizing: border-box;
		top: 0;
		left: 0;
		animation: around 0.7s ease-in-out infinite;
	}

	.loader::after {
		animation: around 0.7s ease-in-out 0.1s infinite;
		background: transparent;
	}

	.user-id-error-message {
		color: $color-red;
		padding-bottom: 1rem;
	}

	.user-id-input-error {
		border-color: $color-red !important;
	}

	.password-match-error-message {
		p {
			color: $color-red !important;
		}
	}
}

#register-modal-root {
	.heading-1 {
		color: $color-black;
	}
	.modal-container {
		.btn-primary {
			background-color: $color-gray-dark;
			border: 1px solid $color-gray-dark;
			color: $color-white;
			text-align: center;
		}
	}
}

.forgot-password {
	display: flex;
	flex-direction: column;
	margin-left: 1rem;
	margin-top: 2rem;
	margin-right: 1rem;
	margin-bottom: 2rem;

	/*input[type='text'] {
		padding-top: 1.35rem;
		@include breakpoint(lg) {
			padding-left: 1.25rem;
		}
	}*/

	@include breakpoint(lg) {
		margin-left: auto;
		margin-right: auto;
	}

	.input-field {
		position: relative;
	}
	input[type='text'],
	input[type='password'] {
		background-color: $color-white;
		border: 1px solid $color-white;
		color: $color-black;
		padding-top: 2rem;
		text-align: left;
		position: relative;
		&:disabled {
			background-color: $color-gray-light;
			border-color: $color-gray-neutral;
		}

		&:focus,
		&:not(:placeholder-shown) {
			& + .floating-label {
				font-size: 0.875rem;
				left: 1.35rem;
				top: 0.25rem;
				position: absolute;
				color: $color-gray-medium-light;
			}
		}

		@include breakpoint(lg) {
			padding-left: 1.35rem;
		}
	}

	.floating-label {
		@include text(lambda);
		font-size: 1.125rem;
		line-height: 1.33;
		color: $color-gray-medium-light;
		left: 1.35rem;
		opacity: 1;
		pointer-events: none;
		position: absolute;
		top: 1.5rem;
	}

	.input-field {
		justify-content: center;
		position: relative;

		input {
			position: relative;

			::placeholder {
				text-align: left;
			}

			/* or, for legacy browsers */

			::-webkit-input-placeholder {
				text-align: left;
			}

			:-moz-placeholder {
				/* Firefox 18- */
				text-align: left;
			}

			::-moz-placeholder {
				/* Firefox 19+ */
				text-align: left;
			}

			:-ms-input-placeholder {
				text-align: left;
			}
		}

		.error {
			display: none;
			height: 100%;
			position: absolute;
			padding-right: 0.5rem;
			right: 0;
			top: 10px;

			.icon-error {
				height: 20px;
				position: relative;
				width: 20px;
				z-index: 2;
			}
		}
	}

	label.input-label {
		position: relative;
		display: inline-block;
		margin: 0;
		width: 100%;
	}

	label.input-label > .icon {
		position: absolute;
		top: 40%;
		right: 15px;
		transform: translateY(-50%);
		color: silver;
		z-index: 100;
	}

	label.input-label > input {
		padding-left: calc(1em + 10px + 8px);
		width: 100%;
		max-width: none;
		text-align: left;
		background-color: #eee;
	}

	.send-code-options {
		display: flex;
		flex-direction: row;

		.send-code-option {
			padding-right: 1rem;
		}
	}

	.rules-description {
		@include text(kappa);
		margin: 1rem 0 0;
	}

	.valid,
	.invalid {
		margin-top: 0.25rem;
	}

	.checklist-icon {
		height: 1rem;
		width: 1rem;
		margin-top: 2px;
	}

	.new-password-input {
		&.invalid-password {
			border-color: $color-red;
		}

		&.valid-password {
			border-bottom-color: $color-green;
		}
	}

	.password-weak-text {
		color: $color-red;
	}

	.password-strong-text {
		color: $color-green;
	}

	.resend-code-text {
		display: flex;
		font-size: 0.875rem;

		.clear-btn {
			cursor: pointer;
			margin-left: 0.5rem;
			margin-bottom: 0.5rem;
			padding-left: 0;
		}

		.resend-disabled {
			color: #00759a;
			margin-left: 0.5rem;
			margin-bottom: 0.5rem;
			padding-left: 0;
		}
	}

	@include breakpoint(lg) {
		margin-left: auto;
		margin-right: auto;
		min-width: 50rem;
	}
}

// SOLUTIONS GROUP HEADER (matching class names and styling from Wellness at Work site)

.tsgHeaderNavBarWrapper {
	height: 50px;
	background-color: #f1ede3;
}

.tsgHeaderNavBar {
	max-width: 1030px;
	margin: 0 auto;
	position: relative;
	padding-top: 3px;
}

.tsgHeaderImage {
	width: 100%;
	height: 226px;
	background: url('https://mypres.phs.org/Style%20Library/img/wellness/theater_2000x298_shadow.jpg')
		0 0 no-repeat;
	background-size: cover;
}

.wellnessLogoCanvas {
	max-width: 1003px;
	margin: 0px auto 75px auto;
	padding-top: 10px;
}

.wellnessLogo {
	height: 125px;
}

@media only screen and (max-width: 1024px) {
	.tsgHeaderImage {
		height: 94px;
		background: url('https://mypres.phs.org/Style%20Library/img/wellness/theater_2000x94_shadow.jpg')
			0 0 no-repeat;
	}
	.wellnessLogoCanvas {
		max-width: 768px;
		padding-left: 10px;
	}
	.wellnessLogo {
		height: 50px;
	}
}
