.t3 {
	.cta-text-list {
		li {
			padding-bottom: 0.25rem;
			text-align: center;
		}

		.cta-link {
			display: inline;
		}

		@include breakpoint(md) {
			li {
				text-align: left;
			}
		}
	}

	.instruction-list {
		margin-bottom: 1.5rem;
		text-align: center;

		li {
			@include text(kappa, 18);
			color: $color-gray-dark;
			counter-increment: step-counter;
			margin-bottom: 1rem;

			&:before {
				@include text(lambda);
				@include font-weight(medium);
				background-color: lighten($color-gray-neutral, 8);
				border-radius: 50%;
				color: lighten($color-gray-dark, 8);
				content: counter(step-counter);
				display: block;
				height: 24px;
				margin: auto;
				margin-bottom: 1rem;
				padding-left: 1px;
				padding-top: 3px;
				text-align: center;
				width: 24px;
			}
		}

		@include breakpoint(md) {
			padding-top: 0.25rem;
			text-align: left;

			li {
				padding-left: 34px;
				&:before {
					display: inline-block;
					margin-bottom: 0;
					margin-left: -34px;
					margin-right: 0.625rem;
					margin-top: -4px;
					padding-left: 0;
					position: absolute;
				}
			}
		}

		@include breakpoint(lg) {
			li {
				padding-left: 0;
			}
		}
	}

	.requirements-list {
		margin-bottom: 1.5rem;

		dt {
			@include text(kappa, 18);
			@include font-weight(bold);
			color: $color-black;
			margin-top: 1.5rem;
			text-align: center;
		}

		dd {
			@include text(kappa);
			color: $color-gray-dark;

			text-align: center;
		}

		@include breakpoint(md) {
			dt {
				text-align: left;
			}

			dd {
				text-align: left;
			}
		}
	}

	.list-separated {
		li {
			text-align: center;
			position: relative;
			&:after {
				background-color: $color-red;
				bottom: 0;
				content: ' ';
				display: block;
				height: 1px;
				opacity: 1;
				position: relative;
				left: 0;
				margin: 0.75rem auto;
				width: 10px;
			}
			&:last-child {
				margin-bottom: 1rem;
				&:after {
					display: none;
				}
			}
		}

		@include breakpoint(md) {
			li {
				text-align: left;
				&:after {
					margin: 0.75rem 0;
				}
			}
		}
	}
}
