.upcoming-appointments {
	@include make-container;

	&.hide-future {
		.future-section {
			display: none;
			height: 0;
		}

		.section-header {
			.icon {
				transform: none;
			}
		}
	}

	.icon-check {
		stroke: $color-black;
	}

	.icon-success {
		fill: $color-green;
		height: 30px;
		stroke: $color-black;
		width: 30px;
	}

	.icon-action-needed {
		border: 2px solid $color-gray-neutral;
		border-radius: 50%;
		color: $color-white;
		content: '';
		display: block;
		height: 30px;
		width: 30px;
	}

	.title-container {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 1.2rem;
		margin-top: 2rem;

		.title-main {
			@include font-weight(semi-bold);
			@include text(gamma);
			color: $color-red;
			margin-bottom: 1.2rem;
			min-width: 100%;
		}

		.title-info {
			@include font-weight(bold);
			@include text(iota);
			color: $color-black;
		}
	}

	.main-row {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
	}

	.row-title {
		@include font-weight(bold);
		@include text(kappa);
		margin-bottom: 1.5rem;
	}

	.full-row {
		margin-bottom: 2rem;

		.row-container {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 0.5rem;
		}
	}

	.appointment-date {
		@include font-weight(bold);

		span {
			@include font-weight(normal);
		}

		.estimated-time,
		.scheduled-time {
			@include font-weight(bold);
		}

		.delay {
			@include text(lambda);
			@include font-weight(bold);
		}
	}

	.provider {
		@include font-weight(bold);
		display: block;
	}

	.appointment-type {
		@include font-weight(bold);
		@include text(mu);
		color: $color-gray-medium-light;
		margin-bottom: 1.5rem;
		text-transform: capitalize;
	}

	.section-header {
		@include font-weight(bold);
		align-items: center;
		background-color: $color-gray-neutral;
		display: flex;
		justify-content: center;
		line-height: 1;
		margin: 0;
		padding: 0.75rem 2rem;
		width: 100%;

		&.section-toggle-button {
			&:hover {
				background-color: $color-gray-dark;
				color: $color-white;
				cursor: pointer;

				.icon {
					stroke: $color-off-white;
				}
			}
		}

		.section-header-text {
			align-items: center;
			display: flex;
			flex: 1;
			justify-content: center;
		}

		.icon {
			align-items: center;
			display: flex;
			margin-left: auto;
			stroke-width: 4px;
			transform: rotate(180deg);
		}
	}

	.future-section {
		margin-bottom: 5rem;
	}

	@include breakpoint(md) {
		.title-container {
			justify-content: space-between;

			.title-main {
				margin-bottom: 0;
				min-width: 0;
			}

			.title-info {
				text-align: right;
			}
		}

		.row-container {
			min-height: 95px;

			.main-row {
				display: flex;
				flex-wrap: nowrap;
				width: 100%;
			}
		}

		.provider {
			display: inline;
		}

		.appointment-type {
			margin-bottom: 0;
		}
	}
}
