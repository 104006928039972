.ngde-prior-authorizations {
	@include make-container();
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 4rem;
	max-width: $lg-xl-breakpoint;
	padding-left: 30px;
	padding-right: 30px;
	padding-top: 168px;
	width: 100%;

	&.no-padding {
		padding-top: 0 !important;
	}

	.back-link {
		@include text(lambda);
		color: $color-black;
		display: inline-block;
		margin-bottom: 1rem;

		.icon-arrow-right {
			height: 8.9px;
			margin-right: 0.5rem;
			transform: rotate(180deg);
			width: 4.45px;
		}
	}

	.claims-wrap {
		margin-bottom: 4rem;
		position: relative;

		&.loading {
			min-height: 300px;
			pointer-events: none;

			.hide-while-loading,
			.total-and-summary-container {
				display: none;
			}

			.loading-overlay {
				opacity: 1;
			}
		}

		.claims-section {
			min-height: 150px;

			position: relative;
		}

		.loading-overlay {
			background-color: transparent;
			padding-top: 50px;
		}

		.filter-section {
			display: none;
		}
	}

	a {
		color: $color-blue;
		text-decoration: none;
		li {
			margin-bottom: 0.5rem;
		}
	}

	.prior-auth-details-title {
		display: flex;
		justify-content: space-between;
	}

	.prior-auth-header-row {
		background: $color-gray-light;
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.prior-auth-header-title {
		padding: 1rem 1rem 0.5rem;
	}

	.prior-auth-header-status {
		padding: 0 1rem 1rem;

		.icon {
			margin-right: 0.5rem;
		}
	}

	.no-prior-auths-message {
		@include text(kappa);
	}

	.expandable-summaries {
		margin-bottom: 5rem;

		.claims-header-title {
			display: inline-block;
			padding: 15px 30px 15px 0;
		}

		.data-summary {
			border: 0;
			margin: 0;
			width: auto;

			.data-section {
				background-color: white;
				border-right: none;
			}
		}

		span.claims-header-title {
			display: inline-block;
			padding: 15px 0 15px 30px;
			width: 50%;

			&.right {
				padding: 15px 30px 15px 0;
				text-align: right;
			}
		}

		.expandable {
			border: 1px solid #d3d3d3;
			border-bottom: none;

			&:last-of-type {
				border-bottom: 1px solid #d3d3d3;
				margin-bottom: 50px;
			}

			.expander {
				cursor: pointer;
				margin-bottom: 0;

				.icon {
					height: 9px;
					margin-right: 1rem;
					width: 5px;
				}
			}

			.header {
				@include text(lambda);
				@include font-weight(normal);
				text-align: left;
				padding: 20px;
				width: 100%;
			}

			&.expanded {
				background-color: white;

				.additional-info {
					display: flex;
				}

				.expander {
					.icon {
						transform: rotate(90deg);
					}
				}
			}
		}
		.additional-info {
			background-color: white;
			border: none;
			display: none;
			margin-bottom: 0;
		}
	}

	.grid-row {
		@include make-row();
		section {
			overflow: hidden;
			width: 100%;
		}
	}

	.prior-auth-note {
		background-color: $color-blue-highlight;
		margin-bottom: 2rem;
		padding: 1rem;
		.info-icon-wrapper {
			@include text(kappa);
			.icon {
				margin-right: 10px;
				stroke: none;
			}
		}
	}

	.prior-auth-notification-wrap {
		background-color: $color-info;
		display: flex;
		margin: 1rem 0 2rem 0;
		min-width: 800px;
		padding: 1.2rem;

		.icon-info-alert {
			height: 1rem;
			margin-right: 0.625rem;
			width: 1rem;
		}

		.btn-link {
			display: inline-block;
			margin-left: 0.625rem;
		}
	}
	.drop-down__list-item {
		align-items: center;
		display: flex;
		height: 30px;

		&.disabled {
			> input {
				display: none;
			}
		}

		&:first-child {
			@include font-weight(bold);
		}
	}
	.drop-down__list-item.title-divider {
		@include font-weight(bold);
	}
	.drop-down__list-item > input {
		height: 20px;
		margin: 0.5rem 0;
		width: 20px;
	}
	.drop-down__list-item > label {
		@include text(lambda);
		color: $color-gray-medium-light;
		margin-left: 0.5rem;
	}
	.new-drop-down__button {
		@include text(kappa);
		border: 1px solid $color-gray-neutral;
		color: $color-gray-medium-light;
		cursor: pointer;
		font-family: inherit;
		margin-bottom: 0;
		padding: 0.5rem;
		position: relative;
		text-align: left;
		width: 100%;
	}

	.label-filter {
		@include text(lambda);
		@include font-weight(semi-bold);
		color: $color-gray-dark;
		line-height: 2.5;
		padding: 0;
	}
	.drop-down-icon {
		align-items: center;
		display: flex;
		height: 20px;
		justify-content: center;
	}
	.drop-down-icon--is-rotate {
		transform: rotate(180deg) translateY(50%);
	}
	.new-drop-down {
		@include text(lambda);
		width: 100%;
	}

	.new-drop-down {
		@include text(lambda);
		position: relative;
		width: 100%;
		z-index: 1;
	}

	button.new-drop-down__button.new-drop-down--is-open {
		color: $color-gray-dark;
	}

	button.new-drop-down__button.new-drop-down--is-open span {
		color: $color-gray-dark;
	}

	.new-drop-down__list-wrapper {
		background: white;
		border: 1px solid $color-gray-neutral;
		border: 1px solid #eee;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
		max-width: 100%;
		padding: 1rem;
		position: absolute;
		top: 45px;
		width: 100%;
	}

	.filter-wrapper {
		display: flex;
		justify-content: space-between;
		.dropdown-title {
			@include font-weight(bold);
			text-align: center;
		}
		.dropdown-subtitle {
			margin-bottom: 0;
			text-align: center;
			@include text(lambda);
		}
		.dropdown-text {
			font-size: 10px;
			margin-bottom: 0;
			text-align: center;
		}
		.top-margin {
			margin-top: 1.5rem;
		}
	}

	@include breakpoint(xs) {
		padding-top: 168px;

		.prior-mobile-container {
			align-items: flex-end;
			display: flex;
			justify-content: space-between;
			padding: 0 0.5rem;
			.prior-mobile-section-title {
				@include text(iota);
				@include font-weight(bold);
			}
			.prior-mobile-action-button {
				cursor: pointer;
				display: flex;
				flex-direction: row;
				width: auto;
				.moble-right-btn {
					padding: 0.5rem 0.5rem 0;
					& .icon {
						stroke: #363534;
					}
					&:first-child {
						.icon {
							stroke: none;
						}
					}
				}
			}
		}
	}

	@include breakpoint(md) {
		padding-top: 200px;
		.grid-row {
			@include make-row();
			section {
				overflow: unset;
				width: unset;
			}
		}

		.claims-wrap {
			.filter-section {
				display: block;
				margin-bottom: 2rem;
			}
		}

		.prior-auth-header-row {
			align-items: center;
			flex-direction: row;
			justify-content: space-between;
			padding: 1rem;
		}

		.prior-auth-header-title,
		.prior-auth-header-status {
			padding: 0;
		}

		.claims-mobile-filters {
			display: none;
		}

		.expandable-summaries {
			.claims-header-title {
				text-align: right;
				width: 50%;
			}
		}

		.data-summary {
			.data-section {
				flex-basis: 80%;
				max-width: 80%;
			}
		}
	}

	@include breakpoint(lg) {
		padding-top: 300px;
	}

	@media print {
		.expandable-summaries {
			.additional-info {
				display: flex;
			}
		}
	}
}
