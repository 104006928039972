.ngde-current-coverage {
	margin-left: auto;
	margin-right: auto;
	max-width: $lg-xl-breakpoint;
	padding-left: 30px;
	padding-right: 30px;
	width: 100%;
	padding-top: 168px;
	@include make-container();

	.who-covered-section,
	.current-plan-card-wrap {
		&.loading {
			.loading-overlay-section {
				opacity: 1;
			}
		}
	}

	.plan-inactive {
		display: none;
	}

	&.set-active {
		.plan-inactive {
			display: none;
		}

		.plan-active,
		.plan-list-active {
			display: block;
		}
	}

	&.set-inactive {
		.plan-inactive {
			display: block;
		}

		.plan-active,
		.plan-list-active {
			display: none;
		}
	}

	&.errorLeft {
		.left-error {
			display: flex;
		}
	}

	&.errorRight {
		.right-error {
			display: flex;
		}
	}

	.error-notification-wrap {
		@include text(kappa);
		background-color: $color-red-light;
		padding: 1.25rem;
		width: 90%;
		.text-wrapper {
			margin-left: 1.25rem;
		}
	}

	.left-error,
	.right-error {
		display: none;
	}

	a {
		color: $color-blue;
		text-decoration: none;
		li {
			margin-bottom: 0.5rem;
		}
	}

	.grid-row {
		@include make-row();
	}

	.content-col {
		.whos-covered-wrap-container {
			position: sticky;
			overflow-x: scroll;
			overflow-y: hidden;
			.whos-covered-wrap {
				padding: 0.5rem 0.5rem 2.5rem 0.5rem;
				width: 800px;
			}
		}

		.box {
			padding: 0px;
		}

		.wrapper {
			display: grid;
			//grid-gap: 5px;
			row-gap: 7px;
			column-gap: 17px;
			grid-template-columns: repeat(
				auto-fill,
				minmax(100px, 5fr) minmax(100px, 1fr)
			);
			font-size: 0.9rem;
		}

		.container-grid {
			padding-right: 10%;
			padding-left: 6%;
		}
	}

	.whos-covered-wrap {
		padding: 0.5rem 0.5rem 2.5rem 0.5rem;

		.whos-covered-title-row,
		.whos-covered-data-row {
			@include make-row();
			padding: 1rem 0;
		}
		.whos-covered-title-row {
			background-color: $color-gray-light;
			border-bottom: solid $color-gray-neutral 1px;
		}
		.whos-covered-data-row {
			border: solid $color-gray-neutral 1px;
			border-top: none;
		}
	}

	.download-list {
		.btn-link {
			@include text(lambda);
			color: $color-blue;
			margin-bottom: 0rem;
			text-align: left;
		}
	}

	.tax-forms-wrap {
		.text-content {
			li {
				margin-bottom: 0.3rem;
			}
		}
	}

	.websites-wrap {
		margin-top: 1rem;
		.text-content {
			@include text(lambda);
			color: $color-blue;
			margin-bottom: 0rem;
			margin-top: 0.3rem;
			text-align: left;
		}
	}

	.whos-covered-table {
		width: 100%;
		th {
			text-align: left;
		}
	}

	@include breakpoint(sm) {
		padding-top: 168px;
		.content-col {
			.container-grid {
				padding-right: 10%;
				padding-left: 6%;
			}

			.whos-covered-wrap-container {
				position: sticky;
				overflow-x: scroll;
				overflow-y: hidden;
				.whos-covered-wrap {
					padding: 0.5rem 0.5rem 2.5rem 0.5rem;
					width: 800px;
				}
			}
		}
	}

	@include breakpoint(md) {
		padding-top: 200px;
		.content-col {
			.container-grid {
				padding-right: 20px;
				padding-left: 13px;
			}
			.whos-covered-wrap-container {
				width: 100%;
				position: sticky;
				overflow-x: visible;
				overflow-y: visible;

				.whos-covered-wrap {
					padding: 0.5rem 0.5rem 2.5rem 0.5rem;
					width: 100%;
				}
			}
		}
	}

	@include breakpoint(lg) {
		padding-top: 300px;

		.content-col {
			.whos-covered-wrap-container {
				width: 100%;
				overflow: none;
				.whos-covered-wrap {
					padding: 0.5rem 0.5rem 2.5rem 0.5rem;
					width: 100%;
				}
			}
			.wrapper {
				column-gap: 17px;
				grid-template-columns: repeat(
					auto-fill,
					minmax(100px, 5fr) minmax(100px, 1fr)
				);
			}
		}
	}
}
