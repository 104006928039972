.video-call-features {
	.is-android & {
		margin-top: 0;

		.pulse {
			left: 20%;
			top: 15%;
		}

		.features-modal {
			padding: 2rem 0;
		}

		.image-btn-container {
			img {
				max-height: calc(100vh - 6rem);
			}
		}

		.content-slides {
			margin-top: 0;
		}
	}

	.pulse {
		@include pulse-highlight(#ff00ff);
		border-radius: 50%;
		height: 40px;
		left: 25%;
		pointer-events: none;
		position: absolute;
		top: 25%;
		width: 40px;
	}

	.features-modal {
		background-color: $color-gray-light;
		display: none;
		height: 100%;
		left: 0;
		margin-top: 0;
		opacity: 0;
		outline: 0;
		overflow-y: auto;
		padding: 0 0 2rem;
		position: fixed;
		top: 0;
		transition: opacity 0.2s ease-in-out;
		width: 100%;
		z-index: 2;

		&.visible {
			display: block;
		}

		&.open {
			opacity: 1;
		}

		&.no-animate {
			.feature-btn-container {
				button {
					&:before {
						animation: none;
					}

					&:after {
						display: none;
					}
				}
			}
		}

		.container {
			padding-left: 0;
			padding-right: 0;
		}

		p {
			text-align: center;
		}
	}

	.close-modal-btn {
		position: absolute;
		right: 30px;
		top: 30px;
		z-index: 1;
	}

	.video-call-ui {
		display: flex;
		justify-content: center;
	}

	.image-btn-container {
		position: relative;

		img {
			max-height: calc(100vh - 2rem);
		}

		&.hide-for-android {
			margin-top: -0.5rem;
		}

		&.android-only {
			.feature-btn-container {
				bottom: 16.15%;
				width: 9.15%;

				&.camera-off-btn {
					left: 17.3%;
				}

				&.mute-btn {
					left: 31.17%;

					button {
						&::before,
						&::after {
							animation-delay: 0.85s;
						}
					}
				}

				&.end-call-btn {
					bottom: 15.35%;
					left: 45.35%;
					width: 11.85%;

					button {
						&::before,
						&::after {
							animation-delay: 1.7s;
						}
					}
				}

				&.camera-switch-btn {
					left: 61.8%;

					button {
						&::before,
						&::after {
							animation-delay: 2.55s;
						}
					}
				}
			}
		}
	}

	.feature-btn-container {
		bottom: 25.96%;
		height: auto;
		line-height: 0;
		margin: 0;
		padding: 0;
		position: absolute;
		width: 8.5%;

		button {
			@include pulse-highlight(#ff00ff);
			// background-color: rgba($color-blue-bright, 0.55);
			border-radius: 50%;
			height: 0;
			padding-top: 100%;
			pointer-events: auto;
			width: 100%;

			&.active {
				&::before {
					opacity: 1;
				}
			}
		}

		&.camera-off-btn {
			left: 29.5%;
		}

		&.mute-btn {
			left: 40.5%;

			button {
				&::before,
				&::after {
					animation-delay: 0.85s;
				}
			}
		}
		&.camera-switch-btn {
			left: 51.3%;

			button {
				&::before,
				&::after {
					animation-delay: 1.7s;
				}
			}
		}

		&.end-call-btn {
			left: 62.1%;

			button {
				&::before,
				&::after {
					animation-delay: 2.55s;
				}
			}
		}

		.feature-btn {
			&:focus {
				box-shadow: 0 0 2pt 2pt #ff00ff;

				.using-mouse & {
					box-shadow: none;
				}
			}
		}
	}

	.content-slides {
		margin-top: -6.5vh;
		padding: 0 30px;

		.title-secondary {
			@include text(iota);
		}
	}

	@include breakpoint(md) {
		.content-slides {
			.title-secondary {
				@include text(zeta);
			}
		}

		.image-btn-container {
			&.hide-for-android {
				margin-top: 0;
			}
		}
	}
}
