.nav-accordion {
	background-color: $color-white;
	position: relative;

	&.filter-accordion {
		> .accordion-item {
			&[aria-expanded='true'] {
				&.closing {
					> .accordion-item-body {
						transform: translateX(100%);
					}
				}

				> .accordion-item-body {
					transform: translateX(0);
					z-index: 1;
					overflow-x: hidden;
					visibility: visible;
				}
			}

			> .accordion-item-body {
				background-color: $color-gray-light;
				display: block;
				left: 0;
				min-height: 100vh;
				position: fixed;
				top: 0;
				transform: translateX(100%);
				transition: all 0.2s ease-in-out;
				visibility: hidden;
				width: 100%;

				> .accordion-item-body-inner {
					padding: 1rem 30px 6rem;
				}

				.filter-list & {
					min-height: calc(100vh - 80px);
				}
			}

			.mobile-back-btn {
				@include text(iota);
				@include font-weight(medium);
				align-items: center;
				color: $color-white;
				display: flex;
				height: 20px;
				margin-left: -1rem;

				.icon {
					fill: $color-gray-medium-light;
					height: 0.75rem;
					stroke: $color-white;
					width: 1.25rem;
				}
			}
		}
		&[data-accordion-opened='true'] {
			> .accordion-item {
				&[aria-expanded='false'] {
					display: none;
				}
			}
		}
	}

	&.flush {
		.accordion-item {
			&.opening {
				> .accordion-item-body {
					overflow: hidden;
					transition: height 0.4s ease-in-out;
				}
			}

			&[aria-expanded='true'] {
				> .accordion-item-body {
					display: block;
					height: auto;
				}

				&.closing {
					> .accordion-item-title {
						.accordion-item-toggle {
							@include font-weight(light);

							color: $color-gray-dark;

							&:hover,
							&:focus {
								color: $color-orange;
							}

							.icon-arrow-down {
								transform: translateY(-50%);
							}

							.label-secondary {
								opacity: 1;
							}
						}

						.accordion-item-tags {
							opacity: 1;
							transition: opacity 0.4s ease-in-out;
						}
					}

					> .accordion-item-body {
						overflow: hidden;
						transition: height 0.4s ease-in-out;
					}
				}
			}
		}
	}

	.accordion-item {
		border-bottom: 1px solid $color-gray-light;
		padding: 0;

		&[aria-expanded='true'] {
			> .accordion-item-title {
				.accordion-item-toggle {
					@include font-weight(semi-bold);
					color: $color-blue;
				}
			}
		}

		.accordion-item-title {
			position: relative;

			.accordion-item-toggle {
				@include text(epsilon);
				@include font-weight(light);
				background: none;
				border: none;
				color: $color-gray-dark;
				display: flex;
				margin: 0;
				padding: 1.5rem 2.5rem 1.5rem 20px;
				position: relative;
				text-align: left;
				width: 100%;

				&:hover,
				&:focus {
					color: $color-orange;
					outline: 0;

					.icon-arrow-down {
						fill: $color-orange-dark;
					}
				}

				.accordion-item-label {
					@include text(lambda);
					@include font-weight(semi-bold);
					white-space: nowrap;
				}

				.label-secondary {
					@include font-weight(light);
					color: $color-gray-medium;
					overflow: hidden;
					padding-left: 0.5rem;
					text-overflow: ellipsis;
					transition: opacity 0.4s ease-in-out;
					white-space: nowrap;
				}

				.icon-arrow-down {
					fill: $color-orange;
					position: absolute;
					right: 20px;
					top: 50%;
					transform: rotate(-90deg) translateX(50%);
				}
			}

			.accordion-item-tags {
				background-color: $color-white;
				transition: opacity 0.2s ease-in-out;
				z-index: 1;

				.tag-list {
					padding: 0 1rem 0.5rem;

					&:last-of-type {
						padding-bottom: 1rem;
					}

					.tag:first-of-type {
						padding-top: 0;
					}
				}
			}
		}

		.accordion-item-body {
			display: none;
			height: 0;
		}
	}

	@include breakpoint(lg) {
		&.filter-accordion {
			> .accordion-item {
				&[aria-expanded='true'] {
					&.closing {
						> .accordion-item-body {
							transform: none;
							overflow: hidden;
						}
					}

					.accordion-item-title {
						.accordion-item-toggle {
							.icon-arrow-down {
								transform: rotate(180deg) translateY(50%);
							}
						}
					}

					> .accordion-item-body {
						display: block;
						height: auto;
						overflow-x: unset;
					}
				}

				&.opening {
					> .accordion-item-body {
						overflow: hidden;
						transition: height 0.4s ease-in-out;
					}
				}

				.accordion-item-title {
					.accordion-item-toggle {
						padding-left: 1rem;

						.icon-arrow-down {
							right: 1rem;
							transform: translateY(-50%);
						}
					}
				}

				.accordion-item-body {
					display: none;
					height: 0;
					min-height: 0;
					position: relative;
					transition: all 0.4s ease-in-out;
					transform: none;
					.filter-list & {
						min-height: 0;
					}
					[class^='translated-'] & {
						min-height: 0;
						top: 0;
					}
					.accordion-item-body-inner {
						padding: 0 1rem 1rem;
					}
				}

				.mobile-back-btn {
					@include text(iota);
					@include font-weight(medium);
					align-items: center;
					color: $color-white;
					display: flex;
					height: 20px;
					margin-left: -1rem;

					.icon {
						fill: $color-gray-medium-light;
						height: 3rem;
						margin-right: -0.5rem;
						margin-top: -3px;
						stroke: $color-white;
						width: 3rem;
					}
				}
			}
			&[data-accordion-opened='true'] {
				> .accordion-item {
					&[aria-expanded='false'] {
						display: block;
					}
				}
			}
		}

		&.nav-accordion.flush {
			> .accordion-item {
				> .accordion-item-title > .accordion-item-toggle,
				> .accordion-item-body > .accordion-item-body-inner {
					padding-left: 0;
					padding-right: 0;
				}
				> .accordion-item-title
					> .accordion-item-toggle
					> .icon-arrow-down {
					transform: none;
				}
				&[aria-expanded='true'] {
					> .accordion-item-title {
						.accordion-item-toggle > .icon-arrow-down {
							transform: rotate(180deg);
						}
					}
				}
			}
		}
	}
}
