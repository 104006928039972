.selected-filters-list {
	padding: 10px 10px 0;

	.tag-list {
		margin-bottom: 0;
	}

	@include breakpoint(lg) {
		padding: 0;
	}
}
