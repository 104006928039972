.location-result {
	@include text(lambda);
	@include font-weight(medium);
	background-color: $color-gray-providers;
	color: $color-gray-dark;
	height: 100%;

	p {
		@include text(lambda);
		@include font-weight(medium);
		color: $color-gray-dark;
	}

	a {
		color: $color-blue;
	}
	.profile-image-container {
		width: 100%;
		background-color: $color-gray-light;
	}
	.profile-image {
		padding-bottom: 66.6667%;
		display: block;
		height: 0;
		overflow: hidden;
		position: relative;
		img {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			font-family: 'object-fit: cover; object-position: center 25%;';
			object-fit: cover;
			object-position: center 25%;
		}
	}
	.provider-website {
		.icon-external {
			height: 0.5rem;
			margin-left: 0.25rem;
			stroke: $color-blue;
			width: 0.5rem;
		}
	}
	.location-hours {
		@include text(lambda);
		display: flex;
		justify-content: space-between;
		height: 40px;
		padding: 12px 20px;
		.tooltip-link {
			align-items: flex-start;
			color: $color-blue;
			display: flex;
			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
		.tooltip {
			&:not([flow]):after,
			&[flow^='up']:after,
			&[flow^='left']:after {
				padding: 0.2rem;
			}
		}
		span {
			@include font-weight(bold);
		}
	}
	.location-details {
		padding: 1.5rem 30px;
	}
	.location-name {
		@include text(theta, iota);
		@include font-weight(bold);
		color: $color-black;
	}
	.distance-and-location {
		margin: 1rem 0;
	}

	.open-weekend-icon-container {
		display: flex;
	}
	.location-address {
		display: flex;
		flex-direction: column-reverse;
		.location-distance-phone {
			display: flex;
			justify-content: space-between;
			margin-top: 16px;
		}
		address {
			@include text(lambda, mu);
			@include font-weight(medium);
			margin-top: 16px;
		}
	}
	.detail-bottom {
		margin-top: 16px;

		p {
			margin-bottom: 0;
		}
	}
	.schedule-title {
		display: none;
	}
	.schedule-call-container {
		display: none;
	}
	.schedule-online {
		.btn-outline {
			width: 100%;
		}
	}

	@include breakpoint(md) {
		&.grid-result {
			.location-details {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				min-height: calc(53% - 40px);
			}
		}
		&:not(.grid-result) {
			display: flex;
			padding: 20px;
			margin-bottom: 30px;
			.left {
				width: 265px;
			}
			.right {
				width: 145px;
				padding-top: 4px;
			}

			.profile-image-container {
				width: 245px;
				height: 100%;
				.profile-image {
					padding-bottom: 62.041%;
				}
			}
			.location-hours {
				height: 70px;
				padding: 19px 15px;
			}
			.location-type {
				margin-bottom: 5px;
			}
			.location-name {
				@include text(iota);
				display: block;
				margin-bottom: 5px;
			}
			.location-details {
				padding: 0 0 0 20px;
				width: calc(100% - 180px);
			}
			.detail-top {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				min-height: 50%;
				> div {
					margin: 0;
				}
			}
			.detail-bottom {
				border-top: 1px solid $color-gray-neutral;
				padding-top: 16px;
			}
			.location-address {
				flex-direction: column;
				.location-distance-phone {
					display: block;
					margin-top: 0;
				}
			}
			.schedule-title {
				display: block;
				@include text(iota);
				@include font-weight(bold);
				color: $color-black;
				margin-bottom: 6px;
			}
			.schedule-call-container {
				display: block;
			}
			.schedule-options {
				display: flex;
				justify-content: space-between;
			}
		}
	}
	@include breakpoint(lg) {
		&:not(.grid-result) {
			.location-details {
				width: calc(100% - 245px);
			}
			.filters-closed & {
				.location-details {
					width: calc(100% - 380px);
				}
				.left {
					width: 360px;
				}
				.right {
					width: 265px;
				}
			}
		}
	}
}
