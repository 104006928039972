.claims-overview {
	.claims-summary {
		margin-bottom: 30px;
	}

	.claims-summary-inner {
		background-color: $color-off-white;
		margin-bottom: 2rem;
		margin-left: -30px;
		margin-right: -30px;
		padding: 1.25rem 30px;

		dt,
		dd {
			@include text(lambda);
		}

		dd {
			margin-bottom: 0.625rem;
		}
	}

	.total-claims {
		@include text(iota);
		@include font-weight(medium);
		color: $color-gray-dark;
		margin-bottom: 1rem;
	}

	.claims-wrap {
		.mobile-filters {
			background-color: $color-white;
			display: none;
			height: 100%;
			left: 0;
			overflow: auto;
			pointer-events: none;
			position: fixed;
			width: 100%;
			z-index: 11;

			&.filters-open {
				display: block;
				pointer-events: auto;
				top: 0;
			}

			.mobile-filter-header {
				@include make-container();
				align-items: center;
				background-color: $color-gray-dark;
				color: $color-gray-dark;
				display: flex;
				height: 54px;
				justify-content: center;
				position: relative;

				.mobile-close-btn {
					@include text(delta);
					@include font-weight(bold);
					background: none;
					border: none;
					color: $color-gray-medium;
					line-height: 0;
					margin-left: -1rem;
					padding: 1rem;
					transform: translateX(1rem);
					position: absolute;
					right: 14px;
					top: 50%;
					transform: translateY(-50%);

					.icon {
						height: 0.75rem;
						stroke: $color-white;
						width: 0.75rem;
					}

					&:hover,
					&:focus {
						outline: 0;

						.icon {
							fill: $color-blue;
						}
					}
				}

				.filter-heading {
					@include text(lambda);
					@include font-weight(semi-bold);
					color: $color-white;
					margin: 0;
				}
			}

			.table-filters-inner {
				padding: 30px;
			}

			.download {
				.mobile-filter-label {
					text-align: center;
				}

				.btn-outline {
					color: $color-black;
					margin-bottom: 0.75rem;
					width: 100%;

					+ .mobile-filter-label {
						margin: 2rem 0 0;
					}
				}
			}

			.mobile-clear-btn-container {
				text-align: center;

				.mobile-clear-btn {
					text-decoration: underline;
				}
			}

			.sort-radio-set {
				margin-bottom: 1.75rem;
			}

			.radio-wrapper {
				input[type='radio'] {
					+ label {
						&:before {
							border: 1px solid #bfbfbf;
						}
					}
				}
			}

			.DayPicker_transitionContainer {
				height: 600px !important;
			}
		}

		.table-filters {
			display: none;
		}
	}

	.data-summary {
		border: none;
		margin-bottom: 0;

		.data-section {
			background-color: transparent;
			border-right: none;
			flex-basis: 50%;
			max-width: 50%;
			padding: 1.375rem 1.875rem;

			dt {
				width: 40%;
			}

			dd {
				flex-direction: column;
				width: 60%;

				&.has-icon {
					display: flex;
					flex-direction: row;
				}
			}
		}
	}

	@include breakpoint(md) {
		.claims-summary {
			background-color: $color-off-white;
			display: flex;
			padding: 20px;
		}

		.total-and-summary-container {
			background-color: $color-off-white;
			display: flex;
			margin-bottom: 0.625rem;
			padding: 1.5rem 1.25rem;
		}

		.claims-summary-inner {
			background-color: transparent;
			display: flex;
			flex-grow: 1;
			margin: 0;
			padding: 0;

			dl {
				flex-grow: 1;
			}
		}

		.total-claims {
			display: flex;
			margin-right: 100px;
		}

		.claims-wrap {
			.table-filters {
				align-items: center;
				display: flex;
				margin-bottom: 1.25rem;
				margin-top: 2rem;
			}

			.label-filter {
				margin-right: 1.5rem;
			}

			.table-filters-inner {
				display: flex;
				flex: 1;
				justify-content: space-between;

				> * {
					flex: 0 1 calc(25% - 3px);
					max-width: calc(25% - 3px);
				}
			}

			.claims-mobile-container {
				display: none;
			}

			.input-icon-container {
				.icon-search {
					margin: 0;
					stroke: $color-gray-medium-light;
				}
			}

			.new-drop-down__button,
			.filter-input {
				margin-bottom: 0;
			}

			.download-wrap {
				margin-left: 1.5rem;
				position: relative;

				&:hover {
					cursor: pointer;
				}

				.icon.download {
					height: 20px;
					stroke: $color-gray-dark;
					stroke-width: 1px;
					width: 20px;
				}

				.download-header,
				.btn-container {
					display: block;
				}
			}

			.link-button {
				color: $color-black;
				margin: auto;
				margin-bottom: 0.5rem;
				width: 90%;
				&:focus,
				&:hover,
				&:active {
					color: $color-white;
				}

				a {
					text-decoration: solid $color-blue-bright;
				}
			}
		}
	}
}
