.ratings {
	display: flex;

	.radio-wrapper & {
		.average-rating {
			margin-left: 0.375rem;
		}

		.average-rating,
		.rating-count {
			color: $color-gray-dark;
			margin-top: 1px;
		}
	}

	.disabled & {
		.average-rating,
		.rating-count {
			color: $color-gray-neutral;
		}
	}

	.average-rating {
		@include text(lambda);
		margin: 2px 0.25rem 0 0.625rem;
	}

	.rating-count {
		color: $color-gray-medium;
		margin-top: 2px;
	}
}

.rating-stars {
	display: flex;
	justify-content: space-between;
	width: 100px;

	.disabled & {
		.star {
			svg {
				fill: $color-white;
			}

			.star-fill {
				svg {
					fill: $color-gray-neutral;
				}
			}
		}
	}

	.star {
		display: inline-block;
		position: relative;
		height: 18px;
		width: 18px;

		svg {
			fill: $color-gray-neutral;
			height: 18px;
			width: 18px;
			position: absolute;
			top: 0;
			left: 0;
		}

		.star-fill {
			position: absolute;
			top: 0;
			left: 0;
			overflow: hidden;
			width: 0;
			height: 18px;

			svg {
				fill: $color-yellow;
			}
		}
	}

	@for $i from 1 through 5 {
		.star:nth-child(#{$i}) {
			.star-fill {
				$percent: calc((var(--rating) - (#{$i} - 1)) * 100%);
				max-width: 100%;
				width: $percent;
			}
		}
	}
}
.rating-icon-info-alert {
	padding-top: 3px;
	padding-left: 9px;
	display: flex;
}
