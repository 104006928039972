.portal {
	select {
		appearance: none;
		background-color: white;
		padding: 0.8em 1em;
		border: none;
		margin: 0;
		width: 100%;
		font-family: inherit;
		font-size: inherit;
		cursor: inherit;
		line-height: inherit;
	}
}
