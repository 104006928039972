.related-providers {
	@include make-container();
	margin-bottom: 4rem;

	.related-providers-info {
		margin-bottom: 2rem;
		padding: 0;
		width: 100%;
	}

	.title {
		@include text(zeta, epsilon);
		margin-bottom: 10px;
	}

	.related-provider-inner {
		margin-bottom: 20px;
	}

	.profile-image-outer {
		max-width: 100%;
	}

	.profile-image-container {
		display: block;
		height: 0;
		overflow: hidden;
		padding-bottom: 66%;
		position: relative;
		width: 100%;
	}

	a {
		&.profile-image {
			&:focus-visible {
				border: $color-gray-darkest 2px solid;
			}
		}
	}

	.profile-image {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		img {
			font-family: 'object-fit: cover; object-position: center 25%;';
			height: 100%;
			object-fit: cover;
			object-position: center 25%;
			width: 100%;
		}
	}

	.provider-details {
		padding: 16px 0px 13px;

		.provider-name {
			display: block;
			margin-bottom: 6px;
			@include font-weight(bold);
		}

		.related-specialty {
			@include text(lambda, 16);
			@include font-weight(medium);
			color: $color-gray-dark;
			margin-bottom: 10px;
		}

		.ratings {
			margin-bottom: 12px;

			.rating-stars {
				width: 70px;
				.star,
				.star svg {
					height: 12px;
					width: 12px;
				}
			}
			.average-rating {
				@include text(mu, 12);
			}
			.rating-count {
				@include text(mu, 12);
				color: #595756;
			}
		}

		.new-patient {
			svg {
				width: 13px;
			}

			.new-patient-text {
				@include font-weight(medium);
				@include text(mu);
				color: $color-gray-dark;
				vertical-align: top;
			}
		}
	}

	.related-providers-link {
		border-bottom: 1px solid $color-gray-neutral;
		padding-bottom: 30px;

		.view-all {
			@include text(lambda);
			@include font-weight(medium);
			color: $color-blue-providers;
			text-decoration: none;

			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
	}

	@include breakpoint(md) {
		.related-providers-grid {
			align-items: center;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin-bottom: 30px;
		}

		.provider-details {
			padding: 16px 20px 13px;
		}

		.related-provider-inner {
			background-color: $color-gray-providers;
			display: flex;
			width: 50%;
		}

		.profile-image-outer {
			width: 200px;
		}

		.profile-image-container {
			padding-bottom: 100%;
		}

		.related-providers-link {
			padding-right: 40px;
		}
	}
}
