.location-scheduling {
	.location-scheduling-container {
		@include make-container();
	}

	.schedule-info-wrapper {
		margin-bottom: 3rem;
		padding-top: 3rem;
		position: relative;
	}

	.schedule-info-container {
		padding-left: 0;
		padding-right: 0;
	}

	.appointment-types {
		padding-top: 1rem;
		position: relative;
	}

	.appointment-type-description {
		a {
			display: block;
		}
	}

	@include breakpoint(md) {
		.location-scheduling-container {
			display: flex;
			flex-wrap: nowrap;
		}

		.location-about {
			flex: 0 1 calc(100% / 3);
			padding: 20px;

			.title {
				@include text(theta, iota);
				@include font-weight(medium);
			}

			p {
				@include text(lambda);
			}
		}

		.schedule-info-wrapper {
			align-items: center;
			display: flex;
			flex: 0 1 calc(100% / 3 * 2);
			flex-direction: column;
			padding-top: 4rem;
		}

		.schedule-info-container {
			flex: 0 1 100%;
			max-width: none;
			width: 100%;
		}
	}
}
