.slider {
	display: inline-block;
	height: 24px;
	margin-right: 0.75rem;
	position: relative;
	width: 42px;
	transition: 0.4s;

	.switch {
		background-color: $color-gray-neutral;
		bottom: 0;
		cursor: pointer;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
	}

	.switch:before {
		background-color: $color-white;
		bottom: 2px;
		content: '';
		height: 20px;
		left: 2px;
		position: absolute;
		transition: 0.4s;
		width: 20px;
	}

	input:checked + .switch {
		background-color: $color-gray-dark;
	}

	input:checked + .switch:before {
		transform: translateX(18px);
	}

	input:focus + .switch {
		outline: 2px solid $color-black;
	}

	.switch.round {
		border-radius: 24px;
	}

	.switch.round:before {
		border-radius: 50%;
	}

	+ .label-name {
		align-self: center;
		cursor: pointer;
	}
}
