.three-up {
	margin-bottom: 2rem;

	.heart-and-vascular & {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.three-up-image-container {
			padding-bottom: 56.25%;
		}

		.three-up-item {
			flex: 0 1 100%;
		}

		.three-up-content {
			padding: 1rem;
		}
	}

	&.card-4-by-3 {
		.three-up-image-container {
			padding-bottom: 75%;
		}
	}

	&.card-16-by-9 {
		.three-up-image-container {
			padding-bottom: 56.25%;
		}
	}

	.three-up-item {
		background-color: $color-gray-light;
		margin-bottom: 1.25rem;

		&.link-item {
			display: flex;
			flex-direction: column;
			text-decoration: none;

			&:hover,
			&:focus {
				.three-up-content {
					text-decoration: underline;
				}
			}

			.three-up-content {
				@include text(lambda);
				@include font-weight(medium);
				align-items: center;
				color: $color-blue;
				display: flex;
				flex: 1;
				justify-content: center;
				min-height: 60px;
				padding: 1rem 0.5rem;
				text-align: center;
			}
		}
	}

	.three-up-image-container {
		display: block;
		height: 0;
		overflow: hidden;
		padding-bottom: 66.666%;
		position: relative;
		width: 100%;
	}

	.three-up-image {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		img {
			font-family: 'object-fit: cover; object-position: center 25%;';
			height: 100%;
			object-fit: cover;
			object-position: center 25%;
			width: 100%;
		}
	}

	.three-up-content {
		padding: 24px 30px 30px;

		:last-child {
			margin-bottom: 0;
		}
	}

	.three-up-title {
		@include text(iota);
		@include font-weight(medium);
		margin-bottom: 0.75rem;
	}

	p {
		@include text(lambda);
		@include font-weight(medium);
		color: $color-gray-dark;

		a {
			@include text(lambda);
			@include font-weight(medium);
		}
	}

	.btn {
		min-width: 0;
		width: 100%;
	}

	@include breakpoint(xs) {
		.heart-and-vascular & {
			.three-up-item {
				flex: 0 1 calc(50% - 0.5rem);
				max-width: calc(50% - 0.5rem);
			}
		}
	}

	@include breakpoint(md) {
		@include make-container();
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.heart-and-vascular & {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.three-up-item {
				flex: 0 1 calc(100% / 3 - 1rem);
				max-width: calc(100% / 3 - 1rem);
			}
		}

		.container &,
		.hv-two-col & {
			padding: 0;
		}

		&:after {
			content: ' ';
			flex: 0 1 calc(100% / 3 - 1rem);
			max-width: calc(100% / 3 - 1rem);
		}

		.three-up-item {
			flex: 0 1 calc(100% / 3 - 1rem);
			max-width: calc(100% / 3 - 1rem);
		}
	}

	@include breakpoint(lg) {
		.heart-and-vascular & {
			.three-up-content {
				padding: 24px 30px 30px;
			}
		}

		.three-up-item {
			&.link-item {
				.three-up-content {
					@include text(iota);
					padding: 30px 1rem;
				}
			}
		}

		&.four-up {
			&:before {
				content: ' ';
				flex: 0 1 calc(100% / 4 - 1rem);
				max-width: calc(100% / 4 - 1rem);
				order: 1;
			}

			&:after {
				content: ' ';
				flex: 0 1 calc(100% / 4 - 1rem);
			}

			.three-up-item {
				flex: 0 1 calc(100% / 4 - 1rem);
				max-width: calc(100% / 4 - 1rem);

				&.link-item {
					.three-up-content {
						@include text(lambda);
						min-height: 65px;
						padding: 15px;
					}
				}
			}
		}
	}
}
