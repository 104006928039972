.location-overview {
	margin-bottom: 4rem;
	.accordion {
		.title {
			@include text(zeta, epsilon);
			margin-bottom: 10px;
		}
		.accordion-item {
			border-top: 1px solid $color-white;
			&:last-of-type {
				border-bottom: 0;
			}
		}
		.accordion-item-toggle {
			background-color: #f9f9f9;
			padding: 25px 0 22px;
		}
	}

	.tabs {
		display: none;
	}

	@include breakpoint(md) {
		@include make-container();
		margin-top: 4rem;
		.accordion {
			display: none;
		}

		.tabs {
			@include make-row();
			display: flex;
			flex-direction: row-reverse;
			margin: 0;

			.title {
				@include text(zeta, epsilon);
				margin-bottom: 10px;
			}

			.tabs-header {
				@include make-col-ready();
				@include make-col(4);
				background-color: transparent;
				margin-top: 0;
				padding: 0 0 0 20px;
			}

			.tab-panel {
				@include make-col-ready();
				@include make-col(8);
				padding: 0;
			}

			.tab-list {
				background-color: $color-off-white;
				display: block;
				padding: 0;
			}

			.tab-btn {
				background-color: #eeeeee;
				display: block;
				margin-bottom: 1px;
				padding: 28px 20px 26px 50px;
				text-align: left;
				width: 100%;

				&.active {
					background-color: #f9f9f9;
					border-top: 0;
					margin-top: 0;

					&:focus {
						background-color: #f9f9f9;
					}
				}
			}

			.tab-content {
				padding: 0;
				padding-right: 40px;
				.content-container {
					max-width: 100%;
				}
			}
		}
	}
}
