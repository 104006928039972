.ngde-form {
	&.errorEmail {
		.email-input {
			border-bottom: 2px solid $color-red;
		}
	}

	&.errorPhone {
		.phone-input {
			border-bottom: 2px solid $color-red;
		}
	}

	&.errorClaim {
		.claim-input {
			border-bottom: 2px solid $color-red;
		}
	}

	.nav-btn {
		display: block;
		margin-bottom: 0.8rem;
	}

	.active {
		@include font-weight(bold);
		color: $color-black;
	}

	.grid-row {
		@include make-row();
	}

	.action-btn {
		@include text(lambda);
		color: $color-blue;
		display: block;
		margin: 0 auto 1.5rem auto;
		padding: 0 10px;
		text-decoration: underline;
		&:hover,
		&:focus {
			text-decoration: none;
		}

		&.clear-btn {
			text-decoration: none;
			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
	}

	.content-col {
		.current-plan-card {
			@include make-row();
			padding: 1.5rem 0;

			.portal-id-card {
				margin-bottom: 0.6875rem;
			}
		}
	}

	.icon {
		stroke-width: 0px;
	}

	.icon-success,
	.error {
		//without this being min it was making it smaller on small screens.
		min-height: 22px;
		min-width: 22px;
	}

	.icon-question {
		min-height: 19px;
		min-width: 19px;
	}

	.icon-arrow-down {
		min-height: 15px;
		min-width: 7.5px;
		stroke-width: 1px;
	}

	.btn-primary {
		background-color: $color-gray-dark;
		border: 1px solid $color-gray-dark;
		color: $color-white;
		text-align: center;
		width: 100%;

		&:hover,
		&:active {
			background-color: $color-black;
			color: $color-white;
		}

		&:disabled {
			background-color: $color-white;
			border: 1px solid $color-gray-neutral;
			color: $color-gray-medium-light;
		}
	}

	@include make-row();
	margin-top: 1.5rem;

	&.success {
		.success-notification-wrap {
			@include text(iota);
			background-color: $color-green-pale-alert;
			display: flex;
			margin: 1rem 0 2rem 0;
			padding: 1.25rem;

			.text-wrapper {
				margin-left: 1.25rem;
			}
		}
	}

	&.error {
		.error-notification-wrap {
			@include text(iota);
			background-color: $color-red-light;
			display: flex;
			margin: 1rem 0 2rem 0;
			padding: 1.25rem;

			.text-wrapper {
				margin-left: 1.25rem;
			}
		}
	}

	.success-notification-wrap,
	.error-notification-wrap,
	.status-updating {
		display: none;
	}

	.form {
		.form-control {
			max-width: 100%;
		}
	}

	.checkbox-wrapper {
		@include checkbox(
			$borderColor: $color-gray-medium-light,
			$borderColorHover: $color-gray-dark,
			$checkboxColor: $color-black
		);

		color: $color-gray-medium-light;

		label {
			@include text(kappa);
			color: $color-gray-medium-light;
		}

		&.checkbox-span {
			display: flex;
			width: 100%;

			.pull-right {
				margin-left: auto;
			}
		}
	}

	.toggle {
		.label-icon {
			padding-right: 0.625rem;
		}

		.label-name {
			@include font-weight(semi-bold);
			color: $color-gray-dark;
		}

		.label-status {
			@include font-weight(bold);
			color: $color-gray-neutral;
			padding-right: 0.625rem;

			&.status-yes {
				color: $color-gray-dark;
			}
		}
	}

	&.updating {
		.status-updating {
			display: block;
		}
	}

	&.checked {
		.status-no {
			display: none;
		}
		.status-yes {
			display: block;
		}
	}

	&.not-checked {
		.status-no {
			display: block;
		}
		.status-yes {
			display: none;
		}
	}

	.radio-wrapper {
		input[type='radio'] {
			+ label {
				&:before {
					border: 1px solid $color-gray-medium-light;
				}
			}

			&:focus-visible {
				+ label {
					box-shadow: 0 0 0 2px $color-black;

					&:before {
						box-shadow: 0 0 0 1px $color-black;
					}
				}
			}
		}

		.display-name {
			@include font-weight(semi-bold);
			@include text(kappa);
			color: $color-gray-dark;
		}
	}

	.checkbox-list {
		margin-bottom: 2rem;
		padding-inline-start: 0;
	}

	input[type='text'] {
		background-color: $color-gray-light;
		border: 1px solid $color-gray-light;
		color: $color-black;
		padding-top: 1.8rem;
		text-align: left;

		&:disabled {
			background-color: $color-white;
			border-color: $color-gray-neutral;
		}
	}

	.floating-label {
		@include text(lambda);
		color: $color-gray-medium-light;
		left: 1.05rem;
		opacity: 1;
		pointer-events: none;
		position: absolute;
		top: 4px;
	}

	.error-message {
		padding: 0 0 0.625rem 0;
	}

	.select-wrapper {
		width: calc((100% - 15px) / 2 - 1px);
		left: 0;

		svg {
			stroke: $color-black;
			pointer-events: none;
			//right: 1.25rem;
			top: 50%;
		}

		&.audio-list-wrapper {
			left: auto;
			right: 15px;
		}
	}

	.text-box {
		@include text(iota);
		background-color: $color-gray-light;
		border: 1px solid $color-gray-light;
		height: 200px;
		padding: 1.5rem 1.05rem;
		resize: none;
		width: 100%;

		&:focus-visible {
			outline: none;
		}
	}

	.text-counter-wrapper {
		@include text(lambda);
		color: $color-gray-medium-light;
		padding: 0 0 1.625rem 1.25rem;
	}

	.select-input-wrapper {
		position: relative;
		padding-bottom: 12px;
		width: 100%;

		.select-wrapper {
			width: 100%;
		}

		.select {
			background-color: $color-gray-light;
			height: 60px;
			padding-top: 1.4rem;
		}

		.icon-arrow-down {
			pointer-events: none;
			position: absolute;
			right: 1.25rem;
			top: 30%;
		}
	}
}
