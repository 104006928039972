.browser-check {
	text-align: center;

	.title-secondary,
	p,
	.cta-link {
		text-align: center;
	}

	hr {
		margin: 1.2rem 0px;
	}

	.images-container {
		display: flex;
		justify-content: space-evenly;
		margin-bottom: 2rem;

		img {
			padding: 0 4px;
		}
	}

	.icon-danger {
		height: 80px;
		margin-bottom: 1.75rem;
		width: 80px;
	}

	.browser-list {
		.cta-link {
			display: inline-block;
		}
	}

	.is-ipad & {
		.desktop-only {
			display: none;
		}
	}

	@include breakpoint(md) {
		text-align: left;

		.grid-row {
			@include make-row();
		}

		.grid-col {
			@include make-col-ready();
			@include make-col(4);
		}

		p,
		.subtitle,
		.title-secondary {
			text-align: left;
		}

		.no-space {
			margin-bottom: 0;
		}

		.cta-link {
			margin: 0;
			text-align: left;
		}

		.images-container {
			justify-content: flex-start;

			img {
				padding: 0 2rem 0 0;
			}
		}
	}
}
