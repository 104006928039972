.appointment-tips {
	margin: 0 auto;
	max-width: 1000px;
	hr {
		background-color: $color-gray-neutral;
		border: none;
		color: $color-gray-neutral;
		height: 2px;
		margin-bottom: 1.5rem;
	}

	@include breakpoint(md) {
		hr {
			margin: 1.2rem 0px;
		}

		.grid-row {
			@include make-row();
		}

		.grid-col {
			@include make-col-ready();
			@include make-col(6);
			height: 100%;
		}
	}
}
