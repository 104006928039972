input[type='text'],
input[type='password'] {
	@include text(iota);
	background: $color-gray-neutral;
	border: 0;
	border-bottom: 2px solid transparent;
	caret-color: $color-blue-bright;
	color: $color-black;
	display: flex;
	height: 60px;
	margin-bottom: 0.75rem;
	outline: 0;
	padding: 1.25rem;
	position: relative;
	text-align: center;
	width: 100%;

	&::placeholder {
		color: $color-black;
	}

	&:focus {
		background-color: transparent;
		border-color: $color-gray-neutral;

		&::placeholder {
			color: $color-gray-neutral;
		}
	}

	.invalid & {
		background-color: transparent;
		border-color: $color-red;
		margin-bottom: 0;
	}

	@include breakpoint(lg) {
		padding-left: 0;
		text-align: left;

		&::placeholder {
			text-align: center;
		}
	}
}
