@mixin checkbox(
	$borderColor: $color-white,
	$borderColorHover: $color-gray-neutral,
	$checkboxColor: $color-black
) {
	align-items: flex-start;
	display: flex;
	min-height: 1.5rem;
	justify-items: flex-start;
	position: relative;

	input[type='checkbox'] {
		height: 1.5rem;
		margin-top: 2px;
		opacity: 0;
		width: 1.5rem;

		&:checked {
			+ label {
				&:after {
					border-bottom: 1px solid $checkboxColor;
					border-right: 1px solid $checkboxColor;
					content: '';
					display: block;
					height: 0.9rem;
					left: 0;
					margin: 0.25rem 0.5rem;
					position: absolute;
					top: 0;
					transform: rotate(45deg) translate(-1px, -1px);
					width: 0.45rem;
					@if ($checkboxColor != $color-black) {
						border-bottom-width: 2px;
						border-right-width: 2px;
					} @else {
						border-bottom-width: 1px;
						border-right-width: 1px;
					}
				}
			}
		}

		&:not([disabled]) {
			&:hover {
				cursor: pointer;

				+ label {
					&:before {
						border-color: $borderColorHover;
					}
				}
			}

			&:focus {
				+ label {
					&:before {
						border-color: $borderColorHover;
						border-width: 2px; 
					}
				}
			}

			+ label {
				&:hover {
					cursor: pointer;

					&:before {
						border-color: $borderColorHover;
					}
				}
			}
		}

		+ label {
			&:before {
				@if ($borderColor == transparent) {
					background-color: transparent;
				} @else {
					background-color: $color-white;
				}
				border: 1px solid $borderColor;
				border-radius: 2px;
				content: '';
				display: block;
				height: 1.5rem;
				left: 0;
				position: absolute;
				top: 0;
				width: 1.5rem;
			}
		}
	}
}
.checkbox-wrapper {
	@include checkbox();
	margin-bottom: 0.75rem;

	&.centered {
		input[type='checkbox'] {
			align-items: center;

			&:checked {
				+ label {
					&:after {
						top: 50%;
						margin-top: -8px;
					}
				}
			}

			+ label {
				&:before {
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
	}

	label {
		@include text(lambda);
		@include font-weight(medium);
		display: flex;
		justify-content: space-between;
		margin: 3px 0 0;
		padding: 0 0 0 3px;
		width: 100%;

		.label-display-name {
			+ .label-name {
				@include font-weight(semi-bold);
				color: $color-gray-medium;
				text-align: right;
			}
		}

		.label-name {
			flex-grow: 1;
		}

		.label-number {
			padding-left: 0.5rem;
		}
	}
}
