.ngde-my-account {
	padding-top: 155px;

	.hidden {
		display: none;
	}

	.first-header {
		@include text(gamma);
		padding-bottom: 75px;
	}

	input[type='text'],
	input[type='password'] {
		background-color: $color-gray-light;
		max-width: 360px;
		padding: 1.25rem;
		width: 100%;

		&::placeholder {
			text-align: left;
		}
	}

	.form .form-control button {
		max-width: 360px;
		width: 360px;
		text-align: center;

		&:disabled {
			&:hover {
				background-color: $color-white;
				border-color: $color-gray-neutral;
				color: $color-gray-neutral;
			}
		}
	}

	.reset-button {
		text-align: center;
		max-width: 360px;
		width: 360px;
	}

	.form-message {
		background-color: $color-info;
		border-bottom: 2px solid $color-info;
		display: flex;
		padding: 1.25rem 2.25rem;
		margin-bottom: 1rem;

		#reset-password-response {
			@include text(iota, 18);
			@include font-weight(semi-bold);
			margin-bottom: 0;
		}

		.icon {
			height: 1rem;
			width: 1rem;
			margin-right: 0.5rem;
		}

		&.success {
			background-color: $color-success;
		}

		&.error {
			background-color: $color-warning;
		}
	}

	.reset-password-form {
		label.input-label {
			position: relative;
			display: inline-block;
			margin: 0;
			width: 100%;
		}

		label.input-label > .icon {
			position: absolute;
			top: 40%;
			right: 15px;
			transform: translateY(-50%);
			color: silver;
			z-index: 100;
		}

		label.input-label > input {
			padding-left: calc(1em + 10px + 8px);
			width: 100%;
			max-width: none;
		}

		.rules-description {
			@include text(kappa);
			margin: 1rem 0 0;
		}

		.form-control {
			max-width: none;

			.submit-button,
			.reset-button {
				max-width: none;
				width: 100%;
			}
		}

		.valid,
		.invalid {
			margin-top: 0.25rem;
		}

		.checklist-icon {
			height: 1rem;
			width: 1rem;
			margin-top: 2px;
		}

		.new-password-input {
			&.invalid-password {
				border-bottom-color: $color-red;
			}

			&.valid-password {
				border-bottom-color: $color-green;
			}
		}

		.submit-button {
			border-color: $color-gray-dark;
			background-color: $color-gray-dark;
			color: $color-white;
			margin-top: 1rem;
			width: 100%;

			&:hover,
			&:focus {
				border-color: $color-black;
				background-color: $color-black;
				cursor: pointer;
			}

			&[disabled] {
				background-color: $color-white;
				border-color: $color-gray-neutral;
				color: $color-gray-neutral;

				&:hover,
				&:focus {
					background-color: $color-white;
					border-color: $color-gray-neutral;
					color: $color-gray-neutral;
					cursor: default;
				}
			}
		}

		.reset-button {
			&:hover {
				cursor: pointer;
			}
		}
	}

	.my-account-title {
		@include text(zeta);
		@include font-weight(bold);
		color: $color-gray-dark;
		margin-bottom: 1rem;
	}

	.my-account-description {
		@include text(kappa);

		.btn-link {
			@include text(kappa);
		}
	}

	.btn-outline {
		@include text(kappa);
		@include font-weight(bold);
		padding: 18px 22px;
		margin-top: 0.25rem;
	}

	.create-account-providers,
	.password-options {
		@include text(lambda);
		max-width: 360px;
		text-align: center;
	}

	.create-account {
		.btn {
			color: $color-white;
			border: 0;
			background: $color-gray-dark;
		}

		.create-account-desc-list {
			@include text(kappa);
			list-style-type: disc;
			margin-left: 1rem;
			margin-bottom: 2rem;
		}
	}

	.account-help-text {
		margin-top: 6rem;
		margin-bottom: 2rem;

		p {
			@include text(mu);
			margin-bottom: 0.25rem;
			text-align: center;
		}
	}

	@include breakpoint(md) {
		.centered-two-column {
			display: flex;
			margin: 0 auto;
			max-width: 1100px;

			.create-account {
				flex-basis: 55%;
				max-width: 55%;
				flex-grow: 1;
			}

			.portal-login {
				flex-basis: 45%;
				max-width: 45%;
				flex-grow: 1;

				.my-account-description {
					max-width: 360px;
				}
			}
		}

		.create-account {
			border-right: 1px solid $color-gray-light;
			margin-right: 3rem;
			margin-bottom: 3rem;
			padding-right: 4rem;
		}
	}

	@include breakpoint(lg) {
		.create-account {
			margin-right: 5rem;
			margin-bottom: 5rem;
			padding-right: 6rem;
		}

		input[type='text'],
		input[type='password'] {
			width: 360px;
		}

		.btn {
			width: 360px;
		}
	}
}
