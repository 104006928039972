.accordion {
	&.action-block-accordion {
		.accordion-item {
			border: none;
			margin-bottom: 0.25rem;

			&:last-of-type {
				border: none;
				margin-bottom: 0;
			}

			&.active {
				.closed-title {
					display: none;
				}

				.open-title {
					display: block;
				}

				.icon-arrow-down {
					transform: rotate(180deg);
				}
			}
		}

		.accordion-item-toggle {
			background-color: $color-gray-light;
			padding: 0;
		}

		.action-block {
			margin-bottom: 0;
		}

		.open-title {
			display: none;
		}

		.accordion-item-body {
			background-color: $color-gray-light;
			margin: 0;
			padding: 10px 20px 20px;
		}
	}

	&.hv-accordion {
		&.search-accordion {
			.accordion-item {
				&:last-of-type {
					border-bottom: none;
				}

				&.active {
					.accordion-item-body {
						display: flex;
						flex-direction: column;
						justify-content: space-around;
						padding-right: 0;

						@include breakpoint(lg) {
							flex-direction: row;
						}
					}
				}
			}

			.accordion-item-toggle {
				background-color: $color-off-white;
			}

			.accordion-item-body {
				background-color: $color-gray-light;
				margin: 0;
				padding: 0;
			}
		}

		.accordion-item {
			border-top: none;
			padding-bottom: 1px;
			border-bottom: 1px solid $color-gray-neutral;

			&:last-of-type {
				border-bottom: 1px solid $color-gray-neutral;
			}

			&.active {
				.accordion-item-body {
					display: block;
					margin-top: 0.5rem;
					margin-bottom: 0.75rem;

					a {
						@include text(kappa);
						@include font-weight(normal);
						color: $color-blue;
						display: inline-block;
						text-decoration: none;

						&:focus,
						&:hover {
							text-decoration: underline;
						}
					}

					.accordion-content {
						margin-bottom: 1.5rem;
					}

					.external-link {
						span {
							padding-right: 0.25rem;
						}

						&:focus,
						&:hover {
							text-decoration: none;

							span {
								text-decoration: underline;
							}
						}

						.icon-external {
							height: 10px;
							stroke: $color-blue;
							width: 10px;
						}
					}
				}

				.accordion-item-toggle {
					.icon-arrow-down {
						transform: rotate(180deg);
					}
				}
			}

			.toggle-container {
				.section-title {
					display: inline-block;
					max-width: 85%;
					overflow-wrap: normal;
				}
			}
		}

		.accordion-item-toggle {
			padding: 0.5rem 0;

			&:hover {
				background-color: $color-off-white;
			}

			span {
				width: 100%;
			}
		}

		.toggle-container {
			@include font-weight(normal);
			color: $color-black;
			padding-left: 0px;
			padding-right: 20px;
		}

		.accordion-item-more-less {
			background-color: white;

			&.active {
				.show-less {
					display: block;
				}

				.show-more {
					display: none;
				}
			}

			.accordion-item-body {
				margin-top: 0;
				margin-bottom: 0;
			}

			.show-less {
				display: none;
			}

			.show-more {
				display: block;
			}
			.toggle-container-more-less {
				display: flex;
				justify-content: center;

				.icon-arrow-down {
					height: 0.4rem;
					margin-left: 0.5rem;
				}
			}
		}
		.accordion-item-body {
			padding-left: 0px;
		}
	}

	.accordion-item {
		border-top: 2px solid $color-gray-neutral;

		&:last-of-type {
			border-bottom: 2px solid $color-gray-neutral;
		}

		&.active {
			.accordion-item-body {
				display: block;
			}

			.accordion-item-toggle {
				.icon-modal-close {
					transform: none;
				}
			}
		}
	}

	.accordion-item-toggle {
		padding: 30px 0;
		display: block;
		width: 100%;

		.icon-modal-close {
			height: 12px;
			stroke: $color-black;
			stroke-width: 6px;
			width: 12px;
			max-width: 12px;
			flex: 0 0 12px;
			transform: rotate(-45deg);
			// transition: transform 0.2s ease-in-out;
		}
	}

	.toggle-container {
		@include font-weight(bold);
		@include make-container();
		@include text(kappa);
		margin: 0;
		align-items: center;
		color: $color-gray-dark;
		display: flex;
		justify-content: space-between;
		text-align: left;
		pointer-events: none;
	}

	.accordion-item-body {
		@include font-weight(semi-bold);
		@include make-container();
		@include text(kappa);
		display: none;
		color: $color-gray-dark;
		margin-bottom: 40px;
		margin-top: 40px;

		p {
			@include font-weight(semi-bold);
			@include text(kappa);
		}
	}
}
