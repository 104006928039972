.grid-example {
	@include make-container();

	.grid-col {
		&:nth-child(1) {
			background-color: rgba($color-blue, 0.1);
		}
		&:nth-child(2) {
			background-color: rgba($color-blue, 0.2);
		}
		&:nth-child(3) {
			background-color: rgba($color-blue, 0.3);
		}
		&:nth-child(4) {
			background-color: rgba($color-blue, 0.4);
		}
		&:nth-child(5) {
			background-color: rgba($color-blue, 0.5);
		}
		&:nth-child(6) {
			background-color: rgba($color-blue, 0.6);
		}
		&:nth-child(7) {
			background-color: rgba($color-blue, 0.7);
		}
		&:nth-child(8) {
			background-color: rgba($color-blue, 0.8);
		}
		&:nth-child(9) {
			background-color: rgba($color-blue, 0.9);
		}
		&:nth-child(10) {
			background-color: rgba($color-blue, 1);
		}
		&:nth-child(11) {
			background-color: darken($color-blue, 5%);
		}
		&:nth-child(12) {
			background-color: darken($color-blue, 10%);
		}
	}

	.grid-content {
		color: $color-white;
		height: 100%;
		padding: 1rem;
	}

	p {
		margin-bottom: 0;
	}

	@include breakpoint(xs) {
		.grid-row {
			@include make-row();
		}

		.grid-col {
			@include make-col-ready();
			@include make-col(6);
			background-color: $color-gray-light;
			height: 100%;
		}

		.example-three {
			.grid-col {
				@include make-col(12);
			}
		}

		.example-seven {
			.grid-col {
				@include make-col(12);
			}
		}
	}

	@include breakpoint(sm) {
		.grid-col {
			@include make-col(4);
		}

		.example-two {
			.grid-col {
				@include make-col(6);
			}
		}

		.example-three {
			.grid-col {
				@include make-col(4);
			}
		}

		.example-four {
			.grid-col {
				@include make-col(6);
			}
		}

		// left rail
		.example-five {
			.grid-col {
				&:nth-child(1) {
					@include make-col(4);
				}
				&:nth-child(2) {
					@include make-col(8);
				}
			}
		}

		// right rail
		.example-six {
			.grid-col {
				&:nth-child(1) {
					@include make-col(8);
				}
				&:nth-child(2) {
					@include make-col(4);
				}
			}
		}
	}

	@include breakpoint(md) {
		aside {
			background-color: rgba($color-teal, 0.4);
		}

		.grid-col {
			@include make-col(3);
		}

		.example-two {
			.grid-col {
				@include make-col(4);
			}
		}
	}

	@include breakpoint(lg) {
		.grid-col {
			@include make-col(2);
		}
	}

	@include breakpoint(xl) {
		.grid-col {
			@include make-col(1);
		}

		.example-two {
			.grid-col {
				@include make-col(2);
			}
		}

		.example-three {
			.grid-col {
				@include make-col(4);
			}
		}
	}
}
