.location-select {
	position: relative;
	width: 100%;

	&.is-selected {
		.location-item {
			display: none;

			&.active {
				display: block;
			}
		}
	}

	&.is-scheduling {
		.location-select-nav {
			.location-list-btn {
				border-right: none;
			}

			.location-schedule-btn {
				display: none;
			}
		}

		.schedule-info-container {
			.schedule-header {
				.reset-btn {
					margin-top: 5rem;
				}
			}

			.appointment-types {
				margin-top: 5rem;
				width: calc(100% - 2rem);
			}
		}
	}

	.location-item {
		position: relative;

		&.active {
			padding-top: 50px;
			.location-info-container,
			.schedule-info-container {
				width: 100%;
			}
			&.scheduling {
				.location-info-container {
					display: none;
				}

				.schedule-info-container {
					display: block;
				}
			}

			.location-select-btn {
				display: none;
			}

			.location-select-nav {
				display: flex;
			}

			.location-info-container {
				display: block;
			}
		}
	}

	.location-info-container,
	.schedule-info-container {
		display: none;
	}

	.location-select-btn {
		align-items: center;
		background-color: $color-gray-light;
		display: flex;
		justify-content: space-between;
		margin-bottom: 0.125rem;
		margin-top: 0.125rem;
		padding: 0.75rem 1.25rem;
		text-align: left;
		width: 100%;
	}

	.location-label {
		padding-right: 1rem;
	}

	.location-name {
		@include font-weight(bold);
	}

	.icon-arrow-right {
		stroke-width: 1px;
	}

	.location-select-nav {
		display: none;
		justify-content: space-evenly;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1;

		.location-list-btn,
		.location-schedule-btn {
			@include text(iota);
			align-items: center;
			background-color: rgba($color-gray-medium-light, 0.95);
			border-top: 1px solid $color-white;
			color: $color-white;
			display: flex;
			flex-grow: 1;
			height: 3rem;
			justify-content: center;
			padding: 0 1.875rem;

			span {
				padding: 0 0.5rem;
			}

			.icon {
				height: 12px;
				stroke: $color-white;
				stroke-width: 1px;
				width: 20px;
			}
		}

		.location-list-btn {
			border-right: 1px solid $color-white;
			justify-content: flex-start;
		}

		.location-schedule-btn {
			justify-content: flex-end;
		}
	}

	.location-info-container {
		.location-image {
			.distance-overlay {
				bottom: 1.25rem;
				top: auto;
			}
		}
	}

	.location-list-desktop-btn {
		display: none;
		font-weight: 900;
		margin-bottom: 2rem;
	}

	@include breakpoint(md) {
		.location-list-desktop-btn {
			display: block;
		}

		.location-item {
			&.active {
				padding-top: 0px;
			}
			.location-list-btn {
				display: none;
			}
		}
		&.is-selected {
			.location-item {
				&.active {
					display: flex;
					justify-content: space-around;

					.schedule-info-container {
						display: block;
					}
				}
			}

			.schedule-info-default {
				display: none;
			}
		}

		.location-select-btn,
		.location-select-nav {
			max-width: calc(100% / 3);
			flex-basis: calc(100% / 3);
		}

		.location-select-nav {
			.location-list-btn {
				border-right: none;
			}

			.location-schedule-btn {
				display: none;
			}
		}

		.schedule-info-default {
			display: flex;
			flex-direction: column;
			position: absolute;
			top: 0;
			right: 0;
			width: calc(100% * 2 / 3);
		}
	}
}
