.ngde-member-id-cards {
	@include make-container();
	padding-bottom: 100px;
	padding-top: 200px;

	.order-card,
	.card-section,
	.card-image-section {
		&.loading {
			.loading-overlay-section {
				opacity: 1;
			}
			.loading-screen-reader {
				display: block;
			}
		}
	}

	.loading-screen-reader {
		display: none;
	}

	.plan-inactive {
		display: none;
	}

	.no-card {
		display: none;
	}

	&.set-active {
		.plan-inactive {
			display: none;
		}

		.no-card {
			display: none;
		}

		.plan-active,
		.plan-list-active {
			display: block;
		}
	}

	&.set-inactive {
		.plan-inactive {
			display: block;
		}

		.no-card {
			display: none;
		}

		.plan-active,
		.plan-list-active {
			display: none;
		}
	}

	&.set-no-card {
		.plan-inactive {
			display: none;
		}

		.no-card {
			display: block;
		}

		.plan-active,
		.plan-list-active {
			display: none;
		}
	}

	.member-id-card-section {
		@include font-weight(medium);
		@include text(theta);
		position: relative;
	}

	.section-description {
		@include text(kappa);
	}

	p {
		&.section-description {
			width: 96%;
		}
	}

	.customer-service {
		display: inline-block;
	}

	.member-id-card-span {
		position: absolute;
		top: -150px;
	}

	a,
	.nav-btn {
		color: $color-blue;
		text-decoration: none;
		li {
			margin-bottom: 0.5rem;
		}

		&:hover {
			text-decoration: underline;
		}
	}

	.nav-btn {
		display: block;
		margin-bottom: 0.8rem;
	}

	.active {
		@include font-weight(bold);
		color: $color-black;
	}

	.grid-row {
		@include make-row();
	}

	.action-btn {
		@include text(lambda);
		color: $color-blue;
		padding: 0 10px;
		text-decoration: underline;

		&:hover,
		&:focus {
			text-decoration: none;
		}
	}

	.content-col {
		.current-plan-card {
			@include make-row();
			padding: 1.5rem 0;

			.portal-id-card {
				margin-bottom: 0.6875rem;
			}
		}
	}

	.card-action-wrap {
		text-align: center;

		.card-action-first {
			padding: 0 10px 0 0;
		}
	}

	h1 {
		color: $color-red !important;
		margin-bottom: 1rem;
		@include text(iota);
	}

	h2 {
		margin-bottom: 1rem;
		@include text(zeta);
	}

	h3,
	.sub-header {
		@include font-weight(bold);
		@include text(iota);
		color: $color-black;
		margin-bottom: 1.5rem;
	}

	// .overview-text {
	// 	@include font-weight(semi-bold);
	// 	@include text(kappa);
	// }

	.icon-success,
	.error {
		//without this being min it was making it smaller on small screens.
		min-height: 22px;
		min-width: 22px;
	}

	.icon {
		stroke-width: 0px;
	}

	.btn-primary {
		@include text(iota);
		background-color: $color-gray-dark;
		border: 1px solid $color-gray-dark;
		color: $color-white;
		text-align: center;
		width: 100%;

		&:hover,
		&:active {
			background-color: $color-black;
			color: $color-white;
		}

		&:disabled {
			background-color: $color-white;
			border: 1px solid $color-gray-neutral;
			color: $color-black;
		}
	}

	.fade {
		opacity: 0;
	}

	.front-source,
	.back-source {
		opacity: 1;

		&.fade {
			opacity: 0.5;
			transition: opacity 0.2s ease-in-out;
		}
	}

	.card-section,
	.order-card {
		@include make-row();
		margin-top: 1.5rem;
		&.success {
			.success-notification-wrap {
				@include text(iota);
				background-color: $color-green-pale-alert;
				display: flex;
				margin: 1rem 0 2rem 0;
				padding: 1.25rem;

				.text-wrapper {
					margin-left: 1.25rem;
				}
			}
		}

		.plan-inactive {
			display: none;
		}

		.to-fax-number {
			white-space: nowrap;
		}

		&.errorEmail,
		&.errorFax,
		&.errorFail,
		&.errorCheckBox {
			.error-notification-wrap {
				@include text(iota);
				background-color: $color-red-light;
				display: flex;
				margin-bottom: 2rem;
				padding: 1.25rem;
			}

			.error-wrapper {
				margin-left: 1.25rem;
			}
		}

		&.errorEmail {
			.text-wrapper-email {
				margin-left: 1.25rem;
				display: flex;
			}

			.text-wrapper-fax,
			.text-wrapper-fail {
				display: none;
			}

			.email-input {
				border-bottom: 2px solid $color-red;
			}
		}

		&.errorFax {
			.text-wrapper-email,
			.text-wrapper-fail {
				display: none;
			}

			.text-wrapper-fax {
				margin-left: 1.25rem;
				display: flex;
			}

			.fax-input {
				border-bottom: 2px solid $color-red;
			}
		}

		&.errorFail {
			.text-wrapper-email,
			.text-wrapper-fax {
				display: none;
			}

			.text-wrapper-fail {
				margin-left: 1.25rem;
				display: flex;
			}
		}

		.action-btn {
			display: block;
			margin: 0 auto 1.5rem auto;
		}

		.success-notification-wrap,
		.error-notification-wrap {
			display: none;
		}

		.form {
			.form-control {
				max-width: 100%;
			}
		}

		.checkbox-wrapper {
			@include checkbox(
				$borderColor: $color-gray-medium-light,
				$borderColorHover: $color-gray-dark,
				$checkboxColor: $color-black
			);

			color: $color-gray-medium-light;

			label {
				@include text(kappa);
				color: $color-gray-medium-light;
			}
		}

		.checkbox-list {
			margin-bottom: 2rem;
			padding-inline-start: 0;
		}

		input[type='text'] {
			background-color: $color-gray-light;
			border: 1px solid $color-gray-light;
			color: $color-black;
			padding-top: 1.5rem;
			text-align: left;

			&:disabled {
				background-color: $color-white;
				border: 1px solid $color-gray-neutral;
				color: $color-gray-medium-light;
			}
		}

		.floating-label {
			@include text(lambda);
			color: $color-gray-medium-light;
			left: 1.2rem;
			opacity: 1;
			pointer-events: none;
			position: absolute;
			top: 4px;
		}

		.error-message {
			padding: 0 0 0.625rem 0;
		}
	}

	@include breakpoint(lg) {
		padding-top: 300px;

		// h1 {
		// 	@include text(gamma);
		// }

		// .overview-text {
		// 	@include font-weight(light);
		// 	@include text(zeta);
		// }

		.card-action-wrap {
			text-align: left;
			.card-action {
				text-decoration: none;
			}
		}
	}

	/* Safari 10.1+ */

	@media not all and (min-resolution: 0.001dpcm) {
		@supports (-webkit-appearance: none) {
			form.form div.form-control div.input-field input[type='text'] {
				&:disabled {
					-webkit-text-fill-color: $color-gray-medium-light;
					opacity: 1; /* required on iOS */
				}
			}
		}
	}
}
