.report-inaccuracy-block {
	@include make-container();
	text-align: center;
	margin-bottom: 0;
	margin-top: 0;

	&.has-search {
		display: none;
		.detail-view-loaded & {
			display: block;
		}
	}

	a {
		@include text(lambda);
		@include font-weight(medium);
		color: $color-blue;
	}

	& > div {
		a {
			@include text(lambda, theta);
		}
	}

	@include breakpoint(md) {
		margin-bottom: 0;

		a {
			text-decoration: none;

			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
	}

	@include breakpoint(lg) {
		margin-bottom: 0;
	}
}
