.two-column-content {
	margin-bottom: 1.5rem;
	@include breakpoint(md) {
		columns: 2;
	}
}

.three-column-content {
	@include breakpoint(md) {
		columns: 2;
		margin-bottom: 1.5rem;
	}

	@include breakpoint(lg) {
		columns: 3;
	}
}

.four-column-content {
	@include breakpoint(md) {
		columns: 2;
		margin-bottom: 1.5rem;
	}

	@include breakpoint(lg) {
		columns: 3;
	}

	@include breakpoint(xl) {
		columns: 4;
	}
}
