.schedule-btns {
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin-bottom: 1.5rem;
	max-width: 275px;

	.btn {
		@include text(lambda);
		@include font-weight(medium);
		border: 1px solid $color-gray-neutral;
		border-radius: 2px;
		flex-basis: calc(50% - 5px);
		margin: 0;
		max-width: 265px;
		min-width: calc(50% - 5px);
		padding: 0.625rem 0.25rem 0.5rem;

		&:only-child {
			flex-basis: 100%;
			min-width: 100%;
		}

		&.open-scheduling-btn {
			background-color: $color-gray-dark;
			border: 0;
			color: $color-white;

			&:hover,
			&:focus {
				background-color: $color-black;
			}
		}
	}

	p {
		flex-basis: calc(50% - 5px);
		min-width: calc(50% - 5px);
	}

	@include breakpoint(md) {
		justify-content: space-around;

		.schedule-info-container & {
			margin-left: auto;
			margin-right: auto;
			max-width: 500px;

			.btn {
				@include text(iota);
				flex-basis: calc(50% - 10px);
				min-width: calc(50% - 10px);
				padding: 1rem 0.5rem;

				&:only-child {
					min-width: 0;
				}
			}
		}
	}
}
