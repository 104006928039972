.media-device {
	&.still-pending {
		&.pending {
			.media-permissions-pending {
				opacity: 1;
			}
		}
	}

	&.pending {
		.media-device-test {
			display: none;
		}

		.media-permissions-pending {
			$header-height: 60px;
			$footer-height: 50px;
			$safari-ios-buffer: 122px;
			display: flex;
			height: calc(100vh - #{$header-height} - #{$footer-height});
			justify-content: center;
			opacity: 0;
			transition: opacity 0.4s;

			.mobile-only {
				height: 100%;
			}

			@supports (-webkit-touch-callout: none) {
				height: calc(
					100vh - #{$header-height} - #{$footer-height} - #{$safari-ios-buffer}
				);
			}
		}
	}

	&.success {
		.test-status {
			.dot-pulse {
				display: none;
			}

			svg {
				display: block;
				height: 38px;
				width: 38px;
			}
		}

		.default-message {
			display: none;
		}

		.success-message {
			display: block;
		}
	}

	&.fail-permissions {
		.media-device-test {
			display: none;
		}

		.media-device-permissions {
			display: flex;
			justify-content: center;
		}

		.device-help {
			background-color: $color-gray-dark;
			display: block;
			margin-bottom: -1px;
			margin-top: 0;
			overflow: auto;
			padding-bottom: 0;

			img {
				max-height: 370px;
				margin: 2.5rem auto 0;
			}

			.container {
				> :last-child {
					margin-bottom: 0;
				}
			}

			.side-by-side {
				display: flex;
				justify-content: center;

				img {
					margin: 0 2px;
					max-width: calc(50% - 4px);
				}
			}

			.subtitle {
				color: $color-white;
			}

			.is-android & {
				img {
					margin: 4rem auto;
				}

				.side-by-side {
					margin: 4rem auto;

					img {
						margin: 0 2px;
					}
				}
			}
		}
	}

	.media-device-test {
		@include make-container();
		display: block;
	}

	.content-container {
		display: block;
	}

	.media-permissions-pending {
		display: none;
	}

	.pending-container {
		@include make-container();
		align-items: center;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-between;

		div {
			display: flex;
			flex-direction: column;
			justify-content: center;

			:last-child {
				margin-bottom: 0;
			}

			.icon-error {
				height: 40px;
				margin: 0 auto 1rem;
				width: 40px;
			}

			.is-android & {
				&:last-of-type {
					margin-bottom: 3.5rem;
				}
			}
		}

		.title-secondary {
			@include text(iota);
			color: $color-white;
		}

		img {
			margin: 1.5rem 0 2rem;
			width: 125px;
		}

		p {
			margin-bottom: 1rem;
			text-align: center;
		}
	}

	.media-device-permissions {
		display: none;
	}

	.permissions-container {
		@include make-container();
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.title-secondary {
			@include text(iota);
			color: $color-white;
		}

		img {
			margin: 1.25rem 0 2rem;
			width: 125px;

			&.mobile-only {
				width: 100px;
			}
		}

		p {
			margin-bottom: 1rem;
			text-align: center;
		}
	}

	.refresh {
		@include text(kappa);
		color: $color-blue-bright;
		margin-bottom: 1rem;
		text-decoration: none;
	}

	.device-help {
		display: none;
	}

	.media-container {
		max-width: 500px;
		margin: 0 auto 2rem;
		position: relative;
		width: 100%;
	}

	.video-container {
		height: 0;
		padding-top: 100%;
		width: 100%;
		position: relative;
	}

	.video {
		background: black;
		height: 100%;
		width: calc(100% - 15px);
		object-fit: cover;
		position: absolute;
		top: 0;
		left: 0;
	}

	.select-wrapper {
		bottom: 0;
		position: absolute;
		width: calc((100% - 15px) / 2 - 1px);
		left: 0;

		svg {
			stroke: $color-white;
			pointer-events: none;
			position: absolute;
			right: 1.25rem;
			top: 50%;
			transform: translateY(-50%) rotate(-90deg);
		}

		&.audio-list-wrapper {
			left: auto;
			right: 15px;
		}
	}

	.device-list-video,
	.device-list-audio {
		@include text(kappa);
		@include font-weight(bold);
		background-color: rgba($color-black, 0.5);
		border: none;
		border-radius: 0;
		bottom: 0;
		color: $color-white;
		height: 60px;
		left: 0;
		padding: 1rem 2.5rem 1rem 1rem;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-appearance: none;
		white-space: nowrap;
		width: 100%;
	}

	.meter-container {
		right: 0;
		bottom: 0;
		height: 100%;
		background-color: $color-gray-dark;
		position: absolute;
		width: 15px;
	}

	.sound-meter {
		background-color: $color-blue-bright;
		bottom: 0;
		height: 0;
		max-height: 100%;
		position: absolute;
		transition: height 0.1s ease-in-out;
		width: 100%;
	}

	.test-status {
		align-items: center;
		background-color: rgba($color-gray-dark, 0.8);
		border-radius: 50%;
		display: flex;
		justify-content: center;
		position: absolute;
		top: 1.25rem;
		left: 1.25rem;
		width: 38px;
		height: 38px;

		svg {
			display: none;
		}
	}

	.audio {
		margin: 0 0 1.5em 0;
		width: 400px;
	}

	.device-row {
		display: block;
	}

	.dot-pulse {
		@include dot-pulse();
	}

	.success-message {
		display: none;
	}

	.btn {
		width: 100%;
	}

	.icon-error {
		height: 80px;
		margin: 0 auto 1.75rem;
		width: 80px;
	}

	@include breakpoint(md) {
		.btn {
			width: auto;
		}

		.media-device-test {
			align-items: center;
			display: flex;
		}

		&.pending {
			.media-permissions-pending {
				$header-height: 60px;
				$footer-height: 160px;
				height: calc(100vh - #{$header-height} - #{$footer-height});
			}
		}

		.media-container {
			margin: 0 2rem 0 0;
			width: 50%;
		}

		.content-container {
			max-width: calc(50% - 2rem);
		}
	}

	@include breakpoint(lg) {
		.media-container {
			margin: 0 120px 0 0;
			width: 100%;
		}

		.content-container {
			max-width: 460px;
		}
	}
}
