.hv-blockquote {
	text-align: center;
	margin-bottom: 2rem;
	background: $color-gray-light;
	padding: 3rem 1.25rem;

	.icon-quote {
		flex: 3;
		width: 3.3rem;
		height: 2.7rem;
		fill: $color-gray-neutral;
	}

	.quote {
		@include text(eta);
		@include font-weight(extra-bold);

		font-style: oblique;
	}

	@include breakpoint(md) {
		padding: 3rem 6rem;
	}
}
