.message-block {
	background: $color-gray-providers;
	padding: 1rem;
	margin: 1rem -1rem 0;

	> :last-child {
		margin-bottom: 0;
	}

	.heading {
		@include font-weight(bold);
		@include text(iota);
		margin-bottom: 0.25rem;
	}

	.description {
		@include font-weight(normal);
		@include text(lambda);

		a {
			color: $color-blue;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	> :last-child {
		margin-bottom: 0;
	}
}
