.background-image-container {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.background-image {
	height: 100%;
	object-fit: cover;
	width: 100%;
}
