#mychartframe {
	min-height: auto !important;
	height: 100% !important;
}

.echeckin-modal {
	&.iframe-loaded {
		.loading-indicator {
			display: none;
		}
		.echeckin-modal-info {
			display: block;
		}
	}

	&.mychart-down {
		.mychart-error-message {
			display: block;
		}

		iframe,
		.loading-indicator {
			display: none;
		}
	}

	.mychart-error-message {
		display: none;
	}

	.modal-body {
		overflow-y: hidden;
		display: flex;
		flex-direction: column;
	}

	.loading-indicator {
		padding: 3rem 2rem;
	}

	.echeckin-modal-info {
		float: right;
		display: none;
		padding-right: 10px;
		padding-top: 5px;
		padding-bottom: 5px;
		font-size: 1.125rem;
		font-family: AvenirHeavy, Helvetica, sans-serif;
		font-weight: normal;
		align-self: flex-end;
	}
}
