.related-specialties {
	@include make-container();
	@include text(lambda);
	@include font-weight(medium);
	color: $color-gray-dark;
	margin-bottom: 4rem;

	.related-specialties-info {
		margin: 0px auto 30px 0;
		padding: 0;
		width: 100%;
	}

	.title {
		@include text(zeta, epsilon);
		margin-bottom: 10px;
	}

	ul {
		list-style: none;
		padding: 0;
		li {
			@include text(lambda, mu);
			padding-bottom: 10px;
			&:last-child {
				margin-top: 30px;
			}
		}
	}

	.view-all {
		@include font-weight(medium);
		color: $color-blue-providers;
		text-decoration: underline;
		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	@include breakpoint(md) {
		.related-specialties-info,
		.related-specialties-list {
			ul {
				columns: 3;
				li {
					max-width: 200px;
					&:last-child {
						margin-top: 0px;
					}
				}
			}
			.view-all {
				text-decoration: none;
			}
		}
	}
}
