.location-info-container {
	.location-image {
		display: block;
		position: relative;
		text-decoration: none;
		width: 100%;

		img {
			width: 100%;
		}

		.distance-overlay {
			@include text(lambda);
			background-color: $color-white;
			border: 1px solid $color-gray-medium-light;
			border-radius: 0.25rem;
			color: $color-gray-dark;
			left: 1.25rem;
			padding: 0.375rem 0.5rem;
			position: absolute;
			top: 1.25rem;
		}
	}

	.location-info {
		margin: 1rem 2rem;

		.location-name {
			@include font-weight(bold);
			@include text(iota);
			color: $color-black;
			margin-bottom: 1rem;
		}

		.location-data {
			margin-bottom: 1.5rem;
			p,
			a {
				@include font-weight(medium);
				@include text(lambda);
				margin-bottom: 0;
			}

			a {
				color: $color-blue;
				text-decoration: none;
				box-sizing: border-box;
				height: 22.04px;
			}

			a:hover {
				border-bottom: 1px solid $color-blue;
			}
		}
	}

	.contact-website {
		.contact-title {
			@include font-weight(bold);
			margin-bottom: 0.4rem;
		}
	}

	.office-hours {
		.is-expanded {
			.icon-arrow-down {
				padding-bottom: 6px;
				padding-top: 0;
				transform: rotate(180deg);
			}
		}

		.read-more {
			margin-bottom: 0;
		}

		.icon-arrow-down {
			margin-left: 4px;
			padding-top: 6px;
			stroke: $color-blue;
		}

		.toggle-expand {
			margin-top: 0;
		}

		.read-more-body {
			margin-bottom: 0.5rem;
		}

		p {
			span {
				display: inline-block;
				min-width: 2.25rem;
			}
		}
	}
}
