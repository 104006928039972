.content-slides {
	.at & {
		margin-bottom: 2rem;

		.desktop-only {
			display: none;
		}

		.prev,
		.next {
			display: inline-flex;
			margin-bottom: 25px;
			position: relative;
			transform: none;

			&:disabled {
				pointer-events: none;

				.icon {
					stroke: $color-gray-neutral;
				}
			}
		}

		.prev {
			margin-right: 6px;
		}

		.slide-indicators {
			margin-top: 1rem;
		}

		@include breakpoint(lg) {
			.desktop-only {
				display: flex;
			}

			.mobile-only {
				display: none;
			}

			.prev {
				margin-right: 10px;
			}
		}
	}
}
