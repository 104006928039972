body.detail-view-loaded {
}
.search-page {
	#typeahead {
		z-index: 8999;
		background-color: white;
		color: black;
		padding: 15px;
		a {
			color: black;
			font-size: 14px;
			text-decoration: none;
		}

		.highlight {
			font-weight: 600;
			color: black;
		}
	}
	.rbt-aux {
		display: none;
	}
	.typeahead-option {
		background-color: white;
		padding: 15px;
		text-decoration: none;
		color: black;
		display: flex;
		gap: 10px;
		.icon-star,
		.icon-doctor {
			fill: none;
			stroke-width: 1px;
			width: 15px !important;
		}
		p {
			font-size: 13px;
			margin-bottom: 0.25rem;
		}
	}

	&.location-search {
		#typeahead,
		.rbt .rbt-aux,
		.rbt-menu {
			display: none !important;
		}
	}
	.dropdown-item:hover {
		text-decoration: none;
	}
	.typeahead-option:hover {
		text-decoration: none;
		background-color: $color-gray-light;
	}
	.typeahead-option:not(:last-child) {
		border-bottom: 1px solid var($color-gray-dark);
	}
	.results-list {
		li {
			margin: 0;

			&:before {
				display: none;
			}
		}
	}
	.mobile-sort-container {
		background-color: $color-gray-light;
		pointer-events: none;
		overflow: auto;
		visibility: hidden;
		opacity: 0;
		top: 20vh;
		position: fixed;
		width: 100%;
		height: 100%;
		left: 0;
		transition: visibility 0s ease-in-out 400ms, opacity 0.2s ease-in-out,
			top 0.2s ease-in-out;
		z-index: 9;

		&.sort-open {
			display: block;
			opacity: 1;
			pointer-events: auto;
			visibility: visible;
			top: 0;
			transition-delay: 0s;

			[class^='translated-'] & {
				height: calc(100% - 39px);
				top: 39px;
			}
		}

		.mobile-sort-header {
			@include make-container();
			align-items: center;
			background-color: $color-gray-medium-light;
			color: $color-gray-dark;
			display: flex;
			height: 48px;
			justify-content: space-between;
			position: relative;

			.mobile-close-btn {
				@include text(delta);
				@include font-weight(bold);
				background: none;
				border: none;
				color: $color-gray-medium;
				line-height: 0;
				margin-left: -1rem;
				padding: 1rem;
				transform: translateX(1rem);

				.icon {
					height: 0.75rem;
					stroke: $color-white;
					width: 0.75rem;
				}

				&:hover,
				&:focus {
					outline: 0;

					.icon {
						fill: $color-blue;
					}
				}
			}

			.sort-heading {
				@include text(iota);
				@include font-weight(medium);
				color: $color-white;
				margin: 0;
			}
		}

		.mobile-sort-body {
			display: flex;
			height: calc(100% - 48px);
			flex-direction: column;
			padding: 30px;
		}

		.radio-wrapper {
			label {
				color: $color-black;
			}
		}

		.mobile-sort-apply-btn {
			@include text(lambda);
			@include font-weight(medium);
			background-color: $color-gray-dark;
			border-color: $color-gray-dark;
			color: $color-white;
			margin-top: auto;
			padding: 0.5rem 1rem;
		}
	}

	.clear-all-btn {
		color: $color-blue;
		text-decoration: underline;
	}

	.controls-top {
		align-items: flex-start;
		display: flex;
		justify-content: space-between;
		padding-bottom: 0.25rem;
		width: 100%;
	}

	.view-mode-controls-container {
		align-items: center;
		background-color: $color-gray-medium;
		display: none;
		justify-content: space-between;
		padding: 0.5rem;
	}

	.desktop-filters-toggle:hover {
		text-decoration: underline;
	}

	.view-btn {
		align-items: center;
		display: flex;
		justify-content: center;
		margin-right: 0.75rem;

		&:last-of-type {
			margin-right: 0;
		}

		.icon {
			fill: $color-gray-neutral;
		}

		&:hover,
		&:focus {
			.icon {
				fill: $color-black;
			}
		}

		&[disabled] {
			.icon {
				fill: $color-black;
			}
		}
	}

	.results-controls {
		@include make-container();
		background-color: rgba($color-gray-medium-light, 0.9);
		color: $color-white;
		height: auto;
		padding-bottom: 6px;
		padding-top: 10px;
		position: fixed;
		top: 60px;
		z-index: 8;

		[class^='translated-'] & {
			top: 99px;
		}

		.controls-bottom {
			display: flex;
			justify-content: space-between;

			.mobile-filters-toggle,
			.mobile-sort-toggle {
				@include text(lambda);
				background-color: transparent;
				border: none;
				color: $color-white;
				flex: 0 0 33.3333%;
				max-width: 33.3333%;
				padding: none;
			}

			button:disabled,
			button[disabled] {
				color: $color-gray-neutral;

				svg {
					fill: $color-gray-neutral;
				}
			}
		}

		&.mobile-sort-open {
			z-index: 9;
		}
	}

	.sort-select-wrapper {
		display: none;
	}

	/*.results-count {
		@include text(iota);
		@include font-weight(medium);
		color: inherit;
		display: none;
	}*/

	.desktop-filters-toggle {
		display: none;
	}

	.mobile-filters-toggle {
		align-items: center;
		display: flex;
		height: 1.5rem;
		justify-content: flex-end;
		margin-left: auto;
		overflow: hidden;

		svg {
			fill: $color-gray-medium-light;
			/*height: 3rem;*/
			stroke: $color-white;
			/*transform: rotate(-90deg) translate(2px, 10px);*/
			width: 1.5rem;
		}

		.filter-count {
			@include font-weight(bold);
			align-items: center;
			background-color: $color-red;
			border-radius: 50%;
			display: inline-flex;
			font-size: 11px;
			height: 13px;
			justify-content: center;
			margin-left: 0.25rem;
			margin-top: -5px;
			width: 13px;

			.text {
				margin-top: 2px;
			}
		}
	}

	.mobile-sort-toggle {
		align-items: center;
		display: inline-flex;
		justify-content: flex-start;

		svg {
			fill: $color-white;
			height: 11px;
			margin-right: 0.55rem;
			width: 11px;
			transform: translateY(-2px);
		}
	}

	.search-page-results {
		.results-controls {
			display: none;
		}
	}

	.results-list-container {
		position: relative;

		&.loading {
			pointer-events: none;

			.loading-overlay {
				opacity: 1;
			}
		}
	}

	.contracted-link-container {
		display: none;
	}

	.contracted-link {
		@include text(lambda);
		color: $color-blue;
		display: none;
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	.filter-list {
		.contracted-link {
			display: block;
			padding: 1rem 30px 30px;
		}
	}

	.pcp-tooltip {
		width: 100%;
	}

	@include breakpoint(md) {
		@include make-container();
		.results-list {
			padding: 0 $md-grid-outer-padding;

			&.view-mode-grid {
				display: flex;
				flex-wrap: wrap;

				.results-list-item {
					flex: calc(50% - 10px);
					margin-bottom: 1.25rem;
					max-width: calc(50% - 10px);
					&:nth-of-type(2n + 1) {
						margin-right: 10px;
					}
					&:nth-of-type(2n + 2) {
						margin-left: 10px;
					}
				}

				.provider-result {
					display: flex;
					flex-direction: column;
					height: 100%;
					margin-bottom: 0;
					padding-bottom: 1rem;

					.provider-details {
						display: flex;
						flex-direction: column;
						height: 100%;
					}

					.detail-bottom {
						border: none;
						margin-top: auto;
						padding-top: 0;
					}

					.schedule-title {
						border-top: 1px solid $color-gray-neutral;
						margin-top: 1.5rem;
						padding-top: 1.5rem;
					}

					.group-and-specialty {
						min-height: 38px;
					}

					.ratings {
						height: 21px;
					}
				}
			}
		}

		.view-mode-controls-container {
			display: flex;
		}

		.pcp-tooltip {
			width: 300px;
		}
	}

	.results-count {
		@include text(iota);
		@include font-weight(medium);
		color: inherit;
	}

	.search-page-controls {
		.results-count {
			display: none;
		}
	}

	@include breakpoint(lg) {
		@include make-container();

		.results-list {
			padding: 0;
		}

		.search-page-controls {
			@include make-row();
			align-items: center;
		}

		.desktop-toggle-container {
			@include make-col-ready();
			@include make-col(4);
		}

		.view-mode-controls-container {
			background-color: transparent;
			padding: 0;
		}

		.results-controls {
			align-items: center;
			background-color: transparent;
			color: $color-black;
			display: none;
			height: 3rem;
			justify-content: space-between;
			padding: 0;
			position: relative;
			top: 0;

			[class^='translated-'] & {
				top: 0;
			}
		}

		.sort-select-wrapper {
			display: block;
			position: relative;
			margin-left: auto;
			margin-right: 1rem;

			select {
				@include text(lambda);
				@include font-weight(medium);
				background-color: transparent;
				border: 0;
				color: $color-black;
				padding: 0 1.5rem 0 0;
				text-align-last: right;

				/* for Firefox */
				-moz-appearance: none;
				/* for Chrome */
				-webkit-appearance: none;

				/* For IE10 */
				&::-ms-expand {
					display: none;
				}

				&:hover {
					cursor: pointer;
				}
			}
			.icon {
				pointer-events: none;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		/*.results-count {
			@include text(lambda);
			@include font-weight(medium);
			margin-bottom: 0;
			display: block;
		}*/

		.search-page-wrapper {
			@include make-row();

			&.filters-closed {
				.filters-container {
					display: none;
				}

				.search-page-results {
					flex: 0 0 100%;
					max-width: 100%;

					.results-controls {
						&:first-child {
							justify-content: flex-end;
							margin-left: -10px;
							margin-right: -10px;
							pointer-events: none;
							width: calc(100% + 20px);

							.controls-top {
								@include make-col-ready();
								@include make-col(12);
								padding-bottom: 0.25rem;
								pointer-events: auto;
								flex: 0 0 66.7%;
								max-width: 66.7%;
							}
						}
					}
					.results-control {
						width: calc(50% - 10px);
						text-align: center;
					}
				}

				.results-list {
					&.view-mode-grid {
						.results-list-item {
							flex: calc(33.333% - (40px / 3));
							max-width: calc(33.333% - (40px / 3));
							&:nth-of-type(3n + 1) {
								margin-left: 0;
								margin-right: 10px;
							}
							&:nth-of-type(3n + 2) {
								margin-left: 10px;
								margin-right: 10px;
							}
							&:nth-of-type(3n + 3) {
								margin-left: 10px;
								margin-right: 0;
							}
						}
					}
				}

				.provider-result:not(.grid-result) {
					.profile-image-outer {
						width: 350px;
					}

					.profile-image-container {
						padding-bottom: 66.666%;
					}

					.provider-details {
						width: calc(100% - 350px);
					}
				}
			}
		}

		.filters-container {
			@include make-col-ready();
			@include make-col(4);
		}

		.filter-list {
			margin-bottom: 4rem;
			padding: 0.625rem 10px 0;

			.contracted-link {
				display: none;
			}

			+ .contracted-link-container {
				display: block;
				margin-top: -3rem;
				padding: 0 26px 1rem;

				.contracted-link {
					display: inline-block;
					padding: 0;
				}
			}
		}

		.desktop-filters-toggle {
			@include text(lambda, 14px);
			@include font-weight(medium);
			align-items: center;
			display: flex;
			margin: 1rem 0;

			.icon {
				height: 0.75rem;
				width: 1.25rem;
			}
		}

		.mobile-filters-toggle,
		.mobile-sort-toggle {
			display: none;
		}

		.search-page-results {
			@include make-col-ready();
			@include make-col(8);

			.results-controls {
				display: flex;
				margin-top: -3rem;
				width: 100%;
			}
		}

		.provider-result:not(.grid-result) {
			.profile-image-outer {
				width: 160px;
			}

			.profile-image-container {
				padding-bottom: 100%;
			}

			.provider-details {
				width: calc(100% - 160px);
			}
		}

		.pcp-tooltip {
			width: 100%;
		}
	}
}

.pls-profile-section .modal-open-btn {
	@include make-container();
}
