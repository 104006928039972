.provider-result {
	@include text(lambda);
	@include font-weight(medium);
	background-color: $color-gray-providers;
	color: $color-gray-dark;
	margin-bottom: 1.5rem;
	padding-bottom: 2rem;

	&.simplified {
		display: flex;
		flex-wrap: wrap;
		min-height: 95px;
		padding-bottom: 0;

		.profile-image-container {
			padding-bottom: 100%;
			width: 95px;
		}

		.provider-details {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 0.25rem 1rem 0.25rem 0.625rem;
			width: calc(100% - 95px);
		}

		.provider-name {
			@include text(kappa);
		}

		.credit {
			align-items: center;
			display: flex;
			justify-content: center;
			padding: 1rem 0;
			width: 100%;

			.date {
				@include text(kappa);
				@include font-weight(semi-bold);
				color: $color-black;
				margin-right: 1rem;
			}

			.icon-share {
				margin-bottom: 0.375rem;
			}
		}
	}

	p {
		@include text(lambda);
		@include font-weight(medium);
		color: $color-gray-dark;
	}

	.location-name {
		color: black;
	}

	a {
		color: $color-blue;
		cursor: pointer;
		&.profile-image {
			&:focus-visible {
				border: $color-gray-darkest 2px solid;
			}
		}
	}

	.btn {
		@include text(lambda);
		@include font-weight(medium);
		border: 1px solid $color-gray-neutral;
		border-radius: 2px;
		margin: 0;
		padding: 0.625rem 0.25rem 0.5rem;
	}

	.profile-image-container {
		display: block;
		height: 0;
		overflow: hidden;
		padding-bottom: 66.666%;
		position: relative;
		width: 100%;
	}

	.profile-image {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		img {
			font-family: 'object-fit: cover; object-position: center 25%;';
			height: 100%;
			object-fit: cover;
			object-position: center 25%;
			width: 100%;
		}
	}

	.provider-details {
		padding: 1.5rem 30px;
	}

	.provider-list-btn {
		@include text(lambda);
		@include font-weight(bold);
		align-items: center;
		border-bottom: 1px solid $color-gray-neutral;
		display: flex;
		height: 40px;
		justify-content: center;
		text-decoration: none;
		width: 100%;

		svg {
			margin-right: 0.5rem;
		}

		.checkbox-wrapper {
			@include checkbox(
				$color-gray-neutral,
				$color-gray-medium-light,
				$color-green-providers
			);
			margin-bottom: 0;
			margin-top: 4px;

			label {
				@include font-weight(bold);
			}
		}
	}

	.provider-name {
		@include text(theta);
		@include font-weight(bold);
		color: $color-black;
		display: block;
		cursor: pointer !important;
		margin-bottom: 0.25rem;
	}

	.medical-group {
		@include text(lambda);
		@include font-weight(bold);

		&.presbyterian {
			@include font-weight(medium);
			color: $color-red;
		}
	}

	.comparison-specialty,
	.comparison-title {
		display: none;
	}

	.ratings {
		margin: 1rem 0;
	}

	.distance-and-location {
		margin: 1rem 0;

		.location-count {
			margin-left: 0.25rem;
		}
	}

	.provider-website {
		.icon-external {
			height: 0.5rem;
			margin-left: 0.25rem;
			stroke: $color-blue;
			width: 0.5rem;
		}
	}

	.location-address {
		margin: 1rem 0;

		span {
			display: block;
		}
	}

	.new-patient-icon-container {
		display: flex;

		.icon {
			margin-bottom: 1px;
			margin-right: 0.5rem;
			margin-top: 3px;
		}
	}
	.open-weekend-icon-container {
		display: flex;

		.icon {
			margin-bottom: 1px;
			margin-right: 0.5rem;
			margin-top: 3px;
		}
	}

	.telehealth-indicator-icon-container {
		display: flex;

		.icon {
			margin-bottom: 1px;
			margin-right: 0.5rem;
			margin-top: -4px;
		}
	}

	.detail-bottom {
		border-top: 1px solid $color-gray-neutral;
		margin-top: 1.5rem;
		padding-top: 1.5rem;

		.schedule-description {
			margin-bottom: 0.5rem;
		}

		p {
			margin-bottom: 0;
		}
	}

	.schedule-title {
		@include font-weight(bold);
		@include text(iota);
		margin-bottom: 0.375rem;
	}

	.schedule-options {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		position: relative;

		.schedule-btns-container,
		.schedule-call-container {
			min-width: calc(50% - 5px);
			flex-basis: calc(50% - 5px);
		}
	}

	.appointment-types {
		text-align: center;

		.reset-btn {
			display: flex;
			justify-content: flex-end;
			width: 100%;
		}

		.appointment-type-title {
			@include font-weight(bold);
			@include text(lambda);
			margin-bottom: 0.5rem;
		}

		.appointment-type {
			@include text(iota);
			@include font-weight(bold);
			margin-bottom: 0.75rem;
		}

		.schedule-btns {
			max-width: none;
		}
	}

	.comparison-schedule-btn {
		display: none;
	}

	.comparison-bottom {
		display: none;
	}

	@include breakpoint(md) {
		&.simplified {
			&:not(.grid-result) {
				flex-wrap: unset;

				.profile-image-outer {
					width: 95px;
					padding: 0;
				}

				.provider-details {
					padding: 0.25rem 1.25rem 0.25rem 1.25rem;
					flex: 1;
				}

				.detail-top {
					.group-and-specialty {
						margin-bottom: 0;
					}
				}

				.credit {
					width: auto;
					padding: 1rem 1rem 1rem 0;
				}
			}
		}

		&:not(.grid-result) {
			display: flex;
			padding-bottom: 0;

			.provider-list-btn {
				border-bottom: none;
			}

			.provider-name {
				@include text(iota);
			}

			.profile-image-outer {
				padding: 0.625rem 0 0 0.625rem;
				width: 250px;
			}

			.provider-details {
				padding: 1.25rem 1.5rem;
				width: calc(100% - 250px);
			}

			.detail-top {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				> div {
					flex-basis: calc(50% - 5px);
					margin: 0;
					min-width: calc(50% - 5px);
				}

				.group-and-specialty {
					margin-bottom: 1.5rem;
					order: 0;
				}

				.distance-and-location {
					margin-bottom: 1.5rem;
					order: 3;
				}

				.ratings {
					order: 2;
				}

				.new-patient-open-weekend {
					margin-left: auto;
					order: 4;
				}

				// .open-weekend {
				// 	order: 6;
				// }

				.telehealth-indicator {
					margin-left: auto;
					order: 5;
				}

				.location-address {
					order: 1;
				}
			}

			.schedule-title {
				color: $color-gray-dark;
			}

			.schedule-options {
				flex-direction: row;
			}
		}

		.appointment-types {
			.schedule-btns {
				justify-content: space-around;
				max-width: 400px;
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 0;

				.btn {
					max-width: 200px;
					min-width: 0;
				}
			}
		}
	}

	@include breakpoint(lg) {
		&.simplified {
			&:not(.grid-result) {
				.credit {
					padding-right: 4rem;
				}
			}
		}

		&:not(.grid-result) {
			.profile-image-outer {
				width: 350px;
			}

			.provider-details {
				width: calc(100% - 350px);
				.provider-details-header {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					.location-name {
						padding-bottom: 5px;
						font-size: 14px;
						font-weight: 700;
						flex-basis: calc(50% - 5px);
						color: black;
					}
				}
			}
		}
	}
}
