.welcome-page {
	.title-primary {
		@include font-weight(semi-bold);
		@include text(gamma);
		color: $color-red;
		margin-bottom: 2rem;
	}

	ul {
		@include text(eta);
		@include font-weight(normal);
		margin-bottom: 3rem;
	}

	.content-slides {
		.container {
			padding: 0;
		}

		.sign-in-btn {
			background-color: $color-blue-bright;
			min-width: 250px;

			&:hover,
			&:focus {
				background-color: $color-black;
			}
		}
	}

	.banner-btn {
		background-color: $color-blue-bright;
		display: block;
		padding: 30px 0;
		text-decoration: none;
		width: 100%;

		span {
			@include font-weight(bold);
			@include text(iota, kappa);
			@include make-container();
			align-items: center;
			color: $color-black;
			display: flex;
			justify-content: space-between;

			svg {
				stroke: $color-black;
			}
		}

		&:hover,
		&:focus {
			background-color: $color-black;

			span {
				color: $color-white;

				svg {
					stroke: $color-white;
				}
			}
		}
	}
}
