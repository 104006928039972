.hv-callout {
	margin-bottom: 3rem;
	position: relative;
	width: 100%;

	&.has-background {
		background-color: #d3d3d3;
		padding: 43px 30px 50px;

		.callout-description {
			&.warning {
				width: 100%;
				left: 0;
				padding-left: 30px;
				padding-right: 30px;
			}
		}
	}

	.icon-share-this {
		left: 10px;
		position: absolute;
		top: 46px;
	}

	.title {
		@include text(theta);
		@include font-weight(medium);
		margin-bottom: 1.375rem;
	}

	.description {
		@include text(kappa);
	}

	.check-list {
		list-style: none;
		padding-left: 1.7rem;

		li {
			@include text(kappa, iota);
			padding-bottom: 0.5rem;
			position: relative;

			&:before {
				background-image: url('/assets/images/check-mark-green.png');
				background-size: cover;
				content: '';
				display: block;
				height: 9px;
				left: -1.5rem;
				position: absolute;
				top: 5px;
				width: 12px;
			}
		}
	}

	.left-aligned-text {
		text-align: left;
	}

	.columns {
		display: flex;
		margin-bottom: 1rem;
		text-align: center;
		flex-wrap: wrap;

		.image-with-caption {
			width: 40%;
			margin: 0 auto;
		}
	}

	.callout-description {
		@include text(kappa);
		background-color: $color-gray-light;
		padding: 0.75rem 1.5rem;

		&.warning {
			position: relative;
			background-color: $color-red-light;
			width: 100vw;
			left: -30px;
			padding-left: 30px;
			padding-right: 30px;
		}
	}

	@include breakpoint(md) {
		&.has-background {
			padding: 43px 95px 50px;
		}

		.callout-description {
			&.warning {
				width: 100%;
				left: auto;
				padding-left: 1rem;
				padding-right: 1rem;
			}
		}

		.columns {
			.image-with-caption {
				width: calc(80% / 3);
			}
		}
		.icon-share-this {
			left: 57px;
		}
	}

	@include breakpoint(lg) {
		.check-list {
			padding-left: 0;
		}
	}
}
